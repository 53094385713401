import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { GridComponent } from 'src/grid/grid.component';
import { ProposalServices } from 'src/services/proposal.services';
import { PostalService } from 'src/services/postal.services';
import { ModalService } from 'src/services/modal.services';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import { MAIN_PAGE_PART_URL, LANDING, AFFINITY_SELECTION, PRODUCT_CODE, USER_PROFILE_DATA,MEENACHIL_SHOP_OCCUPANCY,MEENACHIL_HOME_OCCUPANCY, SAHAJ_AND_LIB_OCCUPANCY, SHOP_OCCUPANCY_TARIFF, HOME_OCCUPANCY_AND_TARIFF, SHOP_OCCUPANCY, INTERACTIVE_TRADEX_SHOP_OCCUPANCY } from 'src/common/common.constants';
import {
  DESCRIPTION,
  CODE,
  CUSTOMER_NAME,
  START_DATE,
  QUOTE_NO
} from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import * as moment from 'moment';
import { CustomerDetailsVO } from 'src/dto/CustomerDetailsVO';
import { ProposalVO } from 'src/dto/ProposalVO';
import { CommunicationVO } from 'src/dto/CommunicationVO';
import { BeneficiaryVO } from 'src/dto/BeneficiaryVO';
import { PremiumDetailsVO } from 'src/dto/PremiumDetailsVO';
import { EmitterService } from '../services/EmitterService';
import { PaymentDetailsVO } from 'src/dto/PaymentDetailsVO';

@Component({
  selector: 'app-retrieve-proposal',
  templateUrl: './retrieve-proposal.component.html',
  styleUrls: ['./retrieve-proposal.component.css']
})
export class RetrieveProposalComponent implements OnInit {
  public columnsData: Array<Object> = Array<Object>();
  public projectData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public actionColumnsDataDoc: Array<Object> = Array<Object>();
  public searchArray: Array<Object> = Array<Object>();
  public loadingmessage = '';
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  public certificateNumber = '';
  public hasErrorMessage: Boolean = false;
  public message: any = '';
  public paymentError = false;
  public customerDetail: CustomerDetailsVO = new CustomerDetailsVO();
  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public paymentDetail: PaymentDetailsVO = new PaymentDetailsVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public hrLeftMargin: number;
  public hrWidth: number;
  public count: any;
  public totalNumberOfRecords: Number;
  public totalRows: any;
  public productId: any;
  public display = 'none';
  public description: string;
  public lobId: Number;
  public mockProposal: any;
  public mockCertificateProposal: any;
  public sumInsuredForCoverages: any;
  public coverageIds: Object = new Object();
  public coverageSectionType1: Array<any> = new Array<any>();
  public coverageSectionType2: Array<any> = new Array<any>();
  public disableEdit: boolean;
  public currentRole: string;
  public applianceDetail: Array<any> = new Array<any>();
  public prodName: any;
  public lobName: any;
  public shortProdName: any;
  public producDesc: any;
  public tempPlanId: any;
  public actionFailedFlag = false;
  public actionFailedMessage = '';

  constructor(private proposalServices: ProposalServices,
    private postalServices: PostalService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe) { }

  @ViewChild('contentBody') contentBodyRef: ElementRef;
  @ViewChild('grid') gridComponent: GridComponent;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyRef.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
  }

  ngOnInit() {
    sessionStorage.setItem(PRODUCT_CODE, '');
    this.onResize();
    this.loadingmessage = 'Please wait...';
    this.route.paramMap.subscribe(params => {
      this.prodName = params.get('prodName');
      this.lobName = params.get('lobName');
      this.getProdAndLobDetails();
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.searchArray = [];
    this.count = 0;
    this.totalNumberOfRecords = 10;
    this.getRetrievelData();
    this.retrieveSearchArray();
    this.prepareColumnsData();
    this.prepareActionColumns();
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.proposerSearchVo.searchId = this.searchArray[0]['Code'];
    this.description = this.searchArray[0]['Description'];
    this.currentRole = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
    if (String(this.currentRole) === 'Underwriter') {
      this.disableEdit = true;
    }
  }

  set(value: any, description: any) {
    this.proposerSearchVo.searchId = value;
    this.description = description;
    this.toggleDisplay();
  }
  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }

  public getRetrievelData() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined && productid !== '') {
      this.proposalServices.getRetrieveProposal(this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
        });
    }
  }

  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getRetrievelData();
  }

  public prepareProjectData(issuedProposalData) {
    const issuedDetails = issuedProposalData.policyDetailsList;
    if (issuedProposalData !== undefined) {
      this.projectData = [];
      for (let i = 0; i < issuedDetails.length; i++) {
        let customerName = '';
        let PremiumValue = '';
        const startDate = issuedDetails[i].policyStartDate;
        const policyDate: any = startDate.split(' ');
        const policyStartDate = policyDate[0];
        const data: Object = new Object();
        if (issuedDetails[i].customerDetails !== undefined) {
          if(issuedDetails[i].customerDetails.lastName!== undefined)
          {
            customerName = issuedDetails[i].customerDetails.firstName + ' ' + issuedDetails[i].customerDetails.lastName;
          }
          else
          {
            customerName = issuedDetails[i].customerDetails.firstName;
          }
        }
        if (issuedDetails[i].premiumDetails !== undefined) {
          PremiumValue = issuedDetails[i].premiumDetails.grossPremium;
        }
        data['Proposal No.'] = issuedDetails[i].covernoteNo;
        data['Customer Name'] = customerName;
        data['Policy Start Date'] = policyStartDate;
        data['Premium'] = PremiumValue;
        this.projectData.push(data);
      }
    }
  }
  prepareColumnsData() {
    const col1: Object = new Object();
    col1['colName'] = 'Proposal No.';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Customer Name';
    this.columnsData.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Policy Start Date';
    this.columnsData.push(col3);
    const col4: Object = new Object();
    col4['colName'] = 'Premium';
    this.columnsData.push(col4);
  }
  public prepareActionColumns() {
    this.actionColumnsData.push('EDIT');
    this.actionColumnsDataDoc.push('PROPOSAL');
  }
  public prepareColumns() {
    this.actionColumnsData.push('');
  }

  public retrieveSearchArray() {
    const code0: Object = new Object();
    code0[CODE] = '';
    code0[DESCRIPTION] = '';
    const code1: Object = new Object();
    code1[CODE] = QUOTE_NO;
    code1[DESCRIPTION] = 'Proposal No.';
    const code2: Object = new Object();
    code2[CODE] = CUSTOMER_NAME;
    code2[DESCRIPTION] = 'Customer Name';
    const code3: Object = new Object();
    code3[CODE] = START_DATE;
    code3[DESCRIPTION] = 'Policy Start Date';
    this.searchArray.push(code1);
    this.searchArray.push(code2);
    this.searchArray.push(code3);
  }

  public retrievProposalSearch() {
    this.loadingmessage = 'Please wait...';
    const policyStatus = 1;
    const action = 'RETRIEVE';
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {

      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        this.proposalServices.getRetrieveProposal(this.count, this.totalNumberOfRecords)
          .then(data => {
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.loadingmessage = '';
            this.gridComponent.inSearch = false;
            this.gridComponent.setcurrentpage(0);
            this.gridComponent.showData();
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.moveToTop();
          }).catch(error => {
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });

      }

    } else {
      if (this.proposerSearchVo.searchId === START_DATE) {

        const generatedDate: any = (/^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/);

        if ((this.proposerSearchVo.searchInput.match(generatedDate))) {
          this.proposalServices.proposalSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action).then(data => {

            this.loadingmessage = '';
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.gridComponent.inSearch = true;
            this.gridComponent.setcurrentpage(0);
            this.gridComponent.showData();
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.moveToTop();
          })
            .catch(error => {
              this.prepareProjectData(null);
              //  this.openActionFailDialog('Data could not be fetched');
              this.loadingmessage = 'Please wait...';
              setTimeout(() => {
                this.serviceDownMessage();
              }, 10000);
            });
        } else {
          this.loadingmessage = '';
          const obj = new Object();
          obj['policyDetailsList'] = [];
          obj['totalRecords'] = 0;
          this.prepareProjectData(obj);
        }

      } else {
        this.proposalServices.proposalSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action).then(data => {
          this.loadingmessage = '';
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.gridComponent.inSearch = true;
          this.gridComponent.setcurrentpage(0);
          this.gridComponent.showData();
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.moveToTop();
        })
          .catch(error => {
            this.prepareProjectData(null);
            //  this.openActionFailDialog('Data could not be fetched');
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      }
    }
  }
  public searchUser() {
    if (!this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
      this.retrievProposalSearch();
    }
  }

  public retrieveToCreateproposal(str) {
    this.loadingmessage = 'Preparing Document...';
    const actionType = str['Action'];
    const quoteNo = str['Proposal No.'];
    if (actionType === 'PROPOSAL') {
      this.proposerVo.communicationDetails = this.communicationDetail;
      this.proposerVo.customerDetails = this.customerDetail;
      this.proposerVo.paymentDetails = this.paymentDetail;
      this.proposalServices
        .getProposalSummary(quoteNo)
        .then(data2 => {
          if (data2 !== undefined) {
            this.certificateNumber = data2.covernoteNo;
            this.tempPlanId = data2.planId;

            const data = this.summaryData(data2);

          }
        }).then(data => {
          if (this.proposerVo.policyTypeName !== 'Individual' &&  this.proposerVo.policyTypeName !== 'Family Floater') {
            this.getCovergeAndSections(this.tempPlanId);
          } else  {
            this.printProposal();
          }
        }).catch(error => { });



    } else if (actionType === 'EDIT') {
      const certificateNo = JSON.stringify(str);
      this.productId = sessionStorage.getItem(PRODUCT_CODE);
      const url = MAIN_PAGE_PART_URL + 'proposal/' + this.prodName + '/' + this.lobName + ')?actionType=RETRIEVE&proposalno=' + str['Proposal No.'];
      this.router.navigateByUrl(url);
    }
    // else if(actionType === 'PROPOSAL'){

    //   this.printProposal();
    // }
  }
  public resetSearch() {
    this.proposerSearchVo.searchInput = '';
  }

  public summaryData(data) {

    const proposerDetails = data;
    this.proposerVo.policyStartDate = proposerDetails.policyStartDate;
    this.proposerVo.policyTerm = proposerDetails.policyTermDescription;
    this.proposerVo.policyEndDate = proposerDetails.policyEndDate;
    this.proposerVo.masterPolicyNo = proposerDetails.masterPolicyNo;
    this.proposerVo.policyTypeName = proposerDetails.policyTypeDescription;
    this.proposerVo.planTypeName = proposerDetails.planDescription;
    this.proposerVo.sumInsured = proposerDetails.sumInsured;
    this.proposerVo.customerId = proposerDetails.customerId;
    this.proposerVo.employeeId = proposerDetails.employeeId;
    this.proposerVo.applianceDetails = proposerDetails.applianceDetails;
    this.proposerVo.affinityId = proposerDetails.affinityId;
    this.proposerVo.placeOfSupply = proposerDetails.placeOfSupply;
    this.proposerVo.policyIssueDate = proposerDetails.policyIssueDate;
    // commenting, since we are getting agent info from session.
    // this.proposerVo.agentId = proposerDetails.agentId;
    this.proposerVo.branchCode = proposerDetails.branchCode;
    this.proposerVo.branchName = proposerDetails.branchName;
    this.proposerVo.riskLocationDetails = proposerDetails.riskLocationDetails;
    this.proposerVo.paymentDetails.otpVerifiedDate = proposerDetails.paymentDetails.otpVerifiedDate;
    if (proposerDetails.productAnswers !== undefined) {
      this.questionList = proposerDetails.productAnswers;
    }
    if (proposerDetails.communicationDetails !== undefined) {
      const proposerCommDtls = proposerDetails.communicationDetails;
      this.proposerVo.communicationDetails.address1 = proposerCommDtls.address1;
      this.proposerVo.communicationDetails.address2 = proposerCommDtls.address2;
      this.proposerVo.communicationDetails.address3 = proposerCommDtls.address3;
      this.proposerVo.communicationDetails.pincode = proposerCommDtls.pincodeLocality;
      this.proposerVo.communicationDetails.stateName = proposerCommDtls.stateName;
      this.proposerVo.communicationDetails.districtName =
        proposerCommDtls.districtName;
      this.proposerVo.communicationDetails.cityName = proposerCommDtls.cityName;
      this.proposerVo.communicationDetails.emailId = proposerCommDtls.emailId;
      this.proposerVo.communicationDetails.mobile = proposerCommDtls.mobile;
      this.proposerVo.communicationDetails.telephone =
        proposerCommDtls.telephone;
      this.proposerVo.communicationDetails.gstinNo = proposerCommDtls.gstinNo;
    }
    if (proposerDetails.riskLocationDetails !== undefined) {
      const proposerRisDtls = proposerDetails.riskLocationDetails;
      this.proposerVo.riskLocationDetails.address1 = proposerRisDtls.address1;
      this.proposerVo.riskLocationDetails.address2 = proposerRisDtls.address2;
      this.proposerVo.riskLocationDetails.address3 = proposerRisDtls.address3;
      this.proposerVo.riskLocationDetails.pincode = proposerRisDtls.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = proposerRisDtls.stateName;
      this.proposerVo.riskLocationDetails.districtName =
      proposerRisDtls.districtName;
      this.proposerVo.riskLocationDetails.cityName = proposerRisDtls.cityName;
      this.proposerVo.riskLocationDetails.emailId = proposerRisDtls.emailId;
      this.proposerVo.riskLocationDetails.mobile = proposerRisDtls.mobile;
      this.proposerVo.riskLocationDetails.telephone =
      proposerRisDtls.telephone;
      this.proposerVo.riskLocationDetails.gstinNo = proposerRisDtls.gstinNo;
    }
    if (proposerDetails.customerDetails !== undefined) {
      this.customerDetail = proposerDetails.customerDetails;
      this.proposerVo.customerId = this.customerDetail.customerId;
      this.proposerVo.employeeId = this.customerDetail.employeeId;
    }
    if (proposerDetails.premiumDetails !== undefined) {
      const premiumDetails = proposerDetails.premiumDetails;
      this.premiumDetail.cgst = premiumDetails.cgst;
      this.premiumDetail.sgst = premiumDetails.sgst;
      this.premiumDetail.sgst = premiumDetails.igst;
      this.premiumDetail.netPremium = premiumDetails.netPremium;
      this.premiumDetail.grossPremium = premiumDetails.grossPremium;
    }
    this.adultList = [];
    this.childList = [];
    if (proposerDetails.beneficiaryList.length !== 0) {
      this.beneficiaryList = proposerDetails.beneficiaryList;
      for (let i = 0; i < this.beneficiaryList.length; i++) {
        if (this.beneficiaryList[i].nomineeType === 2) {
          this.childList.push(this.beneficiaryList[i]);
        }
        if (this.beneficiaryList[i].nomineeType === 1) {
          this.adultList.push(this.beneficiaryList[i]);
        }
      }
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }



  public getBusinessPlanDetails(planId) {
    this.proposalServices.getApplianceDetails(planId).then(data => {
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < this.proposerVo.applianceDetails.length; i++) {
          if (data[j].appliances.applianceID === this.proposerVo.applianceDetails[i].applianceID) {
            const appliance = new Object();
            appliance['applianceName'] = data[j].appliances.applianceName;
            appliance['quantity'] = this.proposerVo.applianceDetails[i].quantity;
            this.applianceDetail[j] = appliance;
          }

        }
      }
    }).catch(error => { });
  }
  getCovergeAndSections(planId) {
    this.proposalServices.getSumInsuredDetails(planId).then(data => {
      this.sumInsuredForCoverages = data;
      const allCoverAgeIds: Array<any> = new Array<any>();
      if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          allCoverAgeIds.push(data[i].coverageId);
          this.coverageIds['coverageIds'] = allCoverAgeIds;
        }

      }
    }).then(data => {
        this.getCoverageAndSectionForCoverageID();
    })


      .catch(error => {
      });
  }

  public getCoverageAndSectionForCoverageID() {

    this.proposalServices.getCoverageAndSections(this.coverageIds).then(data2 => {

      let sectionType1;
      let sectionType2;
      for (let i = 0; i < data2.length; i++) {
        sectionType1 = data2[i].sectionType1;
        sectionType2 = data2[i].sectionType2;
      }
      this.coverageSectionType2=[];
      for (let i = 0; i < sectionType1.length; i++) {

        for (let j = 0; j < sectionType2.length; j++) {
          if (sectionType1[i].sectionId === sectionType2[j].sectionId) {
            const coverageSectionType2 = new Object();
            coverageSectionType2['coverage'] = sectionType1[i].coverage;
            coverageSectionType2['riskCovered'] = sectionType1[i].riskCovered;
            coverageSectionType2['limitperitem'] = sectionType2[j].coverage;
            for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
              if (this.sumInsuredForCoverages[m].coverageId === sectionType1[i].coverageId) {
                coverageSectionType2['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
              if (this.sumInsuredForCoverages[m].coverageId === sectionType2[j].coverageId) {
                coverageSectionType2['limitperitemsuminsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
            }
            this.coverageSectionType2.push(coverageSectionType2);
            delete sectionType1[i].sectionId;
            delete sectionType1[i].coverage;
            delete sectionType1[i].riskCovered;
            delete sectionType1[i].coverageId;
          }
        }
      }
      this.coverageSectionType1= [];
      for (let j = 0; j < sectionType1.length; j++) {
        if (sectionType1[j].coverage !== undefined) {
          const coverageSectionType = new Object();
          coverageSectionType['coverage'] = sectionType1[j].coverage;
          coverageSectionType['riskCovered'] = sectionType1[j].riskCovered;
          for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
            if (this.sumInsuredForCoverages[m].coverageId === sectionType1[j].coverageId) {
              coverageSectionType['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
            }
          }
          this.coverageSectionType1.push(coverageSectionType);
        }
      }

    })
    .then(data => {
      this.printProposal();

    }).
    catch(error => {
    });
  }


  public printProposal() {

    this.hasErrorMessage = false;
   // this.loadingmessage = 'Preparing Document...';
    this.moveToTop();


    // this.getCovergeAndSections(this.tempPlanId);
    //     this.productId = sessionStorage.getItem(PRODUCT_CODE);




    this.productId = sessionStorage.getItem(PRODUCT_CODE);


    const printProsalJson: Object = new Object();
    printProsalJson['applicationName'] = 'affinity';
    printProsalJson['placeOfSupply'] = this.proposerVo.placeOfSupply;
    printProsalJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printProsalJson['dateOfIssue'] = this.proposerVo.policyIssueDate;
    printProsalJson['affinityName'] = this.proposerVo.affinityName;
    printProsalJson['proposalDetails'] = new Object();
    if (this.shortProdName === 'Home' ) {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposal';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else if(this.shortProdName === 'Shop'){
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposalShop';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printProsalJson['proposalDetails']['templateName'] = 'ProposalForm';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    }
    printProsalJson['proposalDetails']['proposalInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo'][
      'employeeInfo'
    ] = new Object();
    if (this.shortProdName === 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.employeeId;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.agentName;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'licenseCode'
      ] = this.proposerVo.agentId;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'contactNumber'
      ] =this.proposerVo.agentContactNumber;
      // const agentDetails = this.proposerVo.agentId.split('/');
      // printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //   'employeeCode'
      // ] = agentDetails[0];
      // if (agentDetails[1] !== undefined) {
      //   printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //     'licenseCode'
      //   ] = agentDetails[1];
      // }
      // if (agentDetails[2] !== undefined) {
      //   printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //     'contactNumber'
      //   ] = agentDetails[2];
      // }
    }
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchName'
    ] = this.proposerVo.branchName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchId'
    ] = this.proposerVo.branchCode;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['city'] =
      this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['state'] =
      this.proposerVo.communicationDetails.stateName;

    printProsalJson['proposalDetails']['proposalInfo'][
      'personInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'lastName'
    ] = this.customerDetail.lastName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'firstName'
    ] = this.customerDetail.firstName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'middleName'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address1'
    ] = this.proposerVo.communicationDetails.address1;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address2'
    ] = this.proposerVo.communicationDetails.address2;


    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address3'
    ] = this.proposerVo.communicationDetails.address3;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'city'
    ] = this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'state'
    ] = this.proposerVo.communicationDetails.stateName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'pinCode'
    ] = this.proposerVo.communicationDetails.pincode;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'telephone'
    ] = this.proposerVo.communicationDetails.telephone;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'mobile'
    ] = this.proposerVo.communicationDetails.mobile;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'email'
    ] = this.proposerVo.communicationDetails.emailId;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'salariedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'selfemployedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'otherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'detailsOfOtherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'indianResident'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'nonIndianResident'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'policyType'
    ] = new Object();
    if (this.proposerVo.policyTypeName === 'Individual') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = '';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'true';
    }



    printProsalJson['proposalDetails']['proposalInfo']['planType'] = new Object();
    if (this.proposerVo.planTypeName === '2 Adults') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adult 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adult 2 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult 2 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'true';
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'typeOfBusiness'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness']['fresh'] =
      'true';
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness'][
      'renewal'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'sumInsuredInfo'
    ] = new Object();

    if (this.proposerVo.sumInsured === '2 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '3 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '5 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'true';
    }

    if (this.proposerVo.policyStartDate && this.proposerVo.policyEndDate) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'policyDuration'
      ] = new Object();
      const startdate = this.proposerVo.policyStartDate.split(' ');

      const enddate = this.proposerVo.policyEndDate.split(' ');
      printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
        'from'
      ] = startdate[0];
      printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
        'to'
      ] = enddate[0];
      if (this.shortProdName !== 'Health' ) {
        printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
          'from'
        ] =  this.proposerVo.policyStartDate;
        printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
          'to'
        ] = enddate[0] + ' 23:59:59';
      }
    }
    printProsalJson['proposalDetails']['proposalInfo'][
      'insured1Info'
    ] = new Object();


    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.customerDetail.firstName;
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = this.adultList[0].proposerRelationDescription;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = this.adultList[0].genderDescription;

    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = '';
    }



    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = this.adultList[0].dob;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = this.adultList[0].nomineeName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = this.adultList[0].nomineeRelationDescription;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = '';
    }
    if (this.adultList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured2Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'name'
      ] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipWithProposer'
      ] = this.adultList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'gender'
      ] = this.adultList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'dob'
      ] = this.adultList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'nomineeName'
      ] = this.adultList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipOfNominee'
      ] = this.adultList[1].nomineeRelationDescription;
    }

    if (this.childList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured3Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'name'
      ] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipWithProposer'
      ] = this.childList[0].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'gender'
      ] = this.childList[0].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'dob'
      ] = this.childList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'nomineeName'
      ] = this.childList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipOfNominee'
      ] = this.childList[0].nomineeRelationDescription;
    }
    if (this.childList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured4Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'name'
      ] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipWithProposer'
      ] = this.childList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'gender'
      ] = this.childList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'dob'
      ] = this.childList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'nomineeName'
      ] = this.childList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipOfNominee'
      ] = this.childList[1].nomineeRelationDescription;
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'paymentInfo'
    ] = new Object();

    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cash'] = '';

    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['others'] =
      'true';

    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cheque'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['dd'] = '';


    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'customerId'
    ] = this.proposerVo.customerId;
    if (this.shortProdName !== 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'address1'
      ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3;
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'city'
      ] = this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName;
      if (this.proposerVo.riskLocationDetails !== undefined) {
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationAddress'
              ] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' + this.proposerVo.riskLocationDetails.address3;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationCity'
              ] =  this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' +
              this.proposerVo.riskLocationDetails.stateName;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationPincode'
              ] = this.proposerVo.riskLocationDetails.pincode;
      }
    }


    if (this.certificateNumber) {

      const appno = this.certificateNumber.substring(this.certificateNumber.length - 12, this.certificateNumber.length);
      // const appno = this.certificateNumber;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'applicationNo'
      ] = appno;
    }

    // printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
    //   'applicationNo'
    // ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['date'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'financierBranchName'
    ] = '';
    if (this.shortProdName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      }  else if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_SHOP_OCCUPANCY;
      }
      else  if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
      }
      else {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProdName === 'Home') {

      if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_HOME_OCCUPANCY;
      } else {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'loanAccountNo'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'planName'
    ] =  this.proposerVo.planTypeName;
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'otpVerifiedDate'
    ] = this.proposerVo.paymentDetails.otpVerifiedDate;
    if (this.shortProdName === 'Home') {
      this.getBusinessPlanDetails(this.tempPlanId);
    }

      //   printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = new Array();

      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = this.coverageSectionType1;

      //printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1']  = stringified;



      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType2'] = this.coverageSectionType2;
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = this.premiumDetail.netPremium;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = this.premiumDetail.cgst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      } else {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      }


      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = this.premiumDetail.sgst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      } else {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      }

      if (this.premiumDetail.igst) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'IGST(18%)';
        obj5['premiumValue'] = this.premiumDetail.igst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj5);
      }

      if (this.premiumDetail.grossPremium) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Premium Including Tax';
        obj6['premiumValue'] = this.premiumDetail.grossPremium;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      } else {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Net Premium(Taxable value)';
        obj6['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      }
     
    printProsalJson['proposalDetails']['proposalInfo']['equipment'] = new Array();
    if (this.shortProdName === 'Home') {
      let serialCounter: Number = 0;
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printProsalJson['proposalDetails']['proposalInfo']['equipment'].push(obj1);
      }
    }
    if (printProsalJson) {
      this.proposalServices
        .getPrintProposal(JSON.stringify(printProsalJson))
        .then(data1 => {
          const parsedResponse = data1;
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printFrom.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.certificateNumber + '.pdf'; //'printFrom.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.loadingmessage = '';
        });
    }




  }

  public getIssuedDetailsWithSearch() {
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      const policyStatus = 1;
      const action = 'RETRIEVE';
      this.proposalServices.proposalSearchPagination(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action, this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.moveToTop();
        })
        .catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
    }
  }

  updatePageDataWithSearch(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getIssuedDetailsWithSearch();
  }
  public getProdAndLobDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    this.proposerVo.agentName = affinty['agentName'];
    this.proposerVo.agentId = affinty['agentId'];
    this.proposerVo.agentContactNumber = affinty['agentContactNumber'];
    this.proposerVo.affinityName = affinty['name'];
    if (affinty['affinityLogo'] !== '' && affinty['affinityLogo'] !== undefined) {
      this.proposerVo.affinityLogo = 'Logo_' + affinty['affinityLogo'];
    } else {
      this.proposerVo.affinityLogo = '';
    }
    if (affinityId !== undefined) {
      this.proposalServices.getAvailableProducts(affinityId).then(data1 => {
        const productData = data1;
        if (productData != null) {
          sessionStorage.setItem('prodcutDetails', JSON.stringify(productData));
          this.setShortProdName();
          this.getRetrievelData();
        }
      }).catch(error => {
      });
    }
  }
  public setShortProdName() {
    let lobDetails;
    lobDetails = this.getProductIdAndLobName(this.prodName);
    this.lobId = lobDetails['lobId'];
    this.shortProdName = lobDetails['shortProductName'];
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
  }
  public getProductIdAndLobName(prodName: any) {
    const productDetails = sessionStorage.getItem('prodcutDetails');
    if (productDetails) {
      const prodcutArrayObj: Array<Object> = JSON.parse(productDetails);
      if (prodcutArrayObj) {
        for (const prodType of prodcutArrayObj) {
          if (prodType['productName'] === prodName) {
            const productID = prodType['productId'];
            this.producDesc = prodType['productInfo'];
            sessionStorage.setItem(PRODUCT_CODE, productID);
            return this.producDesc;
          }
        }
      }
    }
  }

  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      });
    this.router.navigateByUrl('');
  }

}
