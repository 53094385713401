import {
    Component,
    OnInit,
    Output,
    HostListener,
    ChangeDetectionStrategy,
    ViewChildren,
    AfterViewInit,
    AfterViewChecked,
    ViewChild,
    ElementRef,
    ChangeDetectorRef
  } from '@angular/core';
  import {
    DESCRIPTION,
    CODE,
    CERTIFICATE_NO,
    CUSTOMER_NAME,
    START_DATE,
    PRODUCT_CODE,
    SAHAJ_AND_LIB_OCCUPANCY,
    SHOP_OCCUPANCY_TARIFF,
    HOME_OCCUPANCY_AND_TARIFF,
    SHOP_OCCUPANCY
  } from 'src/common/common.constants';
  import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
  import { GridComponent } from 'src/grid/grid.component';
  import { EmitterService } from '../services/EmitterService';
  import { Router, ActivatedRoute } from '@angular/router';
  import { ProposalServices } from 'src/services/proposal.services';
  import * as _moment from 'moment';


  const moment = (_moment as any).default ? (_moment as any).default : _moment;
  @Component({
    selector: 'app-renewalold',
    templateUrl: './renewalold.component.html'
  })
  export class renewalComponent implements OnInit{
    public columnsData: Array<Object> = Array<Object>();
    public actionNameData: String;
    public projectData: Array<Object> = Array<Object>();
    public subcolumnsData: Array<Object> = Array<Object>();
    public actionColumnsData: Array<Object> = Array<Object>();
    public searchArray: Array<Object> = Array<Object>();
    public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
    public display = 'none';
    public description: string;
    public policyStartDateSearch: any;
    public prodName: any;
    public lobName: any;
    public shortProdName: any;
    public producDesc: any;
    public totalNumberOfRecords: Number;
    public count: any;
    public loadingmessage = '';
    public hrWidth: number;
    public totalRows: any;
    public productId: any;
    public lobId: Number;
    public hrLeftMargin: number;
    public affinityId: any;
    public affinityName: any;

    @ViewChild('contentBody') contentBodyRef: ElementRef;
    @ViewChild('grid') gridComponent: GridComponent;
    @HostListener('window:resize', ['$event'])
    onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyRef.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
  }

  constructor(private router: Router,private proposalServices: ProposalServices,
    private route: ActivatedRoute) {
  }

  ngOnInit(){
    sessionStorage.setItem(PRODUCT_CODE, '');
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    this.affinityId = affinty['id'];
    this.affinityName = affinty['name'];
    this.onResize();
    this.loadingmessage = 'Please wait...';
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.searchArray = [];
    this.count = 0;
    this.totalNumberOfRecords = 10;
    this.issuedSearchArray();
    this.prepareColumnsData();
    this.getIssuedDatails();
    this.prepareActionColumns();
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.proposerSearchVo.searchId = this.searchArray[0]['Code'];
    this.description = this.searchArray[0]['Description'];
  }

  public setShortProdName() {
    let lobDetails;
    lobDetails = this.getProductIdAndLobName(this.prodName);
    this.lobId = lobDetails['lobId'];
    this.shortProdName = lobDetails['shortProductName'];
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
  }

  public getProductIdAndLobName(prodName: any) {
    const productDetails = sessionStorage.getItem('prodcutDetails');
    if (productDetails) {
      const prodcutArrayObj: Array<Object> = JSON.parse(productDetails);
      if (prodcutArrayObj) {
        for (const prodType of prodcutArrayObj) {
          if (prodType['productName'] === prodName) {
            const productID = prodType['productId'];
            this.producDesc = prodType['productInfo'];
            sessionStorage.setItem(PRODUCT_CODE, productID);
            return this.producDesc;
          }
        }
      }
    }
  }

  public getIssuedDatails() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      this.proposalServices.getOldRenewalProposal(this.affinityId,this.count, this.totalNumberOfRecords)
        .then(data => {
          this.loadingmessage = '';
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
        });
    }
  }

  set(value: any, description: any) {
    this.proposerSearchVo.searchId = value;
    this.policyStartDateSearch = '';
    this.proposerSearchVo.searchInput = '';
    this.description = description;
    this.toggleDisplay();
  }

  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }

  hideDropdown() {
    this.display = 'none';
  }
    public issuedSearchArray() {
      const code0: Object = new Object();
      code0[CODE] = CERTIFICATE_NO;
      code0[DESCRIPTION] = 'Certificate No.';
      const code2: Object = new Object();
      code2[CODE] = CUSTOMER_NAME;
      code2[DESCRIPTION] = 'Customer Name';
      const code3: Object = new Object();
      code3[CODE] = START_DATE;
      code3[DESCRIPTION] = 'Policy Start Date';
      this.searchArray.push(code0);
      this.searchArray.push(code2);
      this.searchArray.push(code3);
    }

    prepareColumnsData() {
      this.actionNameData = 'Print Documents';
      const col1: Object = new Object();
      col1['colName'] = 'Certificate No.';
      this.columnsData.push(col1);
      const col2: Object = new Object();
      col2['colName'] = 'Customer Name';
      this.columnsData.push(col2);
      const col3: Object = new Object();
      col3['colName'] = 'Policy Start Date';
      this.columnsData.push(col3);
    }

    public prepareActionColumns() {
      this.actionColumnsData.push('CERTIFICATE');
    }

    public updatePageData(data) {
      if (data.PageNumber === 0) {
        this.count = data.PageNumber;
      } else {
        this.count = data.PageNumber * data.NumberOfRecords;
      }
      this.totalNumberOfRecords = data.NumberOfRecords;
      this.getIssuedDatails();
    }

    updatePageDataWithSearch(data) {
      if (data.PageNumber === 0) {
        this.count = data.PageNumber;
      } else {
        this.count = data.PageNumber * data.NumberOfRecords;
      }
      this.totalNumberOfRecords = data.NumberOfRecords;
      this.getDetailsWithSearch();
    }

    public getDetailsWithSearch() {
      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        this.loadingmessage = 'Please wait...'
        this.proposalServices.oldRenewalProposalSearch(this.affinityId, this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, this.count, this.totalNumberOfRecords)
          .then(data => {
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.loadingmessage = '';
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.moveToTop();
          }).catch(error => {
            this.loadingmessage = '';
            //  this.openActionFailDialog('Data could not be fetched');
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      }
    }

    public issuedProposalSearch() {
      this.loadingmessage = 'Please wait...';
      if(this.proposerSearchVo.searchId === START_DATE) {
        this.proposerSearchVo.searchInput = this.policyStartDateSearch;
      }
      const startdate = moment(this.proposerSearchVo.searchInput).local().format('DD/MM/YYYY');
      if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
        if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
          this.proposalServices.getOldRenewalProposal(this.affinityId, this.count, this.totalNumberOfRecords)
            .then(data => {
              this.totalRows = data['totalRecords'];
              this.prepareProjectData(data);
              this.loadingmessage = '';
              this.gridComponent.inSearch = false;
              this.gridComponent.setcurrentpage(0);
              this.gridComponent.showData();
              this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
              this.moveToTop();
            })
            .catch(error => {
              this.loadingmessage = '';
              //  this.openActionFailDialog('Data could not be fetched');
              this.loadingmessage = 'Please wait...';
              setTimeout(() => {
                this.serviceDownMessage();
              }, 10000);
            });
  
        }
  
      } else {
        if (this.proposerSearchVo.searchId === START_DATE) {
  
          const generatedDate: any = (/^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/);
          this.proposerSearchVo.searchInput = moment(this.proposerSearchVo.searchInput).local().format('DD/MM/YYYY');
          if (this.proposerSearchVo.searchInput.match(generatedDate)) {
            this.proposalServices.oldRenewalProposalSearch(this.affinityId, this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput,this.count,  this.totalNumberOfRecords).then(data => {
  
              this.loadingmessage = '';
              this.totalRows = data['totalRecords'];
              this.prepareProjectData(data);
              this.gridComponent.inSearch = true;
              this.gridComponent.setcurrentpage(0);
              this.gridComponent.showData();
              this.moveToTop();
            }).catch(error => {
                this.loadingmessage = '';
                //  this.openActionFailDialog('Data could not be fetched');
                this.prepareProjectData(null);
                this.loadingmessage = 'Please wait...';
                setTimeout(() => {
                  this.serviceDownMessage();
                }, 10000);
              });
          } else {
            this.loadingmessage = '';
            const obj = new Object();
            obj['policyDetailsList'] = [];
            obj['totalRecords'] = 0;
            this.prepareProjectData(obj);
          }
  
        } else {
          this.proposalServices.oldRenewalProposalSearch(this.affinityId, this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, this.count, this.totalNumberOfRecords).then(data => {
            this.loadingmessage = '';
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.gridComponent.inSearch = true;
            this.gridComponent.setcurrentpage(0);
            this.gridComponent.showData();
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.moveToTop();
          }).catch(error => {
              this.loadingmessage = '';
              //  this.openActionFailDialog('Data could not be fetched');
              this.prepareProjectData(null);
              this.loadingmessage = 'Please wait...';
              setTimeout(() => {
                this.serviceDownMessage();
              }, 10000);
            });
        }
      }
    }
    public searchUser() {
      if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
        this.issuedProposalSearch();
      }
    }

    public prepareProjectData(issuedProposalData) {
      const issuedDetailsList = issuedProposalData.renewalDataList;
      if (issuedProposalData !== undefined) {
        this.projectData = [];
        for (let i = 0; i < issuedDetailsList.length; i++) {
          const startDate = issuedDetailsList[i].policyStartDate;
          const policyDate: any = startDate.split('T');
          const policyStartDate = moment(policyDate[0]).local().format('DD/MM/YYYY');policyDate[0];
          const data: Object = new Object();
          data['Certificate No.'] = issuedDetailsList[i].certificateNo;
          data['Customer Name'] = issuedDetailsList[i].customerName;
          data['Policy Start Date'] = policyStartDate;
          this.projectData.push(data);
        }
      }
    }

    public PrintData(str) {
      this.loadingmessage = 'Preparing Document...';
      const certificateNo = str['Certificate No.'];
      this.proposalServices
      .oldRenewalDownload(this.affinityName, certificateNo)
      .then(data1 => {
        const parsedResponse = data1;
        const blob = new Blob([parsedResponse], {
          type: 'application/pdf'
        });
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'printcertificate.pdf');
          this.loadingmessage = '';
        } else {
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob);
          a.download = 'printcertificate.pdf';
          document.body.appendChild(a);
          a.click();
          this.loadingmessage = '';
          document.body.removeChild(a);
        }
      })
      .catch(error => {
        this.loadingmessage = '';
      });
    }
    public serviceDownMessage() {
      setTimeout(
        function () {
          EmitterService.get('SERVICE_DOWN').emit(true);
        });
      this.router.navigateByUrl('');
    }

    moveToTop() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    }

  }
