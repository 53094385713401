export class RiskLocationVO {
    public customerRiskAddressId: any;
    public customerAddressId: any;
    public customerId: Number;
    public address1: String;
    public address2: String = '';
    public address3: String = '';
    public stateId: Number;
    public districtId: Number;
    public cityId: Number;
    public pincode: String;
    public pincodeId: String;
    public emailId: String;
    public mobile: String;
    public telephone: String = '';
    public gstinNo: String = '' ;
    public stateName: String;
    public districtName: String;
    public cityName: String;
    public isGstinAvail: any;
  }
