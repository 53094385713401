import { DocumentVO } from './DocumentVO';
import { AffinityDocumentVO } from './AffinityDocumentVO';

export class AffinityVO {
  public logo: String;
  public name: string;
  public agentId: String;
  public issuingState: String;
  public ssoUser: boolean;
  public walletLimit: Number;
  public minimumWalletLimit: Number;
  public customerCareEmail: String = 'care@libertyinsurance.in';
  public customerCareMobile: String = '18002665844';
  public documentVO: DocumentVO;
  public issueStateDescription: String;
  public affintyDocuments: any;
  public affinityLogo: any;
  public agentName:any;
  public agentContactNumber: Number;
  public paymentResource: any;
  public affinityLobResource: any;
}
