export class UserDataVO {
    name: string;
    emailAddress: string;
    userName: string;
    employeeCode: string;
    phoneNumber: string;
    roleId: string;
    roleName: string;
    branchId: string;
    affinityname: string;
    officeLocation: any;
    isActive: boolean;
    isLocked: boolean;
    userId: string;
    affinityId: string;
    userPortal: any;
    creationTimestamp: string;
    expiryTimeStamp: string;
    lastActivityDate: string;
    lastUpdatedTimestamp: string;
    password: string;
    constructor() {
        this.affinityname = '';
        this.branchId = '';
        this.emailAddress = '';
        this.employeeCode = '';
        this.name = '';
        this.phoneNumber = '';
        this.roleId = '';
        this.roleName = '';
        this.userName = '';
        this.officeLocation = '';
        this.isActive = true;
        this.isLocked = false;
        this.userId = '';
        this.affinityId = '';
        this.userPortal = [];
        this.creationTimestamp = '';
        this.expiryTimeStamp = '';
        this.lastActivityDate = '';
        this.lastUpdatedTimestamp = '';
        this.password = '';
    }
}
