import { Component, OnInit,HostListener, AfterViewInit ,ViewChildren, ViewChild, ElementRef} from '@angular/core';
import * as _moment from 'moment';
import { ReportsVO } from './../dto/ReportsVO';
import { DatePipe } from '@angular/common';
import { ReportsService } from '../services/reports.service';
import { USER_AFFINITY, AFFINITY_ID, USER_PROFILE_DATA, END_DATE } from '../common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';


const moment = (_moment as any).default ? (_moment as any).default : _moment;
@Component({
  selector: 'app-reportJewellery',
  templateUrl: './jeweleryreport.component.html'
})


export class JeweleryReportComponent implements OnInit {
  public hrLeftMargin;
  public hrWidth;
  public columnHeadings: Array<Object> = Array<Object>();
  public reports: ReportsVO = new ReportsVO();
  public productDateRangeMessage: any;
  public dateFlag: Boolean = false;
  public description: String = '--Select--';
  public enableSubmit: Boolean = false;
  public isSubmit: Boolean = false;
  public max: any;
  public policyCount: Array<Object> = Array<Object>();
  public totalRows: any;
  public loadingmessage = '';
  public dowanloadFlag: Boolean = false;

  
  @ViewChildren(TextBoxComponent) private textFields: any;
  @ViewChildren(SelectComponent) private selectFields: any;
  @ViewChildren(DatePickerComponent) private dateFields: any;
  @ViewChild('formWrapper') formWrapperRef: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);

  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  constructor(private reportsService: ReportsService,
    private datePipe: DatePipe) {

  }


  ngOnInit() {

    const date = new Date;
    this.max = new Date(date.setDate(date.getDate()));
    const endDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
    this.reports.startDate = endDate;
    this.reports.endDate = endDate;

    EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
    
    this.reports.shortProductName = 'Jewel';
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
  }

  ngAfterViewInit() {
    this.onResize();
  }

  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }

  public getPolicyDetails(){
    this.loadingmessage = 'Preparing Data...';
    this.reportsService.getJeweleryTrasactionGridData(this.reports.startDate, this.reports.endDate, this.reports.shortProductName).then(data => {
      const val: any = data;
      this.totalRows = 1;
      if (data !== 0) {
        this.prepareProjectData(val);
      } else {
        this.enableSubmit = true;
        this.loadingmessage = '';
      }
      this.moveToTop();
    }).catch(error => {
      this.loadingmessage = '';
    });
  }


  
  
  successmessage(){
    this.getPolicyDetails()
    this.DailyTransactionColDatas()
    this.isSubmit = true;
    this.enableSubmit = false;
  }



  DailyTransactionColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'No. of Policies';
    this.columnHeadings.push(col1);
}

  public clearStartDate() {
    if (this.isSubmit === true) {
     this.dateFlag = false;
      this.policyCount = [];
      this.reports.endDate = '';
      this.enableSubmit = false;
      this.isSubmit = false;
     }
  }

  public clearEndDate() {
    if (this.isSubmit === true) {
      this.dateFlag = false;
      this.policyCount = [];
      this.reports.startDate = '';
      this.enableSubmit = false;
      this.isSubmit = false;
      this.dowanloadFlag = false;
    }
  }


  public prepareProjectData(dailyTransactionData: any){
    this.loadingmessage = '';
    this.enableSubmit = true;
    const data: Object = new Object();
      this.policyCount = [];
      data['No. of Policies'] = dailyTransactionData;
      this.policyCount.push(data);   
      this.dowanloadFlag = true;
}
 

public downloadReport(event) {
  this.loadingmessage = 'Preparing Document...'
    this.reportsService.getDailyTransactionjewelReport(this.reports.startDate, this.reports.endDate, this.reports.shortProductName)
      .then(data => {
        const parsedResponse = data.blob();
        const blob = new Blob([parsedResponse], {
          type: 'application/xlsx'

        });
      
        if (window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveBlob(blob, 'Daily Transaction Jewel Report.xlsx');
          this.loadingmessage = '';
        } else {
          const a = window.document.createElement('a');
          a.href = window.URL.createObjectURL(blob);  
          a.download = 'Daily Transaction Jewel Report.xlsx';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          this.loadingmessage = '';
        }
      })
      .catch(error => {
        this.loadingmessage = '';
      });

}

private outSidevalidate() {
  let textResult: any = false;
  let selectResult: any = false;
  let dateResult: any = false;
  let hasTextField: Boolean = false;
  let hasSelectField: Boolean = false;
  let hasDateField: Boolean = false;
  let textFlag = true;
  let selectFlag = true;
  let dateFlag = true;
  for (const textField of this.textFields['_results']) {
    hasTextField = true;
    const input: TextBoxComponent = textField;
    textResult = input.onBlurMethod(false);
    if (!textResult) {
      textFlag = false;
    }
  }
  for (const selectField of this.selectFields['_results']) {
    hasSelectField = true;
    const input: SelectComponent = selectField;
    selectResult = input.onBlurMethod(false);
    if (!selectResult) {
      selectFlag = false;
    }
  }
  for (const dateField of this.dateFields['_results']) {
    hasDateField = true;
    const input: DatePickerComponent = dateField;
    dateResult = input.onBlurMethod(false);
    if (!dateResult) {
      dateFlag = false;
    }
  }
  const result: any =
    (textFlag || !hasTextField) &&
    (selectFlag || !hasSelectField) &&
    (dateFlag || !hasDateField) &&
    this.dateFlag;
  if (result) {
    this.enableSubmit = true;
    return true;
  } else {
    this.enableSubmit = false;
    this.dowanloadFlag = false;
    return false;
  }
}



  public productStartChange() {   
    this.clearStartDate();
    const date = moment(this.reports.startDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.reports.startDate = date;
    } else {
      this.reports.startDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
}

  public productEndChange() {
   this.clearEndDate()
    const date = moment(this.reports.endDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.reports.endDate = date;
    } else {
      this.reports.endDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
  }

  dateRangeValidation(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (startDate > endDate) {
        this.productDateRangeMessage = 'Start Date should be prior to End Date';
        this.dateFlag = false;
      } else {
        this.productDateRangeMessage = '';
        this.dateFlag = true;
      }
    } else {
      this.productDateRangeMessage = '';
    }
  }


}

