import { Route } from '@angular/router';
import { RouterModule, Routes, Router } from '@angular/router';
import { ModuleWithProviders, Component } from '@angular/core';

import { LoginComponent } from '../login/login.component';
import { WelcomeComponent } from 'src/welcome/welcome.component';
import { LandingComponent } from 'src/landing/landing.component';
import { ProposalComponent } from 'src/proposal/proposal.component';
import { ErrorComponent } from 'src/ErrorPages/ErrorComponent';
import { AffinityComponent } from 'src/affinityconfig/affinity.component';
import { ProposalSummaryComponent } from 'src/proposal-summary/proposal-summary.component';
import { PaymentComponent } from 'src/payment/payment.component';
import { IssuedProposalComponent } from 'src/issued-proposal/issued-proposal.component';
import { RenewalTransactionComponent } from 'src/renewal-transaction/renewal-transaction.component';
import { RetrieveProposalComponent } from 'src/retrieve-proposal/retrieve-proposal.component';
import { EndorsementProposalComponent } from 'src/endorsement-proposal/endorsement-proposal.component';
import { DeclarationComponent } from 'src/declaration/declaration.component';
import { CreateUserComponent } from 'src/create-user/create-user.component';
import { ViewUsersComponent } from 'src/view-users/view-users.component';
import { CreateNewAffinity } from 'src/create-new-affinity/create-new-affinity.component';
// import { ProductConfiguration } from '../product-configuration/product-configuration.component';
import { AuthGuard } from '../auth-guard/auth.guard';
import { RoleGuard } from '../auth-guard/role.guard';
import { ProductConfigurationComponent } from 'src/product-configuration/product-configuration.component';
import { PlanAssignmentComponent } from 'src/plan-assignment/planassignment';
import { ReportsHealthComponent } from '../reportshealth/reportshealth.component';
import { JeweleryReportComponent } from '../reportsJewellery/jeweleryreport.component';
import { ReportsBusinessComponent } from '../reportsbusiness/reportsbusiness.component';
import { ResourcesComponent } from 'src/resources/resources.component';
import { InboxpageComponent } from 'src/inboxpage/inboxpage.component';
import { SelectProductComponent } from 'src/select-product/select-product.component';
import {BranchComponent} from 'src/branchcreate/branch.component';
import {renewalComponent} from 'src/renewalold/renewalold.component';





export const route: Routes = [

  {
    path: 'welcome', component: WelcomeComponent, children: [
      {
        path: 'affinity', component: AffinityComponent, outlet: 'contentBody',
        data: {
          allowedRoles: ['System Administrator', 'Underwriter']
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'selectProduct', component: SelectProductComponent, outlet: 'contentBody',
      },
      {
        path: 'newAffinity', component: CreateNewAffinity, outlet: 'contentBody',
        data: {
          allowedRoles: ['System Administrator', 'Underwriter', 'Affinity Administrator']
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'planAssignment', component: PlanAssignmentComponent, outlet: 'contentBody'
        // data: {
        //   allowedRoles: ['System Administrator', 'Underwriter', 'Affinity Administrator'],
        //   affinityDataRequired: true
        // },
        // canActivate: [RoleGuard]
      },
      { path: 'resources/download', component: ResourcesComponent, outlet: 'contentBody' },
      { path: 'resources/renewalDownload', component: renewalComponent, outlet: 'contentBody', runGuardsAndResolvers: 'always', canActivate: [AuthGuard],
      data: {
            affinityDataRequired: true
          }
      },      
      { path: 'reports/health', component: ReportsHealthComponent, outlet: 'contentBody' },
      { path: 'reports/business', component: ReportsBusinessComponent, outlet: 'contentBody' },
      { path: 'reports/jewelery', component: JeweleryReportComponent, outlet: 'contentBody'},
      { path: 'reports/inbox', component: InboxpageComponent, outlet: 'contentBody' },
      {
        path: 'product', component: ProductConfigurationComponent, outlet: 'contentBody',
        data: {
          allowedRoles: ['System Administrator', 'Underwriter'],
          affinityDataRequired: true
        },
        canActivate: [RoleGuard]
      },
      {
        path: 'landing', component: LandingComponent, outlet: 'contentBody', canActivate: [AuthGuard],
        data: {
          affinityDataRequired: true
        }
      },
      {
        path: 'proposal/:prodName/:lobName', component: ProposalComponent, outlet: 'contentBody', canActivate: [RoleGuard],
        data: {
          create: ['System Administrator', 'Branch User', 'Call Center User'],
          renew: ['System Administrator', 'Affinity Administrator', 'Branch User', 'Call Center User'],
          retrieve: ['System Administrator', 'Affinity Administrator', 'Branch User', 'Call Center User'],
          endorsements: ['System Administrator', 'Affinity Administrator', 'Branch User', 'Call Center User'],
          affinityDataRequired: true
        }
      },
      { path: 'error', component: ErrorComponent, outlet: 'contentBody' },
      {
        path: 'issuedProposal/:prodName/:lobName', component: IssuedProposalComponent, outlet: 'contentBody', runGuardsAndResolvers: 'always', canActivate: [AuthGuard],
        data: {
          affinityDataRequired: true
        }
      },
      {
        path: 'renewalTransaction/:prodName/:lobName', component: RenewalTransactionComponent, outlet: 'contentBody', canActivate: [AuthGuard],
        data: {
          affinityDataRequired: true
        }
      },
      {
        path: 'retrieveProposal/:prodName/:lobName', component: RetrieveProposalComponent, outlet: 'contentBody', canActivate: [AuthGuard],
        data: {
          affinityDataRequired: true
        }
      },
      {
        path: 'endorsement/:prodName/:lobName', component: EndorsementProposalComponent, outlet: 'contentBody', canActivate: [AuthGuard],
        data: {
          affinityDataRequired: true
        }
      },
      {
        path: 'createUser', component: CreateUserComponent, outlet: 'contentBody', canActivate: [RoleGuard],
        data: {
          create: ['System Administrator', 'Affinity Administrator'],
          edit: ['System Administrator', 'Affinity Administrator', 'Underwriter'],
          affinityDataRequired: true
        }
      },
      {
        path: 'viewUsers', component: ViewUsersComponent, outlet: 'contentBody', canActivate: [RoleGuard],
        data: {
          view: ['System Administrator', 'Underwriter', 'Affinity Administrator'],
          affinityDataRequired: true
        }
      },
      {
        path: 'branchConfig', component: BranchComponent, outlet: 'contentBody', canActivate: [RoleGuard],
        data: {
          allowedRoles: ['Affinity Administrator'],
          affinityDataRequired: true
        }
      },
    ]
  },
  { path: '', component: LoginComponent },
  { path: 'proposal', component: ProposalComponent, canActivate: [AuthGuard] },
  { path: 'proposalSummary/:quoteNumber', component: ProposalSummaryComponent },
  { path: 'paymentGateway/:paymentData', component: PaymentComponent },
  { path: 'declaration/:key', component: DeclarationComponent }
  

];
export const routing: ModuleWithProviders = RouterModule.forRoot(route, {
  onSameUrlNavigation: 'reload'
});
