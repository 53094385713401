import { Injectable } from '@angular/core';

import { AppConfig } from 'src/config/app.config';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HEADER, HEADER_PRINT, USER_PROFILE, HEADER_UM, REQUEST_HEADER } from './../common/common.constants';
import { RequestOptions } from '@angular/http';


@Injectable()
export class AffinityService {
    hostUrl: string = this.config.getConfig('productHost');
    documentDownloadUrl: string = this.config.getConfig('documentHost');
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    public checkAffinityName(name: string) {
        let url: any;
        url = this.hostUrl + 'api/admin/getAffinity/' + name;
        return this.httpClient.get(url, {headers: HEADER, observe: 'body'})
          .toPromise().then((response: Response) => response).catch(this.handleError);
    }

    public deleteAffinityDocument(affinityId: any, idList: any) {
      const requestOptions = new RequestOptions(REQUEST_HEADER);
      const url = this.hostUrl + 'api/admin/delete/affinity/documents/affinityId';
      const requestObject = new Object();
      requestObject['idList'] = idList;
      return this.httpClient.post(url, requestObject, {headers: HEADER})
      .toPromise().then((response: Response) => response)
      .catch(this.handleError);
    }

  public getAdffinityList() {
    let url: any;
    url = this.hostUrl + 'api/admin/getaffinitylist';
    return this.httpClient.get(url, { headers: HEADER, observe: 'body' })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public saveAffinityProducts(affinityDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/save/affinity/lob';
    return this.httpClient
      .post(url, affinityDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAffinityLob(affinityId) {
    let url: any;
    url = this.hostUrl + 'api/admin/affinity/lobMapping/fetch/' + affinityId;
    return this.httpClient.get(url, { headers: HEADER, observe: 'body' })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public userMenuUpdates(affinityId,isHealth,isHome,isShop,isInboxPage){
    let url: any;
    url = this.hostUrl + 'api/user/lob/menu/' + affinityId+'?health='+isHealth+'&home='+isHome+'&shop='+isShop +'&inboxPage='+isInboxPage;
    return this.httpClient.get(url, { headers: HEADER, observe: 'body' })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }
    private handleError(error: any): Promise<any> {
        return Promise.reject(error);
      }
}