import { Component, OnInit, Inject, OnChanges, HostListener } from '@angular/core';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { USER_PROFILE, USER_AFFINITY, AFFINITY_NAME, AFFINITY_ID, LOGIN_DETAILS, LOGIN_USERNAME, UNSUCCESSFUL_LOGIN_COUNT, USER_PROFILE_DATA, BRANCH_CODE_INFO } from './../common/common.constants';
import { CONNECTION_FAILED, SERVER_ERROR, NO_SUCH_USER, WRONG_PASSWORD, NOT_FOUND, ALREADY_LOGGEDIN} from './../common/error.constants';
import { EmitterService } from 'src/services/EmitterService';
import { UserLoginVO } from 'src/dto/UserLoginVO';
import { UserPasswordResetVO } from 'src/dto/UserPasswordResetVO';
import { UserManagementServices } from 'src/services/user-management.services';
import { SelectService } from 'src/services/select.services';
import { AffinityService } from 'src/services/affinity.service';
import {ProposalServices } from 'src/services/proposal.services';
import {NavigationServices } from '../services/navigation.services';
import { trigger, state, style, animate, transition } from '@angular/animations';

@Component({
  selector: 'app-login',
  templateUrl: './login.html',
  animations: [
    trigger('focusAnimation', [
      state('focus', style({
      })),
      state('focusout', style({
      })),
      transition('* => focusout', animate('0.1s ease-out')),
      transition('* => focus', animate('0.1s ease-in')),
    ]),
  ]
})
export class LoginComponent implements OnInit, OnChanges {
  public errorList: Array<Object> = new Array<Object>();
  public action: string;
  public message: string;
  public errorMessage: string;
  public authData: UserLoginVO;
  public emailId: any;
  public passwordResetData: UserPasswordResetVO;
  public confirmPassword: string;
  public passwordChangeSuccess: boolean;
  public emailSuccess: boolean;
  public validationMessage: string;
  public usernameMessage: string;
  public passwordMessage: string;
  public confirmPasswordMessage: string;
  public enableSubmit: boolean;
  public userActivationFailure: boolean;
  public passwordActivationFailure: boolean;

  public userNameFieldState = 'focusout';
  public loginSubmitFlag = false;
  public serviceErrorMessage: string;
  public customerId: String;
  public createdThru: any;
  public ssoToken: any;
  public muthootBranchId: any;
  public muthootCrmLeadId: any;

  constructor(private router: Router,
    private route: ActivatedRoute,
    private authenticationService: AuthenticationService,
    private userManagementService: UserManagementServices,
    private selectService: SelectService,
    private affinityService: AffinityService, 
    private  proposalService: ProposalServices,
    private navigationServices: NavigationServices) {
  }
  ngOnInit() {    
    sessionStorage.clear();
    this.initialize();
    sessionStorage.setItem('LOGGEDIN_AFFINITY', undefined);
    this.route.queryParams.subscribe(data => {
      if (data['action']) {
        this.action = data['action'];
        if (this.action === 'resetPassword') {
          this.passwordResetData.userId = data['userId'];
           this.authenticationService.validatePasswordReset (this.passwordResetData.userId)
          .then(response => {
            const result =  Boolean(response['body']);
            if (result) {
              this.passwordActivationFailure = true;
            }
          }).catch(error => {
            this.passwordActivationFailure = true;
          });
        } else if (this.action === 'home') {
          console.log('*** Sahaj user ***');
            this.authenticateExternalUsers(data['encData']);
            //this.lgiDownMessage();
        }
      } else if (data['token']) {
        console.log('*** Muthoot customer ***');
        this.ssoToken = data['token'];
        this.customerId = data['customerid'];
        this.createdThru = data ['created_thru'];
        this.authenticateMuthootCustomer(this.ssoToken,this.createdThru,this.customerId);
       } else if(data['TokenID']) {
        console.log('*** Muthoot branch user ***');
        this.ssoToken = data['TokenID'];
        this.muthootBranchId = data['BranchCode'];
        this.muthootCrmLeadId = data ['crm_leadid'];
        this.authenticateMuthootBranchUser(this.ssoToken,this.muthootBranchId,this.muthootCrmLeadId);
       }
      else {
        this.action = 'login';
      }
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    EmitterService.get('SESSION_EXPIRED').subscribe(data => {
      this.errorMessage = 'Session Expired. Please Log-in again.';
    });

    EmitterService.get('SERVICE_DOWN').subscribe(data => {
        this.serviceErrorMessage = 'Service is temporarily unavailable. Please try again after some time!';
    });

    EmitterService.get('INVALID_TOKEN').subscribe(data => {
      this.serviceErrorMessage = 'Invalid Token. Please try again!';
  });
  EmitterService.get('LGI_DOWN_TIME').subscribe(data => {
    this.serviceErrorMessage = 'Liberty General Insurance policy booking service is down for maintenance';
});
  }

  ngOnChanges(changes: any) {
  }
  setUsernameFocus(data, action) {
    if (action === 'username') {
      this.userNameFieldState = data;
    }
  }

  public initialize() {
    this.authData = new UserLoginVO();
    this.authData.password = '';
    this.authData.userName = '';
    this.authData.smId= '';
    this.authData.smState = '';
    this.authData.ipAddress = '';
    this.authData.crmLeadId = '';
    this.authData.branchCode = '';
    this.emailId = '';
    this.message = '';
    this.errorMessage = '';
    this.passwordResetData = new UserPasswordResetVO();
    this.passwordResetData.userId = null;
    this.passwordResetData.password = '';
  }

  authenticateUsers() {
 
    if (this.enableSubmit) {
      this.loginSubmitFlag = true;
      this.authenticationService.authenticateUser(this.authData).then(response => {
        const loginData = response.body;
       

        if (response.status === 200) {
          sessionStorage.setItem('TEMPORAL_INDEX', String(new Date()));
          const token = response.headers.get('jsontoken');
          const profileData = response.body;
          if (token) {
            sessionStorage.setItem(USER_PROFILE, JSON.stringify(token));
            sessionStorage.setItem(USER_PROFILE_DATA, JSON.stringify(profileData));
            if(this.authData['userName'].toLowerCase()!=loginData['userName'].toLowerCase())
            {
              this.loginSubmitFlag = false;
              this.serviceDownMessage();    
              sessionStorage.removeItem(LOGIN_DETAILS);
            }
            this.navigationServices.validateUserDetails(loginData).then(data => {
            
            if(profileData['roleName'].toLowerCase() ==='branch user'){
              this.userManagementService.getBranchDetails(profileData['branchId']).then(branchData => {
                const branchCode = branchData['branchCode'];
                sessionStorage.setItem(BRANCH_CODE_INFO, branchCode);
            }); 
            }
            if (!profileData['isActive']) {
              this.loginSubmitFlag = false;
              this.validationMessage = 'Account disabled. Please contact Administrator';
            } else if (profileData['isLocked']) {
              this.loginSubmitFlag = false;
              this.validationMessage = 'Account locked. Please contact Administrator';
            } else if (profileData['roleName'] === 'System Administrator' || profileData['roleName'] === 'Underwriter') {
              this.userManagementService.getUserAffinity(profileData['userId']).then(userAffinity => {
                if (userAffinity['defaultAffinityID'] !== 0) {
                  const affinity = new Object();
                  this.selectService.fetchLookUpData('SelectAffinity', null).then(affinityList => {
                    for (let i = 0; i < affinityList.length; i++) {
                      if (String(userAffinity['defaultAffinityID']) === String(affinityList[i]['affinityId'])) {
                        affinity[AFFINITY_NAME] = affinityList[i]['affinityName'];
                        affinity['issueState'] = affinityList[i]['issueStateId'];
                        affinity['issuestateDesc'] = affinityList[i]['issueStateDescription'];
                        affinity['agentId'] = affinityList[i]['agentId'];
                        affinity['affinityLogo'] = affinityList[i]['affinityLogo'];
                        affinity['agentName'] =affinityList[i]['agentName'];
                        affinity['agentContactNumber'] =affinityList[i]['agentContactNumber'];
                        break;
                      }
                    }
                    affinity[AFFINITY_ID] = userAffinity['defaultAffinityID'];
                    const affinityString = JSON.stringify(affinity);
                    sessionStorage.setItem(USER_AFFINITY, affinityString);
                    // EmitterService.get('LOGIN_EVENT').emit(true);
                    this.router.navigateByUrl('welcome/(contentBody:landing)');
                  });
                } else {
                  this.router.navigateByUrl('welcome/(contentBody:affinity)');
                 // this.router.navigateByUrl('welcome/(contentBody:selectProduct)');
                }
              }).catch(error => {
                this.loginSubmitFlag = false;
                this.serviceDownMessage();
              //  this.validationMessage = 'Error occured in fetching user data';
              });
            } else {
              // Set Affinity Id here
              const affinity = new Object();
              affinity[AFFINITY_NAME] = 'MANAPPURAM';
              affinity[AFFINITY_ID] = '1';
              // User-Affinity Mapping
              this.userManagementService.getUserAffinity(profileData['userId']).then(data => {
                this.selectService.fetchLookUpData('SelectAffinity', null).then(affinityData => {
                  affinity[AFFINITY_ID] = data['affinityID'];
                  for (let i = 0; i < affinityData.length; i++) {
                    if (String(data['affinityID']) === String(affinityData[i]['affinityId'])) {
                      affinity[AFFINITY_NAME] = affinityData[i]['affinityName'];
                      affinity['issueState'] = affinityData[i]['issueStateId'];
                      affinity['issuestateDesc'] = affinityData[i]['issueStateDescription'];
                      affinity['agentId'] = affinityData[i]['agentId'];
                      affinity['affinityLogo'] = affinityData[i]['affinityLogo'];
                      affinity['agentName'] =affinityData[i]['agentName'];
                      affinity['agentContactNumber'] =affinityData[i]['agentContactNumber'];
                      const affinityString = JSON.stringify(affinity);
                      sessionStorage.setItem(USER_AFFINITY, affinityString);
                      // EmitterService.get('LOGIN_EVENT').emit(true);
                      this.router.navigateByUrl('welcome/(contentBody:landing)');
                      break;
                    }
                  }
                }).catch(error => {
                  this.loginSubmitFlag = false;
                  this.serviceDownMessage();
                //  this.validationMessage = 'Error occured in fetching user data';
                });
              }).catch(error => {
                this.loginSubmitFlag = false;
                this.serviceDownMessage();
               // this.validationMessage = 'Error occured in fetching user data';
              });
            }
            sessionStorage.removeItem(LOGIN_DETAILS);
          }).catch(error => {
            this.loginSubmitFlag = false;
            this.serviceDownMessage();
                  });
          } else {
            this.setErrorMessage('Invalid token!');
            this.loginSubmitFlag = false;
          }
        } else {
          if (response.status === 201) {
            this.loginSubmitFlag = false;
            this.validationMessage = 'Incorrect username or password';
            if (sessionStorage.getItem(LOGIN_DETAILS)) {
              const login_data = JSON.parse(sessionStorage.getItem(LOGIN_DETAILS));
              if (this.authData.userName === login_data[LOGIN_USERNAME]) {
                login_data[UNSUCCESSFUL_LOGIN_COUNT] = (Number(login_data[UNSUCCESSFUL_LOGIN_COUNT]) + 1);
                sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
                // Lock Account

                const userStatus = response.body;
                const lockedStatus = userStatus['isLocked'];
                const activeStatus = userStatus['isActive'];
                if (!activeStatus) {
                  this.validationMessage = 'Account disabled. Please contact Administrator';
                 } else if (lockedStatus) {
                   this.validationMessage = 'Account locked. Please contact Administrator';
                 }
                

                /*if (Number(login_data[UNSUCCESSFUL_LOGIN_COUNT]) > 2) {
                  this.authenticationService.lockAccount(login_data[LOGIN_USERNAME]).then(lockData => {
                    if (lockData.status === 208) {
                      this.loginSubmitFlag = false;
                      this.validationMessage = 'Account locked. Please contact Administrator';
                    }
                  }).catch(error => {
                    this.loginSubmitFlag = false;
                    if (error.status === 423) {
                      this.validationMessage = 'Incorrect username or password. Account locked';
                    } else if (error.status === 404) {
                      this.validationMessage = 'Incorrect username or password';
                    } else if (error.status === 208) {
                      this.validationMessage = 'Account locked. Please contact Administrator';
                    }
                  });
                } else {
                const userStatus = response.body;
                const lockedStatus = userStatus['isLocked'];
                const activeStatus = userStatus['isActive'];
              if (!activeStatus) {
                this.validationMessage = 'Account disabled. Please contact Administrator';
              } else if (lockedStatus) {
                this.validationMessage = 'Account locked. Please contact Administrator';
              }
                }*/
              } else {
                this.loginSubmitFlag = false;
                login_data[LOGIN_USERNAME] = this.authData.userName;
                login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
                sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
              }
            } else {
              const userStatus = response.body;
              const lockedStatus = userStatus['isLocked'];
              const activeStatus = userStatus['isActive'];
              if (!activeStatus) {
                this.validationMessage = 'Account disabled. Please contact Administrator';
              } else if (lockedStatus) {
                this.validationMessage = 'Account locked. Please contact Administrator';
              }
              this.loginSubmitFlag = false;
              const login_data = new Object();
              login_data[LOGIN_USERNAME] = this.authData.userName;
              login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
              sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
            }
          } else if (response.status === 202) {
            const profileData = response.body;
            const url = '?action=resetPassword&userId=' + profileData['userId'];
            this.navigateTo(url);
          } else if (response.status === 208) {
            this.validationMessage = 'Account locked. Please contact Administrator';
          }
        }
      })
        .catch(error => {
          this.loginSubmitFlag = false;
          if (error.status === 302) {
            this.validationMessage = 'Incorrect username or password';
            if (sessionStorage.getItem(LOGIN_DETAILS)) {
              const login_data = JSON.parse(sessionStorage.getItem(LOGIN_DETAILS));
              if (this.authData.userName === login_data[LOGIN_USERNAME]) {
                login_data[UNSUCCESSFUL_LOGIN_COUNT] = (Number(login_data[UNSUCCESSFUL_LOGIN_COUNT]) + 1);
                sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
              } else {
                login_data[LOGIN_USERNAME] = this.authData.userName;
                login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
                sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
              }
            } else {
              const login_data = new Object();
              login_data[LOGIN_USERNAME] = this.authData.userName;
              login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
              sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
            }
          } else if (error.status === CONNECTION_FAILED) {
            this.validationMessage = 'Could not connect';
          } else if (error.status === SERVER_ERROR) {
            this.validationMessage = 'Problem with username, Please try again!';
          } else if (error.status === NO_SUCH_USER) {
            this.validationMessage = 'Incorrect username or password';
          } else if (error.status === NOT_FOUND) {
            this.validationMessage = 'Incorrect username or password';
          } else if (error.status === WRONG_PASSWORD) {
            this.validationMessage = 'Incorrect username or password';
          } else if (error.status === ALREADY_LOGGEDIN) {
            this.validationMessage = 'User is already logged in!';
          }
          else {
            this.validationMessage = 'An error occured!';
          }
        });
    }
  }

  authenticateMuthootCustomer(token,createdThru,customerId) {
    this.authData.customerId=customerId;
    this.authData.token = token;
    let tokenDetails: Object = new Object();
    tokenDetails['retrievalJson'] = new Object();
    tokenDetails['retrievalJson']['methodName'] = 'CheckTokenStatus';
    tokenDetails['retrievalJson']['token'] = token;
    tokenDetails['retrievalJson']['customerId'] = customerId;
    tokenDetails['retrievalJson']['clientCode'] = '2';
    tokenDetails['retrievalJson']['apiVersion'] = '1';
    const reqData = JSON.stringify(tokenDetails);
    this.proposalService.muthoodCheckTokenStatus(reqData).then(data => {
      const tokenResponse = data['Response'];
      for (const res of tokenResponse) {
        if (res['ERROR_MSG'].toLowerCase() === 'success') {
    this.authenticationService.authenticateUser(this.authData).then(response => { 
      const token = response.headers.get('jsontoken');
      sessionStorage.setItem(USER_PROFILE, JSON.stringify(token));
      response['roleName'] = 'System Administrator';
      response['isMuthootCustomer'] = true;
      response['lastActivityDate'] =  response['body']['lastActivityDate'];
      const profileData = response;
      sessionStorage.setItem(USER_PROFILE_DATA, JSON.stringify(profileData));

      this.affinityService.getAdffinityList().then(data => {
        const affinity = new Object();
        for (let i = 0; i < data.length; i++) {
          if (data[i]['affinityName'].toLowerCase().startsWith('muthoot')) {
            affinity[AFFINITY_NAME] = data[i]['affinityName'];
            affinity['issueState'] = data[i]['issueStateId'];
            affinity['issuestateDesc'] = data[i]['issueStateDescription'];
            affinity['agentId'] = data[i]['agentId'];
            affinity['affinityLogo'] = data[i]['affinityLogo'];
            affinity['agentName'] = data[i]['agentName'];
            affinity['agentContactNumber'] = data[i]['agentContactNumber'];
            affinity[AFFINITY_ID] = data[i]['affinityId'];
            break;
          }
        }
        const affinityString = JSON.stringify(affinity);
        sessionStorage.setItem(USER_AFFINITY, affinityString);
        this.router.navigateByUrl('welcome/(contentBody:selectProduct)');
      }).catch(error => {

      });
    }).catch(error => {

    });
  } else {
    this.invalidToken();
  }
}
  }).catch(error =>{

  });
  }

  authenticateMuthootBranchUser(token,branchId,cremLeadId) {
    this.authData.branchCode = branchId;
    this.authData.token = token;
    this.authData.crmLeadId = cremLeadId;
    let tokenDetails: Object = new Object();
    tokenDetails['retrievalJson'] = new Object();
    tokenDetails['retrievalJson']['token'] = token;
    const reqData = JSON.stringify(tokenDetails);
    this.proposalService.muthoodBranchTokenStatus(reqData).then(data => {
      const tokenResponse = data['RetrieveResult']['Response'];
      for (const res of tokenResponse) {
      if (res['ERROR_MSG'].toLowerCase() === 'success') {
    this.authenticationService.authenticateUser(this.authData).then(response => { 
      const token = response.headers.get('jsontoken');
      sessionStorage.setItem(USER_PROFILE, JSON.stringify(token));
      response['body']['roleName'] = 'Branch User';
      response['roleName'] = 'Branch User';
      response['muthootBranchUser'] = true;
      response['roleId'] = 4;
      response['branchId'] = branchId;
      response['lastActivityDate'] =  response['body']['lastActivityDate'];
      const muthootBranchId = 'MUT' + branchId;
        this.userManagementService.getBranches(muthootBranchId).then(data => {
          for (let i=0 ; i<data.length; i++){
            response['branchId'] =  data[i].branchID;
            const profileData = response;
            sessionStorage.setItem(USER_PROFILE_DATA, JSON.stringify(profileData));
          }
        }).catch(error =>{
        });
      sessionStorage.setItem(BRANCH_CODE_INFO, 'MUT'+ branchId);

      this.affinityService.getAdffinityList().then(data => {
        const affinity = new Object();
        for (let i = 0; i < data.length; i++) {
          if (data[i]['affinityName'].toLowerCase().startsWith('muthoot')) {
            affinity[AFFINITY_NAME] = data[i]['affinityName'];
            affinity['issueState'] = data[i]['issueStateId'];
            affinity['issuestateDesc'] = data[i]['issueStateDescription'];
            affinity['agentId'] = data[i]['agentId'];
            affinity['affinityLogo'] = data[i]['affinityLogo'];
            affinity['agentName'] = data[i]['agentName'];
            affinity['agentContactNumber'] = data[i]['agentContactNumber'];
            affinity[AFFINITY_ID] = data[i]['affinityId'];
            break;
          }
        }
        const affinityString = JSON.stringify(affinity);
        sessionStorage.setItem(USER_AFFINITY, affinityString);
        this.router.navigateByUrl('welcome/(contentBody:landing)');
      //  break;
      }).catch(error => {

      });
    }).catch(error => {

    });
  } else if (res['ERROR_MSG'].toLowerCase() === 'invalid token'){
    this.invalidToken();
  } else {
    this.serviceErrorMessage = res['ERROR_MSG'] + '!. please try again';
  }
}
  }).catch(error =>{

  });
  }

  authenticateSahajUsers(token: any) {
    this.lgiDownMessage();
  }
  
  authenticateExternalUsers(token: any) {
    sessionStorage.setItem(USER_PROFILE, JSON.stringify(token));
  //  if (this.enableSubmit) {
      this.loginSubmitFlag = true;
      const authToken = JSON.parse(sessionStorage.getItem(USER_PROFILE));
      this.authenticationService.authenticateExternalUser(token).then(response => {
      //  if (response.status === 200) {
          sessionStorage.setItem('TEMPORAL_INDEX', String(new Date()));
          const profileData = response;
          if(profileData['roleName'].toLowerCase() ==='branch user'){
            this.userManagementService.getBranchDetails(profileData['branchId']).then(branchData => {
              const branchCode = branchData['branchCode'];
              sessionStorage.setItem(BRANCH_CODE_INFO, branchCode);
              }); 
            }
          if (token) {
            sessionStorage.setItem(USER_PROFILE_DATA, JSON.stringify(profileData));
            if (!profileData['isActive']) {
              this.loginSubmitFlag = false;
              this.validationMessage = 'Account disabled. Please contact Administrator';
            } else if (profileData['isLocked']) {
              this.loginSubmitFlag = false;
              this.validationMessage = 'Account locked. Please contact Administrator';
            } else if (profileData['roleName'] === 'System Administrator' || profileData['roleName'] === 'Underwriter') {
              this.userManagementService.getUserAffinity(profileData['userId']).then(userAffinity => {
                if (userAffinity['defaultAffinityID'] !== 0) {
                  const affinity = new Object();
                  this.selectService.fetchLookUpData('SelectAffinity', null).then(affinityList => {
                    for (let i = 0; i < affinityList.length; i++) {
                      if (String(userAffinity['defaultAffinityID']) === String(affinityList[i]['affinityId'])) {
                        affinity[AFFINITY_NAME] = affinityList[i]['affinityName'];
                        affinity['issueState'] = affinityList[i]['issueStateId'];
                        affinity['issuestateDesc'] = affinityList[i]['issueStateDescription'];
                        affinity['agentId'] = affinityList[i]['agentId'];
                        affinity['affinityLogo'] = affinityList[i]['affinityLogo'];
                        affinity['agentName'] =affinityList[i]['agentName'];
                        affinity['agentContactNumber'] =affinityList[i]['agentContactNumber'];
                        break;
                      }
                    }
                    affinity[AFFINITY_ID] = userAffinity['defaultAffinityID'];
                    const affinityString = JSON.stringify(affinity);
                    sessionStorage.setItem(USER_AFFINITY, affinityString);
                    // EmitterService.get('LOGIN_EVENT').emit(true);
                    this.router.navigateByUrl('welcome/(contentBody:landing)');
                  });
                } else {
                  this.router.navigateByUrl('welcome/(contentBody:affinity)');
                 // this.router.navigateByUrl('welcome/(contentBody:selectProduct)');
                }
              }).catch(error => {
                this.loginSubmitFlag = false;
                this.serviceDownMessage();
              //  this.validationMessage = 'Error occured in fetching user data';
              });
            } else {
              // Set Affinity Id here
              const affinity = new Object();
              // affinity[AFFINITY_NAME] = 'MANAPPURAM';
              // affinity[AFFINITY_ID] = '1';
              // User-Affinity Mapping
              this.userManagementService.getUserAffinity(profileData['userId']).then(data => {
                this.selectService.fetchLookUpData('SelectAffinity', null).then(affinityData => {
                  affinity[AFFINITY_ID] = data['affinityID'];
                  for (let i = 0; i < affinityData.length; i++) {
                    if (String(data['affinityID']) === String(affinityData[i]['affinityId'])) {
                      affinity[AFFINITY_NAME] = affinityData[i]['affinityName'];
                      affinity['issueState'] = affinityData[i]['issueStateId'];
                      affinity['issuestateDesc'] = affinityData[i]['issueStateDescription'];
                      affinity['agentId'] = affinityData[i]['agentId'];
                      affinity['affinityLogo'] = affinityData[i]['affinityLogo'];
                      affinity['agentName'] =affinityData[i]['agentName'];
                      affinity['agentContactNumber'] =affinityData[i]['agentContactNumber'];
                      const affinityString = JSON.stringify(affinity);
                      sessionStorage.setItem(USER_AFFINITY, affinityString);
                      if (profileData['isMuthootCustomer'] === true) {
                        this.router.navigateByUrl('welcome/(contentBody:selectProduct)');
                      } else {
                        this.router.navigateByUrl('welcome/(contentBody:landing)');
                      }
                      // EmitterService.get('LOGIN_EVENT').emit(true);
                      break;
                    }
                  }
                }).catch(error => {
                  this.loginSubmitFlag = false;
                  this.serviceDownMessage();
                //  this.validationMessage = 'Error occured in fetching user data';
                });
              }).catch(error => {
                this.loginSubmitFlag = false;
                this.serviceDownMessage();
               // this.validationMessage = 'Error occured in fetching user data';
              });
            }
            sessionStorage.removeItem(LOGIN_DETAILS);
          } else {
            this.setErrorMessage('Invalid token!');
            this.loginSubmitFlag = false;
          }
      //  }
        // } else {
        //   if (response.status === 201) {
        //     this.loginSubmitFlag = false;
        //     this.validationMessage = 'Incorrect username or password';
        //     if (sessionStorage.getItem(LOGIN_DETAILS)) {
        //       const login_data = JSON.parse(sessionStorage.getItem(LOGIN_DETAILS));
        //       if (this.authData.userName === login_data[LOGIN_USERNAME]) {
        //         login_data[UNSUCCESSFUL_LOGIN_COUNT] = (Number(login_data[UNSUCCESSFUL_LOGIN_COUNT]) + 1);
        //         sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
        //         // Lock Account
        //         if (Number(login_data[UNSUCCESSFUL_LOGIN_COUNT]) > 2) {
        //           this.authenticationService.lockAccount(login_data[LOGIN_USERNAME]).then(lockData => {
        //             if (lockData.status === 208) {
        //               this.loginSubmitFlag = false;
        //               this.validationMessage = 'Account locked. Please contact Administrator';
        //             }
        //           }).catch(error => {
        //             this.loginSubmitFlag = false;
        //             if (error.status === 423) {
        //               this.validationMessage = 'Incorrect username or password. Account locked';
        //             } else if (error.status === 404) {
        //               this.validationMessage = 'Incorrect username or password';
        //             } else if (error.status === 208) {
        //               this.validationMessage = 'Account locked. Please contact Administrator';
        //             }
        //           });
        //         } else {
        //         const userStatus = response.body;
        //         const lockedStatus = userStatus['isLocked'];
        //         const activeStatus = userStatus['isActive'];
        //       if (!activeStatus) {
        //         this.validationMessage = 'Account disabled. Please contact Administrator';
        //       } else if (lockedStatus) {
        //         this.validationMessage = 'Account locked. Please contact Administrator';
        //       }
        //         }
        //       } else {
        //         this.loginSubmitFlag = false;
        //         login_data[LOGIN_USERNAME] = this.authData.userName;
        //         login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
        //         sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
        //       }
        //     } else {
        //       const userStatus = response.body;
        //       const lockedStatus = userStatus['isLocked'];
        //       const activeStatus = userStatus['isActive'];
        //       if (!activeStatus) {
        //         this.validationMessage = 'Account disabled. Please contact Administrator';
        //       } else if (lockedStatus) {
        //         this.validationMessage = 'Account locked. Please contact Administrator';
        //       }
        //       this.loginSubmitFlag = false;
        //       const login_data = new Object();
        //       login_data[LOGIN_USERNAME] = this.authData.userName;
        //       login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
        //       sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
        //     }
        //   } else if (response.status === 202) {
        //     const profileData = response.body;
        //     const url = '?action=resetPassword&userId=' + profileData['userId'];
        //     this.navigateTo(url);
        //   } else if (response.status === 208) {
        //     this.validationMessage = 'Account locked. Please contact Administrator';
        //   }
        // }
      })
        .catch(error => {
          this.loginSubmitFlag = false;
          if (error.status === 302) {
            this.validationMessage = 'Incorrect username or password';
            if (sessionStorage.getItem(LOGIN_DETAILS)) {
              const login_data = JSON.parse(sessionStorage.getItem(LOGIN_DETAILS));
              if (this.authData.userName === login_data[LOGIN_USERNAME]) {
                login_data[UNSUCCESSFUL_LOGIN_COUNT] = (Number(login_data[UNSUCCESSFUL_LOGIN_COUNT]) + 1);
                sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
              } else {
                login_data[LOGIN_USERNAME] = this.authData.userName;
                login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
                sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
              }
            } else {
              const login_data = new Object();
              login_data[LOGIN_USERNAME] = this.authData.userName;
              login_data[UNSUCCESSFUL_LOGIN_COUNT] = 1;
              sessionStorage.setItem(LOGIN_DETAILS, JSON.stringify(login_data));
            }
          } else if (error.status === CONNECTION_FAILED) {
            this.validationMessage = 'Could not connect';
          } else if (error.status === SERVER_ERROR) {
            this.validationMessage = 'Problem with username, Please try again!';
          } else if (error.status === NO_SUCH_USER) {
            this.validationMessage = 'Incorrect username or password';
          } else if (error.status === NOT_FOUND) {
            this.validationMessage = 'Incorrect username or password';
          } else if (error.status === WRONG_PASSWORD) {
            this.validationMessage = 'Incorrect username or password';
          } else {
            this.validationMessage = 'An error occured!';
          }
        });
  //  }
  }

  public requestUsername() {
    if (this.enableSubmit) {
      this.authenticationService.forgotUsername(this.emailId).then(data => {
        const responseData = data.body;
        if (responseData !== undefined) {
          if (responseData.status === 'User not Activated') {
            this.validationMessage = 'User is not activated yet. Please contact Administrator';
          } else if (responseData.status === 'User Locked') {
            this.validationMessage = 'Account locked. Please contact Administrator';
          } else if (responseData.status === 'User Disabled') {
            this.validationMessage = 'Account disabled. Please contact Administrator';
          } else {
            this.validationMessage = '';
            this.emailSuccess = true;
          }
        }
      }).catch(error => {
        if (error.status === 404) {
          this.validationMessage = 'Email ID not found';
        } else {
          this.validationMessage = 'Unknown error';
        }
      });
    }
  }

  public requestPasswordChange() {
    if (this.enableSubmit) {
      this.authenticationService.forgotPassword(this.emailId).then(data => {
        const responseData = data.body;
        if (responseData !== undefined) {
          if (responseData.status === 'User not Activated') {
            this.validationMessage = 'User is not activated yet. Please contact Administrator';
          } else if (responseData.status === 'User Locked') {
            this.validationMessage = 'Account locked. Please contact Administrator';
          } else if (responseData.status === 'User Disabled') {
            this.validationMessage = 'Account disabled. Please contact Administrator';
          } else {
            this.validationMessage = '';
            this.emailSuccess = true;
          }
        }
      }).catch(error => {
        if (error.status === 404) {
          this.validationMessage = 'Email ID not found';
        }
        if (error.status === 503) {
          this.validationMessage = 'Password reset limit is exceeded';
        } else {
          this.validationMessage = 'Unknown error';
        }
      });
    }
  }

  public resetPasswordRequest() {
    if (this.enableSubmit) {
      this.authenticationService.resetPassword(this.passwordResetData.password, this.passwordResetData.userId)
        .then(response => {
          const body = response.body;
          if (response.status === 200) {
            if (String(body['status']) === String('User Activation Success') || String(body['status']) === String('Pwd Activation Success')) {
              this.validationMessage = '';
              this.passwordChangeSuccess = true;
            } else if (response.status === 404 || !response.body) {
              this.passwordActivationFailure = true;
            } else if (String(body['status']) === String('User Activation Failed')) {
              this.userActivationFailure = true;
            } else if (String(body['status']) === String('Pwd Activation Failed')) {
              this.passwordActivationFailure = true;
            }
          }
        })
        .catch(error => {
          const body = error.body;
          if (error.status === 404 || !error.body) {
            this.passwordActivationFailure = true;
          } else if (String(body['status']) === String('User Activation Failed')) {
            this.userActivationFailure = true;
          } else if (String(body['status']) === String('Pwd Activation Failed')) {
            this.passwordActivationFailure = true;
          } else {
            this.validationMessage = 'The password could not be changed';
          }
        });
    }
  }

  public validate() {
    this.message = '';
    this.errorMessage = '';
    if (this.action === 'login') {
      const unameResult = /^[a-zA-Z0-9]*$/.test(this.authData.userName);
      const passwordResult = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(this.authData.password);
      const result = unameResult && passwordResult;
      if (unameResult) {
        this.validationMessage = '';
        this.enableSubmit = true;
      } else {
        this.validationMessage = 'Incorrect username or password';
        this.enableSubmit = false;
      }
      if (!this.authData.password) {
        this.enableSubmit = false;
      }
      if (!this.authData.userName) {
        this.enableSubmit = false;
      }
    } else if (this.action === 'forgotUsername' || (this.action === 'forgotPassword')) {
      this.validationMessage = '';
      const notValidEmail: String = this.emailId.match(/[^0-9a-zA-Z\._@\-]/g);
      if (notValidEmail && notValidEmail.length >= 1) {
        this.validationMessage = 'Enter Valid Email ID';
      } else {
        this.validationMessage = '';
      }
      if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.emailId)) {
        this.validationMessage = '';
        this.enableSubmit = true;
      } else {
        this.enableSubmit = false;
      }
    } else if (this.action === 'resetPassword') {

      this.validationMessage = '';
      const notEmployeeCode = this.passwordResetData.password.match(/[^0-9a-zA-Z]/g);
      if (notEmployeeCode && notEmployeeCode.length >= 1) {
        this.validationMessage = 'Enter a valid password';
      } else {
        this.validationMessage = '';
      }

      if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(this.passwordResetData.password))) {
        // this.validationMessage = 'Enter a valid password';
        this.enableSubmit = false;
      } else if (this.passwordResetData.password !== this.confirmPassword) {
        this.validationMessage = '';
        if (this.passwordResetData.password && this.confirmPassword) {
          // this.validationMessage = 'Please enter the same password in both fields.';
        }
        this.enableSubmit = false;
      } else {
        this.validationMessage = '';
        this.enableSubmit = true;
      }
    }

    if (this.passwordResetData.password && this.confirmPassword && (this.passwordResetData.password.length <= this.confirmPassword.length) && (this.passwordResetData.password !== this.confirmPassword)) {
      this.validationMessage = 'Please enter the same password in both fields';
    }
  }

  public emailBlur() {
    if (/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(this.emailId)) {
      this.validationMessage = '';
      this.enableSubmit = true;
    } else {
      this.validationMessage = 'Enter Valid Email ID';
      this.enableSubmit = false;
    }
    if (!this.emailId) {
      this.validationMessage = '';
    }
  }

  public passwordBlur() {
    // /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{6,}$/
    if (!(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/.test(this.passwordResetData.password))) {
      this.validationMessage = 'Enter a valid password';
    } else {
      this.validationMessage = '';
      if (this.passwordResetData.password && this.confirmPassword && (this.passwordResetData.password !== this.confirmPassword)) {
        this.validationMessage = 'Please enter the same password in both fields';
      }
    }
    if (!this.passwordResetData.password && !this.confirmPassword) {
      this.validationMessage = '';
    }
  }

  public navigateTo(location: string) {
    this.initialize();
    this.router.navigateByUrl(location);
  }
  public navigateToLanding() {
    this.router.navigateByUrl('welcome/(contentBody:landing)');
  }
  public setErrorMessage(message: string) {
    this.message = '';
    this.errorMessage = message;
  }
  public setMessage(message: string) {
    this.errorMessage = '';
    this.message = message;
  }
  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      },
    );
    this.router.navigateByUrl('');
  }

  public invalidToken() {
    setTimeout(
      function () {
        EmitterService.get('INVALID_TOKEN').emit(true);
      },
    );
   // this.router.navigateByUrl('');
  }

  public lgiDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('LGI_DOWN_TIME').emit(true);
      },
    );
   // this.router.navigateByUrl('');
  }
  keyPressEnter(event){
if(event.keyCode == 13) {
  this.authenticateUsers()
} }
  @HostListener('window:keyup', ['$event'])
  keyEvent(event: KeyboardEvent) {
    if (event.keyCode == 13) {
      this.authenticateUsers()
    }
  }

}
