import { ViewChild, ElementRef, HostListener, AfterViewInit, ViewChildren } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { MAIN_PAGE_PART_URL, LANDING, NEW_AFFINITY, AFFINITY_NAME, AFFINITY_ID, USER_AFFINITY, USER_PROFILE, USER_PROFILE_DATA } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { BranchCreationVO } from 'src/dto/BranchCreationVO';
import { CODE, DESCRIPTION } from 'src/common/common.constants';
import { ProposalServices } from 'src/services/proposal.services';
import { SelectComponent } from 'src/select/select-component';
import { UserManagementServices } from 'src/services/user-management.services';
import { SelectService } from 'src/services/select.services';
import { TextBoxComponent } from 'src/textbox/text-box.component';



@Component({
  selector: 'app-branchcreate',
  templateUrl: './branch.html',

})
export class BranchComponent implements OnInit {
  @ViewChild('contentBody') contentBodyRef: ElementRef;
  @ViewChildren(TextBoxComponent) private textFields: any;
  @ViewChild('formWrapper') formWrapperRef: ElementRef;
  public branchDetails: BranchCreationVO = new BranchCreationVO();
  public enableSubmit: Boolean = false;
  public isSuccess: Boolean = false;
  public loadingmessage: String = '';
  public isBranchCodeExist: Boolean = false;
  public branchCodeErrorMessage = '';
  public hrLeftMargin;
  public hrWidth;
  @ViewChildren(SelectComponent) private selectFields: any;
  constructor(private userManagementService: UserManagementServices,
    private router: Router) {

  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);
  }


  ngAfterViewInit() {
    this.onResize();
  }
  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    EmitterService.get('ENABLE_MENU').emit(true);
  }
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  public branchValidation(branchCode:any){
    this.branchCodeErrorMessage = '';
    this.branchDetails.branchID ='';
    this.branchDetails.branchName ='';
    this.branchDetails.branchArea ='';
    this.branchDetails.branchRegion ='';
    this.branchDetails.branchStateId ='';
    this.branchDetails.branchDistrictId = '';
    this.isBranchCodeExist = false;
      this.userManagementService.getValidBranchCode(branchCode).then(branchData => {
        if (branchData !== undefined && branchData['active'] !== false) {
          this.isBranchCodeExist = true;
          this.branchCodeErrorMessage = 'Branch Code already exists!.';
          this.branchDetails.branchID = branchData['branchID'];
          this.branchDetails.branchName = branchData['branchName'];
          this.branchDetails.branchArea = branchData['branchArea'];
          this.branchDetails.branchRegion = branchData['branchRegion'];
          this.branchDetails.branchStateId = branchData['branchStateId'];
          if(branchData['branchDistrictId'] !== undefined) {
            this.branchDetails.branchDistrictId = branchData['branchDistrictId'];
          }
        }
        }); 
    
  }

  private outSidevalidate() {
    let textResult: any = false;
    let selectResult: any = false;
    let hasTextField: Boolean = false;
    let hasSelectField: Boolean = false;
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        break;
      }
    }
    for (const selectField of this.selectFields['_results']) {
      hasSelectField = true;
      const input: SelectComponent = selectField;
      selectResult = input.onBlurMethod(false);
      if (!selectResult) {
        break;
      }
    }
    const result: any =
      (textResult || !hasTextField) &&
      (selectResult || !hasSelectField);
    if (result) {
      this.enableSubmit = true;
      return true;

    } else {
      this.enableSubmit = false;
      return false;
    }

  }

  public saveBranch() {
    this.loadingmessage = 'Please Wait...';
    const branchDetails: Object = new Object();
    branchDetails['branchResource'] = new Object();
    branchDetails['branchResource'] = this.branchDetails;
    const branchDetailsJson = JSON.stringify(branchDetails);
    this.userManagementService.saveBranch(branchDetailsJson).then(data =>{
      this.loadingmessage = '';
      this.branchCodeErrorMessage = '';
      this.enableSubmit = false;
      this.isSuccess = true;
      this.topFunction();
    }).catch(error =>{
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });
  }

  createBranch(){
   this.router.navigateByUrl('welcome/(contentBody:branchConfig)');
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      }
    );
    this.router.navigateByUrl('');
  }
}
