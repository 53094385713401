import { Component, OnInit } from '@angular/core';
import { EmitterService } from 'src/services/EmitterService';
import {
  DESCRIPTION,
  CODE,
  USER_NAME,
  NAME,
  BRANCH,
  BRANCH_NAME,
  BRANCH_CODE,
  USER_AFFINITY,
  AFFINITY_ID,
  PRODUCT_CODE
} from 'src/common/common.constants';
import { ResourceService } from '../services/resource.service';
import { ResourceVO } from '../dto/ResourceVO';
import { Router, ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-resources',
  templateUrl: './resources.component.html',
  styleUrls: ['./resources.component.css']
})
export class ResourcesComponent implements OnInit {

  public resourceOption: String = 'affinity';
  public errorMessage: any;
  public affinityDocumentList: Array<Object> = new Array<Object>();
  public columnsData: Array<Object> = new Array<Object>();
  public actionColumnsData: Array<Object> = new Array<Object>();
  public actionColumnsDataProd: Array<Object> = new Array<Object>();
  public affinityData: Array<Object> = new Array<Object>();
  public grid: Boolean = false;
  public affinitydocs: any;
  public productDocs: any;
  public affinityId: any;
  public getProductDetails: any;
  public productsList: any;
  public resourceProductsList: Array<Object> = new Array<Object>();
  public productDocGrid: Boolean = false;
  public displayProdName: Array<Object> = new Array<Object>();
  public productData: Array<Object> = new Array<Object>();
  public resourcesVO: ResourceVO = new ResourceVO();
  public productIds: any;
  public totalRows: any;
  public myMap: Map<Number, String>;
  public actionFailedFlag: Boolean = false;
  public actionFailedMessage = '';
  public blob: any;
  public displayedAdditionalDocuments: any;
  public additionalDocumentCount: any;

  // 22/06/2020 
  public productRateSheetGrid: Boolean = false;
  public rateSheetDocs: any;
  public displayRateSheetDocuments: any;
  public rateSheetCount: any;

  constructor(private resourceService: ResourceService, private router: Router) {
  }

  ngOnInit() {
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.affinityId = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    new Promise((resolve, reject) => {
      this.prepareActionColumns();
      this.prepareColumns();
      resolve(1000);
    }).then(data => {
      this.getAffinityDocuments();
    });
  }

  public resourceOptionChange(resourceType: any) {
    this.productIds = [];
    this.displayProdName = undefined;
    if (this.resourceOption === 'affinity') {
      this.getAffinityDocuments();
    } else {
      if (this.resourceOption === 'products') {
        this.productDocs = [];
        this.rateSheetDocs = [];
        this.displayProdName = undefined;
        this.resourcesVO.productId = undefined;
        this.getProductNamesandIds();
      }
    }
  }

  public getProductNamesandIds() {
    this.resourceService.getProductsIds(this.affinityId['id']).then(data => {
      this.productIds = data;
      this.resourceService.getProductNames(this.productIds).then(data1 => {
        this.myMap = data1;
        Object.keys(this.myMap).length;
        const productsize = Object.keys(this.myMap).length;
        this.displayProdName = [];
        for (let i = 0; i < Object.entries(this.myMap).length; i++) {
          const obj: Object = new Object();
          obj['label'] = Object.entries(this.myMap)[i][1];
          obj['value'] = Object.entries(this.myMap)[i][0];
          this.displayProdName.push(obj);
        }
      }).catch(error => {
        this.errorMessage = 'error occured while fetching product names';
      });
    }).catch(error => {
      this.errorMessage = 'error occured while fetching product service';
    });
  }

  public prepareColumns() {
    const obj2: Object = new Object();
    obj2['colName'] = 'Document Name';
    this.columnsData.push(obj2);
  }

  public getAffinityDocuments() {
    this.productDocs = [];
    this.resourceService.getAffinityDocs(this.affinityId['encodedAffinityId']).then(data => {
      this.affinitydocs = data;
      this.grid = true;
      const produtDodc: any = data;
      for (const additionalDoc of produtDodc) {
        const obs = new Object();
        obs['Document Name'] = additionalDoc['affinityDocumentName'];
        this.productDocs.push(obs);
      }
      this.initializeDocumentGrid();
      // this.prepareRows();
    })
      .catch(error => {
        this.errorMessage = 'error occured while fetching affinity documents';
      });
  }

  public displayGrid(productId) {
    this.affinityId = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    if (this.resourcesVO.productId !== undefined) {
      this.productDocs = [];
      this.rateSheetDocs = [];
      this.resourceService.getProductDocuments(productId).then(data => {
        const produtDodc: any = data;
        for (const additionalDoc of produtDodc) {
          const obs = new Object();
          obs['Document Name'] = additionalDoc['documentName'];
          this.productDocs.push(obs);
        }
        this.productDocGrid = true;
        // this.prepareRows();
        this.initializeDocumentGrid();
        this.prepareActionColumnsProducts();
      });
       this.resourceService.getProductRateSheetList(productId).then(data => {
         const rateSheetList: any = data;
         for (let i = 0; i < rateSheetList.length; i++) {
           const obs = new Object();
           obs['Document Name'] = rateSheetList[i]['documentName'];
           this.rateSheetDocs.push(obs);
         }
         this.productRateSheetGrid = true;
         //   // this.prepareRows();
         this.initializeRateSheetDocumentGrid();
         this.prepareActionColumnsProducts();
       });
    } else {
      this.productDocGrid = false;
    }
  }

  public prepareRows() {
    this.productDocs = [];
    if (this.resourceOption === 'affinity') {
      if (this.affinitydocs) {
        for (const data of this.affinitydocs) {
          const obj: Object = new Object();
          this.totalRows++;
          obj['Document Name'] = data['affinityDocumentName'];
          this.productDocs.push(obj);
        }
        this.initializeDocumentGrid();
      }
    } else if (this.resourceOption === 'products') {
      if (this.productDocs) {
        this.initializeDocumentGrid();
        this.initializeRateSheetDocumentGrid();
      }
    }
  }

  public additionalGridService(offset: any, size: any) {
    const totalRecords = this.productDocs.length;
    if (totalRecords > 0) {
      const startIndex = offset;
      const endIndex = (offset + size) > totalRecords ? totalRecords : offset + size;
      const resultArray = this.productDocs.slice(startIndex, endIndex);
      const responseObject = new Object();
      responseObject['totalRecords'] = totalRecords;
      responseObject['documentList'] = resultArray;
      return responseObject;
    } else {
      const responseObject = new Object();
      responseObject['totalRecords'] = 0;
      responseObject['documentList'] = [];
      return responseObject;
    }
  }
  public rateSheetGridService(offset: any, size: any) {
    const totalRecords = this.rateSheetDocs.length;
    if (totalRecords > 0) {
      const startIndex = offset;
      const endIndex = (offset + size) > totalRecords ? totalRecords : offset + size;
      const resultArray = this.rateSheetDocs.slice(startIndex, endIndex);
      const responseObject = new Object();
      responseObject['totalRecords'] = totalRecords;
      responseObject['documentList'] = resultArray;
      return responseObject;
    } else {
      const responseObject = new Object();
      responseObject['totalRecords'] = 0;
      responseObject['documentList'] = [];
      return responseObject;
    }
  }
  public showDocumentGrid(data: any) {
    const size = data['NumberOfRecords'];
    const offset = data['NumberOfRecords'] * (data['PageNumber']);
    const responseObject = this.additionalGridService(offset, size);
    this.displayedAdditionalDocuments = responseObject['documentList'];
    this.additionalDocumentCount = responseObject['totalRecords'];
  }

  public showRideSheetDocumentGrid(data: any) {
    const size = data['NumberOfRecords'];
    const offset = data['NumberOfRecords'] * (data['PageNumber']);
    const responseObject = this.rateSheetGridService(offset, size);
    this.displayRateSheetDocuments = responseObject['documentList'];
    this.rateSheetCount = responseObject['totalRecords'];
  }

  public initializeDocumentGrid() {
    const size = 10;
    const offset = 0;
    const responseObject = this.additionalGridService(offset, size);
    this.displayedAdditionalDocuments = responseObject['documentList'];
    this.additionalDocumentCount = responseObject['totalRecords'];
  }
  public initializeRateSheetDocumentGrid() {
    const size = 10;
    const offset = 0;
    const responseObject = this.rateSheetGridService(offset, size);
    this.displayRateSheetDocuments = responseObject['documentList'];
    this.rateSheetCount = responseObject['totalRecords'];
  }
  public peformevent($event) {
    const extension: String = $event['Document Name'];
    this.resourceService.downloadAfftinityDocs(this.affinityId['id'], $event['Document Name']).then(data => {
      const parsedResponse = data.blob();
      if (extension.endsWith('.pdf')) {
        this.blob = new Blob([parsedResponse], {
          type: 'application/pdf'
        });
      } else {
        this.blob = new Blob([parsedResponse]);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(this.blob, $event['Document Name']);
      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(this.blob);
        a.download = $event['Document Name'];
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    }).catch(error => {
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });
  }

  public peformeventprod($event) {
    const extension: String = $event['Document Name'];
    this.resourceService.downloadProductDocs(this.affinityId['id'], $event['Document Name'], this.resourcesVO.productId).then(data => {
      const parsedResponse = data.blob();
      if (extension.endsWith('.pdf')) {
        this.blob = new Blob([parsedResponse], {
          type: 'application/pdf'

        });
      } else {
        this.blob = new Blob([parsedResponse]);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(this.blob, $event['Document Name']);

      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(this.blob);
        a.download = $event['Document Name'];
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }).catch(error => {
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });

  }
  public peformeventarateSheet($event) {
    const extension: String = $event['Document Name'];
    this.resourceService.downloadProductRateSheetDocs(this.affinityId['id'], $event['Document Name'], this.resourcesVO.productId).then(data => {
      const parsedResponse = data.blob();
      if (extension.endsWith('.pdf')) {
        this.blob = new Blob([parsedResponse], {
          type: 'application/pdf'

        });
      } else {
        this.blob = new Blob([parsedResponse]);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(this.blob, $event['Document Name']);

      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(this.blob);
        a.download = $event['Document Name'];
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }).catch(error => {
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });

  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  public prepareActionColumns() {
    this.actionColumnsData.push('DOWNLOAD');
  }

  public prepareActionColumnsProducts() {
    this.actionColumnsDataProd = [];
    this.actionColumnsDataProd.push('DOWNLOAD');
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      });
    this.router.navigateByUrl('');
  }

  public downloadClaimForm() {
    const extension: String = 'ClaimForm.pdf';
    this.resourceService.downloadClaimForm().then(data => {
      const parsedResponse = data.blob();
      if (extension.endsWith('.pdf')) {
        this.blob = new Blob([parsedResponse], {
          type: 'application/pdf'

        });
      } else {
        this.blob = new Blob([parsedResponse]);
      }
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(this.blob, 'ClaimForm.pdf');

      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(this.blob);
        a.download = 'ClaimForm.pdf';
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }).catch(error => {
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });

  }

}
