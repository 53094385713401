import { BeneficiaryVO } from './BeneficiaryVO';
import { CommunicationVO } from './CommunicationVO';
import { QuestionVO } from './QuestionVO';
import { PremiumDetailsVO } from './PremiumDetailsVO';
import { CustomerDetailsVO } from './CustomerDetailsVO';
import { RiskLocationVO } from './RiskLocationVO';
import { ApplianceVO } from './ApplianceVO';
import { PaymentDetailsVO } from './PaymentDetailsVO';

export class ProposalVO {
  public paymentDetails: PaymentDetailsVO;
  public policyTypeId: String;
  public planId: String;
  public policyStarDate: any;
  public policyStartDate: any;
  public policyEnDate: any;
  public policyEndDate: any;
  public productId: String;
  public policyStatusId: Number;
  public branchCode: any;
  public certificateNo: String;
  public lastUpdatedDate: Date;
  public lastUpdatedBy: String;
  public customerId: String;
  public paymentOption: String;
  public sumInsuredId: String;
  public sumInsured: String;
  public policyTerm: any;
  public policyTermId: any;
  public riskStartDate: Date;
  public blankDatesInception: Date;
  public backDatedInceptionRange: Number;
  public policyNumberingFormat: String;
  public employeeId: String;
  public affinityId: Number;
  public employeeCode: String;
  public employeeName: String;
  // public customerID: String;
  public gstNo: String;
  public beneficiaryList: Array<BeneficiaryVO>;
  public communicationDetails: CommunicationVO;
  public riskLocationDetails: RiskLocationVO;
  public premiumDetails: PremiumDetailsVO;
  public customerDetails:  CustomerDetailsVO;
  public productAnswers: Array<QuestionVO>;
  public applianceDetails: Array<ApplianceVO>;
  public masterPolicyNo: Number;
  public questionsList: Array<QuestionVO>;
  public policyTypeName:  String;
  public planTypeName: String;
  public policyTypeDescription:  String;
  public planDescription: String;
  public dob: any = [];
  public policyId: Number;
  public covernoteNo: any;
  public issueStateId: Number;
  public issueState: Boolean;
  public endorsementReason: any;
  public isRiskAddress: Number;
  public actionType: String;
  public specifyReason: any;
  public isRetrieve: Boolean = false;
  public policyTermDescription: String;
  public cessIndicator: any = false;
  public placeOfSupply: any;
  public policyIssueDate: any;
  public branchName: any;
  public agentId: any;
  public branchId: any;
  // agent name, agent contact number and logo changes
  public agentName: any;
  public agentContactNumber: any;
  public affinityLogo: any;
  // new fileds for exisiting affinities
  public previousCertificateNo: any;
  public affinityName: any;
  // Verify OTP
  public otp: String;
  // Sahaj parameters
  public affinityAuthToken: any;
  public crmLeadId: String;
  public smId: String;
  public ssoTokenUser: any;
  public isCustomerProposal: any;
  public isMuthootBranchUser: String;
  public endorsementTypeDesc: String;
  public policyIdEncrypt: any;

  public panNumber: any;
  public aadharNumber: any;
  public ckycNumber: any;
  public dateOfBirth: any;
  public ckycReferenceId: any;
  public aryaToken: any;
  public aryaProposalId: any;
  public idType: any;
  public reqId: any;

}
