import { OnInit, EventEmitter, Output, OnChanges, AfterViewChecked, AfterContentInit, ChangeDetectorRef } from '@angular/core';
import { Component, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmitterService } from 'src/services/EmitterService';
import { USER_PROFILE_DATA } from 'src/common/common.constants';
@Component({
    selector: 'app-grid',
    templateUrl: './grid.component.html',
})
export class GridComponent implements OnInit, OnChanges, AfterViewChecked, AfterContentInit {

    @Input() Data: Array<Object> = undefined;
    @Input() CompleteData: any = [];
    @Input() actioncols: any = [];
    @Input() actioncolsHeaderNameFirst: any;
    @Input() actioncolsHeaderNameSecond: any;
    @Input() actionDocCols: any = [];
    @Input() ActionData: any = [];
    @Input() Columns: any = []; // Name of the coulmns to display / order
    @Input() TotalRows: any = 0; // Total number of rows for paging
    @Input() PageSize: any = 25;
    @Input() PageIndex: any = 0; // To control the start page index
    @Input() SubColumns: Array<Object> = new Array<Object>();
    @Input() isIssued: Boolean;
    @Input() newProducts: Boolean = false;
    @Input() isRetrieve: Boolean;
    @Output() clickActionEvent: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() clickRowEvent: EventEmitter<Object> = new EventEmitter<Object>();
    @Output() pagenationDetails: EventEmitter<any> = new EventEmitter();
    @Output() searchPagenationDetails: EventEmitter<any> = new EventEmitter();
    @Input() disableEdit: boolean;
    @Input() public noPagination: Boolean = false;
    @Input() maxCellWidthpx: Number;
    @Input() maxVisibleRows: Number;
    @Input() disableSorting: Boolean = false;
    @Input() isReport: Boolean; 
    //  @Output() pagePerRecords: any;
    public totalPages: any = 0;
    public currentpage = 1;
    public currentpageperview = 10;
    public pagesArray = [10, 20, 30, 40, 50];
    public pageNumberArray = [];
    public pagePerView;
    public columnSize = '';
    public selectedRow: any;
    public selectedRowsDetails: Object;
    public startIndex = 1;
    public endIndex = 0;
    public numbers: any;
    public displayNumbers = [];
    public resultsPerPage: any;
    public noOfPages: number;
    public CompleteDataCopy: any[];
    public sortBy = '';
    public sortOrder = '';
    public display = 'none';
    public inSearch: boolean;
    public dispCount = 10;
    public isNextNext: Boolean = true;
    public isPrevious: Boolean =  false;
    isImuthootAffinity: boolean;
    constructor(private router: Router, private route: ActivatedRoute, public cdr: ChangeDetectorRef) {
    }
    ngOnInit() {
        this.getNoOfPages();
        const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
if(userdetails['isMuthootCustomer'] === true){
      this.isImuthootAffinity = true;
    }
    }
    ngAfterViewChecked() {
        this.getNoOfPages();
    }

    ngAfterContentInit() {
        this.setcurrentpage(0);
    }

    toggle(value) {
        value['toggleDropDown'] = !value['toggleDropDown'];
        value['showsubrow'] = !value['showsubrow'];
    }

    toggelDrop(data) {
        data['hidecolumn'] = !data['hidecolumn'];
    }

    ngOnChanges(changes: any) {
        this.columnSize = (this.Columns ? this.Columns.length : 0) + '';
        this.getDisplayPagesForCurrentPage(this.currentpage);
    }

    public setClickedRow(index, object) {
        this.selectedRow = index;
        this.selectedRowsDetails = object;
        this.clickRowEvent.emit();
    }
    public clickAction(action, object, index) {
        object['Action'] = action;
        object['index'] = index;     
        this.clickActionEvent.emit(object);
    }

    public clickActions(action, index, object) {
        object['Action'] = action;
        object['Index'] = index;
        this.clickActionEvent.emit(object);
    }

    public onchangePageNumber(data: any) {

    }

    public onchangeDataPerPageNumber(data: any) {
        this.setcurrentpage(0);
    }

    //

    /*Search Pagination*/
    public setcurrentpage(data: any) {

        if (data !== 0) {
            this.isPrevious = true;
        } else {
            this.isPrevious = false;
        }
        if (this.inSearch) {
            const pagenationObj = new Object();

            this.currentpage = data;
            pagenationObj['PageNumber'] = this.currentpage;
            pagenationObj['NumberOfRecords'] = this.currentpageperview;
            pagenationObj['RecordCount'] = this.endIndex;
            pagenationObj['StartRecordCount'] = this.startIndex;
            this.searchPagenationDetails.emit(pagenationObj);
            this.currentpage = data + 1;
            this.getNoOfPages();
        } else {

            const pagenationObj = new Object();

            this.currentpage = data;
            pagenationObj['PageNumber'] = this.currentpage;
            pagenationObj['NumberOfRecords'] = this.currentpageperview;
            pagenationObj['RecordCount'] = this.endIndex;
            pagenationObj['StartRecordCount'] = this.startIndex;
            this.pagenationDetails.emit(pagenationObj);
            this.currentpage = data + 1;
//              this.currentpage = data + 10;
//             if (this.currentpage > this.noOfPages)
//             {
//                  this.currentpage = this.noOfPages;
//             }
            this.getNoOfPages();
            if (data !== 0) {
                if (this.currentpage === this.noOfPages) {
                    this.isNextNext = false;
                } else {
                    this.isNextNext = true;
                }
            }
}
this.showData();
this.getDisplayPagesForCurrentPage(this.currentpage);
}
// '>' pagination
    public setcurrentpageNext(data: any) {
        this.isPrevious = true;

        if (this.inSearch) {
            const pagenationObj = new Object();

            this.currentpage = data;
            pagenationObj['PageNumber'] = this.currentpage;
            pagenationObj['NumberOfRecords'] = this.currentpageperview;
            pagenationObj['RecordCount'] = this.endIndex;
            pagenationObj['StartRecordCount'] = this.startIndex;
            this.searchPagenationDetails.emit(pagenationObj);
            this.currentpage = data + 1;
//            this.currentpage = data + this.currentpageperview;
//             if (this.currentpage > this.noOfPages) {
//                  this.currentpage = this.noOfPages;
//             }
            if (this.currentpage === this.noOfPages) {
                this.isNextNext = false;
            }
            this.getNoOfPages();
        } else {

            const pagenationObj = new Object();

            this.currentpage = data;
            pagenationObj['PageNumber'] = this.currentpage;
            pagenationObj['NumberOfRecords'] = this.currentpageperview;
            pagenationObj['RecordCount'] = this.endIndex;
            pagenationObj['StartRecordCount'] = this.startIndex;
            this.pagenationDetails.emit(pagenationObj);
             this.currentpage = data + 1;
//             this.currentpage = data + this.currentpageperview;
//             if (this.currentpage > this.noOfPages) {
//                  this.currentpage = this.noOfPages;
//             }
            if (this.currentpage === this.noOfPages) {
                this.isNextNext = false;
            }
            this.getNoOfPages();
        }
        this.showData();
        this.getDisplayPagesForCurrentPage(this.currentpage);
    }

    public setcurrentpagePrevious(data: any) {

        this.isNextNext = true;
        if (this.inSearch) {
            const pagenationObj = new Object();

            this.currentpage = data;
            pagenationObj['PageNumber'] = this.currentpage;
            pagenationObj['NumberOfRecords'] = this.currentpageperview;
            pagenationObj['RecordCount'] = this.endIndex;
            pagenationObj['StartRecordCount'] = this.startIndex;
            this.searchPagenationDetails.emit(pagenationObj);
            this.currentpage = data + 1;
//             this.currentpage = data - (this.currentpageperview - 2);
//             if (this.currentpage <= 1)
//             {
//                 this.currentpage = 1;
//             }
            if (this.currentpage === 1) {
                this.isPrevious = false;
            }
            if (this.currentpage === this.noOfPages) {
                this.isNextNext = false;
            }
            this.getNoOfPages();
        } else {

            const pagenationObj = new Object();

            this.currentpage = data;
            pagenationObj['PageNumber'] = this.currentpage;
            pagenationObj['NumberOfRecords'] = this.currentpageperview;
            pagenationObj['RecordCount'] = this.endIndex;
            pagenationObj['StartRecordCount'] = this.startIndex;
            this.pagenationDetails.emit(pagenationObj);
            this.currentpage = data + 1;
//           this.currentpage = data - (this.currentpageperview - 2);
//             if (this.currentpage <= 1)
//             {
//                 this.currentpage = 1;
//             }
            if (this.currentpage === 1) {
                this.isPrevious = false;
            }
            if (this.currentpage === this.noOfPages) {
                this.isNextNext = false;
            }
            this.getNoOfPages();
        }
        this.showData();
        this.getDisplayPagesForCurrentPage(this.currentpage);
    }
    // public showData() {
    //     this.Data = [];
    //     this.pageNumberArray = [];
    //     this.pagePerView = Math.floor(this.CompleteData.length / this.currentpageperview);
    //     if (this.pagePerView < 1) {
    //         this.pagePerView = 1;
    //     }
    //     for (let i = 1; i <= this.pagePerView; i++) {
    //         this.pageNumberArray.push(i);
    //     }
    //     this.startIndex = (this.currentpage === 1) ? 1 : ((((this.currentpage - 1)
    //         * this.currentpageperview) + 1));
    //     this.endIndex = this.startIndex + (this.CompleteData.length < this.currentpageperview
    //         ? this.CompleteData.length : this.currentpageperview);
    //     let j = 1;
    //     for (const dt of this.CompleteData) {
    //         if (j >= this.startIndex && j < this.endIndex) {
    //             this.Data.push(dt);
    //         }
    //         j = j + 1;
    //     }
    // }

    /*Search Pagination*/
    public showData() {
        this.startIndex = (this.currentpage === 1) ? 1 : ((((this.currentpage - 1)
            * this.currentpageperview) + 1));
        this.endIndex = this.startIndex + (this.CompleteData.length < this.currentpageperview
            ? this.CompleteData.length : this.currentpageperview);
        this.getDisplayPagesForCurrentPage(this.currentpage);
    }


    public getNoOfPages() {
        let n = Math.floor(this.TotalRows / this.currentpageperview);
        if (this.TotalRows % this.currentpageperview !== 0 || n === 0) {
            n++;
        }
        if (!isNaN(n)) {
            this.numbers = Array(n).fill(0).map((x, i) => i);
            this.noOfPages = n;
          
            this.getDisplayPages();
        }
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    public getDisplayPages() {
        if (this.displayNumbers.length === 0 || this.displayNumbers.length > this.noOfPages) {
            const pageRangeStart = 0;
            this.displayNumbers = [];
            let pageRangeEnd: any;
            if ((pageRangeStart + this.dispCount) > this.noOfPages) {
                pageRangeEnd = this.noOfPages;
            } else {
                pageRangeEnd = pageRangeStart + this.dispCount;
            }
            for (let i = pageRangeStart; i < pageRangeEnd; i++) {
                this.displayNumbers.push(i);
            }
            if (!this.cdr['destroyed']) {
                this.cdr.detectChanges();
            }
        }
    }

    public getDisplayPagesForCurrentPage(currentPage: any) {
        this.getNoOfPages();
        const pageRangeStart = Math.floor((currentPage - 1) / this.dispCount) * this.dispCount;
        this.displayNumbers = [];
        let pageRangeEnd: any;
        if ((pageRangeStart + this.dispCount) > this.noOfPages) {
            pageRangeEnd = this.noOfPages;
        } else {
            pageRangeEnd = pageRangeStart + this.dispCount;
        }
        for (let i = pageRangeStart; i < pageRangeEnd; i++) {
            this.displayNumbers.push(i);
        }
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        }
    }

    public getDisplayPagesForward() {
        this.isNextNext = true;
        const pageRangeStart = (Math.floor(this.displayNumbers[this.displayNumbers.length - 1] / this.dispCount) + 1) * this.dispCount;
        this.displayNumbers = [];
        let pageRangeEnd: any;
        if ((pageRangeStart + this.dispCount) > this.noOfPages) {
            pageRangeEnd = this.noOfPages;
        } else {
            pageRangeEnd = pageRangeStart + this.dispCount;
        }
        for (let i = pageRangeStart; i < pageRangeEnd; i++) {
            this.displayNumbers.push(i);
        }
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        } this.setcurrentpage(this.displayNumbers[0]);
    }

    public getDisplayPagesNextForward(data) {
        if (data + 1 === this.noOfPages) {
            this.isNextNext = false;
        } else {
            this.isNextNext = true;
        }
         this.setcurrentpage(data);
    }
    public getDisplayPagesBack() {
        const pageRangeStart = (Math.floor(this.displayNumbers[this.displayNumbers.length - 1] / this.dispCount) - 1) * this.dispCount;
        this.displayNumbers = [];
        let pageRangeEnd: any;
        if ((pageRangeStart + this.dispCount) > this.noOfPages) {
            pageRangeEnd = this.noOfPages;
        } else {
            pageRangeEnd = pageRangeStart + this.dispCount;
        }
        for (let i = pageRangeStart; i < pageRangeEnd; i++) {
            this.displayNumbers.push(i);
        }
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        } this.setcurrentpage(this.displayNumbers[0]);
    }
    public getDisplayPagesLast() {
        this.isNextNext = false;
        this.isPrevious = true;
        const pageRangeStart = Math.floor((this.noOfPages - 1) / this.dispCount) * this.dispCount;
        this.displayNumbers = [];
        let pageRangeEnd: any;
        if ((pageRangeStart + this.dispCount) > this.noOfPages) {
            pageRangeEnd = this.noOfPages;
        } else {
            pageRangeEnd = pageRangeStart + this.dispCount;
        }
        for (let i = pageRangeStart; i < pageRangeEnd; i++) {
            this.displayNumbers.push(i);
        }
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        } this.setcurrentpage(pageRangeEnd - 1);
    }
    public getDisplayPagesFirst() {
        this.isPrevious = false;
        this.isNextNext = true;
        const pageRangeStart = 0;
        this.displayNumbers = [];
        let pageRangeEnd: any;
        if ((pageRangeStart + this.dispCount) > this.noOfPages) {
            pageRangeEnd = this.noOfPages;
        } else {
            pageRangeEnd = pageRangeStart + this.dispCount;
        }
        for (let i = pageRangeStart; i < pageRangeEnd; i++) {
            this.displayNumbers.push(i);
        }
        if (!this.cdr['destroyed']) {
            this.cdr.detectChanges();
        } this.setcurrentpage(this.displayNumbers[0]);
    }
    /***TO DO*** remove --- use pipe */
    public sort(col: string) {
        if (this.sortBy !== col) {
            if (col.match(/date/i)) {
                this.Data.sort(function (a, b) {
                    const aSplit: number[] = a[col].split('/');
                    const bSplit: number[] = b[col].split('/');
                    const aDt = new Date(Number(aSplit[2]), Number(aSplit[1]) - 1, Number(aSplit[0]));
                    const bDt = new Date(Number(bSplit[2]), Number(bSplit[1]) - 1, Number(bSplit[0]));
                    if (aDt < bDt) {
                        return -1;
                    } else if (aDt > bDt) {
                        return 1;
                    }
                    return 0;
                });
            } else if (col.match(/premium/i)) {
                this.Data.sort(function (a, b) {
                    const aNo: number = Number(a[col]);
                    const bNo: number = Number(b[col]);
                    return aNo - bNo;
                });
            } else {
                this.Data.sort(function (a, b) {
                    return String(a[col].toLowerCase()).localeCompare(String(b[col].toLowerCase()));
                });
            }
            this.sortBy = col;
            this.sortOrder = 'ascending';
        } else if (this.sortBy === col && this.sortOrder === 'ascending') {
            this.Data.reverse();
            this.sortOrder = 'descending';
        } else if (this.sortBy === col && this.sortOrder === 'descending') {
            this.Data.reverse();
            this.sortOrder = 'ascending';
        }
        // this.showData();
    }

    /****TO DO****/
    set(value: any) {
        this.currentpageperview = value;
        // this.onchangeDataPerPageNumber(null);
        this.setcurrentpage(0);
        this.toggleDisplay();
    }
    /****TO DO****/
    toggleDisplay() {
        if (this.display === 'none') {
            this.display = 'flex';
        } else {
            this.display = 'none';
        }
    }
    hideDropdown() {
        this.display = 'none';
    }
}
