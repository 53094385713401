import { ModalComponent } from '../modal/modal.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, APP_INITIALIZER } from '@angular/core';
import { AffinityLoginComponent } from 'src/login/affinity-login.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { routing } from 'src/routes/affinity.routes';
import { LoginComponent } from 'src/login/login.component';
import {
  LocationStrategy,
  PathLocationStrategy,
  APP_BASE_HREF, DatePipe
} from '@angular/common';
import { AppConfig } from 'src/config/app.config';
import { getAppFromConfig } from 'src/config/factory';
import { WelcomeComponent } from 'src/welcome/welcome.component';
import { NavigationComponent } from 'src/navigation/navigation.component';
import { LandingComponent } from 'src/landing/landing.component';
import { ProposalComponent } from 'src/proposal/proposal.component';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_FORMATS, DateTimeAdapter, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
// import { DateTimeAdapter, OWL_DATE_TIME_FORMATS, OWL_DATE_TIME_LOCALE } from 'ng-pick-datetime';
import { PanelComponent } from 'src/panel/panel.component';
import { LoaderComponent } from 'src/loader/loader.component';
import { ProposalServices } from 'src/services/proposal.services';
import { ModalService } from '../services/modal.services';
import { ErrorComponent } from 'src/ErrorPages/ErrorComponent';
import { NavigationServices } from '../services/navigation.services';
import { ClickOutsideDirective } from 'src/clickOutside.directive';
import { MY_CUSTOM_FORMATS } from 'src/proposal/proposal.component';
import { MomentDateTimeAdapter } from 'ng-pick-datetime-moment';
import { SelectComponent } from '../select/select-component';
import { SelectService } from 'src/services/select.services';
import { DatePickerComponent } from '../datepicker/datepicker.component';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { PostalService } from 'src/services/postal.services';
import { AffinityComponent } from 'src/affinityconfig/affinity.component';
import { EmitterService } from 'src/services/EmitterService';
import { ProposalSummaryComponent } from 'src/proposal-summary/proposal-summary.component';
import { PaymentComponent } from 'src/payment/payment.component';
import { IssuedProposalComponent } from 'src/issued-proposal/issued-proposal.component';
import { RenewalTransactionComponent } from 'src/renewal-transaction/renewal-transaction.component';
import { RetrieveProposalComponent } from 'src/retrieve-proposal/retrieve-proposal.component';
import { EndorsementProposalComponent } from 'src/endorsement-proposal/endorsement-proposal.component';
import { GridComponent } from 'src/grid/grid.component';
import { DeclarationService } from 'src/services/declaration.services';
import { DeclarationComponent } from 'src/declaration/declaration.component';
import { NoCommaPipe } from 'src/pipes/no-comma.pipe';
import { CreateUserComponent } from '../create-user/create-user.component';
import { ViewUsersComponent } from 'src/view-users/view-users.component';
import { UserManagementServices } from 'src/services/user-management.services';
import { CreateNewAffinity } from '../create-new-affinity/create-new-affinity.component';
// import { ProductConfiguration } from '../product-configuration/product-configuration.component';
import { JwtInterceptor } from 'src/services/jwt.intercepter';
import { AuthenticationService } from 'src/services/authentication.service';
import { AuthGuard } from 'src/auth-guard/auth.guard';
import { RoleGuard } from 'src/auth-guard/role.guard';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { ProductConfigurationComponent } from 'src/product-configuration/product-configuration.component';
import { MultiselectComponent } from 'src/multiselect/multiselect.component';
import { PlanAssignmentComponent } from 'src/plan-assignment/planassignment';
import { AffinityService } from 'src/services/affinity.service';
import { ProductServices } from 'src/services/product.service';
import { PaymentService } from 'src/services/payment.service';
import { ReportsHealthComponent } from '../reportshealth/reportshealth.component';
import { JeweleryReportComponent } from '../reportsJewellery/jeweleryreport.component';
import { ReportsBusinessComponent } from '../reportsbusiness/reportsbusiness.component';
import { ReportsService } from 'src/services/reports.service';
import { ResourcesComponent } from '../resources/resources.component';
import {TextDropdownComponent} from '../text-dropdown/text-dropdown.component';
import {DropdownModule} from 'primeng/dropdown';
import { ResourceService} from '../services/resource.service';
import { InboxpageComponent } from 'src/inboxpage/inboxpage.component';
import { SelectProductComponent } from 'src/select-product/select-product.component';
import { ModalNewComponent } from 'src/modal-new/modal.new.component';
import {BranchComponent} from 'src/branchcreate/branch.component';
import {renewalComponent} from 'src/renewalold/renewalold.component';


@NgModule({
  declarations: [
    AffinityLoginComponent,
    LoginComponent,
    WelcomeComponent,
    NavigationComponent,
    LandingComponent,
    ProposalComponent,
    PanelComponent,
    LoaderComponent,
    ModalComponent,
    ErrorComponent,
    SelectComponent,
    DatePickerComponent,
    ClickOutsideDirective,
    TextBoxComponent,
    AffinityComponent,
    ProposalSummaryComponent,
    PaymentComponent,
    IssuedProposalComponent,
    RenewalTransactionComponent,
    RetrieveProposalComponent,
    EndorsementProposalComponent,
    GridComponent,
    DeclarationComponent,
    NoCommaPipe,
    CreateUserComponent,
    ViewUsersComponent,
    CreateNewAffinity,
    ProductConfigurationComponent,
    MultiselectComponent,
    PlanAssignmentComponent,
    ReportsHealthComponent,
    ReportsBusinessComponent,
    ResourcesComponent,
    TextDropdownComponent,
    InboxpageComponent,
    SelectProductComponent,
    ModalNewComponent,
    BranchComponent,
    renewalComponent,
    JeweleryReportComponent
    ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpModule,
    HttpClientModule,
    routing,
    BrowserAnimationsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    DropdownModule
  ],
  providers: [
    AuthGuard,
    ProposalServices,
    NavigationServices,
    SelectService,
    ModalService,
    PostalService,
    EmitterService,
    UserManagementServices,
    AppConfig,
    DatePipe,
    DeclarationService,
    RoleGuard,
    AuthenticationService,
    AffinityService,
    ProductServices,
    PaymentService,
    ReportsService,
    ResourceService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: LocationStrategy, useClass: PathLocationStrategy },
    { provide: DateTimeAdapter, useClass: MomentDateTimeAdapter, deps: [OWL_DATE_TIME_LOCALE] },
    { provide: OWL_DATE_TIME_FORMATS, useValue: MY_CUSTOM_FORMATS },
    {
      provide: APP_INITIALIZER,
      useFactory: getAppFromConfig,
      deps: [AppConfig],
      multi: true
    },
    { provide: APP_BASE_HREF, useValue: '/' }
  ],
  bootstrap: [AffinityLoginComponent],
  exports: [ClickOutsideDirective]
})
export class AffinityModule { }
