export class ReportsVO {
    // reportType: String;
    shortProductName: string;
    startDate: any;
    endDate: any;
    constructor() {
        this.startDate = null;
        this.endDate = null;
        this.shortProductName = '';
    }
}
