import { Component, OnInit, Input, Output, EventEmitter, ViewChild, Optional, Inject, OnChanges } from '@angular/core';
import { ElementBase } from 'src/accessor/element.base';
import {

NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';

@Component({
  selector: 'app-text-dropdown',
  templateUrl: './text-dropdown.component.html',
  styleUrls: ['./text-dropdown.component.css'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: TextDropdownComponent,
      multi: true
    }
  ]

}
)
export class TextDropdownComponent extends ElementBase<String> implements OnInit, OnChanges {
    @Input() public labels: String;
    @Input() public params: any;
    @Input() public placeholder: String;
    @Input() public lookupName: String;
    @Input() public labelhidden: String;
    @Input() public required: String;
    @Input()
    public DataArray: Array<Object>;
    @Input() public readonly: any = false;
    @Output() public changeevent = new EventEmitter<Object>();
    @Input()
    public validationMessage;
    public results: any;
    @ViewChild(NgModel) model: NgModel;
    public identifier = `app-text-dropdown${identifier++}`;
    // public cars: SelectItem[];
    @Input()
    public dataList: Array<Object> = new Array<Object>();

    constructor(
      @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
      @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    ) {
      super(validators, asyncValidators);
    }
  ngOnInit() {
       // this.fetchLookUpdata();
       // console.log(this.DataArray);
 }

ngOnChanges(changes: any) {
   // console.log(this.DataArray);
     //  this.fetchLookUpdata();
    //   console.log(this.DataArray);
}

public onChangeMethod(data: any) {
  this.changeevent.emit(data);
}

// public fetchLookUpdata(){
//   if(this.DataArray){
//    console.log(this.DataArray);
//   const dtArray: Array<Object> = new Array<Object>();
//   for (const datas of this.DataArray) {
//     console.log(datas);
//     const obj: Object = new Object();
//     obj['label'] = datas['affinityName'];
//     obj['value'] = datas['affinityId'];
//     dtArray.push(obj);
//   }
//   this.dataList = dtArray;
//    console.log(this.dataList);
// }
// }


public onBlurMethod(showError: any): boolean {

// this.isShow = false;
  this.validationMessage = '';
  if ((this.required || this.required === 'true') && (this.value === undefined || this.value === null || !this.value || this.value.length === 0)) {
      if (showError) {
          this.validationMessage = (this.labels + ' is required');
      }
      return false;
  }
  return true;
}

}
let identifier = 0;