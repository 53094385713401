import { Injectable } from '@angular/core';
import { CanActivate, RouterModule, Routes, Router, ActivatedRoute, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { USER_PROFILE, USER_PROFILE_DATA } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';

@Injectable()
export class RoleGuard implements CanActivate {
  private userProfile: Object;
  constructor(private route: ActivatedRoute, private router: Router, private authenticationService: AuthenticationService) { }
  // async canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    // const loggedIn = await this.authenticationService.isLoggedIn().then(data => {
    //   if (String(data['message']) === 'Unauthorized') {
    //     return false;
    //   } else {
    //     return true;
    //   }
    // }).catch(error => {
    //   return false;
    // });
    const loggedIn = true;
    if (loggedIn) {
      if (route.data.affinityDataRequired) {
        if (sessionStorage.getItem('USER_AFFINITY')) {
          const affinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
          if (!affinity['name'] || !affinity['id']) {
            this.router.navigateByUrl('welcome/(contentBody:affinity)');
            return false;
          }
        } else {
          this.router.navigateByUrl('welcome/(contentBody:affinity)');
          return false;
        }
      }


      const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));

      if (state.url === '/welcome/(contentBody:affinity)') {
        const allowedRoles = route.data.allowedRoles;
        if (!this.includes(profileData['roleName'], allowedRoles)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }

      if (state.url.split('?')[0] === '/welcome/(contentBody:createUser)') {
        if (route.queryParams.edit) {
          const edit = route.data.edit;
          if (!this.includes(profileData['roleName'], edit)) {
            this.router.navigateByUrl('welcome/(contentBody:landing)');
            return false;
          }
        } else {
          const create = route.data.create;
          if (!this.includes(profileData['roleName'], create)) {
            this.router.navigateByUrl('welcome/(contentBody:landing)');
            return false;
          }
        }
        return true;
      }


      if (state.url === '/welcome/(contentBody:viewUsers)') {
        const view = route.data.view;
        if (!this.includes(profileData['roleName'], view)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }
  
      if (state.url === '/welcome/(contentBody:branchConfig)') {
        const allowedRoles = route.data.allowedRoles;
        if (!this.includes(profileData['roleName'], allowedRoles)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }

      if (/\/welcome\/\(contentBody\:proposal\/.+\/.+\)/.test(state.url)) {
        if (route.queryParams.actionType) {
          if (route.queryParams.actionType === 'RENEWAL') {
            const renew = route.data.renew;
            if (!this.includes(profileData['roleName'], renew)) {
              this.router.navigateByUrl('welcome/(contentBody:landing)');
              return false;
            }
          } else if (route.queryParams.actionType === 'RETRIEVE') {
            const retrieve = route.data.retrieve;
            if (!this.includes(profileData['roleName'], retrieve)) {
              this.router.navigateByUrl('welcome/(contentBody:landing)');
              return false;
            }
          } else if (route.queryParams.actionType === 'ENDORSEMENT') {
            const endorsements = route.data.endorsements;
            if (!this.includes(profileData['roleName'], endorsements)) {
              this.router.navigateByUrl('welcome/(contentBody:landing)');
              return false;
            }
          }
        } else {
          const create = route.data.create;
          if (!this.includes(profileData['roleName'], create)) {
            this.router.navigateByUrl('welcome/(contentBody:landing)');
            return false;
          }
        }
        return true;
      }

      if (state.url === '/welcome/(contentBody:newAffinity)') {
        const allowedRoles = route.data.allowedRoles;
        if (!this.includes(profileData['roleName'], allowedRoles)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }

      if (state.url === '/welcome/(contentBody:affinity)') {
        const allowedRoles = route.data.allowedRoles;
        if (!this.includes(profileData['roleName'], allowedRoles)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }

      if (state.url === '/welcome/(contentBody:product)') {
        const allowedRoles = route.data.allowedRoles;
        if (!this.includes(profileData['roleName'], allowedRoles)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }

      if (state.url.split('?')[0] === '/welcome/(contentBody:product)') {
        const allowedRoles = route.data.allowedRoles;
        if (!this.includes(profileData['roleName'], allowedRoles)) {
          this.router.navigateByUrl('welcome/(contentBody:landing)');
          return false;
        }
        return true;
      }

      sessionStorage.setItem(USER_PROFILE, '');
      this.router.navigate(['/']);
      return false;
    } else {
      setTimeout(
        function () {
          EmitterService.get('SESSION_EXPIRED').emit(true);
        },
        500
      );
      this.router.navigateByUrl('');
    }
  }
  public includes(role: string, allowedRoles: string[]): boolean {
    let flag = false;
    if (allowedRoles && allowedRoles.length) {
      for (let i = 0; i < allowedRoles.length; i++) {
        if (role === allowedRoles[i]) {
          flag = true;
          break;
        }
      }
    }
    return flag;
  }
}
