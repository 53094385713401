export class BranchCreationVO{
    public branchID: any;
    public branchCode: String;
    public branchName:String;
    public branchRegion: String;
    public branchArea: String;
    public branchStateName: String;
    public branchStateId: any;
    public branchDsitrictName: String;
    public branchDistrictId: any;
}