import { Injectable } from '@angular/core';
// import { USER_PROFILE } from './../common/common.constants';
import { NavigationServices } from 'src/services/navigation.services';
import {
    HttpInterceptor,
    HttpRequest,
    HttpResponse,
    HttpHandler,
    HttpEvent,
    HttpErrorResponse
} from '@angular/common/http';

import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { USER_PROFILE } from 'src/common/common.constants';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add authorization header with jwt token if available
        const userProfile: any = sessionStorage.getItem(USER_PROFILE);
        if (userProfile) {
            request = request.clone({
                setHeaders: {
                    Authorization: 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)),
                    authorizationToken: JSON.parse(sessionStorage.getItem(USER_PROFILE))
                }
            });
        }
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                }
                return event;
            }));
    }
}
