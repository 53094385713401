import { Component, OnInit, ViewChildren, HostListener, ViewChild, ElementRef } from '@angular/core';
import { GridComponent } from 'src/grid/grid.component';
import { ProposalServices } from 'src/services/proposal.services';
import { PostalService } from 'src/services/postal.services';
import { ModalService } from 'src/services/modal.services';
import { Router, ActivatedRoute } from '@angular/router';
import { DatePipe } from '@angular/common';
import {
  DESCRIPTION,
  CODE,
  CERTIFICATE_NO,
  CUSTOMER_NAME,
  START_DATE,
  PRODUCT_CODE,
  SAHAJ_AND_LIB_OCCUPANCY,
  SHOP_OCCUPANCY_TARIFF,
  HOME_OCCUPANCY_AND_TARIFF,
  SHOP_OCCUPANCY,
  MEENACHIL_SHOP_OCCUPANCY,
  MEENACHIL_HOME_OCCUPANCY,
  INTERACTIVE_TRADEX_SHOP_OCCUPANCY
} from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { EmitterService } from '../services/EmitterService';
import { CustomerDetailsVO } from 'src/dto/CustomerDetailsVO';
import { ProposalVO } from 'src/dto/ProposalVO';
import { CommunicationVO } from 'src/dto/CommunicationVO';
import { BeneficiaryVO } from 'src/dto/BeneficiaryVO';
import { PremiumDetailsVO } from 'src/dto/PremiumDetailsVO';
import * as _moment from 'moment';
import { RiskLocationVO } from 'src/dto/RiskLocationVO';
import { PaymentDetailsVO } from 'src/dto/PaymentDetailsVO';

const moment = (_moment as any).default ? (_moment as any).default : _moment;


@Component({
  selector: 'app-issued-proposal',
  templateUrl: './issued-proposal.component.html',
  styleUrls: ['./issued-proposal.component.css']
})
export class IssuedProposalComponent implements OnInit {

  public columnsData: Array<Object> = Array<Object>();
  public actionNameData: String;
  public projectData: Array<Object> = Array<Object>();
  public subcolumnsData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public searchArray: Array<Object> = Array<Object>();
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  public loadingmessage = '';
  public certificateNumber = '';
  public hasErrorMessage: Boolean = false;
  public message: any = '';
  public paymentError = false;
  public customerDetail: CustomerDetailsVO = new CustomerDetailsVO();
  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public riskLocationDetail: RiskLocationVO = new RiskLocationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public paymentDetail: PaymentDetailsVO = new PaymentDetailsVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public count: any;
  public totalNumberOfRecords: Number;
  public dataValue: any;
  public subscription: any;
  public totalRows: any;
  public hrLeftMargin: number;
  public hrWidth: number;
  public productId: any;
  public display = 'none';
  public description: string;
  public lobId: Number;
  public mockProposal: any;
  public mockCertificateProposal: any;
  public prodName: any;
  public lobName: any;
  public shortProdName: any;
  public producDesc: any;
  public tempPlanId: any;
  public sumInsuredForCoverages: any;
  public coverageIds: Object = new Object();
  public coverageSectionType1: Array<any> = new Array<any>();
  public coverageSectionType2: Array<any> = new Array<any>();
  public applianceDetail: Array<any> = new Array<any>();
  public actionFailedFlag = false;
  public actionFailedMessage = '';
  public placeOfSupply: any;
  public placeOfSupplyId: any;
  public issuedCertProp: any;
  public actionType: any;
  public titleDesc: String;
  public policyStartDateSearch: any;
  public endorsementValue: any;
  @ViewChild('contentBody') contentBodyRef: ElementRef;
  @ViewChild('grid') gridComponent: GridComponent;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyRef.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
  }

  constructor(private proposalServices: ProposalServices,
    private postalServices: PostalService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe) {
  }

  ngOnInit() {
    sessionStorage.setItem(PRODUCT_CODE, '');
    this.onResize();
    this.loadingmessage = 'Please wait...';
    this.route.paramMap.subscribe(params => {
      this.prodName = params.get('prodName');
      this.lobName = params.get('lobName');
      this.projectData = [];
      this.getProdAndLobDetails();
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.searchArray = [];
    this.count = 0;
    this.totalNumberOfRecords = 10;
    this.getIssuedDatails();
    this.prepareColumnsData();
   // this.prepareActionColumns();
    this.issuedSearchArray();
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.proposerSearchVo.searchId = this.searchArray[0]['Code'];
    this.description = this.searchArray[0]['Description'];
  }
  public getIssuedDatails() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined && productid !== '') {
      this.proposalServices.getIssuedProposal(this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
        });
    }
  }

  prepareColumnsData() {
    this.actionNameData = 'Print Documents';
    const col1: Object = new Object();
    col1['colName'] = 'Certificate No.';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Customer Name';
    this.columnsData.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Policy Start Date';
    this.columnsData.push(col3);
    const col4: Object = new Object();
    col4['colName'] = 'Premium';
    this.columnsData.push(col4);
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  set(value: any, description: any) {
    this.proposerSearchVo.searchId = value;
    this.policyStartDateSearch = '';
    this.proposerSearchVo.searchInput = '';
    this.description = description;
    this.toggleDisplay();
  }
  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }


  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getIssuedDatails();
  }


  public prepareProjectData(issuedProposalData) {
    const issuedDetailsList = issuedProposalData.policyDetailsList;
    if (issuedProposalData !== undefined) {
      this.projectData = [];
      for (let i = 0; i < issuedDetailsList.length; i++) {
        let customerName = '';
        this.actionColumnsData = new Array<Object>();
        let PremiumValue = '';
        const startDate = issuedDetailsList[i].policyStartDate;
        const policyDate: any = startDate.split(' ');
        const policyStartDate = policyDate[0];
        const data: Object = new Object();
        const certificateNoVal: any = issuedDetailsList[i].certificateNo.split('-');
          if(certificateNoVal.length === 9){
            data['isEndorsed'] = true;
            this.endorsementColumns();
          } else {
            data['isEndorsed'] = false;
            this.prepareActionColumns();
          }
          // data['isEndorsed'] = false;
          //  this.prepareActionColumns();
        if (issuedDetailsList[i].customerDetails !== undefined) {
          if(issuedDetailsList[i].customerDetails.lastName!== undefined)
          {
            customerName = issuedDetailsList[i].customerDetails.firstName + ' ' + issuedDetailsList[i].customerDetails.lastName;
          }
          else
          {
            customerName = issuedDetailsList[i].customerDetails.firstName;
          }
        }
        if (issuedDetailsList[i].premiumDetails !== undefined) {
          PremiumValue = issuedDetailsList[i].premiumDetails.grossPremium;
        }
        data['Certificate No.'] = issuedDetailsList[i].certificateNo;
        data['Customer Name'] = customerName;
        data['Policy Start Date'] = policyStartDate;
        data['Premium'] = PremiumValue;
        this.projectData.push(data);
      }
    }
  }
  public prepareActionColumns() {
    this.actionColumnsData.push('PROPOSAL');
    this.actionColumnsData.push('CERTIFICATE');
  }

  public endorsementColumns(){
    this.actionColumnsData.push('ENDORSEMENTCOPY');
  }
  
  public issuedSearchArray() {
    const code0: Object = new Object();
    code0[CODE] = CERTIFICATE_NO;
    code0[DESCRIPTION] = 'Certificate No.';
    const code2: Object = new Object();
    code2[CODE] = CUSTOMER_NAME;
    code2[DESCRIPTION] = 'Customer Name';
    const code3: Object = new Object();
    code3[CODE] = START_DATE;
    code3[DESCRIPTION] = 'Policy Start Date';
    this.searchArray.push(code0);
    this.searchArray.push(code2);
    this.searchArray.push(code3);
  }
  public peformevent(str) {
  }
  public resetSearch() {
    this.proposerSearchVo.searchInput = '';
  }

  public issuedProposalSearch() {
    this.loadingmessage = 'Please wait...';
    const policyStatus = 2;
    const action = 'ISSUED';
    if(this.proposerSearchVo.searchId === START_DATE) {
      this.proposerSearchVo.searchInput = this.policyStartDateSearch;
    }
    const startdate = moment(this.proposerSearchVo.searchInput).local().format('DD/MM/YYYY');
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        this.proposalServices.getIssuedProposal(this.count, this.totalNumberOfRecords)
          .then(data => {
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.loadingmessage = '';
            this.gridComponent.inSearch = false;
            this.gridComponent.setcurrentpage(0);
            this.gridComponent.showData();
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.moveToTop();
          })
          .catch(error => {
            this.loadingmessage = '';
            //  this.openActionFailDialog('Data could not be fetched');
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });

      }

    } else {
      if (this.proposerSearchVo.searchId === START_DATE) {

        const generatedDate: any = (/^(0[1-9]|1\d|2\d|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d{2}$/);
        this.proposerSearchVo.searchInput = moment(this.proposerSearchVo.searchInput).local().format('DD/MM/YYYY');
        if ((this.proposerSearchVo.searchInput.match(generatedDate))) {
          this.proposalServices.proposalSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action).then(data => {

            this.loadingmessage = '';
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.gridComponent.inSearch = true;
            this.gridComponent.setcurrentpage(0);
            this.gridComponent.showData();
            this.moveToTop();
          })
            .catch(error => {
              this.loadingmessage = '';
              //  this.openActionFailDialog('Data could not be fetched');
              this.prepareProjectData(null);
              this.loadingmessage = 'Please wait...';
              setTimeout(() => {
                this.serviceDownMessage();
              }, 10000);
            });
        } else {
          this.loadingmessage = '';
          const obj = new Object();
          obj['policyDetailsList'] = [];
          obj['totalRecords'] = 0;
          this.prepareProjectData(obj);
        }

      } else {
        this.proposalServices.proposalSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action).then(data => {
          this.loadingmessage = '';
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.gridComponent.inSearch = true;
          this.gridComponent.setcurrentpage(0);
          this.gridComponent.showData();
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.moveToTop();
        })
          .catch(error => {
            this.loadingmessage = '';
            //  this.openActionFailDialog('Data could not be fetched');
            this.prepareProjectData(null);
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      }
    }
  }
  public searchUser() {
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
      this.issuedProposalSearch();
    }
  }

  public PrintData(str) {
  
    this.loadingmessage = 'Preparing Document...';
    const certificateNo = str['Certificate No.'];
    this.actionType = str['Action'];

    this.proposalServices
      .getProposalSummaryByCertificate(certificateNo)
      .then(data2 => {
        this.issuedCertProp = data2;
        if (data2 !== undefined) {
          this.certificateNumber = data2.certificateNo;
          this.message = this.certificateNumber;
          this.tempPlanId = data2.planId;
          this.summaryData(data2);
        }
      }).then(data => {
        if (this.issuedCertProp.policyTypeDescription !== 'Individual' && this.issuedCertProp.policyTypeDescription !== 'Family Floater') {
          this.getCovergeAndSections(this.tempPlanId);
        } else {
          if (this.actionType === 'PROPOSAL') {
            this.printProposal();
          } if (this.actionType === 'CERTIFICATE') {
            this.printCertificate();
          }
        }
      })
      //    return new Promise((resolve, reject) => {
     //   }).then(data => {
          // if (actionType === 'PROPOSAL') {
          //   this.printProposal();
          // } if (actionType === 'CERTIFICATE') {
          //   this.printCertificate();
          // }
      //  });
      .catch(error => { });
    this.proposerVo.communicationDetails = this.communicationDetail;
    this.proposerVo.riskLocationDetails = this.riskLocationDetail;
    this.proposerVo.paymentDetails = this.paymentDetail;
  }

  public summaryData(data) {
    const proposerDetails = data;
    this.proposerVo.policyStartDate = proposerDetails.policyStartDate;
    this.proposerVo.policyTerm = proposerDetails.policyTermDescription;
    this.proposerVo.policyEndDate = proposerDetails.policyEndDate;
    this.proposerVo.masterPolicyNo = proposerDetails.masterPolicyNo;
    this.proposerVo.policyTypeName = proposerDetails.policyTypeDescription;
    this.proposerVo.planTypeName = proposerDetails.planDescription;
    this.proposerVo.sumInsured = proposerDetails.sumInsured;
    this.proposerVo.customerId = proposerDetails.customerDetailId;
    this.proposerVo.employeeId = proposerDetails.employeeId;
    this.proposerVo.paymentDetails = proposerDetails.paymentDetails;
    this.proposerVo.applianceDetails = proposerDetails.applianceDetails;
    this.proposerVo.affinityId = proposerDetails.affinityId;
    this.proposerVo.placeOfSupply = proposerDetails.placeOfSupply;
    this.proposerVo.policyIssueDate = proposerDetails.policyIssueDate;
    this.proposerVo.branchName = proposerDetails.branchName;
    this.proposerVo.branchCode = proposerDetails.branchCode;
    this.proposerVo.covernoteNo = proposerDetails.covernoteNo;
    if(proposerDetails.endorsmentTypeDescription !== undefined){ 
      if(proposerDetails.endorsmentTypeDescription === 'Others') {
        this.proposerVo.endorsementTypeDesc = proposerDetails.endorsmentTypeDescription + "-" + proposerDetails.endorsmentOtherDetails;
    } else {
      this.proposerVo.endorsementTypeDesc = proposerDetails.endorsmentTypeDescription;
    }
    }
    this.endorsementValue = proposerDetails.endorsmentOtherDetails;
    // commenting, since we are getting agent info from session.
    //  this.proposerVo.agentId = proposerDetails.agentId;

    // const affdetails = JSON.parse(sessionStorage.getItem('SelectAffinity'));
    // console.log(affdetails);
    // for(const aff of affdetails){

    //   if(aff['Code'] === this.proposerVo.affinityId){

    //     this.placeOfSupply = aff['State'];
    //     this.placeOfSupplyId = aff['StateId'];
    //   }
    // }
    
    if (proposerDetails.productAnswers !== undefined) {
      this.questionList = proposerDetails.productAnswers;
    }
    if (proposerDetails.communicationDetails !== undefined) {
      const proposerCommDtls = proposerDetails.communicationDetails;
      this.proposerVo.communicationDetails.address1 = proposerCommDtls.address1;
      this.proposerVo.communicationDetails.address2 = proposerCommDtls.address2;
      this.proposerVo.communicationDetails.address3 = proposerCommDtls.address3;
      this.proposerVo.communicationDetails.pincode = proposerCommDtls.pincodeLocality;
      this.proposerVo.communicationDetails.stateName = proposerCommDtls.stateName;
      this.proposerVo.communicationDetails.districtName =
        proposerCommDtls.districtName;
      this.proposerVo.communicationDetails.cityName = proposerCommDtls.cityName;
      this.proposerVo.communicationDetails.emailId = proposerCommDtls.emailId;
      this.proposerVo.communicationDetails.mobile = proposerCommDtls.mobile;
      this.proposerVo.communicationDetails.telephone =
        proposerCommDtls.telephone;
      this.proposerVo.communicationDetails.gstinNo = proposerCommDtls.gstinNo;
    }
    if (proposerDetails.riskLocationDetails !== undefined) {
      const proposerriskCommDtls = proposerDetails.riskLocationDetails;
      if (proposerriskCommDtls.address3 === undefined) {
        this.proposerVo.riskLocationDetails.address3 = ' ';
      } else {
        this.proposerVo.riskLocationDetails.address3 = proposerriskCommDtls.address3;
      }
      if (proposerriskCommDtls.address2 === undefined) {
        this.proposerVo.riskLocationDetails.address2 = ' ';
      } else {
        this.proposerVo.riskLocationDetails.address2 = proposerriskCommDtls.address2;
      }
      if (proposerriskCommDtls.address1 === undefined) {
        this.proposerVo.riskLocationDetails.address1 = ' ';
      } else {
        this.proposerVo.riskLocationDetails.address1 = proposerriskCommDtls.address1;
      }
      this.proposerVo.riskLocationDetails.pincode = proposerriskCommDtls.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = proposerriskCommDtls.stateName;
      this.proposerVo.riskLocationDetails.districtName =
      proposerriskCommDtls.districtName;
      this.proposerVo.riskLocationDetails.cityName = proposerriskCommDtls.cityName;
      this.proposerVo.riskLocationDetails.emailId = proposerriskCommDtls.emailId;
      this.proposerVo.riskLocationDetails.mobile = proposerriskCommDtls.mobile;
      this.proposerVo.riskLocationDetails.telephone =
      proposerriskCommDtls.telephone;
      this.proposerVo.riskLocationDetails.gstinNo = proposerriskCommDtls.gstinNo;
    }
    if (proposerDetails.customerDetails !== undefined) {
      this.titleDesc = proposerDetails.customerDetails.titleDescription;
      this.customerDetail = proposerDetails.customerDetails;
      this.proposerVo.customerId = this.customerDetail.customerId;
      this.proposerVo.employeeId = this.customerDetail.employeeId;
    }
    if (proposerDetails.premiumDetails !== undefined) {
      const premiumDetails = proposerDetails.premiumDetails;
      this.premiumDetail.cgst = premiumDetails.cgst;
      this.premiumDetail.sgst = premiumDetails.sgst;
      this.premiumDetail.igst = premiumDetails.igst;
      this.premiumDetail.cessTax = premiumDetails.cessTax;
      this.premiumDetail.netPremium = premiumDetails.totalNetPremium;
      this.premiumDetail.grossPremium = premiumDetails.totalGrossPremium;
    }
    this.adultList = [];
    this.childList = [];
    if (proposerDetails.beneficiaryList.length !== 0) {
      this.beneficiaryList = proposerDetails.beneficiaryList;
      for (let i = 0; i < this.beneficiaryList.length; i++) {
        if (this.beneficiaryList[i].nomineeType === 2) {
          this.childList.push(this.beneficiaryList[i]);
        }
        if (this.beneficiaryList[i].nomineeType === 1) {
          this.adultList.push(this.beneficiaryList[i]);
        }
      }
    }

this.proposalServices.getProposalSummaryByCertificate(this.certificateNumber).then(data=>{


})

  }

  public printProposal() {
    this.loadingmessage = 'Preparing Document...';
    this.moveToTop();
    const printProsalJson: Object = new Object();
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
    this.hasErrorMessage = false;
    printProsalJson['applicationName'] = 'affinity';
    printProsalJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printProsalJson['placeOfSupply'] = this.proposerVo.placeOfSupply;
    printProsalJson['dateOfIssue'] = this.proposerVo.policyIssueDate;
    printProsalJson['affinityName'] = this.proposerVo.affinityName;
    printProsalJson['proposalDetails'] = new Object();
    if (this.shortProdName === 'Home' ) {
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposal';
    } else if(this.shortProdName === 'Shop') {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposalShop';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printProsalJson['proposalDetails']['templateName'] = 'ProposalForm';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    }

    printProsalJson['proposalDetails']['proposalInfo'] = new Object();

    printProsalJson['proposalDetails']['proposalInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo'][
      'employeeInfo'
    ] = new Object();
    if (this.shortProdName === 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.employeeId;
    } else {
      // printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //   'employeeCode'
      // ] = this.proposerVo.agentId;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.agentName;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'licenseCode'
      ] = this.proposerVo.agentId;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'contactNumber'
      ] =this.proposerVo.agentContactNumber;
      // const agentDetails = this.proposerVo.agentId.split('/');
      // if (agentDetails[1] !== undefined) {
      // }
      //  if (agentDetails[2] !== undefined) {
        
      //  }
    }
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchName'
    ] = this.proposerVo.branchName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchId'
    ] = this.proposerVo.branchCode;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['city'] =
      this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['state'] =
      this.proposerVo.communicationDetails.stateName;

    printProsalJson['proposalDetails']['proposalInfo'][
      'personInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'lastName'
    ] = this.customerDetail.lastName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'firstName'
    ] = this.customerDetail.firstName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'middleName'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address1'
    ] = this.proposerVo.communicationDetails.address1;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address2'
    ] = this.proposerVo.communicationDetails.address2;


    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address3'
    ] = this.proposerVo.communicationDetails.address3;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'city'
    ] = this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'state'
    ] = this.proposerVo.communicationDetails.stateName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'pinCode'
    ] = this.proposerVo.communicationDetails.pincode;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'telephone'
    ] = this.proposerVo.communicationDetails.telephone;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'mobile'
    ] = this.proposerVo.communicationDetails.mobile;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'email'
    ] = this.proposerVo.communicationDetails.emailId;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'salariedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'selfemployedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'otherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'detailsOfOtherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'indianResident'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'nonIndianResident'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'policyType'
    ] = new Object();
    if (this.proposerVo.policyTypeName === 'Individual') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = '';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'true';
    }

    printProsalJson['proposalDetails']['proposalInfo']['planType'] = new Object();
    if (this.proposerVo.planTypeName === '2 Adults') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adults + 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adults + 2 Children') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult + 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult + 2 Children') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'true';
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'typeOfBusiness'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness']['fresh'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness'][
      'renewal'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'sumInsuredInfo'
    ] = new Object();

    if (this.proposerVo.sumInsured === '2 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '3 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '5 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'true';
    }
    if(this.proposerVo.policyStartDate && this.proposerVo.policyEndDate ){
    printProsalJson['proposalDetails']['proposalInfo'][
      'policyDuration'
    ] = new Object();
    const startdate = this.proposerVo.policyStartDate.split(' ');

    const enddate = this.proposerVo.policyEndDate.split(' ');
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'from'
    ] = startdate[0];
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'to'
    ] = enddate[0];
    if (this.shortProdName !== 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
        'from'
      ] =  this.proposerVo.policyStartDate;
      printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
        'to'
      ] = enddate[0] + ' 23:59:59';
    }
  }
    printProsalJson['proposalDetails']['proposalInfo'][
      'insured1Info'
    ] = new Object();

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.customerDetail.firstName;
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = this.adultList[0].proposerRelationDescription;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = this.adultList[0].genderDescription;

    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = '';
    }
    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = this.adultList[0].dob;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = this.adultList[0].nomineeName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = this.adultList[0].nomineeRelationDescription;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = '';
    }

    if (this.adultList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured2Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'name'
      ] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipWithProposer'
      ] = this.adultList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'gender'
      ] = this.adultList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'dob'
      ] = this.adultList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'nomineeName'
      ] = this.adultList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipOfNominee'
      ] = this.adultList[1].nomineeRelationDescription;
    }

    if (this.childList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured3Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'name'
      ] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipWithProposer'
      ] = this.childList[0].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'gender'
      ] = this.childList[0].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'dob'
      ] = this.childList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'nomineeName'
      ] = this.childList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipOfNominee'
      ] = this.childList[0].nomineeRelationDescription;
    }
    if (this.childList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured4Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'name'
      ] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipWithProposer'
      ] = this.childList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'gender'
      ] = this.childList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'dob'
      ] = this.childList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'nomineeName'
      ] = this.childList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipOfNominee'
      ] = this.childList[1].nomineeRelationDescription;
    }
    if(this.proposerVo.paymentDetails){
    printProsalJson['proposalDetails']['proposalInfo'][
      'paymentInfo'
    ] = new Object();
    if(this.proposerVo.paymentDetails.paymentType === 'Offline'){
      printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cash'] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['others'] =
      'true';
    }
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cheque'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['dd'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['others'] =
      '';
  }
    printProsalJson['proposalDetails']['proposalInfo'][
      'medicalQuestion'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'customerId'
    ] = this.proposerVo.customerId;
    if (this.shortProdName !== 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'address1'
      ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3;
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'city'
      ] = this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName;
      if (this.proposerVo.riskLocationDetails !== undefined) {
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationAddress'
              ] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' + this.proposerVo.riskLocationDetails.address3;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationCity'] = this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' +
              this.proposerVo.riskLocationDetails.stateName;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationPincode'
              ] = this.proposerVo.riskLocationDetails.pincode;
      }
    }
    if (this.certificateNumber) {
      const appno = this.certificateNumber.substring(this.certificateNumber.length - 12, this.certificateNumber.length);
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'applicationNo'
      ] = appno;
    }
    // printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
    //   'applicationNo'
    // ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['date'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'financierBranchName'
    ] = '';
    if (this.shortProdName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      }  else if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_SHOP_OCCUPANCY;
      } else  if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
      } else {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProdName === 'Home') {
      if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_HOME_OCCUPANCY;
      } else {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'loanAccountNo'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'planName'
    ] = this.proposerVo.planTypeName;
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'otpVerifiedDate'
    ] = this.proposerVo.paymentDetails.otpVerifiedDate;
    if (this.shortProdName !== 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType2'] = this.coverageSectionType2;
      printProsalJson['proposalDetails']['proposalInfo']['equipment'] = new Array();
      let serialCounter: Number = 0;
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printProsalJson['proposalDetails']['proposalInfo']['equipment'].push(obj1);
      }
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      }
      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      }
      if (this.premiumDetail.cessTax) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'Cess(1%)';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj5);
      }
      if (this.premiumDetail.igst) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'IGST(18%)';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      }

      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Premium Including Tax';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Net Premium(Taxable value)';
        obj7['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj7);
      }
    }

    if (printProsalJson) {
      this.proposalServices
        .getPrintProposal(JSON.stringify(printProsalJson))
        .then(data1 => {
          const parsedResponse = data1; // .blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printFrom.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.proposerVo.covernoteNo + '.pdf'; // 'printFrom.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
        });
    }
  }



  public getBusinessPlanDetails(planId) {
    this.proposalServices.getApplianceDetails(planId).then(data => {
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < this.proposerVo.applianceDetails.length; i++) {
          if (data[j].appliances.applianceID === this.proposerVo.applianceDetails[i].applianceID) {
            const appliance = new Object();
            appliance['applianceName'] = data[j].appliances.applianceName;
            appliance['quantity'] = this.proposerVo.applianceDetails[i].quantity;
            this.applianceDetail[j] = appliance;
          }

        }
      }
    }).then(data => {
      if (this.actionType === 'PROPOSAL') {
        this.printProposal();
      } else if (this.actionType === 'CERTIFICATE') {
        this.printCertificate();
      } else if (this.actionType === 'ENDORSEMENTCOPY') {
        this.printCertificate();
      }
    }).catch(error => { });
  }
  getCovergeAndSections(planId) {
    this.proposalServices.getSumInsuredDetails(planId).then(data => {
      this.sumInsuredForCoverages = data;
      const allCoverAgeIds: Array<any> = new Array<any>();
      if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          allCoverAgeIds.push(data[i].coverageId);
          this.coverageIds['coverageIds'] = allCoverAgeIds;
        }
      }
    }).then(data => {
      this.getCoverageAndSectionForCoverageID();
    }).catch(error => {
    });
  }

  public getCoverageAndSectionForCoverageID() {
    this.proposalServices.getCoverageAndSections(this.coverageIds).then(data2 => {
      let sectionType1;
      let sectionType2;
      for (let i = 0; i < data2.length; i++) {
        sectionType1 = data2[i].sectionType1;
        sectionType2 = data2[i].sectionType2;
      }
      this.coverageSectionType2 = [];
      for (let i = 0; i < sectionType1.length; i++) {

        for (let j = 0; j < sectionType2.length; j++) {
          if (sectionType1[i].sectionId === sectionType2[j].sectionId) {
            const coverageSectionType2 = new Object();
            coverageSectionType2['coverage'] = sectionType1[i].coverage;
            coverageSectionType2['riskCovered'] = sectionType1[i].riskCovered;
            coverageSectionType2['limitperitem'] = sectionType2[j].coverage;
            for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
              if (this.sumInsuredForCoverages[m].coverageId === sectionType1[i].coverageId) {
                coverageSectionType2['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
              if (this.sumInsuredForCoverages[m].coverageId === sectionType2[j].coverageId) {
                coverageSectionType2['limitperitemsuminsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
            }
            this.coverageSectionType2.push(coverageSectionType2);
            delete sectionType1[i].sectionId;
            delete sectionType1[i].coverage;
            delete sectionType1[i].riskCovered;
            delete sectionType1[i].coverageId;
          }
        }
      }
      this.coverageSectionType1 = [];
      for (let j = 0; j < sectionType1.length; j++) {
        if (sectionType1[j].coverage !== undefined) {
          const coverageSectionType = new Object();
          coverageSectionType['coverage'] = sectionType1[j].coverage;
          coverageSectionType['riskCovered'] = sectionType1[j].riskCovered;
          for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
            if (this.sumInsuredForCoverages[m].coverageId === sectionType1[j].coverageId) {
              coverageSectionType['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
            }
          }
          this.coverageSectionType1.push(coverageSectionType);
        }
      }

    }).then(data => {
     if (this.issuedCertProp.policyTypeDescription === 'Business Package For Home') {
       this.getBusinessPlanDetails(this.issuedCertProp.planId);
     } else {
       if (this.actionType === 'PROPOSAL') {
         this.printProposal();
       } else if (this.actionType === 'CERTIFICATE') {
         this.printCertificate();
       } else if (this.actionType === 'ENDORSEMENTCOPY') {
        this.printCertificate();
      }
     }
    }).catch(error => {
    });
  }

  public printCertificate() {
    //  this.proposalServices.getAffinityDetailForEdit(this.proposerVo.affinityId).then(data=>{
    //    console.log(data);
    //    this.placeOfSupply = data;
    //    console.log(this.placeOfSupply);
    //  });

    // this.loadingmessage = 'Preparing Document...';
    this.moveToTop();
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
    const certificateNo = this.certificateNumber;
    const printCertificateJson: Object = new Object();
    printCertificateJson['applicationName'] = 'affinity';
    printCertificateJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printCertificateJson['affinityName'] = this.proposerVo.affinityName;
    printCertificateJson['certificateDetails'] = new Object();
    printCertificateJson['certificateDetails']['affinityName'] = this.proposerVo.affinityName;
    if (this.shortProdName !== 'Health') {
      if(this.actionType === 'ENDORSEMENTCOPY') {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageEndorsement';
      } else if (this.shortProdName === 'Shop') {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificateShop';
      } else {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificate';
        if(this.proposerVo.affinityName.startsWith("MUTHOOTTU"))
        {
          printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageMiniMuthootCertificate';
        }
      }
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printCertificateJson['certificateDetails']['templateName'] = 'CertificateForm';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    }

    printCertificateJson['certificateDetails'][
      'certificateInfo'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo'][
      'policyInfo'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'masterPolicyNo'
    ] = this.proposerVo.covernoteNo;
    if(this.actionType === 'ENDORSEMENTCOPY') {
      const va = certificateNo;
      const certificateNoVal: any = certificateNo.split('-');
      const val2 = certificateNoVal.slice(0,8);
      const endorsedOriginalCertNo = val2.join('-');
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'masterPolicyNo'
      ] = endorsedOriginalCertNo;
    }
    if (this.certificateNumber) {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['certificateNo'] = this.certificateNumber;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['certificateNo'] = '';
    }
    if(this.titleDesc!==undefined && this.customerDetail.lastName!==undefined)
    {
       printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.titleDesc + ' '+ this.customerDetail.firstName + ' ' + this.customerDetail.lastName;
    }else
    {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.customerDetail.firstName;
    }
    const endL = this.proposerVo.policyEndDate.split(' ');
    const startL1 = this.proposerVo.policyStartDate.split(' ');
   printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['periodOfInsurance'] = startL1[0] + ' to ' + endL[0];
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'insuredAddress'
    ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3
    + ' ' + this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName + ' ' + this.proposerVo.communicationDetails.pincode;
    if (this.shortProdName === 'Shop' || this.shortProdName === 'Home') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyTenure'
      ] = startL1[0] + ' 00:00:00' + ' to ' + endL[0] + ' 23:59:59';
      if (this.proposerVo.riskLocationDetails !== undefined) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'riskLocationAddress'
        ] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' + this.proposerVo.riskLocationDetails.address3
        + ' ' + this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' + this.proposerVo.riskLocationDetails.stateName + ' ' + this.proposerVo.riskLocationDetails.pincode;
      }
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyTenure'
      ] = this.proposerVo.policyTerm;
    }

    if (this.proposerVo.customerId) {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerNo'] = this.proposerVo.customerId;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerNo'] = '';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerName'] = this.customerDetail.customerName;
    if (this.proposerVo.policyTypeName === 'Individual') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyVariant'
      ] = 'Individual';
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyVariant'
      ] = 'Family Floater';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'tpaCode'
    ] = '003 / Medi Assist India TPA Pvt. Ltd';

    // printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
    //   'COIPeriod'
    // ] = this.proposerVo.policyStartDate + ' to ' + this.proposerVo.policyEndDate;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'groupAdministrator'
   ] = 'Manappuram Finance Ltd, Kerala';
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'policyServicingOffice'
    ] = 'Liberty General Insurance Ltd. Door No. 39/4166-A, 2nd Floor, Shema Building,M.G Road, Ravipuram, Kochi - 682016 Boardline - 484-6900 010,Fax - 022 6700 1606';
    if (this.beneficiaryList !== undefined) {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'relationshipWithInsured'
      ] = this.beneficiaryList[0].proposerRelationDescription;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'relationshipWithInsured'
      ] = '';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'sumInsured'
    ] = this.proposerVo.sumInsured;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'emailID'
    ] = this.proposerVo.communicationDetails.emailId;
    if (this.shortProdName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      }  else if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = MEENACHIL_SHOP_OCCUPANCY;
      }  else if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
      } else {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProdName === 'Home') {
      if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = MEENACHIL_HOME_OCCUPANCY;
      } else {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
  printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
    'proposalNo'
  ] = this.proposerVo.covernoteNo;
  printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
    'planName'
  ] = this.proposerVo.planTypeName;
  if(this.actionType === 'ENDORSEMENTCOPY') {
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['endorsmenetType'] = this.proposerVo.endorsementTypeDesc;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['endorsmenetValue'] = this.endorsementValue;
    printCertificateJson['certificateDetails']['certificateInfo']['policyDuration'] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo']['policyDuration']['from']= this.proposerVo.policyStartDate;
    printCertificateJson['certificateDetails']['certificateInfo']['policyDuration']['to']= endL[0] + ' 23:59:59';
  }
    // printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
    //   'tollfreeNo'
    // ] = '1800 425 9449';
    if (this.shortProdName === 'Health') {
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithPrimaryMember'] = this.adultList[0].proposerRelationDescription;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = this.adultList[0].genderDescription;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = this.adultList[0].dob;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = this.adultList[0].nomineeName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithNominee'] = this.adultList[0].nomineeRelationDescription;
      if (this.adultList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['name'] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithPrimaryMember'] = this.adultList[1].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['gender'] = this.adultList[1].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['dob'] = this.adultList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['nomineeName'] = this.adultList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithNominee'] = this.adultList[1].nomineeRelationDescription;
      }
      if (this.childList.length > 0) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['name'] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithPrimaryMember'] = this.childList[0].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['gender'] = this.childList[0].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['dob'] = this.childList[0].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['nomineeName'] = this.childList[0].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithNominee'] = this.childList[0].nomineeRelationDescription;
      }
      if (this.childList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['name'] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithPrimaryMember'] = this.childList[1].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['gender'] = this.childList[1].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['dob'] = this.childList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['nomineeName'] = this.childList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithNominee'] = this.childList[1].nomineeRelationDescription;
      }
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.customerDetail.firstName + ' ' + this.customerDetail.lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithProposer'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipOfNominee'] = '';

    }
    printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();
    if (this.premiumDetail.netPremium) {
      const obj2: Object = new Object();
      obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
      obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
    } else {
      const obj2: Object = new Object();
      obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
      obj2['premiumValue'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
    }
    if (this.premiumDetail.cgst) {
      const obj3: Object = new Object();
      obj3['premiumType'] = 'CGST -9%';
      obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
    }
    if (this.premiumDetail.sgst) {
      const obj4: Object = new Object();
      obj4['premiumType'] = 'SGST -9%';
      obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
    }
    if (this.premiumDetail.cessTax) {
      const obj5: Object = new Object();
      obj5['premiumType'] = 'Cess -1%';
      obj5['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
    }
    if (this.premiumDetail.igst) {
      const obj6: Object = new Object();
      obj6['premiumType'] = 'IGST -18%';
      obj6['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
    }
    if (this.premiumDetail.grossPremium) {
      const obj7: Object = new Object();
      obj7['premiumType'] = 'Total Premium Payable';
      obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
    } else {
      const obj7: Object = new Object();
      obj7['premiumType'] = 'Total Premium Payable';
      obj7['premiumValue'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
    }
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['gstin'] = this.proposerVo.communicationDetails.gstinNo;


    // if(this.shortProdName === 'Health'){
    //   printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['sacCode'] = '997133';
    // } else if(this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
    //   printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['sacCode'] = '997137';
    // }
    // printCertificateJson['certificateDetails']['certificateInfo'][
    //   'receiptDetails'
    // ]['irdaRegNo'] = '150';
    // printCertificateJson['certificateDetails']['certificateInfo'][
    //   'receiptDetails'
    // ]['cinNo'] = 'U66000MH2010PLC209656';
    // printCertificateJson['certificateDetails']['certificateInfo'][
    //   'receiptDetails'
    // ]['uin'] = '';
    // printCertificateJson['certificateDetails']['certificateInfo'][
    //   'receiptDetails'
    // ]['serviceDescription'] = 'General Insurance Service';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['placeOfSupply'] = this.proposerVo.placeOfSupply;
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['dateOfIssue'] = this.proposerVo.policyIssueDate;
   // this.placeOfSupply['issueStateDescription'] +'-'+ this.placeOfSupply['issueStateId'];
    if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
      printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = new Array();
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType2'] = this.coverageSectionType2;

      let serialCounter: Number = 0;
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printCertificateJson['certificateDetails']['certificateInfo']['equipment'].push(obj1);
      }
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst !== undefined) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
      }

      if (this.premiumDetail.sgst !== undefined) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
      }
      if (this.premiumDetail.cessTax !== undefined) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'Cess(1%)';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
      }

      if (this.premiumDetail.igst !== undefined) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'IGST(18%)';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
      }

      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Premium Including Tax';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Net Premium(Taxable value)';
        obj7['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      }
    }
    const certificateJson = JSON.stringify(printCertificateJson);
    if (certificateJson) {
      this.proposalServices
        .getPrintCertificate(certificateJson)
        .then(data1 => {
          const parsedResponse = data1;
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printcertificate.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.certificateNumber + '.pdf'; //'printcertificate.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.loadingmessage = '';
        });
    }

  }
  public getIssuedDetailsWithSearch() {
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      const policyStatus = 2;
      const action = 'ISSUED';
      this.loadingmessage = 'Please wait...'
      this.proposalServices.proposalSearchPagination(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action, this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.moveToTop();
        })
        .catch(error => {
          this.loadingmessage = '';
          //  this.openActionFailDialog('Data could not be fetched');
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
    }
  }

  updatePageDataWithSearch(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getIssuedDetailsWithSearch();
  }
  async getProdAndLobDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const userdetails = JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'));
    this.proposerVo.agentName = affinty['agentName'];
    this.proposerVo.agentId = affinty['agentId'];
    this.proposerVo.agentContactNumber = affinty['agentContactNumber'];
    this.proposerVo.affinityName = affinty['name'];
    if (affinty['affinityLogo'] !== '' && affinty['affinityLogo'] !== undefined) {
      this.proposerVo.affinityLogo = 'Logo_' + affinty['affinityLogo'];
    } else {
      this.proposerVo.affinityLogo = '';
    }
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
    this.proposalServices.getAvailableProducts(affinityId).then(data1 => {
      const productData = data1;
      if (productData != null) {
        sessionStorage.setItem('prodcutDetails', JSON.stringify(productData));
        this.setShortProdName();
        if (!this.projectData || this.projectData.length === 0) {
          this.getIssuedDatails();
        }
      }
    }).catch(error => {
      // this.openActionFailDialog('Data could not be fetched');
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });
  }
  }
  public setShortProdName() {
    let lobDetails;
    lobDetails = this.getProductIdAndLobName(this.prodName);
    this.lobId = lobDetails['lobId'];
    this.shortProdName = lobDetails['shortProductName'];
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
  }
  public getProductIdAndLobName(prodName: any) {
    const productDetails = sessionStorage.getItem('prodcutDetails');
    if (productDetails) {
      const prodcutArrayObj: Array<Object> = JSON.parse(productDetails);
      if (prodcutArrayObj) {
        for (const prodType of prodcutArrayObj) {
          if (prodType['productName'] === prodName) {
            const productID = prodType['productId'];
            this.producDesc = prodType['productInfo'];
            sessionStorage.setItem(PRODUCT_CODE, productID);
            return this.producDesc;
          }
        }
      }
    }
  }

  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      });
    this.router.navigateByUrl('');
  }
}
