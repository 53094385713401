export class QuestionVO {
  public productAnswerId: Number;
 // public question: String;
//  public choices: Array<String>;
  public answer: String;
 // public errorMessage: String;
//  public invalidSelection: Boolean = false;
 // public Ans: Number;
  public questionId: Number;
 // public answer: String;
}
