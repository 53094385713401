import { Injectable } from '@angular/core';
import { AppConfig } from 'src/config/app.config';
import { HttpClient } from '@angular/common/http';
import { HEADER, USER_PROFILE } from 'src/common/common.constants';
import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import * as _moment from 'moment';
import { REQUEST_HEADER } from '../common/common.constants';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Injectable()
export class ReportsService {
    constructor(private configService: AppConfig,
        private httpClient: HttpClient,
        private http: Http) {
    }
    private hostUrl = this.configService.getConfig('productHost');
    public getDailyTransactionReport(startDate: any, endDate: any, productType: any, productlist: any,isMISReport: Boolean, isSubAdminRole: Boolean,
        branchName: any, regionName:any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        let url: any;
        url = this.hostUrl + 'api/report/oldDailyTransactionReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY 00:00:00');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY 23:59:59');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['isSubAdminRole'] = isSubAdminRole; 
        if(isMISReport) {
            requestBody['reportRequest']['isCustomerProposal'] = 'Y';
        }
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['branchName'] = branchName;
        requestBody['reportRequest']['regionName'] = regionName;
        requestBody['reportRequest']['policyPlanCoverageInfo'] = [];
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }
    public getDailyTransactionReportShop(startDate: any, endDate: any, productType: any, productlist: any, coverageList: any, isMISReport: Boolean, isSubAdminRole: Boolean,
        branchName: any, regionName:any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        let url: any;
        url = this.hostUrl + 'api/report/dailyTransactionReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY 00:00:00');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY 23:59:59');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['isSubAdminRole'] = isSubAdminRole; 
        if(isMISReport) {
            requestBody['reportRequest']['isCustomerProposal'] = 'Y';
        }
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['branchName'] = branchName;
        requestBody['reportRequest']['regionName'] = regionName;
        requestBody['reportRequest']['policyPlanCoverageInfo'] = coverageList;
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }
    public sendDailyTransactionReportShop(startDate: any, endDate: any, productType: any, productlist: any, coverageList: any, isMISReport: Boolean, isSubAdminRole: Boolean, emailAddress:any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        let url: any;
        url = this.hostUrl + 'api/report/sendReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY 00:00:00');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY 23:59:59');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['isSubAdminRole'] = isSubAdminRole; 
        requestBody['reportRequest']['emailAddress'] = emailAddress;
        if(isMISReport) {
            requestBody['reportRequest']['isCustomerProposal'] = 'Y';
        }
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['policyPlanCoverageInfo'] = coverageList;
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }
    public getDailyTrasactionGridData(startDate: any, endDate: any, productType: any, productlist: any, branchId: any, isMisReport: Boolean,
        branchName: any, regionName: any) {
        const url = this.hostUrl + 'api/report/getNoOfPolicies';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY 00:00:00');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY 23:59:59');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['branchName'] = branchName;
        requestBody['reportRequest']['regionName'] = regionName;
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        if (isMisReport) {
            requestBody['reportRequest']['isCustomerProposal'] = 'Y';
        }
        requestBody['reportRequest']['branchId'] = branchId;
        return this.httpClient.post(url, requestBody, { headers: HEADER })
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError);
    }
    public getPolicyAndPlansForFromAndToDate(startDate: any, endDate: any, productType: any, productlist: any, branchId: any, isMISReport: Boolean) {
        const url = this.hostUrl + 'api/report/getPolicyWithPlan';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        if(isMISReport) {
            requestBody['reportRequest']['isCustomerProposal'] = 'Y';
        }
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['branchId'] = branchId;
        return this.httpClient.post(url, requestBody, { headers: HEADER })
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError);
    }
    public getBusinessGridData(startDate: any, endDate: any, productType: any,  productlist: any, branchId: any) {
        const url = this.hostUrl + 'api/report/getBusinessReportGridData';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['branchId'] = branchId;
        return this.httpClient.post(url, requestBody, { headers: HEADER })
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError);
    }

    public getBusinessReport(startDate: any, endDate: any, productType: any, branchdata: any,  productlist: any, branchName: any, regionName: any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        let url: any;
        url = this.hostUrl + 'api/report/businessReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['branchName'] = branchName;
        requestBody['reportRequest']['regionName'] = regionName;
        requestBody['reportRequest']['branchDetailMap'] = branchdata;
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }


    public EndorsementBuzzGridData(startDate: any, endDate: any, productType: any,  productlist: any, branchId: any) {
        const url = this.hostUrl + 'api/report/getHealthBuzzReportGridData';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['branchId'] = branchId;
        return this.httpClient.post(url, requestBody, { headers: HEADER })
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError);
    }
    public getEndorsemenBuzzReport(startDate: any, endDate: any,  productType: any,  productlist: any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        const url = this.hostUrl + 'api/report/healthBuzzReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        // requestBody['reportRequest']['branchDetailMap'] = branchdata;
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }

    public getEndorsemenBuzzBPReport(startDate: any, endDate: any,  productType: any,  productlist: any, coverageList: any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        const url = this.hostUrl + 'api/report/healthBuzzReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY-MM-DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY-MM-DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        requestBody['reportRequest']['productIdsByAffinity'] = productlist;
        requestBody['reportRequest']['policyPlanCoverageInfo'] = coverageList;
        // requestBody['reportRequest']['branchDetailMap'] = branchdata;
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }
    public getBranchDetails(branchId: string) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.hostUrl + 'api/user/branchId/' + branchId;
        return this.httpClient
            .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    public getProductListForAffinity(affinityId: string) {
        let url: any;
        url = this.hostUrl + 'api/admin/affinity/product/list/' + affinityId;
        return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
      }

      public getRegionBranchId(regionName: String) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.hostUrl + 'api/user/getBranchId/regionName/' + regionName;
        return this.httpClient
            .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    public getBranchId(branchName: String) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.hostUrl + 'api/user/getBranchId/branchName/' + branchName;
        return this.httpClient
            .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }


    public getReportType(roleId: String, lobid: any) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.hostUrl + 'api/report/getReportNames';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['roleId'] = roleId;
        requestBody['reportRequest']['lobid'] = lobid;
        return this.httpClient.post(url, requestBody, { headers: HEADER })
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError);
    }

    public getlobId() {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.hostUrl + 'api/admin/lob/list';
        return this.httpClient
            .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }
    
    public getCoverageAndSectionsReport(policyAndPlanIdList) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.hostUrl + 'api/rating/coverage';
        return this.httpClient
          .post(url, policyAndPlanIdList, { headers: HEADER })
          .toPromise()
          .then((response: Response) => response)
          .catch(this.handleError);
      }

      public getJeweleryTrasactionGridData(startDate: any, endDate: any, productType: any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        const url = this.hostUrl + 'api/report/getNoOfPolicies';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY 00:00:00');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY 23:59:59');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY/MM/DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY/MM/DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
     
        return this.httpClient.post(url, requestBody, { headers: HEADER })
            .toPromise()
            .then((response: any) => response)
            .catch(this.handleError);

    }

    public getDailyTransactionjewelReport(startDate: any, endDate: any, productType: any) {
        const headers = new Headers();
        headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
        const options = new RequestOptions({ headers: headers });
        options.responseType = ResponseContentType.Blob;
        const url = this.hostUrl + 'api/report/oldDailyTransactionReport';
        const requestBody = new Object();
        requestBody['reportRequest'] = new Object();
        requestBody['reportRequest']['policyFromDate'] = moment(startDate).local().format('DD/MM/YYYY 00:00:00');
        requestBody['reportRequest']['policyToDate'] = moment(endDate).local().format('DD/MM/YYYY 23:59:59');
        requestBody['reportRequest']['policyStartDate'] = moment(startDate).local().format('YYYY/MM/DD 00:00:00');
        requestBody['reportRequest']['policyEndDate'] = moment(endDate).local().format('YYYY/MM/DD 23:59:59');
        requestBody['reportRequest']['businessType'] = productType;
        return this.http.post(url, requestBody, options)
            .toPromise().then((response: Response) => response).catch(this.handleError);
    }
 

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }

    public getProductListForAllAffinities() {
        let url: any;
        url = this.hostUrl + 'api/admin/affinity/product/list';
        return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
      }
}
