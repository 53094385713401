import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { HEADER } from 'src/common/common.constants';
import { AppConfig } from 'src/config/app.config';

@Injectable()
export class PaymentService {
    private hostUrl: string;
    constructor(private httpClient: HttpClient,
        private configService: AppConfig) {
        this.hostUrl = this.configService.getConfig('productHost');
    }

    public getPaymentGatewayList() {
        const api = this.hostUrl + 'api/payment/list/gateway';
        return this.httpClient.get(api, { headers: HEADER })
            .toPromise().then((response: any) => response)
            .catch(error => this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
