import { Component, OnInit } from '@angular/core';
import { ProposalVO } from '../dto/ProposalVO';
import { CommunicationVO } from '../dto/CommunicationVO';
import { BeneficiaryVO } from '../dto/BeneficiaryVO';
import { PremiumDetailsVO } from '../dto/PremiumDetailsVO';
import { ProposalServices } from '../services/proposal.services';
import { ActivatedRoute } from '@angular/router';
import { CustomerDetailsVO } from 'src/dto/CustomerDetailsVO';
import { PRODUCT_CODE, SAHAJ_AND_LIB_OCCUPANCY, SHOP_OCCUPANCY_TARIFF, HOME_OCCUPANCY_AND_TARIFF, SHOP_OCCUPANCY, USER_PROFILE, INTERACTIVE_TRADEX_SHOP_OCCUPANCY } from '../common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import * as moment from 'moment';
import { RiskLocationVO } from 'src/dto/RiskLocationVO';
import { PaymentDetailsVO } from 'src/dto/PaymentDetailsVO';
import { DatePipe } from '@angular/common';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.css']
})
export class PaymentComponent implements OnInit {
  public hasErrorMessage: Boolean = false;
  public message: any = '';
  public paymentError = false;
  public customerDetail: CustomerDetailsVO = new CustomerDetailsVO();
  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public riskLocationdetail : RiskLocationVO = new RiskLocationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public paymentDetail: PaymentDetailsVO = new PaymentDetailsVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public loadingmessage = '';
  public certificateNumber: any;
  public downloadFailed: Boolean = false;
  public lobId: Number;
  public productId: any;
  public mockProposal: any;
  public mockCertificateProposal: any;
  public tempPlanId: any;
  public sumInsuredForCoverages: any;
  public coverageIds: Object = new Object();
  public coverageSectionType1: Array<any> = new Array<any>();
  public coverageSectionType2: Array<any> = new Array<any>();
  public applianceDetail: Array<any> = new Array<any>();
  public shortProdName: any;
  public prodName: any;
  public lobName: any;
  public producDesc: any;
  public titleDes: any;
  public txnDetails: any;
  public shortProductName: any;
  public productName: any;
  public isMuthootCustomer:Boolean = false;
  


  constructor(
    private proposalServices: ProposalServices,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) { }

  ngOnInit() {
    EmitterService.get('HIDE_USER_DETAIL').emit(true);
    this.premiumDetail.gatewayId = 3;
    let transID;
    // let certificateNumber;

    this.route.queryParams.subscribe(params => {
      transID = params.txnid;
      if (transID.includes('T')) {
        const data = transID.split('T');
        const transIdNum = data[0];
        this.sendTransId(transIdNum);
      } else {
        this.sendTransId(transID);
      }
    }
    );

   // if ( this.certificateNumber === undefined) {

    this.proposerVo.communicationDetails = this.communicationDetail;
    this.proposerVo.riskLocationDetails = this.riskLocationdetail;
    this.proposerVo.paymentDetails = this.paymentDetail;
    
}
  
  public sendTransId(transIdNumber) {
    this.proposalServices.getPaymentTransDetails(transIdNumber).then(data => {
      this.producDesc = data.productDescription;
   //   this.getPaymentKeyDisable();
      if (data !== undefined) {
        if (data.paymentDetails.status !== undefined && data.paymentDetails.status.toLowerCase() === 'success') {
        this.certificateNumber = data.certificateNo;
      //  this.message = data.certificateNo;
        this.txnDetails = data;
        this.shortProductName = data.shortProductName;
        const affinityId = data.affinityId;
        
        this.proposalServices.getAffinityDetailForEdit(affinityId).then(data => {
          if (data !== undefined && data !== '') {
            this.proposerVo.agentId = data['agentId'];
            this.proposerVo.agentContactNumber = data['agentContactNumber'];
            this.proposerVo.agentName= data['agentName'];
            this.proposerVo.affinityName = data['affinityName'];
            if (data['affinityLogo'] !== undefined && data['affinityLogo'] !== '') {
              this.proposerVo.affinityLogo = 'Logo_' + data ['affinityLogo'];
            } else {
              this.proposerVo.affinityLogo = '';
            }
            this.proposalServices
          .getProposalSummaryByCertificatePayment(this.certificateNumber,this.proposerVo.agentName)
          .then(data2 => {
            this.loadingmessage = 'Please wait...';
            if (data2 !== undefined) {
              this.summaryData(data2);
              if (this.shortProductName === 'Home') {
                this.getBusinessPlanDetails(data2.planId);
              }
             if (this.shortProductName !== 'Health') {
                this.getCovergeAndSections(data2.planId);
              }
              if (this.shortProductName === 'Health') {
                this.coverageIdsPolicyDetails();
              }
            }
          })
          .catch(error => { });
          }
          }).catch(error => { });
        } else {
          this.paymentError = true;
        }
      }
    }).catch(error => { });

   }
  public getProdAndLobDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      this.proposalServices.getAvailableProducts(affinityId).then(data1 => {
        const productData = data1;
        if (productData != null) {
          sessionStorage.setItem('prodcutDetails', JSON.stringify(productData));
          this.setShortProdName();
        }
      }).catch(error => {
      });
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  public summaryData(data) {
    const proposerDetails = data;
    this.proposerVo.policyStartDate = proposerDetails.policyStartDate;
    this.proposerVo.policyTerm = proposerDetails.policyTermDescription;
    this.proposerVo.policyEndDate = proposerDetails.policyEndDate;
    this.proposerVo.masterPolicyNo = proposerDetails.masterPolicyNo;
    this.proposerVo.policyTypeName = proposerDetails.policyTypeDescription;
    this.proposerVo.planTypeName = proposerDetails.planDescription;
    this.proposerVo.covernoteNo = proposerDetails.covernoteNo;
    this.proposerVo.sumInsured = proposerDetails.sumInsured;
    this.proposerVo.customerId = proposerDetails.customerId;
    this.proposerVo.employeeId = proposerDetails.employeeId;
    this.proposerVo.affinityId = proposerDetails.affinityId;
    this.proposerVo.placeOfSupply = proposerDetails.placeOfSupply;
    this.proposerVo.policyIssueDate = proposerDetails.policyIssueDate;
    this.proposerVo.branchCode = proposerDetails.branchCode;
    this.proposerVo.branchName = proposerDetails.branchName;
    this.proposerVo.paymentDetails.otpVerifiedDate = proposerDetails.paymentDetails.otpVerifiedDate;
    this.proposerVo.isCustomerProposal = proposerDetails.isCustomerProposal;
    if(proposerDetails.ssoTokenUser !== undefined) {
      this.proposerVo.ssoTokenUser = proposerDetails.ssoTokenUser;
    }
    this.productName = proposerDetails.producDesc;
    sessionStorage.setItem(USER_PROFILE, JSON.stringify(proposerDetails.affinityAuthToken));
    // commenting, we are getting the agent info from affinity tables.
    // this.proposerVo.agentId = proposerDetails.agentId;
    if (proposerDetails.productAnswers !== undefined) {
      this.questionList = proposerDetails.productAnswers;
    }
    if (proposerDetails.communicationDetails !== undefined) {
      const proposerCommDtls = proposerDetails.communicationDetails;
      this.proposerVo.communicationDetails.address1 = proposerCommDtls.address1;
      this.proposerVo.communicationDetails.address2 = proposerCommDtls.address2;
      this.proposerVo.communicationDetails.address3 = proposerCommDtls.address3;
      this.proposerVo.communicationDetails.pincode = proposerCommDtls.pincodeLocality;
      this.proposerVo.communicationDetails.stateName = proposerCommDtls.stateName;
      this.proposerVo.communicationDetails.districtName =
        proposerCommDtls.districtName;
      this.proposerVo.communicationDetails.cityName = proposerCommDtls.cityName;
      this.proposerVo.communicationDetails.emailId = proposerCommDtls.emailId;
      this.proposerVo.communicationDetails.mobile = proposerCommDtls.mobile;
      this.proposerVo.communicationDetails.telephone =
        proposerCommDtls.telephone;
      this.proposerVo.communicationDetails.gstinNo = proposerCommDtls.gstinNo;
    }
    if (proposerDetails.riskLocationDetails !== undefined) {
      const proposerRisDtls = proposerDetails.riskLocationDetails;
      this.proposerVo.riskLocationDetails.address1 = proposerRisDtls.address1;
      this.proposerVo.riskLocationDetails.address2 = proposerRisDtls.address2;
      this.proposerVo.riskLocationDetails.address3 = proposerRisDtls.address3;
      this.proposerVo.riskLocationDetails.pincode = proposerRisDtls.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = proposerRisDtls.stateName;
      this.proposerVo.riskLocationDetails.districtName =
      proposerRisDtls.districtName;
      this.proposerVo.riskLocationDetails.cityName = proposerRisDtls.cityName;
      this.proposerVo.riskLocationDetails.emailId = proposerRisDtls.emailId;
      this.proposerVo.riskLocationDetails.mobile = proposerRisDtls.mobile;
      this.proposerVo.riskLocationDetails.telephone =
      proposerRisDtls.telephone;
      this.proposerVo.riskLocationDetails.gstinNo = proposerRisDtls.gstinNo;
    }
    if (proposerDetails.customerDetails !== undefined) {
      this.customerDetail = proposerDetails.customerDetails;
      this.titleDes = proposerDetails.customerDetails.titleDescription;
      // const customerDetails = data.customerDetails;
      this.proposerVo.customerId = this.customerDetail.customerId;
      this.proposerVo.employeeId = this.customerDetail.employeeId;
    }
    if (proposerDetails.premiumDetails !== undefined) {
      const premiumDetails = proposerDetails.premiumDetails;
      this.premiumDetail.cgst = premiumDetails.cgst;
      this.premiumDetail.sgst = premiumDetails.sgst;
      this.premiumDetail.igst = premiumDetails.igst;
      this.premiumDetail.cessTax = premiumDetails.cessTax;
      this.premiumDetail.netPremium = premiumDetails.totalNetPremium;
      this.premiumDetail.grossPremium = premiumDetails.totalGrossPremium;
    }
    this.adultList = [];
    this.childList = [];
    // adult and child list seperation.
    if (proposerDetails.beneficiaryList.length !== 0) {
      this.beneficiaryList = proposerDetails.beneficiaryList;
      for (let i = 0; i < this.beneficiaryList.length; i++) {
        if (this.beneficiaryList[i].nomineeType === 2) {
          this.childList.push(this.beneficiaryList[i]);
        }
        if (this.beneficiaryList[i].nomineeType === 1) {
          this.adultList.push(this.beneficiaryList[i]);
        }
      }
    }
    if (proposerDetails.applianceDetails !== undefined) {
      this.proposerVo.applianceDetails = proposerDetails.applianceDetails;
    }
  }
public getAffinityDetails(affinityId) {
  this.proposalServices.getAffinityDetailForEdit(affinityId).then(data => {
  if (data !== undefined && data !== '') {
    this.proposerVo.agentId = data['agentId'];
    this.proposerVo.agentContactNumber = data['agentContactNumber'];
    this.proposerVo.agentName= data['agentName'];
    if (data['affinityLogo'] !== undefined && data['affinityLogo'] !== '') {
      this.proposerVo.affinityLogo = 'Logo_' + data ['affinityLogo'];
    } else {
      this.proposerVo.affinityLogo = '';
    }
  }
  }).catch(error => { });
}
  public getBusinessPlanDetails(planId) {
    this.proposalServices.getApplianceDetailsPayment(planId).then(data => {
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < this.proposerVo.applianceDetails.length; i++) {
          if (data[j].appliances.applianceID === this.proposerVo.applianceDetails[i].applianceID) {
            const appliance = new Object();
            appliance['applianceName'] = data[j].appliances.applianceName;
            appliance['quantity'] = this.proposerVo.applianceDetails[i].quantity;
            this.applianceDetail[j] = appliance;
          }
        }
      }
    }).catch(error => { });
  }
  getCovergeAndSections(planId) {
    this.proposalServices.getSumInsuredDetailsPayment(planId).then(data => {
      this.sumInsuredForCoverages = data;
      const allCoverAgeIds: Array<any> = new Array<any>();
      if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          allCoverAgeIds.push(data[i].coverageId);
          this.coverageIds['coverageIds'] = allCoverAgeIds;
        }
        this.getCoverageAndSectionForCoverageID();
      }
    }).

    catch(error => {
    });

  }


public coverageIdsPolicyDetails() {
const coverageIdsPolicyDetails:Object = new Object();
coverageIdsPolicyDetails['coverageSectionType1'] = new Array<Object>();
coverageIdsPolicyDetails['coverageSectionType1'] = this.coverageSectionType1;
coverageIdsPolicyDetails['coverageSectionType2'] = new Array<Object>();
coverageIdsPolicyDetails['coverageSectionType2'] = this.coverageSectionType2;
coverageIdsPolicyDetails['policyDetails'] = new Object();
this.txnDetails.affinityLogo = this.proposerVo.affinityLogo;
this.txnDetails.agentName = this.proposerVo.agentName;
this.txnDetails.agentContactNumber = this.proposerVo.agentContactNumber;
this.txnDetails.affinityName = this.proposerVo.affinityName;
coverageIdsPolicyDetails['policyDetails'] = this.txnDetails;
this.message = this.certificateNumber;
this.loadingmessage = '';
  if (this.proposerVo.isCustomerProposal === 'Y' && this.proposerVo.ssoTokenUser !== null) {
    this.isMuthootCustomer = true;
    this.saveIMuthootProposal();
  } else {
    this.isMuthootCustomer = false;
  }
this.proposalServices.saveCoverageIds(JSON.stringify(coverageIdsPolicyDetails)).then(data => {

});
  }

  public getCoverageAndSectionForCoverageID() {
    this.proposalServices.getCoverageAndSectionsPayment(this.coverageIds).then(data2 => {
      let sectionType1;
      let sectionType2;
      for (let i = 0; i < data2.length; i++) {
        sectionType1 = data2[i].sectionType1;
        sectionType2 = data2[i].sectionType2;
      }
      this.coverageSectionType2 = [];
      for (let i = 0; i < sectionType1.length; i++) {

        for (let j = 0; j < sectionType2.length; j++) {
          if (sectionType1[i].sectionId === sectionType2[j].sectionId) {
            const coverageSectionType2 = new Object();
            coverageSectionType2['coverage'] = sectionType1[i].coverage;
            coverageSectionType2['riskCovered'] = sectionType1[i].riskCovered;
            coverageSectionType2['limitperitem'] = sectionType2[j].coverage;
            for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
              if (this.sumInsuredForCoverages[m].coverageId === sectionType1[i].coverageId) {
                coverageSectionType2['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
              if (this.sumInsuredForCoverages[m].coverageId === sectionType2[j].coverageId) {
                coverageSectionType2['limitperitemsuminsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
            }
            this.coverageSectionType2.push(coverageSectionType2);
            delete sectionType1[i].sectionId;
            delete sectionType1[i].coverage;
            delete sectionType1[i].riskCovered;
            delete sectionType1[i].coverageId;
          }
        }
      }
      this.coverageSectionType1 = [];
      for (let j = 0; j < sectionType1.length; j++) {
        if (sectionType1[j].coverage !== undefined) {
          const coverageSectionType = new Object();
          coverageSectionType['coverage'] = sectionType1[j].coverage;
          coverageSectionType['riskCovered'] = sectionType1[j].riskCovered;
          for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
            if (this.sumInsuredForCoverages[m].coverageId === sectionType1[j].coverageId) {
              coverageSectionType['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
            }
          }
          this.coverageSectionType1.push(coverageSectionType);
        }
      }
      this.coverageIdsPolicyDetails();
    }).catch(error => {
    });
  }

  public saveIMuthootProposal() {
    let proposalSave = new Object();
    proposalSave['saveJson'] = new Object();
    proposalSave['saveJson']['methodName'] = 'UpdatePolicyStatusResponse';
    proposalSave['saveJson']['token'] = this.proposerVo.ssoTokenUser;
    proposalSave['saveJson']['customerId'] = this.customerDetail.customerId;
    proposalSave['saveJson']['clientCode'] = '2';
    proposalSave['saveJson']['productId'] = '1';
    proposalSave['saveJson']['productName'] = this.producDesc;
    proposalSave['saveJson']['policyNumber'] = this.proposerVo.certificateNo;
    proposalSave['saveJson']['policyAmount'] = this.premiumDetail.grossPremium;
    proposalSave['saveJson']['policyDate'] = moment(this.proposerVo.policyStartDate, 'DD/MM/YYYY HH:mm:ss').local().format('YYYY-MM-DD');
    proposalSave['saveJson']['expiryDate'] = moment(this.proposerVo.policyEndDate, 'DD/MM/YYYY HH:mm:ss').local().format('YYYY-MM-DD');
    proposalSave['saveJson']['createdThrough'] = '1';
    proposalSave['saveJson']['premiumAmount'] = this.premiumDetail.grossPremium;
    proposalSave['saveJson']['apiVersion'] = '1';
    const saveData = JSON.stringify(proposalSave);
    this.proposalServices.muthootStatusUpdate(saveData).then(data => {
      const response = data['Response'];
      for(let i=0; i< response.length; i++) {
        if(response[i]['ERROR_MSG'].toLowerCase() === 'success'){
          const statusResult = data['Result1'];
        }
      }

    }).catch(error => {

    });
  }

  public printProposal() {
    this.moveToTop();
  //  this.productId = sessionStorage.getItem(PRODUCT_CODE);
    //  if (this.productId === '1') {
    //     this.lobId = 1;
    //   } else {
    //     this.lobId = 2;
    //   }
    this.hasErrorMessage = false;
    // this.message = '';
    this.loadingmessage = 'Preparing Document...';
    this.downloadFailed = false;

    const printProsalJson: Object = new Object();
    printProsalJson['proposalDetails'] = new Object();
    // printProsalJson['proposalDetails']['templateName'] = new Object();
    printProsalJson['applicationName'] = 'affinity';
    printProsalJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printProsalJson['dateOfIssue'] = this.proposerVo.policyIssueDate;
    printProsalJson['affinityName'] = this.proposerVo.affinityName;
    if (this.shortProductName === 'Health') {
      printProsalJson['proposalDetails']['templateName'] = 'ProposalForm';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else if (this.shortProductName === 'Shop') {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposalShop';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposal';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    }

    // printProsalJson['proposalDetails']['templateName'] = 'ProposalForm';
    // printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    printProsalJson['proposalDetails']['proposalInfo'] = new Object();

    printProsalJson['proposalDetails']['proposalInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo'][
      'employeeInfo'
    ] = new Object();
    if (this.shortProductName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.employeeId;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.agentName;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'licenseCode'
      ] = this.proposerVo.agentId;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'contactNumber'
      ] =this.proposerVo.agentContactNumber;
      // const agentDetails = this.proposerVo.agentId.split('/');
      // printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //   'employeeCode'
      // ] = agentDetails[0];
      // if (agentDetails[1] !== undefined) {
      //   printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //     'licenseCode'
      //   ] = agentDetails[1];
      // }
      // if (agentDetails[2] !== undefined) {
      //   printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      //     'contactNumber'
      //   ] = agentDetails[2];
      // }
    }
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchName'
    ] = this.proposerVo.branchName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchId'
    ] = this.proposerVo.branchCode;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['city'] =
      this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['state'] =
      this.proposerVo.communicationDetails.stateName;

    printProsalJson['proposalDetails']['proposalInfo'][
      'personInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'lastName'
    ] = this.customerDetail.lastName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'firstName'
    ] = this.customerDetail.firstName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'middleName'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address1'
    ] = this.proposerVo.communicationDetails.address1;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address2'
    ] = this.proposerVo.communicationDetails.address2;


    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address3'
    ] = this.proposerVo.communicationDetails.address3;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'city'
    ] = this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'state'
    ] = this.proposerVo.communicationDetails.stateName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'pinCode'
    ] = this.proposerVo.communicationDetails.pincode;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'telephone'
    ] = this.proposerVo.communicationDetails.telephone;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'mobile'
    ] = this.proposerVo.communicationDetails.mobile;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'email'
    ] = this.proposerVo.communicationDetails.emailId;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'salariedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'selfemployedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'otherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'detailsOfOtherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'indianResident'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'nonIndianResident'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'policyType'
    ] = new Object();
    if (this.proposerVo.policyTypeName === 'Individual') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = '';
    } else if (this.proposerVo.policyTypeName === 'Family Floater') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'false';
    }

    printProsalJson['proposalDetails']['proposalInfo']['planType'] = new Object();
    if (this.proposerVo.planTypeName === '2 Adults') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adult 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adult 2 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult 2 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'false';
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'typeOfBusiness'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness']['fresh'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness'][
      'renewal'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'sumInsuredInfo'
    ] = new Object();

    if (this.proposerVo.sumInsured === '2 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '3 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '5 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'false';
    }
    const date =this.proposerVo.policyEndDate.split(' ');
    printProsalJson['proposalDetails']['proposalInfo'][
      'policyDuration'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'from'
    ] = this.proposerVo.policyStartDate;
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'to'
    ] = date[0] + ' 23:59:59';




    printProsalJson['proposalDetails']['proposalInfo'][
      'insured1Info'
    ] = new Object();


    //   if (this.proposerVo.affinityId !== 2) {
    //   printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'name'
    //   ] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
    //   } else {
    //     printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'name'
    //   ] = this.customerDetail.firstName;
    // }

    //   if (this.proposerVo.affinityId !== 2) {
    //   printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'relationshipWithProposer'
    //   ] = this.adultList[0].proposerRelationDescription;
    // } else {
    //   printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'relationshipWithProposer'
    //   ] = '';
    // }

    //   if (this.proposerVo.affinityId !== 2) {
    //   printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'gender'
    //   ] = this.adultList[0].genderDescription;

    //   } else {
    //     printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'gender'
    //   ] = '';
    // }



    // if (this.proposerVo.affinityId !== 2) {
    // printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //   'dob'
    // ] = this.adultList[0].dob;
    //  } else {
    //    printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //   'dob'
    // ] = '';
    //  }

    //  if (this.proposerVo.affinityId !== 2) {
    //   printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'nomineeName'
    //   ] = this.adultList[0].nomineeName;
    //    } else {
    //       printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //     'nomineeName'
    //   ] = '';
    //    }

    //         if (this.proposerVo.affinityId !== 2) {
    // printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //   'relationshipOfNominee'
    // ] =  this.adultList[0].nomineeRelationDescription;
    //     } else {
    //       printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
    //   'relationshipOfNominee'
    // ] = '';
    //     }
    if (this.adultList !== undefined) {
    if (this.adultList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured1Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = this.adultList[0].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = this.adultList[0].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = this.adultList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = this.adultList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = this.adultList[0].nomineeRelationDescription;
    }
    if (this.adultList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured2Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'name'
      ] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipWithProposer'
      ] = this.adultList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'gender'
      ] = this.adultList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'dob'
      ] = this.adultList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'nomineeName'
      ] = this.adultList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipOfNominee'
      ] = this.adultList[1].nomineeRelationDescription;
    }
  }
  if (this.childList !== undefined) {
    if (this.childList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured3Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'name'
      ] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipWithProposer'
      ] = this.childList[0].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'gender'
      ] = this.childList[0].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'dob'
      ] = this.childList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'nomineeName'
      ] = this.childList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipOfNominee'
      ] = this.childList[0].nomineeRelationDescription;
    }
    if (this.childList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured4Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'name'
      ] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipWithProposer'
      ] = this.childList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'gender'
      ] = this.childList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'dob'
      ] = this.childList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'nomineeName'
      ] = this.childList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipOfNominee'
      ] = this.childList[1].nomineeRelationDescription;
    }
  }
    printProsalJson['proposalDetails']['proposalInfo'][
      'paymentInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cash'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cheque'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['dd'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['others'] =
      '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'medicalQuestion'
    ] = new Object();


    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['customerId'] = this.proposerVo.customerId;
    if (this.shortProductName !== 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'address1'
      ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3;
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'city'
      ] = this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName;
      if (this.proposerVo.riskLocationDetails !== undefined) {
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationAddress'
              ] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' + this.proposerVo.riskLocationDetails.address3;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationCity'] = this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' +
              this.proposerVo.riskLocationDetails.stateName;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationPincode'
              ] = this.proposerVo.riskLocationDetails.pincode;
      }
    }
    const appno = this.certificateNumber.substring(this.certificateNumber.length - 12, this.certificateNumber.length);
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['applicationNo'] = appno;
    if (this.proposerVo.policyTypeName !== 'Individual' && this.proposerVo.policyTypeName !== 'Family Floater') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['customerId'] = this.proposerVo.customerId;
      const appno = this.certificateNumber.substring(this.certificateNumber.length - 12, this.certificateNumber.length);
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['applicationNo'] = appno;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['date'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['financierBranchName'] = null;
      if (this.shortProductName === 'Shop') {
        if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
          || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
          printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
            'occupancy'
          ] = SAHAJ_AND_LIB_OCCUPANCY;
        } else  if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
          printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
            'occupancy'
          ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
        } else {
          printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
            'occupancy'
          ] = SHOP_OCCUPANCY;
        }
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancyTariff'
        ] = SHOP_OCCUPANCY_TARIFF;
  
      } else if (this.shortProductName === 'Home') {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancyTariff'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'loanAccountNo'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'planName'
      ] = this.proposerVo.planTypeName;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'otpVerifiedDate'
      ] = this.proposerVo.paymentDetails.otpVerifiedDate;
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType2'] = this.coverageSectionType2;
      printProsalJson['proposalDetails']['proposalInfo']['equipment'] = new Array();
      
      let serialCounter: Number = 0;
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printProsalJson['proposalDetails']['proposalInfo']['equipment'].push(obj1);
        // printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = this.mockCertificateProposal['certificateDetails']['certificateInfo']['equipment'];
      }
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = new Array();
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['excess'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['certificateDetails'] = null;
      // if (this.premiumDetail.netPremium) {
      //   const obj2: Object = new Object();
      //   obj2['premiumType'] = 'Net Premium(Taxable value)';
      //   obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      // } else {
      //   const obj2: Object = new Object();
      //   obj2['premiumType'] = 'Net Premium(Taxable value)';
      //   obj2['premiumValue'] = '';
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      // }

      // if (this.premiumDetail.cgst) {
      //   const obj3: Object = new Object();
      //   obj3['premiumType'] = 'CGST(9%)';
      //   obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      // } else {
      //   const obj3: Object = new Object();
      //   obj3['premiumType'] = 'CGST(9%)';
      //   obj3['premiumValue'] = '';
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      // }


      // if (this.premiumDetail.sgst) {
      //   const obj4: Object = new Object();
      //   obj4['premiumType'] = 'SGST(9%)';
      //   obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      // } else {
      //   const obj4: Object = new Object();
      //   obj4['premiumType'] = 'SGST(9%)';
      //   obj4['premiumValue'] = '';
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      // }

      // if (this.premiumDetail.igst) {
      //   const obj5: Object = new Object();
      //   obj5['premiumType'] = 'IGST(18%)';
      //   obj5['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj5);
      // }

      // if (this.premiumDetail.grossPremium) {
      //   const obj6: Object = new Object();
      //   obj6['premiumType'] = 'Premium Including Tax';
      //   obj6['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      // } else {
      //   const obj6: Object = new Object();
      //   obj6['premiumType'] = 'Net Premium(Taxable value)';
      //   obj6['premiumValue'] = '';
      //   printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      // }


    }
    //  else {
    //        printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = '';
    //        printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType2'] = '';
    //        printProsalJson['proposalDetails']['proposalInfo']['equipment'] = '';
    //        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = '';
    //   }

    if (printProsalJson) {
      this.proposalServices
        .getPrintProposalpayment(JSON.stringify(printProsalJson))
        .then(data1 => {
          const parsedResponse = data1; // .blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printFrom.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.proposerVo.covernoteNo +'.pdf'; //printFrom.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.downloadFailed = true;
          this.loadingmessage = '';
          // this.paymentError = true;
          // this.loadingmessage = '';
        });
    }
  }

  public setShortProdName() {
    let lobDetails;
    lobDetails = this.getProductIdAndLobName(this.prodName);
    this.lobId = lobDetails['lobId'];
    this.shortProdName = lobDetails['shortProductName'];
  //  this.productId = sessionStorage.getItem(PRODUCT_CODE);
    //   this.preparePageParams();
  }
  public getProductIdAndLobName(prodName: any) {
    const productDetails = sessionStorage.getItem('prodcutDetails');
    if (productDetails) {
      const prodcutArrayObj: Array<Object> = JSON.parse(productDetails);
      if (prodcutArrayObj) {
        for (const prodType of prodcutArrayObj) {
          if (prodType['productName'] === prodName) {
            const productID = prodType['productId'];
            this.producDesc = prodType['productInfo'];
            sessionStorage.setItem(PRODUCT_CODE, productID);
            return this.producDesc;
          }
        }
      }
    }
  }
  public printCertificate() {
    this.moveToTop();
    this.loadingmessage = 'Preparing Document...';
    this.downloadFailed = false;
 // this.productId = sessionStorage.getItem(PRODUCT_CODE);

    const certificateNo = this.certificateNumber;
    const printCertificateJson: Object = new Object();
    printCertificateJson['certificateDetails'] = new Object();
    printCertificateJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printCertificateJson['affinityName'] = this.proposerVo.affinityName;
    printCertificateJson['certificateDetails']['affinityName'] = this.proposerVo.affinityName;
    if (this.shortProductName === 'Health') {
      printCertificateJson['certificateDetails']['templateName'] = 'CertificateForm';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    } else if (this.shortProductName === 'Shop') {
      printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificateShop';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificate';
      if(this.proposerVo.affinityName.startsWith("MUTHOOTTU"))
      {
          printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageMiniMuthootCertificate';
      }
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    }
    printCertificateJson['applicationName'] = 'affinity';
   // printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;


    printCertificateJson['certificateDetails'][
      'certificateInfo'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo'][
      'policyInfo'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'masterPolicyNo'
    ] = this.proposerVo.covernoteNo;
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'groupAdministrator'
     ] = 'Manappuram Finance Ltd, Kerala';
    if (this.proposerVo.policyTypeName === 'Individual') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyVariant'
      ] = 'Individual';
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyVariant'
      ] = 'Family Floater';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'certificateNo'
    ] = certificateNo;
    if(this.titleDes!==undefined && this.customerDetail.lastName!==undefined)
    {
       printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.titleDes + ' '+ this.customerDetail.firstName + ' ' + this.customerDetail.lastName;
    }else
    {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.customerDetail.firstName;
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'insuredAddress'
    ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3 +
     ' ' + this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName +
     ' ' + this.proposerVo.communicationDetails.pincode;


if (this.txnDetails.riskLocationDetails !== undefined) {
  if (this.txnDetails.riskLocationDetails.address1 === undefined) {
    this.txnDetails.riskLocationDetails.address1 = '';
  }
  if (this.txnDetails.riskLocationDetails.address2 === undefined) {
    this.txnDetails.riskLocationDetails.address2 = '';
  }
  if (this.txnDetails.riskLocationDetails.address3 === undefined) {
    this.txnDetails.riskLocationDetails.address3 = '';
  }
  printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
   'riskLocationAddress'
 ] = this.txnDetails.riskLocationDetails.address1 + ' ' + this.txnDetails.riskLocationDetails.address2 + ' ' + this.txnDetails.riskLocationDetails.address3 +
  ' ' + this.txnDetails.riskLocationDetails.cityName + ' ' + this.txnDetails.riskLocationDetails.districtName + ' ' + this.txnDetails.riskLocationDetails.stateName +
  ' ' + this.txnDetails.riskLocationDetails.pincodeLocality;
}


    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'customerNo'
    ] = this.proposerVo.customerId;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'tpaCode'
    ] = '003 / Medi Assist India TPA Pvt. Ltd';
    if (this.shortProductName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      } else if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
      } else {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProductName === 'Home') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancy'
      ] = HOME_OCCUPANCY_AND_TARIFF;
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'proposalNo'
    ] = this.proposerVo.covernoteNo;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'planName'
    ] = this.proposerVo.planTypeName;
    const endL = this.txnDetails.policyEndDate.split(' ');
        const startL1 = this.txnDetails.policyStartDate.split(' ');
  /// printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['COIPeriod'] = startL1[0]+ ' ' +"to" +' ' +endL;
   printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['periodOfInsurance'] = startL1[0] + ' to ' + endL[0];
    // printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
    //   'COIPeriod'
    // ] = this.proposerVo.policyStartDate + ' to ' + this.proposerVo.policyEndDate;
    if (this.proposerVo.policyTypeName === 'Individual' || this.proposerVo.policyTypeName === 'Family Floater') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyTenure'
      ] = this.proposerVo.policyTerm;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyTenure'
      ] = startL1[0] + ' 00:00:00' + ' to ' + endL[0] + ' 23:59:59';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerName'] = this.txnDetails.customerDetails.customerName;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'policyServicingOffice'
    ] = 'Liberty General Insurance Ltd. Door No. 39/4166-A, 2nd Floor, Shema Building,M.G Road, Ravipuram, Kochi - 682016 Boardline - 484-6900 010,Fax - 022 6700 1606';
    if (this.beneficiaryList !== undefined) {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'relationshipWithInsured'
      ] = this.beneficiaryList[0].proposerRelationDescription;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'relationshipWithInsured'
      ] = '';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'sumInsured'
    ] = this.proposerVo.sumInsured;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'emailID'
    ] = this.proposerVo.communicationDetails.emailId;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'tollfreeNo'
    ] = '';

if (this.proposerVo.policyTypeName === 'Individual' || this.proposerVo.policyTypeName === 'Family Floater') {
if (this.adultList.length > 0) {

      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithPrimaryMember'] = this.adultList[0].proposerRelationDescription;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = this.adultList[0].genderDescription;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = this.adultList[0].dob;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = this.adultList[0].nomineeName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithNominee'] = this.adultList[0].nomineeRelationDescription;

      if (this.adultList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['name'] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithPrimaryMember'] = this.adultList[1].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['gender'] = this.adultList[1].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['dob'] = this.adultList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['nomineeName'] = this.adultList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithNominee'] = this.adultList[1].nomineeRelationDescription;
      }

      if (this.childList.length > 0) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['name'] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithPrimaryMember'] = this.childList[0].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['gender'] = this.childList[0].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['dob'] = this.childList[0].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['nomineeName'] = this.childList[0].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithNominee'] = this.childList[0].nomineeRelationDescription;
      }

      if (this.childList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['name'] = this.childList[1].firstName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithPrimaryMember'] = this.childList[1].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['gender'] = this.childList[1].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['dob'] = this.childList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['nomineeName'] = this.childList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithNominee'] = this.childList[1].nomineeRelationDescription;
      }
    } else {

      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.customerDetail.firstName + ' ' + this.customerDetail.lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithProposer'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipOfNominee'] = '';

    }

  }

    // printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium'] = new Object();
    // printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['basicpremium'] = 'Rs.' + this.premiumDetail.netPremium;
    printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();
      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
        obj2['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST -9%';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
      }


      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST -9%';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
      }


      if (this.premiumDetail.igst) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'IGST -18%';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
      }

      if(this.premiumDetail.cessTax) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Cess -1%';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
      }

      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Total Premium Payable';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Total Premium Payable';
        obj7['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      }

    // else {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['cgst'] = '';
    // }
    // if (this.premiumDetail.cgst !== undefined) {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['cgst'] = 'Rs.' + this.premiumDetail.cgst;
    // }
    // if (this.premiumDetail.sgst !== undefined) {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['sgst'] = 'Rs.' + this.premiumDetail.sgst;
    // }
    // if (this.premiumDetail.igst !== undefined) {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['igst'] = 'Rs.' + this.premiumDetail.igst;
    // }
    // printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['totalPremium'] = 'Rs.' + this.premiumDetail.grossPremium;
    //  else {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['sgst'] = '';
    // }


    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['dateOfIssue'] = this.proposerVo.policyIssueDate;
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['gstin'] = this.proposerVo.communicationDetails.gstinNo;
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['sacCode'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['irdaRegNo'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['cinNo'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['uin'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['serviceDescription'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['placeOfSupply'] = this.proposerVo.placeOfSupply;


    if (this.proposerVo.policyTypeName !== 'Individual' && this.proposerVo.policyTypeName !== 'Family Floater') {

      // printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType1'] = this.mockCertificateProposal['certificateDetails']['certificateInfo']['coverageSectionType1'];
      // printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType2'] = this.mockCertificateProposal['certificateDetails']['certificateInfo']['coverageSectionType2'];
      printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = new Array();
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType2'] = this.coverageSectionType2;
      let serialCounter: Number = 0;
      
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printCertificateJson['certificateDetails']['certificateInfo']['equipment'].push(obj1);
      }
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
      }


      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
      }

      if (this.premiumDetail.igst) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'IGST(18%)';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
      }


      if(this.premiumDetail.cessTax){
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Cess(1%)';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
      }


      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Premium Including Tax';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Net Premium(Taxable value)';
        obj7['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      }


    }
    // else {
    //     printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType1'] = '';
    //     printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType2'] = '';
    //     printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = '';
    //     printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = '';
    //   }


    const certificateJson = JSON.stringify(printCertificateJson);
    this.loadingmessage = 'Preparing Certificate...';
    if (certificateJson) {
      this.proposalServices
        .getPrintCertificatePayment(certificateJson)
        .then(data1 => {
          const parsedResponse = data1; // .blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printcertificate.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.certificateNumber+'.pdf'; // 'printcertificate.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.downloadFailed = true;
          this.loadingmessage = '';
          // this.hasErrorMessage = true;
          // this.message = 'Error occured when downloading document';
          // this.loadingmessage = '';
        });
    }

  }
}
