export class PremiumDetailsVO {
    public cgst: Number;
    public sgst: Number;
    public igst: Number;
    public netPremium: Number;
    public grossPremium: Number;
    public gatewayId: any;
    public startDate: String;
    public endDate: String;
    public premiumId: any;
    public walletId: any;
    public gatWayName: any;
    public cessTax: Number;
    // public dob: any = [];
}
