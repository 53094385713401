import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DeclarationService } from '../services/declaration.services';
import { ProposalServices } from 'src/services/proposal.services';
import { ModalService } from 'src/services/modal.services';
import { USER_PROFILE_DATA } from 'src/common/common.constants';

@Component({
    selector: 'app-declaration',
    templateUrl: './declaration.component.html'
})

export class DeclarationComponent implements OnInit {
    private application: string;
    public gateWayId: string;
    public policy: string;
    public declarationId: string;
    public declaration: string;
    public paymentEndpoint: string;
    public policyInfo: string;
    public checked: boolean = false;
    public walletId: String;
    public paymentKey: any;
    public successFlag: Boolean = false;
    public actionFailedMessage: any;
    public actionFailedFlag: Boolean = false;
    public displayFlag: Boolean = false;
    public loadingmessage = 'Please wait...';
    isImuthootAffinity: boolean;
    constructor(private route: ActivatedRoute, private router: Router, private declarationService: DeclarationService, private modalService: ModalService, private proposalServices: ProposalServices) { }
    ngOnInit() {
//         const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
// if(userdetails['isMuthootCustomer'] === true){
//       this.isImuthootAffinity = true;
//     }
        this.loadingmessage = 'Please wait...';
       this.actionFailedFlag = false;
       this.route.params.subscribe(params => (this.paymentKey = params.key));
       sessionStorage.setItem('paymentKey', this.paymentKey);
        // this.route.queryParams.subscribe(params => {
        //     this.application = params.application;
        //     this.gateWayId = params.gateWayId;
        //     this.policy = params.policy;
        //     this.walletId = params.walletId;
        // });
        this.proposalServices.getDetailsForPayment(this.paymentKey).then(data => {
            const dataVal = data['paymentValue'];
            if (dataVal !== undefined) {
                const dataValueSplitted = dataVal.lastIndexOf('policy=');
                // const isRetrieve: any;
                const applicationData: any = dataVal.substring(0, dataValueSplitted);
                const policyValue = dataVal.substring(dataValueSplitted);
                const dataPolicy = policyValue.substring(7);
                this.policyInfo = dataPolicy;
                const splitedData = applicationData.split('&');
                this.application = splitedData[0].split('=')[1];
                this.gateWayId = splitedData[1].split('=')[1];
                this.walletId = splitedData[2].split('=')[1];
                const isRetrievelFlag = splitedData[3].split('=')[1];
                // const isRetrievelFlag = 'true';
                const policyId = splitedData[4].split('=')[1];
                this.declarationService.getPolicyIdInfo(policyId).then(dataResponse => {
                    this.actionFailedMessage = '';
                    if (dataResponse !== undefined) {
                        if (dataResponse['policyStatusId'] === 7) {
                            const isRetrieve: String = dataResponse['isRetrieve'];
                            if (isRetrieve.toString() === isRetrievelFlag) {
                                this.declarationService.getDeclarationDetails(this.application).then(data => {
                                    this.loadingmessage = '';
                                    this.displayFlag = true;
                                    if (data !== undefined) {
                                        this.declarationId = data.declarationId;
                                        this.declaration = data['declaration'];
                                        this.paymentEndpoint = data['paymentURL'];
                                    }
                                }).catch(error => {
                                });
                            } else {
                                this.loadingmessage = '';
                                this.openActionFailDialog('The payment link has been expired or already payment has been made for this transaction');
                            }
                        } else if(dataResponse['policyStatusId'] === 6){
                            this.loadingmessage = '';
                            this.openActionFailDialog('Please Validate the OTP to proceed for Payment');
                        } else {
                            this.loadingmessage = '';
                            this.openActionFailDialog('The payment link has been expired or already payment has been made for this transaction');
                        }
                    } else {
                        this.loadingmessage = '';
                        this.openActionFailDialog('The payment link has been expired or already payment has been made for this transaction');
                    }
                }).catch(error => {
                });
            } else {
                this.loadingmessage = '';
                this.openActionFailDialog('The payment link has been expired or already payment has been made for this transaction');
            }
        }).catch(error => {

        });
    }
    public paymentGateWayRedirection() {
        this.declarationService.redirectToPaymentGateWay(this.application, this.gateWayId, this.walletId, this.policy).then(data => {
            const url = data['url'];
            this.router.navigateByUrl(url);
        }).catch(error => { });
    }
    toggleCheck() {
        if (!this.checked) {
            this.checked = true;
            this.successFlag = true;
        } else {
            this.checked = false;
        }
    }

    showAlert() {
        this.modalService.open('declaration');
    }

    openModal(id: string) {
        this.modalService.open(id);
    }

    closeModal(id: string) {
        this.modalService.close(id);
    }

    public openActionFailDialog(message: any) {
        this.actionFailedFlag = true;
        this.displayFlag = true;
        this.actionFailedMessage = message;
      }
}
