import { Component, OnInit, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { GridComponent } from 'src/grid/grid.component';

import { ProposalServices } from 'src/services/proposal.services';
import { ProposalVO } from 'src/dto/ProposalVO';
import { CommunicationVO } from '../dto/CommunicationVO';
import { BeneficiaryVO } from '../dto/BeneficiaryVO';
import { Router, ActivatedRoute } from '@angular/router';
import { PremiumDetailsVO } from 'src/dto/PremiumDetailsVO';
import { ModalService } from '../services/modal.services';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import {
  DESCRIPTION,
  CODE,
  CERTIFICATE_NO,
  CUSTOMER_NAME,
  START_DATE,
  MAIN_PAGE_PART_URL,
  END_DATE,
  USER_PROFILE_DATA
} from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { PRODUCT_CODE } from './../common/common.constants';
import { EmitterService } from '../services/EmitterService';

@Component({
  selector: 'app-endorsement-proposal',
  templateUrl: './endorsement-proposal.component.html',
  styleUrls: ['./endorsement-proposal.component.css']
})
export class EndorsementProposalComponent implements OnInit {

  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public collabsible = new Object();
  public columnsData: Array<Object> = Array<Object>();
  public projectData: Array<Object> = Array<Object>();
  public subcolumnsData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public searchArray: Array<Object> = Array<Object>();
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  public loadingmessage = '';
  public productID: String;
  public hrLeftMargin: number;
  public hrWidth: number;
  public productId: any;
  public totalRows: any;
  public count: any;
  public totalNumberOfRecords: Number;
  public display = 'none';
  public description: string;
  public disableEdit: boolean;
  public currentRole: string;
  public prodName: any;
  public lobName: any;
  public lobId: any;
  public shortProdName: any;
  public producDesc: any;
  public actionFailedFlag = false;
  public actionFailedMessage = '';

  @ViewChild('contentBody') contentBodyRef: ElementRef;
  @ViewChild('grid') gridComponent: GridComponent;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyRef.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
    if (!this.cdr['destroyed']) {
      this.cdr.detectChanges();
    }
  }

  constructor(
    private proposalServices: ProposalServices,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private cdr: ChangeDetectorRef) { }
  ngOnInit() {
    sessionStorage.setItem(PRODUCT_CODE, '');
    this.onResize();
    this.route.paramMap.subscribe(params => {
      this.prodName = params.get('prodName');
      this.lobName = params.get('lobName');
      this.getProdAndLobDetails();
    });
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.loadingmessage = 'Please wait...';
    this.count = 0;
    this.totalNumberOfRecords = 10;
    this.getEndorsmentDetails();
    this.endorsmentSearchArray();
    this.prepareColumnsData();
    this.prepareActionColumns();
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.productID = sessionStorage.getItem(PRODUCT_CODE);
    this.description = this.searchArray[0]['Description'];
    this.proposerSearchVo.searchId = this.searchArray[0]['Code'];
    this.currentRole = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
    if (String(this.currentRole) === 'Underwriter') {
      this.disableEdit = true;
    }
  }

  set(value: any, description: any) {
    this.proposerSearchVo.searchId = value;
    this.description = description;
    this.toggleDisplay();
  }
  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }

  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getEndorsementsDetails();
  }

  public getEndorsementsDetails() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined && productid !== '') {
      this.proposalServices.getEndorsmentProposal(this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.gridComponent.cdr.detectChanges();
          this.moveToTop();
        })
        .catch(error => {
        });
    }
  }

  prepareColumnsData() {
    const col1: Object = new Object();
    col1['colName'] = 'Certificate No.';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Customer Name';
    this.columnsData.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Policy Start Date';
    this.columnsData.push(col3);
    const col4: Object = new Object();
    col4['colName'] = 'Premium';
    this.columnsData.push(col4);
  }

  public getEndorsmentDetails() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    if (productid !== '') {
      this.proposalServices.getEndorsmentProposal(this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.gridComponent.cdr.detectChanges();
        })
        .catch(error => {
        });
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  public prepareProjectData(issuedProposalData) {
    const issuedDetailsList = issuedProposalData.policyDetailsList;
    if (issuedProposalData !== undefined) {
      this.projectData = [];
      for (let i = 0; i < issuedDetailsList.length; i++) {
        let customerName = '';
        let PremiumValue = '';
        const startDate = issuedDetailsList[i].policyStartDate;
        const policyDate: any = startDate.split(' ');
        const policyStartDate = policyDate[0];
        const data: Object = new Object();
        if (issuedDetailsList[i].customerDetails !== undefined) {
          if(issuedDetailsList[i].customerDetails.lastName!== undefined)
          {
            customerName = issuedDetailsList[i].customerDetails.firstName + ' ' + issuedDetailsList[i].customerDetails.lastName;
          }
          else
          {
            customerName = issuedDetailsList[i].customerDetails.firstName;
          }
        }
        if (issuedDetailsList[i].premiumDetails !== undefined) {
          PremiumValue = issuedDetailsList[i].premiumDetails.grossPremium;
        }
        const certificateNoVal: any = issuedDetailsList[i].certificateNo.split('-');
         if(certificateNoVal[certificateNoVal.length-1] === '002'){
           data['disableEdit'] = true;
         }
        data['Certificate No.'] = issuedDetailsList[i].certificateNo;
        data['Customer Name'] = customerName;
        data['Policy Start Date'] = policyStartDate;
        data['Premium'] = PremiumValue;
        this.projectData.push(data);
      }
    }
  }

  public prepareActionColumns() {
    this.actionColumnsData.push('EDIT');
  }

  public endorsmentToCreateproposal(str) {
    const certificateNo = JSON.stringify(str);
    const url = MAIN_PAGE_PART_URL + 'proposal/' + this.prodName + '/' + this.lobName + ')?actionType=ENDORSEMENT&proposalno=' + str['Certificate No.'];
    this.router.navigateByUrl(url);
  }
  public endorsmentSearchArray() {
    const code1: Object = new Object();
    code1[CODE] = CERTIFICATE_NO;
    code1[DESCRIPTION] = 'Certificate No.';
    const code2: Object = new Object();
    code2[CODE] = CUSTOMER_NAME;
    code2[DESCRIPTION] = 'Customer Name';
    const code3: Object = new Object();
    code3[CODE] = START_DATE;
    code3[DESCRIPTION] = 'Policy Start Date';
    this.searchArray.push(code1);
    this.searchArray.push(code2);
    this.searchArray.push(code3);
  }

  public summaryData(data) {
    const proposerDetails = data;
    this.proposerVo.policyStartDate = proposerDetails.policyStartDate;
    this.proposerVo.policyTerm = proposerDetails.policyTermDescription;
    this.proposerVo.policyEndDate = proposerDetails.policyEndDate;
    this.proposerVo.masterPolicyNo = proposerDetails.masterPolicyNo;
    this.proposerVo.policyTypeName = proposerDetails.policyTypeDescription;
    this.proposerVo.planTypeName = proposerDetails.planDescription;
    this.proposerVo.sumInsured = proposerDetails.sumInsured;
    this.proposerVo.customerId = proposerDetails.customerId;
    this.proposerVo.employeeId = proposerDetails.employeeId;
    if (proposerDetails.productAnswers !== undefined) {
      this.questionList = proposerDetails.productAnswers;
    }
    if (proposerDetails.communicationDetails !== undefined) {
      const proposerCommDtls = proposerDetails.communicationDetails;
      this.proposerVo.communicationDetails.address1 = proposerCommDtls.address1;
      this.proposerVo.communicationDetails.address2 = proposerCommDtls.address2;
      this.proposerVo.communicationDetails.address3 = proposerCommDtls.address3;
      this.proposerVo.communicationDetails.pincode = proposerCommDtls.pincode;
      this.proposerVo.communicationDetails.stateId = proposerCommDtls.stateName;
      this.proposerVo.communicationDetails.districtId =
        proposerCommDtls.districtName;
      this.proposerVo.communicationDetails.cityId = proposerCommDtls.cityName;
      this.proposerVo.communicationDetails.emailId = proposerCommDtls.emailId;
      this.proposerVo.communicationDetails.mobile = proposerCommDtls.mobile;
      this.proposerVo.communicationDetails.telephone =
        proposerCommDtls.telephone;
      this.proposerVo.communicationDetails.gstinNo = proposerCommDtls.gstinNo;
    }
    if (proposerDetails.customerDetails !== undefined) {
      const customerDetails = data.customerDetails;
      this.proposerVo.customerId = customerDetails.customerId;
      this.proposerVo.employeeId = customerDetails.employeeId;
    }
    if (proposerDetails.premiumDetails !== undefined) {
      const premiumDetails = proposerDetails.premiumDetails;
      this.premiumDetail.cgst = premiumDetails.cgst;
      this.premiumDetail.sgst = premiumDetails.sgst;
      this.premiumDetail.igst = premiumDetails.igst;
      this.premiumDetail.netPremium = premiumDetails.netPremium;
      this.premiumDetail.grossPremium = premiumDetails.grossPremium;
    }
    this.adultList = [];
    this.childList = [];
    if (proposerDetails.beneficiaryList.length !== 0) {
      this.beneficiaryList = proposerDetails.beneficiaryList;
      for (let i = 0; i < this.beneficiaryList.length; i++) {
        if (this.beneficiaryList[i].nomineeType === 2) {
          this.childList.push(this.beneficiaryList[i]);
        }
        if (this.beneficiaryList[i].nomineeType === 1) {
          this.adultList.push(this.beneficiaryList[i]);
        }
      }
    }
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  public endorsmentProposalSearch() {
    this.loadingmessage = 'Please wait...';
    const policyStatus = 2;
    const action = 'ENDORSEMENT';
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {

      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        this.proposalServices.getEndorsmentProposal(this.count, this.totalNumberOfRecords)
          .then(data => {
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.loadingmessage = '';
            this.gridComponent.inSearch = false;
            this.gridComponent.setcurrentpage(0);
            this.gridComponent.showData();
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.gridComponent.cdr.detectChanges();
            this.moveToTop();
          })
          .catch(error => {
            this.loadingmessage = '';
            // this.openActionFailDialog('Data could not be fetched');
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });

      }

    } else {
      this.proposalServices.proposalSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action).then(data => {
        this.loadingmessage = '';
        this.totalRows = data['totalRecords'];
        this.prepareProjectData(data);
        this.loadingmessage = '';
        this.gridComponent.inSearch = true;
        this.gridComponent.setcurrentpage(0);
        this.gridComponent.showData();
        this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
        this.gridComponent.cdr.detectChanges();
        this.moveToTop();
      })
        .catch(error => {
          this.loadingmessage = '';
          this.prepareProjectData(null);
        });
    }
  }

  public searchUser() {
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
      this.endorsmentProposalSearch();
    }
  }
  public resetSearch() {
    this.proposerSearchVo.searchInput = '';
  }

  public getIssuedDetailsWithSearch() {
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      const policyStatus = 2;
      const action = 'ENDORSEMENT';
      this.proposalServices.proposalSearchPagination(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, policyStatus, action, this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.gridComponent.cdr.detectChanges();
          this.moveToTop();
        })
        .catch(error => {
          this.loadingmessage = '';
         // this.openActionFailDialog('Data could not be fetched');
         this.loadingmessage = 'Please wait...';
         setTimeout(() => {
           this.serviceDownMessage();
         }, 10000);
        });
    }
  }

  updatePageDataWithSearch(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getIssuedDetailsWithSearch();
    this.gridComponent.cdr.detectChanges();
  }

  public getProdAndLobDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      this.proposalServices.getAvailableProducts(affinityId).then(data1 => {
        const productData = data1;
        if (productData != null) {
          sessionStorage.setItem('prodcutDetails', JSON.stringify(productData));
          this.setShortProdName();
          if (!this.projectData || this.projectData.length === 0) {
            this.getEndorsmentDetails();
          }
        }
      }).catch(error => {
      });
    }
  }
  public setShortProdName() {
    let lobDetails;
    lobDetails = this.getProductIdAndLobName(this.prodName);
    this.lobId = lobDetails['lobId'];
    this.shortProdName = lobDetails['shortProductName'];
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
  }
  public getProductIdAndLobName(prodName: any) {
    const productDetails = sessionStorage.getItem('prodcutDetails');
    if (productDetails) {
      const prodcutArrayObj: Array<Object> = JSON.parse(productDetails);
      if (prodcutArrayObj) {
        for (const prodType of prodcutArrayObj) {
          if (prodType['productName'] === prodName) {
            const productID = prodType['productId'];
            this.producDesc = prodType['productInfo'];
            sessionStorage.setItem(PRODUCT_CODE, productID);
            return this.producDesc;
          }

        }

      }

    }

  }

  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      },
      500
    );
    this.router.navigateByUrl('');
  }

}


