import { CommunicationVO } from '../dto/CommunicationVO';
import { Router, ActivatedRoute, NavigationEnd } from '@angular/router';
import {
  Component,
  OnInit,
  Output,
  HostListener,
  ChangeDetectionStrategy,
  ViewChildren,
  AfterViewInit,
  AfterViewChecked,
  ViewChild,
  ElementRef,
  ChangeDetectorRef
} from '@angular/core';
import { ProposalServices } from 'src/services/proposal.services';
import { ModalService } from '../services/modal.services';
import { ProposalVO } from 'src/dto/ProposalVO';
import { QuestionVO } from 'src/dto/QuestionVO';
import { BeneficiaryVO } from '../dto/BeneficiaryVO';
import { DatePipe } from '@angular/common';
import { DISABLED } from '@angular/forms/src/model';
import { IfStmt } from '@angular/compiler';
import * as _moment from 'moment';
import { SelectComponent } from 'src/select/select-component';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import {
  BENIFICIARY_SELF,
  BENIFICIARY_SPOUSE,
  GENDER_MALE,
  GENDER_FEMALE,
  RELATIONSHIP_DEPENDENT_SON,
  RELATIONSHIP_DEPENDENT_DAUGHTER,
  PLAN_TYPE_DESC,
  POLICY_TYPE_DESC,
  DEFAULT_POLICY_TYPE,
  GENDER_ID,
  GENDER_DESC,
  RELATION_SHIP_DESC,
  RELATION_SHIP_ID,
  LABEL_GENDER,
  LABEL_RELATIONSHIP,
  SUMISURED,
  SUMINSURED_ID,
  DESCRIPTION,
  CODE,
  RELATIONSHIP_OTHERS,
  ENDORSEMENT_REASON_ID,
  ENDORSEMENT_REASON_DESC,
  USER_PROFILE_DATA,
  SHORT_PROD_NAME,
  USER_PROFILE,
  USER_AFFINITY,
  PREMIUM_ERROR_MSG,
  SAHAJ_AND_LIB_OCCUPANCY,
  SHOP_OCCUPANCY_TARIFF,
  MEENACHIL_SHOP_OCCUPANCY,
  MEENACHIL_HOME_OCCUPANCY,
  HOME_OCCUPANCY_AND_TARIFF,
  SHOP_OCCUPANCY,
  INTERACTIVE_TRADEX_SHOP_OCCUPANCY
} from 'src/common/common.constants';
import {
  QUESTION_ERROR_MESSAGE,
  INVALID_CHILD,
  INVALID_CHILD_AGE,
  PINCODE_ERROR
} from 'src/common/error.constants';
import { PostalService } from 'src/services/postal.services';
import {
  PLAN_TYPE_ID,
  POLICY_TYPE_ID
} from './../common/common.constants';
import { PremiumDetailsVO } from 'src/dto/PremiumDetailsVO';
import { CustomerDetailsVO } from 'src/dto/CustomerDetailsVO';
import { throwError } from 'rxjs';
import { EmitterService } from 'src/services/EmitterService';
import { MAIN_PAGE_PART_URL, LANDING } from 'src/common/common.constants';
import { RiskLocationVO } from 'src/dto/RiskLocationVO';
import { ApplianceVO } from 'src/dto/ApplianceVO';
import { PRODUCT_CODE, CERTIFICATE_NO } from '../common/common.constants';
import { PaymentDetailsVO } from '../dto/PaymentDetailsVO';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SelectService } from '../services/select.services';
import { UserManagementServices } from 'src/services/user-management.services';
import { ProductServices } from 'src/services/product.service';

import { EventEmitter } from 'events';
import { InboxpageService } from 'src/services/inboxpage.service';
import { AppConfig } from 'src/config/app.config';

declare const AJL: any;

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-proposal',
  templateUrl: './proposal.html'
})
export class ProposalComponent implements OnInit, AfterViewInit, AfterViewChecked {
  @Output() public changeevent = new EventEmitter();
  public policyErrorMessage: String;
  public isRenewal: Boolean = false;
  public isEndorsement: Boolean = false;
  public hasSuccessPayment: Boolean = false;
  public hasErrorMessageTwo: Boolean = false;
  public selectedLevel = 1;
  public loading: Boolean = false;
  public hasErrorMessage: Boolean = false;
  public message: any = '';
  public adultCount: any = 0;
  public childCount: any = 0;
  public proposerVo: ProposalVO = new ProposalVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public questionList: Array<any> = new Array<any>();
  public adultList: Array<any> = new Array<any>();
  public childList: Array<any> = new Array<any>();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public riskLocationDetail: RiskLocationVO = new RiskLocationVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public customerDetail: CustomerDetailsVO = new CustomerDetailsVO();
  public applianceDetails: ApplianceVO = new ApplianceVO();
  public applianceDetail: Array<any> = new Array<any>();
  public paymentOptionList: Array<any> = new Array<any>();
  public gateWayList: Array<any> = new Array<any>();
  public walletList: Array<any> = new Array<any>();
  public productAnswer: QuestionVO = new QuestionVO();
  public backdatedinception: Boolean = true;
  public date: Date;
  public min: any;
  public max: any;
  public policyData: any;
  public policyYear: Number;
  public minDob: any;
  public maxDob: any;
  public maxD = new Date();
  public isRiskAddress: Number = 0;
  // Setting flag to navigate the steps
  public policyDetailsFlag: Boolean = false;
  public proposedInsuredFlag: Boolean = false;
  public premiumAndPaymentFlag: Boolean = false;
  public communicationDetailsFlag: Boolean = false;
  public createProposalFlag: Boolean = false;
  public craetePorposalDisable: Boolean = true;
  public createProposalButtonFlag: Boolean = false;
  public enableSubmit: Boolean = false;
  public dateTime = new moment();
  public sumInsured: Array<Object> = new Array<Object>();
  public stateArray: Array<Object> = new Array<Object>();
  public districtArray: Array<Object> = new Array<Object>();
  public cityArray: Array<Object> = new Array<Object>();
  public policytermArray: Array<Object> = new Array<Object>();
  public endorsmentArray: Array<Object> = new Array<Object>();
  public masterPolicyArray: Array<Object> = new Array<Object>();
  public policyTypeArray: Array<Object> = new Array<Object>();
  public ChildRelationType: Array<Object> = new Array<Object>();
  public adultRelationShipArray: Array<Object> = new Array<Object>();
  public titleArray: Array<Object> = new Array<Object>();
  public planTypeArray: Array<Object> = new Array<Object>();
  public planForPremium: Array<Object> = new Array<Object>();;
  public paymentOptionsArray: Array<Object> = new Array<Object>();
  public muthootPaymentURL: any;


  public childAgeFlag: Boolean = false;
  public pincodeArray: Array<any> = Array<any>();
  public pincodeRiskArray: Array<any> = Array<any>();
  public genderDesc: String;
  public pincodeErrorMessage: String;
  public riskPincodeErrorMessage: String;

  public loadingmessage = '';
  public loadingmessagefailure = '';
  public downloadFailed: Boolean = false;
  @ViewChildren(SelectComponent)
  private selectFields: any;

  public planChangeFlag: Boolean = false;
  public existingPlan: String;
  public existingPlanName: String;
  public existingPolicyName: String;
  public existingPolicy: String;
  public exPolicyTypeArray: any;
  public exPlanTypeArray: any;
  public exChildList: Array<any> = new Array<any>();
  public exAdultList: Array<any> = new Array<any>();
  public exPlanList: any[];
  public exSumInsured: String;
  public newPlan: String = '';
  public cancelFlag: Boolean = false;
  public cancelProposalFlag: Boolean = false;
  public date1: any;
  public month: any;
  public year: any;
  public childYear: any;
  public childMonth: any;
  public childDate: any;
  public keys: any;
  public pageParams: Object = new Object();
  public policyStartDateFlag: Boolean = false;
  public proposedInfoFlag: Boolean = false;
  public dobErrorMessage: String;
  public proposalData: any;
  public isbuttondiable: Boolean = false;
  // retrieve proposal
  public actionType;
  public exPolicyType: any;
  public exPlanType: any;
  public retrieveList: any;
  public retrieveAdultList: Array<any> = new Array<any>();
  public retrieveChildList: Array<any> = new Array<any>();
  public paymentDetail: PaymentDetailsVO = new PaymentDetailsVO();
  public navigationSubscription: any;
  public maxVisitedLevel = 1;
  public maxVisitedLevelCopy = this.maxVisitedLevel;
  public retrivePlan: any;
  public retrieveFlag: Boolean = false;
  public premiumID: Number;
  public productId: any;
  public prodName: any;
  public lobName: any;
  public communicationFlag: Boolean;
  public riskLocationFlag: Boolean;
  public failedValidationPage = 100;
  public validatedPages = new Array<boolean>(4);
  public hrLeftMargin;
  public hrWidth;
  public productName: String;
  public lobId: Number;
  public shortProductName: String;
  public quantity: Number = 0;

  public lastNameError: Boolean = false;
  public disableLastName: Boolean = false;

  public displayReason: Boolean = false;

  public mockProposal: any;
  public mockCertificateProposal: any;

  public currentPlanId: any;

  public disableRenewalButton: Boolean = false;
  public modalCounter = 0;

  public proposalInfoFlag: Boolean = true;
  public exPolicyStartDate: any;
  public exPolicyTerm: any;
  public exMasterPolicy: any;
  public coverageSectionType1: Array<any> = new Array<any>();
  public coverageSectionType2: Array<any> = new Array<any>();
  public coverageIds: Object = new Object();
  public sumInsuredForCoverages: any;
  public employeeNameValidationMessage: any;
  public producDesc: any;
  public lobProdId: any;
  public shortProdName: any;

  public employeeCodeExists = false;
  public employeeNameTemporal: any;

  public employeeCodeFlag = false;
  public employeeCodeMessage2 = '';
  public insuredChangeFlag = false;

  public dataCopy = new Object();
  public policyDateCancelFlag = false;
  public productTerm: any;
  public disableEndorsmentOptions: Boolean = false;
  public employeeIdTemporal: any;
  public existingPremium: any;
  public endorsmentPremiumFlag: Boolean = false;
  public endorsmentDobErrorMessage: any;
  public actionFailedFlag = false;
  public actionFailedMessage = '';
  public originalBeneficiaryList: [BeneficiaryVO];
  // Plan type array for health products
  public healthPlanArray = [];
  public offlinePaymentType: Boolean = false;
  public choosenPaymentType: any;
  // public paymentOptionsArray: Array<Object> = new Array<Object>();
  public childAgeErrorMessageCheck: Boolean = true;
  public isDateEligibleForEndorsement: Boolean = true;
  public dateField: Boolean = true;
  public premiumValidationMessage = '';
  public adultAge: any;
  public adultDay: any;
  public disablePlan: any = false;
  public planFlag: any;
  public policyTermFlag: any;
  public endorsmentPaymentOption: any;
  public gstinOptionArray: Array<any> = new Array<any>();
  public gstinOptionRiskArray: Array<any> = new Array<any>();
  public isGstinNoApplicable: Boolean = false;
  public isRiskGstinNoApplicable: Boolean = false;
  public cessIndicator: any = 'N';
  public placeOfSupply: any;
  public exApplianceDetail: Array<any> = new Array<any>();
  public renewalOrRetrievalNewPlan: any;
  public renewalOrRetrievalNewPolicy: any;

  // agent name, logo and contact number changes.
  public agentName :any;
  public agentContactNumber: any;
  public affinityLogo: any;
  // Setting for existing portal
  public isExistingProposal: Boolean = false;
  public existingCertificateNo: any;

  //Sahaj Details
  public smId: String;
  public crmLeadId: String;
  public paymentDeclArray: Array<Object> = new Array<Object>();
  public isSelectAllArray: Array<Object> = new Array<Object>();
  public otpInput: String;
  public allChecked: boolean;
  public isSelectedChecked: Boolean;
  public otpStatus: any;
  public otpValidateStatus: any;
  public otpFlag: Boolean = false;
  public otpValidationSuccess: Boolean = false;
  public isSahajAffinity: Boolean = false;
  public isImuthootAffinity: Boolean = false;
  public isOptScreenDisabled: Boolean = true;
  public iagree: boolean;
  public autoPincode: Boolean = false;
  //Premium view Grid
  public columnsData: Array<Object> = Array<Object>();
  public actionNameData: String;
  public projectData: Array<Object> = Array<Object>();
  public subcolumnsData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  //muthoot
  public isProposalSuccess: Boolean = true;
  public serverDate: any;
  public mobileValidationError: String = '';
  public mobileNumberFlag: Boolean = true;
  // Endorsement Validation
  public exTitle: any;
  public exFirstName: String;
  public exLastName: String;
  public exCommAddress1: String;
  public exCommAddress2: String;
  public exCommAddress3: string;
  public exPincode: string;
  public exCommMobile: any;
  public exCommEmail: String;
  public exCommPhone: any;
  public exRiskAddress1: String;
  public exRiskAddress2: String;
  public exRiskAddress3: string;
  public exRiskMobile: any;
  public exRiskEmail: String;
  public exRiskPhone: any;
  public exEmpId: any;
  public exEmpName: any;
  public exCommName: String;
  public exEndorsmentReason: any;
  public isEndorsementSameData:Boolean = false;
  public endorsmentReasonValue: String;
  public hideButton: boolean = false;

  // Meenachil Affinity
  public affinityPaymentOption: String;
  public MeenachilPolicyDetails: any;
  public isMeenahcilAffinity: Boolean = false;

  //CKYC
  public isReadonlyField: any = "readonly";
  public isKYCFieldsReadOnly: any = "";
  public aryaReferenceNumber: any;  
  public aryaToken: any;
  public aryaProposalId: any;
  public idType: any;
  public reqId: any;
  public isMobileNoReadonlyField: any = "";
  public isEmailIDReadonlyField: any = "";
  public isPanCardReadonlyField: any = "";
  public isDateofBirthReadonlyField: any = "";
  public isFullNameReadonlyField: any = "";
  public isAddressReadonlyField: any = "";

  public dataList: any;

  public DOBValidationError: String = '';
  public DOBFlag: Boolean = true;
  public FullNameFlag: Boolean = true;

  public isAryaError: Boolean = false;

  public FullNameValidationError: String = '';

  public amlMatch: String = '';
  public amlFlag: Boolean = true;
  
  @ViewChild('masterPolicyField') public masterPolicyField: SelectComponent;

  @ViewChild('policyType') public policyTypeField: SelectComponent;

  @ViewChild('PlanType') public planTypeField: SelectComponent;

  @ViewChildren(TextBoxComponent) private textFields: any;

  @ViewChildren(DatePickerComponent)
  private dateFields: any;

  @ViewChild('formWrapper') contentBodyView: ElementRef;
  @ViewChild('policyTerm') policyTermField: SelectComponent;
  sahajUrl: string = this.config.getConfig('sahajRedirection');

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(event) {
    this.scrollFunction();
  }

  /**TO DO */
  @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
  //   this.hrLeftMargin = -((this.hrWidth - this.contentBodyView.nativeElement.clientWidth) / 2) + 'px';
  //   this.hrWidth += 'px';
  //   if (!this.cdr['destroyed']) {
  //     this.cdr.detectChanges();
  //   }
  // }

  public insuredChange() {
    if (this.shortProductName === 'Health') {
      this.insuredChangeFlag = true;
    }
  }

  noQuestions(): boolean {
    return this.selectedLevel !== 2 && this.shortProdName !== 'Health';
  }

  public noDateField(): boolean {
    return this.selectedLevel !== 2 || this.shortProdName !== 'Health';
  }


  subsequentPagesValidated(n: number): boolean {
    if (n === 2) {
      return this.validatedPages[0];
    } else if (n === 3) {
      return this.validatedPages[0] && this.validatedPages[1];
    } else if (n === 4) {
      return this.validatedPages[0] && this.validatedPages[1] && this.validatedPages[2];
    }
    return true;
  }

  public employeeCodeCheck(employeeId: string) {
    this.employeeCodeMessage2 = '';
    const _this = this;
    if (this.employeeNameTemporal) {
      clearTimeout(_this.employeeNameTemporal);
    }
    this.employeeNameTemporal = setTimeout(function () {
      _this.proposalServices.employeeCodeExists(employeeId).then(data => {
        _this.employeeCodeExists = true;
        _this.employeeCodeMessage2 = '';
        _this.outSidevalidate();
      })
        .catch(error => {
          _this.employeeCodeExists = false;
          _this.employeeCodeMessage2 = 'Employee does not exist';
        });
    }, 2000);
  }

  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  constructor(
    private proposalServices: ProposalServices,
    private postalServices: PostalService,
    private modalService: ModalService,
    private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private selectService: SelectService,
    private userServices: UserManagementServices,
    private cdr: ChangeDetectorRef,
    private productService: ProductServices,
    private inboxService: InboxpageService,
    private config: AppConfig
  ) {
    EmitterService.get('ACTION_TYPE').subscribe((data: any) => {
      if (data === 'CREATE_PROPOSAL') {
        this.cancelProposal();
      }
    });
    this.navigationSubscription = this.router.events.subscribe((e: any) => {
      // If it is a NavigationEnd event re-initalise the component
      if (e instanceof NavigationEnd) {
        //  this.refresh();
      }
    });
    EmitterService.get('GET_PINCODE_DETAILS').subscribe(data => { this.getPinCodeDetails(data); });
  }

  ngAfterViewInit() {
    //  this.onResize();
  }

  ngOnInit() {
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    const serverStartDate:any  = profileData['lastActivityDate'];
    this.serverDate = moment(serverStartDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    this.proposerVo.otp='';
    this.getPolicyTerm();
    this.gstinOptionsLoadArray();
    this.getSahajDetails();
    this.route.queryParams.subscribe(params =>
      this.proposalData = params
    );
    EmitterService.get('ENABLE_MENU').emit(true);
    EmitterService.get('DISABLE_FOOTER').emit(true);
    return new Promise((resolve, reject) => {
      this.route.paramMap.subscribe(params => {
        this.prodName = params.get('prodName');
        this.lobName = params.get('lobName');
        sessionStorage.setItem(SHORT_PROD_NAME, this.lobName);
        this.getProductInformation();
      });
      if(this.lobName === 'Shop')
      {
          this.generateAryaToken();
      }
      this.route.queryParams.subscribe(data => {
        this.actionType = data['actionType'];
        if(this.actionType==='ENDORSEMENT')
        {
          this.isEmailIDReadonlyField="";
          this.isMobileNoReadonlyField="";
          this.isAddressReadonlyField="";
        }
      });
      resolve(100);
    }).then(data => {
    //  this.getProdAndLobDetails();
    }).
   
    catch(error => {
    });
    this.moveToTop();
    
  }

  public loadImuthootValues() {
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    const customerId = profileData['body']['customerId'];
    const muthootssoToken: String = profileData['body']['ssoToken'];
    let tokenDetails: Object = new Object();
    tokenDetails['retrievalJson'] = new Object();
    tokenDetails['retrievalJson']['methodName'] = 'CheckTokenStatus';
    tokenDetails['retrievalJson']['token'] = muthootssoToken;
    tokenDetails['retrievalJson']['customerId'] = customerId;
    tokenDetails['retrievalJson']['clientCode'] = '2';
    tokenDetails['retrievalJson']['apiVersion'] = '1';
    const reqData = JSON.stringify(tokenDetails);
    this.proposalServices.muthoodCheckTokenStatus(reqData).then(data => {
      const tokenResponse = data['Response'];
      for (const res of tokenResponse) {
        if (res['ERROR_MSG'].toLowerCase() === 'success') {
          tokenDetails['retrievalJson']['methodName'] = 'FetchCustomerDetails';
          const reqcusData = JSON.stringify(tokenDetails);
          this.proposalServices.muthootusDts(reqcusData).then(data2 => {
            const tokenResponse = data2['Response'];
            for (const res of tokenResponse) {
              if (res['ERROR_MSG'].toLowerCase() === 'success') {
                const customerData = data2['ResultSet'];
                for (const dataVal of customerData) {
                   
                  this.proposerVo.customerDetails.firstName = dataVal['FIRST_NAME'];
                  this.proposerVo.customerDetails.lastName = dataVal['LAST_NAME'];
                  if (dataVal['MIDDLE_NAME'] !== undefined && dataVal['MIDDLE_NAME'] !== '') {
                    this.proposerVo.customerDetails.lastName = dataVal['MIDDLE_NAME'] + ' ' + dataVal['LAST_NAME'];
                  }
                  this.proposerVo.communicationDetails.address1 = dataVal['ADDRESS1'];
                  this.proposerVo.communicationDetails.address2 = dataVal['ADDRESS2'];
                  if ( dataVal['ADDRESS3'] !== '') {
                    this.proposerVo.communicationDetails.address3 = dataVal['ADDRESS3'];
                  }
                  // Pincode values are commented as per clinet requirement.
                  // if (null !== dataVal['PIN_CODE']) {
                  //  this.proposerVo.communicationDetails.pincode = dataVal['PIN_CODE'];
                  // }
                  this.proposerVo.communicationDetails.emailId = dataVal['EMAIL'];
                  this.proposerVo.communicationDetails.mobile = dataVal['MOBILE_NUMBER'];
                }
              }
            }
          }).catch(error => {

          });

        }
      }
    }).catch(error => {
    });
  }

  public async getProductInformation() {
   const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      await this.proposalServices.getAvailableProducts(affinityId).then(products => {
     //  console.log('products '+ products);
       // performace issue 
       sessionStorage.setItem('prodcutDetails', JSON.stringify(products));
        for (const product of products) {
          if (String(product['productName']) === String(this.prodName)) {
            const productID = product['productId'];
            this.producDesc = product['productInfo'];
            this.lobId = this.producDesc['lobId'];
            this.shortProdName = this.producDesc['shortProductName'];
            this.productId = sessionStorage.getItem(PRODUCT_CODE);
            sessionStorage.setItem(PRODUCT_CODE, productID);
            sessionStorage.setItem(SHORT_PROD_NAME, this.shortProdName);
          }
        }
        // perfomance issue
        this.setShortProdName();
      });
    }
  }

  public getProdAndLobDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      this.proposalServices.getAvailableProducts(affinityId).then(data1 => {
      //  console.log('product data' + data1);
        const productData = data1;
        if (productData != null) {
          sessionStorage.setItem('prodcutDetails', JSON.stringify(productData));
          this.setShortProdName();
        }
      }).catch(error => {
        // this.openActionFailDialog('Product Data could not be fetched 446');
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
    }
  }

  public setShortProdName() {
    let lobDetails;
    lobDetails = this.getProductIdAndLobName(this.prodName);
    this.lobId = lobDetails['lobId'];
    this.shortProdName = lobDetails['shortProductName'];
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
    sessionStorage.setItem(SHORT_PROD_NAME, this.shortProdName);
    this.preparePageParams();
  }
  public initilalize() {
  //  this.getPremiumLinkDts();
    this.getPolicyTerm();
    this.validatedPages = new Array<boolean>(4);
    this.isRenewal = false;
    this.maxVisitedLevel = 1;
    this.hasSuccessPayment = false;
    this.hasErrorMessageTwo = false;
    this.disableEndorsmentOptions = false;
    this.selectedLevel = 1;
    this.loading = false;
    this.hasErrorMessage = false;
    this.message = '';
    this.adultCount = 0;
    this.childCount = 0;
    this.proposerVo.paymentDetails = new PaymentDetailsVO();
    this.proposerVo = new ProposalVO();
    this.beneficiaryVO = new BeneficiaryVO();
    this.questionList = new Array<any>();
    this.adultList = new Array<any>();
    this.childList = new Array<any>();
    this.communicationDetail = new CommunicationVO();
    this.premiumDetail = new PremiumDetailsVO();
    this.productAnswer = new QuestionVO();
    this.backdatedinception = true;
    this.date = undefined;
    this.min = undefined;
    this.max = undefined;
    this.policyData = undefined;
    this.policyYear = undefined;
    this.minDob = undefined;
    this.maxDob = undefined;
    this.maxD = new Date();
    this.policyDetailsFlag = false;
    this.proposedInsuredFlag = false;
    this.premiumAndPaymentFlag = false;
    this.communicationDetailsFlag = false;
    this.createProposalFlag = false;
    this.createProposalButtonFlag = false;
    this.enableSubmit = false;
    this.dateTime = new moment();
    this.sumInsured = new Array<Object>();
    this.stateArray = new Array<Object>();
    this.districtArray = new Array<Object>();
    this.cityArray = new Array<Object>();
    this.policytermArray = new Array<Object>();
    this.endorsmentArray = new Array<Object>();
    this.masterPolicyArray = new Array<Object>();
    this.planTypeArray = new Array<Object>();
    this.policyTypeArray = new Array<Object>();
    this.adultRelationShipArray = new Array<Object>();
    this.titleArray = new Array<Object>();
    this.childAgeFlag = false;
    this.pincodeArray = Array<any>();
    this.genderDesc = undefined;
    this.pincodeErrorMessage = undefined;
    this.planChangeFlag = false;
    this.existingPlan = null;
    this.existingPolicy = undefined;
    this.exPolicyTypeArray = undefined;
    this.exPlanTypeArray = undefined;
    this.exChildList = new Array<any>();
    this.exAdultList = new Array<any>();
    this.exPlanList = undefined;
    this.exSumInsured = undefined;
    this.newPlan = '';
    this.cancelFlag = false;
    this.cancelProposalFlag = false;
    this.date1 = undefined;
    this.month = undefined;
    this.year = undefined;
    this.childYear = undefined;
    this.childMonth = undefined;
    this.childDate = undefined;
    this.keys = undefined;
    this.pageParams = new Object();
    this.policyStartDateFlag = false;
    this.proposedInfoFlag = false;
    this.dobErrorMessage = undefined;


    this.proposerVo = new ProposalVO();
    this.proposerVo.communicationDetails = new CommunicationVO();
    this.proposerVo.customerDetails = new CustomerDetailsVO();
    this.proposerVo.premiumDetails = new PremiumDetailsVO();
    this.proposerVo.productAnswers = new Array<QuestionVO>();
    this.proposerVo.questionsList = new Array<QuestionVO>();
    this.proposerVo.beneficiaryList = new Array<BeneficiaryVO>();
    this.moveToTop();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.proposerVo.applianceDetails = this.applianceDetail;
    this.actionType = this.proposalData.actionType;
    const coverNoteNo = this.proposalData.proposalno;
    if (this.actionType === 'RETRIEVE') {
      this.productId = sessionStorage.getItem(PRODUCT_CODE);
      return new Promise((resolve, reject) => {
        this.proposalServices.getProductDetails().then(data => {
          const date = new Date;
          const date1 = new Date;
          const date2 = new Date;
          for (let i = 0; i < data.length; i++) {
            date1.setHours(0);
            date1.setMinutes(0);
            date1.setSeconds(0);
            const riskstartdaterange = data[i].riskStartDateRange;
            const backdatedinceptionFlag = data[i].backDatedInception;
            const backdatedinceptionrange = data[i].backDatedInceptionRange;
            this.productName = data[i].productName;
            this.lobId = data[i].lobId;
            this.shortProductName = data[i].shortProductName;
            this.productTerm = data[i].policyTerm;
            if (backdatedinceptionFlag) {
              this.policyStartDateFlag = false;
              if (riskstartdaterange !== 0 && backdatedinceptionrange !== 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                this.proposerVo.policyStartDate = this.max;
              } else if (riskstartdaterange === 0 && backdatedinceptionrange !== 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                this.proposerVo.policyStartDate = this.max;
              } else if (riskstartdaterange !== 0 && backdatedinceptionrange === 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                this.proposerVo.policyStartDate = this.max;
              } else if (riskstartdaterange === 0 && backdatedinceptionrange === 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                this.proposerVo.policyStartDate = this.max;
              }
            } else if (!backdatedinceptionFlag) {
              this.policyStartDateFlag = true;
              if (riskstartdaterange !== 0) {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
                this.proposerVo.policyStartDate = startDate;
              } else if (riskstartdaterange === 0) {
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
                this.proposerVo.policyStartDate = startDate;
              }
            }
          //  console.log('place one')
            this.setPolicyTerm();
            this.setAffinityID();
            this.proposalServices
              .getProposalSummary(coverNoteNo).then(data1 => {
                if (data1 !== undefined) {
                  this.setRetrievelInfo(data1);
                }
              })
              .catch(error => {
                //  this.openActionFailDialog('Data could not be fetched');
                this.loadingmessage = 'Please wait...';
                setTimeout(() => {
                  this.serviceDownMessage();
                }, 10000);
              });
          }
        })
          .catch(error => { });
      }).then(data => {
        this.proposalServices
          .getProposalSummary(coverNoteNo).then(data1 => {
            if (data1 !== undefined) {
              this.setRetrievelInfo(data1);
            }
          })
          .catch(error => {
            //    this.openActionFailDialog('Data could not be fetched');
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });

      });
    } else if (this.actionType === 'RENEWAL') {
      this.productId = sessionStorage.getItem(PRODUCT_CODE);
      return new Promise((resolve, reject) => {
        this.proposalServices.getProductDetails().then(data => {
          const date = new Date;
          const date1 = new Date;
          const date2 = new Date;
          for (let i = 0; i < data.length; i++) {
            date1.setHours(0);
            date1.setMinutes(0);
            date1.setSeconds(0);
            const riskstartdaterange = data[i].riskStartDateRange;
            const backdatedinceptionFlag = data[i].backDatedInception;
            const backdatedinceptionrange = data[i].backDatedInceptionRange;
            this.productName = data[i].productName;
            this.lobId = data[i].lobId;
            this.shortProductName = data[i].shortProductName;
            this.productTerm = data[i].policyTerm;
            if (backdatedinceptionFlag) {
              this.policyStartDateFlag = false;
              if (riskstartdaterange !== 0 && backdatedinceptionrange !== 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
               
              } else if (riskstartdaterange === 0 && backdatedinceptionrange !== 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
               
              } else if (riskstartdaterange !== 0 && backdatedinceptionrange === 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
               
              } else if (riskstartdaterange === 0 && backdatedinceptionrange === 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
                
              }
            } else if (!backdatedinceptionFlag) {
              this.policyStartDateFlag = true;
              if (riskstartdaterange !== 0) {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              
                this.proposerVo.policyStartDate = startDate;
                
              } else if (riskstartdaterange === 0) {
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
               
                this.proposerVo.policyStartDate = startDate;
                
              }
            }
          //  console.log('calling 2');
            this.setPolicyTerm();
            this.setAffinityID();
            this.proposalServices
              .getProposalSummaryByCertificate(coverNoteNo).then(data1 => {
                if (data1 !== undefined) {
                  this.isRenewal = true;
                  this.setRetrievelInfo(data1);
                }
              })
              .catch(error => {
                //  this.openActionFailDialog('Data could not be fetched');
                this.loadingmessage = 'Please wait...';
                setTimeout(() => {
                  this.serviceDownMessage();
                }, 10000);
              });
          }
        })
          .catch(error => { });
      }).then(data => {
        this.proposalServices
          .getProposalSummaryByCertificate(coverNoteNo).then(data1 => {
            if (data1 !== undefined) {
              this.isRenewal = true;
              this.setRetrievelInfo(data1);
            }
          })
          .catch(error => {
            // this.openActionFailDialog('Data could not be fetched');
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });

      });
    } else if (this.actionType === 'ENDORSEMENT') {
      this.setAffinityID();
      this.productId = sessionStorage.getItem(PRODUCT_CODE);
      this.proposalServices
        .getProposalSummaryByCertificate(coverNoteNo)
        .then(data => {
          if (data !== undefined) {
            this.disableEndorsmentOptions = true;
            this.isEndorsement = true;
            this.isRiskAddress = data.communicationDetails.isRiskAddress
           // this.setRetrievelInfo(data);
            this.setEndorsmentInfo(data);
          }
        })
        .catch(error => {
          // this.openActionFailDialog('Data could not be fetched');
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });

    } else {
      // this.getPolicyTerm();
      this.productId = sessionStorage.getItem(PRODUCT_CODE);
      this.proposerVo.communicationDetails = this.communicationDetail;
      this.proposerVo.riskLocationDetails = this.riskLocationDetail;
      this.proposerVo.customerDetails = this.customerDetail;
      this.proposerVo.premiumDetails = this.premiumDetail;
      const productName = sessionStorage.getItem(SHORT_PROD_NAME);
      if (productName === 'Health') {
        this.proposalServices
          .getQuestion()
          .then(data => {
            this.questionList = data;
            if (this.questionList.length !== 0) {
              for (let i = 0; i < this.questionList.length; i++) {
                this.questionList[i].answer = this.questionList[
                  i
                ].choices[1].choiceValue;
              }
            }
          })
          .catch(error => {
          });
      }

      this.proposalServices.getProductDetails().then(data => {
        try {
         // const date = new Date(this.serverDate);
          const date = new Date(this.serverDate);
          const date1 = new Date(this.serverDate);
          const date2 = new Date(this.serverDate);
          for (let i = 0; i < data.length; i++) {
            date1.setHours(0);
            date1.setMinutes(0);
            date1.setSeconds(0);
            const riskstartdaterange = data[i].riskStartDateRange;
            const backdatedinceptionFlag = data[i].backDatedInception;
            const backdatedinceptionrange = data[i].backDatedInceptionRange;
            this.productName = data[i].productName;
            this.lobId = data[i].lobId;
            this.shortProductName = data[i].shortProductName;
            this.productTerm = data[i].policyTerm;
            if (backdatedinceptionFlag) {
              this.policyStartDateFlag = false;
              if (riskstartdaterange !== 0 && backdatedinceptionrange !== 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
              } else if (riskstartdaterange === 0 && backdatedinceptionrange !== 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                
                this.proposerVo.policyStartDate = this.max;
                
              } else if (riskstartdaterange !== 0 && backdatedinceptionrange === 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
                
              } else if (riskstartdaterange === 0 && backdatedinceptionrange === 0) {
                this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
               
                this.proposerVo.policyStartDate = this.max;
                
              }
            } else if (!backdatedinceptionFlag) {
              this.policyStartDateFlag = true;
              if (riskstartdaterange !== 0) {
                date.setHours(0);
                date.setMinutes(0);
                date.setSeconds(0);
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
                this.proposerVo.policyStartDate = startDate;
              } else if (riskstartdaterange === 0) {
                this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
                const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
                this.proposerVo.policyStartDate = startDate;
               
              }
            }
          //  console.log('calling 3');
            this.setPolicyTerm();
            this.setAffinityID();
            //  this.getPlansHealth();
          }
        } catch (error) {
        }
      })
        .catch(error => {
          //  this.openActionFailDialog('Product data could not be fetched 726');
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });

      const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    }

    EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
  }

  public loadPaymentOptions() {
    this.proposalServices.getPaymentOptionList(1).then(data => {
      this.loadingmessage = '';
      this.gateWayList = [];
      this.walletList = [];
      if (data.gatewayList !== undefined) {
        this.gateWayList = data.gatewayList;
      } if (data.walletList !== undefined) {
        this.walletList = data.walletList;
      }
    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });
  }

  public setAffinityID() {
    let affinty;
    const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    this.proposerVo.affinityId = affinty['id'];
    this.proposerVo.placeOfSupply = affinty['issuestateDesc'] + ' (' + affinty['issueState'] + ')';
    this.proposerVo.agentId = affinty['agentId'];
    this.proposerVo.agentName = affinty['agentName'];
    this.proposerVo.agentContactNumber = affinty['agentContactNumber'];
    this.proposerVo.affinityName = affinty['name'];
    if(this.proposerVo.affinityName.startsWith('Sahaj') || this.proposerVo.affinityName.startsWith('SREI')){
      this.isSahajAffinity = true;
    }  
    if (this.proposerVo.affinityName.startsWith('MEENACHIL')){
      this.isMeenahcilAffinity = true;
    }
    if(userdetails['isMuthootCustomer'] === true){
      this.isImuthootAffinity = true;
      this.loadImuthootValues();
    }
    if ( affinty['affinityLogo'] !== undefined && affinty['affinityLogo'] !==''){
    this.proposerVo.affinityLogo =  'Logo_' + affinty['affinityLogo'];
    } else {
      this.proposerVo.affinityLogo =  '';
    }
    const userRole = userdetails['roleName'];
    const branchDetailId = userdetails['branchId'];
    if (userRole === 'Branch User') {
     this.proposerVo.branchId = branchDetailId;
      this.getBranchUserStateDetails(branchDetailId);
    } else {
      this.proposerVo.branchId = 0;
      this.proposerVo.branchCode = '';
      this.proposerVo.branchName = '';
      this.getIssuedStateDetails();
    }
    // no useful 
    // this.proposalServices.getAffinityDetailForEdit(this.proposerVo.affinityId).then(data => {

    //   this.placeOfSupply = data;
    // });
  }

  public getIssuedStateDetails() {
    this.proposalServices.getIssuedStateDetails(this.proposerVo.affinityId).
      then(data => {
        const stateDetails = data;
        if (data !== undefined) {
          this.proposerVo.issueStateId = data.issueStateId;
          // this.loadPremiumDetails();
        }
      }).catch(error => {
      });
  }

  public getBranchUserStateDetails(str) {
    // getBranches
    let branchID = str;
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
   // if(profileData['muthootBranchUser']) {
    //  this.proposerVo.branchId = str;
      // branchID = 'MUT' + str;
      // this.userServices.getBranches(branchID).then(data => {
      //   for (let i=0 ; i<data.length; i++){
      //   this.proposerVo.issueStateId = data[i].branchStateId;
      //    this.proposerVo.branchCode = data[i].branchCode;
      //    this.proposerVo.branchName = data[i].branchName;
      //    this.proposerVo.branchId = data[i].branchID;
      //   }
      // }).catch(error =>{
      // });
  //  } else {
    this.userServices.getBranchDetails(branchID).then(data => {
      if (data !== undefined) {
        this.proposerVo.issueStateId = data.branchStateId;
        this.proposerVo.branchCode = data.branchCode;
        this.proposerVo.branchName = data.branchName;
        //  this.loadPremiumDetails();;
      }
    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
      // this.openActionFailDialog('Branch User State data could not be fetched');
    });
 // }
  }

  public setProductTerm() {
  }

  ngAfterViewChecked() {
    this.outSidevalidate();
  }


  public setRetrievelInfo(data) {
    
    const policyTerm = data['policyTerm'];
    sessionStorage.setItem('retrievedPolicyTerm', policyTerm);
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.proposerVo.policyStartDate = data.policyStartDate;

    this.proposerVo.panNumber=data.panNumber;
    this.proposerVo.aadharNumber=data.aadharNumber;
    this.proposerVo.ckycNumber=data.ckycNumber;
    this.proposerVo.dateOfBirth=data.dateOfBirth;

    this.selectService.fetchLookUpData('RelationType', null).then(relationList => {
      sessionStorage.setItem('RelationType', JSON.stringify(relationList));
    });
    if (data.previousCertificateNo !== undefined && data.previousCertificateNo !== '') {
      this.isExistingProposal = true;
      this.existingCertificateNo = data.previousCertificateNo;
    this.retrieveFlag = true;
    this.retrieveAdultList = [];
    this.retrieveChildList = [];
    this.max = data.policyStartDate;
    if (this.shortProdName === 'Health' && data.productAnswers === undefined) {
      this.proposalServices
        .getQuestion()
        .then(data => {
          this.questionList = data;
          if (this.questionList.length !== 0) {
            for (let i = 0; i < this.questionList.length; i++) {
              this.questionList[i].answer = this.questionList[
                i
              ].choices[1].choiceValue;
            }
          }
        })
        .catch(error => {
        });
    } else if(this.shortProdName === 'Health') {
      this.questionList = data.productAnswers;
      if (this.questionList.length !== 0) {
        for (let i = 0; i < this.questionList.length; i++) {
          this.questionList[i].answer = this.questionList[i].answer;
          this.questionList[i].questionName = this.questionList[i].questionDescription;
          if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
            delete this.questionList[i].productAnswerId;
          }
        }
      }
    }
    // this.getAdultRelationShipList();
    this.proposerVo.policyStartDate = data.policyStartDate;

    const benifiList = data.beneficiaryList;
    if (this.shortProdName === 'Health') {
      this.selectService.fetchLookUpData('RelationType', null).then(relationList => {
        sessionStorage.setItem('RelationType', JSON.stringify(relationList));
        for (let i =0; i <benifiList.length; i++) {
          benifiList[i].nomineeRelation = this.getRelationshipID(benifiList[i].nomineeRelationDescription);
         // benifiList[i].proposerRelation = this.getRelationshipID(benifiList[i].proposerRelationDescription);
        }
        data.beneficiaryList = benifiList;
      });
      this.retrieveList = data.beneficiaryList;
      this.originalBeneficiaryList = data.beneficiaryList;
    }
    this.proposerVo.productId = data.productId;
    this.proposerVo.planTypeName = data.planDescription;
    const productCode: any = data.productId;
    
    this.proposerVo.policyTypeName = data.policyTypeDescription;
    this.proposerVo.sumInsured = data.sumInsured;
    this.exSumInsured = data.sumInsured;
    if (this.shortProdName === 'Health')  {
      this.proposerVo.policyTypeId = data.policyTypeId;
    if (this.proposerVo.policyTypeId === undefined) {
      this.selectService.fetchLookUpData('PolicyType', this.proposerVo.policyStartDate).then(data1 => {
        this.proposerVo.policyTypeId = this.getPolicyType(this.proposerVo.policyTypeName);
      }).then(data2 => {
        this.proposerVo.planId = this.getPlanDetailsForRenewals(this.proposerVo.planTypeName);
       data.planId = this.proposerVo.planId;
        this.proposerVo.policyEndDate = data.policyEndDate;
        this.proposerVo.policyId = data.policyId;
        this.proposerVo.policyTerm = data.policyTerm;
        // data.masterPolicyNo = '1212121';
        // this.proposerVo.masterPolicyNo = data.masterPolicyNo;
        
        this.proposerVo.planId = data.planId;
        this.retrivePlan = data.planId;
        this.currentPlanId = data.planId;
        this.existingPlanName = data.planDescription;
        this.existingPolicyName = data.policyTypeDescription;
        this.existingPlan = data.planId;
        this.getPlansHealth();
        //  this.fetechPlanForHomeAndShop();
         // if (this.proposerVo.planId !== undefined && this.proposerVo.planId !== null && this.proposerVo.planId !== '') {
         //   this.getBusinessPlanDetails(this.proposerVo.planId);
        //  }
       
       

      }).catch(error => {
  
      });
    } 
    } else {
      this.proposerVo.planId = this.getPlanDetailsForRenewals(this.proposerVo.planTypeName);
      this.proposerVo.planTypeName = data.planDescription;
      data.planId = this.proposerVo.planId;
    //  this.proposerVo.policyEndDate = data.policyEndDate;
      this.proposerVo.policyId = data.policyId;
      this.proposerVo.policyTerm = data.policyTerm;
      this.proposerVo.planId = data.planId;
      this.retrivePlan = data.planId;
      this.currentPlanId = data.planId;
      this.existingPlanName = data.planDescription;
      this.existingPolicyName = data.policyTypeDescription;
      this.existingPlan = data.planId;
    }
    
    this.exMasterPolicy = this.proposerVo.masterPolicyNo;
    this.proposedInfoFlag = true;
    this.existingPolicy = data.policyTypeId;
    this.proposerVo.employeeName = data.employeeName;
    this.premiumDetail = data.premiumDetails;
    if(data.paymentDetails !== undefined) {
      this.paymentDetail = data.paymentDetails;
    }
    this.existingPremium = data.premiumDetails.totalGrossPremium;
    this.exPolicyStartDate = data.policyStartDate;
    this.exPolicyTerm = data.policyTerm;
    

    if (this.actionType === 'ENDORSEMENT' || this.actionType === 'RENEWAL' || this.actionType === 'RETRIEVE') {
      this.proposerVo.certificateNo = data.certificateNo;
      // Endorsement policy term, call policy term endpoint
      if (this.actionType === 'ENDORSEMENT') {
        const policyTermCopy = this.proposerVo.policyTerm;
        this.proposerVo.policyTerm = policyTermCopy;
      //  console.log('calling 4');
        this.setPolicyTerm();
        if (data.endorsementId !== undefined) {
          this.proposerVo.endorsementReason = data.endorsementId;
          if (data.endorsementId === 2) {
            this.proposerVo.specifyReason = data.endorsmentOtherDetails;
            const reason = this.proposerVo.specifyReason.split("!");
            this.proposerVo.specifyReason=reason[0];
            this.checkEndorsementOthers(data.endorsementId);
          }
        }
      }
    }
    //  this.getPolicyTermDetails('1 year');
    if (this.actionType === 'RENEWAL') {
      const startDate = data.policyEndDate.split(' ');
      const parts = startDate[0].split('/');
      const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      const policyDate = new Date(mydate.setDate(mydate.getDate() + 1));
      if (this.policyStartDateFlag) {
        const ddate = this.datePipe.transform(policyDate, 'dd/MM/yyyy HH:mm:ss');
        this.proposerVo.policyStartDate = ddate;
      } else {
        this.proposerVo.policyStartDate = policyDate;
      }
    //  console.log('calling 5');
      this.setPolicyTerm();
    } else {
      const startDate = data.policyStartDate.split(' ');
      const parts = startDate[0].split('/');
      const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      const policyDate = new Date(mydate.setDate(mydate.getDate()));
      if (this.policyStartDateFlag) {
        const ddate = this.datePipe.transform(policyDate, 'dd/MM/yyyy HH:mm:ss');
        this.proposerVo.policyStartDate = ddate;
      } else {
        this.proposerVo.policyStartDate = policyDate;
      }
    }
    if (this.shortProdName === 'Health') {
    for (let i = 0; i < this.retrieveList.length; i++) {
      if (this.retrieveList[i].nomineeType === 1) {
        if (this.retrieveList[i]['dob']) {
          this.retrieveList[i]['dob'] = this.createDateObject(this.retrieveList[i]['dob']);
        } if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          delete this.retrieveList[i].beneficiaryInfoId;
          this.retrieveAdultList.push(this.retrieveList[i]);
        } else {
          this.retrieveAdultList.push(this.retrieveList[i]);
        }
      } else {
        if (this.retrieveList[i]['dob']) {
          this.retrieveList[i]['dob'] = this.createDateObject(this.retrieveList[i]['dob']);
        } if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          delete this.retrieveList[i].beneficiaryInfoId;
          this.retrieveChildList.push(this.retrieveList[i]);
          this.getChildRelationShipList();
        } else {
          this.retrieveChildList.push(this.retrieveList[i]);
          this.getChildRelationShipList();
        }
      }
    }

    this.childList = this.retrieveChildList;
    this.adultList = this.retrieveAdultList;
    
      if (this.proposerVo.planTypeName === '2 Adults' || this.proposerVo.planTypeName === '1 Adult' || this.proposerVo.planTypeName === '1 Adult + 1 Child' ||
        this.proposerVo.planTypeName === '1 Adult + 2 Children') {
        this.retrieveAdultList[0].proposerRelation = 'Self';
        if (this.proposerVo.planTypeName === '2 Adults') {
          this.getAdultRelationShipList();
        }
      } else {
        this.retrieveAdultList[0].proposerRelation = 'Self';
        this.retrieveAdultList[1].proposerRelation = 'Spouse';
      }
      this.exAdultList = JSON.parse(JSON.stringify(this.retrieveAdultList));
      this.exChildList = JSON.parse(JSON.stringify(this.retrieveChildList));
    }
    if (data.paymentDetails !== undefined) {
      this.premiumDetail.gatWayName = data.paymentDetails.paymentType;
    }
    if (this.shortProdName === 'Home') {
      this.applianceDetail = [];
      this.exApplianceDetail = [];
      const applianceDetails = data.applianceDetails;
      for (let i = 0; i < applianceDetails.length; i++) {
        const quantity = [];
        if (applianceDetails[i].quantity === undefined) {
          applianceDetails[i].appliances.quantity = 0;
        }
        this.exApplianceDetail.push(applianceDetails[i]);
        this.applianceDetail.push(applianceDetails[i]);
      }
    }
    this.proposerVo.communicationDetails = data.communicationDetails;
    this.proposerVo.communicationDetails.pincode = data.communicationDetails.pincodeLocality;
    this.proposerVo.communicationDetails.stateName = data.communicationDetails.stateName;
    this.proposerVo.communicationDetails.districtName = data.communicationDetails.districtName;
    this.proposerVo.communicationDetails.cityName = data.communicationDetails.cityName;
    this.proposerVo.customerDetails = data.customerDetails;
    if (data.communicationDetails.isGstinAvail !== undefined) {
      if (data.communicationDetails.isGstinAvail === true) {
        this.isGstinNoApplicable = true;
        this.proposerVo.communicationDetails.isGstinAvail = 1;
      } else if (data.communicationDetails.isGstinAvail === false) {
        this.proposerVo.communicationDetails.isGstinAvail = 0;
      }
    }
    if (this.shortProdName !== 'Health') {
      if (data.riskLocationDetails.isGstinAvail !== undefined) {
        if (data.riskLocationDetails.gstinNo !== '') {
          this.isRiskGstinNoApplicable = true;
        }
      }
    }
    // Sahaj Changes
    // if (this.shortProdName !== 'Health') {
    //   this.getEmployeeName(this.proposerVo.customerDetails.employeeId);
    // }
    if (this.proposerVo.customerDetails.title === 5) {
      this.disableonMs();
    }
    if (data.customerDetails.customerId !== undefined) {
      this.proposerVo.customerDetails.customerId = data.customerDetails.customerId;
      this.proposerVo.customerDetails.employeeId = data.customerDetails.employeeId;
      this.customerDetail.employeeId = data.customerDetails.employeeId;
      this.customerDetail.customerId = data.customerDetails.customerId;
      this.customerDetail.customerDetailId = data.customerDetails.customerDetailId;
    }
    if (data.riskLocationDetails !== undefined) {
      // pincode need to set for rislocationAddress.
      if (data.communicationDetails.address1 === data.riskLocationDetails.address1
        && data.communicationDetails.address2 === data.riskLocationDetails.address2
        && data.communicationDetails.address3 === data.riskLocationDetails.address3
        && data.communicationDetails.pincodeId === data.riskLocationDetails.pincodeId
        && data.communicationDetails.pincodeLocality === data.riskLocationDetails.pincodeLocality
        && data.communicationDetails.mobile === data.riskLocationDetails.mobile
        && data.communicationDetails.stateId === data.riskLocationDetails.stateId
        && data.communicationDetails.stateName === data.riskLocationDetails.stateName
        && data.communicationDetails.districtId === data.riskLocationDetails.districtId
        && data.communicationDetails.districtName === data.riskLocationDetails.districtName
        && data.communicationDetails.cityId === data.riskLocationDetails.cityId
        && data.communicationDetails.cityName === data.riskLocationDetails.cityName
        && data.communicationDetails.emailId === data.riskLocationDetails.emailId
        && data.communicationDetails.telephone === data.riskLocationDetails.telephone) {

        this.setRiskLocation('yes');
        this.proposerVo.riskLocationDetails.pincode = data.communicationDetails.pincodeLocality;
        this.proposerVo.riskLocationDetails.pincodeId = data.communicationDetails.pincodeId;
        this.proposerVo.riskLocationDetails.stateName = data.communicationDetails.stateName;
        this.proposerVo.riskLocationDetails.districtName = data.communicationDetails.districtName;
        this.proposerVo.riskLocationDetails.cityName = data.communicationDetails.cityName;
      } else {
        this.setRiskLocation('No');
        this.proposerVo.riskLocationDetails = data.riskLocationDetails;
        this.proposerVo.riskLocationDetails.pincodeId = data.riskLocationDetails.pincodeId;
        this.proposerVo.riskLocationDetails.pincode = data.riskLocationDetails.pincodeLocality;
        
      }


    }

    if (data.customerDetails.customerName !== undefined && data.customerDetails.customerName !== null) {
      this.proposerVo.customerDetails.customerName = data.customerDetails.customerName;
    }
    this.proposerVo.covernoteNo = data.covernoteNo;
    this.premiumID = data.premiumDetails.premiumId;
    
    this.premiumDetail.startDate = this.proposerVo.policyStartDate;
    
    this.premiumDetail.premiumId = data.premiumDetails.premiumId;
    // this.proposalInfoFlag = false;
    this.adultList = this.retrieveAdultList;
    this.childList = this.retrieveChildList;
    // this.proposerVo.sumInsured = data.sumInsured;

    if (this.shortProdName === 'Health' && this.actionType === 'RENEWAL') {
      this.insuredChangeFlag = true;
    }

    if (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL') {
      this.insuredChangeFlag = true;
      this.dataCopy['adultList'] = JSON.parse(JSON.stringify(this.exAdultList));
      this.dataCopy['childList'] = JSON.parse(JSON.stringify(this.exChildList));
      this.dataCopy['childCount'] = this.dataCopy['childList'].length;
      this.dataCopy['adultCount'] = this.dataCopy['adultList'].length;
      
      this.dataCopy['policyStartDate'] = this.proposerVo.policyStartDate;
      
      this.dataCopy['policyTerm'] = this.proposerVo.policyTerm;
      this.dataCopy['policyEndDate'] = this.proposerVo.policyEndDate;
      this.dataCopy['masterPolicyNo'] = this.proposerVo.masterPolicyNo;
      this.dataCopy['policyTypeId'] = this.proposerVo.policyTypeId;
      this.dataCopy['planId'] = this.proposerVo.planId;
      this.proposerVo.sumInsured = data.sumInsured;
      this.dataCopy['sumInsured'] = this.proposerVo.sumInsured;
    }
    } else {
    this.retrieveFlag = true;
    this.retrieveAdultList = [];
    this.retrieveChildList = [];
    this.max = data.policyStartDate;
    if (this.shortProdName === 'Health' && data.productAnswers === undefined) {
      this.proposalServices
        .getQuestion()
        .then(data => {
          this.questionList = data;
          if (this.questionList.length !== 0) {
            for (let i = 0; i < this.questionList.length; i++) {
              this.questionList[i].answer = this.questionList[
                i
              ].choices[1].choiceValue;
            }
          }
        })
        .catch(error => {
        });
    } else {
      this.questionList = data.productAnswers;
      if (this.questionList.length !== 0) {
        for (let i = 0; i < this.questionList.length; i++) {
          this.questionList[i].answer = this.questionList[i].answer;
          this.questionList[i].questionName = this.questionList[i].questionDescription;
          if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
            delete this.questionList[i].productAnswerId;
          }
        }
      }
    }
    // this.getAdultRelationShipList();
    this.proposerVo.policyStartDate = data.policyStartDate;
    this.proposerVo.productId = data.productId;
    this.proposerVo.planTypeName = data.planDescription;
    const productCode: any = data.productId;
    this.proposerVo.policyEndDate = data.policyEndDate;
    this.proposerVo.policyId = data.policyId;
    this.proposerVo.policyIdEncrypt=data.policyIdEncrypt;
    this.proposerVo.policyTerm = data.policyTerm;
    // data.masterPolicyNo = '1212121';
    this.proposerVo.masterPolicyNo = data.masterPolicyNo;
    this.proposerVo.sumInsured = data.sumInsured;
    this.exSumInsured = data.sumInsured;
    this.proposerVo.policyTypeId = data.policyTypeId;
    this.proposerVo.policyTypeName = data.policyTypeDescription;
    this.proposerVo.planId = data.planId;
    this.retrivePlan = data.planId;
    this.existingPlanName = data.planDescription;
    this.existingPolicyName = data.policyTypeDescription;
    this.retrieveList = data.beneficiaryList;
    this.existingPlan = data.planId;
    this.proposedInfoFlag = true;
    this.existingPolicy = data.policyTypeId;
    this.proposerVo.employeeName = data.employeeName;
    this.premiumDetail = data.premiumDetails;
    this.paymentDetail = data.paymentDetails;
    this.existingPremium = data.premiumDetails.totalGrossPremium;
    

    this.exPolicyStartDate = data.policyStartDate;
    this.exPolicyTerm = data.policyTerm;
    this.exMasterPolicy = data.masterPolicyNo;
    this.currentPlanId = data.planId;
    this.originalBeneficiaryList = data.beneficiaryList;
    this.proposerVo.certificateNo = data.certificateNo;
    if (this.actionType === 'ENDORSEMENT'  || this.actionType === 'RETRIEVE') {
      // || this.actionType === 'RENEWAL'
      if (this.shortProdName !== 'Health') {
        this.fetechPlanForHomeAndShop();
        this.getBusinessPlanDetails(this.proposerVo.planId);
      } 
      else if (this.shortProdName === 'Health') {
        this.getPlansHealth();
      }
      this.proposerVo.certificateNo = data.certificateNo;
      // Endorsement policy term, call policy term endpoint
      if (this.actionType === 'ENDORSEMENT') {
        const policyTermCopy = this.proposerVo.policyTerm;
        this.proposerVo.policyTerm = policyTermCopy;
      //  console.log('calling 6');
        this.setPolicyTerm();
        if (data.endorsementId !== undefined) {
          this.proposerVo.endorsementReason = data.endorsementId;
          if (data.endorsementId === 2) {
            this.proposerVo.specifyReason = data.endorsmentOtherDetails;
            const reason = this.proposerVo.specifyReason.split("!");
            this.proposerVo.specifyReason=reason[0];
            this.checkEndorsementOthers(data.endorsementId);
          }
        }
      }
    }
    //  this.getPolicyTermDetails('1 year');
    if (this.actionType === 'RENEWAL') {
      const startDate = data.policyEndDate.split(' ');
      const parts = startDate[0].split('/');
      const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      const policyDate = new Date(mydate.setDate(mydate.getDate() + 1));
      if (this.policyStartDateFlag) {
        const ddate = this.datePipe.transform(policyDate, 'dd/MM/yyyy HH:mm:ss');
        this.proposerVo.policyStartDate = ddate;
      } else {
        this.proposerVo.policyStartDate = policyDate;
      }
    //  console.log('calling 7');
    if (this.shortProdName === 'Health')  {
     this.proposerVo.policyTypeId = undefined; //data.policyTypeId;
    if (this.proposerVo.policyTypeId === undefined) {
      this.selectService.fetchLookUpData('PolicyType', this.proposerVo.policyStartDate).then(data1 => {
        this.proposerVo.policyTypeId = this.getPolicyType(this.proposerVo.policyTypeName);
        this.renewalOrRetrievalNewPolicy =  this.proposerVo.policyTypeId;
      }).then(data2 => {
        this.proposerVo.planId = this.getPlanDetailsForRenewals(this.proposerVo.planTypeName);
        this.renewalOrRetrievalNewPlan = this.proposerVo.planId;
       // data.planId = this.proposerVo.planId;
      //  this.proposerVo.policyEndDate = data.policyEndDate;
        this.proposerVo.policyId = data.policyId;
        this.proposerVo.policyTerm = data.policyTerm;
        // data.masterPolicyNo = '1212121';
        // this.proposerVo.masterPolicyNo = data.masterPolicyNo;
        
        this.proposerVo.planId = data.planId;
        this.retrivePlan = data.planId;
        this.currentPlanId = data.planId;
        this.existingPlanName = data.planDescription;
        this.existingPolicyName = data.policyTypeDescription;
        this.existingPlan = data.planId;
        this.getPlansHealth();
        //  this.fetechPlanForHomeAndShop();
         // if (this.proposerVo.planId !== undefined && this.proposerVo.planId !== null && this.proposerVo.planId !== '') {
         //   this.getBusinessPlanDetails(this.proposerVo.planId);
        //  }
       
       

      }).catch(error => {
  
      });
    } 
    }
      this.setPolicyTerm();
    } else {
      const startDate = data.policyStartDate.split(' ');
      const parts = startDate[0].split('/');
      const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
      const policyDate = new Date(mydate.setDate(mydate.getDate()));
      if (this.policyStartDateFlag) {
        const ddate = this.datePipe.transform(policyDate, 'dd/MM/yyyy HH:mm:ss');
        this.proposerVo.policyStartDate = ddate;
      } else {
        this.proposerVo.policyStartDate = policyDate;
      }
    }
    for (let i = 0; i < this.retrieveList.length; i++) {
      if (this.retrieveList[i].nomineeType === 1) {
        if (this.retrieveList[i]['dob']) {
          this.retrieveList[i]['dob'] = this.createDateObject(this.retrieveList[i]['dob']);
        } if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          delete this.retrieveList[i].beneficiaryInfoId;
          this.retrieveAdultList.push(this.retrieveList[i]);
        } else {
          this.retrieveAdultList.push(this.retrieveList[i]);
        }
      } else {
        if (this.retrieveList[i]['dob']) {
          this.retrieveList[i]['dob'] = this.createDateObject(this.retrieveList[i]['dob']);
        } if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          delete this.retrieveList[i].beneficiaryInfoId;
          this.retrieveChildList.push(this.retrieveList[i]);
          this.getChildRelationShipList();
        } else {
          this.retrieveChildList.push(this.retrieveList[i]);
          this.getChildRelationShipList();
        }
      }
    }

    this.childList = this.retrieveChildList;
    this.adultList = this.retrieveAdultList;
    if (this.shortProdName === 'Health') {
      if (this.proposerVo.planTypeName === '2 Adults' || this.proposerVo.planTypeName === '1 Adult' || this.proposerVo.planTypeName === '1 Adult + 1 Child' ||
        this.proposerVo.planTypeName === '1 Adult + 2 Children') {
        this.retrieveAdultList[0].proposerRelation = 'Self';
        if (this.proposerVo.planTypeName === '2 Adults') {
          this.getAdultRelationShipList();
        }
      } else {
        this.retrieveAdultList[0].proposerRelation = 'Self';
        this.retrieveAdultList[1].proposerRelation = 'Spouse';
      }
      
      // if (data.productAnswers !== undefined) {
      //   this.questionList = data.productAnswers;
      //   if (this.questionList.length !== 0) {
      //     for (let i = 0; i < this.questionList.length; i++) {
      //       this.questionList[i].answer = this.questionList[i].answer;
      //       this.questionList[i].questionName = this.questionList[i].questionDescription;
      //       if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
      //         delete this.questionList[i].productAnswerId;
      //       }
      //     }
      //   }
      // }
     
      this.exAdultList = JSON.parse(JSON.stringify(this.retrieveAdultList));
      this.exChildList = JSON.parse(JSON.stringify(this.retrieveChildList));
    }
    if (data.paymentDetails !== undefined) {
      this.premiumDetail.gatWayName = data.paymentDetails.paymentType;
    }
    if (this.shortProdName === 'Home') {
      this.applianceDetail = [];
      this.exApplianceDetail = [];
      const applianceDetails = data.applianceDetails;
      for (let i = 0; i < applianceDetails.length; i++) {
        const quantity = [];
        if (applianceDetails[i].quantity === undefined) {
          applianceDetails[i].appliances.quantity = 0;
        }
        this.exApplianceDetail.push(applianceDetails[i]);
        this.applianceDetail.push(applianceDetails[i]);
      }
    }
    this.proposerVo.communicationDetails = data.communicationDetails;
    this.proposerVo.communicationDetails.pincode = data.communicationDetails.pincodeLocality;
    this.proposerVo.communicationDetails.stateName = data.communicationDetails.stateName;
    this.proposerVo.communicationDetails.districtName = data.communicationDetails.districtName;
    this.proposerVo.communicationDetails.cityName = data.communicationDetails.cityName;
    this.proposerVo.customerDetails = data.customerDetails;
    if (data.communicationDetails.isGstinAvail !== undefined) {
      if (data.communicationDetails.isGstinAvail === true) {
        this.isGstinNoApplicable = true;
        this.proposerVo.communicationDetails.isGstinAvail = 1;
       // this.proposerVo.communicationDetails.gstinNo = data.communicationDetails.gstinNo;
      } else if (data.communicationDetails.isGstinAvail === false) {
        this.proposerVo.communicationDetails.isGstinAvail = 0;
      }
    }
    if (this.shortProdName !== 'Health') {
      if (data.riskLocationDetails.isGstinAvail !== undefined) {
        if (data.riskLocationDetails.gstinNo !== '') {
          this.isRiskGstinNoApplicable = true;
        //  this.proposerVo.riskLocationDetails.isGstinAvail = 0;
        }
        // if (data.riskLocationDetails.isGstinAvail === true) {
        //   const data1 = 1;
        //   this.proposerVo.riskLocationDetails.isGstinAvail = data1;
        //   this.isRiskGstinNoApplicable = true;
        //  // this.proposerVo.communicationDetails.gstinNo = data.communicationDetails.gstinNo;
        // } else if (data.riskLocationDetails.isGstinAvail === false) {
        //   this.proposerVo.riskLocationDetails.isGstinAvail = 0;
        // }
      }
    }
    // Sahaj changes
    // if (this.shortProdName !== 'Health') {
    //   this.getEmployeeName(this.proposerVo.customerDetails.employeeId);
    // }
    if (this.proposerVo.customerDetails.title === 5) {
      this.disableonMs();
    }
    if (data.customerDetails.customerId !== undefined) {
      this.proposerVo.customerDetails.customerId = data.customerDetails.customerId;
      this.proposerVo.customerDetails.employeeId = data.customerDetails.employeeId;
      this.customerDetail.employeeId = data.customerDetails.employeeId;
      this.customerDetail.customerId = data.customerDetails.customerId;
      this.customerDetail.customerDetailId = data.customerDetails.customerDetailId;
    }
    if (data.riskLocationDetails !== undefined) {
      // pincode need to set for rislocationAddress.
      if (data.communicationDetails.address1 === data.riskLocationDetails.address1
        && data.communicationDetails.address2 === data.riskLocationDetails.address2
        && data.communicationDetails.address3 === data.riskLocationDetails.address3
        && data.communicationDetails.pincodeId === data.riskLocationDetails.pincodeId
        && data.communicationDetails.pincodeLocality === data.riskLocationDetails.pincodeLocality
        && data.communicationDetails.mobile === data.riskLocationDetails.mobile
        && data.communicationDetails.stateId === data.riskLocationDetails.stateId
        && data.communicationDetails.stateName === data.riskLocationDetails.stateName
        && data.communicationDetails.districtId === data.riskLocationDetails.districtId
        && data.communicationDetails.districtName === data.riskLocationDetails.districtName
        && data.communicationDetails.cityId === data.riskLocationDetails.cityId
        && data.communicationDetails.cityName === data.riskLocationDetails.cityName
        && data.communicationDetails.emailId === data.riskLocationDetails.emailId
        && data.communicationDetails.telephone === data.riskLocationDetails.telephone) {

        this.setRiskLocation('yes');
      } else {
        this.setRiskLocation('No');
      }

      this.proposerVo.riskLocationDetails = data.riskLocationDetails;
      this.proposerVo.riskLocationDetails.pincode = data.communicationDetails.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = data.communicationDetails.stateName;
      this.proposerVo.riskLocationDetails.districtName = data.communicationDetails.districtName;
      this.proposerVo.riskLocationDetails.cityName = data.communicationDetails.cityName;

    }

    if (data.customerDetails.customerName !== undefined && data.customerDetails.customerName !== null) {
      this.proposerVo.customerDetails.customerName = data.customerDetails.customerName;
    }
    this.proposerVo.covernoteNo = data.covernoteNo;
    this.premiumID = data.premiumDetails.premiumId;
    
    this.premiumDetail.startDate = this.proposerVo.policyStartDate;
    
    this.premiumDetail.premiumId = data.premiumDetails.premiumId;
    // this.proposalInfoFlag = false;
    this.adultList = this.retrieveAdultList;
    this.childList = this.retrieveChildList;
    // this.proposerVo.sumInsured = data.sumInsured;

    if (this.shortProdName === 'Health' && this.actionType === 'RENEWAL') {
      this.insuredChangeFlag = true;
    }

    if (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL') {
      this.insuredChangeFlag = true;
      this.dataCopy['adultList'] = JSON.parse(JSON.stringify(this.exAdultList));
      this.dataCopy['childList'] = JSON.parse(JSON.stringify(this.exChildList));
      this.dataCopy['childCount'] = this.dataCopy['childList'].length;
      this.dataCopy['adultCount'] = this.dataCopy['adultList'].length;
      
      this.dataCopy['policyStartDate'] = this.proposerVo.policyStartDate;
      
      this.dataCopy['policyTerm'] = this.proposerVo.policyTerm;
      this.dataCopy['policyEndDate'] = this.proposerVo.policyEndDate;
      this.dataCopy['masterPolicyNo'] = this.proposerVo.masterPolicyNo;
      this.dataCopy['policyTypeId'] = this.proposerVo.policyTypeId;
      this.dataCopy['planId'] = this.proposerVo.planId;
      this.dataCopy['policyIdEncrypt'] = this.proposerVo.policyIdEncrypt;
      this.dataCopy['sumInsured'] = this.proposerVo.sumInsured;
    }
    // if (!this.getPlanDetails(data.planDescription)) {
    //   this.refresh3();
    // } else {
    //   this.proposerVo.planId = data.planId;
    //   this.proposerVo.sumInsured = data.sumInsured;
    // }
  }
  }

  public setEndorsmentInfo(data) {
    const policyTerm = data['policyTerm'];
    sessionStorage.setItem('retrievedPolicyTerm', policyTerm);
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);

    this.retrieveFlag = true;
    this.retrieveAdultList = [];
    this.retrieveChildList = [];
    this.max = data.policyStartDate;
    
    this.proposerVo.policyStartDate = data.policyStartDate;
    
    this.proposerVo.productId = data.productId;
    const productCode: any = data.productId;
    const endDateSplit = data.policyEndDate.split(' ');
    const endDate = endDateSplit[0] + ' 23:59:59';   
    this.proposerVo.policyEndDate = endDate;
    this.proposerVo.policyId = data.policyId;
    this.proposerVo.policyTerm = data.policyTerm;
    this.proposerVo.masterPolicyNo = data.masterPolicyNo;
    this.proposerVo.sumInsured = data.sumInsured;
    this.exSumInsured = data.sumInsured;
    this.proposerVo.policyTypeId = data.policyTypeId;
    this.proposerVo.policyTypeName = data.policyTypeDescription;
    this.proposerVo.planId = data.planId;

    this.proposerVo.panNumber=data.panNumber;
    this.proposerVo.aadharNumber=data.aadharNumber;
    this.proposerVo.ckycNumber=data.ckycNumber;
    this.proposerVo.dateOfBirth=data.dateOfBirth;

    this.retrivePlan = data.planId;
   // this.proposerVo.planTypeName = data.planDescription;
    this.retrieveList = data.beneficiaryList;
    this.existingPlan = data.planId;
    this.proposedInfoFlag = true;
    this.existingPolicy = data.policyTypeId;
    this.proposerVo.employeeName = data.employeeName;
    this.premiumDetail = data.premiumDetails;
    this.paymentDetail = data.paymentDetails;
    this.existingPremium = data.premiumDetails.totalGrossPremium;

    this.exPolicyStartDate = data.policyStartDate;
    this.exPolicyTerm = data.policyTerm;
    this.exMasterPolicy = data.masterPolicyNo;
    this.currentPlanId = data.planId;
    this.proposerVo.sumInsuredId = data.sumInsuredId;
    this.originalBeneficiaryList = data.beneficiaryList;
    this.proposerVo.policyTermDescription = data.policyTermDescription;
    this.getSumInsured();
    this.fetechPlanForHomeAndShop();
    this.getBusinessPlanDetails(this.proposerVo.planId);
    this.proposerVo.certificateNo = data.certificateNo;
    if (data.endorsementId !== undefined) {
      this.proposerVo.endorsementReason = data.endorsementId;
      // Endorsement Changes
      this.exEndorsmentReason = data.endorsementId;
      if (data.endorsementId === 2) {
        this.proposerVo.specifyReason = data.endorsmentOtherDetails;
        const reason = this.proposerVo.specifyReason.split("!");
        this.proposerVo.specifyReason=reason[0];
        this.checkEndorsementOthers(data.endorsementId);
      }
    }
    // if (this.actionType === 'ENDORSEMENT' || this.actionType === 'RENEWAL' || this.actionType === 'RETRIEVE') {
    //   if (this.shortProdName !== 'Health') {
    //     this.fetechPlanForHomeAndShop();
    //     this.getBusinessPlanDetails(this.proposerVo.planId);
    //   } else if (this.shortProdName === 'Health') {
    //     this.getPlansHealth();
    //   }
    //   // Endorsement policy term, call policy term endpoint
    //   if (this.actionType === 'ENDORSEMENT') {
    //     const policyTermCopy = this.proposerVo.policyTerm;
    //     this.proposerVo.policyTerm = policyTermCopy;
    //     this.setPolicyTerm();
    //     if (data.endorsementId !== undefined) {
    //       this.proposerVo.endorsementReason = data.endorsementId;
    //       if (data.endorsementId === 2) {
    //         this.proposerVo.specifyReason = data.endorsmentOtherDetails;
    //         this.checkEndorsementOthers(data.endorsementId);
    //       }
    //     }
    //   }
    // }
    const startDate = data.policyStartDate.split(' ');
    const parts = startDate[0].split('/');
    const mydate = new Date(parts[2], parts[1] - 1, parts[0]);
    const policyDate = new Date(mydate.setDate(mydate.getDate()));
   
    this.proposerVo.policyStartDate = policyDate;
    
    for (let i = 0; i < this.retrieveList.length; i++) {
      if (this.retrieveList[i].nomineeType === 1) {
        if (this.retrieveList[i]['dob']) {
          this.retrieveList[i]['dob'] = this.createDateObject(this.retrieveList[i]['dob']);
        } if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          delete this.retrieveList[i].beneficiaryInfoId;
          this.retrieveAdultList.push(this.retrieveList[i]);
        } else {
          this.retrieveAdultList.push(this.retrieveList[i]);
        }
      } else {
        if (this.retrieveList[i]['dob']) {
          this.retrieveList[i]['dob'] = this.createDateObject(this.retrieveList[i]['dob']);
        } if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          delete this.retrieveList[i].beneficiaryInfoId;
          this.retrieveChildList.push(this.retrieveList[i]);
          this.getChildRelationShipList();
        } else {
          this.retrieveChildList.push(this.retrieveList[i]);
          this.getChildRelationShipList();
        }
      }
    }

    this.childList = this.retrieveChildList;
    this.adultList = this.retrieveAdultList;
    if (this.shortProdName === 'Health') {
      if (this.proposerVo.planTypeName === '2 Adults' || this.proposerVo.planTypeName === '1 Adult' || this.proposerVo.planTypeName === '1 Adult + 1 Child' ||
        this.proposerVo.planTypeName === '1 Adult + 2 Children') {
        this.retrieveAdultList[0].proposerRelation = 'Self';
        if (this.proposerVo.planTypeName === '2 Adults') {
          this.getAdultRelationShipList();
        }
      } else {
        this.retrieveAdultList[0].proposerRelation = 'Self';
        this.retrieveAdultList[1].proposerRelation = 'Spouse';
      }
      this.questionList = data.productAnswers;
      if (this.questionList.length !== 0) {
        for (let i = 0; i < this.questionList.length; i++) {
          this.questionList[i].answer = this.questionList[i].answer;
          this.questionList[i].questionName = this.questionList[i].questionDescription;
          if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
            delete this.questionList[i].productAnswerId;
          }
        }
      }
      this.exAdultList = JSON.parse(JSON.stringify(this.retrieveAdultList));
      this.exChildList = JSON.parse(JSON.stringify(this.retrieveChildList));
    }
    if (data.paymentDetails !== undefined) {
      this.premiumDetail.gatWayName = data.paymentDetails.paymentType;
    }
    if (this.shortProdName === 'Home') {
      this.applianceDetail = [];
      const applianceDetails = data.applianceDetails;
      for (let i = 0; i < applianceDetails.length; i++) {
        const quantity = [];
        if (applianceDetails[i].quantity === undefined) {
          applianceDetails[i].appliances.quantity = 0;
        }
        this.applianceDetail.push(applianceDetails[i]);
      }
    }
    this.proposerVo.communicationDetails = data.communicationDetails;
    this.proposerVo.communicationDetails.pincode = data.communicationDetails.pincodeLocality;
    this.proposerVo.communicationDetails.stateName = data.communicationDetails.stateName;
    this.proposerVo.communicationDetails.districtName = data.communicationDetails.districtName;
    this.proposerVo.communicationDetails.cityName = data.communicationDetails.cityName;
    this.proposerVo.customerDetails = data.customerDetails;
    this.exEmpId = data.customerDetails.employeeId;
    this.exEmpName = data.employeeName;
    // Endorsement Changes
    this.exCommAddress1 = data.communicationDetails.address1;
    this.exCommAddress2 = data.communicationDetails.address2;
    this.exCommAddress3 = data.communicationDetails.address3;
    this.exPincode = data.communicationDetails.pincodeLocality;
    this.exCommEmail = data.communicationDetails.emailId;
    this.exCommMobile =  data.communicationDetails.mobile;
    this.exCommPhone =  data.communicationDetails.telephone;
    this.exTitle =  data.customerDetails.title;
    this.exFirstName = data.customerDetails.firstName;
    this.exLastName = data.customerDetails.lastName;
    if (data.communicationDetails.isGstinAvail !== undefined) {
      if (data.communicationDetails.isGstinAvail === true) {
        this.isGstinNoApplicable = true;
        this.proposerVo.communicationDetails.isGstinAvail = 'Yes';
       // this.proposerVo.communicationDetails.gstinNo = data.communicationDetails.gstinNo;
      } else if (data.communicationDetails.isGstinAvail === false) {
        this.proposerVo.communicationDetails.isGstinAvail = 'No';
      }
    }
    if (this.shortProdName !== 'Health') {
      if (data.riskLocationDetails.isGstinAvail !== undefined) {
        if (data.riskLocationDetails.gstinNo !== '') {
          this.isRiskGstinNoApplicable = true;
        //  this.proposerVo.riskLocationDetails.isGstinAvail = 0;
        }
        // if (data.riskLocationDetails.isGstinAvail === true) {
        //   const data1 = 1;
        //   this.proposerVo.riskLocationDetails.isGstinAvail = data1;
        //   this.isRiskGstinNoApplicable = true;
        //  // this.proposerVo.communicationDetails.gstinNo = data.communicationDetails.gstinNo;
        // } else if (data.riskLocationDetails.isGstinAvail === false) {
        //   this.proposerVo.riskLocationDetails.isGstinAvail = 0;
        // }
      }
    }
    //Sahaj Changes
    // if (this.shortProdName !== 'Health') {
    //   this.getEmployeeName(this.proposerVo.customerDetails.employeeId);
    // }
    
    if (this.proposerVo.customerDetails.title === 5) {
      this.disableonMs();
    }
    if (data.customerDetails.customerId !== undefined) {
      this.proposerVo.customerDetails.customerId = data.customerDetails.customerId;
      this.proposerVo.customerDetails.employeeId = data.customerDetails.employeeId;
      this.customerDetail.employeeId = data.customerDetails.employeeId;
      this.customerDetail.customerId = data.customerDetails.customerId;
      this.customerDetail.customerDetailId = data.customerDetails.customerDetailId;
    }
    if (data.riskLocationDetails !== undefined) {
      // pincode need to set for rislocationAddress.
      if (data.communicationDetails.address1 === data.riskLocationDetails.address1
        && data.communicationDetails.address2 === data.riskLocationDetails.address2
        && data.communicationDetails.address3 === data.riskLocationDetails.address3
        && data.communicationDetails.pincodeId === data.riskLocationDetails.pincodeId
        && data.communicationDetails.pincodeLocality === data.riskLocationDetails.pincodeLocality
        && data.communicationDetails.mobile === data.riskLocationDetails.mobile
        && data.communicationDetails.stateId === data.riskLocationDetails.stateId
        && data.communicationDetails.stateName === data.riskLocationDetails.stateName
        && data.communicationDetails.districtId === data.riskLocationDetails.districtId
        && data.communicationDetails.districtName === data.riskLocationDetails.districtName
        && data.communicationDetails.cityId === data.riskLocationDetails.cityId
        && data.communicationDetails.cityName === data.riskLocationDetails.cityName
        && data.communicationDetails.emailId === data.riskLocationDetails.emailId
        && data.communicationDetails.telephone === data.riskLocationDetails.telephone) {

        this.setRiskLocation('yes');
      } else {
        this.setRiskLocation('No');
      }

      this.proposerVo.riskLocationDetails = data.riskLocationDetails;
      this.proposerVo.riskLocationDetails.pincode = data.riskLocationDetails.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = data.riskLocationDetails.stateName;
      this.proposerVo.riskLocationDetails.districtName = data.riskLocationDetails.districtName;
      this.proposerVo.riskLocationDetails.cityName = data.riskLocationDetails.cityName;
      if (data.riskLocationDetails.isGstinAvail !== undefined) {
        if (data.riskLocationDetails.gstinNo !== '') {
          this.isRiskGstinNoApplicable = true;
          this.proposerVo.riskLocationDetails.isGstinAvail = 'Yes';
          this.proposerVo.riskLocationDetails.gstinNo = data.riskLocationDetails.gstinNo;
        } else {
          this.proposerVo.riskLocationDetails.isGstinAvail = 'No';
        }
      }
      // Endorsement Changes
      this.exRiskAddress1 = data.riskLocationDetails.address1;
      this.exRiskAddress2 = data.riskLocationDetails.address2;
      this.exRiskAddress3 = data.riskLocationDetails.address3;
      this.exRiskEmail = data.riskLocationDetails.emailId;
      this.exRiskMobile =  data.riskLocationDetails.mobile;
      this.exRiskPhone =  data.riskLocationDetails.telephone;
    }

    if (data.customerDetails.customerName !== undefined && data.customerDetails.customerName !== null) {
      this.proposerVo.customerDetails.customerName = data.customerDetails.customerName;
      // Endorsement Changes
      this.exCommName = data.customerDetails.customerName;
    }
    this.proposerVo.covernoteNo = data.covernoteNo;
    this.premiumID = data.premiumDetails.premiumId;
    this.premiumDetail.startDate = this.proposerVo.policyStartDate;
    
    this.premiumDetail.premiumId = data.premiumDetails.premiumId;
    this.endorsmentPaymentOption = data.premiumDetails.gatWayName;
    this.getProductPaymentInfo();
    if (data.premiumDetails.gatWayName === 'Offline' || data.premiumDetails.gatWayName === 'Offline With OTP') {
      this.offlinePaymentType = true;
    } else {
      this.offlinePaymentType = false;
    }
    // this.proposalInfoFlag = false;
    this.adultList = this.retrieveAdultList;
    this.childList = this.retrieveChildList;
  }
  public policyStartDateSet() {


   }

  // continue button
  movetoNextLevel() {
    this.message = '';
    this.hasErrorMessage = false;
    this.enableSubmit = false;
    this.selectedLevel = this.selectedLevel + 1;
  }

  public covertDateFormat(inputDate) {
    return inputDate;
  }
  public radioChanged(Event){
    this.iagree = true;
    if(this.isImuthootAffinity && this.shortProdName === 'Shop'){
      if(this.iagree && this.enableSubmit) {
        this.createProposalFlag = true;
      }
    }
    return true;
  }
  // policyTypeData:Array<Object> = [
  //   {id: 1, name:"Business Package for Home"},
  //   {id: 2, name:"Business Package for Shop Owners"}
  // ]
  // public selectedPolicyType(){
  //   if (this.selectedPolicyItem.id == 1){
  //     this.iMuthootHomePolicy = true;
  //     this.iMuthootShopPolicy = false;
  //     this.shortProdName = "Home";
  //   }
  //   if (this.selectedPolicyItem.id == 2){
  //     this.iMuthootShopPolicy = true;
  //     this.iMuthootHomePolicy = false;
  //     this.shortProdName = "Shop";
  //   }
  // }
  public paymentValidation() {
    this.message = '';
    if (this.offlinePaymentType) {
     
      this.createProposalFlag = true;
      // meenachil Changes
      this.premiumDetail.gatWayName = this.affinityPaymentOption;
    //  this.premiumDetail.gatWayName = 'Offline';
      return true;
    } else if ((this.premiumDetail.gatWayName === undefined ||
      this.premiumDetail.gatWayName === null)) {
      this.enableSubmit = false;
      this.hasErrorMessage = true;
      return false;
    } else {
      if (this.shortProdName === 'Health') {
        if (this.premiumValidationMessage === '') {
         
          this.createProposalFlag = true;
        } else {
         
          this.createProposalFlag = false;
        }
      }
      if(this.isImuthootAffinity && this.shortProdName === 'Shop' && this.iagree) {
        this.createProposalFlag = true;
      } else if(this.isImuthootAffinity && this.shortProdName === 'Home' && !this.iagree){
        this.enableSubmit = false;
      }
      return true;
    }
  }
  // Previous button
  movetoPreviousLevel() {
    this.message = '';
    this.hasErrorMessage = false;
    this.selectedLevel = this.selectedLevel - 1;
  }
  // steps navigationvalidation
  public stepsNavigation(selectedLevel) {

    if (this.selectedLevel === 2) {
      if (this.shortProdName === 'Health' && !this.actionType) {
        this.dataCopy['adultList'] = JSON.parse(JSON.stringify(this.adultList));
        this.dataCopy['childList'] = JSON.parse(JSON.stringify(this.childList));
        this.dataCopy['childCount'] = this.childCount;
        this.dataCopy['adultCount'] = this.adultCount;
      } else if (this.shortProdName === 'Health' && (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL')) {
        this.dataCopy['adultList'] = JSON.parse(JSON.stringify(this.adultList));
        this.dataCopy['childList'] = JSON.parse(JSON.stringify(this.childList));
        this.dataCopy['childCount'] = this.childCount;
        this.dataCopy['adultCount'] = this.adultCount;
      }
    }
    // this.insuredChangeFlag = true;

    if (selectedLevel === 1 && this.policyDetailsFlag && this.failedValidationPage >= selectedLevel && this.subsequentPagesValidated(1)) {
      this.existingValueSet();
      this.selectedLevel = 1;

    } else if (selectedLevel === 2 && this.proposedInsuredFlag && this.failedValidationPage >= selectedLevel && this.subsequentPagesValidated(2) && this.maxVisitedLevel !== selectedLevel) {
      if (this.shortProdName === 'Health') {
        if (this.adultList.length === 0 && this.childList.length === 0) {
          this.calculateAD(this.proposerVo.planId);
        }
      }
      this.selectedLevel = 2;

    } else if (selectedLevel === 3 && this.communicationDetailsFlag && this.failedValidationPage >= selectedLevel && this.subsequentPagesValidated(3) && this.maxVisitedLevel !== selectedLevel) {
      this.selectedLevel = 3;

    } else if (selectedLevel === 4 && this.premiumAndPaymentFlag && this.failedValidationPage >= selectedLevel && this.subsequentPagesValidated(4) && this.maxVisitedLevel !== selectedLevel) {
      this.selectedLevel = 4;
      this.loadPremiumDetails();
    }
    document.body.scrollTop = document.documentElement.scrollTop = 0;

    /**TO DO */
    if (this.selectedLevel !== this.maxVisitedLevel) {
      setTimeout(function () { EmitterService.get('SHOW_VALIDATION_MESSAGE').emit(true); }, 100);
    }
  }

  fetchsuminsured(planid: any) {
    this.sumInsured = [];
    this.proposalServices.getSumInsured(planid).then(data => {
      const obj1: Object = new Object();
    });
  }

  public fetchPlanType() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    let startDate;
    if (this.actionType === 'RENEWAL' || this.actionType === 'RETRIEVE' || this.actionType === 'ENDORSEMENT') {
      startDate = this.proposerVo.policyStartDate;
    } else {
     // startDate = this.datePipe.transform(this.proposerVo.policyStartDate, 'dd/MM/yyyy HH:mm:ss');
     startDate = this.proposerVo.policyStartDate;
    }
    let policyDate = startDate;
    if (policyDate !== null && policyDate !== undefined) {
      if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(policyDate)) {
        policyDate = startDate;
      } else {
        policyDate = this.datePipe.transform(startDate, 'dd/MM/yyyy HH:mm:ss');
      }
    }
  //  console.log('calkling 13');
    this.proposalServices.getPolicyType(policyDate).then(data => {
      for (let i = 0; i < data.length; i++) {
        this.proposerVo.policyTypeId = data[i].policyTypeId;
      }
    });
  }
  public preparePageParams() {
    this.route.params.subscribe(params => {
      const code: string = params.productCode;
      sessionStorage.setItem(PRODUCT_CODE, this.productId);
      this.initilalize();
      this.fectchMasterPolicy();
      this.loadPaymentOptions();
      this.getAvilablePaymentOptions();
    });
  }
  fetchPolicyType() {
    const startDate = this.datePipe.transform(
      this.proposerVo.policyStartDate,
      'dd/MM/yyyy HH:mm:ss'
    );
  }

  calculateAD(planId: any) {
    this.policyErrorValidation();
    if (!planId) {
      return;
    }
    let data;
    let planType;
    planType = sessionStorage.getItem('PlanType');
    if (planType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_ID] === planId) {
            data = pnType[PLAN_TYPE_DESC];
          }
        }
      }
    } else {
      //   this.serviceDownMessage();
      //  console.log('Session Plan Type not available.');
    }

    if (!data) {
      data = '1 Adult';
    }

    this.adultCount = 0;
    this.childCount = 0;
    this.adultList = [];
    this.childList = [];
    const coverage: Array<any> = data.split('+');
    this.adultCount = Number.parseInt(coverage[0], 0);
    this.childCount = Number.parseInt(coverage[1], 0);
    if (data === '2 Adults') {
      this.childCount = 0;
    }

    if (this.childCount === 0 && this.adultCount === 2) {
      for (let i = 0; i < this.adultCount; i++) {
        const befiniciaryVo: BeneficiaryVO = new BeneficiaryVO();
        if (i === 0) {
          befiniciaryVo.proposerRelation = BENIFICIARY_SELF;
        } else {
          this.getAdultRelationShipList();
        }
        this.adultList.push(befiniciaryVo);
      }
    } else {
      for (let i = 0; i < this.adultCount; i++) {
        const befiniciaryVo: BeneficiaryVO = new BeneficiaryVO();
        if (i === 0) {
          befiniciaryVo.proposerRelation = BENIFICIARY_SELF;
        } else {
          befiniciaryVo.proposerRelation = BENIFICIARY_SPOUSE;
        }
        this.adultList.push(befiniciaryVo);
      }
      for (let i = 0; i < this.childCount; i++) {
        this.childList.push(new BeneficiaryVO());
        this.getChildRelationShipList();
      }
    }

    if (this.shortProdName === 'Health' && this.actionType !== 'RENEWAL' && this.actionType !== 'RETRIEVE') {
      if (this.proposedInfoFlag && !this.cancelFlag) {
        if (this.proposerVo.planId !== this.existingPlan) {
          this.proposerVo.sumInsured = undefined;
          this.maxVisitedLevel = 1;
          this.proposedInsuredFlag = false;
          this.communicationDetailsFlag = false;
          this.premiumAndPaymentFlag = false;
          this.retrieveAdultList = [];
          this.retrieveChildList = [];
          // this.openModal('planChange');
          return;
        } else if (this.proposerVo.planId === this.existingPlan) {
          this.adultList = this.exAdultList;
          this.childList = this.exChildList;
        }
      }
    }
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }
  public setPolicyTerm() {
    if (this.shortProdName !== 'Health' && this.shortProdName !== undefined) {
      this.fetechPlanForHomeAndShop();
    }
    let availableTermArry: any;
    this.policytermArray = [];
    new Promise((resolve, reject) => {
      const availableTerms: any = sessionStorage.getItem('PolicyTerm');
      availableTermArry = JSON.parse(availableTerms);
      resolve(100);
    }).then(data2 => {
      if (availableTermArry !== undefined) {
        if (this.productTerm !== undefined) {
          const policyTerm: Array<Object> = JSON.parse(this.productTerm);
          let defaultFlag = false;
          const defaultValue = '1 year';
          let defaultCode: any;
          for (const data of policyTerm) {
            for (const data1 of availableTermArry) {
              if (data['policyTermId'] === data1['policyTermId']) {
                const obj: Object = new Object();
                obj['Code'] = data1['policyTermId'];
                obj['Description'] = data1['policyTerm'];
                if (String(defaultValue) === String(obj['Description'])) {
                  defaultFlag = true;
                  defaultCode = obj['Code'] = data1['policyTermId'];
                  this.proposerVo.policyTerm = 1;
                  this.getPolicyEndDate();
                }
                this.policytermArray.push(obj);
              }
            }
          }
          if (!defaultFlag && this.policytermArray.length > 1 && this.policyTermField) {
            this.policyTermField.placeholder = '--Select--';
          } else if (defaultFlag && this.policyTermField) {
            this.policyTermField.value = defaultCode;
            this.policyTermField.placeholder = '';
          } else if (this.policytermArray.length === 1 && this.policyTermField) {
            this.policyTermField.value = this.policytermArray[0]['Code'];
            this.getPolicyEndDate();
          }
          // this.getPolicyEndDate();
        } else {
          const availableTerms: any = sessionStorage.getItem('PolicyTerm');
          availableTermArry = JSON.parse(availableTerms);
          let defaultFlag = false;
          let defaultCode: any;
          const defaultValue = '1 year';
          for (const data1 of availableTermArry) {
            const obj: Object = new Object();
            obj['Code'] = data1['policyTermId'];
            obj['Description'] = data1['policyTerm'];
            if (String(defaultValue) === String(obj['Description'])) {
              defaultFlag = true;
              defaultCode = obj['Code'] = data1['policyTermId'];
              this.getPolicyEndDate();
            }
            this.policytermArray.push(obj);
          }
          if (!defaultFlag && this.policytermArray.length > 1) {
            this.policyTermField.placeholder = ' ';
          } else if (defaultFlag && this.policyTermField) {
            this.policyTermField.value = defaultCode;
            this.policyTermField.placeholder = '';
          } else if (this.policytermArray.length === 1 && this.policyTermField) {
            this.policyTermField.value = this.policytermArray[0]['Code'];
          }
        }
      }
    }).catch(error => { });
    // if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
    //   console.log('calling policy 1');
    //   this.fetchPlanType();
    // }
  }

  public getPolicyTerm() {
    this.selectService.fetchLookUpData('PolicyTerm', undefined).then(data1 => {
      let dataArray;
      if (data1 !== undefined) {
        dataArray = data1;
        sessionStorage.setItem('PolicyTerm', JSON.stringify(dataArray));
      }

    }).catch(error => {

    });
  }

  public policyStartChange() {
    
  
    
    this.policyDateCancelFlag = true;
    // this.refresh3();
    if (this.actionType !== 'RENEWAL' && this.actionType !== 'RETRIEVE' && this.actionType !== 'ENDORSEMENT') {
      this.proposerVo.policyTypeId = undefined;
      this.proposerVo.planId = undefined;
      this.proposerVo.sumInsured = undefined;
    }
    this.healthPlanArray = [];    //   this.proposerVo = new ProposalVO();
    EmitterService.get('HIDE_VALIDATION_MESSAGE').emit(true);
    this.showModal();
  //  console.log('calling 9');
    this.setPolicyTerm();
    const insuredChangeFlagCopy = this.insuredChangeFlag;
    this.insuredChangeFlag = insuredChangeFlagCopy;
    // this.fetechPlanForHomeAndShop();
  }

  // Policy end date generation
  public getPolicyEndDate() {
    
    let start: any = 0;
    // const formattedDate = moment(this.proposerVo.policyStartDate);
    // console.log(formattedDate);
    if (this.policyStartDateFlag) {
      const startDateSeperation = this.proposerVo.policyStartDate.split(' ');
      start = this.createDateObject(startDateSeperation[0]);
    } else {
      start = this.proposerVo.policyStartDate;
    }
    const date2 = new Date();
    let policyEndDate: any;
    const year: Number = 1;
    let endDate: any;
    const s = new Date(start);
    this.policyYear = this.proposerVo.policyTerm;
    const selectedTermyear = date2.getFullYear() + this.proposerVo.policyTerm;

    s.setFullYear(s.getFullYear() + this.proposerVo.policyTerm);
    s.setDate(s.getDate() - 1);

    policyEndDate = s.getDate();



    endDate = new Date(s.setDate(policyEndDate));
    endDate.setSeconds(59);
    endDate.setHours(23);
    endDate.setMinutes(59);
    this.proposerVo.policyEnDate = endDate;
  
    const endDateTemp = moment(endDate).local().format('DD/MM/YYYY HH:mm:ss');
  //const endDateTemp = moment(endDate).format('DD/MM/YYYY');
    this.proposerVo.policyEndDate = String(endDateTemp) !== 'Invalid date' ? endDateTemp : '';
 
  }

  public leapYearCheck(year) {
    return (year % 100 === 0) ? (year % 400 === 0) : (year % 4 === 0);
  }
  /*Questions Validation*/
  public questionsValidation() {

    for (let i = 0; i < this.questionList.length; i++) {
      if (this.questionList[i].btnToggActiveYesFlag) {
        return false;
      }
    }
    return true;

  }
  // cancel proposal function call.
  public cancelProposal() {
    if (this.actionType === 'ENDORSEMENT') {
      this.router.navigateByUrl(this.router.url);
    }
    if (this.actionType !== 'RENEWAL' && this.actionType !== 'RETRIEVE' && this.actionType !== 'ENDORSEMENT') {
      // this.router.navigateByUrl('welcome/(contentBody:proposal/' + this.productId + ')');
      this.router.navigateByUrl('welcome/(contentBody:proposal/' + this.prodName + '/' + this.shortProdName + ')');
    } else {
      //
      // this.selectedLevel = 1;
      // this.initilalize();
      this.router.navigateByUrl(this.router.url);
    }
    this.moveToTop();

  }

  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  // CreateProposal popup continue function call
  public continueFunction() {
    this.adultList = [];
    this.childList = [];
    this.planChangeFlag = false;
    this.calculateAD(this.proposerVo.planId);
    this.stepsNavigation(1);
    this.existingPolicy = '';
    this.existingPlan = '';
    this.closeModal('confirmationModal');
    this.closeModal('planChange');
    this.closeModal('premiumBreakup');
  }
  // CreateProposal popup cancel button function call.
  public cancelFunction(str) {
    this.closeModal('confirmationModal');
    this.closeModal('planChange');
    this.closeModal('premiumBreakup');
    this.proposedInfoFlag = false;
    this.communicationDetail = new CommunicationVO();
    this.proposerVo.paymentOption = '';
    this.proposerVo.policyTypeId = this.existingPolicy;
    this.proposerVo.planId = this.existingPlan;
    const planId = this.proposerVo.planId;
    if (this.exPlanTypeArray) {
      const planTypeArrayObj: Array<Object> = JSON.parse(this.exPlanTypeArray);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_ID] === this.proposerVo.planId) {
            this.proposerVo.planTypeName = pnType[PLAN_TYPE_DESC];
          }
        }
      }
    }
    const policyId = this.proposerVo.policyTypeId;
    if (this.exPolicyTypeArray) {
      const planTypeArrayObj: Array<Object> = JSON.parse((this.exPolicyTypeArray));
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[POLICY_TYPE_ID] === this.proposerVo.policyTypeId) {
            this.proposerVo.policyTypeName = pnType[POLICY_TYPE_DESC];
          }
        }
      }
    }
    this.planChangeFlag = true;
    this.proposerVo.sumInsured = this.exSumInsured;
    this.calculateAD(this.proposerVo.planId);
    if (this.exAdultList.length !== 0) {
      for (let i = 0; i < this.exAdultList.length; i++) {
        this.adultList[i] = this.exAdultList[i];
      }
    } else {
      this.adultList = this.adultList;
    }
    if (this.exChildList.length !== 0) {
      for (let i = 0; i < this.exChildList.length; i++) {
        this.childList[i] = this.exChildList[i];
      }
    } else {
      this.childList = this.childList;
    }

  }

  // Create proposal function call.
  createProposal() {
    this.hasErrorMessageTwo = true;
    this.loadingmessage = 'Please wait...';
    this.moveToTop();
    this.proposerVo.smId = this.smId;
    this.proposerVo.crmLeadId= this.crmLeadId;
    this.proposerVo.affinityAuthToken = JSON.parse(sessionStorage.getItem(USER_PROFILE));
    
    if (this.shortProdName === 'Health') {
      this.adultProposerSet();
      this.setCustomerDetails();
      this.setAdultAndChildDesc();
      this.proposerVo.beneficiaryList = this.adultList.concat(this.childList);
      for (const beneficiary of this.proposerVo.beneficiaryList) {
        beneficiary.isActive = 1;
      }
      const additionalBeneficiaries = [];
      if (this.actionType === 'RETRIEVE') {
        for (const beneficiary of this.originalBeneficiaryList) {
          let flag = false;
          for (const beneficiary2 of this.proposerVo.beneficiaryList) {
            if (beneficiary['beneficiaryInfoId'] && beneficiary2['beneficiaryInfoId']) {
              if (String(beneficiary['beneficiaryInfoId']) === String(beneficiary2['beneficiaryInfoId'])) {
                flag = true;
              }
            }
          }
          if (!flag) {
            beneficiary.isActive = 0;
            beneficiary.dob = moment(beneficiary.dob, 'ddd MMM DD YYYY').format('DD/MM/YYYY');
            if (beneficiary.nomineeType === 1) {
              beneficiary.proposerRelation = this.getRelationshipID(beneficiary.proposerRelation);
            }
            additionalBeneficiaries.push(beneficiary);
          }
        }
      }
      this.proposerVo.beneficiaryList = this.proposerVo.beneficiaryList.concat(additionalBeneficiaries);
      if (this.questionList.length !== 0) {
        for (let i = 0; i < this.questionList.length; i++) {
          this.questionList[i].answer = 0;
          this.questionList[i].question = this.questionList[i].questionDescription;
        }
  }
      this.proposerVo.productAnswers = this.questionList;
      this.proposerVo.planDescription = this.proposerVo.planTypeName;
      this.proposerVo.policyTypeDescription = this.proposerVo.policyTypeName;
      if (this.isRiskAddress !== undefined && this.isRiskAddress === 0) {
        this.proposerVo.riskLocationDetails = null;
      }
    } else {
      this.proposerVo.applianceDetails = this.applianceDetail;
      if(this.proposerVo.riskLocationDetails.address1=== this.proposerVo.communicationDetails.address1
        && this.proposerVo.riskLocationDetails.address2=== this.proposerVo.communicationDetails.address2
        && this.proposerVo.riskLocationDetails.address3=== this.proposerVo.communicationDetails.address3
        && this.proposerVo.riskLocationDetails.pincode=== this.proposerVo.communicationDetails.pincode
        && this.proposerVo.riskLocationDetails.emailId=== this.proposerVo.communicationDetails.emailId
        && this.proposerVo.riskLocationDetails.mobile=== this.proposerVo.communicationDetails.mobile
        && this.proposerVo.riskLocationDetails.telephone=== this.proposerVo.communicationDetails.telephone
        && this.proposerVo.riskLocationDetails.isGstinAvail=== this.proposerVo.communicationDetails.isGstinAvail
        && this.proposerVo.riskLocationDetails.gstinNo=== this.proposerVo.communicationDetails.gstinNo)
      {
        this.isRiskAddress=1;
      }
      else{
        this.isRiskAddress=0;
      }
      this.proposerVo.communicationDetails.isRiskAddress = this.isRiskAddress;
      // 11/06/2020 for defect fixes added
      if (this.isRiskAddress !== undefined && this.isRiskAddress === 1) {
        this.proposerVo.riskLocationDetails = this.proposerVo.communicationDetails;
      }

    }
    this.proposerVo.productId = sessionStorage.getItem(PRODUCT_CODE);
    
    if (!this.policyStartDateFlag) {
      // this.proposerVo.policyStartDate = this.datePipe.transform(
      //   this.proposerVo.policyStartDate,
      //   'dd/MM/yyyy HH:mm:ss'
      // );
      if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(this.proposerVo.policyStartDate)) {
        this.proposerVo.policyStartDate = this.proposerVo.policyStartDate;
      } else {
        this.proposerVo.policyStartDate = this.datePipe.transform(this.proposerVo.policyStartDate, 'dd/MM/yyyy HH:mm:ss');
      }
    }
    if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
      delete this.proposerVo.customerDetails.customerDetailId;
      delete this.customerDetail.customerDetailId;
      delete this.proposerVo.communicationDetails.customerAddressId;
      delete this.premiumDetail.premiumId;
      delete this.riskLocationDetail.customerAddressId;
      delete this.riskLocationDetail.customerRiskAddressId;
      delete this.proposerVo.communicationDetails.customerRiskAddressId;
      delete this.proposerVo.policyTermDescription;
      this.proposerVo.customerDetails.isActive = true;
      if (this.proposerVo.riskLocationDetails !== undefined && this.proposerVo.riskLocationDetails !== null) {
        delete this.proposerVo.riskLocationDetails.customerAddressId;
        delete this.proposerVo.riskLocationDetails.customerRiskAddressId;
      }
      if(this.paymentDetail !== undefined) {
        delete this.paymentDetail.paymentID;
       delete this.paymentDetail.otp;
      }
      this.proposerVo.actionType = this.actionType;
      this.proposerVo.policyStatusId = 2;
    } else {
      this.proposerVo.policyStatusId = 6;
    }
    this.premiumDetail.startDate = this.proposerVo.policyStartDate;
    this.premiumDetail.endDate = this.proposerVo.policyEndDate;
      if (this.shortProdName === 'Health') {
     this.proposerVo.customerDetails.firstName = this.customerDetail.firstName;
     this.proposerVo.customerDetails.lastName = this.customerDetail.lastName;
      }
    if (this.actionType === 'RETRIEVE') {
      this.proposerVo.isRetrieve = true;
      if (this.offlinePaymentType) {
        this.premiumDetail.gatWayName = 'Offline';
      }
    }
    if (this.premiumDetail.gatWayName !== undefined) {
      const selectedGateWayName = this.premiumDetail.gatWayName;
      const paymentTypes: any = sessionStorage.getItem('gateWayDetails');
      if (paymentTypes) {
        const paymentArrayObj: Array<Object> = JSON.parse(paymentTypes);
        if (paymentArrayObj) {
          for (const paymentType of paymentArrayObj) {
            if (paymentType['gatewayName'] === selectedGateWayName) {
              if (paymentType['gatewayId'] !== 0) {
                this.paymentDetail.paymentType = selectedGateWayName;
                this.paymentDetail.amount = this.premiumDetail.grossPremium;
                this.premiumDetail.gatewayId = paymentType['gatewayId'];
              } else if (paymentType['walletId'] !== 0) {
                this.paymentDetail.paymentType = selectedGateWayName;
                this.paymentDetail.amount = this.premiumDetail.grossPremium;
                this.premiumDetail.walletId = paymentType['walletId'];
              }
            }
          }
        }
      }
    }
    this.proposerVo.paymentDetails = this.paymentDetail;
   //// if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
      // this.proposerVo.paymentDetails.status = 'OTP Verified';
  //  }
    this.proposerVo.premiumDetails = this.premiumDetail;
    const poicyDate = new Date();
    this.proposerVo.policyIssueDate = moment(poicyDate).format('DD/MM/YYYY HH:mm:ss');
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    if(this.isImuthootAffinity) {
      this.proposerVo.isCustomerProposal= 'Y';
      this.proposerVo.customerDetails.customerId = profileData['body']['customerId'];
     this.proposerVo.ssoTokenUser = profileData['body']['ssoToken'];
    } else {
      this.proposerVo.isCustomerProposal= 'N';
      if(profileData['muthootBranchUser']) {
        this.proposerVo.isMuthootBranchUser = 'Y';
       // this.proposerVo.branchCode = profileData['body']['branchCode'];
        this.proposerVo.ssoTokenUser = profileData['body']['ssoToken'];
        this.proposerVo.crmLeadId = profileData['body']['crmLeadId'];
      } else {
        this.proposerVo.isMuthootBranchUser = 'N';
      }
    }
    if (this.proposerVo.endorsementReason != null) {

      if(this.proposerVo.communicationDetails.isGstinAvail === 'No') {
        this.proposerVo.communicationDetails.isGstinAvail = 0;
      } else if (this.proposerVo.communicationDetails.isGstinAvail === 'Yes') {
        this.proposerVo.communicationDetails.isGstinAvail = 1;
      }
      if(this.proposerVo.riskLocationDetails.isGstinAvail === 'No') {
        this.proposerVo.riskLocationDetails.isGstinAvail = 0;
      } else if (this.proposerVo.riskLocationDetails.isGstinAvail === 'Yes') {
        this.proposerVo.riskLocationDetails.isGstinAvail = 1;
      }
      let policyEndorsmentDetails: Object = new Object();
      policyEndorsmentDetails = new Object();
      policyEndorsmentDetails['endorsementNumber'] = this.proposerVo.certificateNo;
      policyEndorsmentDetails['endorsmentType'] = this.proposerVo.endorsementReason;
      const endorsementTypeDesc: any = sessionStorage.getItem('Endorsement');
            if (endorsementTypeDesc) {
              const endorsTypeArrayObj: Array<Object> = JSON.parse(endorsementTypeDesc);
              if (endorsTypeArrayObj) {
                for (const endorsType of endorsTypeArrayObj) {
                  if (endorsType['endorsementTypeID'] === this.proposerVo.endorsementReason) {
                    if('Others' === endorsType['endorsementType']) {
                      this.proposerVo.endorsementTypeDesc = endorsType['endorsementType'] + ' - ' + this.proposerVo.specifyReason;
                    } else {
                      this.proposerVo.endorsementTypeDesc = endorsType['endorsementType'];
                    }
                    
                  }
                }
              }
            }
      policyEndorsmentDetails['policyId'] = this.proposerVo.policyId;
      policyEndorsmentDetails['endorsmentTypeDescription'] = this.proposerVo.endorsementTypeDesc;
      if (this.proposerVo.specifyReason !== undefined) {
        policyEndorsmentDetails['endorsmentOthers'] = this.proposerVo.specifyReason + "!"+ this.endorsmentReasonValue;
      } else {
        policyEndorsmentDetails['endorsmentOthers'] = this.endorsmentReasonValue;
      }
      // policyEndorsmentDetails['policyEndorsmentDetails'] = this.proposerVo.policyId;
      policyEndorsmentDetails['customerEndorsementMailDetails'] = new Object();
      policyEndorsmentDetails['customerEndorsementMailDetails']['mailId'] = this.proposerVo.communicationDetails.emailId;
      policyEndorsmentDetails['customerEndorsementPhoneDetails'] = new Object();
      policyEndorsmentDetails['customerEndorsementPhoneDetails']['mobile'] = this.proposerVo.communicationDetails.mobile;
      policyEndorsmentDetails['customerEndorsementPhoneDetails']['telephone'] = this.proposerVo.communicationDetails.telephone;
      policyEndorsmentDetails['customerEndorsementRiskAddressDetails'] = new Object();
      policyEndorsmentDetails['customerEndorsementAddress'] = new Object();
      policyEndorsmentDetails['customerEndorsementAddress']['isRiskAddress'] = this.isRiskAddress;
      policyEndorsmentDetails['customerEndorsementAddress']['address1'] = this.proposerVo.communicationDetails.address1;
      policyEndorsmentDetails['customerEndorsementAddress']['address2'] = this.proposerVo.communicationDetails.address2;
      policyEndorsmentDetails['customerEndorsementAddress']['address3'] = this.proposerVo.communicationDetails.address3;
      policyEndorsmentDetails['customerEndorsementAddress']['pincode'] = this.proposerVo.communicationDetails.pincodeId;
      policyEndorsmentDetails['customerEndorsementDetails'] = new Object();
      policyEndorsmentDetails['customerEndorsementDetails']['firstName'] = this.proposerVo.customerDetails.firstName;
      policyEndorsmentDetails['customerEndorsementDetails']['lastName'] = this.proposerVo.customerDetails.lastName;
      policyEndorsmentDetails['customerEndorsementDetails']['title'] = this.proposerVo.customerDetails.title;
      policyEndorsmentDetails['applianceDetails'] = new Object;
      policyEndorsmentDetails['applianceDetails'] = this.proposerVo.applianceDetails;
      if (this.shortProdName !== 'Health') {
        policyEndorsmentDetails['customerEndorsementRiskAddressDetails']['address1'] = this.proposerVo.riskLocationDetails.address1;
        policyEndorsmentDetails['customerEndorsementRiskAddressDetails']['address2'] = this.proposerVo.riskLocationDetails.address2;
        policyEndorsmentDetails['customerEndorsementRiskAddressDetails']['address3'] = this.proposerVo.riskLocationDetails.address3;
        policyEndorsmentDetails['customerEndorsementRiskAddressDetails']['pincode'] = this.proposerVo.riskLocationDetails.pincodeId;
      }
      let policyDetailsForSave: Object = new Object();
      //   const policyData = JSON.stringify(policyEndorsmentDetails);
      let policyDetails: Object = new Object();
      policyDetails = this.proposerVo;
      policyDetails['coverageSectionType1'] = this.coverageSectionType1;
      policyDetails['coverageSectionType2'] = this.coverageSectionType2;
      policyDetailsForSave = new Object();
      policyDetailsForSave['policyDetails'] = policyDetails;
      policyDetailsForSave['policyEndorsmentDetails'] = policyEndorsmentDetails;
      const policyData = JSON.stringify(policyDetailsForSave);
      this.proposalServices.saveEndorsementData(policyData).then(data => {
      if(data['errorMessage']!=null &&  data['errorMessage']!=undefined)
      {
      	this.openActionFailDialog(data['errorMessage']+".....Please Try Again.");
        this.loadingmessage = '';
        return false;
      }
        this.loadingmessage = '';
        this.cancelProposalFlag = true;
        this.disableRenewalButton = true;
        this.createProposalFlag = false;
        this.hasErrorMessage = false;
        this.hasErrorMessageTwo = true;
        this.message = data.endorsementNumber;
        this.proposerVo.certificateNo = data.endorsementNumber;
      }).catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
        //   this.openActionFailDialog('Data could not be saved');
      });
    } else {
      if(this.isExistingProposal && this.actionType === 'RENEWAL') {
        this.proposerVo.policyStatusId=1;
        delete this.proposerVo.actionType;
        delete this.proposerVo.covernoteNo;
        delete this.proposerVo.certificateNo;
        this.proposerVo.previousCertificateNo = this.existingCertificateNo;
      }
      const policyDetails: Object = new Object();
      policyDetails['policyDetails'] = this.proposerVo;
      policyDetails['policyDetails']['coverageSectionType1'] = this.coverageSectionType1;
      policyDetails['policyDetails']['coverageSectionType2'] = this.coverageSectionType2;
      const policyData = JSON.stringify(policyDetails);
      this.proposalServices
        .savePropsal(policyData)
        .then(data => {
      if(data['errorMessage']!=null &&  data['errorMessage']!=undefined)
      {
      	this.openActionFailDialog(data['errorMessage']+".....Please Try Again.");
        this.loadingmessage = '';
        return false;
      }
          this.MeenachilPolicyDetails = data;
          this.loadingmessage = '';
          this.isSelect();
          this.LoadDeclration();
          this.createProposalFlag = false;
          this.disableRenewalButton = true;
          this.craetePorposalDisable = false;
          this.hasErrorMessageTwo = true;
          this.proposerVo.policyTypeId = data.policyId;
          this.choosenPaymentType = data.paymentMode;
          this.proposerVo.covernoteNo = data.covernoteNo;
          this.proposerVo.otp = '';
          this.proposerVo.policyId = data.policyId;
          this.proposerVo.paymentDetails.otpVerifiedDate = data.paymentDetails.otpVerifiedDate;
          if(this.isSahajAffinity || this.isImuthootAffinity){
            this.isOptScreenDisabled = false;
          } else {
            this.isOptScreenDisabled = true;
          }
          if(this.isImuthootAffinity){
            this.muthootPaymentURL = data.paymentUrl;
          }
          // this.message = data.covernoteNo;
          const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
          if (this.choosenPaymentType === 'OfflinePaymentHealth' || this.choosenPaymentType === 'OfflinePaymentHome' || this.choosenPaymentType === 'OfflinePaymentShop' || userdetails['muthootBranchUser']) {
            this.choosenPaymentType = 'offlinePayment';
            this.message = data.certificateNo;
            this.proposerVo.certificateNo = data.certificateNo;
            
            // if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
            this.hasErrorMessage = false;
            this.hasSuccessPayment = true;
            //   }
          } else {
            this.message = data.covernoteNo;
            this.hasErrorMessage = false;
            this.hasSuccessPayment = true;
          }
          if(userdetails['muthootBranchUser']) {
            if(data.muthootResponse !== undefined && data.muthootResponse.toLowerCase() !== 'success') {
              this.isProposalSuccess = false;
              this.message = data.muthootResponse;
            }
          }
          this.cancelProposalFlag = true;
          const _this = this;
        })
        .catch(error => {
          //  this.openActionFailDialog('Data could not be saved');
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });

    }
  }

  public OfflineWithOtpPaymentFlow() {
      const policyDetails: Object = new Object();
      this.MeenachilPolicyDetails.paymentDetails.status = 'OTP Verified';      
      policyDetails['policyDetails'] = this.MeenachilPolicyDetails;      
      //policyDetails['policyDetails']['coverageSectionType1'] = this.coverageSectionType1;
      // policyDetails['policyDetails']['coverageSectionType2'] = this.coverageSectionType2;
      const policyData = JSON.stringify(policyDetails);
      this.proposalServices
        .savePropsal(policyData)
        .then(data => {
      if(data['errorMessage']!=null &&  data['errorMessage']!=undefined)
      {
      	this.openActionFailDialog(data['errorMessage']+".....Please Try Again.");
        this.loadingmessage = '';
        return false;
      }
          this.loadingmessage = '';          
          this.isSelect();           
          this.LoadDeclration();
          this.createProposalFlag = false;           
          this.disableRenewalButton = true;           
          this.craetePorposalDisable = false;
          this.hasErrorMessageTwo = true;             
          this.choosenPaymentType = 'offlinePayment';
          this.message = data.certificateNo;             
          this.proposerVo.certificateNo = data.certificateNo;            
          this.hasErrorMessage = false;             
          this.hasSuccessPayment = true;
          this.cancelProposalFlag = true;           
          const _this = this;
        })
        .catch(error => {
          //  this.openActionFailDialog('Data could not be saved');
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
    }

  // the below methods are added only for muthoot branch proposal
  public muthootBranchUpdates() {
    let tokenDetails: Object = new Object();
    tokenDetails['saveJson'] = new Object();
    tokenDetails['saveJson']['token'] = 'WCTvei+IjFtkj/EU9txkUMEt9AYKgDpNaZVY5zbFr7HqqZY7U2mwdZyTnX5QOiNs'; //this.proposerVo.ssoTokenUser;
    tokenDetails['saveJson']['branch_CODE'] ='MUT0257'; //this.proposerVo.branchCode;
    tokenDetails['saveJson']['cust_NAME'] = this.proposerVo.customerDetails.firstName + ' '+ this.proposerVo.customerDetails.lastName;
    tokenDetails['saveJson']['cust_ADDRESS1'] = this.proposerVo.communicationDetails.address1;
    tokenDetails['saveJson']['cust_MOBILE'] = this.proposerVo.communicationDetails.mobile;
    tokenDetails['saveJson']['cust_EMAIL'] = this.proposerVo.communicationDetails.emailId;
    tokenDetails['saveJson']['control_NO'] = this.proposerVo.certificateNo;
    tokenDetails['saveJson']['transaction_AMOUNT'] = this.proposerVo.paymentDetails.amount;
    const reqData = JSON.stringify(tokenDetails);
    this.proposalServices.cashVoucherAPI(reqData).then(data =>{
      const voucherApiRes = data['SaveResult']['Response'];
      for (const res of voucherApiRes) {
        if (res['ERROR_MSG'].toLowerCase() !== 'success') {
          let transDetails: Object = new Object();
          transDetails['retrievalJson'] = new Object();
          transDetails['retrievalJson']['methodName'] = 'GetAPITransactionStatus';
          transDetails['retrievalJson']['token'] = 'WCTvei+IjFtkj/EU9txkUMEt9AYKgDpNaZVY5zbFr7HqqZY7U2mwdZyTnX5QOiNs'; //this.proposerVo.ssoTokenUser;
          transDetails['retrievalJson']['client_CODE'] = 'LIB';
          transDetails['retrievalJson']['extdb_TYPE'] = '18';
          transDetails['retrievalJson']['control_NO'] = this.proposerVo.certificateNo;
          transDetails['retrievalJson']['trancaction_DATE'] = moment(this.proposerVo.policyIssueDate, 'DD/MM/YYYY HH:mm:ss').local().format('MM/DD/YYYY');
          const transReqData = JSON.stringify(transDetails);
          this.proposalServices.updateTransactionDetails(transReqData).then(data2 =>{
            const transApiRes = data2['RetrieveResult']['Response'];
             for (const res of transApiRes) {
              if (res['ERROR_MSG'].toLowerCase() !== 'success') {
                this.proposalServices.updateMutBranchPolicyDetails(this.proposerVo.policyId).then(data3 =>{

                }).catch(error =>{
        
                });
              }
            }
        }).catch(error => {

        });
        }
      }
    }).catch(errror =>{

    });
  }
  public adultProposerSet() {
    for (let i = 0; i < this.adultList.length; i++) {
      this.adultList[i].nomineeType = 1;
      this.adultList[i].isActive = 1;
      this.adultList[i].dob = this.datePipe.transform(
        this.adultList[i].dob,
        'dd/MM/yyyy'
      );
      if ((this.adultList[i].proposerRelation === BENIFICIARY_SELF)) {
        this.adultList[i].proposerRelation = 1;
      } if ((this.adultList[i].proposerRelation === BENIFICIARY_SPOUSE)) {
        this.adultList[i].proposerRelation = 2;
      }
    }
    for (let i = 0; i < this.childList.length; i++) {
      this.childList[i].nomineeType = 2;
      this.childList[i].isActive = 1;
      this.childList[i].dob = this.datePipe.transform(
        this.childList[i].dob,
        'dd/MM/yyyy'
      );
    }
  }

  public setCustomerDetails() {
    for (let i = 0; i < this.adultList.length; i++) {
      this.customerDetail.firstName = this.adultList[0].firstName;
      this.customerDetail.lastName = this.adultList[0].lastName;
      this.customerDetail.title = this.adultList[0].title;
    }
  }

  public setAdultAndChildDesc() {
    for (let i = 0; i < this.adultList.length; i++) {
      this.adultList[i].genderDescription = this.getGenderDesc(this.adultList[i].gender);
      this.adultList[i].proposerRelationDescription = this.getRelationshipDesc(this.adultList[i].proposerRelation);
      this.adultList[i].nomineeRelationDescription = this.getRelationshipDesc(this.adultList[i].nomineeRelation);
    }
    for (let i = 0; i < this.childList.length; i++) {
      this.childList[i].genderDescription = this.getGenderDesc(this.childList[i].gender);
      this.childList[i].proposerRelationDescription = this.getRelationshipDesc(this.childList[i].proposerRelation);
      this.childList[i].nomineeRelationDescription = this.getRelationshipDesc(this.childList[i].nomineeRelation);
    }
  }

  public adultDateValidation(strDate, rowId) {
    for (let i = 0; i < this.adultList.length; i++) {
      this.adultList[rowId].dob = strDate;
      this.calculateChildAge();
    }
    const date = new Date(strDate);
  }
  public childDateValidation(strDate, rowId) {
    for (let i = 0; i < this.childList.length; i++) {
      this.childList[rowId].dob = strDate;
      return this.calculateChildAge();

    }
  }

  private outSidevalidate() {
    // this.isDateEligibleForEndorsement = true;
    this.craetePorposalDisable = true;
    let textResult: any = false;
    let selectResult: any = false;
    let dateResult: any = false;
    let hasTextField: Boolean = false;
    let hasSelectField: Boolean = false;
    let hasDateField: Boolean = false;
    const hasEmployeeField = this.hasEmployeeField();
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        break;
      }
    }
    for (const selectField of this.selectFields['_results']) {
      hasSelectField = true;
      const input: TextBoxComponent = selectField;
      selectResult = input.onBlurMethod(false);
      if (!selectResult) {
        break;
      }
    }
    for (const dateField of this.dateFields['_results']) {
      hasDateField = true;
      const input: TextBoxComponent = dateField;
      dateResult = input.onBlurMethod(false);
      this.dateField = dateResult;
      if (!dateResult) {
        break;
      }
    }
    const result: any =
      (textResult || !hasTextField) &&
      (selectResult || !hasSelectField) &&
      (dateResult || !hasDateField) && (!this.isAryaError) &&
      (this.noDateField() || this.calculateChildAge()) &&
      (this.noQuestions() || this.questionsValidation());
    if (result) {
      this.enableSubmit = true;
      if (this.maxVisitedLevel === 1) {
      } else if (this.maxVisitedLevel === 2) {
        this.policyDetailsFlag = true;
        this.proposedInsuredFlag = true;
        if(!this.mobileNumberFlag) {
          this.enableSubmit = false;
        }
        if(!this.DOBFlag) {
          this.enableSubmit = false;
        }
        if(!this.FullNameFlag) {
          this.enableSubmit = false;
        }
        if(!this.amlFlag) {
          this.enableSubmit = false;
        }
      } else if (this.maxVisitedLevel === 3) {
        this.policyDetailsFlag = true;
        this.proposedInsuredFlag = true;
        this.communicationDetailsFlag = true;
        if (this.selectedLevel === 3) {
          if (this.shortProdName !== 'Health' &&(this.premiumDetail.grossPremium === undefined || this.premiumDetail.grossPremium.toString() === '0.00' || this.premiumDetail.grossPremium.toString() === '0')) {
              this.enableSubmit =false;
          }
          if (this.shortProdName !== 'Health' && !this.offlinePaymentType) {
            this.paymentValidation();
          }
        }
      } else if (this.maxVisitedLevel === 4) {
        this.policyDetailsFlag = true;
        this.proposedInsuredFlag = true;
        this.premiumAndPaymentFlag = true;
        this.communicationDetailsFlag = true;
        if ((this.shortProdName === 'Home' || this.shortProdName === 'Shop') && !this.hasSuccessPayment) {
          // endorsementChanges 
          if(this.actionType === 'ENDORSEMENT') {
            if(this.endorsementValidation()) {
              this.createProposalFlag = false;
            } else {
              this.createProposalFlag = true;
            }
          } else {
            this.createProposalFlag = true;
          }
        }
        if (this.shortProdName === 'Health' && this.actionType === 'ENDORSEMENT' && !this.hasSuccessPayment) {
         
          this.createProposalFlag = true;
        }
      }
      this.btnDisabled();
      if (this.selectedLevel === this.failedValidationPage) {
        this.failedValidationPage = 100;
      }
      if(this.isImuthootAffinity && this.shortProdName === 'Shop') {
        this.validatedPages[0] = true;
        this.validatedPages[this.selectedLevel] = true;
      } else {
        this.validatedPages[this.selectedLevel - 1] = true;
      }
      return true;
    } else {
    
      this.createProposalFlag = false;
      this.enableSubmit = false;
      if (this.failedValidationPage < this.selectedLevel) {
        this.failedValidationPage = this.selectedLevel;
      }
      if (this.selectedLevel === 1) {
        this.proposedInsuredFlag = false;
        this.premiumAndPaymentFlag = false;
        this.communicationDetailsFlag = false;
      } else if (this.selectedLevel === 2) {
        this.premiumAndPaymentFlag = false;
        this.communicationDetailsFlag = false;
      } else if (this.selectedLevel === 3) {
        this.premiumAndPaymentFlag = false;
      }
      this.validatedPages[this.selectedLevel - 1] = false;
      return false;
    }

  }

  public isSelectAllCheck(data:any) {
  }

  //otp validation
  private validateOTP(data: any) {
    this.otpValidateStatus = '';
    this.otpFlag  = false;
    const pattern = '^[0-9]*$';
    this.otpStatus = '';
    this.otpValidateStatus = '';
    this.craetePorposalDisable = true;
    let textResult: any = false;
    let hasTextField: Boolean = false;
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        break;
      }
    }
    const result: any =
      (textResult || !hasTextField);
    if (result) {
      this.allChecked = this.paymentDeclArray.every(data => data['checked'] === true)
      if (this.allChecked) {
        this.proposerVo.otp = this.otpInput;
            let otpDetails: Object = new Object();
            otpDetails = new Object();
            otpDetails['policyId'] = this.proposerVo.policyId;
            otpDetails['otpEnteredbyUser'] = this.proposerVo.otp;
            const otpdata = JSON.stringify(otpDetails);
            this.inboxService.validateOTP(otpdata)
              .then(data => {
                if('OTP_NOT_VALIDATED' === data) {
                  this.otpValidateStatus = 'OTP validation failed. Please try again.';
                } else if ('OTP_VALIDATED' === data) {
                  this.otpValidationSuccess = true;
                  this.otpValidateStatus = 'OTP validated successfully!.';
                }
      
              }).catch(error => {
      
              });
      }
    }
  }

  // Setting existinig value for cancel popup.
  public existingValueSet() {
    this.proposedInfoFlag = false;
    this.exAdultList = JSON.parse(JSON.stringify(this.adultList));
    this.exChildList = JSON.parse(JSON.stringify(this.childList));
    for (const i of this.exAdultList) {
      for (const key in i) {
        if (
          i[key] !== BENIFICIARY_SELF &&
          i[key] !== '' &&
          i[key] !== undefined
        ) {
          this.proposedInfoFlag = true;
        }
      }
    }
    for (const j of this.exChildList) {
      for (const Childkey in j) {
        if (j[Childkey] !== undefined && j[Childkey] !== '') {
          this.proposedInfoFlag = true;
        }
      }
    }
  }

  // method for continue button
  public validate() {
    if (this.selectedLevel === 1) {
      this.enableSubmit = false;
      this.policyDetailsFlag = true;
      this.hasErrorMessage = true;
      this.selectedLevel = this.selectedLevel + 1;
      this.existingPolicy = this.proposerVo.policyTypeId;
      this.existingPlan = this.proposerVo.planId;
      this.exSumInsured = this.proposerVo.sumInsured;
      if (this.shortProdName === 'Health' && !this.actionType) {
        this.dataCopy['policyStartDate'] = this.proposerVo.policyStarDate;
        this.dataCopy['policyTerm'] = this.proposerVo.policyTerm;
        this.dataCopy['policyEndDate'] = this.proposerVo.policyEndDate;
        this.dataCopy['masterPolicyNo'] = this.proposerVo.masterPolicyNo;
        this.dataCopy['policyTypeId'] = this.proposerVo.policyTypeId;
        this.dataCopy['planId'] = this.proposerVo.planId;
        this.dataCopy['sumInsured'] = this.proposerVo.sumInsured;
      } else if (this.shortProdName === 'Health' && (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL')) {
        this.dataCopy['policyStartDate'] = this.proposerVo.policyStarDate;
        this.dataCopy['policyTerm'] = this.proposerVo.policyTerm;
        this.dataCopy['policyEndDate'] = this.proposerVo.policyEndDate;
        this.dataCopy['masterPolicyNo'] = this.proposerVo.masterPolicyNo;
        this.dataCopy['policyTypeId'] = this.proposerVo.policyTypeId;
        this.dataCopy['planId'] = this.proposerVo.planId;
        this.dataCopy['sumInsured'] = this.proposerVo.sumInsured;
      }
      if (this.retrieveAdultList !== undefined && this.retrieveAdultList.length !== 0) {
        this.adultCount = this.retrieveAdultList.length;
        this.adultList = this.retrieveAdultList;
      }
      if (this.retrieveChildList !== undefined && this.retrieveChildList.length !== 0) {
        this.childCount = this.retrieveChildList.length;
        this.childList = this.retrieveChildList;
      }
      // if (this.adultList.length === 0 && this.childList.length === 0) {
      //   this.calculateAD(this.proposerVo.planId);
      // }

      if (this.maxVisitedLevel < 2) {
        this.maxVisitedLevel = 2;
      } else {
        setTimeout(function () { EmitterService.get('SHOW_VALIDATION_MESSAGE').emit(true); }, 100);
      }
      this.proposedInsuredFlag = true;
      
      return true;
    }
    if (this.selectedLevel === 2) {

      if (this.shortProdName === 'Health' && !this.actionType) {
        this.dataCopy['adultList'] = JSON.parse(JSON.stringify(this.adultList));
        this.dataCopy['childList'] = JSON.parse(JSON.stringify(this.childList));
        this.dataCopy['childCount'] = this.childCount;
        this.dataCopy['adultCount'] = this.adultCount;
      }

      this.enableSubmit = false;
      this.proposedInsuredFlag = true;
      this.hasErrorMessage = true;
      if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
        // this.proposerVo.planId = '100';
         this.loadPremiumDetails();
        this.getCovergeAndSections(this.proposerVo.planId);
      }
      this.selectedLevel = this.selectedLevel + 1;
      if (this.maxVisitedLevel < 3) {
        this.maxVisitedLevel = 3;
      } else {
        setTimeout(function () { EmitterService.get('SHOW_VALIDATION_MESSAGE').emit(true); }, 100);
      }
      this.communicationDetailsFlag = true;
      return true;
    }
    if (this.selectedLevel === 3 && this.shortProdName === 'Health') {
      this.enableSubmit = false;
      this.hasErrorMessage = true;
      this.selectedLevel = this.selectedLevel + 1;
      this.communicationDetailsFlag = true;
      this.loadPremiumDetails();
      if (this.actionType !== 'ENDORSEMENT') {
        this.paymentValidation();
      }
      if (this.maxVisitedLevel < 4) {
        this.maxVisitedLevel = 4;
      } else {
        setTimeout(function () { EmitterService.get('SHOW_VALIDATION_MESSAGE').emit(true); }, 100);
      }
      this.premiumAndPaymentFlag = true;
      return true;
    } if (this.selectedLevel === 3 && this.shortProdName !== 'Health') {
      this.enableSubmit = false;
      this.hasErrorMessage = true;
      this.selectedLevel = this.selectedLevel + 1;
      this.communicationDetailsFlag = true;
      if (this.maxVisitedLevel < 4) {
        this.maxVisitedLevel = 4;
      } else {
        setTimeout(function () { EmitterService.get('SHOW_VALIDATION_MESSAGE').emit(true); }, 100);
      }
      this.premiumAndPaymentFlag = true;
      return true;
    }
    if (this.selectedLevel === 4 && this.shortProdName === 'Health' && this.paymentValidation()) {
      this.hasErrorMessage = true;
      this.enableSubmit = true;
      return true;
    } if (this.selectedLevel === 4 && this.shortProdName === 'Home') {
      this.hasErrorMessage = true;
      this.enableSubmit = true;
      return true;
    } else {
      this.enableSubmit = false;
    }
    // return result;
  }

  public setProposedInsuredDefaults(index: any, isAdult: Boolean, fieldName: any) {
    this.cancelFlag = false;
    this.planChangeFlag = true;
    this.exPlanTypeArray = sessionStorage.getItem('PlanType');
    this.exPolicyTypeArray = sessionStorage.getItem('PolicyType');
    this.exAdultList = this.adultList;
    this.exChildList = this.childList;
    if (isAdult) {
      const genderDesc = this.getGenderDesc(this.adultList[index].gender);
      this.adultList[index].genderDescription = genderDesc;
      if (index === 0 && genderDesc === GENDER_MALE) {
        if (this.adultList[index + 1]) {
          this.adultList[index + 1].gender = this.getGenderID(GENDER_FEMALE);
          this.adultList[index + 1].genderDescription = GENDER_MALE;
        }
      } else if (index === 0 && genderDesc === GENDER_FEMALE) {
        if (this.adultList[index + 1]) {
          this.adultList[index + 1].gender = this.getGenderID(GENDER_MALE);
          this.adultList[index + 1].genderDescription = GENDER_FEMALE;
        }
      } else if (index === 1 && genderDesc === GENDER_MALE) {
        this.adultList[index - 1].gender = this.getGenderID(GENDER_FEMALE);
        this.adultList[index - 1].genderDescription = GENDER_MALE;
      } else if (index === 1 && genderDesc === GENDER_FEMALE) {
        this.adultList[index - 1].gender = this.getGenderID(GENDER_MALE);
        this.adultList[index - 1].genderDescription = GENDER_FEMALE;
      }
    } else {
      if (fieldName === LABEL_GENDER) {
        this.genderDesc = this.getGenderDesc(this.childList[index].gender);
        if (this.genderDesc === GENDER_MALE) {
          this.childList[index].proposerRelation = this.getRelationshipID(RELATIONSHIP_DEPENDENT_SON);
          this.childList[index].proposerRelationDescription = RELATIONSHIP_DEPENDENT_SON;
        } else if (this.genderDesc === GENDER_FEMALE) {
          this.childList[index].proposerRelation = this.getRelationshipID(RELATIONSHIP_DEPENDENT_DAUGHTER);
          this.childList[index].proposerRelationDescription = RELATIONSHIP_DEPENDENT_DAUGHTER;
        }
      } else if (fieldName === LABEL_RELATIONSHIP) {
        if (this.getRelationshipDesc(this.childList[index].proposerRelation) === RELATIONSHIP_DEPENDENT_SON) {
          this.childList[index].gender = this.getGenderID(GENDER_MALE);
          this.childList[index].genderDescription = GENDER_MALE;
        } else if (this.getRelationshipDesc(this.childList[index].proposerRelation) === RELATIONSHIP_DEPENDENT_DAUGHTER) {
          this.childList[index].gender = this.getGenderID(GENDER_FEMALE);
          this.childList[index].genderDescription = GENDER_FEMALE;

        }
      }
    }
  }

  public getAdultRelationShipList() {
    this.adultRelationShipArray = [];
    this.selectService.fetchLookUpData('RelationType', null).then(relationList => {
      sessionStorage.setItem('RelationType', JSON.stringify(relationList));

      const adultRelationShipType: any = sessionStorage.getItem('RelationType');
      if (adultRelationShipType) {
        const adultRelation: Array<Object> = JSON.parse(adultRelationShipType);
        for (const data of adultRelation) {
          if (data[RELATION_SHIP_DESC] !== BENIFICIARY_SELF
            && data[RELATION_SHIP_DESC] !== RELATIONSHIP_DEPENDENT_DAUGHTER
            && data[RELATION_SHIP_DESC] !== RELATIONSHIP_DEPENDENT_SON) {
            const obj: Object = new Object();
            obj[CODE] = data[RELATION_SHIP_ID];
            obj[DESCRIPTION] = data[RELATION_SHIP_DESC];
            this.adultRelationShipArray.push(obj);
          }
        }
      }
    });
  }
  // Generating the gender Description to set the relationship proposar for child.
  public getGenderDesc(gender: any) {
    const genderDesc = sessionStorage.getItem('Gender');
    if (genderDesc) {
      const genderTypeArryObj: Array<Object> = JSON.parse(genderDesc);
      if (genderTypeArryObj) {
        for (const genderType of genderTypeArryObj) {
          if (genderType[GENDER_ID] === gender) {
            this.genderDesc = genderType[GENDER_DESC];
            return this.genderDesc;
          }
        }
      }
    }
  }

  public getProductIdAndLobName(prodName: any) {
    const productDetails = sessionStorage.getItem('prodcutDetails');
    if (productDetails) {
      const prodcutArrayObj: Array<Object> = JSON.parse(productDetails);
      if (prodcutArrayObj) {
        for (const prodType of prodcutArrayObj) {
          if (prodType['productName'] === prodName) {
            const productID = prodType['productId'];
            this.producDesc = prodType['productInfo'];
            sessionStorage.setItem(PRODUCT_CODE, productID);
            return this.producDesc;
          }

        }

      }

    }

  }

  public getGenderID(gender: any) {
    const genderDesc = sessionStorage.getItem('Gender');
    if (genderDesc) {
      const genderTypeArryObj: Array<Object> = JSON.parse(genderDesc);
      if (genderTypeArryObj) {
        for (const genderType of genderTypeArryObj) {
          if (genderType[GENDER_DESC] === gender) {
            const genderID = genderType[GENDER_ID];
            return genderID;
          }
        }
      }
    }
  }

  // Genderating the relationship desc for selected gender
  public getRelationshipID(retaionDesc) {
    const relationShipDesc = sessionStorage.getItem('RelationType');
    if (relationShipDesc) {
      const retaionShipTypeArryObj: Array<Object> = JSON.parse(relationShipDesc);
      for (const relationShip of retaionShipTypeArryObj) {
        if (relationShip[RELATION_SHIP_DESC] === retaionDesc) {
          const relationId = relationShip[RELATION_SHIP_ID];
          return relationId;
        }
      }
    }
  }
  // Genderating the relationship desc for selected gender
  public getRelationshipDesc(retaionID) {
    const relationShipDesc = sessionStorage.getItem('RelationType');
    if (relationShipDesc) {
      const retaionShipTypeArryObj: Array<Object> = JSON.parse(relationShipDesc);
      for (const relationShip of retaionShipTypeArryObj) {
        if (relationShip[RELATION_SHIP_ID] === retaionID) {
          const relationDesc = relationShip[RELATION_SHIP_DESC];
          return relationDesc;
        }
      }
    }
  }
  public objectContains(dataArray, value) {
    for (const dt of dataArray) {
      if (dt['Code'] === value) {
        return true;
      }
    }

    return false;
  }
  public fetchPostalDetails(postalCode: any) {
    this.postalServices
      .fetchPostalDetailsByZip(postalCode)
      .then(data => {
        this.stateArray = [];
        this.districtArray = [];
        this.cityArray = [];
        for (const dt of data) {
          if (!this.objectContains(this.stateArray, dt['State'])) {
            const state: any = new Object();
            state[CODE] = dt['State'];
            state[DESCRIPTION] = dt['State'];
            this.stateArray.push(state);
          }
          if (!this.objectContains(this.districtArray, dt['District'])) {
            const district: any = new Object();
            district[CODE] = dt['District'];
            district[DESCRIPTION] = dt['District'];
            this.districtArray.push(district);
          }
          if (!this.objectContains(this.cityArray, dt['Region'])) {
            const city: any = new Object();
            city[CODE] = dt['Region'];
            city[DESCRIPTION] = dt['Region'];
            this.cityArray.push(city);
          }
        }
      })
      .catch(error => { });
  }
  public populatePincode(data) {
    let pincodeLength;
    if (this.proposerVo.communicationDetails.pincode !== undefined && this.proposerVo.communicationDetails.pincode !== '') {
      pincodeLength = this.proposerVo.communicationDetails.pincode.length;
      this.communicationFlag = true;
    }
    if (data.length !== 0) {
      this.pincodeErrorMessage = '';
      this.pincodeArray = [];
      for (const dt of data) {
        const pincode: any = new Object();
        pincode[CODE] = dt['pincode'];
        pincode[DESCRIPTION] = dt['pincodeInfo'];
        pincode['pincodeId'] = dt['pincodeId'];
        const pincodeLocality = dt['pincode_locality'];
        if (this.proposerVo.communicationDetails.pincode === pincode[DESCRIPTION]) {
          this.getDetailOfPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
          // commenting now, need to remobved once manapuuram is ready
          // if (this.proposerVo.affinityName.toLowerCase() === 'manappuram' && this.shortProdName === 'Health') {
          //     this.proposalServices.validateDeclinedPincode(pincode[CODE]).then(data => {
          //       const response  = data;
          //       if(response) {
          //         this.pincodeErrorMessage = 'We regret to inform you that this pin code is not serviceable at our end, please select another pin code to proceed with Proposal creation';
          //         this.proposerVo.communicationDetails.stateId = null;
          //         this.proposerVo.communicationDetails.districtId = null;
          //         this.proposerVo.communicationDetails.cityId = null;
          //         this.proposerVo.communicationDetails.cityName = '';
          //         this.proposerVo.communicationDetails.stateName = '';
          //         this.proposerVo.communicationDetails.districtName = '';
          //         this.proposerVo.communicationDetails.pincodeId = '';
          //       } else {
          //         this.getDetailOfPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
          //       }
          //     }).catch(error => {
  
          //     })
          // } else {
          //   this.getDetailOfPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
          // }
        } else {
          this.proposerVo.communicationDetails.cityName = '';
          this.proposerVo.communicationDetails.stateName = '';
          this.proposerVo.communicationDetails.districtName = '';
        }
        this.pincodeArray.push(pincode);
      }
    } else {
      this.pincodeErrorMessage = PINCODE_ERROR;
      this.proposerVo.communicationDetails.stateId = null;
      this.proposerVo.communicationDetails.districtId = null;
      this.proposerVo.communicationDetails.cityId = null;
      this.proposerVo.communicationDetails.cityName = '';
      this.proposerVo.communicationDetails.stateName = '';
      this.proposerVo.communicationDetails.districtName = '';
      this.proposerVo.communicationDetails.pincodeId = '';
    }
  }

  public getDetailOfPincode(communicationFlag,pincode, pincodeLocality,pincodeId) {
    this.proposalServices.getPinCodeDetails(pincode, pincodeLocality).then(data2 => {
      if (communicationFlag) {
       this.proposerVo.communicationDetails.stateId = data2.stateId;
        this.proposerVo.communicationDetails.districtId = data2.districtId;
        this.proposerVo.communicationDetails.cityId = data2.cityId;
        this.proposerVo.communicationDetails.cityName = data2.cityName;
        this.proposerVo.communicationDetails.stateName = data2.stateName;
        this.proposerVo.communicationDetails.districtName = data2.districtName;
        this.proposerVo.communicationDetails.pincodeId = pincodeId;
        this.pincodeArray = [];
      }
    })
      .catch(error => { });
  }

  public getDetailOfRiskPincode(communicationFlag,pincode, pincodeLocality,pincodeId) {
    this.proposalServices.getPinCodeDetails(pincode, pincodeLocality).then(data2 => {
      if (!communicationFlag) {
       this.proposerVo.riskLocationDetails.stateId = data2.stateId;
        this.proposerVo.riskLocationDetails.districtId = data2.districtId;
        this.proposerVo.riskLocationDetails.cityId = data2.cityId;
        this.proposerVo.riskLocationDetails.cityName = data2.cityName;
        this.proposerVo.riskLocationDetails.stateName = data2.stateName;
        this.proposerVo.riskLocationDetails.districtName = data2.districtName;
        this.proposerVo.riskLocationDetails.pincodeId = pincodeId;
        this.pincodeRiskArray = [];
      }
    })
      .catch(error => { });
  }

  public populateRiskPincode(data) {
    // let pincodeLength;
    // if (this.proposerVo.riskLocationDetails.pincode !== undefined && this.proposerVo.riskLocationDetails.pincode !== '') {
    //   this.communicationFlag = false;
    //   pincodeLength = this.proposerVo.riskLocationDetails.pincode.length;
    // }
    // if (data.length !== 0) {
    //   this.pincodeRiskArray = [];
    //   for (const dt of data) {
    //     const pincode: any = new Object();
    //     pincode[CODE] = dt['pincode'];
    //     pincode[DESCRIPTION] = dt['pincodeInfo'];
    //     pincode['pincodeId'] = dt['pincodeId'];

    //     if (this.proposerVo.riskLocationDetails.pincode === pincode[DESCRIPTION]) {
    //       this.proposalServices.getPinCodeDetails(pincode[CODE],pincode[DESCRIPTION]).then(data2 => {
    //         if (!this.communicationFlag) {
    //           this.proposerVo.riskLocationDetails.stateId = data2.stateId;
    //           this.proposerVo.riskLocationDetails.districtId = data2.districtId;
    //           this.proposerVo.riskLocationDetails.cityId = data2.cityId;
    //           this.proposerVo.riskLocationDetails.cityName = data2.cityName;
    //           this.proposerVo.riskLocationDetails.stateName = data2.stateName;
    //           this.proposerVo.riskLocationDetails.districtName = data2.districtName;
    //           this.proposerVo.riskLocationDetails.pincodeId = dt['pincodeId'];
    //           this.pincodeRiskArray = [];
    //         }
    //       })
    //         .catch(error => {
    //         });
    //     } else {
    //       this.proposerVo.riskLocationDetails.stateId = null;
    //       this.proposerVo.riskLocationDetails.districtId = null;
    //       this.proposerVo.riskLocationDetails.cityId = null;
    //       this.proposerVo.riskLocationDetails.cityName = '';
    //       this.proposerVo.riskLocationDetails.stateName = '';
    //       this.proposerVo.riskLocationDetails.districtName = '';
    //       this.proposerVo.riskLocationDetails.pincodeId = '';
    //     }
    //     this.pincodeRiskArray.push(pincode);
    //   }
    // } else {
    //   this.proposerVo.riskLocationDetails.stateId = null;
    //   this.proposerVo.riskLocationDetails.districtId = null;
    //   this.proposerVo.riskLocationDetails.cityId = null;
    //   this.proposerVo.riskLocationDetails.cityName = '';
    //   this.proposerVo.riskLocationDetails.stateName = '';
    //   this.proposerVo.riskLocationDetails.districtName = '';
    //   this.proposerVo.riskLocationDetails.pincodeId = '';
    // }
    let pincodeLength;
    if (this.proposerVo.riskLocationDetails.pincode !== undefined && this.proposerVo.riskLocationDetails.pincode !== '') {
      pincodeLength = this.proposerVo.riskLocationDetails.pincode.length;
      this.communicationFlag = false;
    }
    if (data.length !== 0) {
      this.pincodeErrorMessage = '';
      this.pincodeRiskArray = [];
      for (const dt of data) {
        const pincode: any = new Object();
        pincode[CODE] = dt['pincode'];
        pincode[DESCRIPTION] = dt['pincodeInfo'];
        pincode['pincodeId'] = dt['pincodeId'];
        const pincodeLocality = dt['pincode_locality'];
        if (this.proposerVo.riskLocationDetails.pincode === pincode[DESCRIPTION]) {
          this.getDetailOfRiskPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
          // commenting now, need to remobved once manapuuram is ready
          // if (this.proposerVo.affinityName.toLowerCase() === 'manappuram' && this.shortProdName === 'Health') {
          //     this.proposalServices.validateDeclinedPincode(pincode[CODE]).then(data => {
          //       const response  = data;
          //       if(response) {
          //         this.pincodeErrorMessage = 'We regret to inform you that this pin code is not serviceable at our end, please select another pin code to proceed with Proposal creation';
          //         this.proposerVo.riskLocationDetails.stateId = null;
          //         this.proposerVo.riskLocationDetails.districtId = null;
          //         this.proposerVo.riskLocationDetails.cityId = null;
          //         this.proposerVo.riskLocationDetails.cityName = '';
          //         this.proposerVo.riskLocationDetails.stateName = '';
          //         this.proposerVo.riskLocationDetails.districtName = '';
          //         this.proposerVo.riskLocationDetails.pincodeId = '';
          //       } else {
          //         this.getDetailOfRiskPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
          //       }
          //     }).catch(error => {
  
          //     })
          // } else {
          //   this.getDetailOfRiskPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
          // }
        } else {
          this.proposerVo.riskLocationDetails.cityName = '';
          this.proposerVo.riskLocationDetails.stateName = '';
          this.proposerVo.riskLocationDetails.districtName = '';
        }
        this.pincodeRiskArray.push(pincode);
      }
    } else {
      this.pincodeErrorMessage = PINCODE_ERROR;
      this.proposerVo.riskLocationDetails.stateId = null;
      this.proposerVo.riskLocationDetails.districtId = null;
      this.proposerVo.riskLocationDetails.cityId = null;
      this.proposerVo.riskLocationDetails.cityName = '';
      this.proposerVo.riskLocationDetails.stateName = '';
      this.proposerVo.riskLocationDetails.districtName = '';
      this.proposerVo.riskLocationDetails.pincodeId = '';
    }
  }



  // To be made dynamic
  preparePolicyTermArray() {
    const code1: Object = new Object();
    code1[CODE] = '1';
    code1[DESCRIPTION] = '1 Year';
    const code2: Object = new Object();
    code2[CODE] = '2';
    code2[DESCRIPTION] = '2 Year';
    this.policytermArray.push(code1);
    this.policytermArray.push(code2);
  }

  fectchMasterPolicy() {
    this.selectService.fetchLookUpData('MasterPolicy', undefined).then(data1 => {
      let dataArray;
      if (data1 !== undefined) {
        dataArray = data1;
        for (const data of dataArray) {
          const obj: Object = new Object();
          obj['Code'] = data['masterPolicyId'];
          obj['Description'] = data['masterPolicyId'];
          this.masterPolicyArray.push(obj);
        }
        if (this.masterPolicyArray.length === 1) {
          this.masterPolicyField.placeholder = '';
          this.masterPolicyField.value = this.masterPolicyArray[0]['Code'];
        }
        sessionStorage.setItem('MsaterPolicy', JSON.stringify(dataArray));
      }

    }).catch(error => {

    });
  }

  public fetechPlanForHomeAndShop() {
    this.selectService.fetchLookUpData('PolicyType', this.proposerVo.policyStartDate).then(data1 => {

      let dataArray;
      let policyTypeId: any;
      this.planTypeArray = [];
      if (data1.length !== 0) {
        for (let i = 0; i < data1.length; i++) {
          policyTypeId = data1[i].policyTypeId;
          dataArray = data1[i].planType;
        }
        // performance issue
        this.proposerVo.policyTypeId = policyTypeId;
        const userId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userId'];
        this.getPlanDropdown(userId, this.productId, policyTypeId);
        // for (const data of dataArray) {
        //   const obj: Object = new Object();
        //   obj['Code'] = data['planid'];
        //   obj['Description'] = data['planTypeName'];
        //   this.planTypeArray.push(obj);
        // }
        // sessionStorage.setItem('PlanType', JSON.stringify(dataArray));
      }

    }).catch(error => {

    });

  }
  public getPlanCode(planName) {
    const planType: any = sessionStorage.getItem('PlanType');
    if (planType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_ID] === planName) {
            return pnType[PLAN_TYPE_ID];
          }
        }
      }
    }
    return '';
  }

  public getPolicyType(policyType) {
    const planType: any = sessionStorage.getItem('PolicyType');
    if (planType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[POLICY_TYPE_DESC] === policyType) {
            const plans = pnType['planType'];
            sessionStorage.setItem('PlanType', JSON.stringify(plans));
            return pnType[POLICY_TYPE_ID];
          }
        }
      }
    }
    return '';
  }

  public getChildRelationShipList() {
    this.ChildRelationType = [];
    const childRelationShipType: any = sessionStorage.getItem('ChildRelationType');
    if (childRelationShipType) {
      const childRelation: Array<Object> = JSON.parse(childRelationShipType);
      for (const data of childRelation) {
        if (data[RELATION_SHIP_DESC] === RELATIONSHIP_DEPENDENT_DAUGHTER || data[RELATION_SHIP_DESC] === RELATIONSHIP_DEPENDENT_SON) {
          const obj: Object = new Object();
          obj[CODE] = data[RELATION_SHIP_ID];
          obj[DESCRIPTION] = data[RELATION_SHIP_DESC];
          this.ChildRelationType.push(obj);
        }
      }
    }
  }

  public loadPremiumDetails() {
    this.premiumValidationMessage = '';
    const planId = this.proposerVo.planId;
    const planTypeS: any = sessionStorage.getItem('PlanType');
    if (planTypeS) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planTypeS);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_ID] === planId) {
            this.proposerVo.planTypeName = pnType[PLAN_TYPE_DESC];
          }
        }
      }
    }
    const policyId = this.proposerVo.policyTypeId;
    const policyType: any = sessionStorage.getItem('PolicyType');
    if (policyType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(policyType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[POLICY_TYPE_ID] === policyId) {
            this.proposerVo.policyTypeName = pnType[POLICY_TYPE_DESC];
          }
        }
      }
    }
    const sumInsured = this.proposerVo.sumInsured;
    const sumIsuredData: any = sessionStorage.getItem('SumInsured');
    if (sumIsuredData) {
      const sumInsuredArrayObj: Array<Object> = JSON.parse(sumIsuredData);
      if (sumInsuredArrayObj) {
        for (const sumInsuredValue of sumInsuredArrayObj) {
          if (sumInsuredValue[SUMISURED] === sumInsured) {
            this.proposerVo.sumInsuredId = sumInsuredValue[SUMINSURED_ID];
          }
        }
      }
    }
    const allDOb: Array<any> = new Array<any>();
    for (let i = 0; i < this.adultList.length; i++) {
      allDOb.push(this.datePipe.transform(this.adultList[i].dob, 'dd/MM/yyyy'));
    }
    for (let i = 0; i < this.childList.length; i++) {
      allDOb.push(this.datePipe.transform(this.childList[i].dob, 'dd/MM/yyyy'));
    }
    // checking the issue state and selected state is same or not and appliyng the logic for IGST calculation.
    const seletcedState = this.proposerVo.communicationDetails.stateId;
    this.proposerVo.cessIndicator = false;
    if (this.proposerVo.issueStateId === seletcedState) {
      this.proposerVo.issueState = true;
      // if ( 'KERALA' === this.proposerVo.communicationDetails.stateName) {
        
      //   if (this.proposerVo.communicationDetails.gstinNo === '') {
          
      //     this.proposerVo.cessIndicator = true;
      //   } else {

      //     this.proposerVo.cessIndicator = false;
      //   }
      // } else {
       
      //   this.proposerVo.cessIndicator = false;
      // }
    } else {
      this.proposerVo.issueState = false;
    }
    this.proposerVo.policyTermId = this.proposerVo.policyTerm;
    this.proposerVo.dob = allDOb;
    const resourceDetails: Object = new Object();
    const resourceData = JSON.stringify(resourceDetails);
    resourceDetails['resourceDetails'] = this.proposerVo;
    this.proposalServices.getPremiumDetails(this.lobId, resourceDetails)
      .then(data => {
        if (this.shortProdName === 'Health') {
          const responseData = data;
          if (data.totalNetPremium !== undefined && data.totalGrossPremium !== undefined && ((data.totalsgst !== undefined && data.totalcgst !== undefined) || data.totaligst !== undefined)) {
            this.premiumDetail.netPremium = data.totalNetPremium;
            this.premiumDetail.sgst = data.totalsgst;
            this.premiumDetail.cgst = data.totalcgst;
            this.premiumDetail.igst = data.totaligst;
            this.premiumDetail.grossPremium = data.totalGrossPremium;
            if (data.cessTax !== undefined) {
              this.premiumDetail.grossPremium = data.totalCessGrossPremium;
              this.premiumDetail.cessTax = data.cessTax;
            } else {
              this.premiumDetail.cessTax = undefined;
            }
          } else  if (data.totalCessNetPremium !== undefined && data.totalGrossPremium !== undefined && ((data.totalCsgst !== undefined && data.totalCcgst !== undefined) || data.totaligst !== undefined)) {
            this.premiumDetail.netPremium = data.totalCessNetPremium;
            this.premiumDetail.sgst = data.totalCsgst;
            this.premiumDetail.cgst = data.totalCcgst;
            this.premiumDetail.igst = data.totaligst;
            this.premiumDetail.grossPremium = data.totalGrossPremium;
            if (data.cessTax !== undefined) {
              //this.premiumDetail.grossPremium = data.totalCessGrossPremium;
              this.premiumDetail.cessTax = data.cessTax;
            } else {
              this.premiumDetail.cessTax = undefined;
            }
          }
          
          
          
          else {
           
            // this.premiumDetail.netPremium = data.totalNetPremium;
            // this.premiumDetail.sgst = data.totalsgst;
            // this.premiumDetail.cgst = data.totalcgst;
            // this.premiumDetail.igst = data.totaligst;
            // this.premiumDetail.grossPremium = data.totalGrossPremium;
           this.premiumValidationMessage = 'No Premium for this age Range. Please Contact Administrator';
            this.createProposalFlag = false;
          }
        }
        else if (data.totalCessNetPremium !== undefined && data.totalGrossPremium !== undefined && ((data.totalCsgst !== undefined && data.totalCcgst !== undefined) || data.totaligst !== undefined)) {
          this.premiumDetail.netPremium = data.totalCessNetPremium;
          this.premiumDetail.sgst = data.totalCsgst;
          this.premiumDetail.cgst = data.totalCcgst;
          this.premiumDetail.igst = data.totaligst;
          this.premiumDetail.grossPremium = data.totalGrossPremium;
          if (data.cessTax !== undefined) {
            //this.premiumDetail.grossPremium = data.totalCessGrossPremium;
            this.premiumDetail.cessTax = data.cessTax;
          } } else
          {
          this.premiumDetail.netPremium = data.totalNetPremium;
          //totalcessnetpremium
          this.premiumDetail.sgst = data.totalsgst;
          // this.premiumDetail.sgst = data.totalcsgst;
          this.premiumDetail.cgst = data.totalcgst;
          //ccgst
          this.premiumDetail.igst = data.totaligst;
          this.premiumDetail.grossPremium = data.totalGrossPremium;
          if (data.cessTax !== undefined) {
            this.premiumDetail.cessTax = data.cessTax;
            this.premiumDetail.grossPremium = data.totalCessGrossPremium;
          } else {
            this.premiumDetail.cessTax = undefined;
          }
        }
      
        })
      .catch(error => { });
  }

  public getRiskPinCodeDetails(str) {
    if (str.length <= 6) {
      this.proposerVo.riskLocationDetails.cityName = '';
      this.proposerVo.riskLocationDetails.stateName = '';
      this.proposerVo.riskLocationDetails.districtName = '';

    } else if (str.length > 6) {
      const pincodeData = str.split('-');
      const pincode = pincodeData[0];
      let pincodeId: string;
      if (pincode.length > 2 && pincode.length === 6) {

        if (this.pincodeRiskArray.length !== 0) {
          for (let i = 0; i < this.pincodeRiskArray.length; i++) {
            if (this.pincodeRiskArray[i][DESCRIPTION] === str) {
              pincodeId = this.pincodeRiskArray[i].pincodeId;
            }
          }
        }
        this.proposalServices.getRiskPinCodeDetails(pincode).then(data => {

          this.proposerVo.riskLocationDetails.stateId = data.stateId;
          this.proposerVo.riskLocationDetails.districtId = data.districtId;
          this.proposerVo.riskLocationDetails.cityId = data.cityId;
          this.proposerVo.riskLocationDetails.cityName = data.cityName;
          this.proposerVo.riskLocationDetails.stateName = data.stateName;
          this.proposerVo.riskLocationDetails.districtName = data.districtName;
          this.proposerVo.riskLocationDetails.pincodeId = pincodeId;

        })
          .catch(error => { });
      }
    }
  }

  public getPinCodeDetails(str) {
    if (str.length <= 6) {
      this.proposerVo.communicationDetails.cityName = '';
      this.proposerVo.communicationDetails.stateName = '';
      this.proposerVo.communicationDetails.districtName = '';

    } else if (str.length > 6) {
      const pincodeData = str.split('-');
      const pincode = pincodeData[0];
      const pincodeLocality =  pincodeData[1];
      let pincodeId: string;
      if (pincode.length > 2 && pincode.length === 6) {

        if (this.pincodeArray.length !== 0) {
          for (let i = 0; i < this.pincodeArray.length; i++) {
            if (this.pincodeArray[i][DESCRIPTION] === str) {
              pincodeId = this.pincodeArray[i].pincodeId;
            }
          }
        }
        this.proposalServices.getPinCodeDetails(pincode,pincodeLocality).then(data => {
          if (this.communicationFlag) {
            this.proposerVo.communicationDetails.stateId = data.stateId;
            this.proposerVo.communicationDetails.districtId = data.districtId;
            this.proposerVo.communicationDetails.cityId = data.cityId;
            this.proposerVo.communicationDetails.cityName = data.cityName;
            this.proposerVo.communicationDetails.stateName = data.stateName;
            this.proposerVo.communicationDetails.districtName = data.districtName;
            this.proposerVo.communicationDetails.pincodeId = pincodeId;
          }
        })
          .catch(error => { });
      }
    }
  }

  public calculateChildAge() {
    this.dobErrorMessage = '';
    const childAgeCheck = [];
    if (this.childList.length !== 0) {
      for (let i = 0; i < this.adultList.length; i++) {
        const adultDate = this.adultList[i].dob;
        if (adultDate !== NaN && adultDate !== '' && adultDate !== undefined) {
          const selectedAdultDate: any = new Date(adultDate);
          const timeAdultDiff = Math.abs(Date.now() - selectedAdultDate);
          const timeAdultDiffDay = timeAdultDiff / (1000 * 3600 * 24) / 365;
          const adultAge = Math.floor(timeAdultDiff / (1000 * 3600 * 24) / 365);
          const timeAdultDiffDayString = timeAdultDiffDay.toString();
          const adultAgeArray = timeAdultDiffDayString.split('.');
          const adultAgenew = '.' + adultAgeArray[1];
          const adultDay = Math.floor(Number(adultAgenew) * 365);
          for (let j = 0; j < this.childList.length; j++) {
            const childDate = this.childList[j].dob;
            this.childList[j].dobErrorMessage = '';
            if (
              childDate !== NaN &&
              childDate !== '' &&
              childDate !== undefined
            ) {
              const selectedChildDate: any = new Date(childDate);
              const timeChildDiff = Math.abs(Date.now() - selectedChildDate);
              const timeChildDiffDay = timeChildDiff / (1000 * 3600 * 24) / 365;
              const childAge = Math.floor(
                timeChildDiff / (1000 * 3600 * 24) / 365
              );
              const timeChildiffDayString = timeChildDiffDay.toString();
              const childAgeArray = timeChildiffDayString.split('.');
              const childAgenew = '.' + childAgeArray[1];
              const childDay = Math.floor(Number(childAgenew) * 365);
              if (adultAge < childAge) {
                this.childList[j].dobErrorMessage =
                  'Child age should not be equal to or greater than Adult age';
                childAgeCheck[j] = false;
                //  return false;
              } else if (adultAge === childAge) {
                if (adultDay <= childDay) {
                  this.childList[j].dobErrorMessage =
                    'Child age should not be equal to or greater than Adult age';
                  childAgeCheck[j] = false;
                  // return false;
                } else {
                  this.childList[j].dobErrorMessage = '';
                }
              } else {
                this.childList[j].dobErrorMessage = '';
              }
            }
          }
          for (const x of childAgeCheck) {
            if (!x) {
              this.childAgeErrorMessageCheck = false;
              this.dateField = false;
              return false;
            }
          }
        }
      }
    }
    // for (const x of childAgeCheck) {
    //   if (!x) {
    //     this.childAgeErrorMessageCheck = false;
    //     this.dateField = false;
    //     return false;
    //   }
    // }
    return true;
  }
  public adultDataEndorementValidation(str) {
    this.isDateEligibleForEndorsement = true;
    if (this.actionType === 'ENDORSEMENT') {
      const childAgeCheck = [];
      new Promise((resolve, reject) => {
        //  this.calculateChildAge();
        resolve(100);
      }).then(data1 => {
        if (this.actionType === 'ENDORSEMENT') {
          for (let i = 0; i < this.adultList.length; i++) {
            const planId = this.proposerVo.planId;
            const planTypeS: any = sessionStorage.getItem('PlanType');
            if (planTypeS) {
              const planTypeArrayObj: Array<Object> = JSON.parse(planTypeS);
              if (planTypeArrayObj) {
                for (const pnType of planTypeArrayObj) {
                  if (pnType[PLAN_TYPE_ID] === planId) {
                    this.proposerVo.planTypeName = pnType[PLAN_TYPE_DESC];
                  }
                }
              }
            }
            const policyId = this.proposerVo.policyTypeId;
            const policyType: any = sessionStorage.getItem('PolicyType');
            if (policyType) {
              const planTypeArrayObj: Array<Object> = JSON.parse(policyType);
              if (planTypeArrayObj) {
                for (const pnType of planTypeArrayObj) {
                  if (pnType[POLICY_TYPE_ID] === policyId) {
                    this.proposerVo.policyTypeName = pnType[POLICY_TYPE_DESC];
                  }
                }
              }
            }
            const sumInsured = this.proposerVo.sumInsured;
            const sumIsuredData: any = sessionStorage.getItem('SumInsured');
            if (sumIsuredData) {
              const sumInsuredArrayObj: Array<Object> = JSON.parse(sumIsuredData);
              if (sumInsuredArrayObj) {
                for (const sumInsuredValue of sumInsuredArrayObj) {
                  if (sumInsuredValue[SUMISURED] === sumInsured) {
                    this.proposerVo.sumInsuredId = sumInsuredValue[SUMINSURED_ID];
                  }
                }
              }
              const allDOb: Array<any> = new Array<any>();
              for (let i = 0; i < this.adultList.length; i++) {
                allDOb.push(this.datePipe.transform(this.adultList[i].dob, 'dd/MM/yyyy'));
              }
              for (let i = 0; i < this.childList.length; i++) {
                allDOb.push(this.datePipe.transform(this.childList[i].dob, 'dd/MM/yyyy'));
              }
              // checking the issue state and selected state is same or not and appliyng the logic for IGST calculation.
              const seletcedState = this.proposerVo.communicationDetails.stateId;
              if (this.proposerVo.issueStateId === seletcedState) {
                this.proposerVo.issueState = true;
              } else {
                this.proposerVo.issueState = false;
              }
              this.proposerVo.policyTermId = this.proposerVo.policyTerm;
              this.proposerVo.dob = allDOb;
              const resourceDetails: Object = new Object();
              const resourceData = JSON.stringify(resourceDetails);
              resourceDetails['resourceDetails'] = this.proposerVo;
              this.proposalServices.getPremiumDetails(this.lobId, resourceDetails)
                .then(data => {
                  this.premiumDetail.netPremium = data.totalNetPremium;
                  this.premiumDetail.sgst = data.totalsgst;
                  this.premiumDetail.cgst = data.totalcgst;
                  this.premiumDetail.igst = data.totaligst;
                  this.premiumDetail.grossPremium = data.totalGrossPremium;
                  if (this.existingPremium !== this.premiumDetail.grossPremium) {
                    const adult1 = this.adultAgeCalculationForEndorsment(this.adultList[0].dob, 'age');
                    if (this.adultCount === 2) {
                      let adult2 = this.adultList[1].dob;
                      if (adult2 !== undefined) {
                        adult2 = this.adultAgeCalculationForEndorsment(this.adultList[1].dob, 'age');
                        if (adult2 < adult1) {
                          this.adultList[0].dobErrorMessage = PREMIUM_ERROR_MSG;
                          this.adultList[1].dobErrorMessage = '';
                        } else if (adult2 > adult1) {
                          this.adultList[0].dobErrorMessage = '';
                          this.adultList[1].dobErrorMessage = PREMIUM_ERROR_MSG;
                        } else if (adult2 === adult1) {
                          const adult1day = this.adultAgeCalculationForEndorsment(this.adultList[0].dob, 'day');
                          const adult2day = this.adultAgeCalculationForEndorsment(this.adultList[1].dob, 'day');
                          if (adult1day > adult2day) {
                            this.adultList[0].dobErrorMessage = PREMIUM_ERROR_MSG;
                            this.adultList[1].dobErrorMessage = '';
                          } else if (adult1day < adult2day) {
                            this.adultList[1].dobErrorMessage = PREMIUM_ERROR_MSG;
                            this.adultList[0].dobErrorMessage = '';
                          } else {
                            this.adultList[0].dobErrorMessage = PREMIUM_ERROR_MSG;
                            this.adultList[1].dobErrorMessage = PREMIUM_ERROR_MSG;
                          }
                        }
                      }
                    } else {
                      this.adultList[0].dobErrorMessage = PREMIUM_ERROR_MSG;
                    }
                    if (this.childCount !== undefined) {
                      this.calculateChildAge();
                    }
                    if (!this.endorsmentPremiumFlag && this.dateField) {
                      this.isDateEligibleForEndorsement = false;
                      childAgeCheck[str] = false;
                      this.dateField = false;
                      return false;
                    }
                  } else {
                    this.adultList[0].dobErrorMessage = '';
                    if (this.adultCount === 2) {
                      this.adultList[1].dobErrorMessage = '';
                    }
                    this.isDateEligibleForEndorsement = true;
                    if (this.childCount !== undefined) {
                      this.calculateChildAge();
                    }
                  }
                })
                .catch(error => { });
            }
          }
        }
      }).then(data2 => {
        // let adultDateStatus = true;
        // for (const x of childAgeCheck) {
        //   if (!x) {
        //     adultDateStatus = false;
        //     return false;
        //   }
        // }
        // if (adultDateStatus) {
        //   for (let i = 0; i < this.childList.length; i++) {
        //     // if () {
        //      const planId = this.proposerVo.planId;
        //      const planTypeS: any = sessionStorage.getItem('PlanType');
        //      if (planTypeS) {
        //        const planTypeArrayObj: Array<Object> = JSON.parse(planTypeS);
        //        if (planTypeArrayObj) {
        //          for (const pnType of planTypeArrayObj) {
        //            if (pnType[PLAN_TYPE_ID] === planId) {
        //              this.proposerVo.planTypeName = pnType[PLAN_TYPE_DESC];
        //            }
        //          }
        //        }
        //      }
        //      const policyId = this.proposerVo.policyTypeId;
        //      const policyType: any = sessionStorage.getItem('PolicyType');
        //      if (policyType) {
        //        const planTypeArrayObj: Array<Object> = JSON.parse(policyType);
        //        if (planTypeArrayObj) {
        //          for (const pnType of planTypeArrayObj) {
        //            if (pnType[POLICY_TYPE_ID] === policyId) {
        //              this.proposerVo.policyTypeName = pnType[POLICY_TYPE_DESC];
        //            }
        //          }
        //        }
        //      }
        //      const sumInsured = this.proposerVo.sumInsured;
        //      const sumIsuredData: any = sessionStorage.getItem('SumInsured');
        //      if (sumIsuredData) {
        //        const sumInsuredArrayObj: Array<Object> = JSON.parse(sumIsuredData);
        //        if (sumInsuredArrayObj) {
        //          for (const sumInsuredValue of sumInsuredArrayObj) {
        //            if (sumInsuredValue[SUMISURED] === sumInsured) {
        //              this.proposerVo.sumInsuredId = sumInsuredValue[SUMINSURED_ID];
        //            }
        //          }
        //        }
        //        const allDOb: Array<any> = new Array<any>();
        //        for (let i = 0; i < this.adultList.length; i++) {
        //          allDOb.push(this.datePipe.transform(this.adultList[i].dob, 'dd/MM/yyyy'));
        //        }
        //        for (let i = 0; i < this.childList.length; i++) {
        //          allDOb.push(this.datePipe.transform(this.childList[i].dob, 'dd/MM/yyyy'));
        //        }
        //        // checking the issue state and selected state is same or not and appliyng the logic for IGST calculation.
        //        const seletcedState = this.proposerVo.communicationDetails.stateId;
        //        if (this.proposerVo.issueStateId === seletcedState) {
        //          this.proposerVo.issueState = true;
        //        } else {
        //          this.proposerVo.issueState = false;
        //        }
        //        this.proposerVo.policyTermId = this.proposerVo.policyTerm;
        //        this.proposerVo.dob = allDOb;
        //        const resourceDetails: Object = new Object();
        //        const resourceData = JSON.stringify(resourceDetails);
        //        resourceDetails['resourceDetails'] = this.proposerVo;
        //        this.proposalServices.getPremiumDetails(this.lobId, resourceDetails)
        //          .then(data => {
        //            this.premiumDetail.netPremium = data.totalNetPremium;
        //            this.premiumDetail.sgst = data.totalsgst;
        //            this.premiumDetail.cgst = data.totalcgst;
        //            this.premiumDetail.igst = data.totaligst;
        //            this.premiumDetail.grossPremium = data.totalGrossPremium;
        //            if (this.existingPremium !== this.premiumDetail.grossPremium) {
        //              if (!this.endorsmentPremiumFlag) {
        //                this.childList[i].dobErrorMessage = 'This impacts premium, Please check the date range';
        //                childAgeCheck[i] = false;
        //                this.isDateEligibleForEndorsement = false;
        //              }
        //            } else {
        //             this.childList[i].dobErrorMessage = '';
        //             this.isDateEligibleForEndorsement = true;
        //            }
        //          })
        //          .catch(error => { });
        //      }
        //  //    }
        //    }
        // }
      }).catch(error => {
      });
      return true;
    }
    // }
  }
  public adultAgeCalculationForEndorsment(adultDate, str) {
    this.adultAge = '';
    this.adultDay = '';
    const selectedAdultDate: any = new Date(adultDate);
    const timeAdultDiff = Math.abs(Date.now() - selectedAdultDate);
    const timeAdultDiffDay = timeAdultDiff / (1000 * 3600 * 24) / 365;
    this.adultAge = Math.floor(timeAdultDiff / (1000 * 3600 * 24) / 365);
    const timeAdultDiffDayString = timeAdultDiffDay.toString();
    const adultAgeArray = timeAdultDiffDayString.split('.');
    const adultAgenew = '.' + adultAgeArray[1];
    this.adultDay = Math.floor(Number(adultAgenew) * 365);
    if (str === 'age') {
      return this.adultAge;
    } else {
      return this.adultDay;
    }
  }

  public questionsOptions(str, optionsFlag) {
    if (optionsFlag) {
      this.questionList[str].invalidSelection = true;
      this.questionList[str].errorMessage = QUESTION_ERROR_MESSAGE;
      this.questionList[str].btnToggActiveYesFlag = true;
      this.questionList[str].btnToggActiveNoFlag = true;
    } else {
      this.questionList[str].invalidSelection = false;
      delete this.questionList[str].errorMessage;
      this.questionList[str].btnToggActiveYesFlag = false;
      this.questionList[str].btnToggActiveNoFlag = false;
    }
  }

  public checkOthers(str) {
    const relationType = this.getRelationshipDesc(str);
    if (relationType !== undefined) {
      if (relationType === RELATIONSHIP_OTHERS) {
        return true;
      } else {
        for (let i = 0; i < this.adultList.length; i++) {
          if (this.adultList[i].proposerRelation !== 14) {
            this.adultList[i].proposerOthers = '';
          } if (this.adultList[i].nomineeRelation !== 14) {
            this.adultList[i].nomineeOthers = '';
          }
        }
        for (let i = 0; i < this.childList.length; i++) {
          if (this.childList[i].proposerRelation !== 14) {
            this.childList[i].proposerOthers = '';
          } if (this.childList[i].nomineeRelation !== 14) {
            this.childList[i].nomineeOthers = '';
          }
        }
        return false;
      }
      return false;
    }
  }
  public checkEndorsementOthers(str) {
    if (str === 2) {
      this.displayReason = true;
    } else {
      this.displayReason = false;
    }
  }
  public gstinOptionsLoadArray() {
    const obj: Object = new Object();
    obj['Code'] = 0;
    obj['Description'] = 'No';
    const obj1: Object = new Object();
    obj1['Code'] = 1;
    obj1['Description'] = 'Yes';
    this.gstinOptionArray.push(obj);
    this.gstinOptionArray.push(obj1);
  }
  public gstinOptionsRiskLoadArray() {
    const obj: Object = new Object();
    obj['Code'] = 0;
    obj['Description'] = 'No';
    const obj1: Object = new Object();
    obj1['Code'] = 1;
    obj1['Description'] = 'Yes';
    this.gstinOptionRiskArray.push(obj);
    this.gstinOptionRiskArray.push(obj1);
  }
  public isGstinApplicable(str) {
    if (str === 1) {
      this.isGstinNoApplicable = true;
    } else {
      this.proposerVo.communicationDetails.gstinNo = '';
      this.isGstinNoApplicable = false;
    }
  }

  public isRiskGstinApplicable(str) {
    if (str === 1) {
      this.isRiskGstinNoApplicable = true;
    } else {
      this.proposerVo.riskLocationDetails.gstinNo = '';
      this.isRiskGstinNoApplicable = false;
    }
  }
  // Business Package data mapping for records

  public printProposal() {
    this.downloadFailed = false;
    this.hasErrorMessage = false;
    this.loadingmessage = 'Preparing Document...';
    this.moveToTop();
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
    const printProsalJson: Object = new Object();
    printProsalJson['applicationName'] = 'affinity';
    printProsalJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printProsalJson['dateOfIssue'] = this.proposerVo.policyIssueDate;
    printProsalJson['affinityName'] = this.proposerVo.affinityName;
    printProsalJson['proposalDetails'] = new Object();

    if (this.shortProdName === 'Home' ) {
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposal';
    } else if(this.shortProdName === 'Shop') {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposalShop';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printProsalJson['proposalDetails']['templateName'] = 'ProposalForm';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    }
    // printProsalJson['proposalDetails']['proposalInfo'] = new Object();

    printProsalJson['proposalDetails']['proposalInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo'][
      'employeeInfo'
    ] = new Object();
    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.customerDetails.employeeId;
    } else {
     // const agentDetails = this.proposerVo.agentId.split('/');
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.agentName;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'licenseCode'
      ] = this.proposerVo.agentId;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'contactNumber'
      ] = this.proposerVo.agentContactNumber;
    //  if (agentDetails[1] !== undefined) {
        
   //   }
  //    if (agentDetails[2] !== undefined) {
        
  ///    }
    }
    if (this.proposerVo.branchCode !== undefined && this.proposerVo.branchCode !== 0 ) {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'branchId'
      ] = this.proposerVo.branchCode;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'branchName'
      ] = this.proposerVo.branchName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'branchId'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'branchName'
      ] = '';
    }
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['city'] =
      this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['state'] =
      this.proposerVo.communicationDetails.stateName;

    printProsalJson['proposalDetails']['proposalInfo'][
      'personInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'lastName'
    ] =  this.proposerVo.customerDetails.lastName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'firstName'
    ] = this.proposerVo.customerDetails.firstName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'middleName'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address1'
    ] = this.proposerVo.communicationDetails.address1;


    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address2'
    ] = this.proposerVo.communicationDetails.address2;


    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address3'
    ] = this.proposerVo.communicationDetails.address3;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'city'
    ] = this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'state'
    ] = this.proposerVo.communicationDetails.stateName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'pinCode'
    ] = this.proposerVo.communicationDetails.pincode;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'telephone'
    ] = this.proposerVo.communicationDetails.telephone;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'mobile'
    ] = this.proposerVo.communicationDetails.mobile;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'email'
    ] = this.proposerVo.communicationDetails.emailId;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'salariedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'selfemployedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'otherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'detailsOfOtherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'indianResident'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'nonIndianResident'
    ] = '';
    if (this.shortProdName !== 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'address1'
      ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3;
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'city'
      ] = this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName;
      if (this.proposerVo.riskLocationDetails !== undefined) {
        if (this.proposerVo.riskLocationDetails.address3 === undefined) {
          this.proposerVo.riskLocationDetails.address3 = ' ';
        }
        if (this.proposerVo.riskLocationDetails.address2 === undefined) {
          this.proposerVo.riskLocationDetails.address2 = ' ';
        }
        if (this.proposerVo.riskLocationDetails.address1 === undefined) {
          this.proposerVo.riskLocationDetails.address1 = ' ';
        }
        printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
          'riskLocationAddress'
        ] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' + this.proposerVo.riskLocationDetails.address3;
        printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
          'riskLocationCity'
        ] =  this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' +
        this.proposerVo.riskLocationDetails.stateName;
        printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
          'riskLocationPincode'
        ] = this.proposerVo.riskLocationDetails.pincode;
      }
    }
    printProsalJson['proposalDetails']['proposalInfo'][
      'policyType'
    ] = new Object();
    if (this.proposerVo.policyTypeName === 'Individual') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = '';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'true';
    }

    printProsalJson['proposalDetails']['proposalInfo']['planType'] = new Object();
    if (this.proposerVo.planTypeName === '2 Adults') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adults + 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adults + 2 Children') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult + 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult + 2 Children') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'true';
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'typeOfBusiness'
    ] = new Object();
    if (this.actionType === 'RENEWAL') {
      printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness']['fresh'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness'][
        'renewal'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness']['fresh'] =
        'true';
      printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness'][
        'renewal'
      ] = '';
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'sumInsuredInfo'
    ] = new Object();

    if (this.proposerVo.sumInsured === '2 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '3 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '5 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'true';
    }


if (this.proposerVo.policyStartDate && this.proposerVo.policyEndDate ) {

    printProsalJson['proposalDetails']['proposalInfo'][
      'policyDuration'
    ] = new Object();
    const startdate = this.proposerVo.policyStartDate.split(' ');

 const enddate = this.proposerVo.policyEndDate.split(' ');
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'from'
    ] = startdate[0];
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'to'
    ] = enddate[0];

    if (this.shortProdName !== 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
        'from'
      ] = this.proposerVo.policyStartDate;
      printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
        'to'
      ] = enddate[0] + ' 23:59:59';
    }

  }


    printProsalJson['proposalDetails']['proposalInfo'][
      'insured1Info'
    ] = new Object();


    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.proposerVo.customerDetails.firstName + ' ' + this.proposerVo.customerDetails.lastName;
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = this.getRelationshipDesc(this.adultList[0].proposerRelation);
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = this.getGenderDesc(this.adultList[0].gender);
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = this.adultList[0].dob;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = '';
    }


    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = this.adultList[0].nomineeName;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = '';
    }

    if (this.shortProdName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = this.getRelationshipDesc(this.adultList[0].nomineeRelation);
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = '';
    }


    if (this.adultList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured2Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'name'
      ] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipWithProposer'
      ] = this.getRelationshipDesc(this.adultList[1].proposerRelation);
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'gender'
      ] = this.getGenderDesc(this.adultList[1].gender);
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'dob'
      ] = this.adultList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'nomineeName'
      ] = this.adultList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipOfNominee'
      ] = this.getRelationshipDesc(this.adultList[1].nomineeRelation);
    }

    if (this.childList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured3Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'name'
      ] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipWithProposer'
      ] = this.getRelationshipDesc(this.childList[0].proposerRelation);
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'gender'
      ] = this.getGenderDesc(this.childList[0].gender);
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'dob'
      ] = this.childList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'nomineeName'
      ] = this.childList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipOfNominee'
      ] = this.getRelationshipDesc(this.childList[0].nomineeRelation);
    }
    if (this.childList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured4Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'name'
      ] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipWithProposer'
      ] = this.getRelationshipDesc(this.childList[1].proposerRelation);
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'gender'
      ] = this.getGenderDesc(this.childList[1].gender);
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'dob'
      ] = this.childList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'nomineeName'
      ] = this.childList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipOfNominee'
      ] = this.getRelationshipDesc(this.childList[1].nomineeRelation);
    }
    if(this.proposerVo.paymentDetails){
    printProsalJson['proposalDetails']['proposalInfo'][
      'paymentInfo'
    ] = new Object();
    if(this.proposerVo.paymentDetails.paymentType === 'Offline'){
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cash'] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['others'] =
      'true';
    }
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cheque'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['dd'] = '';
   
  }

  
    printProsalJson['proposalDetails']['proposalInfo']['medicalQuestions'] = new Array<Object>();
     let i = 1;
     const dtArray: Array<Object> = new Array<Object>();
     for(const proans of this.proposerVo.productAnswers){

  // printProsalJson['proposalDetails']['proposalInfo']['medicalQuestions'] = new Array<Object>();

     const a :Object = new Object();
     a['medicalQuestionNo'] = i;
     a['medicalQuestion'] =proans['questionName'];
     a['questionAnswerY']= false;
     a['questionAnswerN']= true;
    
i++;

const stringifiedJson = JSON.stringify(a);
const parsedJson = JSON.parse(stringifiedJson);
dtArray.push(parsedJson);
       

   }
   printProsalJson['proposalDetails']['proposalInfo']['medicalQuestions'] = dtArray;

   
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'customerId'
    ] = this.proposerVo.customerDetails.customerId;
    if(this.proposerVo.certificateNo){
    
    const appno = this.proposerVo.certificateNo.substring(this.proposerVo.certificateNo.length - 12,this.proposerVo.certificateNo.length);
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'applicationNo'
    ] = appno;
  }
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['date'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'financierBranchName'
    ] = '';
    if (this.shortProdName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      }  else if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_SHOP_OCCUPANCY;
      } else  if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
      } else {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProdName === 'Home') {

      if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_HOME_OCCUPANCY;
      } else {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'loanAccountNo'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'planName'
    ] = this.proposerVo.planTypeName;
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
      'otpVerifiedDate'
    ] = this.proposerVo.paymentDetails.otpVerifiedDate;


    if (this.shortProdName !== 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType2'] = this.coverageSectionType2;
      if (this.shortProdName === 'Home') {
        printProsalJson['proposalDetails']['proposalInfo']['equipment'] = new Array();
        let serialCounter: Number = 0;
        for (const data of this.applianceDetail) {
          const obj1: Object = new Object();
          serialCounter = Number(serialCounter) + 1;
          obj1['serialNumber'] = serialCounter;
          obj1['appliance'] = data.applianceName;
          obj1['quantity'] = data.quantity;
          printProsalJson['proposalDetails']['proposalInfo']['equipment'].push(obj1);
        }
      }
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      } else {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj3);
      }


      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      } else {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj4);
      }

      if (this.premiumDetail.igst) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'IGST(18%)';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj5);
      }

      if (this.premiumDetail.grossPremium) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Premium Including Tax';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      } else {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Net Premium(Taxable value)';
        obj6['premiumValue'] = '';
        printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'].push(obj6);
      }


    }
    if (printProsalJson) {
      this.proposalServices
        .getPrintProposal(JSON.stringify(printProsalJson))
        .then(data1 => {
          const parsedResponse = data1; // .blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printFrom.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.proposerVo.covernoteNo +'.pdf'; //'printFrom.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.downloadFailed = true;
          this.loadingmessage = '';
        });
    }
  }


  public printCertificate() {
    this.hasErrorMessage = false;
    this.loadingmessage = 'Preparing Document...';
    this.moveToTop();

    this.productId = sessionStorage.getItem(PRODUCT_CODE);
    const titleDesc = sessionStorage.getItem('Title');
    let title: any;
    const titleData = JSON.parse(sessionStorage.getItem('Title'));
    if(titleData!==null){
    for (const data of titleData) {
      if (this.proposerVo.customerDetails.title === data['titleId']) {
        title =  data['titleName'];
      }
    }
  }
    const printCertificateJson: Object = new Object();

    printCertificateJson['applicationName'] = 'affinity';
    printCertificateJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printCertificateJson['affinityName'] = this.proposerVo.affinityName;
    printCertificateJson['certificateDetails'] = new Object();
    printCertificateJson['certificateDetails']['affinityName'] = this.proposerVo.affinityName;
    if (this.shortProdName !== 'Health') {
      if(this.actionType === 'ENDORSEMENT') {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageEndorsement';
      }
      else if (this.shortProdName === 'Shop') {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificateShop';
      } else {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificate';
        if(this.proposerVo.affinityName.startsWith("MUTHOOTTU"))
        {
          printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageMiniMuthootCertificate';
        }
      }
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
     // this.proposerVo.affinityId;
    } else {
      printCertificateJson['certificateDetails']['templateName'] = 'CertificateForm';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    }
    printCertificateJson['certificateDetails']['certificateInfo'] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'] = new Object();
    const certificateNo= this.proposerVo.certificateNo;
    const certificateNoVal: any = certificateNo.split('-');
    const val2 = certificateNoVal.slice(0,8);
    const endorsedOriginalCertNo = val2.join('-');
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['masterPolicyNo'] = endorsedOriginalCertNo;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['certificateNo'] = this.proposerVo.certificateNo;
    if(title!==undefined && this.proposerVo.customerDetails.lastName!==undefined)
    {
       printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = title + ' ' + this.proposerVo.customerDetails.firstName + ' ' + this.proposerVo.customerDetails.lastName;
    }else
    {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.proposerVo.customerDetails.firstName;
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['insuredAddress'] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' +
      this.proposerVo.communicationDetails.address3 + ' ' + this.proposerVo.communicationDetails.pincode + ' ' + this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' +
      this.proposerVo.communicationDetails.stateName;
    if (this.shortProdName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      } else if (this.proposerVo.affinityName.startsWith('INTERACTIVE TRADEX')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = INTERACTIVE_TRADEX_SHOP_OCCUPANCY;
      } else {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProdName === 'Home') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancy'
      ] = HOME_OCCUPANCY_AND_TARIFF;
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
     printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'proposalNo'
    ] = '';
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'planName'
    ] = this.proposerVo.planTypeName;
      if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
    if(this.riskLocationFlag !== undefined)  {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['riskLocationAddress'] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' +
      this.proposerVo.riskLocationDetails.address3 + ' ' + this.proposerVo.riskLocationDetails.pincode + ' ' + this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' +
      this.proposerVo.riskLocationDetails.stateName;
    } else {
    if (this.proposerVo.riskLocationDetails.address3 === undefined) {
      this.proposerVo.riskLocationDetails.address3 = ' ';
    }
    if (this.proposerVo.riskLocationDetails.address2 === undefined) {
      this.proposerVo.riskLocationDetails.address2 = ' ';
    }
    if (this.proposerVo.riskLocationDetails.address1 === undefined) {
      this.proposerVo.riskLocationDetails.address1 = ' ';
    }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['riskLocationAddress'] = this.proposerVo.riskLocationDetails.address1 + ' '+ this.proposerVo.riskLocationDetails.address2 + ' '+
      this.proposerVo.riskLocationDetails.address3 + ' '+ this.proposerVo.riskLocationDetails.pincode+ ' '+ this.proposerVo.riskLocationDetails.cityName + ' '+ this.proposerVo.riskLocationDetails.districtName+ ' '+
      this.proposerVo.riskLocationDetails.stateName;
    }
  }
    if (this.proposerVo.customerDetails.customerId) {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerNo'] = this.proposerVo.customerDetails.customerId;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerNo'] = '';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerName'] = this.proposerVo.customerDetails.customerName;

    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'groupAdministrator'
    ] = 'Manappuram Finance Ltd, Kerala';
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['policyVariant'] = this.proposerVo.policyTypeName;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['tpaCode'] = '003 / Medi Assist India TPA Pvt. Ltd';
    if (this.proposerVo.policyStartDate && this.proposerVo.policyEndDate) {
    const endL1 =  this.proposerVo.policyEndDate.split(' ');
    const startL1 = this.proposerVo.policyStartDate.split(' ');
  /// printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['COIPeriod'] = startL1[0]+ ' ' +"to" +' ' +endL;
   printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['periodOfInsurance'] = startL1[0] + ' to ' + endL1[0];
   }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['policyTenure'] = this.proposerVo.policyTerm + ' Year(s)';
    if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
      const endL =  this.proposerVo.policyEndDate.split(' ');
      const startL1 = this.proposerVo.policyStartDate.split(' ');
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['policyTenure'] = this.proposerVo.policyStartDate + ' to ' + endL[0] + ' 23:59:59';
      if(this.actionType === 'ENDORSEMENT') {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['endorsmenetType'] = this.proposerVo.endorsementTypeDesc;
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['endorsmenetValue'] = this.endorsmentReasonValue;

        printCertificateJson['certificateDetails']['certificateInfo']['policyDuration'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['policyDuration']['from']= this.proposerVo.policyStartDate;
        printCertificateJson['certificateDetails']['certificateInfo']['policyDuration']['to']= endL[0] + ' 23:59:59';
      }
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['policyServicingOffice'] = 'Liberty General Insurance Ltd. Door No. 39/4166-A, 2nd Floor, Shema Building,M.G Road, Ravipuram, Kochi - 682016 Boardline - 484-6900 010,Fax - 022 6700 1606';
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['emailID'] = this.proposerVo.communicationDetails.emailId;
    
    
    // printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['tollfreeNo'] = '1800 425 9449';
    if (this.shortProdName === 'Health') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['relationshipWithInsured'] = this.getRelationshipDesc(this.adultList[0].proposerRelation);
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['sumInsured'] = this.proposerVo.sumInsured;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithPrimaryMember'] = this.getRelationshipDesc(this.adultList[0].proposerRelation);
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = this.getGenderDesc(this.adultList[0].gender);
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = this.adultList[0].dob;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = this.adultList[0].nomineeName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithNominee'] = this.getRelationshipDesc(this.adultList[0].nomineeRelation);

      if (this.adultList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['name'] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithPrimaryMember'] = this.getRelationshipDesc(this.adultList[1].proposerRelation);
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['gender'] = this.getGenderDesc(this.adultList[1].gender);
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['dob'] = this.adultList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['nomineeName'] = this.adultList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithNominee'] = this.getRelationshipDesc(this.adultList[1].nomineeRelation);
      }

      if (this.childList.length > 0) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['name'] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithPrimaryMember'] = this.getRelationshipDesc(this.childList[0].proposerRelation);
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['gender'] = this.getGenderDesc(this.childList[0].gender);
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['dob'] = this.childList[0].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['nomineeName'] = this.childList[0].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithNominee'] = this.getRelationshipDesc(this.childList[0].nomineeRelation);
      }

      if (this.childList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['name'] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithPrimaryMember'] = this.getRelationshipDesc(this.childList[1].proposerRelation);
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['gender'] = this.getGenderDesc(this.childList[1].gender);
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['dob'] = this.childList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['nomineeName'] = this.childList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithNominee'] = this.getRelationshipDesc(this.childList[1].nomineeRelation);
      }
    } else {

      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.proposerVo.customerDetails.firstName + ' ' + this.proposerVo.customerDetails.lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithPrimaryMember'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithNominee'] = '';

    }

    printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();

    if (this.premiumDetail.netPremium) {
      const obj2: Object = new Object();
      obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
      obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
    } else {
      const obj2: Object = new Object();
      obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
      obj2['premiumValue'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
    }

    if (this.premiumDetail.cgst) {
      const obj3: Object = new Object();
      obj3['premiumType'] = 'CGST -9%';
      obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
    }

    if (this.premiumDetail.sgst) {
      const obj4: Object = new Object();
      obj4['premiumType'] = 'SGST -9%';
      obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
    }
    if (this.premiumDetail.cessTax) {
      const obj5: Object = new Object();
      obj5['premiumType'] = 'Cess -1%';
      obj5['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
    }

    if (this.premiumDetail.igst) {
      const obj6: Object = new Object();
      obj6['premiumType'] = 'IGST -18%';
      obj6['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
    }

    if (this.premiumDetail.grossPremium) {
      const obj7: Object = new Object();
      obj7['premiumType'] = 'Total Premium Payable';
      obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
    } else {
      const obj7: Object = new Object();
      obj7['premiumType'] = 'Total Premium Payable';
      obj7['premiumValue'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
    }
    // printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium'] = new Object();
    // printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['basicpremium'] = 'Rs.' + this.proposerVo.premiumDetails.netPremium;
    // if (this.proposerVo.premiumDetails.cgst) {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['cgst'] = 'Rs.' + this.proposerVo.premiumDetails.cgst;
    // } else {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['cgst'] = '';
    // }
    // if (this.proposerVo.premiumDetails.sgst) {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['sgst'] = 'Rs.' + this.proposerVo.premiumDetails.sgst;
    // } else {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['sgst'] = '';
    // }
    // if (this.proposerVo.premiumDetails.igst) {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['igst'] = 'Rs.' + this.proposerVo.premiumDetails.igst;
    // } else {
    //   printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['igst'] = '';
    // }
    // printCertificateJson['certificateDetails']['certificateInfo']['scheduleOfPremium']['totalPremium'] = 'Rs.' + this.proposerVo.premiumDetails.grossPremium;

    printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails'] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['dateOfIssue'] = '';
    printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['gstin'] = this.proposerVo.communicationDetails.gstinNo;
    
// if(this.shortProdName === 'Health'){
//   printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['sacCode'] = '997133';
// } else if(this.shortProdName === 'Home' || this.shortProdName === 'Shop') {

//   printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['sacCode'] = '997137';
// }
// console.log(this.placeOfSupply);
    
//     printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['irdaRegNo'] = '150';
//     printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['cinNo'] = 'U66000MH2010PLC209656';
//     printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['uin'] = '';
//     printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['serviceDescription'] = 'General Insurance Service';

 
  printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['placeOfSupply'] = this.proposerVo.placeOfSupply;
  printCertificateJson['certificateDetails']['certificateInfo']['receiptDetails']['dateOfIssue'] = this.proposerVo.policyIssueDate;


    if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
      
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType2'] = this.coverageSectionType2;
      if (this.shortProdName === 'Home') {
        printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = new Array();
        let serialCounter: Number = 0;
        for (const data of this.applianceDetail) {
          const obj1: Object = new Object();
          serialCounter = Number(serialCounter) + 1;
          obj1['serialNumber'] = serialCounter;
          obj1['appliance'] = data.applianceName;
          obj1['quantity'] = data.quantity;
          printCertificateJson['certificateDetails']['certificateInfo']['equipment'].push(obj1);
        }
      }
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
      }

      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
      }
      if (this.premiumDetail.cessTax) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'Cess(1%)';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
      }

      if (this.premiumDetail.igst) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'IGST(18%)';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
      }

      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Premium Including Tax';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Premium Including Tax';
        obj7['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      }


    }
    if (printCertificateJson) {
      this.proposalServices
        .getPrintCertificate(JSON.stringify(printCertificateJson))
        .then(data1 => {
          const parsedResponse = data1; // data1.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printCertificate.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.proposerVo.certificateNo +'.pdf'; //'printCertificate.pdf';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loadingmessage = '';
          }
        })
        .catch(error => {
          this.downloadFailed = true;
          this.loadingmessage = '';
        });
    }

  }



  public createNewProposal(selectedLevel) {
    this.disableEndorsmentOptions = false;
    this.router.navigateByUrl(this.router.url);
  }

  /**TO DO */
  createDateObject(responsedate): Object {
    const dateObj: Object = new Object();
    const appDate: Object = new Object();
    const parts = responsedate.split('/');
    const mydate = new Date(parts[2], parts[1] - 1, parts[0]);


    return mydate.toDateString();
  }

  public showConfirmationForRetrieve() {
    if (this.shortProdName !== 'Health' && (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL') && this.modalCounter === 0) {
      this.openModal('confirmationModal');
      this.modalCounter++;
    }
  }

  public showConfirmation() {
    if (this.dataCopy['policyTypeId'] !== this.proposerVo.policyTypeId || this.dataCopy['planId'] !== this.proposerVo.planId) {
      if (this.actionType !== 'ENDORSEMENT') {
        this.proposalInfoFlag = true;
        this.insuredChangeFlag = false;
      }
      this.openModal('confirmationModal');
      this.closeModal('planChange');
      this.closeModal('premiumBreakup');
      return;
    }
  }

  public showModal() {
    if (this.shortProdName === 'Health' || this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
      if (!this.actionType && this.insuredChangeFlag) {
        this.proposalInfoFlag = true;
        this.insuredChangeFlag = false;
        this.openModal('confirmationModal');
        this.closeModal('planChange');
        this.closeModal('premiumBreakup');
        // } else if ((this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL') && this.insuredChangeFlag) {
      } else if ((this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL') && this.insuredChangeFlag) {
        this.proposalInfoFlag = true;
        this.insuredChangeFlag = false;
        this.openModal('confirmationModal');
        this.closeModal('planChange');
        this.closeModal('premiumBreakup');
      } else if (this.actionType === 'ENDORSEMENT') {
      }
    }
  }


  public setRenewalValue() {
    this.retrieveAdultList = [];
    this.retrieveChildList = [];
    this.closeModal('confirmationModal');
    this.closeModal('planChange');
    this.closeModal('premiumBreakup');
    const userId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userId'];
    this.renewalOrRetrievalNewPlan = this.proposerVo.planId;
    this.renewalOrRetrievalNewPolicy = this.proposerVo.policyTypeId;
    this.getPlanDropdown(userId, this.productId, this.proposerVo.policyTypeId);
    if (this.policyDateCancelFlag) {
      const date = moment(this.proposerVo.policyStartDate, 'DD/MM/YYYY').startOf(
        'day'
      );
      const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
      if ((now > date || now < date)) {
        this.proposerVo.policyStartDate = date;
      } else {
        this.proposerVo.policyStartDate = moment(
          new Date(),
          'DD/MM/YYYY HH:MM:ss'
        );
      }
      this.dataCopy['policyTypeId'] = this.proposerVo.policyTypeId;
      this.dataCopy['planId'] = this.proposerVo.policyTypeId;
      this.dataCopy['planId'] = this.proposerVo.policyTypeId;
      this.proposerVo.policyTypeId = undefined;
      this.proposerVo.planId = undefined;
      // this.proposerVo.masterPolicyNo = undefined;
      this.proposerVo.sumInsured = undefined;
      this.planChangeFlag = false;
      this.getPolicyEndDate();
      this.refresh2();
      if (this.shortProdName === 'Home' || this.shortProdName === 'Shop') {
     //   console.log('calling policy 2');
        this.fetchPlanType();
      }
      this.policyDateCancelFlag = false;
    }
  }

  public cancelRenewalValue() {
    if (!this.policyDateCancelFlag) {
      if (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL') {
        const startDate: any = this.dataCopy['policyStartDate'];
        this.proposerVo.policyStartDate = startDate;
      }
      this.proposerVo.policyTypeId = this.dataCopy['policyTypeId'];
      const userId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userId'];
      this.getPlanDropdown(userId, this.productId, this.proposerVo.policyTypeId);
      this.closeModal('confirmationModal');
      this.closeModal('planChange');
      this.closeModal('premiumBreakup');
      this.proposerVo.policyEndDate = this.dataCopy['policyEndDate'];
      this.proposerVo.policyTerm = this.dataCopy['policyTerm'];
      this.proposerVo.masterPolicyNo = this.dataCopy['masterPolicyNo'];
      this.proposerVo.planId = this.existingPlan;
      this.proposerVo.sumInsured = this.exSumInsured;
      this.adultList = JSON.parse(JSON.stringify(this.dataCopy['adultList']));
      this.childList = JSON.parse(JSON.stringify(this.dataCopy['childList']));
      this.childCount = this.dataCopy['childCount'];
      this.adultCount = this.dataCopy['adultCount'];
      this.insuredChangeFlag = true;
      this.applianceDetail = this.exApplianceDetail;
    } else {
      this.proposerVo.policyTypeId = this.dataCopy['policyTypeId'];
      const userId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userId'];
      this.getPlanDropdown(userId, this.productId, this.proposerVo.policyTypeId);
      this.closeModal('confirmationModal');
      this.closeModal('planChange');
      this.closeModal('premiumBreakup');
      const startDate: any = this.dataCopy['policyStartDate'];
      this.proposerVo.policyStartDate = startDate;
      this.proposerVo.policyEndDate = this.dataCopy['policyEndDate'];
      this.proposerVo.policyTerm = this.dataCopy['policyTerm'];
      this.proposerVo.masterPolicyNo = this.dataCopy['masterPolicyNo'];
      this.proposerVo.planId = this.existingPlan;
      this.proposerVo.sumInsured = this.exSumInsured;
      this.adultList = JSON.parse(JSON.stringify(this.dataCopy['adultList']));
      this.childList = JSON.parse(JSON.stringify(this.dataCopy['childList']));
      this.childCount = this.dataCopy['childCount'];
      this.adultCount = this.dataCopy['adultCount'];
      this.insuredChangeFlag = true;
      this.policyDateCancelFlag = false;
      this.applianceDetail = this.exApplianceDetail;
    }
  }

  refresh() {
    this.getPolicyTerm();
    this.validatedPages = new Array<boolean>(4);
    this.isRenewal = false;
    this.maxVisitedLevel = 1;
    this.hasSuccessPayment = false;
    this.hasErrorMessageTwo = false;
    this.disableEndorsmentOptions = false;
    this.selectedLevel = 1;
    this.loading = false;
    this.hasErrorMessage = false;
    this.message = '';
    this.adultCount = 0;
    this.childCount = 0;
    this.proposerVo.paymentDetails = new PaymentDetailsVO();
    this.proposerVo = new ProposalVO();
    this.beneficiaryVO = new BeneficiaryVO();
    this.questionList = new Array<any>();
    this.adultList = new Array<any>();
    this.childList = new Array<any>();
    this.communicationDetail = new CommunicationVO();
    this.premiumDetail = new PremiumDetailsVO();
    this.productAnswer = new QuestionVO();
    this.backdatedinception = true;
    this.date = undefined;
    this.min = undefined;
    this.max = undefined;
    this.policyData = undefined;
    this.policyYear = undefined;
    this.minDob = undefined;
    this.maxDob = undefined;
    this.maxD = new Date();
    this.policyDetailsFlag = false;
    this.proposedInsuredFlag = false;
    this.premiumAndPaymentFlag = false;
    this.communicationDetailsFlag = false;
    this.createProposalFlag = false;
    this.createProposalButtonFlag = false;
    this.enableSubmit = false;
    this.dateTime = new moment();
    this.sumInsured = new Array<Object>();
    this.stateArray = new Array<Object>();
    this.districtArray = new Array<Object>();
    this.cityArray = new Array<Object>();
    this.policytermArray = new Array<Object>();
    this.endorsmentArray = new Array<Object>();
    this.masterPolicyArray = new Array<Object>();
    this.planTypeArray = new Array<Object>();
    this.policyTypeArray = new Array<Object>();
    this.adultRelationShipArray = new Array<Object>();
    this.titleArray = new Array<Object>();
    this.childAgeFlag = false;
    this.pincodeArray = Array<any>();
    this.genderDesc = undefined;
    this.pincodeErrorMessage = undefined;
    this.planChangeFlag = false;
    this.existingPlan = null;
    this.existingPolicy = undefined;
    this.exPolicyTypeArray = undefined;
    this.exPlanTypeArray = undefined;
    this.exChildList = new Array<any>();
    this.exAdultList = new Array<any>();
    this.exPlanList = undefined;
    this.exSumInsured = undefined;
    this.newPlan = '';
    this.cancelFlag = false;
    this.cancelProposalFlag = false;
    this.date1 = undefined;
    this.month = undefined;
    this.year = undefined;
    this.childYear = undefined;
    this.childMonth = undefined;
    this.childDate = undefined;
    this.keys = undefined;
    this.pageParams = new Object();
    this.policyStartDateFlag = false;
    this.proposedInfoFlag = false;
    this.dobErrorMessage = undefined;


    this.proposerVo = new ProposalVO();
    this.proposerVo.communicationDetails = new CommunicationVO();
    this.proposerVo.customerDetails = new CustomerDetailsVO();
    this.proposerVo.premiumDetails = new PremiumDetailsVO();
    this.proposerVo.productAnswers = new Array<QuestionVO>();
    this.proposerVo.questionsList = new Array<QuestionVO>();
    this.proposerVo.beneficiaryList = new Array<BeneficiaryVO>();


    this.route.queryParams.subscribe(params =>
      this.proposalData = params
    );
    const actionType = this.proposalData.actionType;
    const coverNoteNo = this.proposalData.proposalno;
    if (actionType === 'RETRIEVE') {

      this.proposalServices.getProductDetails().then(data => {
        const date = new Date;
        const date1 = new Date;
        const date2 = new Date;
        for (let i = 0; i < data.length; i++) {
          date1.setHours(0);
          date1.setMinutes(0);
          date1.setSeconds(0);
          const riskstartdaterange = data[i].riskStartDateRange;
          const backdatedinceptionFlag = data[i].backDatedInception;
          const backdatedinceptionrange = data[i].backDatedInceptionRange;
          this.productName = data[i].productName;
          this.lobId = data[i].lobId;
          this.productTerm = data[i].policyTerm;
          if (backdatedinceptionFlag) {
            this.policyStartDateFlag = false;
            if (riskstartdaterange !== 0 && backdatedinceptionrange !== 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange === 0 && backdatedinceptionrange !== 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange !== 0 && backdatedinceptionrange === 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange === 0 && backdatedinceptionrange === 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            }
          } else if (!backdatedinceptionFlag) {
            this.policyStartDateFlag = true;
            if (riskstartdaterange !== 0) {
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              this.proposerVo.policyStartDate = startDate;
            } else if (riskstartdaterange === 0) {
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              this.proposerVo.policyStartDate = startDate;
            }
          }
        //  console.log('calling 10');
          this.setPolicyTerm();
          this.setAffinityID();
        }
      })
        .catch(error => { });
      const coverNoteNo1: any = this.proposalData.proposalno;
      this.proposalServices
        .getProposalSummary(coverNoteNo1)
        .then(data => {
          if (data !== undefined) {
            this.setRetrievelInfo(data);
          }
        })
        .catch(error => { });
    } else if (actionType === 'RENEWAL') {
      this.proposalServices.getProductDetails().then(data => {
        const date = new Date;
        const date1 = new Date;
        const date2 = new Date;
        for (let i = 0; i < data.length; i++) {
          date1.setHours(0);
          date1.setMinutes(0);
          date1.setSeconds(0);
          const riskstartdaterange = data[i].riskStartDateRange;
          const backdatedinceptionFlag = data[i].backDatedInception;
          const backdatedinceptionrange = data[i].backDatedInceptionRange;
          this.productName = data[i].productName;
          this.lobId = data[i].lobId;
          this.productTerm = data[i].policyTerm;
          if (backdatedinceptionFlag) {
            this.policyStartDateFlag = false;
            if (riskstartdaterange !== 0 && backdatedinceptionrange !== 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange === 0 && backdatedinceptionrange !== 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange !== 0 && backdatedinceptionrange === 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange === 0 && backdatedinceptionrange === 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            }
          } else if (!backdatedinceptionFlag) {
            this.policyStartDateFlag = true;
            if (riskstartdaterange !== 0) {
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              this.proposerVo.policyStartDate = startDate;
            } else if (riskstartdaterange === 0) {
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              this.proposerVo.policyStartDate = startDate;
            }
          }
        //  console.log('calling 11');
          this.setPolicyTerm();
          this.setAffinityID();
        }
      })
        .catch(error => { });
      this.proposalServices
        .getProposalSummaryByCertificate(coverNoteNo)
        .then(data => {
          if (data !== undefined) {
            this.setRetrievelInfo(data);
          }
        })
        .catch(error => { });

    } else {
      /**TO DO */
      this.proposerVo.communicationDetails = this.communicationDetail;
      this.proposerVo.riskLocationDetails = this.riskLocationDetail;
      this.proposerVo.customerDetails = this.customerDetail;
      this.proposalServices
        .getQuestion()
        .then(data => {
          this.questionList = data;
          if (this.questionList.length !== 0) {
            for (let i = 0; i < this.questionList.length; i++) {
              this.questionList[i].answer = this.questionList[
                i
              ].choices[1].choiceValue;
            }
          }
        })
        .catch(error => { });

      this.proposalServices.getProductDetails().then(data => {
        const date = new Date(this.serverDate);
        const date1 = new Date(this.serverDate);
        const date2 = new Date(this.serverDate);
        for (let i = 0; i < data.length; i++) {
          date1.setHours(0);
          date1.setMinutes(0);
          date1.setSeconds(0);
          const riskstartdaterange = data[i].riskStartDateRange;
          const backdatedinceptionFlag = data[i].backDatedInception;
          const backdatedinceptionrange = data[i].backDatedInceptionRange;
          this.productName = data[i].productName;
          this.lobId = data[i].lobId;
          this.productTerm = data[i].policyTerm;
          if (backdatedinceptionFlag) {
            this.policyStartDateFlag = false;
            if (riskstartdaterange !== 0 && backdatedinceptionrange !== 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange === 0 && backdatedinceptionrange !== 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange !== 0 && backdatedinceptionrange === 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            } else if (riskstartdaterange === 0 && backdatedinceptionrange === 0) {
              this.min = new Date(date1.setDate(date1.getDate() - backdatedinceptionrange));
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              this.proposerVo.policyStartDate = this.max;
            }
          } else if (!backdatedinceptionFlag) {
            this.policyStartDateFlag = true;
            if (riskstartdaterange !== 0) {
              date.setHours(0);
              date.setMinutes(0);
              date.setSeconds(0);
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              this.proposerVo.policyStartDate = startDate;
            } else if (riskstartdaterange === 0) {
              this.max = new Date(date.setDate(date.getDate() + riskstartdaterange));
              const startDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
              this.proposerVo.policyStartDate = startDate;
            }
          }
        //  console.log('calling 12');
          this.setPolicyTerm();
          this.setAffinityID();
        }
      })
        .catch(error => { });
    }
  }

  /**TO DO */
  refresh2() {
    this.maxVisitedLevel = 1;
    //  this.proposerVo.masterPolicyNo = undefined;
    this.proposerVo.policyTypeName = undefined;
    this.proposerVo.policyTypeId = undefined;
    //  this.proposerVo.masterPolicyNo = undefined;
    this.proposerVo.planTypeName = undefined;
    this.proposerVo.planId = undefined;
    this.proposerVo.sumInsured = undefined;
  }
  refresh3() {
    this.proposerVo.planId = undefined;
    this.proposerVo.planTypeName = undefined;
    this.proposerVo.sumInsured = undefined;
    this.proposerVo.sumInsuredId = undefined;
  }
  refresh4() {
    this.proposerVo.sumInsured = undefined;
    this.proposerVo.sumInsuredId = undefined;
  }
  getStartYear(): any {
    return (new Date().getFullYear() - 55);
  }
  getStartChildYear(): any {
    return (new Date().getFullYear() - 25);
  }
  getEndYear(): any {
    return (new Date().getFullYear() - 18);
  }
  getEndChildYear(): any {
    return new Date().getFullYear();
  }
  public setRiskLocation(option) {

    if ((this.shortProdName === 'Home' || this.shortProdName === 'Shop' || this.shortProdName ===  'Health' )) {
      if (this.actionType !== 'ENDORSEMENT') {
    if (option === 'yes') {
      this.riskLocationFlag = true;
      this.isRiskAddress = 1;
      this.proposerVo.riskLocationDetails = this.proposerVo.communicationDetails;
    } else {
      this.riskLocationFlag = false;
      this.isRiskGstinNoApplicable = false;
      this.isRiskAddress = 0;
      this.proposerVo.riskLocationDetails = new CommunicationVO();
    }
  } else if(this.actionType == 'ENDORSEMENT'){
    if(option === 'yes'){
    this.riskLocationFlag = true;
    } 
    else {
      this.riskLocationFlag = false;
    }
  }
}
  }

  public getBusinessPlanDetails(str) {
    const planId = this.proposerVo.planId;
    if (this.shortProdName === 'Home') {
      this.proposalServices.getApplianceDetails(planId).then(data => {
        if (this.actionType !== 'ENDORSEMENT') {
          if (this.currentPlanId !== this.proposerVo.planId) {
            this.currentPlanId = this.proposerVo.planId;
            this.applianceDetail = [];
            const applianceDetails = data;
            for (let i = 0; i < applianceDetails.length; i++) {
              const quantity = [];
              if (data[i].quantity === undefined) {
                data[i].appliances.quantity = 0;
              }
              this.applianceDetail.push(data[i].appliances);
            }
          } else {
            for (let i = 0; i < data.length; i++) {
              for (let j = 0; j < this.applianceDetail.length; j++) {
                if (data[i].appliances['applianceID'] === this.applianceDetail[j]['applianceID']) {
                  this.applianceDetail[j]['applianceName'] = data[i].appliances['applianceName'];
                  if (this.actionType === 'RENEWAL') {
                    delete this.applianceDetail[j]['policyOtherdetailsID'];
                  }
                }
              }
            }
          }
        } else {
          for (let i = 0; i < data.length; i++) {
            for (let j = 0; j < this.applianceDetail.length; j++) {
              if (data[i].appliances['applianceID'] === this.applianceDetail[j]['applianceID']) {
                this.applianceDetail[j]['applianceName'] = data[i].appliances['applianceName'];
                if (this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
                  delete this.applianceDetail[j]['policyOtherdetailsID'];
                }
              }
            }
          }
        }
      }).catch(error => { });
    }
  }


  public getEmployeeName(employeeId) {
    this.employeeCodeMessage2 = '';
    this.employeeCodeFlag = false;
    if (String(employeeId).length < 4) {
      return;
    }
    if (this.employeeIdTemporal) {
      clearTimeout(this.employeeIdTemporal);
    }
    const _this = this;
    this.employeeIdTemporal = setTimeout(function () {
      this.proposalServices.getEmployeeNameByCode(employeeId).then(data => {
        if (data.employeeName) {
          this.proposerVo.employeeName = data.employeeName;
          this.employeeCodeFlag = true;
          this.employeeCodeMessage2 = '';
        } else {
          this.proposerVo.employeeName = '';
          this.employeeCodeFlag = false;
          this.employeeCodeMessage2 = 'Employee ID does not exist';
        }
      }).catch(error => {
        this.proposerVo.employeeName = '';
        this.employeeCodeFlag = false;
        this.employeeCodeMessage2 = 'Employee ID does not exist';
      });
    }.bind(_this), 1000);

  }

  public hasEmployeeField(): boolean {
    if ((this.shortProductName === 'Shop' || this.shortProductName === 'Home') && String(this.selectedLevel) === '4') {
      return true;
    }
    return false;
  }

  
  public policyErrorValidation() {
    this.policyErrorMessage = '';
    if ((this.proposerVo.planId === undefined || this.proposerVo.planId === null || this.proposerVo.planId === '') &&
      (this.proposerVo.policyTypeId === undefined || this.proposerVo.policyTypeId === null || this.proposerVo.policyTypeId === '')) {
      this.policyErrorMessage = 'Plan is requireddddd';
    } else {
      this.policyErrorMessage = '';
    }
  }

  public disableonMs() {
    let isSahajAff = false; 
    if (this.proposerVo.affinityName.startsWith('Sahaj') || this.proposerVo.affinityName.startsWith('SREI')){
       isSahajAff = true;
    }
    
    if (this.proposerVo.customerDetails.title === undefined) {
    } else if (Number(this.proposerVo.customerDetails.title) === 5  && !isSahajAff) {
      this.proposerVo.customerDetails.lastName = '';
      this.disableLastName = true;
      this.lastNameError = true;
    } else {
      this.disableLastName = false;
    }
    // if (this.proposerVo.customerDetails.title === undefined) {
    // } else if (Number(this.proposerVo.customerDetails.title) === 5) {
    //   this.proposerVo.customerDetails.lastName = '';
    //   this.disableLastName = true;
    //   this.lastNameError = true;

    // } else {
    //   this.disableLastName = false;
    // }
  }

  public getCovergeAndSections(planId) {
    this.proposalServices.getSumInsuredDetails(planId).then(data => {
      this.sumInsuredForCoverages = data;
      const allCoverAgeIds: Array<any> = new Array<any>();
      if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          allCoverAgeIds.push(data[i].coverageId);
          this.coverageIds['coverageIds'] = allCoverAgeIds;
        }
        this.getCoverageAndSectionForCoverageID();
      }
    }).catch(error => {
    });

  }

  public getProductPaymentInfo() {
    if (this.actionType === 'ENDORSEMENT') {
      this.paymentOptionsArray = [];
      let paymentDesc;
      const code1: Object = new Object();
      const paymentOptions: any = sessionStorage.getItem('paymentOptions');
      if (paymentOptions) {
        const paymentTypeArry: Array<Object> = JSON.parse(paymentOptions);
        if (paymentTypeArry) {
          for (const pnType of paymentTypeArry) {
            if (pnType['gatewayName'] === this.endorsmentPaymentOption) {
              if (pnType['gatewayId'] !== undefined && pnType['gatewayId'] !== 0) {
                code1['gatewayId'] = pnType['gatewayId'];
                code1['walletId'] = 0;
              } else {
                code1['walletId'] = pnType['walletId'];
                code1['gatewayId'] = 0;
              }
              paymentDesc = pnType['gatewayName'];
              code1['gatewayName'] = paymentDesc;
              code1['logoURL'] = pnType['logoURL'];
            }
          }
        }
        this.paymentOptionsArray.push(code1);
        sessionStorage.setItem('gateWayDetails', JSON.stringify(this.paymentOptionsArray));
      }
    } else {
      this.proposalServices.getAffinityPaymentDetails().then(data => {
        const affintyDetails = JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'));
      this.paymentOptionsArray = [];
        let geatWayId;
        let walletId;
        if (data !== undefined) {
          for (let i = 0; i < data.length; i++) {
            const code1: Object = new Object();
            let paymentDesc;
            if (data[i].gatewayId !== 0) {
              if (affintyDetails['isMuthootCustomer'] === true) {
                data[i].gatewayId = 1;
              }
              geatWayId = data[i].gatewayId;
              code1['gatewayId'] = data[i].gatewayId;
              code1['walletId'] = 0;
            } else if (data[i].walletId !== 0) {
              walletId = data[i].walletId;
              code1['walletId'] = walletId;
              code1['gatewayId'] = 0;
            }
            const paymentOptions: any = sessionStorage.getItem('paymentOptions');
            if (paymentOptions) {
              const paymentTypeArry: Array<Object> = JSON.parse(paymentOptions);
              if (paymentTypeArry) {
                for (const pnType of paymentTypeArry) {
                  if (data[i].gatewayId !== undefined && data[i].gatewayId !== 0) {
                    if (pnType['gatewayId'] === data[i].gatewayId) {
                      paymentDesc = pnType['gatewayName'];
                      code1['gatewayName'] = paymentDesc;
                      code1['logoURL'] = pnType['logoURL'];
                    }
                  } else if (data[i].walletId !== undefined) {
                    if (pnType['walletId'] === data[i].walletId) {
                      paymentDesc = pnType['gatewayName'];
                      code1['gatewayName'] = paymentDesc;
                      code1['logoURL'] = pnType['logoURL'];
                    }
                  }
                }
              }
              this.paymentOptionsArray.push(code1);
            }
            if (this.actionType !== 'ENDORSEMENT') {
              this.affinityPaymentOption = paymentDesc;
              if ('Offline' === paymentDesc || 'Offline With OTP' === paymentDesc) {
                this.offlinePaymentType = true;
                this.paymentValidation();
              }
            }
          }
          sessionStorage.setItem('gateWayDetails', JSON.stringify(this.paymentOptionsArray));
        }
      }).catch(error => {

      });
    }
  }

  public getAvilablePaymentOptions() {
    this.proposalServices.getAvailablePaymentOptions().then(data => {
      if (data !== undefined) {
        sessionStorage.setItem('paymentOptions', JSON.stringify(data));
        this.getProductPaymentInfo();
      }
    }).catch(error => {

    });
  }

  hideError(str) {
  }
  public endorsmentDateCheck() {
    if (this.actionType === 'ENDORSEMENT') {
      const data = this.loadPremiumDetails();
      if (this.existingPremium.grossPremium !== this.premiumDetail.grossPremium) {
      }
    }
  }
  public getCoverageAndSectionForCoverageID() {
    this.proposalServices.getCoverageAndSections(this.coverageIds).then(data2 => {
      let sectionType1;
      let sectionType2;
      for (let i = 0; i < data2.length; i++) {
        sectionType1 = data2[i].sectionType1;
        sectionType2 = data2[i].sectionType2;
      }
      this.coverageSectionType2 = [];
      for (let i = 0; i < sectionType1.length; i++) {

        for (let j = 0; j < sectionType2.length; j++) {
          if (sectionType1[i].sectionId === sectionType2[j].sectionId) {
            const coverageSectionType2 = new Object();
            coverageSectionType2['coverage'] = sectionType1[i].coverage;
            coverageSectionType2['riskCovered'] = sectionType1[i].riskCovered;
            coverageSectionType2['limitperitem'] = sectionType2[j].coverage;
            for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
              if (this.sumInsuredForCoverages[m].coverageId === sectionType1[i].coverageId) {
                coverageSectionType2['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
           
              }
              if (this.sumInsuredForCoverages[m].coverageId === sectionType2[j].coverageId) {
                coverageSectionType2['limitperitemsuminsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
            }
            this.coverageSectionType2.push(coverageSectionType2);
            delete sectionType1[i].sectionId;
            delete sectionType1[i].coverage;
            delete sectionType1[i].riskCovered;
            delete sectionType1[i].coverageId;
          }
        }
      }
      this.coverageSectionType1 = [];
      for (let j = 0; j < sectionType1.length; j++) {
        if (sectionType1[j].coverage !== undefined) {
          const coverageSectionType = new Object();
          coverageSectionType['coverage'] = sectionType1[j].coverage;
          coverageSectionType['riskCovered'] = sectionType1[j].riskCovered;
          for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
            if (this.sumInsuredForCoverages[m].coverageId === sectionType1[j].coverageId) {
              coverageSectionType['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
            }
          }
          this.coverageSectionType1.push(coverageSectionType);
        }
      }
    }).catch(error => {
    });
  }
  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  // Get plan dropdown for proposal
  public getPlanDropdown(userId: any, productID: any, policyTypeId: any) {
    this.proposerVo.sumInsured = undefined;
    let value: any;
    this.productService.getUserPlanList(userId, productID).then(data => {
      if (this.shortProdName === 'Health') {
        this.productService.getUserPlans(data, policyTypeId).then(plans => {
          this.policyErrorMessage = '';
          this.healthPlanArray = [];
          if (plans.length > 0) {
            for (const plan of plans) {
              const obj = new Object();
              obj['Code'] = plan['planid'];
              obj['Description'] = plan['planTypeName'];
              if (obj['Description'] === '1 Adult') {
                // this.proposerVo.planTypeName = '1 Adult';
                // this.proposerVo.planId = this.healthPlanArray[0]['Code'];
                //  value = obj['Code'];
              }
              this.healthPlanArray.push(obj);
            }
            sessionStorage.setItem('PlanType', JSON.stringify(plans));
            if (this.healthPlanArray.length === 1 && this.policyTypeField) {
              //  this.disablePlan = true;
              // this.planTypeField.placeholder = '';
              if (this.healthPlanArray[0]['Description'] === '1 Adult') {
                this.disablePlan = true;
                this.proposerVo.planId = this.healthPlanArray[0]['Code'];
                this.proposerVo.planTypeName = this.healthPlanArray[0]['Description'];
                this.calculateAD(this.proposerVo.planId);
              } else {
                this.disablePlan = false;
                this.proposerVo.planId = undefined;
              }
            } else {
              this.disablePlan = false;
              this.proposerVo.planId = undefined;
            }
            this.changeevent.emit(value);
            if (this.actionType === 'RETRIEVE' || this.actionType === 'ENDORSEMENT') {
              // this.proposerVo.planTypeName = this.existingPlanName;
             if (this.renewalOrRetrievalNewPolicy !== undefined && this.renewalOrRetrievalNewPolicy !== this.existingPolicy) {
              if (!this.disablePlan) {
              //  this.proposerVo.policyTypeId = this.renewalOrRetrievalNewPolicy;
                this.proposerVo.planId  = undefined;
                this.proposerVo.sumInsured = undefined;
              }
             } else if (this.renewalOrRetrievalNewPlan !== undefined && this.renewalOrRetrievalNewPlan !== this.existingPlan) {
              this.proposerVo.planId = this.renewalOrRetrievalNewPlan;
              this.calculateAD(this.proposerVo.planId);
              this.getPlanDetailsDetailsForRenewalAndRetrieve(this.renewalOrRetrievalNewPlan);
             } else {
              this.proposerVo.planId = this.existingPlan;
              this.proposerVo.sumInsured = this.exSumInsured;
             }
              // if (this.renewalOrRetrievalNewPolicy !== undefined && this.renewalOrRetrievalNewPolicy !== this.existingPolicy) {
              //   this.proposerVo.policyTypeId = this.renewalOrRetrievalNewPolicy;
              //   this.proposerVo.planId  = undefined;
              //   this.proposerVo.sumInsured = undefined;
              // } else {
              //   this.proposerVo.policyTypeId = this.existingPolicy;
              // }
              // if (this.renewalOrRetrievalNewPlan !== undefined && this.renewalOrRetrievalNewPlan !== this.existingPlan) {
              //   this.proposerVo.planId = this.renewalOrRetrievalNewPlan;
              //   this.calculateAD(this.proposerVo.planId);
              //   this.getPlanDetailsDetailsForRenewalAndRetrieve(this.renewalOrRetrievalNewPlan);
              // } else {
              // //  this.proposerVo.policyTypeName = this.exist
              //   this.proposerVo.planId = this.existingPlan;
              //   this.proposerVo.sumInsured = this.exSumInsured;
              // }
            //  this.changeevent.emit(value);
              // if (!this.getPlanDetails(this.proposerVo.planTypeName)) {
              //   this.refresh3();
              // } else {
              //   this.proposerVo.planId = this.existingPlan;
              //   this.proposerVo.sumInsured = this.exSumInsured;
              // }
            }
            if (this.actionType === 'RENEWAL') {
            //('Old ' + this.renewalOrRetrievalNewPolicy);
             // console.log('New ' + this.existingPolicy);
             // console.log('Old plan ' + this.renewalOrRetrievalNewPlan);
             //  console.log('New Plan ' + this.existingPlan);
             if (this.renewalOrRetrievalNewPolicy !== undefined && this.renewalOrRetrievalNewPolicy !== this.existingPolicy) {
              this.proposerVo.planId = this.renewalOrRetrievalNewPlan;
              this.calculateAD(this.proposerVo.planId);
              this.getPlanDetailsDetailsForRenewalAndRetrieve(this.renewalOrRetrievalNewPlan);
              this.getSumInsured();
              // const sumIsuredData: any = sessionStorage.getItem('SumInsured');
              //   if (sumIsuredData) {
              //     const sumInsuredArrayObj: Array<Object> = JSON.parse(sumIsuredData);
              //     if (sumInsuredArrayObj) {
              //       for (const sumInsuredValue of sumInsuredArrayObj) {
              //        this.proposerVo.sumInsured = sumInsuredValue['sumInsured'];
              //           this.proposerVo.sumInsuredId = sumInsuredValue[SUMINSURED_ID];
                      
              //       }
              //     }
              //   }
              if (!this.disablePlan) {
              //  this.proposerVo.policyTypeId = this.renewalOrRetrievalNewPolicy;
              //  this.proposerVo.planId  = undefined;
              //  this.proposerVo.sumInsured = undefined;
              } else {
                
                
              }
             } else if (this.renewalOrRetrievalNewPlan !== undefined && this.renewalOrRetrievalNewPlan !== this.existingPlan) {
              this.proposerVo.planId = this.renewalOrRetrievalNewPlan;
              this.calculateAD(this.proposerVo.planId);
              this.getPlanDetailsDetailsForRenewalAndRetrieve(this.renewalOrRetrievalNewPlan);
             } else {
              this.proposerVo.planId = this.existingPlan;
              this.proposerVo.sumInsured = this.exSumInsured;
             }
            }
          } else if (plans.length === 0) {
            this.disablePlan = true;
            this.policyErrorMessage = 'Plan is not available for this policy type, please contact administrator';
            sessionStorage.setItem('PlanType', JSON.stringify(plans));
            if (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
              this.refresh3();
            }
            this.changeevent.emit(value);
          }
        });
      } else {
        this.productService.getUserPlans(data, policyTypeId).then(plans => {
          this.healthPlanArray = [];
          this.planTypeArray = [];
          this.planForPremium = [];
          const coverageList: Array<Object> = [];
		      const col1: Object = new Object();
		      col1['colName'] = 'Cover';
		      this.columnsData.push(col1);
          for (const plan of plans) {
          //  const obj: Object = new Object();
            const objForPremium: Object = new Object();
            //  obj['Code'] = plan['planid'];
            //  obj['Description'] = plan['planTypeName'];
            //  this.planTypeArray.push(obj);
            objForPremium['planId'] = plan['planid'];
            objForPremium['planDesc'] = plan['planTypeName'];
            this.planForPremium.push(objForPremium);
          }
          sessionStorage.setItem('PlanType', JSON.stringify(plans));
          // below foeloop is for plan & coverage summary
          for (const val of this.planForPremium) {
            const col1: Object = new Object();
            col1['colName'] = val['planDesc'];
            this.columnsData.push(col1);
          }
        //  this.getPremiumLinkDts();
          this.proposalServices.getBpSumIsnuredList(this.planForPremium).then(data1 => {
        //  this.proposalServices.getPlanwithPremium(this.planForPremium).then(data2 =>{
          const coverageDetailLiat = data1.sumCoveList;
             this.proposalServices.getCovergeDetlForPremium(coverageDetailLiat).then(data2 => {
              for(let i=0; i< data2.length; i++) {
                if(coverageList.length==0) {
                 coverageList.push(data2[i]['coverageName']);
                } else {
                  let coverageFalg = false;
                  for(let j =0; j<coverageList.length; j++) {
                    if(data2[i]['coverageName'] === coverageList[j]) {
                      coverageFalg = true;
                    }
                  }
                  if(!coverageFalg) {
                    coverageList.push(data2[i]['coverageName']);
                  }
                }
              }
              const datVal2: Object = new Object();
              const datVal3: Object = new Object();
              const datVal4: Object = new Object();
              for(let i=0;i<coverageList.length;i++){
                this.loadingmessage = '';
                const datVal: Object = new Object();
                datVal['Cover'] = coverageList[i];
                this.projectData.push(datVal);
                for(let j=0; j< data2.length; j++) {
                  if(coverageList[i] === data2[j]['coverageName']) {
                    datVal[data2[j]['planName']] = data2[j]['sumInsuredValue'];
                    datVal2[data2[j]['planName']] = data2[j]['grossPremium'];
                    datVal3[data2[j]['planName']]  = data2[j]['netPremium'];
                    datVal4[data2[j]['planName']]  = data2[j]['igst'];
                  }
                }
    
              }
              datVal2['Cover'] = 'Including with GST';
              this.projectData.push(datVal2);
              datVal3['Cover'] = 'Base Premium';
              this.projectData.push(datVal3);
              datVal4['Cover'] = 'GST';
              this.projectData.push(datVal4);
            }).catch(error => { });
            const planDataList = data1.planDescInfoList;
             for (const plan of planDataList) {
               const obj: Object = new Object();
               const objForPremium: Object = new Object();
               obj['Code'] = plan['planId'];
               obj['Description'] = plan['planDescPremium'];
               this.planTypeArray.push(obj);
             }
             if(this.actionType === 'ENDORSEMENT') {
               this.getPlanDetailsDetailsForRenewalAndRetrieve(this.proposerVo.planId);

               for (const pnType of this.planTypeArray) {
                if (pnType['Code'] === this.proposerVo.planId) {
                  this.proposerVo.planTypeName = pnType['Description'];
                }
              }
             }
          }).catch(error =>{

          });
        });
        if (this.actionType === 'RETRIEVE' || this.actionType === 'RENEWAL' || this.actionType === 'ENDORSEMENT') {
          if (this.renewalOrRetrievalNewPlan !== undefined && this.renewalOrRetrievalNewPlan !== this.existingPlan) {
            this.proposerVo.planId = this.renewalOrRetrievalNewPlan;
            this.proposerVo.applianceDetails = [];
            this.calculateAD(this.proposerVo.planId);
            this.getPlanDetailsDetailsForRenewalAndRetrieve(this.renewalOrRetrievalNewPlan);
          } else {
              this.proposerVo.planId = this.existingPlan;
              this.proposerVo.sumInsured = this.exSumInsured;
          }
          // if (!this.getPlanDetails(this.proposerVo.planTypeName)) {
          //   this.refresh3();
          // } else {
          //   this.proposerVo.planId = this.existingPlan;
          //   this.proposerVo.sumInsured = this.exSumInsured;
          // }
        }
      }
    });
  }
  // Policy type change routine for health
  public getPlansHealth() {
    if (this.shortProdName === 'Health') {
      const userId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userId'];
      this.getPlanDropdown(userId, this.productId, this.proposerVo.policyTypeId);
    }
  }

  public getPlanDetails(planName) {
    this.planFlag = false;
    const planType: any = sessionStorage.getItem('PlanType');
    if (planType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_DESC] === planName) {
            this.planFlag = true;
            return pnType[PLAN_TYPE_ID];
          }
        }
        // if (!this.PlanFlag) {
        //   this.refresh3();
        // }
      }
    }
    return this.planFlag;
  }

  public getPlanDetailsDetailsForRenewalAndRetrieve(planName) {
    this.planFlag = false;
    const planType: any = sessionStorage.getItem('PlanType');
    if (planType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_ID] === planName) {
            this.planFlag = true;
            this.proposerVo.planTypeName = pnType[PLAN_TYPE_DESC];
          }
        }
      }
    }
    return this.planFlag;
  }
  public getPolicyTermDetails(termName) {
    this.planFlag = false;
    const policyTerm: any = sessionStorage.getItem('PolicyTerm');
    if (policyTerm) {
      const planTypeArrayObj: Array<Object> = JSON.parse(policyTerm);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType['policyTerm'] === termName) {
            // this.PlanFlag = true;
            // return pnType[PLAN_TYPE_ID];
          }
        }
        // if (!this.PlanFlag) {
        //   this.refresh3();
        // }
      }
    }
    return this.planFlag;

  }

  public getSumInsured() {
    this.selectService.fetchLookUpData('SumInsured', this.proposerVo.planId).then(data1 => {
      let dataArray;
      if (data1 !== undefined) {
        dataArray = data1;
        sessionStorage.setItem('SumInsured', JSON.stringify(dataArray));
        this.getSumInsuredId();
      }
    }).catch(error => {
    });
  }
  public getSumInsuredId() {
    const sumIsuredData: any = sessionStorage.getItem('SumInsured');
    if (sumIsuredData) {
      const sumInsuredArrayObj: Array<Object> = JSON.parse(sumIsuredData);
      if (sumInsuredArrayObj) {
        for (const sumInsuredValue of sumInsuredArrayObj) {
          if(this.actionType === 'RENEWAL') {
            this.proposerVo.sumInsured = sumInsuredValue[SUMISURED];
            this.proposerVo.sumInsuredId = sumInsuredValue[SUMINSURED_ID];
          } else if (sumInsuredValue[SUMISURED] === this.proposerVo.sumInsured) {
            this.proposerVo.sumInsuredId = sumInsuredValue[SUMINSURED_ID];
          }
        }
      }
    }
  }
  public getPlanDetailsForRenewals(planName) {
    this.planFlag = false;
    const planType: any = sessionStorage.getItem('PlanType');
    if (planType) {
      const planTypeArrayObj: Array<Object> = JSON.parse(planType);
      if (planTypeArrayObj) {
        for (const pnType of planTypeArrayObj) {
          if (pnType[PLAN_TYPE_DESC] === planName) {
            this.planFlag = true;
            return pnType[PLAN_TYPE_ID];
          }
        }
        // if (!this.PlanFlag) {
        //   this.refresh3();
        // }
      }
    }
    return '';
  }
  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      },
    );
    this.router.navigateByUrl('');
  }
public getSahajDetails(){ 
  this.proposerVo.affinityAuthToken = JSON.parse(sessionStorage.getItem(USER_PROFILE));
  this.proposalServices.loadSahajDetails(this.proposerVo.affinityAuthToken).then(data => {
    if(data.smId !== undefined) {
      this.smId = data.smId;
      this.crmLeadId = data.crmLeadId;
    }
  });
}
LoadDeclration(){
  this.paymentDeclArray = [];
  this.selectService.fetchLookUpData('sahajDeclaration', null).then(data => {
        for (const val of data) {
          const obj: Object = new Object();
          obj['Description'] = val['declaration'];
          obj['id'] = val['id'];
          obj['checked'] = false;
          this.paymentDeclArray.push(obj);
        }
  });    
}

public isSelect() {
  const obj: Object = new Object();
  obj['Description'] ='Select All';
  obj['id'] =1;
  obj['checked'] = false;
  this.isSelectAllArray.push(obj);
}

public isSelected(){
  this.isSelectedChecked = this.isSelectAllArray.every(data => data['checked'] === true);
  if(this.isSelectedChecked) {
    for (let i = 0; i < this.paymentDeclArray.length; i++) {
     this.paymentDeclArray[i]['checked'] = true;      
    }
    this.validateOTPs();
  } else {
    for (let i = 0; i < this.paymentDeclArray.length; i++) {
      this.paymentDeclArray[i]['checked'] = false;      
     }
  }
}

// Sahaj Validation
public declarationValidation() {
  for (let i = 0; i < this.paymentDeclArray.length; i++) {
    if (this.paymentDeclArray[i]['checked'] !== true) {
      return false;
    }
  }
  return true;
}
public validateOTPs() {
  let isSuccess:Boolean = false;
  this.otpValidateStatus = '';
 // this.otpValidationSuccess = false;
  this.proposerVo.otp = this.otpInput;
  this.allChecked = this.paymentDeclArray.every(data => data['checked'] === true)
  if (!this.otpValidationSuccess && this.allChecked && this.proposerVo.otp !== undefined && this.proposerVo.otp !== '' && this.proposerVo.otp.length === 6) {
    const pattern = '^[0-9]*$';
    isSuccess = true;
      if (this.proposerVo.otp.match(pattern)) {
        let otpDetails: Object = new Object();
        otpDetails = new Object();
        otpDetails['policyId'] = this.proposerVo.policyId;
        otpDetails['otpEnteredbyUser'] = this.proposerVo.otp;
        const otpdata = JSON.stringify(otpDetails);
        this.inboxService.validateOTP(otpdata)
          .then(data => {
            if('OTP_NOT_VALIDATED' === data) {
              this.otpValidateStatus = 'OTP validation failed. Please try again.';
            } else if ('OTP_VALIDATED' === data) {
              this.otpFlag = false;
              this.otpValidationSuccess = true;
              this.otpValidateStatus = 'OTP validated successfully!.';
            }
          }).catch(error => {
  
          });
      }
  }

}
public makePayment() {
  if(this.isImuthootAffinity) {
    window.location.href = this.muthootPaymentURL;
  } else if (this.isSahajAffinity){
  const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
  this.proposerVo.affinityAuthToken = JSON.parse(sessionStorage.getItem(USER_PROFILE));
  let smState;
  let branchCode;
  if(profileData['smState'] === undefined) {
    smState = '';
  } else {
    smState = profileData['smState'];
  }
  if(profileData['branchCode'] === undefined) {
    branchCode = '';
  } else {
    branchCode = profileData['branchCode'];
  }
  const userName = profileData['userName'];
  this.proposalServices.getPaymentReqData(this.proposerVo.policyId,smState,branchCode,userName,this.proposerVo.affinityAuthToken).then(data =>{
    if (null != data && data !== undefined){
      const url = this.sahajUrl+data;
      window.location.href = url;
    }
  }).catch(error =>{
  });
} else {
  this.OfflineWithOtpPaymentFlow();
}
}

// below method call is for premium link details

  public getPremiumLinkDts() {
    this.loadingmessage = 'Please wait...';
    const coverageList: Array<Object> = [];
    this.proposerVo.productId = sessionStorage.getItem(PRODUCT_CODE);
    const planList: Array<Object> = [];
    const col1: Object = new Object();
    col1['colName'] = 'Cover';
    this.columnsData.push(col1);
  //  this.proposalServices.getPlanDetails(this.proposerVo.productId).then(data => {
    //  if (data !== undefined && data != null) {
        for (const val of this.planForPremium) {
          planList.push(val['planId'])
          const col1: Object = new Object();
          col1['colName'] = val['planDesc'];
          this.columnsData.push(col1);
        }
    //  }
      this.proposalServices.getBpSumIsnuredList(this.planForPremium).then(data1 => {
      // const val =   Array.from(new Set(data1.map((item: any) => item.planDesc)))

      //  const data22 =  data1.map(item => item.planDesc)
//  .filter((value, index, self) => self.indexOf(value) === index);
        this.proposalServices.getCovergeDetlForPremium(data1).then(data2 => {
          for(let i=0; i< data2.length; i++) {
            if(coverageList.length==0) {
             coverageList.push(data2[i]['coverageName']);
            } else {
              let coverageFalg = false;
              for(let j =0; j<coverageList.length; j++) {
                if(data2[i]['coverageName'] === coverageList[j]) {
                  coverageFalg = true;
                }
              }
              if(!coverageFalg) {
                coverageList.push(data2[i]['coverageName']);
              }
            }
          }
          const datVal2: Object = new Object();
          const datVal3: Object = new Object();
          const datVal4: Object = new Object();
          for(let i=0;i<coverageList.length;i++){
            this.loadingmessage = '';
            const datVal: Object = new Object();
            datVal['Cover'] = coverageList[i];
            this.projectData.push(datVal);
            for(let j=0; j< data2.length; j++) {
              if(coverageList[i] === data2[j]['coverageName']) {
                datVal[data2[j]['planName']] = data2[j]['sumInsuredValue'];
                datVal2[data2[j]['planName']] = data2[j]['grossPremium'];
                datVal3[data2[j]['planName']]  = data2[j]['netPremium'];
                datVal4[data2[j]['planName']]  = data2[j]['igst'];
              }
            }

          }
          datVal2['Cover'] = 'Including with GST';
          this.projectData.push(datVal2);
          datVal3['Cover'] = 'Base Premium';
          this.projectData.push(datVal3);
          datVal4['Cover'] = 'GST';
          this.projectData.push(datVal4);
        }).catch(error => { });
      }).catch(error => { });
  //  }).catch(error => { }); --perfomance issue
  }

  public mobileNumberValid(event: any) {
    this.mobileValidationError = '';
    this.productId = sessionStorage.getItem(PRODUCT_CODE);
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if(event.length == 10) {
    this.proposalServices.MobileValidation(affinityId, event, this.productId).then(resp => {
      if (resp == true) {
        this.mobileNumberFlag = false;
        if(this.isImuthootAffinity) {
          this.mobileValidationError = 'Your daily Policy issuance limit for this product has been reached. Please try tomorrow.';
        } else {
          this.mobileValidationError = 'Your daily policy issuance limit for this customer has been reached. Please try tomorrow.';
        }
      } else {
        this.mobileNumberFlag = true;
      }
    }).catch(error => {
      });
    }
  }

  // Endorsement Changes 
  public endorsementValidation(){
      this.isEndorsementSameData = true;
      this.endorsmentReasonValue="";
      let title: any;
    const titleData = JSON.parse(sessionStorage.getItem('Title'));
    if(titleData!==null && titleData!==undefined)
    {
      for (const data of titleData) {
        if (this.proposerVo.customerDetails.title === data['titleId']) {
          title =  data['titleName'];
        }
      }
    }
      if(this.exTitle !== this.proposerVo.customerDetails.title 
        || this.exFirstName !== this.proposerVo.customerDetails.firstName 
        || this.exLastName !== this.proposerVo.customerDetails.lastName) {
          if(title!==undefined && this.proposerVo.customerDetails.lastName!==undefined){
            this.endorsmentReasonValue = "Insured Name:"+title + " "+ this.proposerVo.customerDetails.firstName +" " + this.proposerVo.customerDetails.lastName;
          }
          else{
            this.endorsmentReasonValue = "Insured Name:"+ this.proposerVo.customerDetails.firstName;
          }
          this.isEndorsementSameData = false;
        }  if (this.exCommAddress1 !== this.proposerVo.communicationDetails.address1
          || this.exCommAddress2 !== this.proposerVo.communicationDetails.address2
          || this.exCommAddress3 !== this.proposerVo.communicationDetails.address3
          || this.exPincode !== this.proposerVo.communicationDetails.pincode) {
          this.endorsmentReasonValue = this.endorsmentReasonValue + ";" + "Communication Address:" +this.proposerVo.communicationDetails.address1 + " "
          + this.proposerVo.communicationDetails.address2 + " " + this.proposerVo.communicationDetails.address3 + " " +this.proposerVo.communicationDetails.pincode;
          this.isEndorsementSameData = false;
        }  if (this.exCommEmail !== this.proposerVo.communicationDetails.emailId
          || this.exCommMobile !== this.proposerVo.communicationDetails.mobile) {
            this.endorsmentReasonValue = this.endorsmentReasonValue + ";" + "Email Id/Mobile Number:" +this.proposerVo.communicationDetails.emailId + "/"+this.proposerVo.communicationDetails.mobile;
            this.isEndorsementSameData = false;
          }  if (this.exEmpName !== this.proposerVo.employeeName
            || this.exEmpId !== this.proposerVo.customerDetails.employeeId) {
              this.endorsmentReasonValue = this.endorsmentReasonValue + ";" + "Employee ID/Employee Name:" +this.proposerVo.customerDetails.employeeId + "/ "+ this.proposerVo.employeeName;
              this.isEndorsementSameData = false;
        }  if (this.exCommPhone !== this.proposerVo.communicationDetails.telephone) {
          this.endorsmentReasonValue = this.endorsmentReasonValue + ";" + "Phone No:" +this.proposerVo.communicationDetails.telephone;
          this.isEndorsementSameData = false;
        }  if(this.exCommName !== this.proposerVo.customerDetails.customerName) {
          this.endorsmentReasonValue = this.endorsmentReasonValue + ";" + "Customer Name:" +this.proposerVo.customerDetails.customerName;
          this.isEndorsementSameData = false;
        }
    // if (this.exTitle === this.proposerVo.customerDetails.title 
    //   && this.exFirstName === this.proposerVo.customerDetails.firstName
    //   && this.exLastName === this.proposerVo.customerDetails.lastName
    //   && this.exCommAddress1 === this.proposerVo.communicationDetails.address1
    //   && this.exCommAddress2 === this.proposerVo.communicationDetails.address2
    //   && this.exCommAddress3 === this.proposerVo.communicationDetails.address3
    //   && this.exCommEmail === this.proposerVo.communicationDetails.emailId
    //   && this.exCommMobile === this.proposerVo.communicationDetails.mobile
    //   && this.exCommPhone === this.proposerVo.communicationDetails.telephone
    //   && this.exCommName === this.proposerVo.customerDetails.customerName
    //   && this.exEndorsmentReason === this.proposerVo.endorsementReason
    //   && this.exEmpName === this.proposerVo.employeeName
    //   && this.exEmpId === this.proposerVo.customerDetails.employeeId) {
    //     this.isEndorsementSameData = true;
    //   } else{
    //     this.isEndorsementSameData = false;
    //   }
    return this.isEndorsementSameData;
  }

  inputValidation(event) {

    this.proposerVo.customerDetails.employeeId = event;


    if (this.proposerVo.customerDetails.employeeId == '' || this.proposerVo.customerDetails.employeeId == undefined) {
      this.isbuttondiable = true;

      this.selectedLevel = 4;
    }
    else if (this.proposerVo.customerDetails.employeeId.length <= 3) {
      this.isbuttondiable = true;
    } else {
      this.isbuttondiable = false;

    }
    if(!this.isEndorsement)
    {
      this.isEndorsementSameData = false;
      this.isbuttondiable = false;
    }
  }
  inputValidation1(e) {

    this.proposerVo.employeeName = e.target.value;
    if (this.proposerVo.employeeName == '' || this.proposerVo.employeeName == undefined) {
      this.isbuttondiable = true;

    }
    else if (this.proposerVo.employeeName.length <= 1) {
      this.isEndorsementSameData = true;
    } else {
      this.isbuttondiable = false;
    }
    if(!this.isEndorsement)
    {
      this.isEndorsementSameData = false;
      this.isbuttondiable = false;
      
    }
  }
  inputValidation2(e) {

    this.proposerVo.customerDetails.customerName = e.target.value;
    if (this.proposerVo.customerDetails.customerName == '' || this.proposerVo.customerDetails.customerName == undefined) {
      this.isbuttondiable = true;

    }
    else if (this.proposerVo.customerDetails.customerName.length <= 1) {
      this.isEndorsementSameData = true;
    } else {
      this.isbuttondiable = false;
    }
    if(!this.isEndorsement)
    {
      this.isEndorsementSameData = false;
      this.isbuttondiable = false;
    }
  }
 
  btnDisabled() {
    if ((this.shortProdName === 'Home' || this.shortProdName === 'Shop' || this.shortProdName ===  'Health' )) {
      if (this.actionType === 'ENDORSEMENT') {
        this.hideButton = true;
      }
      else {
        this.hideButton = false;
      }
    }
  }

  setFieldValues(data)
  {
    this.idType=data['api_result']['request']['input']['id_type'];
    if(this.idType===null || this.idType===undefined)
    {
      this.idType="";
    }
    
    this.proposerVo.idType=data['api_result']['request']['input']['id_type'];
    this.proposerVo.reqId=data['api_result']['request']['input']['req_id'];
    
    if(data['api_result']['dob']!==null && data['api_result']['dob']!==undefined && data['api_result']['dob'].length>0)
    {
      this.proposerVo.dateOfBirth=data['api_result']['dob'];
    }
    if(data['api_result']['registered_name']!==null && data['api_result']['registered_name']!==undefined && data['api_result']['registered_name'].length>0)
    {
      this.proposerVo.customerDetails.firstName =data['api_result']['registered_name'];
    }
    if(data['api_result']['email']!==null && data['api_result']['email']!==undefined && data['api_result']['email'].length>0)
    {
      this.proposerVo.communicationDetails.emailId=data['api_result']['email'];
    }
    if(data['api_result']['c_address']['line1']!==null && data['api_result']['c_address']['line1']!==undefined && data['api_result']['c_address']['line1'].length>0)
    {
      this.proposerVo.communicationDetails.address1=data['api_result']['c_address']['line1'];
    }
    if(data['api_result']['c_address']['line2']!==null && data['api_result']['c_address']['line2']!==undefined && data['api_result']['c_address']['line2'].length>0)
    {
      this.proposerVo.communicationDetails.address2=data['api_result']['c_address']['line2'];
    }
    if(data['api_result']['mobile']!==null && data['api_result']['mobile']!==undefined && data['api_result']['mobile'].length>0)
    {
      this.proposerVo.communicationDetails.mobile=data['api_result']['mobile'];
    }
    if(data['api_result']['pan']!==null && data['api_result']['pan']!==undefined && data['api_result']['pan'].length>0)
    {
      this.proposerVo.panNumber=data['api_result']['pan'];
    }
    if(data['aadhaar']!==null && data['aadhaar']!==undefined && data['aadhaar'].length>0)
    {
      this.proposerVo.aadharNumber=data['aadhaar'];
    }
    if(data['api_result']['ckyc_number']!==null && data['api_result']['ckyc_number']!==undefined && data['api_result']['ckyc_number'].length>0)
    {
      this.proposerVo.ckycNumber=data['api_result']['ckyc_number'];
    }
    if(this.proposerVo.communicationDetails.emailId===null || 
      this.proposerVo.communicationDetails.emailId===undefined || this.proposerVo.communicationDetails.emailId.length===0)
    {
      this.isEmailIDReadonlyField="";
    }
    if(this.proposerVo.communicationDetails.mobile===null ||
       this.proposerVo.communicationDetails.mobile===undefined || this.proposerVo.communicationDetails.mobile.length===0)
    {
      this.isMobileNoReadonlyField="";
    }
    if(this.idType!=='PAN' && this.idType!=='AADHAAR')
    {
      if(this.proposerVo.panNumber===null || this.proposerVo.panNumber===undefined || this.proposerVo.panNumber.length===0)
      {
        this.isPanCardReadonlyField="";
      }
    }

    this.populateCKYCPincode(data['api_result']['c_address']['pincode']);

    if(data['api_result']['mobile']!==null && data['api_result']['mobile']!==undefined && data['api_result']['mobile'].length>0
      && this.proposerVo.affinityName === 'Muthoot Insurance Brokers')
    {
      this.mobileNumberValid(this.proposerVo.communicationDetails.mobile);
    }
  
  }

  verifyCKYC()
  {
    AJL.aryafns.initMod(this.aryaToken,this.aryaProposalId,this.mapAryaResponse.bind(this));
  }
  
  mapAryaResponse(data)
  {
     console.log(data);
     this.setFieldValues(data);
     this.validateFields();
     this.validate();
     this.validateAML();
     this.moveToTop();
  }
  
  generateAryaToken()
  {
    this.isAryaError=false;
    const details: Object = new Object();
    details['proposal_id']=''
    const AryaReqData = JSON.stringify(details);
    this.proposalServices.getAaryaToken(AryaReqData).then(resp => {
      console.log("arya res : ", resp);
      console.log("resp['token'] : " +resp['token']);
      //resp['errorMessage']="Auth token cannot be null or empty";
      if(resp['errorMessage']!=null &&  resp['errorMessage']!=undefined)
      {
      	this.openActionFailDialog("Arya service is Down.....Please Try After Some Time.");
        this.isAryaError=true;
      }
      console.log("resp['proposal_id'] : " +resp['proposal_id']);
      this.aryaToken=resp['token'];
      this.aryaProposalId=resp['proposal_id'];
      this.proposerVo.aryaToken= this.aryaToken;
      this.proposerVo.aryaProposalId= this.aryaProposalId;
    }).catch(error => {
      alert("Error While Generating the Arya token");
      });
  }
  
  public validateFields(){
    this.FullNameValidationError="";
    this.FullNameFlag=true;
      if(this.proposerVo.customerDetails.firstName.length>50)
      {
        this.FullNameValidationError="Full Name Length cannot be greater than 50.";
        this.FullNameFlag=false;
      }
  }
  
  public populateCKYCPincode(ckycpincode) {
    this.dataList = [];
      let pincodeLength;
      if (this.proposerVo.communicationDetails.pincode !== undefined && this.proposerVo.communicationDetails.pincode !== '') {
        pincodeLength = this.proposerVo.communicationDetails.pincode.length;
        this.communicationFlag = true;
      }
      if (ckycpincode.length !== 0) {
        this.pincodeErrorMessage = '';
        this.pincodeArray = [];
      
      this.proposalServices.getPincode(ckycpincode).then(pindata => {
            this.dataList = pindata;
            if (this.dataList.length === 0 && ckycpincode.length <= 6) {
              this.pincodeErrorMessage = PINCODE_ERROR;
            }
         for (const dt of this.dataList) {
          const pincode: any = new Object();
          pincode[CODE] = dt['pincodeInfo'];
          pincode[DESCRIPTION] = dt['pincodeInfo'];
          pincode['pincodeId'] = dt['pincodeId'];
          const pincodeLocality = dt['pincode_locality'];
          this.pincodeArray.push(pincode);
        }
		})
      } else {
        this.pincodeErrorMessage = PINCODE_ERROR;
        this.proposerVo.communicationDetails.stateId = null;
        this.proposerVo.communicationDetails.districtId = null;
        this.proposerVo.communicationDetails.cityId = null;
        this.proposerVo.communicationDetails.cityName = '';
        this.proposerVo.communicationDetails.stateName = '';
        this.proposerVo.communicationDetails.districtName = '';
        this.proposerVo.communicationDetails.pincodeId = '';
      }
    }

    public populateCKYCPincodeDetails(ckycpincode) {
      console.log("pincodeArrya at start is: " +this.pincodeArray);
        let pincodeLength;
        if (this.proposerVo.communicationDetails.pincode !== undefined && this.proposerVo.communicationDetails.pincode !== '') {
          pincodeLength = this.proposerVo.communicationDetails.pincode.length;
          this.communicationFlag = true;
        }
        if (ckycpincode.length !== 0) {
          this.pincodeErrorMessage = '';
         // this.pincodeArray = [];
        
        /*this.proposalServices.getPincode(ckycpincode).then(pindata => {
              this.dataList = pindata;
              if (this.dataList.length === 0 && ckycpincode.length <= 6) {
                this.pincodeErrorMessage = PINCODE_ERROR;
              }*/
              
            
        
        
        
          for (const dt of this.dataList) {
            const pincode: any = new Object();
            pincode[CODE] = dt['pincode'];
            pincode[DESCRIPTION] = dt['pincodeInfo'];
            pincode['pincodeId'] = dt['pincodeId'];
            const pincodeLocality = dt['pincode_locality'];
            if (this.proposerVo.communicationDetails.pincode === pincode[DESCRIPTION]) {
              this.getDetailOfPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
              // commenting now, need to remobved once manapuuram is ready
              // if (this.proposerVo.affinityName.toLowerCase() === 'manappuram' && this.shortProdName === 'Health') {
              //     this.proposalServices.validateDeclinedPincode(pincode[CODE]).then(data => {
              //       const response  = data;
              //       if(response) {
              //         this.pincodeErrorMessage = 'We regret to inform you that this pin code is not serviceable at our end, please select another pin code to proceed with Proposal creation';
              //         this.proposerVo.communicationDetails.stateId = null;
              //         this.proposerVo.communicationDetails.districtId = null;
              //         this.proposerVo.communicationDetails.cityId = null;
              //         this.proposerVo.communicationDetails.cityName = '';
              //         this.proposerVo.communicationDetails.stateName = '';
              //         this.proposerVo.communicationDetails.districtName = '';
              //         this.proposerVo.communicationDetails.pincodeId = '';
              //       } else {
              //         this.getDetailOfPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
              //       }
              //     }).catch(error => {
      
              //     })
              // } else {
              //   this.getDetailOfPincode(this.communicationFlag,pincode[CODE], pincodeLocality, dt['pincodeId']);
              // }
            } else {
              this.proposerVo.communicationDetails.cityName = '';
              this.proposerVo.communicationDetails.stateName = '';
              this.proposerVo.communicationDetails.districtName = '';
            }
            //this.pincodeArray.push(pincode);
          }
      //})
        } else {
          this.pincodeErrorMessage = PINCODE_ERROR;
          this.proposerVo.communicationDetails.stateId = null;
          this.proposerVo.communicationDetails.districtId = null;
          this.proposerVo.communicationDetails.cityId = null;
          this.proposerVo.communicationDetails.cityName = '';
          this.proposerVo.communicationDetails.stateName = '';
          this.proposerVo.communicationDetails.districtName = '';
          this.proposerVo.communicationDetails.pincodeId = '';
        }
        console.log("pincodeArrya at end is: " +this.pincodeArray);
      }

      public validateDOB(event: any)
      {
        this.DOBValidationError="";
        this.DOBFlag=true;
        var DOB=event.trim();
        
        var dayString=event.split('-')[0];
        var monthString=event.split('-')[1];
        var yearString=event.split('-')[2];

        var day: number = +dayString;
        var month: number = +monthString;
        var year: number = +yearString;

        var currentDate = new Date();
        var currentYear = currentDate.getFullYear();
        var currentMonth=currentDate.getUTCMonth() +1;
        var currentDay=currentDate.getUTCDate();
        
        if(year>currentYear)
        {
          this.DOBValidationError="Date of Birth cannot be future date";
          this.DOBFlag=false;
        }
        if(year===currentYear)
        {
          if(month>currentMonth)
          {
            this.DOBValidationError="Date of Birth cannot be future date";
            this.DOBFlag=false;
          }
          if(month===currentMonth)
          {
            if(day>currentDay)
            {
              this.DOBValidationError="Date of Birth cannot be future date";
              this.DOBFlag=false;
            }
          }
        }
      }
      public validateAML(){
        const details: Object = new Object();
        if(this.proposerVo.panNumber!==null && this.proposerVo.panNumber!==undefined && this.proposerVo.panNumber.length>0)
         {
           details['unique_id']='pan';
           details['pan']= this.proposerVo.panNumber;
         }
         else if(this.proposerVo.aadharNumber!==null && this.proposerVo.aadharNumber!==undefined && this.proposerVo.aadharNumber.length>0)
         {
           details['unique_id']='national_id';
           details['national_id']= this.proposerVo.aadharNumber;
         }
        details['name']= this.proposerVo.customerDetails.firstName;
        details['proposal_id']=this.aryaProposalId;
        details['search_type']='individual';
        details['deduplicate']='true';
        details['date_of_birth']=this.proposerVo.dateOfBirth;
        details['application_id']=this.aryaProposalId;
        const AmlReqData = JSON.stringify(details);
        this.proposalServices.checkAMLStatus(AmlReqData).then(resp => {
         this.amlMatch=resp['result']['Match'];
         if(this.amlMatch!=null &&  this.amlMatch!=undefined)
         {
          if(this.amlMatch.toLowerCase() ==='yes')
          {
            alert("Your details are under review. Please try again after 48 hours.");
            this.amlFlag = false;
          }
         } 

        });
      }
}

export const MY_CUSTOM_FORMATS = {
  parseInput: 'LL LT',
  fullPickerInput: 'DD/MM/YYYY  HH:mm:ss',
  datePickerInput: 'DD/MM/YYYY',
  timePickerInput: 'LT',
  monthYearLabel: 'MMM YYYY',
  dateA11yLabel: 'LL',
  monthYearA11yLabel: 'MMMM YYYY'
};
