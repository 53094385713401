
import { AppConfig } from '../config/app.config';
import { Http, Response, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Injectable } from '@angular/core';
import { REQUEST_HEADER, META_URL, USER_PROFILE } from 'src/common/common.constants';
import { PRODUCT_CODE } from './../common/common.constants';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HEADER } from './../common/common.constants';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
@Injectable()
export class SelectService {
  hostUrl: string = this.config.getConfig('productHost');
  basehostUrl: string = this.config.getConfig('basehost');
  inputHostUrl: String = this.config.getConfig('loginHost');
  constructor(private http: Http,
    private httpClient: HttpClient,
    private datePipe: DatePipe,
    private config: AppConfig) {
  }

  fetchLookUpData(metaDataName: String, params: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const request: Object = new Object();
    const url = this.getURL(metaDataName, params);
    // Performance issue
    if ('PolicyType' === metaDataName) {
      let policyStarteDate;
      if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(params)) {
        policyStarteDate= params;
      } else {
        const startDate = this.datePipe.transform(params, 'dd/MM/yyyy HH:mm:ss');
        policyStarteDate= startDate;
      }
      const policyDetails: Object = new Object();
      policyDetails['policyDetails']  = new Object();
      policyDetails['policyDetails']['policyStartDate'] = policyStarteDate;
      return this.httpClient.post(url,policyDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
    } else {
      return this.httpClient.get(url, { headers: HEADER })
        .toPromise()
        .then((response: Response) => response)
        .catch(this.handleError);
    }
  }

  private getURL(metaDataName, params) {
    switch (metaDataName) {
      case 'PolicyTerm':
        {
          return this.hostUrl + 'api/reference/term';
        }
      case 'PolicyType':
        {
          if (params !== null && params !== undefined) {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/policy/product/' + productid + '/policytypes';
          } else {
             return '';
          }
          //   const productid = sessionStorage.getItem(PRODUCT_CODE);
          //   if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(params)) {
          //     return this.hostUrl + 'api/policy/product/' + productid + '/policytypes?startDate=' + params;
          //   } else {
          //     const startDate = this.datePipe.transform(params, 'dd/MM/yyyy HH:mm:ss');
          //     return this.hostUrl + 'api/policy/product/' + productid + '/policytypes?startDate=' + startDate;
          //   }
          
        }
      case 'PlanType':
        {
          if (params !== undefined) {
            const productid = sessionStorage.getItem(PRODUCT_CODE);
            return this.hostUrl + 'api/policy/plans/' + params;
          } else {
            return '';
          }
        }
      case 'MasterPolicy':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          // if (/^(0[1-9]|[1-2][0-9]|3[0-1])\/(0[1-9]|1[0-2])\/[0-9]{4} (2[0-3]|[01][0-9]):[0-5][0-9]:[0-5][0-9]$/.test(params)) {
          //   return this.hostUrl + 'api/policy/masterPolicy/' + productid + '?policyStartDate=' + params;
          // }
          // const startDate = this.datePipe.transform(params, 'dd/MM/yyyy HH:mm:ss');
          // if (startDate !== null && startDate !== undefined) {
         // const startDate = '24/07/2019 00:00:00';
          return this.hostUrl + 'api/policy/masterPolicy/' + productid;
        }
      case 'SumInsured':
        {
          if (params !== undefined) {
            return this.hostUrl + 'api/rating/suminsured/' + params;
          } else {
            return '';
          }
        }
      case 'Title':
        {
          return this.hostUrl + 'api/reference/title';
        }
      case 'Gender':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/reference/gender';
        }
      case 'RelationType':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/reference/relationtypes';
        }
      case 'ChildRelationType':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/reference/relationtypes';
        }
      case 'pincode':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/reference/pincodes/' + params;
        }
      case 'CreateUserRole':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          // return this.hostUrl + 'api/user/roles/' + params;
          return this.hostUrl + 'api/user/role';
        }
      case 'Endorsement':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/reference/endorsmentType';
        }
      case 'SelectAffinity':
        {
          return this.hostUrl + 'api/admin/getaffinitylist';
        }
      case 'planInfo':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return './planInfo.json';
        }
        case 'reportInfo':
        {
          return './reportInfo.json';
        }
      case 'OfficeLocation':
        {
          return this.hostUrl + 'api/reference/state/list';
        }
      case 'issuingState':
        {
          return this.hostUrl + 'api/reference/state/list';
        }
      case 'ProductMasterPolicy':
        {
          return this.hostUrl + 'api/product/master/policy';
        }
      case 'ProductType':
        {
          return './ProductType.json';
        }
      case 'SubProductType':
        {
          return './SubProductType.json';
        }
      case 'applianceList': {
        return this.hostUrl + 'api/reference/appliance';
      }
      case 'ReportsProductType':
        {
          const productid = sessionStorage.getItem(PRODUCT_CODE);
          return this.hostUrl + 'api/admin/lob/list';
        }
      case 'sahajDeclaration' : 
      {
        return './Declaration.json';
      }
      case 'selectProductType' : 
      {
        return this.hostUrl + 'api/admin/lob/list';
      }
      case 'districtList':
        {
          return this.hostUrl + 'api/reference/district/list/'+ params;
        }
    }
  }

  private handleError(error: any): Promise<any> {

    return Promise.reject(error.message || error);
  }
}
