import { Component, Input } from '@angular/core';
@Component({
  selector: 'app-panel',
  styles: [`
    .hide {
      display: none;
    },
    `  ],
  templateUrl: './panel.html'
})
export class PanelComponent {
  @Input() Title: string;
  @Input()
  opened: Boolean = true;
  noArrow: Boolean = true;
  toggle() {
    this.opened = !this.opened;
  }
}

