import { ViewChild, ViewChildren, QueryList, ElementRef } from '@angular/core';
import { Component, AfterViewInit, HostListener, OnInit, Output, OnChanges } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { MAIN_PAGE_PART_URL, LANDING, USER_AFFINITY, USER_PROFILE_DATA } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { AffinityVO } from 'src/dto/AffinityVO';
import { CODE, DESCRIPTION, ISSUING_STATE, ISSUING_STATE_DESCRIPTION } from 'src/common/common.constants';
import { DocumentVO } from '../dto/DocumentVO';
import { ProposalServices } from '../services/proposal.services';
import { GridComponent } from '../grid/grid.component';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { NumberSymbol, DatePipe } from '@angular/common';
import { AffinityService } from 'src/services/affinity.service';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { PaymentService } from 'src/services/payment.service';
import { MultiselectComponent } from 'src/multiselect/multiselect.component';
@Component({
  selector: 'app-new-affinity',
  templateUrl: './create-new-affinity.html',

})
export class CreateNewAffinity implements OnInit, AfterViewInit, OnChanges {
  public loading: Boolean = true;
  public selectedAffinity: any;
  public document: Array<any> = [];
  public totalfiles: Array<any> = [];
  public createNewAffinity = false;
  public isupdate: Boolean = false;
  public isdeletefile: Boolean = false;
  public enableSubmit: Boolean = false;
  public getParam: String;
  public affinityID: String;

  public isdeleteADDfile: Boolean = false;
  public actionColumnsDatas: Array<Object> = Array<Object>();
  public values: any;
  public valuesAdditional: any;
  public affinityVO: AffinityVO = new AffinityVO();
  public isvalue: Boolean = false;

  public isAddvalue: Boolean = false;
  public AdditionalProjectData: Array<Object> = Array<Object>();
  public affinityNameArray: Array<Object> = new Array<Object>();
  public selectedAffinityName: any;
  public hasSuccess: Boolean = false;
  public customValidationMessage: String;
  public walletValidationMessage: String = '';

  public files: File;
  public columnsDatas: Array<Object> = Array<Object>();
  public projectsDatas: Array<Object> = Array<Object>();
  public validationMessage: String = '';
  public validationMessage2: String = '';
  public fileSelectionValidationMessage: String='';
  public selectedAdditionalDocFile: any;
  public pattern='^[a-zA-Z0-9. ]*$';
  public hrLeftMargin;
  public hrWidth;
  public selectedFile: any;
  public currentRole: string;
  public affinityNameFlag = false;
  public affinityNameMessage = '';
  public temporalAffinityName: any;
  public editAffinityName: any;
  public walletLimitFlag = true;
  public attachedDocumentLabelFlag = true;
  public actionFailedFlag = false;
  public actionFailedMessage = '';
  public documentDeleteList = [];
  public logoFile = '';
  public logoUploaded: Boolean = false
  public dataArray = [];
  public loadingmessage = '';
  public paymentArray = [];
  public lobArray = [];
  public affinityLobArray= [];
  public paymentOptionsFlag = false;
  public productOptionFalg = false;
  public originalGatewayArray: any;
  public ValidDocCode: any;
  public 
  @ViewChildren(TextBoxComponent) private textFields: any;
  @ViewChildren(SelectComponent) private selectFields: any;
  @ViewChildren(DatePickerComponent) private dateFields: any;
  @ViewChild('grid') grid: GridComponent;
  @ViewChild('formWrapper') formWrapperRef: ElementRef;
  @ViewChild('documentLabel') documentLabelField: TextBoxComponent;
  @ViewChildren(MultiselectComponent) private multiselectFields: any;
  constructor(private proposalServices: ProposalServices, private router: Router, private route: ActivatedRoute,
    private affinityService: AffinityService, private datePipe: DatePipe, private paymentService: PaymentService) {

  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);
  }


  ngAfterViewInit() {
    this.onResize();
  }

  ngOnChanges(changes: any) {
    this.validationAffinity();
  }
  ngOnInit() {
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.initialize();
    this.loadPaymentOptions();
    this.prepareDocActionColumns();
    this.prepareDocColumnsDatas();
    this.getLobList();
    this.affinityVO.affintyDocuments = [];
    this.affinityVO.paymentResource = [];
    this.affinityVO.affinityLobResource =  [];
    this.isupdate = false;

    this.currentRole = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];

    if (sessionStorage.getItem('USER_AFFINITY')) {
      const selectedAffinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
      this.isupdate = true;
      EmitterService.get('ENABLE_MENU').emit(true);

      this.proposalServices.getAffinityDetailForEdit(selectedAffinity.id).then(data => {
        this.proposalServices.getAffinityPaymentDetails().then(data => {
          this.originalGatewayArray = JSON.parse(JSON.stringify(data));
       //   this.paymentService.getPaymentGatewayList().then(productGateway => {
         //   this.dataArray = productGateway;
            for (const paymentData of this.dataArray) {
              for (const gatewayData of this.originalGatewayArray) {
                if (gatewayData['gatewayId'] && paymentData['gatewayId'] && String(gatewayData['gatewayId']) === String(paymentData['gatewayId'])) {
                  paymentData['Checked'] = true;
                  paymentData['paymentMappingId'] = gatewayData['paymentMappingId'];
                  this.onChangeMethods(paymentData);
                } else if (gatewayData['walletId'] && paymentData['walletId'] && String(gatewayData['walletId']) === String(paymentData['walletId'])) {
                  paymentData['Checked'] = true;
                  paymentData['paymentMappingId'] = gatewayData['paymentMappingId'];
                  this.onChangeMethods(paymentData);
                }
              }
            }
            
        //  });
          this.affinityService.getAffinityLob(selectedAffinity.id).then(dataRes =>{
            const selectedVal = dataRes;
            for (const selectedLob of selectedVal){
              for(const avaialbeLob of this.lobArray) {
                if(selectedLob['lobId'] === avaialbeLob['lobId']) {
                  avaialbeLob['Checked'] = true;
                  avaialbeLob['affinityLobMappingId'] = selectedLob['affinityLobMappingId'];
                  this.onChangeProductSelect(avaialbeLob);
                }
              }
            }
          }).catch(error =>{

          });
          this.proposalServices.getAffinityDocDetailForEdit(selectedAffinity.id, 0 , 0).then(editDocData => {
            this.document = [];
            this.grid.TotalRows = editDocData['totalRecords'];
            for (const document of editDocData['affinityDocumentList']) {
              const displayRowData: any = [];
              displayRowData['affinityDocumentId'] = document['affinityDocumentId'];
              displayRowData['Uploaded Files'] = document['affinityDocumentName'];
              displayRowData['Document Label'] = document['affinityDocumentLocation'];
             // displayRowData['Upload Date & Time'] =  document['creationTimeStamp'];
              const dateTime = document['creationTimeStamp'];
              const uploadedDate: any = this.datePipe.transform(dateTime, 'dd/MM/yyyy HH:mm:ss');
              displayRowData['Upload Date & Time'] = uploadedDate;
              displayRowData['affinityID'] = document['affinityID'];
              this.document.push(displayRowData);
            }
            const pagenationObj = new Object();
            pagenationObj['PageNumber'] = 0;
            pagenationObj['NumberOfRecords'] = this.grid.currentpageperview;
            pagenationObj['RecordCount'] = 1;
            pagenationObj['StartRecordCount'] = 1;
            this.showData(pagenationObj);
            this.grid.setcurrentpage(0);
        }).catch(error => {
       //   this.openActionFailDialog('Affinity data could not be fetched');
         // this.loadingmessage = 'Please wait...';
              setTimeout(() => {
         this.serviceDownMessage();
       }, 10000);
        });
      }).then(error =>{

      });
   
        this.affinityID = selectedAffinity.id;
        this.affinityVO.name = data.affinityName;
        this.editAffinityName = data.affinityName;
        this.affinityVO.agentId = data.agentId;
        this.affinityVO.issuingState = data.issueStateId;
        this.affinityVO.ssoUser = data.ssoflag;
        this.affinityVO.minimumWalletLimit = data.minWalletLimit;
        this.affinityVO.walletLimit = data.walletLimit;
        this.affinityVO.agentName = data.agentName;
        this.affinityVO.agentContactNumber = data.agentContactNumber;
        if (data.affinityLogo !== undefined && data.affinityLogo !== '' && data.affinityLogo !== null ) {
          this.affinityVO.affinityLogo = data.affinityLogo;
          this.logoUploaded = false;
          this.isvalue = true;
          this.isdeletefile = true;
          this.values = data.affinityLogo;
        } else {
          this.isvalue = false;
          this.isdeletefile = false;
        }
        this.enableSubmit = true;
        this.affinityNameFlag = true;
        
      }).catch(error => {
      //  this.openActionFailDialog('Affinity data could not be fetched');
     // this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
      });
      this.router.routeReuseStrategy.shouldReuseRoute = function () {
        return false;
      };
    } else if (this.currentRole === 'Underwriter') {
      this.router.navigateByUrl('welcome/(contentBody:affinity)');
    }
    EmitterService.get('DISABLE_FOOTER').emit(true);
    EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
  }
  public initialize() {
    this.affinityVO = new AffinityVO();
    this.affinityVO.documentVO = new DocumentVO();
    this.affinityVO.documentVO.UploadedFile = null;
    this.affinityVO.documentVO.DocumentLabel = '';
  }
  public ssoIDforBranchUser(str) {
    if (str === true) {
      this.affinityVO.ssoUser = true;
    } else {
      this.affinityVO.ssoUser = false;
    }

  }

  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  public checkNameValidity() {
    this.affinityNameMessage = '';
    const _this = this;
    if (this.temporalAffinityName) {
      clearTimeout(_this.temporalAffinityName);
    }
    if (this.editAffinityName === this.affinityVO.name) {
      this.affinityNameMessage = '';
      this.affinityNameFlag = true;
      this.outSidevalidate();
      return;
    }
    if (this.temporalAffinityName) {
      clearTimeout(_this.temporalAffinityName);
    }
    this.temporalAffinityName = setTimeout(function () {
      if (_this.affinityVO.name !== '') {
      _this.affinityService.checkAffinityName(_this.affinityVO.name).then(data => {
        _this.affinityNameFlag = false;
        _this.affinityNameMessage = 'Affinity already exists';
        _this.outSidevalidate();
      })
        .catch(error => {
          if (String(error.status) === '404') {
            _this.affinityNameFlag = true;
            _this.affinityNameMessage = '';
            _this.outSidevalidate();
          } else {
            _this.affinityNameFlag = false;
            _this.affinityNameMessage = 'Error in recieving status';
            _this.outSidevalidate();
          }
        });
      }
    }, 2000);
  
  }

  prepareDocColumnsDatas() {
    const col1: Object = new Object();
    col1['colName'] = 'Uploaded Files';
    this.columnsDatas.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Document Label';
    this.columnsDatas.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Upload Date & Time';
    this.columnsDatas.push(col3);
  }

  public prepareDocActionColumns() {
    this.actionColumnsDatas = [];
    this.actionColumnsDatas.push('DELETE');
  }
  fileSelectionEvent(fileInput: any) {
    this.selectedFile = fileInput.target.files[0];
    this.affinityVO.documentVO.UploadedFile = this.selectedFile.name;
    this.isAddvalue = true;
    this.isdeleteADDfile = true;
    this.valuesAdditional = this.selectedFile.name;
    this.selectedAdditionalDocFile = new Object();
    this.selectedAdditionalDocFile['fileData'] = fileInput.target.files[0];
    this.selectedAdditionalDocFile['completeFileName'] = fileInput.target.files[0].name;
    this.selectedAdditionalDocFile['fileName'] = this.selectedAdditionalDocFile['completeFileName'];
    const nameSegmentLength = this.selectedAdditionalDocFile['completeFileName'].split('.').length;
    const selectedFileExtension = this.selectedAdditionalDocFile['completeFileName'].split('.')[nameSegmentLength - 1];

    if (selectedFileExtension !== 'xlsx' && selectedFileExtension !== 'xls' && selectedFileExtension !== 'pdf') {
      this.fileSelectionValidationMessage = 'Invalid File Type.Only PDF & Excel File Type Is Allowed';
    } else {
      this.fileSelectionValidationMessage = '';
    }
    if(this.fileSelectionValidationMessage==='')
    {
      if(!this.selectedAdditionalDocFile['completeFileName'].match(this.pattern))
      {
        this.fileSelectionValidationMessage = 'Invalid File Name.Special Characters Are Not Allowed.';
      }
      else{
        this.fileSelectionValidationMessage = '';
      }
    }
  }


  fileSelection(fileInput: any) {
    this.isvalue = true;
    this.isdeletefile = true;
    const fileObj = new Object();
    fileObj['fileValues'] = fileInput.target.files;
    const v: String = fileInput.target.files[0].name;
    this.logoFile = fileInput.target.files[0];
    this.logoUploaded = true; 
    this.values = v;

    this.affinityVO.documentVO.UploadedFile = v;
    this.affinityVO.affinityLogo = v;
    fileObj['fileName'] = v.split('.')[0];
    const extension = v.split('.')[1];

    this.validateAttachedDocumentName();
    if (extension === 'jpeg' || extension === 'JPEG' || extension === 'jpeg' || extension === 'PNG' || extension === 'png' || extension === 'jpg' || extension === 'JPG') {
      this.validationMessage = '';
    } else {
      this.validationMessage = 'Please upload valid file type (.jpeg , .png)';
    }
    this.totalfiles.push(fileObj);
    this.outSidevalidate();
  }

  public validateAttachedDocumentName() {
    this.validationMessage2 = '';
    this.attachedDocumentLabelFlag = this.documentLabelField.onBlurMethod(false);
    if (this.affinityVO.documentVO.UploadedFile && this.affinityVO.documentVO.DocumentLabel) {
      let flag = false;
      for (const additionalDocument of this.AdditionalProjectData) {
        if (String(this.affinityVO.documentVO.UploadedFile) === String(additionalDocument['Uploaded Files']) && String(this.affinityVO.documentVO.DocumentLabel) === String(additionalDocument['Document Label'])) {
          flag = true;
          break;
        }
      }
      if (flag) {
        this.validationMessage2 = 'Document with the same name already exits';
      }
    }
    this.outSidevalidate();
  }

  AttachDoc(status) {
    
    this.validationMessage2 = '';
    if (this.affinityVO.documentVO.DocumentLabel && this.affinityVO.documentVO.UploadedFile) {

      let documentFlag = true;
   //   if (!status) {
        for (const doc of this.document) {
          if (this.affinityVO.documentVO.UploadedFile === doc['Uploaded Files'] && this.affinityVO.documentVO.DocumentLabel === doc['Document Label']) {
            documentFlag = false;
            break;
          }
        }
    //  }

      if (documentFlag) {
        const currentDate = new Date();
        const currentTime = new Date().toLocaleTimeString();
        // const dateTime = currentDate + ' ' + currentTime;
        const dateTime = this.datePipe.transform(currentDate, 'dd/MM/yyyy HH:mm:ss');
        const displayRowData: any = [];
        displayRowData['Uploaded Files'] = this.affinityVO.documentVO.UploadedFile;
        displayRowData['Document Label'] = this.affinityVO.documentVO.DocumentLabel;
        displayRowData['Upload Date & Time'] = dateTime;
        displayRowData['File'] = this.selectedFile;
        const fileData: any = new FormData();
        fileData.append('file', displayRowData['File'], displayRowData['Uploaded Files']);
        this.proposalServices.validateDocuments(fileData).then(data => {
         this.ValidDocCode=data.status;
        if(this.ValidDocCode === 200 && data['_body']['validationMessage'].length==0){
        this.document.unshift(displayRowData);

        this.affinityVO.documentVO.UploadedFile = '';
        this.affinityVO.documentVO.DocumentLabel = '';

        this.grid.TotalRows = this.document.length;
        const pagenationObj = new Object();
        pagenationObj['PageNumber'] = 0;
        pagenationObj['NumberOfRecords'] = this.grid.currentpageperview;
        pagenationObj['RecordCount'] = 1;
        pagenationObj['StartRecordCount'] = 1;
        this.showData(pagenationObj);
        this.grid.setcurrentpage(0);
        this.deleteAddFile();
        }
        else{
          this.validationMessage2 = data['_body']['validationMessage'];;
        }
      }).catch(error =>{
      
      });
      } else {
        this.validationMessage2 = 'Document with the same name\nalready exits';
      }
    }
    this.outSidevalidate();
  }

  showData(data: any) {
    const PageNumber = data.PageNumber;
    const RecordCount = data.RecordCount;
    const NumberOfRecords = data.NumberOfRecords;
    const StartRecordCount = data.StartRecordCount;

    this.AdditionalProjectData = [];
    for (let i = PageNumber * NumberOfRecords; i <= (((PageNumber * NumberOfRecords + NumberOfRecords) > this.document.length - 1) ? this.document.length - 1 : PageNumber * NumberOfRecords + NumberOfRecords - 1); i++) {
      const displayRowData: Object = new Object();
      displayRowData['Uploaded Files'] = this.document[i]['Uploaded Files'];
      displayRowData['Document Label'] = this.document[i]['Document Label'];
      displayRowData['Upload Date & Time'] = this.document[i]['Upload Date & Time'];
      if (this.document[i]['affinityDocumentId']) {
        displayRowData['affinityDocumentId'] = this.document[i]['affinityDocumentId'];
      }
      this.AdditionalProjectData.push(displayRowData);
    }
  }

  action(data) {
    if (data['affinityDocumentId']) {
      this.documentDeleteList.push(data['affinityDocumentId']);
    }
    for (let i = 0; i < this.document.length; i++) {
      if (String(data['Uploaded Files']) === String(this.document[i]['Uploaded Files']) &&
        String(data['Document Label']) === String(this.document[i]['Document Label']) &&
        String(data['Uploaded Date & Time']) === String(this.document[i]['Uploaded Date & Time'])) {
        this.document.splice(i, 1);
        this.grid.TotalRows = this.document.length;
        this.grid.getNoOfPages();
        const pagenationObj = new Object();
        pagenationObj['PageNumber'] = (this.grid.noOfPages - 1 < this.grid.currentpage) ? this.grid.noOfPages - 1 : this.grid.currentpage - 1;
        pagenationObj['NumberOfRecords'] = this.grid.currentpageperview;
        pagenationObj['RecordCount'] = 1;
        pagenationObj['StartRecordCount'] = 1;
        this.grid.setcurrentpage(pagenationObj['PageNumber']);
        this.showData(pagenationObj);
        break;
      }
    }
  }

  deleteFile() {
    this.isdeletefile = false;
    this.values = null;
    this.isvalue = false;
    this.validationMessage = '';
    this.affinityVO.affinityLogo = '';
    this.outSidevalidate();
  }

  deleteAddFile() {
    this.isdeleteADDfile = false;
    this.valuesAdditional = null;
    this.isAddvalue = false;
    this.isdeleteADDfile = false;
    this.affinityVO.documentVO.DocumentLabel = '';
    this.affinityVO.documentVO.UploadedFile = '';
    this.validationMessage2 = '';
    this.documentLabelField.validationMessage = '';
    this.fileSelectionValidationMessage='';
    const _this = this;
    setTimeout(function () {
      _this.outSidevalidate();
    });
  }

  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }
  public setAffinity(affinityName: any) {
    this.selectedAffinity = affinityName;
  }
  public createNewAffinityCode() {
    this.createNewAffinity = true;
  }
  public navigateToLanding() {

    if (this.selectedAffinity === 1) {
      this.selectedAffinityName = 'MANAPPRUM';
    } else if (this.selectedAffinity === 2) {
      this.selectedAffinityName = 'MUTHOOT';
    }

    EmitterService.get('ENABLE_MENU').emit(this.selectedAffinityName);
    const url = MAIN_PAGE_PART_URL + LANDING + ')';
    sessionStorage.setItem('LOGGEDIN_AFFINITY', this.selectedAffinityName);
    this.router.navigateByUrl(url);
  }

  checkIfWalletLimitEntered() {
    this.walletValidationMessage = '';
    this.walletLimitFlag = true;
    if (this.affinityVO.walletLimit && this.affinityVO.minimumWalletLimit) {
      if (Number(this.affinityVO.walletLimit) < Number(this.affinityVO.minimumWalletLimit)) {
        this.walletValidationMessage = 'Wallet Limit should  not be lesser than Minimum Wallet Limit';
        this.walletLimitFlag = false;
      }
    } else if (!this.affinityVO.walletLimit && this.affinityVO.minimumWalletLimit) {
      this.walletValidationMessage = 'Wallet Limit should  not be lesser than Minimum Wallet Limit';
      this.walletLimitFlag = false;
    }
  }

  public saveAffinity() {
    EmitterService.get('SHOW_SPINNER').emit(true);
    this.isAddvalue = false;
    this.getIssueStateDesc(this.affinityVO.issuingState);
    this.topFunction();
    
    for (const additionalDoc of this.document) {
      // Document exclusion for edit affinity save
      if (additionalDoc['affinityDocumentId']) {
        continue;
      }
      //
      const additionalFile = new Object();
      const nameSegmentCount = String(additionalDoc['Uploaded Files']).split('.').length;
      additionalFile['affinityDocumentName'] = String(additionalDoc['Uploaded Files']);
      additionalFile['affinityDocumentType'] = String(additionalDoc['Uploaded Files']).split('.')[nameSegmentCount - 1];
      additionalFile['isLogo'] = false;
      this.affinityVO.affintyDocuments.push(additionalFile);
    }

    if (this.affinityVO.affinityLogo !== '') {
      const nameSegmentCount = String(this.affinityVO.affinityLogo).split('.').length;
      const additionalFile = new Object();
      additionalFile['affinityDocumentName'] = this.affinityVO.affinityLogo;
      additionalFile['affinityDocumentType'] = String(this.affinityVO.affinityLogo).split('.')[nameSegmentCount - 1];
      additionalFile['isLogo'] = true;
      this.affinityVO.affintyDocuments.push(additionalFile);
    }
    const affinitySavedata: Object = new Object();
    affinitySavedata['affinityDetails'] = new Object();
    affinitySavedata['affinityDetails']['affinityName'] = this.affinityVO.name;
    affinitySavedata['affinityDetails']['affinityDescription'] = this.affinityVO.name;
    affinitySavedata['affinityDetails']['agentId'] = this.affinityVO.agentId;
    affinitySavedata['affinityDetails']['affinityLogo'] = this.affinityVO.affinityLogo;
    affinitySavedata['affinityDetails']['agentName'] = this.affinityVO.agentName;
    affinitySavedata['affinityDetails']['agentContactNumber'] = this.affinityVO.agentContactNumber;
    if (this.isupdate) {
      affinitySavedata['affinityDetails']['affinityId'] = this.affinityID;
    }

    if (this.affinityVO.ssoUser) {
      affinitySavedata['affinityDetails']['ssoflag'] = this.affinityVO.ssoUser;
    } else {
      affinitySavedata['affinityDetails']['ssoflag'] = false;
    }
    affinitySavedata['affinityDetails']['active'] = 'true';
    affinitySavedata['affinityDetails']['issueStateId'] = this.affinityVO.issuingState;
    affinitySavedata['affinityDetails']['issueStateDescription'] = this.affinityVO.issueStateDescription;
    affinitySavedata['affinityDetails']['walletLimit'] = this.affinityVO.walletLimit;
    affinitySavedata['affinityDetails']['minWalletLimit'] = this.affinityVO.minimumWalletLimit;
    affinitySavedata['affinityDetails']['customerCareEmail'] = this.affinityVO.customerCareEmail;
    affinitySavedata['affinityDetails']['customerCareMobile'] = this.affinityVO.customerCareMobile;
    affinitySavedata['affinityDetails']['affinityDocuments'] = new Object();
    affinitySavedata['affinityDetails']['affinityDocuments'] = this.affinityVO.affintyDocuments;
    affinitySavedata['affinityDetails']['paymentMapping'] = new Object();
    affinitySavedata['affinityDetails']['paymentMapping'] = this.affinityVO.paymentResource;


    const affinityData = JSON.stringify(affinitySavedata);
    this.proposalServices.saveAffinityData(affinityData).then(data => {
      this.selectedAffinityName = data.affinityName;
      const affinityId = data.affinityId;
      for (const paymentOption of this.paymentArray) {
        if (paymentOption['Checked']) {
          const gatewayObj = new Object();
          gatewayObj['gatewayId'] = paymentOption['gatewayId'] ? paymentOption['gatewayId'] : 0;
          gatewayObj['walletId'] = paymentOption['walletId'] ? paymentOption['walletId'] : 0;
          gatewayObj['affinityId'] = affinityId;
          gatewayObj['isChecked'] = paymentOption['Checked'];
          if(paymentOption['paymentMappingId'] !== undefined){
            gatewayObj['paymentMappingId'] = paymentOption['paymentMappingId'];
          }
          this.affinityVO.paymentResource.push(gatewayObj);
        } else if (!paymentOption['Checked'] && paymentOption['paymentMappingId'] !== undefined && paymentOption['paymentMappingId'] !== '') {
            const gatewayObj = new Object();
            gatewayObj['gatewayId'] = paymentOption['gatewayId'] ? paymentOption['gatewayId'] : 0;
            gatewayObj['walletId'] = paymentOption['walletId'] ? paymentOption['walletId'] : 0;
            gatewayObj['affinityId'] = affinityId;
            gatewayObj['isChecked'] = paymentOption['Checked'];
            if(paymentOption['paymentMappingId'] !== undefined){
              gatewayObj['paymentMappingId'] = paymentOption['paymentMappingId'];
            }
            this.affinityVO.paymentResource.push(gatewayObj);

        }
      }
     const affinitySavedata: Object = new Object();
    affinitySavedata['affinityDetails'] = new Object();
    affinitySavedata['affinityDetails']['paymentMapping'] = new Object();
    affinitySavedata['affinityDetails']['paymentMapping'] = this.affinityVO.paymentResource;
      this.proposalServices.saveAffinityPayment(affinitySavedata).then(docData => {
      }).then(error =>{
        
      });
      for (const affnityLob of this.affinityLobArray ){
        const lobObject:Object = new Object();
        if (affnityLob['affinityLobMappingId'] !== undefined && affnityLob['affinityLobMappingId'] !==''){
          lobObject['affinityLobMappingId'] = affnityLob['affinityLobMappingId'];
        } else {
          lobObject['affinityLobMappingId']='';
        }
          lobObject['isChecked'] = affnityLob['Checked'];
          lobObject['affinityId'] = affinityId;
          lobObject['lobId'] = affnityLob['lobId'];
        //  lobObject['lobDescription'] = affnityLob['lobDescription'];
          this.affinityVO.affinityLobResource.push(lobObject);
        
      }
      affinitySavedata['affinityDetails'] = new Object();
      affinitySavedata['affinityDetails']['affinityLob'] = new Object();
      affinitySavedata['affinityDetails']['affinityLob'] = this.affinityVO.affinityLobResource;
      this.affinityService.saveAffinityProducts(affinitySavedata).then(data => {
      }).catch(error =>{

      });

      let isHealth: Boolean = false;
      let isHome: Boolean = false;
      let isShop: Boolean = false;
      let isInboxPage: Boolean = false;
      for (const avalLob of this.affinityLobArray) {
        if (avalLob['lobName'].toLowerCase() === 'home' && avalLob['Checked'] === true) {
          isHome = true;
        } else if (avalLob['lobName'].toLowerCase() === 'health' && avalLob['Checked'] === true) {
          isHealth = true;
        } else if (avalLob['lobName'].toLowerCase() === 'shop owners' && avalLob['Checked'] === true) {
          isShop = true;
        } else if (avalLob['lobName'].toLowerCase() === 'inbox page' && avalLob['Checked'] === true) {
          isInboxPage = true;
        }
      }
      this.affinityService.userMenuUpdates(affinityId,isHealth,isHome,isShop,isInboxPage).then(dataMenuValues => {
       
       // EmitterService.get('MENU_UPDATE_EVENT').emit(true);
       // EmitterService.get('ENABLE_MENU').emit(true);
      //  EmitterService.get('AFFINITY_SELECT_EVENT').emit(true);
      //   EmitterService.get('ENABLE_MENU').emit(this.selectedAffinityName);
      // EmitterService.get('AFFINITY_SELECT_EVENT').emit(true)
      sessionStorage.setItem('MENU_UPDATE_EVENT', 'true');
      EmitterService.get('MENU_UPDATE_EVENT').emit(true);
      }).catch(error =>{

      });
      const documentData = new Object();
      const fileNameType = [];
      // Document exclusion for edit affinity save
      for (const additionalDoc of this.document) {
        if (additionalDoc['affinityDocumentId']) {
          continue;
        }
      //
        const additionalFile = new Object();
        const nameSegmentCount = String(additionalDoc['Uploaded Files']).split('.').length;
        additionalFile['affinityDocumentName'] = String(additionalDoc['Uploaded Files']);
        additionalFile['affinityDocumentType'] = String(additionalDoc['Uploaded Files']).split('.')[nameSegmentCount - 1];
        additionalFile['affinityID'] =  data.affinityId;
        additionalFile['affinityDocumentLocation'] =  String(additionalDoc['Document Label']);
        fileNameType.push(additionalFile);
      }
      documentData['affinityDocuments'] = fileNameType;
  //    formData.append('file', fileNameType);
      this.proposalServices.saveDocuments(documentData).then(docData => {
        EmitterService.get('SHOW_SPINNER').emit(false);

      this.hasSuccess = true;
      this.enableSubmit = false;
      const affinity = new Object();
      affinity['name'] = data.affinityName;
      affinity['id'] = data.affinityId;
      affinity['issueState'] = data.issueStateId;
      affinity['issuestateDesc'] = data.issueStateDescription;
      affinity['agentId'] = data.agentId;
      affinity['affinityLogo'] = data.affinityLogo;
      affinity['agentName'] = data.agentName;
      affinity['agentContactNumber'] = data.agentContactNumber;
      sessionStorage.setItem(USER_AFFINITY, JSON.stringify(affinity));
      const formData: any = new FormData();
      for (let i = 0; i < this.document.length; i++) {
        if (this.document[i]['affinityDocumentId'] === undefined) {
          formData.append('file', this.document[i]['File'], this.document[i]['Uploaded Files']);
        }
      }
      if (this.logoUploaded && this.affinityVO.affinityLogo !== '' && this.affinityVO.affinityLogo !== undefined) {
        formData.append('file', this.logoFile, 'Logo_' + this.affinityVO.affinityLogo);
      }
      this.proposalServices.uploadDocumentsInS3(data.affinityId, formData).then(data2 => {
  EmitterService.get('ENABLE_MENU').emit(this.affinityVO.name);
      const affinityDemoName: any = this.affinityVO.name;
      sessionStorage.setItem('LOGGEDIN_AFFINITY', affinityDemoName);
      const url = MAIN_PAGE_PART_URL + LANDING + ')';
      EmitterService.get('AFFINITY_SELECT_EVENT').emit(true);
      }).catch( error => {
      //  this.openActionFailDialog('Failed to upload documnet in S3');
     //   this.serviceDownMessage();
      });
    }).catch(error => {
     // this.openActionFailDialog('Affinity could not be saved');
     setTimeout(() => {
       this.serviceDownMessage();
     }, 10000);
    });

    // Document delete snippet for edit
    if (this.documentDeleteList && this.documentDeleteList.length > 0) {
      this.affinityService.deleteAffinityDocument(this.affinityID, this.documentDeleteList).then(data => {
      })
      .catch(error => console.log('Document delete failed.'));
    }
    }).catch(error => {
     // this.openActionFailDialog('Affinity could not be saved');
     setTimeout(() => {
       this.serviceDownMessage();
     }, 10000);
    });
  }

  public validationAffinity() {

    if (this.validationMessage === '') {

      if ((this.affinityVO.name !== '' || this.affinityVO.name !== undefined) &&
        (this.affinityVO.agentId !== '' || this.affinityVO.agentId !== undefined) &&
        (this.affinityVO.walletLimit !== undefined) &&
        (this.affinityVO.minimumWalletLimit !== undefined)) {

        this.enableSubmit = true;

      }

    }

  }

  public walletCheck() {
    this.walletValidationMessage = '';
    if (this.affinityVO.walletLimit === undefined && this.affinityVO.minimumWalletLimit === undefined) {
      return true;
    } else if (this.affinityVO.walletLimit !== undefined && this.affinityVO.minimumWalletLimit !== undefined) {
      if (this.affinityVO.walletLimit < this.affinityVO.minimumWalletLimit) {
        this.walletValidationMessage = 'Wallet Limit should be greater than minimumWalletLimit ';
        return false;
      } else {
        return true;
      }
    } else if (this.affinityVO.minimumWalletLimit && !this.affinityVO.walletLimit) {
      this.walletValidationMessage = 'Please enter wallet amount!';
    }
  }

  public cancelAffinity() {
    this.topFunction();
    if (sessionStorage.getItem('USER_AFFINITY')) {
      const selectedAffinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
      this.isupdate = true;

      this.affinityVO = new AffinityVO();
       this.affinityVO.documentVO = new DocumentVO();
      this.document = [];
      this.AdditionalProjectData = [];
      this.deleteAddFile();
      this.deleteFile();
      this.walletValidationMessage = '';
      this.validationMessage2 = '';
      this.validationMessage = '';

      this.proposalServices.getAffinityDetailForEdit(selectedAffinity.id).then(data => {
        this.proposalServices.getAffinityPaymentDetails().then(data => {

        }).then(error =>{
        });
        this.proposalServices.getAffinityDocDetailForEdit(selectedAffinity.id, 0 , 0).then(editDocData => {
          this.document = [];
          this.grid.TotalRows = editDocData['totalRecords'];
          for (const document of editDocData['affinityDocumentList']) {
            const displayRowData: any = [];
            displayRowData['affinityDocumentId'] = document['affinityDocumentId'];
            displayRowData['Uploaded Files'] = document['affinityDocumentName'];
            displayRowData['Document Label'] = document['affinityDocumentLocation'];
            const dateTime = document['creationTimeStamp'];
            const uploadedDate: any = this.datePipe.transform(dateTime, 'dd/MM/yyyy HH:mm:ss');
            displayRowData['Upload Date & Time'] = uploadedDate;
            displayRowData['affinityID'] = document['affinityID'];
            this.document.push(displayRowData);
          }
          const pagenationObj = new Object();
          pagenationObj['PageNumber'] = 0;
          pagenationObj['NumberOfRecords'] = this.grid.currentpageperview;
          pagenationObj['RecordCount'] = 1;
          pagenationObj['StartRecordCount'] = 1;
          this.showData(pagenationObj);
          this.grid.setcurrentpage(0);
      }).catch(error => {
       // this.openActionFailDialog('Affinity data could not be fetched');
       setTimeout(() => {
         this.serviceDownMessage();
       }, 10000);
      });
        this.affinityID = selectedAffinity.id;
        this.affinityVO.name = data.affinityName;
        this.affinityVO.agentId = data.agentId;
        this.affinityVO.issuingState = data.issueStateId;
        this.affinityVO.ssoUser = data.ssoflag;
        this.affinityVO.minimumWalletLimit = data.minWalletLimit;
        this.affinityVO.walletLimit = data.walletLimit;
        this.affinityVO.agentContactNumber = data.agentContactNumber;
        if (data.affinityLogo !== undefined && data.affinityLogo !== '' && data.affinityLogo !== null ) {
          this.affinityVO.affinityLogo = data.affinityLogo;
          this.isvalue = true;
          this.isdeletefile = true;
          this.values = data.affinityLogo;
        } else {
          this.isvalue = false;
          this.isdeletefile = false;
        }
        this.enableSubmit = true;
        const _this = this;
        setTimeout(function () { EmitterService.get('SHOW_VALIDATION_MESSAGE').emit(true); });
      }).catch(error => {
     //   this.openActionFailDialog('Affinity data could not be fetched');
     setTimeout(() => {
       this.serviceDownMessage();
     }, 10000);
      });
    } else {
      this.router.navigateByUrl(this.router.url);
    }

  }

  public validate(file: File) {
  }

  public getIssueStateDesc(stateId: any) {
    const stateDesc = sessionStorage.getItem('issuingState');
    if (stateDesc) {
      const stateTypeArryObj: Array<Object> = JSON.parse(stateDesc);
      if (stateTypeArryObj) {
        for (const stateType of stateTypeArryObj) {
          if (stateType[ISSUING_STATE] === stateId) {
            this.affinityVO.issueStateDescription = stateType[ISSUING_STATE_DESCRIPTION];
            return this.affinityVO.issueStateDescription;
          }
        }
      }
    }
  }

  private outSidevalidate() {
    let textResult: any = false;
    let selectResult: any = false;
    let dateResult: any = false;
    let hasTextField: Boolean = false;
    let hasSelectField: Boolean = false;
    let hasDateField: Boolean = false;
    let hasMultiselectField: Boolean = false;
    let multiselectResult: any = true;
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        break;
      }
    }
    for (const selectField of this.selectFields['_results']) {
      hasSelectField = true;
      const input: SelectComponent = selectField;
      selectResult = input.onBlurMethod(false);
      if (!selectResult) {
        break;
      }
    }
    for (const dateField of this.dateFields['_results']) {
      hasDateField = true;
      const input: DatePickerComponent = dateField;
      dateResult = input.onBlurMethod(false);
    }
    for (const multiselectField of this.multiselectFields['_results']) {
      hasMultiselectField = true;
      const input: MultiselectComponent = multiselectField;
      multiselectResult = input.onBlurMethod(false);
      if (!multiselectResult) {
        break;
      }
    }
    const result: any =
      (textResult || !hasTextField) &&
      (selectResult || !hasSelectField) &&
      (multiselectResult || !hasMultiselectField) &&
      (dateResult || !hasDateField) && !this.validationMessage && this.affinityNameFlag && this.walletLimitFlag && !this.validationMessage2 && this.paymentOptionsFlag && this.productOptionFalg;
    if (result) {
      this.enableSubmit = true;
      return true;
    } else {
      this.enableSubmit = false;
      return false;
    }
  }
  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  public loadPaymentOptions() {
    
    this.paymentService.getPaymentGatewayList().then(data => {
      this.dataArray = data;
    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
      }
    );

  }

  onChangeMethods(data: any) {
    this.paymentArray = [];
    for (let i = 0; i < this.dataArray.length; i++) {
      if (data['gatewayName'] === 'Offline') {
        if (this.dataArray[i]['Checked'] && this.dataArray[i]['gatewayName'] === 'Offline') {
          this.paymentArray.push(this.dataArray[i]);
        } else if (this.dataArray[i]['paymentMappingId'] !== undefined ||this.dataArray[i]['paymentMappingId'] === '') {
          this.dataArray[i]['Checked'] = false;
          this.paymentArray.push(this.dataArray[i]);
        }
        else {
          this.dataArray[i]['Checked'] = false;
        }
      } else {
        if (this.dataArray[i]['gatewayName'] !== 'Offline') {
          if (this.dataArray[i]['Checked']) {
            this.paymentArray.push(this.dataArray[i]);
          } else if (!this.dataArray[i]['Checked'] && this.dataArray[i]['paymentMappingId'] !== undefined ||this.dataArray[i]['paymentMappingId'] === '') {
            this.paymentArray.push(this.dataArray[i]);
          }
        } else {
          if (this.dataArray[i]['paymentMappingId'] !== undefined ||this.dataArray[i]['paymentMappingId'] === ''){
            this.dataArray[i]['Checked'] = false;
            this.paymentArray.push(this.dataArray[i]);
          }else {

            this.dataArray[i]['Checked'] = false;
          }
        }
      }
    }
    if (this.paymentArray.length > 0) {
      this.paymentOptionsFlag = true;
    } else {
      this.paymentOptionsFlag = false;
    }
    this.outSidevalidate();
  }

  onChangeProductSelect(data: any) {
    this.affinityLobArray = [];
    for (let i = 0; i < this.lobArray.length; i++) {
      if (this.lobArray[i]['Checked'] && this.lobArray[i]['affinityLobMappingId'] === undefined) {
        this.affinityLobArray.push(this.lobArray[i]);
      } else if (this.lobArray[i]['affinityLobMappingId'] !== undefined && this.lobArray[i]['affinityLobMappingId'] !== '') {
        this.affinityLobArray.push(this.lobArray[i]);
      }
    }
    if (this.affinityLobArray.length > 0) {
      this.productOptionFalg = true;
    } else {
      this.productOptionFalg = false;
    }
    this.outSidevalidate();
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      }
    );
    this.router.navigateByUrl('');
  }

  public getLobList() {
    this.proposalServices.getLob().then(data => {
      if (data !== undefined) {
        this.lobArray = data;
      }
      const inbox = new Object();
      inbox['lobId'] = 4;
      inbox['lobName']= 'Inbox Page';
      inbox['lobDescription'] = 'Inbox Page';
      this.lobArray.push(inbox);
    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });
  }

  public saveAffinityProducts() {
    
  }
}
