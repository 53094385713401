import { Component, OnInit, Output, Input, EventEmitter, OnChanges, Optional, Inject, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, NG_VALIDATORS, NG_ASYNC_VALIDATORS, NgModel } from '@angular/forms';
import { ElementBase } from 'src/accessor/element.base';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;
import {
  YEAR, DATE, MONTH
} from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: DatePickerComponent, multi: true }
  ]

})
export class DatePickerComponent extends ElementBase<string> implements OnChanges, OnInit {
  @Input() public min: any;
  @Input() public max: any;
  @Input() public required: any;
  @Input() public dateTimeType: any;
  @Input() public label: any;
  @Input() public id: any;
  @Input() public minRange: any;
  @Input() public maxRange: any;
  @Input() public childMinRange: any;
  @Input() public childMaxRange: any;
  @Output() public changeevent = new EventEmitter<Object>();
  @Output() public afterpickeropenevent = new EventEmitter<Object>();
  @Input() public validationMessage: any;
  @Input() public validationMessage2: any;
  @Input() public validationMessage3: any;
  @Input() public childValidationErrorMessage: String;
  @Input() public dateArray: Array<Object> = new Array<Object>();
  @Input() public monthArray: Array<Object> = new Array<Object>();
  @Input() public yearArray: Array<Object> = new Array<Object>();
  @Input() public startYear: any;
  @Input() public customValidationMessage: any;
  @Input() public disable: boolean;

  @Input() public endYear: any;
  @ViewChild(NgModel) model: NgModel;

  @Input()
  public YYYY: any;
  @Input()
  public selectedyear: any;
  @Input()
  public selectedMonth: any;
  @Input()
  public selectedDate: any;
  @Input()
  public childSelectedYear: any;
  @Input()
  public childSelectedMonth: any;
  @Input()
  public childSelectedDate: any;
  @Input()
  public subText: any;
  @Input()
  public notDynamic = false;

  @Input()
  public locallyRequired = false;

  public childDate: any;
  public age: Number = 0;
  public day: Number = 0;
  public date: any;
  public month: any;
  public year: Number;
  public selectedYear: String;
  public firstBlur = true;

  public  showDropDown: Boolean = false;
  public selectedDt: any = new Date();
  public selectedTime: any = new Date();

  @Input()
  public disableOnRenewal = false;
  public isRenewal = false;

  @Input()
  public disableOnEndorsement = false;



  constructor(
    @Optional()
    @Inject(NG_VALIDATORS)
    validators: Array<any>,
    @Optional()
    @Inject(NG_ASYNC_VALIDATORS)
    asyncValidators: Array<any>,
    private route: ActivatedRoute,
    private datePipe: DatePipe
  ) {
    super(validators, asyncValidators);
  }
  ngOnInit() {
    this.prepareDateArray();
    this.prepareMonthArray();
    this.prepareYearArray();
    EmitterService.get('SHOW_VALIDATION_MESSAGE').subscribe(data => {
      if (this.value) {
        this.onBlurMethod(true);
      }
    });
    EmitterService.get('HIDE_VALIDATION_MESSAGE').subscribe(data => {
      this.validationMessage = '';
      this.customValidationMessage = '';
      this.validationMessage2 = '';
      this.validationMessage3 = '';
    });
  }

  ngOnChanges(changes: any) {
    if (changes.selectedyear && changes.selectedMonth && changes.selectedDate) {
      if (changes.selectedyear.currentValue === undefined
        && changes.selectedMonth.currentValue === undefined
        && changes.selectedDate.currentValue === undefined) {
        this.selectedYear = YEAR;
        this.selectedMonth = MONTH;
        this.selectedDate = DATE;
      } else {
        this.selectedYear = changes.selectedyear.currentValue;
        this.selectedMonth = changes.selectedMonth.currentValue;
        this.selectedDate = changes.selectedDate.currentValue;
      }
    }
    if (changes.childSelectedYear && changes.childSelectedMonth && changes.childSelectedDate) {
      if (changes.childSelectedYear.currentValue === undefined
        && changes.childSelectedMonth.currentValue === undefined
        && changes.childSelectedDate.currentValue === undefined) {
        this.childSelectedYear = YEAR;
        this.childSelectedMonth = MONTH;
        this.childSelectedDate = DATE;
      } else {
        this.childSelectedYear = changes.childSelectedYear.currentValue;
        this.childSelectedMonth = changes.childSelectedMonth.currentValue;
        this.childSelectedDate = changes.childSelectedDate.currentValue;
      }
    }

    //   this.selectedYear = this.year;
  }

  public calculateAge(str) {
    if (str) {
      // const strdt: Date = new Date(str);
      const selectedDate: any = new Date(str);
      const timeDiff: any = Math.abs(Date.now() - selectedDate);
      const timeDiffDay = ((timeDiff / (1000 * 3600 * 24)) / 365.25);
      const timeDiffDayString = timeDiffDay.toString();
      const diffArray = timeDiffDayString.split('.');
      const days = '.' + diffArray[1];
      this.age = Math.floor((timeDiff / (1000 * 3600 * 24)) / 365.25);
      this.day = Math.floor(Number(days) * 365);
    }
  }

  public onBlurMethod(showError: any): boolean {
    if (showError) {
      this.validationMessage = '';
      if ((this.locallyRequired) && (this.value === undefined || this.value === null || !this.value)) {
        this.validationMessage = (this.label + ' is required');
      }
    }
    if (this.value && !this.year && !this.month && !this.date) {
      const s = new Date(this.value);
      this.year = s.getFullYear();
      this.month = (s.getMonth() + 1).toString();
      this.date = s.getDate().toString();
      if (this.month < 10) {
        this.month = '0' + this.month;
      }
      if (this.date < 10) {
        this.date = '0' + this.date;
      }
    }
    if ((this.required || this.required === 'true') && (this.value === undefined || this.value === null || !this.value)) {
      if (showError) {
        this.validationMessage = (this.label + ' is required');
      }
      return false;
    }
    if (this.minRange && this.maxRange && this.value) {
      if (this.value.match('Invalid date')) {
        this.validationMessage = 'Enter valid ' + this.label;
        return false;
      } else {
        this.calculateAge(this.value);
        if (this.age < this.minRange || this.age > this.maxRange) {
         // this.customValidationMessage = '';
          this.validationMessage = ('Enter ' + this.label + ' between ' + this.minRange + ' years and ' + this.maxRange + ' years');
          return false;
        } else if (this.age === 55 && this.day > 1) {
         // this.customValidationMessage = '';
          this.validationMessage = ('Enter ' + this.label + ' between ' + this.minRange + ' years and ' + this.maxRange + ' years');
          return false;
        } else if (this.age < 1) {
          if (this.day < 91) {
            this.validationMessage = ('Enter ' + this.label + ' between ' + 91 + ' days and ' + 18 + ' years');
            return false;
          }
        }
      }
      // if (this.customValidationMessage === 'This impacts premium, Please check the date range') {
      //   return false;
      // }
    }
    if (this.childMinRange && this.childMaxRange && this.value) {
      // this.validationMessage = '';
      if (this.value.match('Invalid date')) {
        this.validationMessage = 'Enter valid ' + this.label;
        return false;
      } else {
        this.calculateAge(this.value);
        //  this.validationMessage = '';
        if (this.age < 1) {
          const myDate = new Date(this.value);
          const today = new Date();
          if (myDate < today) {
            if (this.day < 91) {
              this.validationMessage = ('Enter ' + this.label + ' between ' + 91 + ' days and ' + 25 + ' years');
              return false;
            }
          } else {
            this.validationMessage = ('Enter ' + this.label + ' between ' + 91 + ' days and ' + 25 + ' years');
            return false;
          }
        } else if (this.age < this.childMinRange || this.age > this.childMaxRange) {
          this.validationMessage = ('Enter ' + this.label + ' between ' + 91 + ' days and ' + this.childMaxRange + ' years');
          return false;
        } else if (this.age === 25 && this.day >= 1) {
          this.validationMessage = ('Enter ' + this.label + ' between ' + 91 + ' days and ' + this.childMaxRange + ' years');
          return false;
        }
      }
    }
    return true;
  }
  public OnChange(data: any) {
    this.changeevent.emit(data);
  }

  public OnPickerOpen(data: any) {
    this.afterpickeropenevent.emit(data);
  }
  public prepareDateArray() {
    let values = 0;

    if (this.date === undefined) {
      this.dateArray = [];

      for (let i = 1; i <= 31; i++) {
        const dt: Object = new Object();
        dt['Code'] = (i < 10 ? '0' : '') + i;
        dt['Description'] = (i < 10 ? '0' : '') + i;
        this.dateArray.push(dt);
      }
    }


    if (this.month !== undefined) {
      if ((Number(this.month)) < 8) {
        if (((Number(this.month)) % 2 !== 0)) {
          values = 31;
        } else if ((Number(this.month) % 2) === 0 && (Number(this.month)) !== 2) {
          values = 30;
        } else if (
          ((Number(this.month)) === 2) && (Number(this.year) % 400 === 0 || Number(this.year) % 100 !== 0 && Number(this.year) % 4 === 0)) {
          values = 29;
        } else {
          values = 28;
        }
      } else {
        if (((Number(this.month)) % 2 === 0)) {
          values = 31;
        } else {
          values = 30;
        }

      }
      this.dateArray = [];
      const dt: Object = new Object();
      dt['Code'] = '--Select--';
      dt['Description'] = '--Select--';
      this.dateArray.push(dt);
      for (let i = 1; i <= values; i++) {
        const dt1: Object = new Object();
        dt1['Code'] = (i < 10 ? '0' : '') + i;
        dt1['Description'] = (i < 10 ? '0' : '') + i;
        this.dateArray.push(dt1);
      }
    }


  }


  public prepareMonthArray() {
    const jan: Object = new Object();
    jan['Code'] = '01';
    jan['Description'] = 'January';
    this.monthArray.push(jan);

    const feb: Object = new Object();
    feb['Code'] = '02';
    feb['Description'] = 'Feburary';
    this.monthArray.push(feb);

    const mar: Object = new Object();
    mar['Code'] = '03';
    mar['Description'] = 'March';
    this.monthArray.push(mar);

    const apr: Object = new Object();
    apr['Code'] = '04';
    apr['Description'] = 'April';
    this.monthArray.push(apr);

    const may: Object = new Object();
    may['Code'] = '05';
    may['Description'] = 'May';
    this.monthArray.push(may);

    const jun: Object = new Object();
    jun['Code'] = '06';
    jun['Description'] = 'June';
    this.monthArray.push(jun);

    const jul: Object = new Object();
    jul['Code'] = '07';
    jul['Description'] = 'July';
    this.monthArray.push(jul);

    const aug: Object = new Object();
    aug['Code'] = '08';
    aug['Description'] = 'Augest';
    this.monthArray.push(aug);

    const sep: Object = new Object();
    sep['Code'] = '09';
    sep['Description'] = 'September';
    this.monthArray.push(sep);

    const oct: Object = new Object();
    oct['Code'] = '10';
    oct['Description'] = 'October';
    this.monthArray.push(oct);

    const nov: Object = new Object();
    nov['Code'] = '11';
    nov['Description'] = 'November';
    this.monthArray.push(nov);

    const dec: Object = new Object();
    dec['Code'] = '12';
    dec['Description'] = 'December';
    this.monthArray.push(dec);


  }
  public prepareYearArray() {

    const currentDt = new Date();
    this.startYear = this.startYear ? this.startYear : (currentDt.getFullYear());
    const stDate: any = Number.parseInt(this.startYear, 0);
    const enDate: any = this.endYear ? Number.parseInt(this.endYear, 0) : (stDate + 10);
    for (let i = stDate; i <= enDate; i++) {
      const dt: Object = new Object();
      dt['Code'] = i;
      dt['Description'] = i;
      this.yearArray.push(dt);
    }

  }

  public onChangeMethod(data: any) {
    if (this.year && this.month && this.date) {
      const currentDate = new Date().toDateString();

      this.value = moment(this.year + '-' + this.month + '-' + this.date).toString();
      const selectedDate = new Date(this.value).toDateString();
      this.setDOB();
      const returnResponse = this.onBlurMethod(true);
      if (returnResponse) {
        this.changeevent.emit(this.value);
        EmitterService.get('VALIDATE').emit();
      }
    }
  }
  public setDOB() {
    if (this.year) {

    } else {
      this.selectedYear = 'YYYY';
    }
  }

  public validationCall() {
    if (!this.firstBlur) {
      if (!this.notDynamic) {
        this.onBlurMethod(true);
      } else {
        if (this.onBlurMethod(false)) {
          this.onBlurMethod(true);
        }
      }
      EmitterService.get('VALIDATE').emit();
    } else {
      this.firstBlur = false;
    }
  }

  public focus() {
    this.showDropDown = true;
  }

  validateTime() {
    try {
    const date1 = new Date(this.selectedTime);
    } catch (err) {
    }
  }
  public setSelectedDateTime() {
    let isError: any = false;
    let dateTime1;
    let dateTime2;
    try {
    const date = new Date(this.selectedDt);
    const date1 = new Date(this.selectedTime);
    const time = date1.toLocaleString('en-GB');
     dateTime1 = moment(date).format("DD/MM/YYYY");
     dateTime2 = moment(time).format("HH:mm:ss");
    this.showDropDown = false;
    }  catch (err) {
      isError = true;
    }
    if (!isError) {
      if (dateTime1 && dateTime2) {
      this.value = dateTime1 + ' ' + dateTime2;
      }
    }
  }

  public changeDate() {
    const todaysDate = new Date();

    if (((new Date(this.selectedDt)).setHours(0, 0, 0, 0) ) === todaysDate.setHours(0, 0, 0, 0)) {
      this.selectedTime = new Date();
  }  else {
    this.resetDate();
  }
  }

  public resetDate() {
    this.selectedTime = new Date(new Date().setHours(0, 0, 0, 0));
  }

}
