import { ViewChild } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { EmitterService } from 'src/services/EmitterService';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.html',

})
export class LandingComponent implements OnInit {
  public loading: Boolean = true;

  constructor(private router: Router, private route: ActivatedRoute) {

  }
  ngOnInit() {

    setTimeout(() => {
      this.loading = false;
    }, 4000);
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('AFFINITY_SELECT_EVENT').emit(true);
  }

  public toggle() {

  }

}

