import { Component, OnInit, ViewChild, ElementRef, HostListener, AfterViewInit, ViewChildren } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { EmitterService } from 'src/services/EmitterService';
import { ProductVO } from '../dto/ProductVO';
import { DocumentVO } from '../dto/DocumentVO';
import { PremiumDetailsVO } from './../dto/PremiumDetailsVO';
import * as _moment from 'moment';
import { GridComponent } from '../grid/grid.component';
import { ProposalServices } from '../services/proposal.services';
import { ModalService } from 'src/services/modal.services';
import { NavigationServices } from 'src/services/navigation.services';

import { RenewalTransactionComponent } from 'src/renewal-transaction/renewal-transaction.component';
import { SelectComponent } from 'src/select/select-component';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { MultiselectComponent } from 'src/multiselect/multiselect.component';
import { ProductServices } from 'src/services/product.service';
import { USER_AFFINITY, AFFINITY_ID } from 'src/common/common.constants';
import { ProductMasterPolicyVO } from 'src/dto/ProductMasterPolicyVO';
import { ProductDetailsVO } from 'src/dto/ProductDetailsVO';
import { QuestionVO } from 'src/dto/QuestionVO';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';
import { SelectService } from 'src/services/select.services';
import { PaymentService } from 'src/services/payment.service';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-product-configuration',
  templateUrl: './product-configuration.component.html',
  providers: [ProductServices]
})
export class ProductConfigurationComponent implements OnInit, AfterViewInit {
  @ViewChild(GridComponent)
  public gridcomponent: GridComponent;
  @ViewChildren(MultiselectComponent) multiSelectComponents: any;
  public isvalues: Boolean = false;
  public columnsData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public AdditionalProjectData: Array<Object> = Array<Object>();
  public PremiumFilesArray: Array<Object> = Array<Object>();
  public CCWEFilesArray: Array<Object> = Array<Object>();
  public projectsDatas: Array<Object> = Array<Object>();
  public columnsDatas: Array<Object> = Array<Object>();
  public columnDatas: Array<Object> = Array<Object>();
  public insertingProduct: Array<Object> = Array<Object>();
  public actionColumnsDatas: Array<Object> = Array<Object>();
  public actionColumns: Array<Object> = Array<Object>();
  public planTypes: Object = Object;
  public bussiness: Boolean = false;
  public totalRows: any;
  public isvalue: Boolean = false;
  public isvalue1: Boolean = false;
  public isSubmit: Boolean = false;
  public productdetails: Array<Object> = Array<Object>();
  public productCreation: Boolean = false;
  public newProduct: Boolean = false;
  public productVo: ProductVO = new ProductVO();
  public premiumDetailsVO: PremiumDetailsVO = new PremiumDetailsVO();
  public totalfiles: Array<any> = [];
  public premiumfiles: Array<any> = [];
  public totalPremiumfiles: any;
  public status = false;
  public statusVal: Boolean = false;
  public hasSuccess: Boolean = false;
  public document: Array<any> = [];
  public values: any;
  public Docvalues: any;
  public files: File;
  public isdeletefile: Boolean = false;
  public isdeleteCCWEfile: Boolean = false;
  public isdeletefiles: Boolean = false;
  public questions: Array<Object>;
  public hrLeftMargin;
  public hrWidth;
  public indexValue: any;
  public AddingQuestion: String = '';
  public len: Number = 0;
  public getBasic: Array<Object>;
  public hasErrorMessage = true;
  public enableSubmit = false;
  public backdateInceptionFlag = false;
  public dataArray = [];
  public inceptionRangeFieldControl = 'readonly';
  public PaymentArray = [];
  public planArray = [];
  public planSheetList = [];
  public customValidationMessage: Boolean = false;
  public ValidationMessage: String;
  public CCWEValidationMessage: String;
  public pattern='^[a-zA-Z0-9. ]*$';
  public arrayMasterPolicy: Array<Object> = new Array<Object>();
  public arrayQuestions: Array<Object> = new Array<Object>();
  public masterPolicy: any;
  public premiumDoc: any;
  public count = 10;
  public totalNumberOfRecords = 10;
  public loadingmessage = '';
  public productType: any;
  public subProductType: any;
  public editProductId: any;
  public editProduct: any;
  public editProductData: any;
  public rateSheetEffectiveDate: any;
  public ccweSheetEffectiveDate: any;
  public policyType: any;
  public productDateRangeMessage: any;
  public productDateFlag: boolean = false;
  public masterPolicyRangeFlagArray = [];
  public masterPolicyRangeMessageArray = [];
  public selectAllQuestions = true;
  public firstProductChange = true;
  public firstSubProductChange = true;
  public creationTimestamp: any;
  public lastUpdatedTimestamp: any;

  /******************************************/
  public selectedRateSheetFile: any;
  public selectedCCWESheetFile: any;
  public selectedAdditionalDocFile: any;
  public additionalDocLabel: any;
  // public policyTypeArray: Array<Object> = new Array<Object>();
  /******************************************/

  public paymentOptionsFlag = false;
  public editSuccessful = false;
  public offlineFlag = false;
  public masterPolicyMessageArray = [];
  public masterPolicyFlag = false;
  public masterPolicyTermArray = [];
  public effectiveDateMessage = '';
  public ccweEffectiveDateMessage = '';
  public productDetails: ProductDetailsVO = new ProductDetailsVO();
  public productNameFlag = false;
  public productNameMessage = '';
  public productNameTemporal: any;
  public productName: any;
  public additionalDocumentMessage: any;
  public applianceData: any;
  public additionalDocumentsDeleteQueue = [];
  public rateSheetUploadFlag = false;
  public rateSheetFlagCreateProduct = false;
  public rateSheetValidationMessage = '';
  public ccweSheetValidationMessage = '';
  public additionalDocFilenameMessage = '';
  public actionFailedFlag = false;
  public actionFailedMessage = '';
  public rateLoadingFlag: any;
  public ccweLoadingFlag: any;
  public displayedAdditionalDocuments: any;
  public additionalDocumentCount: any;
  public originalPolicyType: any;
  public originalGatewayArray: any;
  public applianceList: any;
  public uniqueEquivalentPlans: any;
  public availableApplianceList: any;

  @ViewChild('documentLabel') documentLabelField: TextBoxComponent;

  /*Multiple Rate Sheet Plan*/
  // Array of response objects / arrays for rate sheet upload
  public rateSheetDataArray = [];
  // Contains file name of ratesheet corresponding to index in rateSheetDataArray
  public rateSheetMetaDataArray = [];
  public policyTypeArray = [];
  public planTypeValue: any;
  public uniquePlans: Array<Object> = new Array<Object>();
  public existingPlanFalg: Boolean = false;
  public applianceForUniquePlans = [];

  public ccweSheetDataArray = [];
  public ccweSheetMetaDataArray = [];

  // Rate sheet stored in S3
  public rahetsheetFormData: any;
  public rateSheetForS3List: Array<Object> = new Array<Object>();

   // Rate sheet stored in S3
   public ccwesheetFormData: any;
   public ccweSheetForS3List: Array<Object> = new Array<Object>();

  constructor(private services: ProposalServices,
    private modalService: ModalService,
    private productService: ProductServices,
    private router: Router,
    private route: ActivatedRoute,
    private navigationService: NavigationServices,
    private selectService: SelectService,
    private paymentService: PaymentService) {
  }
  @ViewChild('contentBody') contentBodyView: ElementRef;

  @ViewChildren(SelectComponent)
  private selectFields: any;

  @ViewChildren(TextBoxComponent) private textFields: any;
  @ViewChild('grid') grid: GridComponent;

  @ViewChildren(DatePickerComponent)
  private dateFields: any;

  @ViewChildren(MultiselectComponent)
  private multiselectFields: any;

  /*Search Pagination*/
  @ViewChild('grid') gridComponent: GridComponent;
  @ViewChild('grid2') gridComponent2: GridComponent;
  /*****************/

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyView.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }

  public effectiveDateBlur() {
    this.effectiveDateMessage = '';
    if (!this.productDetails.rateSheetEffectiveDate) {
      this.effectiveDateMessage = 'Effective Date is required';
    }
  }

  public ccweeffectiveDateBlur() {
    this.ccweEffectiveDateMessage = '';
    if (!this.productDetails.ccweSheetEffectiveDate) {
      this.ccweEffectiveDateMessage = 'CCWE Effective Date is required';
    }
  }

  topFunction() {
    document.body.scrollTop = 0;
    document.documentElement.scrollTop = 0;
  }

  public cancelFunction() {
    if (this.editProduct) {
      this.moveToTop();
      this.router.navigateByUrl(this.router.url);
    } else {
      // this.AddingQuestion = '';
      // this.AdditionalProjectData = [];
      // this.Docvalues = '';
      // this.PaymentArray = [];
      // this.PremiumFilesArray = [];
      // this.ValidationMessage = '';
      // this.actionColumns = [];
      // this.actionColumnsData = [];
      // this.actionColumnsDatas = [];
      // this.arrayMasterPolicy = [];
      // this.arrayQuestions = [];
      // this.backdateInceptionFlag = false;
      // this.bussiness = false;
      // this.columnDatas = [];
      // this.columnsData = [];
      // this.columnsDatas = [];
      // this.count = 10;
      // this.customValidationMessage = false;
      // this.dataArray = [];
      // this.document = [];
      // this.editProduct = false;
      // this.editProductData = undefined;
      // this.editProductId = undefined;
      // this.enableSubmit = false;
      // this.files = undefined;
      // this.getBasic = [];
      // this.hasErrorMessage = false;
      // this.hasSuccess = false;
      // this.inceptionRangeFieldControl = 'readonly';
      // this.indexValue = undefined;
      // this.insertingProduct = [];
      // this.isSubmit = false;
      // this.isdeletefile = false;
      // this.isdeletefile = false;
      // this.isvalue = false;
      // this.isvalues = false;
      // this.len = 0;
      // this.loadingmessage = '';
      // this.masterPolicy = undefined;
      // this.masterPolicyRangeFlagArray = [];
      // this.masterPolicyRangeMessageArray = [];
      // this.arrayMasterPolicy = [];
      // this.newProduct = false;
      // this.planArray = [];
      // this.planSheetList = [];
      // this.policyType = undefined;
      // this.premiumDoc = undefined;
      // this.premiumfiles = [];
      // this.productCreation = false;
      // this.productCreation = false;
      // this.newProduct = false;
      // this.productType = undefined;
      // this.subProductType = undefined;
      // this.rateSheetEffectiveDate = undefined;
      // this.selectedRateSheetFile = undefined;
      // this.selectedAdditionalDocFile = undefined;
      // this.additionalDocLabel = undefined;
      // this.policyTypeArray = [];
      // this.initialize();
      // this.createProduct();
      // setTimeout(function () {
      //   EmitterService.get('HIDE_VALIDATION_MESSAGE').emit(true);
      // });
      this.router.navigateByUrl(this.router.url + '?createProduct=true');
      this.moveToTop();
    }
  }

  ngOnInit() {
     this.loadingmessage = 'Please wait...';
    this.lodingAppliancesList();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    EmitterService.get('CREATE_PRODUCT').subscribe(data => {
      this.productCreation = true;
      this.newProduct = true;
    });
    EmitterService.get('ENABLE_MENU').emit(null);
    EmitterService.get('DISABLE_FOOTER').emit(null);
    EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
    this.initialize();
    const createFlag = this.route.snapshot.queryParams['createProduct'];
    if (createFlag) {
      this.createProduct();
    }
  }
  ngAfterViewInit() {
    this.onResize();
    this.route.queryParams.subscribe(params => {
      this.editProduct = params['edit'];
      this.editProductId = params['productId'];
      if (this.editProduct && this.editProductId) {
        this.productCreation = true;
        this.newProduct = true;
        this.productService.editProduct(this.editProductId).then(data => {
          this.editProductData = data;
          this.initializeProductEdit(data);
        });
      }
    });
  }

  prepareColumnsData() {
    const col1: Object = new Object();
    col1['colName'] = 'Product Name';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Product Type';
    this.columnsData.push(col2);
  }

  validateProductName() {
    this.productNameMessage = '';
    const _this = this;
    if (this.productNameTemporal) {
      clearTimeout(this.productNameTemporal);
    }
    if (this.editProduct && this.productName === this.productDetails.productName) {
      this.productNameFlag = true;
    } else {
      this.productNameTemporal = setTimeout(function () {
        this.productService.getProductList().then(idList => {
          this.productService.productExists(idList, _this.productDetails.productName).then(data => {
            if (!Boolean(data)) {
              _this.productNameFlag = true;
            } else {
              if (_this.productName === _this.productDetails.productName) {
                _this.productNameFlag = false;
              } else {
                _this.productNameFlag = false;
                _this.productNameMessage = 'Product already exists';
              }
              _this.outSidevalidate();
            }
          }).catch(error => {
            if (_this.productName === _this.productDetails.productName) {
              _this.productNameFlag = false;
            } else {
              _this.productNameFlag = false;
              _this.productNameMessage = 'Product already exists';
            }
            _this.outSidevalidate();
          });
        }).catch(error => {
          if (_this.productName === _this.productDetails.productName) {
            _this.productNameFlag = false;
          } else {
            _this.productNameFlag = false;
            _this.productNameMessage = 'Product already exists';
          }
          _this.outSidevalidate();
        });
      }.bind(_this), 500);
    }
  }

  public calculateMasterPolicyEndDate(index: any) {
    if (this.productDetails.productMasterPolicy[index]['startDate'] &&
      this.masterPolicyTermArray[index]) {
      const endDate = new Date(this.productDetails.productMasterPolicy[index]['startDate']);
      const endDateMoment = moment(endDate).add(this.masterPolicyTermArray[index], 'years');
      endDateMoment.subtract(1, 'days');
      endDateMoment.set({ hour: 23, minute: 59, second: 59 });
      this.productDetails.productMasterPolicy[index]['endDate'] = endDateMoment.toDate();
      this.masterPolicyDateRangeValidation(index);
    }
    const _this = this;
    setTimeout(
      function () {
        _this.outSidevalidate();
      }.bind(_this), 500
    );
  }

  prepareDocColumnsDatas() {

    const col1: Object = new Object();
    col1['colName'] = 'Uploaded Files';
    this.columnsDatas.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Document Label';
    this.columnsDatas.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Upload Date & Time';
    this.columnsDatas.push(col3);
  }

  prepareColDatas() {
    const col1: Object = new Object();
    col1['colName'] = 'Uploaded File Name';
    this.columnDatas.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Effective Date';
    this.columnDatas.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Upload Date & Time';
    this.columnDatas.push(col3);
  }
  public prepareActionColumns() {

    this.actionColumnsData.push('EDIT');

  }
  public prepareAction() {
    this.actionColumns.push('DELETE');
  }
  public prepareDocActionColumns() {

    this.actionColumnsDatas.push('DELETE');

  }
  public prepareProjectData(productData: any) {
    const productDetailsList = productData['productList'];
    if (productData !== undefined) {
      this.productdetails = [];
      for (let i = 0; i < productDetailsList.length; i++) {
        const data: Object = new Object();
        data['Product Name'] = productDetailsList[i]['productName'];
        data['Product Type'] = productDetailsList[i]['productType'] === 'Health' ? 'Corporate Group Health' : productDetailsList[i]['productType'];
        data['Product ID'] = productDetailsList[i]['productId'];
        this.productdetails.push(data);
      }
    }
  }

  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getProductDetails(this.count, this.totalNumberOfRecords);
  }

  public propagteAction(event: any) {
    if (String(event['Action']) === 'EDIT') {
      const url = '/welcome/(contentBody:product)?edit=true&productId=' + event['Product ID'];
      this.router.navigateByUrl(url);
      this.moveToTop();
    }
  }

  // public validateMasterPolicy(index: any) {
  //   this.masterPolicyFlag = true;
  //   for (let i = 0; i < this.masterPolicyMessageArray.length; i++) {
  //     this.masterPolicyMessageArray[i] = '';
  //   }
  //   for (let i = 0; i < this.productDetails.productMasterPolicy.length; i++) {
  //     if (this.productDetails.productMasterPolicy[i]['masterPolicyId'] === this.productDetails.productMasterPolicy[index]['masterPolicyId'] &&
  //       index !== i) {
  //       this.masterPolicyFlag = false;
  //       this.masterPolicyMessageArray[i] = 'Master Policy Number already exists';
  //     }
  //   }
  //   this.masterPolicyMessageArray[index] = this.masterPolicyFlag ? '' : 'Master Policy Number already exists';
  // }


  public validateMasterPolicy(index) {
    this.masterPolicyFlag = true;
    for (let i = 0; i < this.masterPolicyMessageArray.length; i++) {
      if (this.masterPolicyMessageArray[i] === 'Master Policy Already exists') {
        this.masterPolicyMessageArray[i] = '';
      }
    }
    for (let i = 0; i < this.productDetails.productMasterPolicy.length; i++) {
      for (let j = 0; j < this.productDetails.productMasterPolicy.length; j++) {
        if (i !== j) {
          if (this.productDetails.productMasterPolicy[i]['masterPolicyId'] === this.productDetails.productMasterPolicy[j]['masterPolicyId'] &&
            this.productDetails.productMasterPolicy[i]['masterPolicyId'] && this.productDetails.productMasterPolicy[j]['masterPolicyId']) {
            this.masterPolicyMessageArray[i] = 'Master Policy Already exists';
            this.masterPolicyMessageArray[j] = 'Master Policy Already exists';
            this.masterPolicyFlag = false;
          }
        }
      }
    }
  }


  createProduct() {

    this.productVo.documentVO.DocumentLabel = '';
    this.productVo.documentVO.UploadedFile = null;
    this.productVo.documentVO.UploadedFileName = '';
    // this.productVo.ProductName = '';
    this.productVo.PolicyNumberingFormat = '';
    this.productVo.PolicyTerm = 1;
    this.productVo.Premium = '';
    this.productVo.BackdatedInception = '';
    this.productVo.BackdateInceptionRange = '';
    this.productVo.EffectiveDate = null;
    this.productVo.Elite = '';
    this.productVo.Basic = '';
    this.productVo.ProductStartDate = null;
    this.productVo.ProductType = '';
    this.productVo.RiskStartDate = null;
    this.productVo.Supreme = '';
    this.productVo.MasterPolicy = '';
    this.status = false;
    this.statusVal = false;
    this.productVo.ProductEndDate = null;
    this.AdditionalProjectData = [];
    this.projectsDatas = Array<Object>();
    this.premiumDetailsVO.gatewayId = '';
    this.productCreation = true;
    this.newProduct = true;
    this.isSubmit = false;
  }

  public initialize() {
    this.productVo = new ProductVO();
    this.premiumDetailsVO = new PremiumDetailsVO();
    this.productVo.documentVO = new DocumentVO();
    this.productVo.documentVO.UploadedFile = null;
    this.productVo.documentVO.DocumentLabel = '';
    this.productVo.BackdateInceptionRange = '';
    this.productVo.PolicyTerm = '';
    this.productVo.BackdatedInception = '';
    this.productVo.PolicyNumberingFormat = '';


    this.masterPolicyMessageArray = [];
    this.masterPolicyMessageArray.push('');
    this.masterPolicyFlag = false;
    this.masterPolicyTermArray = [];
    this.masterPolicyTermArray.push(undefined);


    this.prepareColumnsData();
    this.prepareActionColumns();
    this.prepareColDatas();
    this.prepareDocColumnsDatas();
    this.prepareDocActionColumns();
    this.prepareAction();
    this.getProductDetails(0, 10);
    this.masterPolicyRangeFlagArray.push(true);
    this.masterPolicyRangeMessageArray.push('');
    this.productDetails = new ProductDetailsVO();
    this.productVo.BackdatedInception = false;

    // this.productService.getPaymentItems().then(data => {
    //   this.dataArray = data;
    // });
    this.paymentService.getPaymentGatewayList().then(data => {
      this.dataArray = data;
    });
    // this.productService.getPlaninfo().then(data => {
    //   this.planArray = data;
    // });
    this.productService.masterPolicy().then(data => {
      this.masterPolicy = data;
    });

    this.productService.masterQuestions().then(data => {
      this.questions = data;
      for (const question of this.questions) {
        delete question['questionId'];
        question['Checked'] = true;
      }
    });
  }

  public getProductDetails(offset: any, size: any) {
  //  this.loadingmessage = 'Please wait...';
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
      this.productService.getProductListForAffinity(affinity[AFFINITY_ID])
        .then(list => {
          this.productService.listProducts(offset, size, list).then(data => {
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.loadingmessage = '';
            this.moveToTop();
          }).catch(error => {
            this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
           });
        }).catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
    }
  }

  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  public documentLabelChange() {
    this.additionalDocumentMessage = '';
  }
  AttachDoc() {
    let flag = true;
    for (const document of this.AdditionalProjectData) {
      if (document['Uploaded Files'] === this.selectedAdditionalDocFile['completeFileName'] &&
        document['Document Label'] === this.additionalDocLabel) {
        this.additionalDocumentMessage = 'File name already exists';
        flag = false;
        break;
      }
    }
    if (flag) {
      const additionalDocumentData: Object = new Object();
      additionalDocumentData['Uploaded Files'] = this.selectedAdditionalDocFile['completeFileName'];
      additionalDocumentData['Document Label'] = this.additionalDocLabel;
      additionalDocumentData['Upload Date & Time'] = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
      additionalDocumentData['File'] = this.selectedAdditionalDocFile['fileData'];
      const fileData: any = new FormData();
      fileData.append('file', additionalDocumentData['File'], additionalDocumentData['Uploaded Files']);
      this.services.validateDocuments(fileData).then(data => {
      if(data.status === 200 && data['_body']['validationMessage'].length==0){
        this.AdditionalProjectData.unshift(additionalDocumentData);
		    this.deleteAddFile();
        this.initializeDocumentGrid();
        }
        else{
          this.additionalDocumentMessage = data['_body']['validationMessage'];;
        }
      }).catch(error =>{
      
      });
    }
    
  }

  public additionalDocsAction(event) {
    if (String(event['Action']) === 'DELETE') {
      for (let index = 0; index < this.AdditionalProjectData.length; index++) {
        if (String(this.AdditionalProjectData[index]['Uploaded Files']) === String(event['Uploaded Files']) &&
          String(this.AdditionalProjectData[index]['Document Label']) === String(event['Document Label'])
        ) {
          this.AdditionalProjectData.splice(index, 1);
          if (event['productDocumentId']) {
            this.additionalDocumentsDeleteQueue.push(event['productDocumentId']);
          }
          break;
        }
      }
      this.initializeDocumentGrid();
    }
  }




  fileSelectionEvent(fileInput: any) {
    this.isvalues = true;
    this.isdeletefiles = true;
    this.selectedAdditionalDocFile = new Object();
    this.selectedAdditionalDocFile['fileData'] = fileInput.target.files[0];
    this.selectedAdditionalDocFile['completeFileName'] = fileInput.target.files[0].name;
    this.selectedAdditionalDocFile['fileName'] = this.selectedAdditionalDocFile['completeFileName'];
    const nameSegmentLength = this.selectedAdditionalDocFile['completeFileName'].split('.').length;
    const selectedFileExtension = this.selectedAdditionalDocFile['completeFileName'].split('.')[nameSegmentLength - 1];

    if (selectedFileExtension !== 'xlsx' && selectedFileExtension !== 'xls' && selectedFileExtension !== 'pdf') {
      this.additionalDocFilenameMessage = 'Invalid File Type.Only PDF & Excel File Type Is Allowed';
    } else {
      this.additionalDocFilenameMessage = '';
    }
    if(this.additionalDocFilenameMessage==='')
    {
      if(!this.selectedAdditionalDocFile['completeFileName'].match(this.pattern))
      {
        this.additionalDocFilenameMessage = 'Invalid File Name.Special Characters Are Not Allowed.';
      }
      else{
        this.additionalDocFilenameMessage = '';
      }
    }
  }

  Details() {
    if (this.files !== null) {
      const d = new Date();
      const obs: any = [];
      obs['Uploaded File Name'] = this.productVo.documentVO.UploadedFileName;
      obs['Effective Date'] = moment(this.rateSheetEffectiveDate).format('DD/MM/YYYY h:mm:ss');
      obs['Upload Date & Time'] = moment(d).local().format('DD/MM/YYYY HH:mm:ss');
      this.rateSheetEffectiveDate = undefined;


      this.projectsDatas.push(obs);
      this.gridComponent2.TotalRows = this.projectsDatas.length;


      for (let i = 0; i < this.premiumfiles.length; i++) {
        const data1: Object = new Object();
        data1['Uploaded File Name'] = this.projectsDatas[i]['Uploaded File Name'];
        data1['Effective Date'] = this.projectsDatas[i]['Effective Date'];
        data1['Upload Date & Time'] = this.projectsDatas[i]['Upload Date & Time'];
        data1['File'] = this.premiumfiles[i]['fileValues'][0];

        this.PremiumFilesArray.push(data1);
      }
      this.productVo.EffectiveDate = null;
      this.values = '';
      this.isvalue = false;
    }
  }

  public rateSheetGridAction(event) {
    if (this.editProduct) {
      if (event['Action'] === 'DELETE') {
        const policyTypes = [];
        for (let i = 0; i < this.policyTypeArray.length; i++) {
          if (this.policyTypeArray[i]['effectiveDate'] === event['Effective Date']) {
            this.policyTypeArray[i]['isActive'] = 0;
            //  this.policyTypeArray.splice(i, 1);
          } else {
            policyTypes.push(this.policyTypeArray[i]);
          }
        }
        if (this.productDetails.shortProductName === 'Home') {
          this.getUniquePlanNameIndices(this.policyTypeArray);
          this.setApplianceForEditProduct(policyTypes);
        }
        for (let i = 0; i < this.rateSheetMetaDataArray.length; i++) {
          if (this.rateSheetMetaDataArray[i]['Uploaded File Name'] === event['Uploaded File Name'] &&
            this.rateSheetMetaDataArray[i]['Effective Date'] === event['Effective Date']) {
            this.rateSheetMetaDataArray[i]['isActive'] = 0;
            // this.rateSheetDataArray.splice(i, 1);
            // this.rateSheetMetaDataArray.splice(i, 1);
            if (this.rateSheetDataArray.length === 0) {
              this.policyType = undefined;
              this.productDetails.policyType = undefined;
            } else {
              this.sortRateSheetData();
            }
            // if (event['rateSheetDocumentId']) {
            //   this.additionalDocumentsDeleteQueue.push(event['productDocumentId']);
            // }
          }
        }
      }
    } else {
      for (let i = 0; i < this.rateSheetMetaDataArray.length; i++) {
        if (this.rateSheetMetaDataArray[i]['Uploaded File Name'] === event['Uploaded File Name'] &&
          this.rateSheetMetaDataArray[i]['Effective Date'] === event['Effective Date']) {
          this.rateSheetDataArray.splice(i, 1);
          this.rateSheetMetaDataArray.splice(i, 1);
          for (let j = 0; j < this.policyTypeArray.length; j++) {
            if (this.policyTypeArray[j]['effectiveDate'] === event['Effective Date']) {
              this.policyTypeArray[j]['isActive'] = 0;
              this.getUniquePlanNameIndices(this.policyTypeArray);
              this.policyTypeArray.splice(j, 1);
            }
          }
          //   delete this.rateSheetMetaDataArray[i];
          if (this.rateSheetDataArray.length === 0) {
            this.policyType = undefined;
            this.productDetails.policyType = undefined;
            this.PremiumFilesArray = [];
          } else {
            this.sortRateSheetData();
          }
        }
      }
    }
  }

  public ccweSheetGridAction(event) {
    if (this.editProduct) {
      if (event['Action'] === 'DELETE') {
        const policyTypes = [];
        /*for (let i = 0; i < this.policyTypeArray.length; i++) {
          if (this.policyTypeArray[i]['ccweEffectiveDate'] === event['Effective Date']) {
            this.policyTypeArray[i]['isActive'] = 0;
            //  this.policyTypeArray.splice(i, 1);
          } else {
            policyTypes.push(this.policyTypeArray[i]);
          }
        }
        if (this.productDetails.shortProductName === 'Home') {
          this.getUniquePlanNameIndices(this.policyTypeArray);
          this.setApplianceForEditProduct(policyTypes);
        }*/
        for (let i = 0; i < this.ccweSheetMetaDataArray.length; i++) {
          if (this.ccweSheetMetaDataArray[i]['Uploaded File Name'] === event['Uploaded File Name'] &&
            this.ccweSheetMetaDataArray[i]['Effective Date'] === event['Effective Date']) {
            this.ccweSheetMetaDataArray[i]['isActive'] = 0;
            // this.ccweSheetDataArray.splice(i, 1);
            // this.ccweSheetMetaDataArray.splice(i, 1);
            if (this.ccweSheetDataArray.length === 0) {
              this.policyType = undefined;
              this.productDetails.policyType = undefined;
            } else {
              this.sortCCWESheetData();
            }
            // if (event['ccwesheetDocumentId']) {
            //   this.additionalDocumentsDeleteQueue.push(event['productDocumentId']);
            // }
          }
        }
      }
    } else {
      for (let i = 0; i < this.ccweSheetMetaDataArray.length; i++) {
        if (this.ccweSheetMetaDataArray[i]['Uploaded File Name'] === event['Uploaded File Name'] &&
          this.ccweSheetMetaDataArray[i]['Effective Date'] === event['Effective Date']) {
          this.ccweSheetMetaDataArray[i]['isActive'] = 0;
          //this.ccweSheetDataArray.splice(i, 1);
          //this.ccweSheetMetaDataArray.splice(i, 1);
          for (let j = 0; j < this.policyTypeArray.length; j++) {
            if (this.policyTypeArray[j]['ccweEffectiveDate'] === event['Effective Date']) {
              this.policyTypeArray[j]['isActive'] = 0;
              this.getUniquePlanNameIndices(this.policyTypeArray);
              this.policyTypeArray.splice(j, 1);
            }
          }
          //   delete this.ccweSheetMetaDataArray[i];
          if (this.ccweSheetDataArray.length === 0) {
            this.policyType = undefined;
            this.productDetails.policyType = undefined;
            this.PremiumFilesArray = [];
          } else {
            this.sortCCWESheetData();
          }
        }
      }
    }
  }

  downloadTemplate() {
    // TO-DO
    let lob;
    if (String(this.productDetails.shortProductName) === 'Health') {
      lob = 1;    } else if (String(this.productDetails.shortProductName) === 'Shop' || String(this.productDetails.shortProductName) === 'Home') {
      lob = 2;
    }
    this.productService.downloadTemplate(lob).then(data => {
      const parsedResponse = data.blob();
      const blob = new Blob([parsedResponse], {
        type: 'application/xlsx'

      });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'RateSheet.xlsx');

      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'RateSheet.xlsx';
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }).catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
  }

  downloadCCWETemplate() {
    
    this.productService.downloadCCWETemplate(this.productDetails.shortProductName).then(data => {
      const parsedResponse = data.blob();
      const blob = new Blob([parsedResponse], {
        type: 'application/xlsx'

      });
      if (window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveBlob(blob, 'CCWESheet.xlsx');

      } else {
        const a = window.document.createElement('a');
        a.href = window.URL.createObjectURL(blob);
        a.download = 'CCWESheet.xlsx';
        document.body.appendChild(a);
        a.click();

        document.body.removeChild(a);
      }
    }).catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
  }

  public insertRateSheetData(sheetData: any, metaData) {
    this.rateSheetDataArray.push(sheetData);
    if (metaData['isActive'] === 1) {
      this.rateSheetMetaDataArray.push(metaData);
    }
    this.sortRateSheetData();
  }

  public sortRateSheetData() {
    this.PremiumFilesArray = [];
    this.rateSheetForS3List = [];
    for (let i = 0; i < this.rateSheetMetaDataArray.length; i++) {
      let largestDate = moment(this.rateSheetMetaDataArray[i]['Upload Date & Time'], 'DD/MM/YYYY HH:mm:ss').toDate();
      for (let j = i; j < this.rateSheetMetaDataArray.length; j++) {
        const currentDate = moment(this.rateSheetMetaDataArray[j]['Upload Date & Time'], 'DD/MM/YYYY HH:mm:ss').toDate();
        if (largestDate < currentDate) {
          let temp = this.rateSheetMetaDataArray[i];
          this.rateSheetMetaDataArray[i] = this.rateSheetMetaDataArray[j];
          this.rateSheetMetaDataArray[j] = temp;
          temp = this.rateSheetDataArray[i];
          this.rateSheetDataArray[i] = this.rateSheetDataArray[j];
          this.rateSheetDataArray[j] = temp;
          largestDate = moment(this.rateSheetMetaDataArray[i]['Upload Date & Time'], 'DD/MM/YYYY HH:mm:ss').toDate();
        }
      }
    }
    for (let i = 0; i < this.rateSheetMetaDataArray.length; i++) {
      if (this.rateSheetMetaDataArray[i]['isActive'] === 1) {
        this.PremiumFilesArray.push(this.rateSheetMetaDataArray[i]);
        this.rateSheetForS3List.push(this.rateSheetMetaDataArray[i]);
      }
    }
    this.policyType = this.rateSheetDataArray[0];
    if (this.PremiumFilesArray.length === 0) {
      this.policyType = undefined;
      this.productDetails.policyType = undefined;
    }
    if(this.rateSheetMetaDataArray[0]!=undefined)
    {
      this.productDetails.rateSheetEffectiveDate = this.rateSheetMetaDataArray[0]['Effective Date'];
    }
  }

  public insertCCWESheetData(sheetData: any, metaData) {
    this.ccweSheetDataArray.push(sheetData);
    if (metaData['isActive'] === 1) {
      this.ccweSheetMetaDataArray.push(metaData);
    }
    this.sortCCWESheetData();
  }

  public sortCCWESheetData() {
    this.CCWEFilesArray = [];
    this.ccweSheetForS3List = [];
    for (let i = 0; i < this.ccweSheetMetaDataArray.length; i++) {
      let largestDate = moment(this.ccweSheetMetaDataArray[i]['Upload Date & Time'], 'DD/MM/YYYY HH:mm:ss').toDate();
      for (let j = i; j < this.ccweSheetMetaDataArray.length; j++) {
        const currentDate = moment(this.ccweSheetMetaDataArray[j]['Upload Date & Time'], 'DD/MM/YYYY HH:mm:ss').toDate();
        if (largestDate < currentDate) {
          let temp = this.ccweSheetMetaDataArray[i];
          this.ccweSheetMetaDataArray[i] = this.ccweSheetMetaDataArray[j];
          this.ccweSheetMetaDataArray[j] = temp;
          temp = this.ccweSheetDataArray[i];
          this.ccweSheetDataArray[i] = this.ccweSheetDataArray[j];
          this.ccweSheetDataArray[j] = temp;
          largestDate = moment(this.ccweSheetMetaDataArray[i]['Upload Date & Time'], 'DD/MM/YYYY HH:mm:ss').toDate();
        }
      }
    }
    for (let i = 0; i < this.ccweSheetMetaDataArray.length; i++) {
      if (this.ccweSheetMetaDataArray[i]['isActive'] === 1) {
        this.CCWEFilesArray.push(this.ccweSheetMetaDataArray[i]);
        this.ccweSheetForS3List.push(this.ccweSheetMetaDataArray[i]);
      }
    }
    this.policyType = this.ccweSheetDataArray[0];
    if (this.CCWEFilesArray.length === 0) {
      //this.policyType = undefined;
      //this.productDetails.policyType = undefined;
    }
    if(this.ccweSheetMetaDataArray[0]!=undefined)
    {
      this.productDetails.ccweSheetEffectiveDate = this.ccweSheetMetaDataArray[0]['Effective Date'];
    }
  }
  
  uploadDocument() {
    this.rateSheetValidationMessage = '';
    this.effectiveDateMessage = '';
    let premiumFileFlag = true;
    const formData = new FormData();
    formData.append('file', this.selectedRateSheetFile['fileData']);
    //his.rahetsheetFormData.append('file', this.selectedRateSheetFile['fileData']);
    const tableData = new Object();
    tableData['Uploaded File Name'] = this.selectedRateSheetFile['fileName'];
    const effectiveDate = moment(this.rateSheetEffectiveDate).local().format('DD/MM/YYYY HH:mm:ss');
    tableData['Effective Date'] = moment(this.rateSheetEffectiveDate).local().format('DD/MM/YYYY HH:mm:ss');
    tableData['Upload Date & Time'] = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
    tableData['File'] = this.selectedRateSheetFile['fileData'];
    tableData['isActive'] = 1;
    const policyEndDate = moment(this.productDetails.endDate, 'DD/MM/YYYY HH:mm:ss').toDate();
    const rateSheetDate = moment(this.rateSheetEffectiveDate, 'DD/MM/YYYY HH:mm:ss').toDate();
    const policyStartDate = moment(this.productDetails.startDate, 'DD/MM/YYYY HH:mm:ss').toDate();
    for (const premiumFile of this.PremiumFilesArray) {
      if (premiumFile['Effective Date'] === tableData['Effective Date']) {
        premiumFileFlag = false;
        this.effectiveDateMessage = 'Effective Date already exists';
        break;
      }
    }
    // Rate sheet upload-product id rule implementation
    // Pass product id as 0 in create
    if (premiumFileFlag) {
      let productIdRateSheet;
      if (this.editProduct) {
        productIdRateSheet = this.editProductId;
      } else {
        productIdRateSheet = 0;
      }

      // lobId 1 for health
      if (String(this.productDetails.lobId) === '1') {
        this.rateLoadingFlag = true;
        this.productService.uploadDocument(formData, effectiveDate, this.productDetails.lobId, this.productDetails.shortProductName, productIdRateSheet).then(data => {

          this.rateLoadingFlag = false;
          if (!data['_body'] || data['_body'].length === 0) {
            this.rateSheetValidationMessage = 'Uploaded Rate Sheet is not in the right format. Please check';
            this.rateSheetFlagCreateProduct = false;
            return;
          }
          this.deleteFile();
          this.rateSheetFlagCreateProduct = true;
          this.rateSheetUploadFlag = true;
          // for (const policy of data['_body']) {
          //   policy['effectiveDate'] = policy['effectiveDate'] = moment(this.rateSheetEffectiveDate).local().format('DD-MM-YYYYTHH:mm:ss');
          // }
           this.policyType = data['_body'];
          // this.productDetails.policyType = JSON.parse(JSON.stringify(data['_body']));
          // this.policyTypeArray.push(JSON.parse(JSON.stringify(data['_body'])));
          const dataValue =  data['_body'];
          for (let i = 0; i < dataValue.length; i++) {
            this.policyTypeArray.push(dataValue[i]);
          }
            for (const policy of this.policyType) {
              policy['isActive'] = 1;
          }
         // this.policyTypeArray.push(data['_body'][0]);
          // this.productDetails.rateSheetEffectiveDate = this.rateSheetEffectiveDate;
          // this.PremiumFilesArray.push(tableData);
          this.insertRateSheetData(this.policyType, tableData);
          for (const dateField of this.dateFields['_results']) {
            dateField.firstBlur = true;
          }
        }).catch(error => {
          console.log(error);
          this.rateLoadingFlag = false;
          this.rateSheetValidationMessage = error['_body']['message'];
          this.rateSheetFlagCreateProduct = false;
        });
        // lobId 2 for business package
      } else if (String(this.productDetails.lobId) === '2') {
        this.rateLoadingFlag = true;
        this.productService.uploadDocument(formData, effectiveDate, this.productDetails.lobId, this.productDetails.shortProductName, productIdRateSheet).then(data => {
          this.rateLoadingFlag = false;
          if (!data['_body'] || data['_body'].length === 0) {
            this.rateSheetValidationMessage = 'Uploaded Rate Sheet is not in the right format. Please check';
            this.rateSheetFlagCreateProduct = false;
            return;
          }
          this.deleteFile();
          this.rateSheetFlagCreateProduct = true;
          this.rateSheetUploadFlag = true;
          this.policyType = data['_body'];
          this.policyTypeArray.push(data['_body'][0]);
          if (this.productDetails.shortProductName === 'Home') {
            this.applianceData = true;
            for (const policy of this.policyType) {
              policy.policyTypeName = 'Business Package For Home';
              policy['isActive'] = 1;
            }
            this.getUniquePlanNameIndices(JSON.parse(JSON.stringify(data['_body'])));
          } else {
            for (const policy of this.policyType) {
             // policy.policyTypeName = 'Business Package For Home';
              policy['isActive'] = 1;
            }
          }
          for (const dateField of this.dateFields['_results']) {
            dateField.firstBlur = true;
          }
          this.insertRateSheetData(this.policyType, tableData);
        }).catch(error => {
          console.log(error);
          this.rateLoadingFlag = false;
          this.rateSheetValidationMessage = error['_body']['error']['errorMessage'];
          this.rateSheetFlagCreateProduct = false;
        });
      }
    }
  }

  uploadCCWEDocument() {
    this.ccweSheetValidationMessage = '';
    this.ccweEffectiveDateMessage = '';
    let premiumFileFlag = true;
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    const formData = new FormData();
    formData.append('file', this.selectedCCWESheetFile['fileData']);
    //his.rahetsheetFormData.append('file', this.selectedCCWESheetFile['fileData']);
    const tableData = new Object();
    tableData['Uploaded File Name'] = this.selectedCCWESheetFile['fileName'];
    const effectiveDate = moment(this.ccweSheetEffectiveDate).local().format('DD/MM/YYYY HH:mm:ss');
    tableData['Effective Date'] = moment(this.ccweSheetEffectiveDate).local().format('DD/MM/YYYY HH:mm:ss');
    tableData['Upload Date & Time'] = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
    tableData['File'] = this.selectedCCWESheetFile['fileData'];
    tableData['isActive'] = 1;
    const policyEndDate = moment(this.productDetails.endDate, 'DD/MM/YYYY HH:mm:ss').toDate();
    const rateSheetDate = moment(this.ccweSheetEffectiveDate, 'DD/MM/YYYY HH:mm:ss').toDate();
    const policyStartDate = moment(this.productDetails.startDate, 'DD/MM/YYYY HH:mm:ss').toDate();
    for (const premiumFile of this.PremiumFilesArray) {
      if (premiumFile['Effective Date'] === tableData['Effective Date']) {
        premiumFileFlag = false;
        this.ccweEffectiveDateMessage = 'Effective Date already exists';
        break;
      }
    }
    // Rate sheet upload-product id rule implementation
    // Pass product id as 0 in create
    if (premiumFileFlag) {
      let productIdRateSheet;
      if (this.editProduct) {
        productIdRateSheet = this.editProductId;
      } else {
        productIdRateSheet = 0;
      }

      // lobId 1 for health
      if (String(this.productDetails.lobId) === '1') {
        this.ccweLoadingFlag = true;
        this.productService.validateCCWEFileData(affinity['id'], this.productDetails.shortProductName, formData,effectiveDate).then(data => {

          this.ccweLoadingFlag = false;
          if (!data['_body'] || data['_body'].length === 0) {
            //this.ccweSheetValidationMessage = 'Uploaded CCWE Sheet is not in the right format. Please check';
            //this.rateSheetFlagCreateProduct = false;
            //return;
          }
          if (data['_body']['message']!==undefined && ['_body']['message'].length>0) {
            this.ccweSheetValidationMessage = data['_body'] ['message'];
            return;
          }
          this.deleteCCWEFile();
          this.rateSheetFlagCreateProduct = true;
          this.rateSheetUploadFlag = true;
          // for (const policy of data['_body']) {
          //   policy['effectiveDate'] = policy['effectiveDate'] = moment(this.ccweSheetEffectiveDate).local().format('DD-MM-YYYYTHH:mm:ss');
          // }
           this.policyType = data['_body'];
          // this.productDetails.policyType = JSON.parse(JSON.stringify(data['_body']));
          // this.policyTypeArray.push(JSON.parse(JSON.stringify(data['_body'])));
          const dataValue =  data['_body'];
          for (let i = 0; i < dataValue.length; i++) {
            this.policyTypeArray.push(dataValue[i]);
          }
            for (const policy of this.policyType) {
              policy['isActive'] = 1;
          }
         // this.policyTypeArray.push(data['_body'][0]);
          // this.productDetails.ccweSheetEffectiveDate = this.ccweSheetEffectiveDate;
          // this.PremiumFilesArray.push(tableData);
          this.insertRateSheetData(this.policyType, tableData);
          for (const dateField of this.dateFields['_results']) {
            dateField.firstBlur = true;
          }
        }).catch(error => {
          this.ccweLoadingFlag = false;
          this.ccweSheetValidationMessage = error['_body']['message'];
          this.rateSheetFlagCreateProduct = false;
        });
        // lobId 2 for business package
      } else if (String(this.productDetails.lobId) === '2') {
        this.ccweLoadingFlag = true;
        this.productService.validateCCWEFileData(affinity['id'], this.productDetails.shortProductName, formData,effectiveDate).then(data => {
          this.ccweLoadingFlag = false;
          if (!data['_body'] || data['_body'].length === 0) {
            //this.ccweSheetValidationMessage = 'Uploaded CCWE Sheet is not in the right format. Please check';
            //this.rateSheetFlagCreateProduct = false;
            //return;
          }
          if (data['_body']['message']!==undefined && ['_body']['message'].length>0) {
            this.ccweSheetValidationMessage = data['_body'] ['message'];
            return;
          }
          this.deleteCCWEFile();
          this.rateSheetFlagCreateProduct = true;
          this.rateSheetUploadFlag = true;
          //this.policyType = data['_body'];
          //this.policyTypeArray.push(data['_body'][0]);
          if (this.productDetails.shortProductName === 'Home') {
            this.applianceData = true;
            if(this.policyType===undefined)
            {
              this.policyType=[];
            }
            for (const policy of this.policyType) {
              policy.policyTypeName = 'Business Package For Home';
              policy['isActive'] = 1;
            }
            this.getUniquePlanNameIndices(JSON.parse(JSON.stringify(data['_body'])));
          } else {
            if(this.policyType===undefined)
            {
              this.policyType=[];
            }
            for (const policy of this.policyType) {
             // policy.policyTypeName = 'Business Package For Home';
              policy['isActive'] = 1;
            }
          }
          for (const dateField of this.dateFields['_results']) {
            dateField.firstBlur = true;
          }
          this.insertCCWESheetData(this.policyType, tableData);
        }).catch(error => {
          console.log(error);
          this.ccweLoadingFlag = false;
          this.ccweSheetValidationMessage = error['_body']['message'];
          this.rateSheetFlagCreateProduct = false;
        });
      }
    }
  }

  file(fileInputs: any) {
    this.ValidationMessage = '';
    this.isvalue = true;
    this.isdeletefile = true;
    this.selectedRateSheetFile = new Object();
    this.selectedRateSheetFile['fileData'] = fileInputs.target.files[0];
    this.selectedRateSheetFile['completeFileName'] = fileInputs.target.files[0].name;
    const splitFileName = this.selectedRateSheetFile['completeFileName'].split('.');
    this.selectedRateSheetFile['fileName'] = this.selectedRateSheetFile['completeFileName'];
    const nameSegmentLength = this.selectedRateSheetFile['completeFileName'].split('.').length;
    const selectedFileExtension = this.selectedRateSheetFile['completeFileName'].split('.')[nameSegmentLength - 1];
    if (selectedFileExtension !== 'xlsx' && selectedFileExtension !== 'xls') {
      this.ValidationMessage = 'Invalid File Type.Only Excel File Type Is Allowed';
    } else {
      this.ValidationMessage = '';
    }
    if(this.ValidationMessage==='')
    {
      if(!this.selectedRateSheetFile['completeFileName'].match(this.pattern))
      {
        this.ValidationMessage = 'Invalid File Name.Special Characters Are Not Allowed.';
      }
      else{
        this.ValidationMessage = '';
      }
    }
  }

  ccwefile(fileInputs: any) {
    this.CCWEValidationMessage = '';
    this.isvalue1 = true;
    this.isdeleteCCWEfile = true;
    this.selectedCCWESheetFile = new Object();
    this.selectedCCWESheetFile['fileData'] = fileInputs.target.files[0];
    this.selectedCCWESheetFile['completeFileName'] = fileInputs.target.files[0].name;
    const splitFileName = this.selectedCCWESheetFile['completeFileName'].split('.');
    this.selectedCCWESheetFile['fileName'] = this.selectedCCWESheetFile['completeFileName'];
    const nameSegmentLength = this.selectedCCWESheetFile['completeFileName'].split('.').length;
    const selectedFileExtension = this.selectedCCWESheetFile['completeFileName'].split('.')[nameSegmentLength - 1];
    if (selectedFileExtension !== 'xlsx' && selectedFileExtension !== 'xls') {
      this.CCWEValidationMessage = 'Invalid File Type.Only Excel File Type Is Allowed';
    } else {
      this.CCWEValidationMessage = '';
    }
    if(this.CCWEValidationMessage==='')
    {
      if(!this.selectedCCWESheetFile['completeFileName'].match(this.pattern))
      {
        this.CCWEValidationMessage = 'Invalid File Name.Special Characters Are Not Allowed.';
      }
      else{
        this.CCWEValidationMessage = '';
      }
    }
   }

  productTypeChange() {
    if (String(this.productType) === '2') {
      this.bussiness = true;
      this.productDetails.lobId = '2';
      if (String(this.subProductType) === '1') {
        this.productDetails.shortProductName = 'Home';
      } else if (String(this.subProductType) === '2') {
        this.productDetails.shortProductName = 'Shop';
      }
      this.productDetails.shortProductName = '';
    } else if (String(this.productType) === '1') {
      this.bussiness = false;
      this.productDetails.lobId = '1';
      this.productDetails.shortProductName = 'Health';
      this.subProductType = undefined;
    }
    this.firstSubProductChange = true;
    if (!this.firstProductChange) {
      this.productChangeRoutine();
      EmitterService.get('HIDE_VALIDATION_MESSAGE').emit(true);
    } else {
      this.firstProductChange = false;
    }
  }

  subProductTypeChange() {
    if (String(this.productType) === '2') {
      this.bussiness = true;
      this.productDetails.lobId = '2';
      if (String(this.subProductType) === '1') {
        this.productDetails.shortProductName = 'Home';
        this.productDetails.productName = 'Liberty Home Comprehensive Package Policy';
      } else if (String(this.subProductType) === '2') {
        this.productDetails.shortProductName = 'Shop';
        this.productDetails.productName = 'Business Package';
      }
    } else if (String(this.productType) === '1') {
      this.bussiness = false;
      this.productDetails.lobId = '1';
      this.productDetails.shortProductName = 'Health';
      this.subProductType = undefined;
    }
    if (!this.firstSubProductChange) {
      this.productChangeRoutine();
      EmitterService.get('HIDE_VALIDATION_MESSAGE').emit(true);
    } else {
      this.firstSubProductChange = false;
    }
    this.validateProductName();
  }

  productChangeRoutine() {
    const copyProdDetails: ProductDetailsVO = JSON.parse(JSON.stringify(this.productDetails));
    this.productDetails = new ProductDetailsVO();
    this.productDetails.lobId = copyProdDetails.lobId;
    this.productDetails.shortProductName = copyProdDetails.shortProductName;
    this.productDetails.productName = copyProdDetails.productName;

    for (const dateField of this.dateFields['_results']) {
      dateField.firstBlur = true;
    }

    this.AddingQuestion = '';
    this.AdditionalProjectData = [];
    this.Docvalues = '';
    this.PremiumFilesArray = [];
    this.ValidationMessage = '';
    this.CCWEValidationMessage = '';
    this.backdateInceptionFlag = false;
    this.customValidationMessage = false;
    this.document = [];
    this.enableSubmit = false;
    this.files = undefined;
    this.hasErrorMessage = false;
    this.hasSuccess = false;
    this.inceptionRangeFieldControl = 'readonly';
    this.indexValue = undefined;
    this.insertingProduct = [];
    this.isSubmit = false;
    this.isdeletefile = false;
    this.isdeleteCCWEfile = false;
    this.isvalue = false;
    this.isvalue1 = false;
    this.isvalues = false;
    this.masterPolicy = undefined;
    this.masterPolicyRangeFlagArray = [];
    this.masterPolicyRangeFlagArray.push(true);
    this.masterPolicyRangeMessageArray = [];
    this.masterPolicyRangeMessageArray.push('');
    this.policyType = undefined;
    this.premiumDoc = undefined;
    this.premiumfiles = [];
    this.rateSheetEffectiveDate = undefined;
    this.projectsDatas = [];
    this.status = false;
    this.statusVal = false;
    this.selectedRateSheetFile = undefined;
    this.selectedCCWESheetFile = undefined;
    this.selectedAdditionalDocFile = undefined;
    this.additionalDocLabel = undefined;
    this.policyTypeArray = [];
    //  this.planTypeArray = undefined;
    this.planTypes = {};
    this.masterPolicyMessageArray = [];
    this.masterPolicyMessageArray.push('');
    this.masterPolicyFlag = false;
    this.masterPolicyTermArray = [];
    this.masterPolicyTermArray.push(undefined);
    this.selectAllQuestions = true;
    EmitterService.get('MULTISELECT_INITIALIZE').emit(true);
    this.productService.masterQuestions().then(data => {
      this.questions = data;
      for (const question of this.questions) {
        question['Checked'] = true;
        delete question['questionId'];
      }
    });
    this.paymentService.getPaymentGatewayList().then(data => {
      this.dataArray = data;
    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
      }
    );
  }

  deleteFile() {
    this.isdeletefile = false;
    this.isvalue = false;
    this.rateSheetEffectiveDate = undefined;
    this.selectedRateSheetFile = undefined;
    this.effectiveDateMessage = '';
    this.ValidationMessage = '';
    this.rateSheetValidationMessage = '';
    for (const dateField of this.dateFields['_results']) {
      dateField.firstBlur = true;
    }
  }

  deleteCCWEFile() {
    this.isdeleteCCWEfile = false;
    this.isvalue1 = false;
    this.ccweSheetEffectiveDate = undefined;
    this.selectedCCWESheetFile = undefined;
    this.ccweEffectiveDateMessage = '';
    this.CCWEValidationMessage = '';
    this.ccweSheetValidationMessage = '';
    
  }

  deleteFiles() {
    this.Docvalues = null;
    this.isdeletefiles = false;
    this.isvalues = false;
    this.additionalDocLabel = '';
    this.additionalDocumentMessage = '';
    this.additionalDocFilenameMessage = '';
    this.documentLabelField.validationMessage = '';
    const _this = this;
    setTimeout(function () {
      _this.outSidevalidate();
    });
  }
  private outSidevalidate() {
    let textResult: any = false;
    let selectResult: any = false;
    let dateResult: any = false;
    let multiselectResult: any = true;
    let hasTextField: Boolean = false;
    let hasSelectField: Boolean = false;
    let hasDateField: Boolean = false;
    let hasMultiselectField: Boolean = false;
    let masterPolicyRangeFlag: Boolean = true;
    let questionsFlag: Boolean = false;
    let hasQuestions: Boolean = false;
    if (this.productDetails.shortProductName === 'Health') {
      hasQuestions = true;
    }
    if (hasQuestions) {
      for (const question of this.questions) {
        if (question['Checked']) {
          questionsFlag = true;
          break;
        }
      }
    }
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        break;
      }
    }
    for (const selectField of this.selectFields['_results']) {
      hasSelectField = true;
      const input: SelectComponent = selectField;
      selectResult = input.onBlurMethod(false);
      if (!selectResult) {
        break;
      }
    }
    for (const dateField of this.dateFields['_results']) {
      hasDateField = true;
      const input: DatePickerComponent = dateField;
      dateResult = input.onBlurMethod(false);
      if (!dateResult) {
        break;
      }
    }
    if (this.editProduct) {
      for (const masterPolicyFlag of this.masterPolicyRangeFlagArray) {
        if (!masterPolicyFlag && masterPolicyFlag !== undefined) {
          masterPolicyRangeFlag = false;
          break;
        }
      }
    } else {
      for (const masterPolicyFlag of this.masterPolicyRangeFlagArray) {
        if (!masterPolicyFlag) {
          masterPolicyRangeFlag = false;
          break;
        }
      }
    }
    for (const multiselectField of this.multiselectFields['_results']) {
      hasMultiselectField = true;
      const input: MultiselectComponent = multiselectField;
      multiselectResult = input.onBlurMethod(false);
      if (!multiselectResult) {
        break;
      }
    }
   // this.paymentOptionsFlag && removed
    const result: any =
      (textResult || !hasTextField) &&
      (selectResult || !hasSelectField) &&
      (dateResult || !hasDateField) &&
      (multiselectResult || !hasMultiselectField) &&
      this.productDateFlag && masterPolicyRangeFlag && this.masterPolicyFlag && this.productNameFlag &&
      (!hasQuestions || questionsFlag);
    if (result) {
      this.enableSubmit = true;
      return true;

    } else {
      this.enableSubmit = false;
      return false;
    }

  }

  public enableBackdateInception(flag: boolean) {
    this.backdateInceptionFlag = flag;
    this.inceptionRangeFieldControl = '';
    if (!flag) {
      this.productDetails.backDatedInceptionRange = null;
      this.inceptionRangeFieldControl = 'readonly';
      this.productDetails.backDatedInception = false;
    } else {
      this.inceptionRangeFieldControl = '';
      this.productDetails.backDatedInception = true;
    }
    this.productVo.BackdatedInception = flag;
    const _this = this;
    setTimeout(function () {
      _this.outSidevalidate();
    });
  }

  addMasterPolicy() {
    if (this.productDetails.productMasterPolicy.length < 3) {
      if (this.productDetails.productMasterPolicy.length === 0) {
        this.productDetails.productMasterPolicy.push(new ProductMasterPolicyVO());
        this.masterPolicyRangeFlagArray.push(false);
        this.masterPolicyRangeMessageArray.push('');
        this.masterPolicyMessageArray.push('');
        this.masterPolicyTermArray.push(undefined);
      } else {
        this.productDetails.productMasterPolicy.push(new ProductMasterPolicyVO());
        this.masterPolicyMessageArray.push('');
        this.masterPolicyTermArray.push(undefined);
      }
    } else {
    }
    const _this = this;
    setTimeout(function () { _this.outSidevalidate(); });
  }

  deleteMasterPolicy(index: any) {
    this.productDetails.productMasterPolicy.splice(index, 1);
    this.masterPolicyMessageArray.splice(index, 1);
    this.masterPolicyTermArray.splice(index, 1);
    for (let i = 0; i < this.productDetails.productMasterPolicy.length; i++) {
      this.validateMasterPolicy(i);
    }
    const _this = this;
    setTimeout(
      function () {
        _this.outSidevalidate();
      }.bind(_this), 500
    );
  }

  // setAppliance(event, k, i) {
  //   this.policyType[k].planType[i].planAppliance = [];
  //   const applianceList = JSON.parse(event);
  //   for (const appliance of applianceList) {
  //     const applianceObj = new Object();
  //     applianceObj['appliances'] = appliance;
  //     this.policyType[k].planType[i].planAppliance.push(applianceObj);
  //   }
  // }
  setAppliance(event, name) {
    let applianceList;
    if (this.editProduct) {
      applianceList = event;
    } else {
      applianceList = JSON.parse(event);
    }
    // //  this.policyType[k].planType[i].planAppliance = [];
    let applianceFlag = false;
    const applianceObj = new Object();
    for (let i = 0; i < this.applianceForUniquePlans.length; i++) {
      if (this.applianceForUniquePlans[i]['applianceName'] === name) {
        this.applianceForUniquePlans[i]['appliances'] = applianceList;
        applianceFlag = true;
        break;
      }
    }
    if (!applianceFlag) {
      applianceObj['appliances'] = applianceList;
      applianceObj['applianceName'] = name;
      this.applianceForUniquePlans.push(applianceObj);
    }
  }
    // for (const appliance of applianceList) {
    //   const applianceObj = new Object();
    //   applianceObj['appliances'] = appliance;
    //   applianceObj['applianceName']  = name;
      // for (let i = 0; i < this.policyTypeArray.length; i++) {
      //   console.log(this.policyTypeArray);
      //   const planType = this.policyTypeArray[i]['planType'];
      //   for (let j = 0; j < planType.length; j++) {
      //     if (planType[j].planTypeName === applianceName) {
      //       this.policyTypeArray[i]['planType'][j].planAppliance = [];
      //       console.log(this.policyTypeArray[i]['planType'][j].planAppliance);
      //       planAppliance.push(applianceObj);
      //       this.policyTypeArray[i]['planType'][j].planAppliance = planAppliance;
      //       console.log(this.policyTypeArray[i]['planType'][j].planAppliance);
      //     }
      //   }
      // }
      // this.policyType[k].planType[i].planAppliance.push(applianceObj);
   // }

  checkAction(data: any) {
    if (!data['Checked']) {
      this.selectAllQuestions = false;
    }
    let checkedFlag = true;
    for (const question of this.questions) {
      if (!question['Checked']) {
        checkedFlag = false;
        break;
      }
    }
    if (checkedFlag) {
      this.selectAllQuestions = true;
    }
    const _this = this;
    setTimeout(
      function () {
        _this.outSidevalidate();
      }.bind(_this)
    );
  }
  ViewProducts() {
    this.router.navigateByUrl('welcome/(contentBody:product)');
  }
  public addTimestamp(edit: Boolean, shortProduct: any, productDetails: ProductDetailsVO) {
    const timeStamp = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
    if (!edit) {
      productDetails['creationTimeStamp'] = timeStamp;
      productDetails['lastUpdatedTimeStamp'] = timeStamp;
      for (const document of productDetails.productDocuments) {
        document['creationTimeStamp'] = timeStamp;
        document['lastUpdatedTimeStamp'] = timeStamp;
      }
      for (const rateSheet of productDetails.rateSheetDocuments) {
        rateSheet['creationTimeStamp'] = timeStamp;
        console.log(rateSheet['lastUpdatedTimeStamp']);
        rateSheet['lastUpdatedTimeStamp'] = timeStamp;
      }
      for (const ccweSheet of productDetails.ccweSheetDocuments) {
        ccweSheet['creationTimeStamp'] = timeStamp;
        ccweSheet['lastUpdatedTimeStamp'] = timeStamp;
      }
      for (const masterPolicy of productDetails.productMasterPolicy) {
        masterPolicy['creationTimeStamp'] = timeStamp;
        masterPolicy['lastUpdatedTimeStamp'] = timeStamp;
      }
      if (shortProduct === 'Health') {
        for (const question of productDetails.productQuestion) {
          question['creationTimeStamp'] = timeStamp;
          question['lastUpdatedTimeStamp'] = timeStamp;
        }
      }
      for (const policy of productDetails.policyType) {
        policy['creationTimeStamp'] = timeStamp;
        policy['lastUpdatedTimeStamp'] = timeStamp;
        if (this.productDetails.shortProductName === 'Health') {
       //   for (let i = 0; i < policy.length; i++) {
            const planType  = policy['planType'];
            for (let j = 0; j < planType.length; j++) {
              planType[j]['creationTimeStamp'] = timeStamp;
              planType[j]['lastUpdatedTimeStamp'] = timeStamp;
            }
        //  }
        } else {
          for (const plan of policy.planType) {
            plan['creationTimeStamp'] = timeStamp;
            plan['lastUpdatedTimeStamp'] = timeStamp;
            if (shortProduct === 'Home') {
              for (const appliance of plan.planAppliance) {
                appliance['creationTimeStamp'] = timeStamp;
                appliance['lastUpdatedTimeStamp'] = timeStamp;
              }
            }
          }
        }
      }
    }
  }
  public addTimestampForUpdate(edit: Boolean, shortProduct: any, productDetails: ProductDetailsVO) {
    const timeStamp = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
    //   if (!edit) {
    productDetails['lastUpdatedTimeStamp'] = timeStamp;
    for (const document of productDetails.productDocuments) {
      document['creationTimeStamp'] = timeStamp;
      document['lastUpdatedTimeStamp'] = timeStamp;
    }
    for (const rateSheet of productDetails.rateSheetDocuments) {
      if (rateSheet.rateSheetDocumentId === undefined) {
        rateSheet['creationTimeStamp'] = timeStamp;
        rateSheet['lastUpdatedTimeStamp'] = timeStamp;
      } else {
        rateSheet['lastUpdatedTimeStamp'] = timeStamp;
      }
    }
    for (const ccweSheet of productDetails.ccweSheetDocuments) {
      if (ccweSheet.ccwesheetDocumentId === undefined) {
        ccweSheet['creationTimeStamp'] = timeStamp;
        ccweSheet['lastUpdatedTimeStamp'] = timeStamp;
      } else {
        ccweSheet['lastUpdatedTimeStamp'] = timeStamp;
      }
    }
    for (const masterPolicy of productDetails.productMasterPolicy) {
      if (masterPolicy.masterPolicyId === undefined) {
        masterPolicy['creationTimeStamp'] = timeStamp;
        masterPolicy['lastUpdatedTimeStamp'] = timeStamp;
      } else {
        masterPolicy['lastUpdatedTimeStamp'] = timeStamp;
      }
    }
    if (shortProduct === 'Health') {
      for (const question of productDetails.productQuestion) {
        question['creationTimeStamp'] = timeStamp;
        question['lastUpdatedTimeStamp'] = timeStamp;
      }
    }
    for (const policy of productDetails.policyType) {
      console.log("policy = " +policy);
      if (policy.policyTypeId === undefined) {
        policy['creationTimeStamp'] = timeStamp;
        policy['lastUpdatedTimeStamp'] = timeStamp;
      } else {
        policy['lastUpdatedTimeStamp'] = timeStamp;
      }
      for (const plan of policy.planType) {
        if (plan.planid === undefined) {
          plan['creationTimeStamp'] = timeStamp;
          plan['lastUpdatedTimeStamp'] = timeStamp;
        } else {
          plan['lastUpdatedTimeStamp'] = timeStamp;
        }
        if (shortProduct === 'Home') {
          for (const appliance of plan.planAppliance) {
            appliance['creationTimeStamp'] = timeStamp;
            appliance['lastUpdatedTimeStamp'] = timeStamp;
          }
        }
      }
    }
    //  }
  }
  createProducts() {
    const dateContainer = new Object();
    dateContainer['endDate'] = this.productDetails.endDate;
    dateContainer['startDate'] = this.productDetails.startDate;
    dateContainer['rateSheetEffectiveDate'] = this.productDetails.rateSheetEffectiveDate;
    dateContainer['productMasterPolicy'] = this.productDetails.productMasterPolicy;
    if (this.enableSubmit && !this.editProduct) {
      // TO-DO
      for (const paymentOption of this.PaymentArray) {
        if (paymentOption['Checked']) {
          const gatewayObj = new Object();
          gatewayObj['gatewayId'] = paymentOption['gatewayId'] ? paymentOption['gatewayId'] : 0;
          gatewayObj['walletId'] = paymentOption['walletId'] ? paymentOption['walletId'] : 0;
          this.productDetails.productGateway.push(gatewayObj);
        }
      }

      this.productDetails.endDate = moment(this.productDetails.endDate).local().format('DD/MM/YYYY HH:mm:ss');
      this.productDetails.startDate = moment(this.productDetails.startDate).local().format('DD/MM/YYYY HH:mm:ss');
      for (const additionalDoc of this.AdditionalProjectData) {
        const additionalFile = new Object();
        additionalFile['documentName'] = String(additionalDoc['Uploaded Files']);
        additionalFile['documentLabel'] = String(additionalDoc['Document Label']);
        const fileSegmentCount = additionalDoc['Uploaded Files'].split('.').length;
        additionalFile['documentType'] = String(additionalDoc['Uploaded Files']).split('.')[fileSegmentCount - 1];
        this.productDetails.productDocuments.push(additionalFile);
      }
      for (const rateSheet of this.rateSheetMetaDataArray) {
        const additionalFile = new Object();
        additionalFile['documentName'] = String(rateSheet['Uploaded File Name']);
        additionalFile['effectiveDate'] = String(rateSheet['Effective Date']);
        additionalFile['isActive'] = rateSheet['isActive'];
        this.productDetails.rateSheetDocuments.push(additionalFile);
      }
      for (const question of this.questions) {
        if (question['Checked']) {
          const questionWrapper = new Object();
          questionWrapper['questionName'] = question['question'];
          this.productDetails.productQuestion.push(questionWrapper);
        }
      }
      for (const masterPolicy of this.productDetails.productMasterPolicy) {
        masterPolicy['startDate'] = moment(masterPolicy['startDate']).local().format('DD/MM/YYYY HH:mm:ss');
        masterPolicy['endDate'] = moment(masterPolicy['endDate']).local().format('DD/MM/YYYY HH:mm:ss');
      }
      if (this.productDetails.shortProductName === 'Home') {
        for (let i = 0; i < this.applianceForUniquePlans.length; i++) {
          const selectedAppliance = this.applianceForUniquePlans[i].applianceName;
          const appliancesLists = this.applianceForUniquePlans[i].appliances;
          for (let j = 0; j < this.policyTypeArray.length; j++) {
            const plans = this.policyTypeArray[j]['planType'];
            for (let l = 0; l < plans.length; l++) {
              if (plans[l]['planTypeName'] === selectedAppliance) {
                const applianceData =  [];
                for (const appliance of appliancesLists) {
                  const applianceObj = new Object();
                  applianceObj['appliances'] = appliance;
                  applianceData.push(applianceObj);
                }
                this.policyTypeArray[j]['planType'][l].planAppliance = applianceData;
              }
            }
          }
        }
      }
      this.productDetails.policyType = this.policyTypeArray;
      this.addTimestamp(false, this.productDetails.shortProductName, this.productDetails);
      const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
      this.productDetails.affinityId = affinity['id'];
      const saveProduct = new Object();
      saveProduct['productDetails'] = this.productDetails;

      if (String(this.productDetails.lobId) === '1') {
        // Health
      } else if (String(this.productDetails.lobId) === '2') {
        // Business Package Home
        delete this.productDetails.productQuestion;
      }
      const policyData = JSON.stringify(saveProduct);
      
      this.productService.saveProduct(saveProduct).then(data => {
        // AWS document upload snippet start
        const formData: any = new FormData();
        const formData2: any = new FormData();
        const formData3: any = new FormData();
        const productId = data['productId'];
        const rateSheetDocument = this.productDetails.rateSheetDocuments;
        const ccweSheetDocument = this.productDetails.ccweSheetDocuments;
        
        for (let i = 0; i < this.AdditionalProjectData.length; i++) {
          formData.append('file', this.AdditionalProjectData[i]['File'], this.AdditionalProjectData[i]['Uploaded Files']);
        }
        for (let j= 0; j< this.rateSheetForS3List.length; j++) {
          if(this.rateSheetForS3List[j]['rateSheetDocumentId'] === undefined) {
            formData2.append('file', this.rateSheetForS3List[j]['File'], this.rateSheetForS3List[j]['Uploaded File Name']);

          }
        }
        for (let k= 0; k< this.ccweSheetForS3List.length; k++) {
          if(this.ccweSheetForS3List[k]['ccwesheetDocumentId'] === undefined) {
            formData3.append('file', this.ccweSheetForS3List[k]['File'], this.ccweSheetForS3List[k]['Uploaded File Name']);

          }
        }
        // 22/06/2020 commenting below method -no longer being used
        // this.productService.uploadAdditionalDocuments(formData).then(uploadResponse => console.log('Document upload successful'))
        //   .catch(error => console.log('Document upload failed'));
        // AWS document upload snippet end
        this.productService.uploadProductDocumentsInS3(affinity['id'], productId, formData).then(documentUploadRes => {
        console.log('Document upload successful in S3');
        }).catch(error => {
          console.log('Document upload failed in S3');
        });
        this.productService.uploadProductRateSheetInS3(affinity['id'], productId, formData2).then(documentUploadRes => {
          console.log('Ratesheet upload successful in S3');
          }).catch(error => {
            console.log('Ratesheet upload failed in S3');
          });
          this.productService.uploadCCWESheetInS3(affinity['id'], productId, formData3).then(documentUploadRes => {
            console.log('CCWE Sheet upload successful in S3');

            this.productService.saveCCWEFileData(affinity['id'], this.productDetails.shortProductName, formData3,this.productDetails.ccweSheetEffectiveDate).then(documentUploadRes => {
              console.log('CCWE File Data Saved successfully.');
              }).catch(error => {
                console.log('Failure While Saving CCWE File Data.');
              });
              const CCWELastUpdatedTimeStamp = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
              for (const ccweSheet of this.ccweSheetMetaDataArray) {
                const ccweFile = new Object();
                ccweFile['documentName'] = String(ccweSheet['Uploaded File Name']);
                ccweFile['effectiveDate'] = String(ccweSheet['Effective Date']);
                ccweFile['creationTimeStamp'] = ccweSheet['Upload Date & Time'] ? ccweSheet['Upload Date & Time'] : CCWELastUpdatedTimeStamp;
                ccweFile['lastUpdatedTimeStamp'] = CCWELastUpdatedTimeStamp;
                ccweFile['isActive'] =   ccweSheet['isActive'];
                ccweFile['affinityId'] = this.productDetails.affinityId;
                ccweFile['productId'] = data['productId'];
                ccweFile['productType'] = this.productDetails.shortProductName;
                this.productDetails.ccweSheetDocuments.push(ccweFile);
              }
              saveProduct['productDetails'] = this.productDetails;
              const ccweRequestWrapper = new Object();
              ccweRequestWrapper['ccweSheetDocumentsList']= new Object();
              ccweRequestWrapper['ccweSheetDocumentsList']['ccweSheetResource']=new Object();
              ccweRequestWrapper['ccweSheetDocumentsList']['ccweSheetResource'] = saveProduct['productDetails']['ccweSheetDocuments'];
              console.log("ccweRequestWrapper= " + ccweRequestWrapper);
              this.productService.saveCCWESheetList(ccweRequestWrapper).then(documentUploadRes => {
                console.log('CCWESheet Documents List Saved Successfully..');
                }).catch(error => {
                  console.log('Failure While Saving CCWESheet Documents List.');
                });
            }).catch(error => {
              console.log('CCWE sheet upload failed in S3');
            });
            
        this.productService.mapProductToAffinity(data['productId'], affinity['id']).then(affinityData => {
          sessionStorage.setItem('MENU_UPDATE_EVENT', 'true');
          EmitterService.get('MENU_UPDATE_EVENT').emit(true);
        }).catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
        let productType: any;
        if (String(this.productType === '1')) {
          productType = 'Health';
        } else if (String(this.productType === '2')) {
          if (String(this.subProductType === '1')) {
            productType = 'Home';
          } else if (String(this.subProductType === '2')) {
            productType = 'Shop';
          }
        }
        this.navigationService.mapMenuToProduct(productId, this.productDetails.productName,
          this.productDetails.shortProductName,
          affinity['id'],
          this.productDetails.policyEndorsement ? 'Y' : 'N',
          this.productDetails.policyRenewal ? 'Y' : 'N',
          moment(this.productDetails.startDate, 'DD/MM/YYYY HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss'),
          moment(this.productDetails.endDate, 'DD/MM/YYYY HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss')).then(mappingData => {
          }).catch(error => {
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
        this.isSubmit = true;
        this.isvalue = false;
        this.isvalue1 = false;
        this.isvalues = false;
        this.topFunction();
        this.hasSuccess = true;

        this.productDetails.endDate = dateContainer['endDate'];
        this.productDetails.startDate = dateContainer['startDate'];
        this.productDetails.rateSheetEffectiveDate = dateContainer['rateSheetEffectiveDate'];
        this.productDetails.productMasterPolicy = [];
        for (const dateObj of dateContainer['productMasterPolicy']) {
          const masterDateObj = new Object();
          masterDateObj['masterPolicyId'] = dateObj['masterPolicyId'];
          masterDateObj['startDate'] = moment(dateObj['startDate'], 'DD/MM/YYYY HH:mm:ss');
          masterDateObj['endDate'] = moment(dateObj['endDate'], 'DD/MM/YYYY HH:mm:ss');
          this.productDetails.productMasterPolicy.push(masterDateObj);
        }
      }).catch(error => {
     //   this.openActionFailDialog('Product creation failed');
        this.productDetails.endDate = dateContainer['endDate'];
        this.productDetails.startDate = dateContainer['startDate'];
        this.productDetails.rateSheetEffectiveDate = dateContainer['rateSheetEffectiveDate'];
        this.productDetails.productMasterPolicy = [];
        for (const dateObj of dateContainer['productMasterPolicy']) {
          const masterDateObj = new Object();
          masterDateObj['masterPolicyId'] = dateObj['masterPolicyId'];
          masterDateObj['startDate'] = moment(dateObj['startDate'], 'DD/MM/YYYY HH:mm:ss');
          masterDateObj['endDate'] = moment(dateObj['endDate'], 'DD/MM/YYYY HH:mm:ss');
          this.productDetails.productMasterPolicy.push(masterDateObj);
        }
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
    } else if (this.enableSubmit && this.editProduct) {
      const lastUpdatedTimeStamp = moment(new Date()).local().format('DD/MM/YYYY HH:mm:ss');
      this.productDetails['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
      this.productDetails.productGateway = [];
      for (const paymentOption of this.PaymentArray) {
        if (paymentOption['Checked']) {
          const gatewayObj = new Object();
          gatewayObj['gatewayId'] = paymentOption['gatewayId'] ? paymentOption['gatewayId'] : 0;
          gatewayObj['walletId'] = paymentOption['walletId'] ? paymentOption['walletId'] : 0;
          for (const originalGateway of this.originalGatewayArray) {
            if (String(originalGateway['gatewayId']) !== '0') {
              if (String(originalGateway['gatewayId']) === String(gatewayObj['gatewayId'])) {
                if (originalGateway['productGatewayId']) {
                  gatewayObj['productGatewayId'] = originalGateway['productGatewayId'];
                }
                break;
              }
            } else if (String(originalGateway['walletId']) !== '0') {
              if (String(originalGateway['walletId']) === String(gatewayObj['walletId'])) {
                if (originalGateway['productGatewayId']) {
                  gatewayObj['productGatewayId'] = originalGateway['productGatewayId'];
                }
                break;
              }
            }
          }
          this.productDetails.productGateway.push(gatewayObj);
        }
      }

      this.productDetails.endDate = moment(this.productDetails.endDate).local().format('DD/MM/YYYY HH:mm:ss');
      this.productDetails.startDate = moment(this.productDetails.startDate).local().format('DD/MM/YYYY HH:mm:ss');
      //   this.productDetails.rateSheetEffectiveDate = moment(this.productDetails.rateSheetEffectiveDate).local().format('DD/MM/YYYY HH:mm:ss');
      this.productDetails.rateSheetEffectiveDate = this.productDetails.rateSheetEffectiveDate;
      // delete this.productDetails.rateSheetEffectiveDate;
      const rateSheetList = [];
      for (const rateSheet of this.rateSheetMetaDataArray) {

        // TO-DO : Removal

        /* let flag = false;
         for (const existingDoc of this.productDetails.rateSheetDocuments) {
           if (String(existingDoc['rateSheetDocumentId']) === String(rateSheet['rateSheetDocumentId'])) {
             flag = true;
             existingDoc['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
             break;
           }
         }
         if (flag) {
           continue;
         }
         const additionalFile = new Object();
         additionalFile['documentName'] = String(rateSheet['Uploaded File Name']);
         additionalFile['effectiveDate'] = String(rateSheet['Effective Date']);
         additionalFile['creationTimeStamp'] = rateSheet['Upload Date & Time'] ? rateSheet['Upload Date & Time'] : lastUpdatedTimeStamp;
         additionalFile['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
         this.productDetails.rateSheetDocuments.push(additionalFile); */

        const additionalFile = new Object();
        additionalFile['documentName'] = String(rateSheet['Uploaded File Name']);
        additionalFile['effectiveDate'] = String(rateSheet['Effective Date']);
        additionalFile['creationTimeStamp'] = rateSheet['Upload Date & Time'] ? rateSheet['Upload Date & Time'] : lastUpdatedTimeStamp;
        additionalFile['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
        additionalFile['isActive'] = rateSheet['isActive'];
        if (rateSheet['rateSheetDocumentId']) {
          additionalFile['rateSheetDocumentId'] = rateSheet['rateSheetDocumentId'];
        }
        rateSheetList.push(additionalFile);
      }
      this.productDetails.rateSheetDocuments = rateSheetList;

      this.productDetails.rateSheetDocuments = rateSheetList;


      const ccweSheetList = [];
      for (const ccweSheet of this.ccweSheetMetaDataArray) {

        // TO-DO : Removal

        /* let flag = false;
         for (const existingDoc of this.productDetails.ccweSheetDocuments) {
           if (String(existingDoc['ccwesheetDocumentId']) === String(ccweSheet['ccwesheetDocumentId'])) {
             flag = true;
             existingDoc['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
             break;
           }
         }
         if (flag) {
           continue;
         }
         const additionalCCWEFile = new Object();
         additionalCCWEFile['documentName'] = String(ccweSheet['Uploaded File Name']);
         additionalCCWEFile['effectiveDate'] = String(ccweSheet['Effective Date']);
         additionalCCWEFile['creationTimeStamp'] = ccweSheet['Upload Date & Time'] ? ccweSheet['Upload Date & Time'] : lastUpdatedTimeStamp;
         additionalCCWEFile['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
         this.productDetails.ccweSheetDocuments.push(additionalCCWEFile); */

        const additionalCCWEFile = new Object();
        additionalCCWEFile['documentName'] = String(ccweSheet['Uploaded File Name']);
        additionalCCWEFile['effectiveDate'] = String(ccweSheet['Effective Date']);
        additionalCCWEFile['creationTimeStamp'] = ccweSheet['Upload Date & Time'] ? ccweSheet['Upload Date & Time'] : lastUpdatedTimeStamp;
        additionalCCWEFile['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
        additionalCCWEFile['isActive'] = ccweSheet['isActive'];
        if (ccweSheet['ccwesheetDocumentId']) {
          additionalCCWEFile['ccwesheetDocumentId'] = ccweSheet['ccwesheetDocumentId'];
        }
        additionalCCWEFile['affinityId'] = this.productDetails.affinityId;
        additionalCCWEFile['productId'] = this.editProductId;
        additionalCCWEFile['productType'] = this.productDetails.shortProductName;
        ccweSheetList.push(additionalCCWEFile);
      }
      this.productDetails.ccweSheetDocuments = ccweSheetList;


      this.productDetails.productDocuments = [];
      for (const additionalDoc of this.AdditionalProjectData) {
        // let flag = false;
        // for (const existingDoc of this.productDetails.productDocuments) {
        //   if (String(existingDoc['productDocumentId']) === String(additionalDoc['productDocumentId'])) {
        //     flag = true;
        //     existingDoc['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
        //     break;
        //   }
        // }
        // if (flag) {
        //   continue;
        // }
        const additionalFile = new Object();
        if (additionalDoc['productDocumentId'] !== undefined) {
          additionalFile['productDocumentId'] = additionalDoc['productDocumentId'];
        }
        additionalFile['documentName'] = String(additionalDoc['Uploaded Files']);
        const fileSegmentCount = additionalDoc['Uploaded Files'].split('.').length;
        additionalFile['documentType'] = String(additionalDoc['Uploaded Files']).split('.')[fileSegmentCount - 1];
        additionalFile['documentLabel'] = String(additionalDoc['Document Label']);
        additionalFile['creationTimeStamp'] = additionalDoc['creationTimeStamp'] ? additionalDoc['creationTimeStamp'] : lastUpdatedTimeStamp;
        additionalFile['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
        this.productDetails.productDocuments.push(additionalFile);
      }
      const productQuestions = JSON.parse(JSON.stringify(this.productDetails.productQuestion));
      this.productDetails.productQuestion = [];
      for (const question of this.questions) {
        if (question['Checked']) {
          if (question['id']) {
            for (const prodQuestion of productQuestions) {
              if (String(question['id']) === String(prodQuestion['productQuestionId'])) {
                const questionWrapper = new Object();
                questionWrapper['questionName'] = question['question'];
                questionWrapper['productQuestionId'] = question['id'];
                questionWrapper['creationTimeStamp'] = question['creationTimeStamp'] ? question['creationTimeStamp'] : lastUpdatedTimeStamp;
                questionWrapper['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
                this.productDetails.productQuestion.push(questionWrapper);
              }
            }
          } else {
            const questionWrapper = new Object();
            questionWrapper['questionName'] = question['question'];
            questionWrapper['creationTimeStamp'] = lastUpdatedTimeStamp;
            questionWrapper['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
            this.productDetails.productQuestion.push(questionWrapper);
          }
        }
      }

      for (const masterPolicy of this.productDetails.productMasterPolicy) {
        masterPolicy['startDate'] = moment(masterPolicy['startDate']).local().format('DD/MM/YYYY HH:mm:ss');
        masterPolicy['endDate'] = moment(masterPolicy['endDate']).local().format('DD/MM/YYYY HH:mm:ss');
        masterPolicy['creationTimeStamp'] = masterPolicy['creationTimeStamp'] ? masterPolicy['creationTimeStamp'] : lastUpdatedTimeStamp;
        masterPolicy['lastUpdatedTimeStamp'] = lastUpdatedTimeStamp;
      }
      if (this.productDetails.shortProductName === 'Home') {
        for (let i = 0; i < this.applianceForUniquePlans.length; i++) {
          const selectedAppliance = this.applianceForUniquePlans[i].applianceName;
          const appliancesLists = this.applianceForUniquePlans[i].appliances;
          for (let j = 0; j < this.policyTypeArray.length; j++) {
            const plans = this.policyTypeArray[j]['planType'];
            for (let l = 0; l < plans.length; l++) {
              if (plans[l]['planAppliance'] === undefined ) {
                if (plans[l]['planTypeName'] === selectedAppliance) {
                  const applianceData =  [];
                  const applianceList = JSON.parse(appliancesLists);
                  for (const appliance of applianceList) {
                    const applianceObj = new Object();
                    applianceObj['appliances'] = appliance;
                    applianceData.push(applianceObj);
                  }
                  this.policyTypeArray[j]['planType'][l].planAppliance = applianceData;
                }
              }
            }
          }
        }
      }
      if (String(this.productDetails.shortProductName) === 'Health') {
        // Health
        this.productDetails.policyType = this.policyTypeArray;
      } else if (String(this.productDetails.shortProductName) === 'Home') {
        for (let i = 0; i < this.policyTypeArray.length; i++) {
          const plan = this.policyTypeArray[i]['planType'];
          for (let j = 0; j < plan.length; j++) {
            const planname = plan[j]['planTypeName'];
            const planAppliance = plan[j]['planAppliance'];
            for (let l = 0; l < this.applianceForUniquePlans.length; l++) {
              const appliancePlanName = this.applianceForUniquePlans[l]['applianceName'];
              const appliances = JSON.parse(this.applianceForUniquePlans[l]['appliances']);
              if (appliancePlanName === planname) {
                for (const appliance of appliances) {
                    for (let g = 0; g < planAppliance.length; g++) {
                      if (planAppliance[g]['planApplianceID'] !== undefined) {
                        if (appliance['applianceID'] === (planAppliance[g]['applianceID'] || planAppliance[g]['appliances']['applianceID'])) {
                          planAppliance[g]['availableFlag'] = true;
                          appliance['planApplianceID'] = planAppliance[g]['planApplianceID'];
                        } else {
                          if (!planAppliance[g]['availableFlag'] || planAppliance[g]['availableFlag'] === undefined) {
                            planAppliance[g]['availableFlag'] = false;
                            // if (planAppliance[g]['planApplianceID'] === undefined) {
                            //   // planAppliance[g]['planApplianceID'] ['availableFlag'] = true;
                            //   planAppliance[g]['appliances']['applianceID'] = appliance['applianceID'];
                            // }
                          }
                        }
                      }

                    }
                }
                // for (const appliance of appliances) {
                //   if (appliance['planApplianceID'] === undefined) {
                //     const applianceObj = new Object();
                //     applianceObj['appliances'] = appliance;
                //     plan[j]['planAppliance'].push(applianceObj);
                //   }
                // }
              }
            }
          }
        }
         for (let i = 0; i < this.policyTypeArray.length; i++) {
           const plan = this.policyTypeArray[i]['planType'];
           for (let j = 0; j < plan.length; j++) {
             const planAppliance = plan[j]['planAppliance'];
             if (planAppliance !== undefined) {
               for (let g = 0; g < planAppliance.length; g++) {
                 if (!planAppliance[g]['availableFlag'] && planAppliance[g]['availableFlag'] !== undefined) {
                   if (planAppliance[g]['planApplianceID'] !== undefined) {
                      planAppliance[g]['planApplianceID'] = 0;
                      // planAppliance[g].splice(g, 1);
                   }
                 }
               }
             }
           }

         }
        // Business Package Home
        // for (const originalPolicy of this.originalPolicyType) {
        //   for (const policy of this.policyType) {
        //     if (String(policy['policyTypeId']) === String(originalPolicy['policyTypeId'])) {
        //       for (const originalPlan of originalPolicy.planType) {
        //         for (const plan of policy.planType) {
        //           if (String(plan['planid']) === String(originalPlan['planid'])) {
        //         //    for (const originalPlanAppliance of originalPlan.planAppliance) {
        //               // let originalApplianceId: any;
        //               // for (const appliance of this.applianceList) {
        //               //   if (String(originalPlanAppliance['appliances']['applianceName']) === String(appliance['applianceName'])) {
        //               //     originalApplianceId = appliance['applianceID'];
        //               //   }
        //               // }
        //               const planName = plan['planTypeName'];
        //               for (let j = 0; j < this.applianceForUniquePlans.length; j++) {
        //                 const appliancePlanName = this.applianceForUniquePlans[j]['applianceName'];
        //                 const appliances = JSON.parse(this.applianceForUniquePlans[j]['appliances']);
        //                 if (planName === appliancePlanName) {
        //                   let applianceFlag: Boolean = false;
        //                   for (const appianceID of appliances) {
        //                     for (let i = 0; i < plan.planAppliance.length; i++) {
        //                       if (plan.planAppliance[i]['appliances']['applianceID'] === appianceID) {
        //                         applianceFlag = true;
        //                         break;
        //                   }
        //                     // for (const planAppliance of plan.planAppliance) {
        //                     //   if (planAppliance['appliances']['applianceID'] === appianceID) {
        //                     //     // planAppliance['planApplianceID'] = appianceID['planApplianceID'];
        //                     //     applianceFlag = true;
        //                     //     break;
        //                     //   }
        //                     // }
        //                       if (!applianceFlag) {
        //                         if (plan.planAppliance[i]['planApplianceID'] !== undefined) {
        //                           delete plan.planAppliance[i]['planApplianceID'];
        //                         } else {
        //                           plan.planAppliance[i]['appliances']['applianceID'] = appianceID;
        //                         }
        //                       }
        //                     }

        //                   }
        //                 }
        //               }
        //           //  }
        //           }
        //         }
        //       }
        //     }
        //   }
        // }
        this.productDetails.policyType = this.policyTypeArray;
        // this.productDetails.policyType = [];
        this.productDetails.productQuestion = [];
      } else if (String(this.productDetails.shortProductName) === 'Shop') {
        // Business Package Shop
        this.productDetails.policyType = this.policyTypeArray;
        this.addTimestampForUpdate(false, this.productDetails.shortProductName, this.productDetails);
        this.productDetails.productQuestion = [];
      }
      const saveProduct = new Object();
      const affinitydata = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
      this.productDetails.affinityId = affinitydata['id'];
      saveProduct['productDetails'] = this.productDetails;
      const date2 = new Date();
      const ccweFilesData=new Object();
      ccweFilesData['productDetails']=new Object();
      ccweFilesData['productDetails']['ccweSheetDocuments']=new Object();
      ccweFilesData['productDetails']['ccweSheetDocuments']=saveProduct['productDetails']['ccweSheetDocuments'];
      this.productService.updateProduct(saveProduct).then(data => {
        const productId = data['productId'];
       // EmitterService.get('MENU_UPDATE_EVENT').emit(true);
        // for (const documentId of this.additionalDocumentsDeleteQueue) {
        //   this.productService.deleteAdditionalDocument(documentId).then(deleteData => {
        //     console.log(deleteData);
        //   }).catch(error => {
        //     console.log(error);
        //   });
        // }
        const formData: any = new FormData();
        const formData2: any = new FormData();
        const formData3: any = new FormData();
        for (let i = 0; i < this.AdditionalProjectData.length; i++) {
          if (this.AdditionalProjectData[i]['productDocumentId'] === undefined) {
            formData.append('file', this.AdditionalProjectData[i]['File'], this.AdditionalProjectData[i]['Uploaded Files']);
          }
        }
        for (let j= 0; j< this.rateSheetForS3List.length; j++) {
          if(this.rateSheetForS3List[j]['rateSheetDocumentId'] === undefined) {
            formData2.append('file', this.rateSheetForS3List[j]['File'], this.rateSheetForS3List[j]['Uploaded File Name']);

          }
        }
        for (let k= 0; k< this.ccweSheetForS3List.length; k++) {
          if(this.ccweSheetForS3List[k]['ccwesheetDocumentId'] === undefined) {
            formData3.append('file', this.ccweSheetForS3List[k]['File'], this.ccweSheetForS3List[k]['Uploaded File Name']);

          }
        }
        this.productService.uploadProductDocumentsInS3(affinitydata['id'], productId, formData).then(documentUploadRes => {
          console.log('Document upload successful in S3');
          }).catch(error => {
            console.log('Document upload failed in S3');
          });
          this.productService.uploadProductRateSheetInS3(affinitydata['id'], productId, formData2).then(documentUploadRes => {
            console.log('Ratesheet upload successful in S3');
            }).catch(error => {
              console.log('Ratesheet upload failed in S3');
            });
            this.productService.uploadCCWESheetInS3(affinitydata['id'], productId, formData3).then(documentUploadRes => {
              console.log('CCWE sheet upload successful in S3');

              this.productService.saveCCWEFileData(affinitydata['id'], this.productDetails.shortProductName, formData3,this.productDetails.ccweSheetEffectiveDate).then(documentUploadRes => {
                console.log('CCWE File Data Saved successfully. ');
                }).catch(error => {
                  console.log('Failure While Saving CCWE File Data.');
                });
              const ccweRequestWrapper = new Object();
              ccweRequestWrapper['ccweSheetDocumentsList']= new Object();
              ccweRequestWrapper['ccweSheetDocumentsList']['ccweSheetResource']=new Object();
              ccweRequestWrapper['ccweSheetDocumentsList']['ccweSheetResource'] = ccweFilesData['productDetails']['ccweSheetDocuments'];
              console.log("ccweRequestWrapper= " + ccweRequestWrapper);
              this.productService.saveCCWESheetList(ccweRequestWrapper).then(documentUploadRes => {
                console.log('CCWESheet Documents List Saved Successfully..');
                }).catch(error => {
                  console.log('Failure While Saving CCWESheet Documents List.');
                });
              }).catch(error => {
                console.log('CCWE sheet upload failed in S3');
              });
              
        this.isSubmit = true;
        this.isvalue = false;
        this.isvalue1 = false;
        this.isvalues = false;
        this.topFunction();
        this.editSuccessful = true;
        this.productDetails.endDate = dateContainer['endDate'];
        this.productDetails.startDate = dateContainer['startDate'];
        this.productDetails.rateSheetEffectiveDate = dateContainer['rateSheetEffectiveDate'];
        this.productDetails.productMasterPolicy = [];
        for (const dateObj of dateContainer['productMasterPolicy']) {
          const masterDateObj = new Object();
          masterDateObj['masterPolicyId'] = dateObj['masterPolicyId'];
          masterDateObj['startDate'] = moment(dateObj['startDate'], 'DD/MM/YYYY HH:mm:ss');
          masterDateObj['endDate'] = moment(dateObj['endDate'], 'DD/MM/YYYY HH:mm:ss');
          this.productDetails.productMasterPolicy.push(masterDateObj);
        }
        const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
        this.navigationService.mapMenuToProduct(productId, this.productDetails.productName,
          this.productDetails.shortProductName,
          affinity['id'],
          this.productDetails.policyEndorsement ? 'Y' : 'N',
          this.productDetails.policyRenewal ? 'Y' : 'N',
          moment(this.productDetails.startDate, 'DD/MM/YYYY HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss'),
          moment(this.productDetails.endDate, 'DD/MM/YYYY HH:mm:ss').local().format('YYYY-MM-DD HH:mm:ss')).then(data2 => {
          sessionStorage.setItem('MENU_UPDATE_EVENT', 'true');
            EmitterService.get('MENU_UPDATE_EVENT').emit(true);
          }).catch(error => {
          });
      }).catch(error => {
      //  this.openActionFailDialog('Product update failed');
        this.productDetails.endDate = dateContainer['endDate'];
        this.productDetails.startDate = dateContainer['startDate'];
        this.productDetails.productMasterPolicy = [];
        for (const dateObj of dateContainer['productMasterPolicy']) {
          const masterDateObj = new Object();
          masterDateObj['masterPolicyId'] = dateObj['masterPolicyId'];
          masterDateObj['startDate'] = moment(dateObj['startDate'], 'DD/MM/YYYY HH:mm:ss');
          masterDateObj['endDate'] = moment(dateObj['endDate'], 'DD/MM/YYYY HH:mm:ss');
          this.productDetails.productMasterPolicy.push(masterDateObj);
        }
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
    }
  }

  onChangeMethod(data) {
  }

  editQuestion(index: any) {
  }

  openModal(id: string) {
    this.AddingQuestion = '';
    this.modalService.open(id);
  }


  openModals(id: string, index: any) {
    this.indexValue = index;
    this.AddingQuestion = this.questions[index]['question'];
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  closeModals(id: string) {
    this.modalService.close(id);
  }

  onChangeMethods(data: any) {
    this.PaymentArray = [];
    for (let i = 0; i < this.dataArray.length; i++) {
      if (data['gatewayName'] === 'Offline') {
        if (this.dataArray[i]['Checked'] && this.dataArray[i]['gatewayName'] === 'Offline') {
          this.PaymentArray.push(this.dataArray[i]);
        } else {
          this.dataArray[i]['Checked'] = false;
        }
      } else {
        if (this.dataArray[i]['gatewayName'] !== 'Offline') {
          if (this.dataArray[i]['Checked']) {
            this.PaymentArray.push(this.dataArray[i]);
          }
        } else {
          this.dataArray[i]['Checked'] = false;
        }
      }
    }
    if (this.PaymentArray.length > 0) {
      this.paymentOptionsFlag = true;
    } else {
      this.paymentOptionsFlag = false;
    }
    this.outSidevalidate();
  }
  saveFunctions() {
    this.questions[this.indexValue]['question'] = this.AddingQuestion;
    this.modalService.close('EditQuestions');
  }

  continueFunctions() {
    const data: Object = new Object();
    data['question'] = this.AddingQuestion;
    data['Checked'] = true;
    this.questions.push(data);
    this.outSidevalidate();
    this.modalService.close('AddQuestions');
  }

  public renewalSliderEvent() {
    if (this.status) {
      this.productDetails.policyRenewal = true;
    } else {
      this.productDetails.policyRenewal = false;
      this.productCreation = true;
      this.newProduct = true;
    }
  }

  public endorsementSliderEvent() {
    if (this.statusVal) {
      this.productDetails.policyEndorsement = true;
    } else {
      this.productDetails.policyEndorsement = false;
    }
  }

  public productStartChange() {


    const date = moment(this.productDetails.startDate, 'DD/MM/YYYY').startOf(
      'day'
    );
   
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');

    if ((now > date || now < date)) {
      this.productDetails.startDate = date;
    } else {


      this.productDetails.startDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
    if (this.rateSheetEffectiveDate !== null && this.rateSheetEffectiveDate !== undefined) {
      if (this.rateSheetEffectiveDate.toDate() !== null && this.rateSheetEffectiveDate.toDate() !== undefined) {
        this.effectiveDateValidation();
      }
    }
  }

  public masterStartChange(index) {

    const date = moment(this.productDetails.productMasterPolicy[index]['startDate'], 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');

    if ((now > date || now < date)) {
      this.productDetails.productMasterPolicy[index]['startDate'] = date;
    } else {
      this.productDetails.productMasterPolicy[index]['startDate'] = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
  }

  public productEndChange() {
    const date = moment(this.productDetails.endDate, 'DD/MM/YYYY').endOf(
      'day'
    );
    this.productDetails.endDate = date;
    if (this.rateSheetEffectiveDate !== null && this.rateSheetEffectiveDate !== undefined) {
      if (this.rateSheetEffectiveDate.toDate() !== null && this.rateSheetEffectiveDate.toDate() !== undefined) {
        this.effectiveDateValidation();
      }
    }
  }

  public masterEndChange(index) {

    const date = moment(this.productDetails.productMasterPolicy[index]['endDate'], 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');

    if ((now > date || now < date)) {
      this.productDetails.productMasterPolicy[index]['endDate'] = date;
    } else {


      this.productDetails.endDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }

  }

  public effectiveDateChange() {


    const date = moment(this.rateSheetEffectiveDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');

    if ((now > date || now < date)) {
      this.rateSheetEffectiveDate = date;
    } else {


      this.rateSheetEffectiveDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
     this.effectiveDateValidation();
  }

  public ccweEffectiveDateChange() {


    const date = moment(this.ccweSheetEffectiveDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');

    if ((now > date || now < date)) {
      this.ccweSheetEffectiveDate = date;
    } else {


      this.ccweSheetEffectiveDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
     this.ccweEffectiveDateValidation();
  }

  productDateRangeValidation(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (startDate >= endDate) {
        this.productDateRangeMessage = 'Product Start Date should be prior to Product End Date';
        this.productDateFlag = false;
      } else {
        this.productDateRangeMessage = '';
        this.productDateFlag = true;
      }
    } else {
      this.productDateRangeMessage = '';
    }
  }

  masterPolicyDateRangeValidation(index) {
    if (this.productDetails.productMasterPolicy[index]['endDate'] && this.productDetails.productMasterPolicy[index]['startDate']) {
      if (this.productDetails.productMasterPolicy[index]['startDate'] >= this.productDetails.productMasterPolicy[index]['endDate']) {
        this.masterPolicyRangeMessageArray[index] = 'Master Policy Start Date should be prior to Master \xa0\xa0\xa0\xa0\xa0\xa0\xa0Policy End Date';
        this.masterPolicyRangeFlagArray[index] = false;
      } else {
        this.masterPolicyRangeMessageArray[index] = '';
        this.masterPolicyRangeFlagArray[index] = true;
      }
    } else {
      this.masterPolicyRangeMessageArray[index] = '';
      this.masterPolicyRangeFlagArray[index] = true;
    }
  }

  public selectAllQuestionsFunction() {
    if (this.selectAllQuestions) {
      for (const question of this.questions) {
        question['Checked'] = true;
      }
    } else {
      for (const question of this.questions) {
        question['Checked'] = false;
      }
    }
    const _this = this;
    setTimeout(
      function () {
        _this.outSidevalidate();
      }.bind(_this)
    );
  }

  deleteAction(data) {
    for (let i = 0; i < this.document.length; i++) {
      if (String(data['Uploaded Files']) === String(this.document[i]['Uploaded Files']) &&
        String(data['Document Label']) === String(this.document[i]['Document Label']) &&
        String(data['Uploaded Date & Time']) === String(this.document[i]['Uploaded Date & Time'])) {
        this.document.splice(i, 1);
        this.grid.TotalRows = this.document.length;
        this.grid.getNoOfPages();
        const pagenationObj = new Object();
        pagenationObj['PageNumber'] = (this.grid.noOfPages - 1 < this.grid.currentpage) ? this.grid.noOfPages - 1 : this.grid.currentpage - 1;
        pagenationObj['NumberOfRecords'] = this.grid.currentpageperview;
        pagenationObj['RecordCount'] = 1;
        pagenationObj['StartRecordCount'] = 1;
        this.grid.setcurrentpage(pagenationObj['PageNumber']);
        this.showDocumentGrid(pagenationObj);
        break;
      }
    }
  }

  deleteAddFile() {
    this.isdeletefiles = false;
    this.Docvalues = null;
    this.isvalues = false;
    this.isdeletefiles = false;
    this.additionalDocLabel = '';
    this.additionalDocumentMessage = '';
  }

  public initializeProductEdit(data: any) {
    this.creationTimestamp = data['creationTimestamp'];
    this.lastUpdatedTimestamp = data['lastUpdatedTimestamp'];
    this.editProductData = JSON.parse(JSON.stringify(data));
    this.productDetails = JSON.parse(JSON.stringify(data));
    this.policyType = JSON.parse(JSON.stringify(this.productDetails.policyType));
    // for (let i = 0; i < policyTypeVal.length; i++ ) {
    //   if (policyTypeVal[i]['isActive'] = true) {
    //     policyTypeVal[i]['isActive'] = 1;
    //   } else {
    //     policyTypeVal[i]['isActive'] = 0;
    //   }
    // }
    // this.policyType = policyTypeVal;
     for (let i = 0; i < this.policyType.length; i++) {
       if (this.policyType[i]['isActive'] === true) {
         this.policyType[i]['isActive'] = 1;
       } else {
         this.policyType[i]['isActive'] = 0;
       }
     }
    this.policyTypeArray = this.policyType;
    this.originalPolicyType = JSON.parse(JSON.stringify(this.productDetails.policyType));
    for (let i = 0; i < this.originalPolicyType.length; i++) {
      if (this.originalPolicyType[i]['isActive'] === true) {
        this.originalPolicyType[i]['isActive'] = 1;
      } else {
        this.originalPolicyType[i]['isActive'] = 0;
      }
    }
    this.productDetails.policyType = [];
    this.productDetails.endDate = moment(this.productDetails.endDate, 'DD/MM/YYYY HH:mm:ss');
    this.productDetails.startDate = moment(this.productDetails.startDate, 'DD/MM/YYYY HH:mm:ss');
    this.masterPolicyTermArray = [];
    for (let i = 0; i < this.productDetails.productMasterPolicy.length; i++) {
      this.productDetails.productMasterPolicy[i]['masterPolicyId'] = String(this.productDetails.productMasterPolicy[i]['masterPolicyId']);
      this.productDetails.productMasterPolicy[i]['startDate'] = moment(this.productDetails.productMasterPolicy[i]['startDate'], 'DD/MM/YYYY HH:mm:ss');
      this.productDetails.productMasterPolicy[i]['endDate'] = moment(this.productDetails.productMasterPolicy[i]['endDate'], 'DD/MM/YYYY HH:mm:ss');
      const endDate = moment(this.productDetails.productMasterPolicy[i]['endDate']);
      let term = moment.duration(moment(endDate).diff(moment(this.productDetails.productMasterPolicy[i]['startDate'], 'DD/MM/YYYY HH:mm:ss'))).asYears();
      term = Math.round(term);
      this.masterPolicyTermArray.push(Number(term));
    }
    this.backdateInceptionFlag = data['backDatedInception'];
    if (this.backdateInceptionFlag) {
      this.inceptionRangeFieldControl = '';
    } else {
      this.inceptionRangeFieldControl = 'readonly';
    }
    if (data['policyEndorsement']) {
      this.statusVal = true;
    }
    if (data['policyRenewal']) {
      this.status = true;
    }
    this.AdditionalProjectData = [];
    this.PremiumFilesArray = [];
    for (const additionalDoc of data['productDocuments']) {
      const obs = new Object();
      obs['Uploaded Files'] = additionalDoc['documentName'];
      obs['Document Label'] = additionalDoc['documentLabel'];
      obs['Upload Date & Time'] = additionalDoc['creationTimeStamp'];
      obs['productDocumentId'] = additionalDoc['productDocumentId'];
      this.AdditionalProjectData.push(obs);
    }

    for (const rateSheet of data['rateSheetDocuments']) {
      const obs = new Object();
      if (rateSheet['isActive'] === true) {
        rateSheet['isActive'] = 1;
      } else {
        rateSheet['isActive'] = 0;
      }
      obs['Uploaded File Name'] = rateSheet['documentName'];
      obs['Upload Date & Time'] = rateSheet['creationTimeStamp'];
      obs['Effective Date'] = rateSheet['effectiveDate'];
      obs['rateSheetDocumentId'] = rateSheet['rateSheetDocumentId'];
      obs['isActive'] = rateSheet['isActive'];
      this.rateSheetMetaDataArray.push(obs);
      this.rateSheetDataArray.push([]);
    }
    this.sortRateSheetData();
    if(data['ccweSheetDocuments']!=undefined)
    {
     for (const ccweSheet of data['ccweSheetDocuments']) {
      const obs = new Object();
      if (ccweSheet['isActive'] === true) {
        ccweSheet['isActive'] = 1;
      } else {
        ccweSheet['isActive'] = 0;
      }
      obs['Uploaded File Name'] = ccweSheet['documentName'];
      obs['Upload Date & Time'] = ccweSheet['creationTimeStamp'];
      obs['Effective Date'] = ccweSheet['effectiveDate'];
      obs['ccwesheetDocumentId'] = ccweSheet['ccwesheetDocumentId'];
      obs['isActive'] = ccweSheet['isActive'];
      obs['affinityId'] = ccweSheet['affinityId'];
      obs['productId'] = ccweSheet['productId'];
      obs['productType'] = ccweSheet['productType'];
      this.ccweSheetMetaDataArray.push(obs);
      this.ccweSheetDataArray.push([]);
    }
    this.sortCCWESheetData();
   }
    // TO-DO: Multiple Ratesheets, hard-coding index for policy type
    // policyType stores single rate sheet data
    if (String(data['shortProductName']) === 'Home') {
      this.rateSheetDataArray[0] = this.originalPolicyType;
      this.policyType = this.originalPolicyType;
    } else {
      this.rateSheetDataArray[0] = [];
      this.policyType = [];
    }


    this.initializeDocumentGrid();
    if (String(data['lobId']) === '1') {
      this.productType = 1;
      this.productDetails.shortProductName = 'Health';
      this.bussiness = false;
    } else if (String(data['lobId']) === '2') {
      this.productType = 2;
      if (String(data['shortProductName'] === 'Home')) {
        this.subProductType = 1;
      } else if (String(data['shortProductName'] === 'Shop')) {
        this.subProductType = 2;
      }
      this.bussiness = true;
    }
    if (String(data['shortProductName']) === 'Home') {
      this.subProductType = 1;
    } else if (String(data['shortProductName']) === 'Shop') {
      this.subProductType = 2;
    }
    if (this.productDetails.shortProductName === 'Home') {
      this.getUniquePlanNameIndices(this.policyType);
      this.selectService.fetchLookUpData('applianceList', null).then(applianceData => {
        this.applianceList = JSON.parse(JSON.stringify(applianceData));
        const mockPolicy = [];
        for (const policy of this.policyType) {
          const planType = new Object();
          planType['policyTypeId'] = policy['policyTypeId'];
          planType['planType'] = [];
          for (const plan of policy.planType) {
            const planApplianceList = [];
            for (const appliance of plan.planAppliance) {
              for (const singleApplianceData of applianceData) {
                if (singleApplianceData['applianceName'] === appliance['appliances']['applianceName']) {
                  const applianceData2 = new Object();
                  applianceData2['planApplianceID'] = appliance['planApplianceID'];
                  applianceData2['appliances'] = new Object();
                  applianceData2['appliances']['applianceID'] = singleApplianceData['applianceID'];
                  applianceData2['appliances']['applianceID'] = singleApplianceData['applianceID'];
                  planApplianceList.push(applianceData2);
                }
              }
            }
            const planApplianceObj = new Object();
            planApplianceObj['planid'] = plan['planid'];
            planApplianceObj['planAppliance'] = planApplianceList;
            planApplianceObj['planTypeName'] = plan['planTypeName'];
            planType['planType'].push(planApplianceObj);
          }
          planType['policyTypeName'] = policy['policyTypeName'];
          //    const dateTime = policy['effectiveDate'];
          //   planType['effectiveDate'] = moment(dateTime).format('DD/MM/YYYY HH:mm:ss');
          planType['effectiveDate'] = policy['effectiveDate'];
          mockPolicy.push(planType);
        }

        const parseData = new Object();
        for (let i = 0; i < this.policyType.length; i++) {
          for (let j = 0; j < this.policyType[i].planType.length; j++) {
            for (let k = 0; k < this.policyType[i].planType[j].planAppliance.length; k++) {
              for (const singleApplianceData of applianceData) {
                if (singleApplianceData['applianceName'] === this.policyType[i].planType[j].planAppliance[k]['appliances']['applianceName']) {
                  parseData[String(i + ',' + j)] = parseData[String(i + ',' + j)] ? parseData[String(i + ',' + j)] + ',' + singleApplianceData['applianceName'] : singleApplianceData['applianceName'];
                }
              }
            }
          }
        }
        const multiSelects = this.multiselectFields['_results'];
        // for (let i = 0; i < multiSelects.length; i++) {
        //   if (multiSelects[i].enableParse2) {
        //     multiSelects[i].parse3(parseData[i], this.applianceList);
        //   }
        // }
        for (const multiSelect of multiSelects) {
          if (multiSelect.enableParse2) {
          //  multiSelect.parse3(parseData, this.applianceList);
          }
        }
        this.applianceData = false;
        this.policyType = mockPolicy;
        this.setApplianceForEditProduct(this.policyTypeArray);
      });
    }

    this.originalGatewayArray = JSON.parse(JSON.stringify(data.productGateway));
    this.paymentService.getPaymentGatewayList().then(productGateway => {
      this.dataArray = productGateway;
      for (const paymentData of this.dataArray) {
        for (const gatewayData of data.productGateway) {
          if (gatewayData['gatewayId'] && paymentData['gatewayId'] && String(gatewayData['gatewayId']) === String(paymentData['gatewayId'])) {
            paymentData['Checked'] = true;
            paymentData['productGatewayId'] = gatewayData['productGatewayId'];
            this.onChangeMethods(paymentData);
          } else if (gatewayData['walletId'] && paymentData['walletId'] && String(gatewayData['walletId']) === String(paymentData['walletId'])) {
            paymentData['Checked'] = true;
            paymentData['productGatewayId'] = gatewayData['productGatewayId'];
            this.onChangeMethods(paymentData);
          }
        }
      }
    });

    new Promise((resolve, reject) => {
      this.productService.masterQuestions().then(data2 => {
        this.questions = [];
        //  this.questions = data2;
        const productQuestions = data2;
         for (const question of productQuestions) {
           for (const selectedQuestion of data['productQuestion']) {
             if (question['question'] === selectedQuestion['questionName']) {
               question['question'] = selectedQuestion['questionName'];
               question['Checked'] = true;
               selectedQuestion ['deafultQuestion'] = true;
               delete selectedQuestion['questionName'];
               if (selectedQuestion['productQuestionId']) {
                 question['id'] = selectedQuestion['productQuestionId'];
                 delete selectedQuestion['productQuestionId'];
               }
             }
           }
           this.questions.push(question);
         }

        for (const selectedQuestion of data['productQuestion']) {
          const question1: Object = new Object;
          if (selectedQuestion['deafultQuestion'] !== true) {
            question1['question'] = selectedQuestion['questionName'];
            question1['Checked'] = true;
            delete selectedQuestion['questionName'];
            if (selectedQuestion['productQuestionId']) {
              question1['id'] = selectedQuestion['productQuestionId'];
              delete selectedQuestion['productQuestionId'];
            }
            this.questions.push(question1);
          }
        }

        for (const question of this.questions) {
          if (!question['Checked']) {
            this.selectAllQuestions = false;
            break;
          }
        }
      });
      resolve(100);
    }).then(data2 => {
      for (const question of this.questions) {
        if (!question['Checked']) {
          this.selectAllQuestions = false;
          break;
        }
      }
    }).catch(error => { });
    // for (const question of data['productQuestion']) {
    //   question['Checked'] = true;
    // }
    // for (const question of data['productQuestion']) {
    //   question['question'] = question['questionName'];
    //   delete question['questionName'];
    //   if (question['productQuestionId']) {
    //     question['id'] = question['productQuestionId'];
    //     delete question['productQuestionId'];
    //   }
    //   this.questions.push(question);
    // }
    this.productName = this.productDetails.productName;
    this.enableSubmit = true;
    this.productDateFlag = true;
    this.productDateFlag = true;
    this.paymentOptionsFlag = true;
    this.masterPolicyFlag = true;
    this.productNameFlag = true;
    EmitterService.get('MULTISELECT_PARSE').emit(this.productDetails.policyTerm);
  }

  public setApplianceForEditProduct(str) {
    const data = str;
    for (let i = 0; i < data.length; i++) {
      const planType = data[i]['planType'];
      for (let j = 0; j < planType.length; j++ ) {
        const planName = planType[j]['planTypeName'];
        const planAppliance = planType[j]['planAppliance'];
        if (planAppliance !== undefined) {
        const applianceData: any[] =  [];
        for (let  l = 0; l < planAppliance.length; l++) {
            const applianceObj = new Object();
        //    applianceObj['applianceID'] = planAppliance[l]['applianceName'];
            applianceObj['applianceName'] = planAppliance[l]['appliances']['applianceName'];
            applianceData.push(applianceObj);
        }
        const multiSelects = this.multiselectFields['_results'];
      //  this.multiselectFields.parse3(applianceData,this.applianceList);
        // for (let m = 0; m < multiSelects.length; m++) {
        //   if (multiSelects[m].enableParse2) {
        //     multiSelects[m].parse3(applianceData, this.applianceList);
        //   }
        // }
         for (const multiSelect of multiSelects) {
           if (multiSelect.enableParse2 && multiSelect.label === planName) {
             multiSelect.parse3(applianceData, this.applianceList);
           }
         }
       // this.setAppliance(applianceData, planName);
        }
      }
    }

  }
  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  public lodingAppliancesList() {
    this.availableApplianceList = [];
    this.selectService.fetchLookUpData('applianceList', null).then(applianceData => {
      for (let i = 0; i < applianceData.length; i++) {
        delete applianceData[i].applianceName;
        this.availableApplianceList.push(applianceData[i]);
      }
    });
  }

  public additionalGridService(offset: any, size: any) {
    const totalRecords = this.AdditionalProjectData.length;
    if (totalRecords > 0) {
      const startIndex = offset;
      const endIndex = (offset + size) > totalRecords ? totalRecords : offset + size;
      const resultArray = this.AdditionalProjectData.slice(startIndex, endIndex);
      const responseObject = new Object();
      responseObject['totalRecords'] = totalRecords;
      responseObject['documentList'] = resultArray;
      return responseObject;
    } else {
      const responseObject = new Object();
      responseObject['totalRecords'] = 0;
      responseObject['documentList'] = [];
      return responseObject;
    }
  }
  public showDocumentGrid(data: any) {
    const size = data['NumberOfRecords'];
    const offset = data['NumberOfRecords'] * (data['PageNumber']);
    const responseObject = this.additionalGridService(offset, size);
    if (responseObject['documentList'].length > 0) {
      this.displayedAdditionalDocuments = responseObject['documentList'];
    } if (responseObject['totalRecords'].length > 0) {
      this.additionalDocumentCount = responseObject['totalRecords'];
    }
  }

  public initializeDocumentGrid() {
    const size = 10;
    const offset = 0;
    const responseObject = this.additionalGridService(offset, size);
    this.displayedAdditionalDocuments = responseObject['documentList'];
    this.additionalDocumentCount = responseObject['totalRecords'];
  }

  public effectiveDateValidation() {
    this.effectiveDateMessage = '';
    if (!this.productDetails.endDate || !this.productDetails.startDate) {
      this.effectiveDateMessage = 'Please select both product start date and product end date';
     // this.rateSheetEffectiveDate = '';
    } else if (this.rateSheetEffectiveDate.toDate() < this.productDetails.startDate.toDate() || this.rateSheetEffectiveDate.toDate() > this.productDetails.endDate.toDate()) {
      this.effectiveDateMessage = 'Effective Date is not within the Product start and End Date limit';
     // this.rateSheetEffectiveDate = '';
    } else {
      this.effectiveDateMessage = '';
    }
  }

  public ccweEffectiveDateValidation() {
    this.ccweEffectiveDateMessage = '';
    if (!this.productDetails.endDate || !this.productDetails.startDate) {
      this.ccweEffectiveDateMessage = 'Please select both product start date and product end date';
     // this.rateSheetEffectiveDate = '';
    } else if (this.ccweSheetEffectiveDate.toDate() < this.productDetails.startDate.toDate() || this.ccweSheetEffectiveDate.toDate() > this.productDetails.endDate.toDate()) {
      this.ccweEffectiveDateMessage = 'Effective Date is not within the Product start and End Date limit';
     // this.rateSheetEffectiveDate = '';
    } else {
      this.ccweEffectiveDateMessage = '';
    }
  }

  public getUniquePlanNameIndices(policyType: any) {
    this.uniqueEquivalentPlans = new Object();
    if (this.editProduct) {
      for (let i = 0; i < policyType.length; i++) {
        if (policyType[i]['isActive'] === 0 ) {
          this.uniquePlans = [];
          this.applianceForUniquePlans = [];
        }
      }
      for (let i = 0; i < policyType.length; i++) {
        if (policyType[i]['isActive'] === 1 ) {
          for (let j = 0; j < policyType[i]['planType'].length; j++) {
            this.existingPlanFalg = false;
            const planName = policyType[i]['planType'][j]['planTypeName'];
            if (this.uniquePlans.length === 0) {
              this.uniquePlans.push(planName);
            } else {
              for (let l = 0; l < this.uniquePlans.length; l++) {
                const alreadyPlan = this.uniquePlans[l];
                if (alreadyPlan === planName) {
                  //  this.uniquePlans.push(planName);
                  this.existingPlanFalg = true;
                }
              }
              if (!this.existingPlanFalg) {
                this.uniquePlans.push(planName);
              } else {
              //  this.setAppliance(this.availableApplianceList, planName);
              }
            }
            if (!this.uniqueEquivalentPlans[planName]) {
              this.uniqueEquivalentPlans[planName] = [];
              //   this.uniquePlans.push(planName);
            } else {
              this.uniqueEquivalentPlans[planName].push(i + ',' + j);
            }
          }
        }
      }

    } else {
      for (let i = 0; i < policyType.length; i++) {
        if (policyType[i]['isActive'] === 0 ) {
          this.uniquePlans = [];
          this.applianceForUniquePlans = [];
        }
      }
      for (let i = 0; i < policyType.length; i++) {
        if (policyType[i]['isActive'] !== 0 ) {
          for (let j = 0; j < policyType[i]['planType'].length; j++) {
            this.existingPlanFalg = false;
            const planName = policyType[i]['planType'][j]['planTypeName'];
            if (this.uniquePlans.length === 0) {
              this.uniquePlans.push(planName);
            } else {
              for (let l = 0; l < this.uniquePlans.length; l++) {
                const alreadyPlan = this.uniquePlans[l];
                if (alreadyPlan === planName) {
                  //  this.uniquePlans.push(planName);
                  this.existingPlanFalg = true;
                }
              }
              if (!this.existingPlanFalg) {
                this.uniquePlans.push(planName);
              } else {
              }
            }
            if (!this.uniqueEquivalentPlans[planName]) {
              this.uniqueEquivalentPlans[planName] = [];
              //   this.uniquePlans.push(planName);
            } else {
              this.uniqueEquivalentPlans[planName].push(i + ',' + j);
            }
          }
        }
      }
    }
  }
  public isSelectedUniquePlan(i: any, j: any) {
    const planName = this.policyType[i]['planType'][j]['planTypeName'];
    if (!this.uniqueEquivalentPlans[planName]) {
      return true;
    } else {
      const indices = i + ',' + j;
      for (const existingIndices of this.uniqueEquivalentPlans[planName]) {
        if (existingIndices === indices) {
          return false;
        }
      }
      return true;
    }
  }
  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      });
    this.router.navigateByUrl('');
  }
}
