import { Component, OnInit, ViewChildren, ViewChild, ElementRef, Input, HostListener, AfterViewInit, OnChanges, ɵConsole } from '@angular/core';
import { EmitterService } from 'src/services/EmitterService';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DESCRIPTION, CODE, DROPDOWNS, LOB_NAME, USER_PROFILE } from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { DatePipe } from '@angular/common';
import { ReportsVO } from './../dto/ReportsVO';
import * as _moment from 'moment';
import { UserManagementServices } from 'src/services/user-management.services';
import { USER_AFFINITY, AFFINITY_ID, SHORT_PROD_NAME, USER_PROFILE_DATA,PRODUCT_CODE, SAHAJ_AND_LIB_OCCUPANCY, SHOP_OCCUPANCY_TARIFF, HOME_OCCUPANCY_AND_TARIFF, SHOP_OCCUPANCY,MEENACHIL_SHOP_OCCUPANCY,MEENACHIL_HOME_OCCUPANCY} from '../common/common.constants';
import { InboxpageService } from 'src/services/inboxpage.service';
import { GridComponent } from 'src/grid/grid.component';
import { InboxSearchDTO } from 'src/dto/InboxSearchDTO';
import { ModalService } from '../services/modal.services';
// import {MatDialogModule} from '@angular/material/dialog';
import { SelectService } from '../services/select.services';
import { AppConfig } from 'src/config/app.config';
import { ProposalVO } from '../dto/ProposalVO';
import { CommunicationVO } from '../dto/CommunicationVO';
import { BeneficiaryVO } from '../dto/BeneficiaryVO';
import { PremiumDetailsVO } from '../dto/PremiumDetailsVO';
import { ProposalServices } from '../services/proposal.services';
import { CustomerDetailsVO } from 'src/dto/CustomerDetailsVO';
import { RiskLocationVO } from 'src/dto/RiskLocationVO';
import { PaymentDetailsVO } from 'src/dto/PaymentDetailsVO';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-inboxpage',
  templateUrl: './inboxpage.component.html',
  styleUrls: ['./inboxpage.component.css']
})
export class InboxpageComponent implements OnInit {
  public hrLeftMargin;
  public hrWidth;
  public productType: any;
  public roleId: string;
  public roleName: string;
  public max: any;
  public display = 'none';
  public productTypeDisplay = 'none';
  public statusDisplay = 'none';
  public dateDisplay = 'none';
  public searchBoxDisplay = 'flex';
  public searchValArray: Array<any> = new Array<any>();
  public statusArray: Array<any> = new Array<any>();
  public paymentValArray: Array<any> = new Array<any>();
  public description: string;
  public lobName: string;
  public searchData: string;
  public columnsData: Array<Object> = Array<Object>();
  public actionNameData: String;
  public subcolumnsData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public actionColumnsPaymentData: Array<Object> = Array<Object>();
  public count: any;
  public totalNumberOfRecords: Number;
  public totalRows: any;
  public projectData: Array<Object> = Array<Object>();
  public loadingmessage = '';
  public inboxSearchDTO: InboxSearchDTO = new InboxSearchDTO();
  public productDateRangeMessage: any;
  public dateFlag: Boolean = false;
  public policyId: any;
  public otpStatus: any;
  public otpValidateStatus: any;
  public isOTPValidationSuccess: Boolean = false;
  public declarationArray: Array<Object> = Array<Object>();
  public checkedArray: Array<Object> = Array<Object>();
  public otpInput: String;
  public allChecked: boolean;
  sahajWalletUrl: String = this.config.getConfig('sahajRedirection');
  // Meenahcil Affinity
  public offlineProposalData: any;
  public affinityName: String;
  public customerDetail: CustomerDetailsVO = new CustomerDetailsVO();
  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public riskLocationdetail : RiskLocationVO = new RiskLocationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public paymentDetail: PaymentDetailsVO = new PaymentDetailsVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public titleDes: any;
  public txnDetails: any;
  public shortProductName: any;
  public productName: any;
  public sumInsuredForCoverages: any;
  public coverageIds: Object = new Object();
  public coverageSectionType1: Array<any> = new Array<any>();
  public coverageSectionType2: Array<any> = new Array<any>();
  public applianceDetail: Array<any> = new Array<any>();
  public isPaymentSuccess: Boolean = false;
  public message: String;
  public agentName: String;
  public agentContactNumber: any;
  public agentId: any;
  public downloadFailed: Boolean = false;
  public certificateNumber: any;
  public isMakePaymentSelected: Boolean = false;
  public certificateIssueDate: any;
  

  @ViewChild('contentBody') contentBodyRef: ElementRef;
  @ViewChild('grid') gridComponent: GridComponent;
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyRef.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
  }
 
  constructor(private router: Router,
    private route: ActivatedRoute,
    private datePipe: DatePipe,
    private inboxService: InboxpageService,
    private modalService: ModalService,
    private selectService: SelectService,
    private proposalService: ProposalServices,
    private config:AppConfig) { }

  ngOnInit() {
    this.onResize();
    this.loadingmessage = 'Please wait...';
    this.description = '--Select--';

    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    const affinityId = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    this.affinityName =  affinityId['name'];
    this.agentContactNumber = affinityId['agentContactNumber'];
    this.agentName =  affinityId['agentName'];
    this.agentId =  affinityId['agentId'];
    this.searchValArray = [];
    this.statusArray = [];
    this.paymentValArray = [];
    this.roleId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleId'];
    this.roleName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    this.count = 0;
    this.totalNumberOfRecords = 10;
    const date = new Date;
    this.max = new Date(date.setDate(date.getDate()));
    const endDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');

    this.inboxSearchDTO.endDate = endDate;
    this.dateFlag = false;
    this.projectData = [];
    this.searchBoxDisplay = 'none';
    this.getSearchDetails();
    this.getProdAndLobDetails();
  //  this.getPageLoadDetails();
    this.prepareColumnsData();

    this.proposerVo.communicationDetails = this.communicationDetail;
    this.proposerVo.riskLocationDetails = this.riskLocationdetail;
    this.proposerVo.paymentDetails = this.paymentDetail;
  }

  public checkOtpVerifyOrRetry(str) {
    this.isOTPValidationSuccess = false;
    this.otpValidateStatus = '';
    this.otpInput= '';
    const actionType = str['Action'];
    this.policyId = str['policyIdEncrypt'];   
    const affinityId = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    const selectedAffinity = affinityId['name']; 
    this.affinityName =  affinityId['name'];
    if (actionType === 'OTPRETRY') {
      this.inboxService.getOTPRetryStatus(this.policyId, selectedAffinity)
        .then(data => {
          if ('RESEND_OTP_SUCCESS' === data) {
            this.otpStatus = 'OTP sent successfully on registered mobile number/Email ID.';
          } else {
            this.otpStatus = 'OTP generation failed. Please try again.';
          }
          this.showModal();
        }).catch(error => {

        });
    } else if (actionType === 'OTPVERIFY') {
      this.checkedArray = [];
    this.selectService.fetchLookUpData('sahajDeclaration', null).then(data => {
          for (const val of data) {
            const obj: Object = new Object();
            obj['id'] = val['id'];
            obj['checked'] = false;
            obj['name'] = val['declaration'];
            this.checkedArray.push(obj);
          }
    }); 
    //   const declarationArray: Array<Object> = Array<Object>();
    //   declarationArray.push(DECLARATION_FIRST);
    //   declarationArray.push(DECLARATION_SECOND);
    //  declarationArray.push(DECLARATION_THIRD);
    //   declarationArray.push(DECLARATION_FOURTH);
    //   declarationArray.push(DECLARATION_FIVE);
    //   declarationArray.push(DECLARATION_SIX);
    //   declarationArray.push(DECLARATION_SEVEN);
    //   declarationArray.push(DECLARATION_EIGHT);
      this.showVerifyModal();
   //   this.validateOTP();
    } else if (actionType === 'MAKEPAYMENTLINK') {
      if (this.affinityName.startsWith('MEENACHIL')) {
        this.loadingmessage = 'Please wait...';
        this.isMakePaymentSelected = true;
        this.getCovernoteNoInfo(str['covernoteNoEncrypt'])
      } else {
      const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
      const authToken= JSON.parse(sessionStorage.getItem(USER_PROFILE));
      let smState;
      let branchCode;
      if (profileData['smState'] === undefined) {
        smState = '';
      } else {
        smState = profileData['smState'];
      }
      if (profileData['branchCode'] === undefined) {
        branchCode = '';
      } else {
        branchCode = profileData['branchCode'];
      }
      const userName = profileData['userName'];
      this.proposalService.getPaymentReqData(this.policyId, smState, branchCode, userName,authToken).then(data => {
        if (null !== data && data !== undefined) {
          const paymentRedirectUrl = this.sahajWalletUrl + data;
          window.location.href = paymentRedirectUrl;
        }

      }).catch(error => {

      });
    }
    }
    

  }

  public validateOTP() {
    this.otpValidateStatus= '';
    const pattern = '^[0-9]*$';
    this.otpStatus = '';
    this.allChecked = this.checkedArray.every(data => data['checked'] === true)
    if (this.allChecked) {

      if (this.otpInput !== undefined && this.otpInput !== '' && this.otpInput.length === 6) {
        if (this.otpInput.match(pattern)) {
          let otpDetails: Object = new Object();
          otpDetails = new Object();
          otpDetails['policyId'] = this.policyId;
          otpDetails['otpEnteredbyUser'] = this.otpInput;
          const otpdata = JSON.stringify(otpDetails);
          this.inboxService.validateOTP(otpdata)
            .then(data => {
              if('OTP_NOT_VALIDATED' === data) {
                this.otpValidateStatus = 'OTP validation failed. Please try again.';
              } else if ('OTP_VALIDATED' === data) {
                this.otpValidateStatus = 'OTP validated successfully!.';
                this.isOTPValidationSuccess = true;
              }
    
            }).catch(error => {
    
            });
        } else {
          this.otpStatus = 'Please Enter Valid OTP!';
        }
      } else {
        this.otpStatus = 'Please Enter OTP';
      }
    } else {
      this.otpInput = '';
      this.otpStatus = 'Please select all the declartion and Enter OTP';
    }

  }
  public showValidateModal() {

    this.openModal('validteOtpConfirmation');

  }
  public closeValidationModal() {
    this.closeModal('validteOtpConfirmation');
  }
  public showVerifyModal() {
    this.openModal('otpVerifyModal');
  }
  public closeVerifyModal(){
    this.closeModal('otpVerifyModal');
    this.getPageLoadDetails();
  }
  public closeRetryModal() {
    this.closeModal('confirmationModal');
  }
  public showModal() {

    this.openModal('confirmationModal');

  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }

  getPageLoadDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];

    if (affinityId !== 'undefined' && this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      this.inboxService.getPageLoadData(this.count, this.totalNumberOfRecords, affinityId,this.shortProductName)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
         this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
          const errorResponse = new Object();
          errorResponse['policyDetailsList'] = [];
          errorResponse['totalRecords'] = 0;

        });
    }
  }
  public searchUser() {
    if (!this.inboxSearchDTO.searchInput || !this.inboxSearchDTO.searchInput || this.inboxSearchDTO.searchInput === '') {
      this.issuedProposalSearch();
    }
  }
  prepareColumnsData() {
    this.actionNameData = 'Action';
    const col1: Object = new Object();
    col1['colName'] = 'Proposal No.';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Employee ID';
    this.columnsData.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Proposal Start Date';
    this.columnsData.push(col3);
    const col4: Object = new Object();
    col4['colName'] = 'Proposal End Date';
    this.columnsData.push(col4);
    const col5: Object = new Object();
    col5['colName'] = 'Status';
    this.columnsData.push(col5);
    // const col6: Object = new Object();
    // col6['colName'] = 'Action';
    // this.columnsData.push(col6);
  }
  public prepareProjectData(proposalData) {
    this.loadingmessage= '';
    if (proposalData != null) {
      const proposalList = proposalData.policyDetailsList;
      if (proposalData !== undefined) {
        this.projectData = [];
        for (let i = 0; i < proposalList.length; i++) {
          const policyStatusId = proposalList[i].policyStatusId;
          const policyId = proposalList[i].policyId;
          const policyIdEncrypt = proposalList[i].policyIdEncrypt;
          let status = proposalList[i].paymentDetails.status;
          this.actionColumnsData = [];
          let employeId;
       //   if (policyStatusId == 6 || policyStatusId == 7) {
            if (status == 'OTP Generated') {
            this.actionColumnsData.push('OTP RETRY,OTP VERIFY');
           //   this.prepareActionColumns();
            } else if (status == 'OTP Verified') {
              this.actionColumnsData.push('MAKE PAYMENT LINK');
            //  this.prepareActionVerifyColumns();
            }
            const proposalNo = proposalList[i].covernoteNo;
            const proposalNoEncrypt = proposalList[i].covernoteNoEncrypt;
            const startDate = proposalList[i].policyStartDate;
            const policyDate: any = startDate.split(' ');
            const policyStartDate = policyDate[0];
            const endDate = proposalList[i].policyEndDate;
            const policyEndDateTrim: any = endDate.split(' ');
            const policyEndDate = policyEndDateTrim[0];
            if (proposalList[i].customerDetails !== undefined) {
              employeId = proposalList[i].customerDetails.employeeId;
            }
            const data: Object = new Object();
            data['Proposal No.'] = proposalNo;
            data['Employee ID'] = employeId;
            data['Proposal Start Date'] = policyStartDate;
            data['Proposal End Date'] = policyEndDate;
            data['Status'] = status;
            data['policyId'] = policyId;
            data['policyIdEncrypt'] = policyIdEncrypt;
            data['covernoteNoEncrypt'] = proposalNoEncrypt;
            this.projectData.push(data);

        //  }
        }
      }
    }

  }
  public prepareActionColumns() {
  //  this.actionColumnsPaymentData = [];
    if (!this.actionColumnsData.includes('OTP VERIFY')) {
      this.actionColumnsData.push('OTP VERIFY');
    }
    if (!this.actionColumnsData.includes('OTP RETRY')) {
      this.actionColumnsData.push('OTP RETRY');
    }

  }
  public prepareActionVerifyColumns() {
   // this.actionColumnsData = [];
    if (!this.actionColumnsData.includes('MAKE PAYMENT LINK')) {
      this.actionColumnsData.push('MAKE PAYMENT LINK');
    }

  }
  async getProdAndLobDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      this.inboxService.getPaymentTypeData(affinityId).then(data1 => {
        const productData = data1;
        if (productData != null && productData != 'undefined') {
          sessionStorage.setItem('prodcutDetails', JSON.stringify(productData));
          for (let i = 0; i < productData.length; i++) {
          //  if ('Business Package For Shop Owners'=== productData[i].lobDescription){
              const data: Object = new Object();
              data[CODE] = productData[i].lobId;
              data[LOB_NAME] = productData[i].lobDescription;
              this.paymentValArray.push(data);
        //  }
          }
        //  this.lobName = this.paymentValArray; // [0]['lobName'];
        }
      }).catch(error => {
      
      });
    }
  }

  public productTypeChange() {
    this.loadingmessage = 'Please wait...'
    if (String(this.productType) === '2') {
      this.shortProductName = 'Home';
      this.getPageLoadDetails();
    } else if (String(this.productType) === '3') {
      this.shortProductName = 'Shop';
      this.getPageLoadDetails();
    }
  }
  getSearchDetails() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      this.inboxService.getSearchList(1).then(data1 => {
        const response = data1;
        if (response != null && response != 'undefined') {
          for (let i = 0; i < response.length; i++) {
            const data: Object = new Object();
            data[CODE] = response[i].searchParamsMappingID;
            data[DESCRIPTION] = response[i].searchParam;
            data[DROPDOWNS] = response[i].dropDowns;
            this.searchValArray.push(data);
          }
        //  this.inboxSearchDTO.searchId = this.searchValArray[0]['Code'];
       //   this.inboxSearchDTO.searchId = this.searchValArray[0]['Code'];
          
       //   this.searchData = this.searchValArray[0]['Description'];
        }
      }).catch(error => {
        
      });
    }
  }
  //get status from dropdwon

  getStatus() {
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];
    if (affinityId !== undefined) {
      this.inboxService.getStatus(1).then(data1 => {
        this.statusArray = [];
        const response = data1;
        if (response != null && response != 'undefined') {
         
          for (let i = 0; i < response.length; i++) {
            const data: Object = new Object();
            data[CODE] = response[i].dropDownMappingID;
            data[DESCRIPTION] = response[i].dropDownColumn;
            this.statusArray.push(data);
          }
        }
      }).catch(error => {
       
      });
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  diplaySearchbar(value: any, searchData: any) {
    this.inboxSearchDTO.searchInput = '';
    if (this.searchBoxDisplay === 'none') {
      this.searchBoxDisplay = 'flex';
    } else {
      this.searchBoxDisplay = 'none';
    }
    this.searchData = searchData;
    this.inboxSearchDTO.searchId = value;
    this.display = 'none';
    this.dateDisplay = 'none';
  }
  toggleProposalDateDisplay(value: any, searchData: any) {
    if (this.dateDisplay === 'none') {
      this.dateDisplay = 'flex';
    } else {
      this.dateDisplay = 'none';
    }
    this.searchData = searchData;
    this.inboxSearchDTO.searchId = value;
    this.display = 'none';
    this.searchBoxDisplay = 'none';
    
  }
  displayDate(value: any, searchData: any) {
   
    this.searchData = searchData;
    this.inboxSearchDTO.searchId = value;
    this.toggleDisplay();
  }

  toggleDisplay() {
   
    this.statusDisplay = 'none';
    this.dateDisplay = 'none';
    this.searchBoxDisplay = 'none';
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
  
    this.display = 'none';
  }
  displayStatusMenu(value: any, searchData: any) {
   
    if (searchData === 'Status' && value == 3) {
      
      this.getStatus();
    }
    this.searchData = searchData;
    this.inboxSearchDTO.searchId = value;
    this.toggleStatusDisplay();
  }
  toggleStatusDisplay() {

    if (this.statusDisplay === 'none') {
      this.statusDisplay = 'flex';

    } else {
      this.statusDisplay = 'none';

    }
  }
  hideStatusDropDown() {
    this.statusDisplay = 'none';
  }

  set(lobName: any) {
    this.lobName = lobName;
    this.toggleProductTypeDisplay();
  }



  toggleProductTypeDisplay() {
    if (this.productTypeDisplay === 'none') {
      this.productTypeDisplay = 'flex';
    } else {
      this.productTypeDisplay = 'none';
    }
  }
  hideProductDropDown() {
    this.productTypeDisplay = 'none';
  }
  

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      });
    this.router.navigateByUrl('');
  }

  public issuedProposalSearch() {
    this.loadingmessage = 'Please wait...';
    const action = this.searchData;

    if (this.inboxSearchDTO.searchId == 4 && this.searchData === "ProposalDate"
      && this.inboxSearchDTO.startDate && this.inboxSearchDTO.endDate) {
   
      const generatedDate: any = (/^\d{4}\-(0[1-9]|1[012])\-(0[1-9]|[12][0-9]|3[01])$/);
      let startdate: String = this.inboxSearchDTO.startDate;
      let enddate: String = this.inboxSearchDTO.endDate;
      startdate = moment(startdate).local().format('YYYY-MM-DD');
      enddate = moment(enddate).local().format('YYYY-MM-DD');
      
      if ((String(startdate).match(generatedDate)) && (String(enddate).match(generatedDate))) {
        this.inboxService.proposalSearch(this.inboxSearchDTO.searchId, startdate, enddate, action,this.count,this.totalNumberOfRecords,this.shortProductName)
          .then(data => {
            this.loadingmessage = '';
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.loadingmessage = '';
            this.moveToTop();
          })
          .catch(error => {

            this.prepareProjectData(null);
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      } else {
        this.loadingmessage = '';
        const obj = new Object();
        obj['policyDetailsList'] = [];
        obj['totalRecords'] = 0;
        this.prepareProjectData(obj);
      }

    } else if ((this.inboxSearchDTO.searchId == 1 && this.searchData === "OTP Generated") ||
      (this.inboxSearchDTO.searchId == 2 && this.searchData === "OTP Verified")) {
     
      this.inboxService.proposalSearch(this.inboxSearchDTO.searchId, "PolicyStatusID", this.searchData, action,this.count,this.totalNumberOfRecords,this.shortProductName)
        .then(data => {
          this.loadingmessage = '';
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.gridComponent.inSearch = true;
        //  this.gridComponent.setcurrentpage(0);
         this.gridComponent.showData();
       //  this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
        
          this.prepareProjectData(null);
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
    } else if (this.inboxSearchDTO.searchInput != null && this.inboxSearchDTO.searchInput != '') {
      if ((this.inboxSearchDTO.searchId == 1 && this.searchData === "Employee ID") ||
        (this.inboxSearchDTO.searchId == 2 && this.searchData === "ProposalID")) {
        
        this.inboxService.proposalSearch(this.inboxSearchDTO.searchId, this.inboxSearchDTO.searchInput, null, action,this.count,this.totalNumberOfRecords, this.shortProductName)
          .then(data => {
            this.totalRows = data['totalRecords'];
            this.prepareProjectData(data);
            this.gridComponent.inSearch = true;
            this.gridComponent.setcurrentpage(0);
         //   this.gridComponent.showData();
           this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
            this.loadingmessage = '';
            this.moveToTop();
          }).catch(error => {
            
            this.prepareProjectData(null);
            this.loadingmessage = '';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      }
    } else {
      const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityId = affinty['id'];

    if (affinityId !== 'undefined' && this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      this.inboxService.getPageLoadData(this.count, this.totalNumberOfRecords, affinityId,this.shortProductName)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
        this.gridComponent.inSearch = true;
         //   this.gridComponent.setcurrentpage(0);
        //    this.gridComponent.showData();
         this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
          const errorResponse = new Object();
          errorResponse['policyDetailsList'] = [];
          errorResponse['totalRecords'] = 0;

        });
    }
    }
  }


  dateRangeValidation(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (startDate > endDate) {
        this.productDateRangeMessage = 'Start Date should be prior to End Date';
        this.dateFlag = false;
      } else {
        this.productDateRangeMessage = '';
        this.dateFlag = true;
      }
    } else {
      this.productDateRangeMessage = '';
    }
  }


  public productStartChange() {
    //this.clearStartDate();

    const date = moment(this.inboxSearchDTO.startDate, 'DD/MM/YYYY').startOf(
      'day'
    );
   
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
     
      this.inboxSearchDTO.startDate = date;
    } else {
      this.inboxSearchDTO.startDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );

    }

  }

  public productEndChange() {
    //this.clearEndDate();
    const date = moment(this.inboxSearchDTO.endDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.inboxSearchDTO.endDate = date;
    } else {
      this.inboxSearchDTO.endDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
  }


  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
   this.getPageLoadDetails();
  }
  updatePageDataWithSearch(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    //this.issuedProposalSearch();
  }
  public getCovernoteNoInfo(coverNoteNo1) {
    this.proposalService.getProposalSummary(coverNoteNo1)
          .then(data => {
            if (data !== undefined) { 
              this.offlineProposalData = data;
              if('Business Package for Shop Owners' === data.policyTypeDescription) {
                this.shortProductName = 'Shop';
              } else {
                this.shortProductName = 'Home';
              }
              this.offlineProposalData.affinityName = this.affinityName;
              this.offlineProposalData.agentContactNumber = this.agentContactNumber;
            this.offlineProposalData.agentName = this.agentName;
            this.offlineProposalData.agentId = this.agentId;
            const proposerDetails = data;
            this.proposerVo.agentContactNumber = this.agentContactNumber;
            this.proposerVo.agentName = this.agentName;
            this.proposerVo.agentId = this.agentId;
            this.proposerVo.affinityName = this.affinityName;
    this.proposerVo.policyStartDate = proposerDetails.policyStartDate;
    this.proposerVo.policyTerm = proposerDetails.policyTermDescription;
    this.proposerVo.policyEndDate = proposerDetails.policyEndDate;
    this.proposerVo.masterPolicyNo = proposerDetails.masterPolicyNo;
    this.proposerVo.policyTypeName = proposerDetails.policyTypeDescription;
    this.proposerVo.planTypeName = proposerDetails.planDescription;
    this.proposerVo.covernoteNo = proposerDetails.covernoteNo;
    this.proposerVo.sumInsured = proposerDetails.sumInsured;
    this.proposerVo.customerId = proposerDetails.customerId;
    this.proposerVo.employeeId = proposerDetails.employeeId;
    this.proposerVo.affinityId = proposerDetails.affinityId;
    this.proposerVo.placeOfSupply = proposerDetails.placeOfSupply;
    this.proposerVo.policyIssueDate = proposerDetails.policyIssueDate;
    this.proposerVo.branchCode = proposerDetails.branchCode;
    this.proposerVo.branchName = proposerDetails.branchName;
    this.proposerVo.paymentDetails.otpVerifiedDate = proposerDetails.paymentDetails.otpVerifiedDate;
    this.proposerVo.paymentDetails.otpEncrypt = proposerDetails.paymentDetails.otpEncrypt;
    this.proposerVo.isCustomerProposal = proposerDetails.isCustomerProposal;
    if(proposerDetails.ssoTokenUser !== undefined) {
      this.proposerVo.ssoTokenUser = proposerDetails.ssoTokenUser;
    }
    this.productName = proposerDetails.producDesc;
    sessionStorage.setItem(USER_PROFILE, JSON.stringify(proposerDetails.affinityAuthToken));
    // commenting, we are getting the agent info from affinity tables.
    // this.proposerVo.agentId = proposerDetails.agentId;
    if (proposerDetails.productAnswers !== undefined) {
      this.questionList = proposerDetails.productAnswers;
    }
    if (proposerDetails.communicationDetails !== undefined) {
      const proposerCommDtls = proposerDetails.communicationDetails;
      this.proposerVo.communicationDetails.address1 = proposerCommDtls.address1;
      this.proposerVo.communicationDetails.address2 = proposerCommDtls.address2;
      this.proposerVo.communicationDetails.address3 = proposerCommDtls.address3;
      this.proposerVo.communicationDetails.pincode = proposerCommDtls.pincodeLocality;
      this.proposerVo.communicationDetails.stateName = proposerCommDtls.stateName;
      this.proposerVo.communicationDetails.districtName =
        proposerCommDtls.districtName;
      this.proposerVo.communicationDetails.cityName = proposerCommDtls.cityName;
      this.proposerVo.communicationDetails.emailId = proposerCommDtls.emailId;
      this.proposerVo.communicationDetails.mobile = proposerCommDtls.mobile;
      this.proposerVo.communicationDetails.telephone =
        proposerCommDtls.telephone;
      this.proposerVo.communicationDetails.gstinNo = proposerCommDtls.gstinNo;
    }
    if (proposerDetails.riskLocationDetails !== undefined) {
      const proposerRisDtls = proposerDetails.riskLocationDetails;
      this.proposerVo.riskLocationDetails.address1 = proposerRisDtls.address1;
      this.proposerVo.riskLocationDetails.address2 = proposerRisDtls.address2;
      this.proposerVo.riskLocationDetails.address3 = proposerRisDtls.address3;
      this.proposerVo.riskLocationDetails.pincode = proposerRisDtls.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = proposerRisDtls.stateName;
      this.proposerVo.riskLocationDetails.districtName =
      proposerRisDtls.districtName;
      this.proposerVo.riskLocationDetails.cityName = proposerRisDtls.cityName;
      this.proposerVo.riskLocationDetails.emailId = proposerRisDtls.emailId;
      this.proposerVo.riskLocationDetails.mobile = proposerRisDtls.mobile;
      this.proposerVo.riskLocationDetails.telephone =
      proposerRisDtls.telephone;
      this.proposerVo.riskLocationDetails.gstinNo = proposerRisDtls.gstinNo;
    }
    if (proposerDetails.customerDetails !== undefined) {
      this.customerDetail = proposerDetails.customerDetails;
      this.titleDes = proposerDetails.customerDetails.titleDescription;
      // const customerDetails = data.customerDetails;
      this.proposerVo.customerId = this.customerDetail.customerId;
      this.proposerVo.employeeId = this.customerDetail.employeeId;
    }
    if (proposerDetails.premiumDetails !== undefined) {
      const premiumDetails = proposerDetails.premiumDetails;
      this.premiumDetail.cgst = premiumDetails.cgst;
      this.premiumDetail.sgst = premiumDetails.sgst;
      this.premiumDetail.igst = premiumDetails.igst;
      this.premiumDetail.cessTax = premiumDetails.cessTax;
      this.premiumDetail.netPremium = premiumDetails.totalNetPremium;
      this.premiumDetail.grossPremium = premiumDetails.totalGrossPremium;
    }
    this.adultList = [];
    this.childList = [];
    // adult and child list seperation.
    if (proposerDetails.beneficiaryList.length !== 0) {
      this.beneficiaryList = proposerDetails.beneficiaryList;
      for (let i = 0; i < this.beneficiaryList.length; i++) {
        if (this.beneficiaryList[i].nomineeType === 2) {
          this.childList.push(this.beneficiaryList[i]);
        }
        if (this.beneficiaryList[i].nomineeType === 1) {
          this.adultList.push(this.beneficiaryList[i]);
        }
      }
    }
    if (proposerDetails.applianceDetails !== undefined) {
      this.proposerVo.applianceDetails = proposerDetails.applianceDetails;
    }
              this.getCovergeAndSections(data.planId);
              this.getBusinessPlanDetails(data.planId);
            }
          })
          .catch(error => { });
  }

  public getBusinessPlanDetails(planId) {
    this.proposalService.getApplianceDetailsPayment(planId).then(data => {
      for (let j = 0; j < data.length; j++) {
        for (let i = 0; i < this.proposerVo.applianceDetails.length; i++) {
          if (data[j].appliances.applianceID === this.proposerVo.applianceDetails[i].applianceID) {
            const appliance = new Object();
            appliance['applianceName'] = data[j].appliances.applianceName;
            appliance['quantity'] = this.proposerVo.applianceDetails[i].quantity;
            this.applianceDetail[j] = appliance;
          }
        }
      }
    }).catch(error => { });
  }
  
  
  getCovergeAndSections(planId) {
    this.proposalService.getSumInsuredDetailsPayment(planId).then(data => {
      this.sumInsuredForCoverages = data;
      const allCoverAgeIds: Array<any> = new Array<any>();
      if (data !== undefined) {
        for (let i = 0; i < data.length; i++) {
          allCoverAgeIds.push(data[i].coverageId);
          this.coverageIds['coverageIds'] = allCoverAgeIds;
        }
        this.getCoverageAndSectionForCoverageID();
      }
    }).

    catch(error => {
    });
  }
  
   public getCoverageAndSectionForCoverageID() {
    this.proposalService.getCoverageAndSectionsPayment(this.coverageIds).then(data2 => {
      let sectionType1;
      let sectionType2;
      for (let i = 0; i < data2.length; i++) {
        sectionType1 = data2[i].sectionType1;
        sectionType2 = data2[i].sectionType2;
      }
      this.coverageSectionType2 = [];
      for (let i = 0; i < sectionType1.length; i++) {

        for (let j = 0; j < sectionType2.length; j++) {
          if (sectionType1[i].sectionId === sectionType2[j].sectionId) {
            const coverageSectionType2 = new Object();
            coverageSectionType2['coverage'] = sectionType1[i].coverage;
            coverageSectionType2['riskCovered'] = sectionType1[i].riskCovered;
            coverageSectionType2['limitperitem'] = sectionType2[j].coverage;
            for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
              if (this.sumInsuredForCoverages[m].coverageId === sectionType1[i].coverageId) {
                coverageSectionType2['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
              if (this.sumInsuredForCoverages[m].coverageId === sectionType2[j].coverageId) {
                coverageSectionType2['limitperitemsuminsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
              }
            }
            this.coverageSectionType2.push(coverageSectionType2);
            delete sectionType1[i].sectionId;
            delete sectionType1[i].coverage;
            delete sectionType1[i].riskCovered;
            delete sectionType1[i].coverageId;
          }
        }
      }
      this.coverageSectionType1 = [];
      for (let j = 0; j < sectionType1.length; j++) {
        if (sectionType1[j].coverage !== undefined) {
          const coverageSectionType = new Object();
          coverageSectionType['coverage'] = sectionType1[j].coverage;
          coverageSectionType['riskCovered'] = sectionType1[j].riskCovered;
          for (let m = 0; m < this.sumInsuredForCoverages.length; m++) {
            if (this.sumInsuredForCoverages[m].coverageId === sectionType1[j].coverageId) {
              coverageSectionType['sumInsured'] = this.sumInsuredForCoverages[m].sumInsuredValue;
            }
          }
          this.coverageSectionType1.push(coverageSectionType);
        }
      }
      this.saveProposal();
    }).catch(error => {
    });
  }

  public saveProposal() {
    const policyDetails: Object = new Object();
      policyDetails['policyDetails'] = this.offlineProposalData;
      policyDetails['policyDetails']['coverageSectionType1'] = this.coverageSectionType1;
      policyDetails['policyDetails']['coverageSectionType2'] = this.coverageSectionType2;
      policyDetails['policyDetails']['planTypeName']=this.offlineProposalData.planDescription;
      const policyData = JSON.stringify(policyDetails);
      this.proposalService
        .savePropsal(policyData)
        .then(data4 => {
              this.loadingmessage = '';
              this.isPaymentSuccess = true;
              this.message = data4.certificateNo;
              this.certificateNumber = data4.certificateNo;
              this.certificateIssueDate=data4.policyIssueDate;
        }).catch(error => {

        });
  }


  public printProposal(){
  this.moveToTop();
    this.loadingmessage = 'Preparing Document...';
    this.downloadFailed = false;
    const printProsalJson: Object = new Object();
    printProsalJson['proposalDetails'] = new Object();
    printProsalJson['applicationName'] = 'affinity';
    printProsalJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printProsalJson['placeOfSupply'] = this.proposerVo.placeOfSupply;
    printProsalJson['dateOfIssue'] = this.proposerVo.policyIssueDate;
    printProsalJson['affinityName'] = this.proposerVo.affinityName;
    if (this.shortProductName === 'Health') {
      printProsalJson['proposalDetails']['templateName'] = 'ProposalForm';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else if (this.shortProductName === 'Shop') {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposalShop';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printProsalJson['proposalDetails']['templateName'] = 'BusinessPackageProposal';
      printProsalJson['proposalDetails']['affinityId'] = this.proposerVo.affinityId;
    }
    printProsalJson['proposalDetails']['proposalInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo'][
      'employeeInfo'
    ] = new Object();
    if (this.shortProductName === 'Health') {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.employeeId;
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'employeeCode'
      ] = this.proposerVo.agentName;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'licenseCode'
      ] = this.proposerVo.agentId;
      printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
        'contactNumber'
      ] =this.proposerVo.agentContactNumber;
    }
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchName'
    ] = this.proposerVo.branchName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'branchId'
    ] = this.proposerVo.branchCode;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['city'] =
      this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['employeeInfo']['state'] =
      this.proposerVo.communicationDetails.stateName;

    printProsalJson['proposalDetails']['proposalInfo'][
      'personInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'lastName'
    ] = this.customerDetail.lastName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'firstName'
    ] = this.customerDetail.firstName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'middleName'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address1'
    ] = this.proposerVo.communicationDetails.address1;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address2'
    ] = this.proposerVo.communicationDetails.address2;


    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'address3'
    ] = this.proposerVo.communicationDetails.address3;

    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'city'
    ] = this.proposerVo.communicationDetails.cityName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'state'
    ] = this.proposerVo.communicationDetails.stateName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'district'
    ] = this.proposerVo.communicationDetails.districtName;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'pinCode'
    ] = this.proposerVo.communicationDetails.pincode;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'telephone'
    ] = this.proposerVo.communicationDetails.telephone;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'mobile'
    ] = this.proposerVo.communicationDetails.mobile;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'email'
    ] = this.proposerVo.communicationDetails.emailId;
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'salariedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'selfemployedProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'otherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'detailsOfOtherProfession'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'indianResident'
    ] = '';
    printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
      'nonIndianResident'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'policyType'
    ] = new Object();
    if (this.proposerVo.policyTypeName === 'Individual') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = '';
    } else if (this.proposerVo.policyTypeName === 'Family Floater') {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'individual'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['policyType'][
        'familyFloater'
      ] = 'false';
    }

    printProsalJson['proposalDetails']['proposalInfo']['planType'] = new Object();
    if (this.proposerVo.planTypeName === '2 Adults') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adult 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '2 Adult 2 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult 1 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = '';
    } else if (this.proposerVo.planTypeName === '1 Adult 2 Child') {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['planType']['twoAdults'] =
        'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsOneChild'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'twoAdultsTwoChildren'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultOneChild'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['planType'][
        'oneAdultTwoChildren'
      ] = 'false';
    }

    printProsalJson['proposalDetails']['proposalInfo'][
      'typeOfBusiness'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness']['fresh'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['typeOfBusiness'][
      'renewal'
    ] = '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'sumInsuredInfo'
    ] = new Object();

    if (this.proposerVo.sumInsured === '2 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '3 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'true';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = '';
    } else if (this.proposerVo.sumInsured === '5 Lakhs') {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'true';
    } else {
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'twoLac'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'threeLac'
      ] = 'false';
      printProsalJson['proposalDetails']['proposalInfo']['sumInsuredInfo'][
        'fiveLac'
      ] = 'false';
    }
    const date =this.proposerVo.policyEndDate.split(' ');
    printProsalJson['proposalDetails']['proposalInfo'][
      'policyDuration'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'from'
    ] = this.proposerVo.policyStartDate;
    printProsalJson['proposalDetails']['proposalInfo']['policyDuration'][
      'to'
    ] = date[0] + ' 23:59:59';




    printProsalJson['proposalDetails']['proposalInfo'][
      'insured1Info'
    ] = new Object();
    if (this.adultList !== undefined) {
    if (this.adultList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured1Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'name'
      ] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipWithProposer'
      ] = this.adultList[0].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'gender'
      ] = this.adultList[0].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'dob'
      ] = this.adultList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'nomineeName'
      ] = this.adultList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'][
        'relationshipOfNominee'
      ] = this.adultList[0].nomineeRelationDescription;
    }
    if (this.adultList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured2Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'name'
      ] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipWithProposer'
      ] = this.adultList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'gender'
      ] = this.adultList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'dob'
      ] = this.adultList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'nomineeName'
      ] = this.adultList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'][
        'relationshipOfNominee'
      ] = this.adultList[1].nomineeRelationDescription;
    }
  }
  if (this.childList !== undefined) {
    if (this.childList.length > 0) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured3Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'name'
      ] = this.childList[0].firstName + ' ' + this.childList[0].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipWithProposer'
      ] = this.childList[0].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'gender'
      ] = this.childList[0].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'dob'
      ] = this.childList[0].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'nomineeName'
      ] = this.childList[0].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'][
        'relationshipOfNominee'
      ] = this.childList[0].nomineeRelationDescription;
    }
    if (this.childList.length > 1) {
      printProsalJson['proposalDetails']['proposalInfo'][
        'insured4Info'
      ] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'name'
      ] = this.childList[1].firstName + ' ' + this.childList[1].lastName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipWithProposer'
      ] = this.childList[1].proposerRelationDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'gender'
      ] = this.childList[1].genderDescription;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'dob'
      ] = this.childList[1].dob;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'nomineeName'
      ] = this.childList[1].nomineeName;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'][
        'relationshipOfNominee'
      ] = this.childList[1].nomineeRelationDescription;
    }
  }
    printProsalJson['proposalDetails']['proposalInfo'][
      'paymentInfo'
    ] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cash'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['cheque'] =
      '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['dd'] = '';
    printProsalJson['proposalDetails']['proposalInfo']['paymentInfo']['others'] =
      '';

    printProsalJson['proposalDetails']['proposalInfo'][
      'medicalQuestion'
    ] = new Object();


    printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['customerId'] = this.proposerVo.customerId;
    if (this.shortProductName !== 'Health' ) {
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'address1'
      ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3;
      printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
        'city'
      ] = this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName;
      if (this.proposerVo.riskLocationDetails !== undefined) {
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationAddress'
              ] = this.proposerVo.riskLocationDetails.address1 + ' ' + this.proposerVo.riskLocationDetails.address2 + ' ' + this.proposerVo.riskLocationDetails.address3;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationCity'] = this.proposerVo.riskLocationDetails.cityName + ' ' + this.proposerVo.riskLocationDetails.districtName + ' ' +
              this.proposerVo.riskLocationDetails.stateName;
              printProsalJson['proposalDetails']['proposalInfo']['personInfo'][
                'riskLocationPincode'
              ] = this.proposerVo.riskLocationDetails.pincode;
      }
    }
    const appno = this.certificateNumber.substring(this.certificateNumber.length - 12, this.certificateNumber.length);
    printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['applicationNo'] = appno;
    if (this.proposerVo.policyTypeName !== 'Individual' && this.proposerVo.policyTypeName !== 'Family Floater') {
      printProsalJson['proposalDetails']['proposalInfo']['insured1Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['insured2Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['insured3Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['insured4Info'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'] = new Object();
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['customerId'] = this.proposerVo.customerId;
      const appno = this.certificateNumber.substring(this.certificateNumber.length - 12, this.certificateNumber.length);
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['applicationNo'] = appno;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['date'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo']['financierBranchName'] = null;
      if (this.shortProductName === 'Shop') {
        if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
          || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
          printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
            'occupancy'
          ] = SAHAJ_AND_LIB_OCCUPANCY;
        } else  if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
          printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
            'occupancy'
          ] = MEENACHIL_SHOP_OCCUPANCY;
        } 
        else {
          printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
            'occupancy'
          ] = SHOP_OCCUPANCY;
        }
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancyTariff'
        ] = SHOP_OCCUPANCY_TARIFF;
  
      } else if (this.shortProductName === 'Home') {
      if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = MEENACHIL_HOME_OCCUPANCY;
      } else {

        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
        printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
          'occupancyTariff'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'loanAccountNo'
      ] = '';
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'planName'
      ] = this.proposerVo.planTypeName;
      printProsalJson['proposalDetails']['proposalInfo']['otherInfo'][
        'otpVerifiedDate'
      ] = this.proposerVo.paymentDetails.otpVerifiedDate;
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printProsalJson['proposalDetails']['proposalInfo']['coverageSectionType2'] = this.coverageSectionType2;
      printProsalJson['proposalDetails']['proposalInfo']['equipment'] = new Array();
      
      let serialCounter: Number = 0;
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printProsalJson['proposalDetails']['proposalInfo']['equipment'].push(obj1);
        // printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = this.mockCertificateProposal['certificateDetails']['certificateInfo']['equipment'];
      }
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = new Array();
      printProsalJson['proposalDetails']['proposalInfo']['premiumDetails'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['excess'] = null;
      printProsalJson['proposalDetails']['proposalInfo']['certificateDetails'] = null;
    }
    if (printProsalJson) {
      this.proposalService.getPrintProposalpayment(JSON.stringify(printProsalJson))
        .then(data1 => {
          const parsedResponse = data1; // .blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printFrom.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.proposerVo.covernoteNo +'.pdf'; //printFrom.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.downloadFailed = true;
          this.loadingmessage = '';
        });
    }
  }

    public printCertificate() {
    this.moveToTop();
   // this.loadingmessage = 'Preparing Document...';
    this.downloadFailed = false;
 // this.productId = sessionStorage.getItem(PRODUCT_CODE);

    const certificateNo = this.certificateNumber;
    const printCertificateJson: Object = new Object();
    printCertificateJson['certificateDetails'] = new Object();
    printCertificateJson['affinityLogo'] = this.proposerVo.affinityLogo;
    printCertificateJson['affinityName'] = this.proposerVo.affinityName;
    printCertificateJson['certificateDetails']['affinityName'] = this.proposerVo.affinityName;
    if (this.shortProductName === 'Health') {
      printCertificateJson['certificateDetails']['templateName'] = 'CertificateForm';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    } else if (this.shortProductName === 'Shop') {
      printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificateShop';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
    } else {
      printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageCertificate';
      printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;
      if(this.proposerVo.affinityName.startsWith("MUTHOOTTU"))
      {
        printCertificateJson['certificateDetails']['templateName'] = 'BusinessPackageMiniMuthootCertificate';
      }
    }
    printCertificateJson['applicationName'] = 'affinity';
   // printCertificateJson['certificateDetails']['affinityId'] = this.proposerVo.affinityId;


    printCertificateJson['certificateDetails'][
      'certificateInfo'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo'][
      'policyInfo'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'masterPolicyNo'
    ] = this.proposerVo.covernoteNo;
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'groupAdministrator'
     ] = 'Manappuram Finance Ltd, Kerala';
    if (this.proposerVo.policyTypeName === 'Individual') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyVariant'
      ] = 'Individual';
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyVariant'
      ] = 'Family Floater';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'certificateNo'
    ] = certificateNo;
    if(this.titleDes!==undefined && this.customerDetail.lastName!==undefined)
    {
       printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.titleDes + ' '+ this.customerDetail.firstName + ' ' + this.customerDetail.lastName;
    }else
    {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'insuredName'
        ] = this.customerDetail.firstName;
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'insuredAddress'
    ] = this.proposerVo.communicationDetails.address1 + ' ' + this.proposerVo.communicationDetails.address2 + ' ' + this.proposerVo.communicationDetails.address3 +
     ' ' + this.proposerVo.communicationDetails.cityName + ' ' + this.proposerVo.communicationDetails.districtName + ' ' + this.proposerVo.communicationDetails.stateName +
     ' ' + this.proposerVo.communicationDetails.pincode;


if (this.offlineProposalData.riskLocationDetails !== undefined) {
  if (this.offlineProposalData.riskLocationDetails.address1 === undefined) {
    this.offlineProposalData.riskLocationDetails.address1 = '';
  }
  if (this.offlineProposalData.riskLocationDetails.address2 === undefined) {
    this.offlineProposalData.riskLocationDetails.address2 = '';
  }
  if (this.offlineProposalData.riskLocationDetails.address3 === undefined) {
    this.offlineProposalData.riskLocationDetails.address3 = '';
  }
  printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
   'riskLocationAddress'
 ] = this.offlineProposalData.riskLocationDetails.address1 + ' ' + this.offlineProposalData.riskLocationDetails.address2 + ' ' + this.offlineProposalData.riskLocationDetails.address3 +
  ' ' + this.offlineProposalData.riskLocationDetails.cityName + ' ' + this.offlineProposalData.riskLocationDetails.districtName + ' ' + this.offlineProposalData.riskLocationDetails.stateName +
  ' ' + this.offlineProposalData.riskLocationDetails.pincodeLocality;
}


    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'customerNo'
    ] = this.proposerVo.customerId;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'tpaCode'
    ] = '003 / Medi Assist India TPA Pvt. Ltd';
    if (this.shortProductName === 'Shop') {
      if (this.proposerVo.affinityName.startsWith('SAHAJ') || this.proposerVo.affinityName.startsWith('SREI')
        || (this.proposerVo.affinityName.startsWith('Lead') || this.proposerVo.affinityName.startsWith('LIB'))) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SAHAJ_AND_LIB_OCCUPANCY;
      }  else if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = MEENACHIL_SHOP_OCCUPANCY;
      } else {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = SHOP_OCCUPANCY;
      }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = SHOP_OCCUPANCY_TARIFF;

    } else if (this.shortProductName === 'Home') {

      if (this.proposerVo.affinityName.startsWith('MEENACHIL')) {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = MEENACHIL_HOME_OCCUPANCY;
      } else {
        printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
          'occupancy'
        ] = HOME_OCCUPANCY_AND_TARIFF;
      }
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'occupancyTariff'
      ] = HOME_OCCUPANCY_AND_TARIFF;
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'proposalNo'
    ] = this.proposerVo.covernoteNo;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'planName'
    ] = this.proposerVo.planTypeName;
    const endL = this.offlineProposalData.policyEndDate.split(' ');
        const startL1 = this.offlineProposalData.policyStartDate.split(' ');
   printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['periodOfInsurance'] = startL1[0] + ' to ' + endL[0];
  
    if (this.proposerVo.policyTypeName === 'Individual' || this.proposerVo.policyTypeName === 'Family Floater') {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyTenure'
      ] = this.proposerVo.policyTerm;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'policyTenure'
      ] = startL1[0] + ' 00:00:00' + ' to ' + endL[0] + ' 23:59:59';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo']['customerName'] = this.offlineProposalData.customerDetails.customerName;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'policyServicingOffice'
    ] = 'Liberty General Insurance Ltd. Door No. 39/4166-A, 2nd Floor, Shema Building,M.G Road, Ravipuram, Kochi - 682016 Boardline - 484-6900 010,Fax - 022 6700 1606';
    if (this.beneficiaryList !== undefined) {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'relationshipWithInsured'
      ] = this.beneficiaryList[0].proposerRelationDescription;
    } else {
      printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
        'relationshipWithInsured'
      ] = '';
    }
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'sumInsured'
    ] = this.proposerVo.sumInsured;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'emailID'
    ] = this.proposerVo.communicationDetails.emailId;
    printCertificateJson['certificateDetails']['certificateInfo']['policyInfo'][
      'tollfreeNo'
    ] = '';

if (this.proposerVo.policyTypeName === 'Individual' || this.proposerVo.policyTypeName === 'Family Floater') {
if (this.adultList.length > 0) {

      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithPrimaryMember'] = this.adultList[0].proposerRelationDescription;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = this.adultList[0].genderDescription;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = this.adultList[0].dob;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = this.adultList[0].nomineeName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithNominee'] = this.adultList[0].nomineeRelationDescription;

      if (this.adultList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['name'] = this.adultList[1].firstName + ' ' + this.adultList[1].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithPrimaryMember'] = this.adultList[1].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['gender'] = this.adultList[1].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['dob'] = this.adultList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['nomineeName'] = this.adultList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured2Info']['relationshipWithNominee'] = this.adultList[1].nomineeRelationDescription;
      }

      if (this.childList.length > 0) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['name'] = this.adultList[0].firstName + ' ' + this.adultList[0].lastName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithPrimaryMember'] = this.childList[0].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['gender'] = this.childList[0].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['dob'] = this.childList[0].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['nomineeName'] = this.childList[0].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured3Info']['relationshipWithNominee'] = this.childList[0].nomineeRelationDescription;
      }

      if (this.childList.length > 1) {
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info'] = new Object();
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['name'] = this.childList[1].firstName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithPrimaryMember'] = this.childList[1].proposerRelationDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['gender'] = this.childList[1].genderDescription;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['dob'] = this.childList[1].dob;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['nomineeName'] = this.childList[1].nomineeName;
        printCertificateJson['certificateDetails']['certificateInfo']['insured4Info']['relationshipWithNominee'] = this.childList[1].nomineeRelationDescription;
      }
    } else {

      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info'] = new Object();
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['name'] = this.customerDetail.firstName + ' ' + this.customerDetail.lastName;
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipWithProposer'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['gender'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['dob'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['nomineeName'] = '';
      printCertificateJson['certificateDetails']['certificateInfo']['insured1Info']['relationshipOfNominee'] = '';

    }

  }

    printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();
      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Basic Premium ( Taxable Value)';
        obj2['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST -9%';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
      }


      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST -9%';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
      }


      if (this.premiumDetail.igst) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'IGST -18%';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
      }

      if(this.premiumDetail.cessTax) {
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Cess -1%';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
      }

      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Total Premium Payable';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Total Premium Payable';
        obj7['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      }
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ] = new Object();
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['dateOfIssue'] = this.certificateIssueDate;
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['gstin'] = this.proposerVo.communicationDetails.gstinNo;
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['sacCode'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['irdaRegNo'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['cinNo'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['uin'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['serviceDescription'] = '';
    printCertificateJson['certificateDetails']['certificateInfo'][
      'receiptDetails'
    ]['placeOfSupply'] = this.proposerVo.placeOfSupply;


    if (this.proposerVo.policyTypeName !== 'Individual' && this.proposerVo.policyTypeName !== 'Family Floater') {
      printCertificateJson['certificateDetails']['certificateInfo']['equipment'] = new Array();
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType1'] = this.coverageSectionType1;
      printCertificateJson['certificateDetails']['certificateInfo']['coverageSectionType2'] = this.coverageSectionType2;
      let serialCounter: Number = 0;
      
      for (const data of this.applianceDetail) {
        const obj1: Object = new Object();
        serialCounter = Number(serialCounter) + 1;
        obj1['serialNumber'] = serialCounter;
        obj1['appliance'] = data.applianceName;
        obj1['quantity'] = data.quantity;
        printCertificateJson['certificateDetails']['certificateInfo']['equipment'].push(obj1);
      }
      printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'] = new Array();

      if (this.premiumDetail.netPremium) {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = 'Rs.' + this.premiumDetail.netPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      } else {
        const obj2: Object = new Object();
        obj2['premiumType'] = 'Net Premium(Taxable value)';
        obj2['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj2);
      }

      if (this.premiumDetail.cgst) {
        const obj3: Object = new Object();
        obj3['premiumType'] = 'CGST(9%)';
        obj3['premiumValue'] = 'Rs.' + this.premiumDetail.cgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj3);
      }


      if (this.premiumDetail.sgst) {
        const obj4: Object = new Object();
        obj4['premiumType'] = 'SGST(9%)';
        obj4['premiumValue'] = 'Rs.' + this.premiumDetail.sgst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj4);
      }

      if (this.premiumDetail.igst) {
        const obj5: Object = new Object();
        obj5['premiumType'] = 'IGST(18%)';
        obj5['premiumValue'] = 'Rs.' + this.premiumDetail.igst;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj5);
      }


      if(this.premiumDetail.cessTax){
        const obj6: Object = new Object();
        obj6['premiumType'] = 'Cess(1%)';
        obj6['premiumValue'] = 'Rs.' + this.premiumDetail.cessTax;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj6);
      }


      if (this.premiumDetail.grossPremium) {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Premium Including Tax';
        obj7['premiumValue'] = 'Rs.' + this.premiumDetail.grossPremium;
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      } else {
        const obj7: Object = new Object();
        obj7['premiumType'] = 'Net Premium(Taxable value)';
        obj7['premiumValue'] = '';
        printCertificateJson['certificateDetails']['certificateInfo']['premiumDetails'].push(obj7);
      }


    } 
    const certificateJson = JSON.stringify(printCertificateJson);
    this.loadingmessage = 'Preparing Certificate...';
    if (certificateJson) {
      this.proposalService.getPrintCertificatePayment(certificateJson)
        .then(data1 => {
          const parsedResponse = data1; // .blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/pdf'
          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'printcertificate.pdf');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = this.certificateNumber+'.pdf'; // 'printcertificate.pdf';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
          this.downloadFailed = true;
          this.loadingmessage = '';
        });
    }

  
  }
 



}




