import { Component, OnInit, HostListener, ViewChild, ElementRef, AfterViewInit, ɵConsole } from '@angular/core';
import { GridComponent } from 'src/grid/grid.component';

import { ProposalVO } from 'src/dto/ProposalVO';
import { CommunicationVO } from '../dto/CommunicationVO';
import { BeneficiaryVO } from '../dto/BeneficiaryVO';
import { Router, ActivatedRoute } from '@angular/router';
import { PremiumDetailsVO } from 'src/dto/PremiumDetailsVO';
import { ModalService } from '../services/modal.services';
import { DatePipe } from '@angular/common';
import {
  DESCRIPTION,
  CODE,
  USER_NAME,
  NAME,
  EMAIL_ID,
  LAST_ACTIVE_DATE,
  ACCOUNT,
  STATUS,
  MAIN_PAGE_PART_URL,
  END_DATE,
  USER_PROFILE_DATA
} from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { PRODUCT_CODE } from './../common/common.constants';
import { EmitterService } from '../services/EmitterService';
import { UserManagementServices } from 'src/services/user-management.services';


@Component({
  selector: 'app-view-users',
  templateUrl: './view-users.component.html',
  styleUrls: ['./view-users.component.css']
})
export class ViewUsersComponent implements OnInit, AfterViewInit {

  public columnsData: Array<Object> = Array<Object>();
  public projectData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public actionColumnsDataDoc: Array<Object> = Array<Object>();
  public searchArray: Array<Object> = Array<Object>();
  public loadingmessage = '';
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  // public loadingmessage = '';
  public certificateNumber = '';
  public hasErrorMessage: Boolean = false;
  public message: any = '';
  public paymentError = false;
  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public hrLeftMargin: number;
  public hrWidth: number;
  public count: any;
  public totalNumberOfRecords: Number;
  public totalRows: any;
  public productId: any;
  public display = 'none';
  public description: String = '--Select--';
  public roleId: any;
  public roleName: any;
  public actionFailedFlag = false;
  public actionFailedMessage = '';

  @ViewChild('contentBody') contentBodyRef: ElementRef;
  /*Search Pagination*/
  @ViewChild('grid') gridComponent: GridComponent;
  /*****************/
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.contentBodyRef.nativeElement.clientWidth) / 2);
    if (this.hrWidth >= 900) {
      this.gridComponent.dispCount = 10;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else if (this.hrWidth < 900 && this.hrWidth >= 700) {
      this.gridComponent.dispCount = 5;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    } else {
      this.gridComponent.dispCount = 3;
      this.gridComponent.getDisplayPagesForCurrentPage(this.gridComponent.currentpage);
    }
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  constructor(
    private userManagementServices: UserManagementServices,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private router: Router,
    private datePipe: DatePipe
  ) { }



  ngAfterViewInit() {
    this.onResize();
  }
  set(value: any, description: any) {
    this.proposerSearchVo.searchId = value;
    this.description = description;
    this.toggleDisplay();
  }
  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }
  ngOnInit() {
    // this.preparePageParams();
    this.onResize();
    this.router.routeReuseStrategy.shouldReuseRoute = function () {
      return false;
    };
    this.searchArray = [];
    this.loadingmessage = 'Please wait...';
    this.preparePageParams();
    this.count = 0;
    this.totalNumberOfRecords = 10;
    this.getUsersData();
    this.prepareColumnsData();
    this.prepareActionColumns();
    this.viewUsersSearchArray();
    this.roleId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleId'];
    this.roleName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
  }
  public getUsersData() {
    this.loadingmessage = 'Please wait...';
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      this.userManagementServices.getUsers(this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data['userDetailsList']);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
          // this.loadingmessage = '';
          // this.actionFailedFlag = true;
          // this.actionFailedMessage = 'User list could not be retrieved';
        });
    }
  }
  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.getUsersData();
  }
  prepareColumnsData() {
    const col1: Object = new Object();
    col1['colName'] = 'Username';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Name';
    this.columnsData.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Email ID';
    this.columnsData.push(col3);
    const col4: Object = new Object();
    col4['colName'] = 'Last Active Date';
    this.columnsData.push(col4);
    const col5: Object = new Object();
    col5['colName'] = 'Account';
    this.columnsData.push(col5);
    const col6: Object = new Object();
    col6['colName'] = 'Status';
    this.columnsData.push(col6);
    const col7: Object = new Object();
    col7['colName'] = 'Branch Code';
    this.columnsData.push(col7);
  }

  public prepareProjectData(viewUsersData) {
    const viewUsersDataCopy = viewUsersData;
    const userList = [];
    if (viewUsersData !== undefined) {
      for (let i = 0; i < viewUsersData.length; i++) {
        const data: Object = new Object();
        data['Username'] = viewUsersData[i].userName;
        data['Name'] = viewUsersData[i].name;
        data['Email ID'] = viewUsersData[i].emailAddress;
        data['Last Active Date'] = viewUsersData[i].lastActivityDate.substr(0, 10);
        data['Account'] = viewUsersData[i].isLocked ? 'Locked' : 'Unlocked';
        data['Status'] = viewUsersData[i].isActive ? 'Enabled' : 'Disabled';
        data['Employee Code'] = viewUsersData[i].employeeCode;
        data['Role ID'] = viewUsersData[i].roleId;
        data['User ID'] = viewUsersData[i].userId;
        data['userIdEncrypt'] = viewUsersData[i].userIdEncrypt;
        data['Branch Code'] = viewUsersData[i].branchCode ? String(viewUsersData[i].branchCode) : String(' ');

        if (String(this.roleId) === '2') {
          if (String(data['Role ID']) === '1' || String(data['Role ID']) === '3') {
            data['disableEdit'] = true;
          }
        } else if (String(this.roleId) === '3') {
          if (String(data['Role ID']) === '1' || String(data['Role ID']) === '2') {
            data['disableEdit'] = true;
          }
        } else {
          data['disableEdit'] = false;
        }
        userList.push(data);
      }
      this.projectData = JSON.parse(JSON.stringify(userList));
    }
  }

  public prepareActionColumns() {
    this.actionColumnsData.push('EDIT');
  }

  public viewToUpdateUser(data: string) {
    const url = 'welcome/(contentBody:createUser)?edit=true&userID=' + data['User ID']+'&userIdEncrypt='+data['userIdEncrypt'];
    this.router.navigateByUrl(url);
    this.moveToTop();
  }
  public viewUsersSearchArray() {

    const code0: Object = new Object();
    code0[CODE] = USER_NAME;
    code0[DESCRIPTION] = 'Username';
    const code2: Object = new Object();
    code2[CODE] = NAME;
    code2[DESCRIPTION] = 'Name';
    const code3: Object = new Object();
    code3[CODE] = EMAIL_ID;
    code3[DESCRIPTION] = 'Email ID';
    const code4: Object = new Object();
    code4[CODE] = LAST_ACTIVE_DATE;
    code4[DESCRIPTION] = 'Last Active Date';
    const code5: Object = new Object();
    code5[CODE] = ACCOUNT;
    code5[DESCRIPTION] = 'Account';
    const code6: Object = new Object();
    code6[CODE] = STATUS;
    code6[DESCRIPTION] = 'Status';
    const code7: Object = new Object();
    code7[CODE] = 'branchCode';
    code7[DESCRIPTION] = 'Branch Code';
    this.searchArray.push(code0);
    this.searchArray.push(code2);
    this.searchArray.push(code3);
    this.searchArray.push(code7);
  }

  openModal(id: string) {
    this.modalService.open(id);
  }

  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }


  closeModal(id: string) {
    this.modalService.close(id);
  }
  public viewUsersSearch() {
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {

      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
          this.loadingmessage = 'Please wait...';
          this.userManagementServices.getUsers(this.count, this.totalNumberOfRecords)
            .then(data => {
              this.totalRows = data['totalRecords'];
              this.prepareProjectData(data['userDetailsList']);
              this.gridComponent.inSearch = false;
               this.gridComponent.setcurrentpage(0);
              // this.gridComponent.showData();
              this.moveToTop();
              this.loadingmessage = '';
            })
            .catch(error => {
              this.loadingmessage = 'Please wait...';
              setTimeout(() => {
                this.serviceDownMessage();
              }, 10000);
              // this.loadingmessage = '';
              // this.actionFailedFlag = true;
              // this.actionFailedMessage = 'User list could not be retrieved';
            });
        }
      }

    } else if (this.proposerSearchVo.searchId) {
      this.loadingmessage = 'Please wait...';
      this.count = 0;
      this.userManagementServices.userSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, this.count, this.totalNumberOfRecords).then(data => {
        this.prepareProjectData(data['userDetailsList']);
        this.totalRows = data['totalRecords'];
        this.gridComponent.inSearch = true;
        // this.gridComponent.showData();
        // this.gridComponent.setcurrentpage(0);
        this.moveToTop();
        this.loadingmessage = '';
      })
        .catch(error => {
          this.prepareProjectData(null);
          this.moveToTop();
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
          // this.actionFailedFlag = true;
          // this.actionFailedMessage = 'User list could not be retrieved';
          // this.loadingmessage = '';
        });
    }
  }

  public preparePageParams() {
    this.route.params.subscribe(params => {
      const code: string = params.productCode;
      sessionStorage.setItem(PRODUCT_CODE, code);
    });
  }
  public resetSearch() {
    this.proposerSearchVo.searchInput = '';
  }

  /*Search Pagination*/
  public getIssuedDetailsWithSearch() {
    this.loadingmessage = 'Please wait...';
    if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
      const policyStatus = 2;
      const action = 'ISSUED';
      this.userManagementServices.userSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, this.count, this.totalNumberOfRecords)
        .then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data['userDetailsList']);
          this.loadingmessage = '';
          this.moveToTop();
        })
        .catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
          // this.loadingmessage = '';
          // this.actionFailedFlag = true;
          // this.actionFailedMessage = 'User list could not be retrieved';
        });
    }
  }

  /*Search Pagination*/
  updatePageDataWithSearch(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.viewUsersSearch();
  }

  public clearSearch() {
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
      this.viewUsersSearch();
    }
  }
  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      },
    );
    this.router.navigateByUrl('');
  }
}
