import { Component, OnInit } from '@angular/core';
@Component({
  selector: 'app-affinitylogin',
  templateUrl: './affinity-login.html',

})
export class AffinityLoginComponent implements OnInit {
  ngOnInit() {

  }

}
