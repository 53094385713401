import { OnInit, EventEmitter, Output, OnChanges, AfterViewInit } from '@angular/core';
import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';


import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ElementBase } from 'src/accessor/element.base';
import { SelectService } from '../services/select.services';
import { ProposalServices } from './../services/proposal.services';
import { BENIFICIARY_SELF, RELATIONSHIP_DEPENDENT_DAUGHTER, RELATIONSHIP_DEPENDENT_SON, RELATIONSHIP_DAUGHTER, RELATIONSHIP_SON, SHORT_PROD_NAME } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { ActivatedRoute } from '@angular/router';
import { PRODUCT_CODE } from '../common/common.constants';

@Component({
  selector: 'app-select',
  templateUrl: './select.html',
  //  animations,
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: SelectComponent,
    multi: true,
  }],
})
export class SelectComponent extends ElementBase<String> implements OnInit, OnChanges, AfterViewInit {
  @Input() public label: String;
  @Input() public id: any;
  @Input() public class: String;
  @Input() public labelHidden: any = 'false';
  @Input() public params: any;
  @Input() public placeholder: String;
  @Input() public lookupName: String;
  @Input() public required: String;
  @Input() public DataArray: Array<Object> = new Array<Object>();
  @Input() public readonly: any = false;
  @Input() public isautosearch: any;
  @Output() public changeevent = new EventEmitter<Object>();
  @Input()
  public validationMessage: any = false ;
  public othersFlag: Boolean = false;
  @Input()
  public customValidationMessage: any = false;

  @Input()
  public disableOnRenewal = false;
  public isRenewal = false;
  public isEndorsement = false;

  @Input()
  public disableOnEndorsement = false;


  public results: any;
  @ViewChild(NgModel) model: NgModel;

  public identifier = `app-select${identifier++}`;

  public undefinedValue = undefined;

  public display = 'none';
  @Input() public forSearch = false;
  public description: string;


  @Input() public disable: boolean;
  @Input() public greyoutOnSingleOption: boolean;

  public disablePlan: Boolean = false;


  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private proposalServices: ProposalServices,
    private selectService: SelectService,
    private route: ActivatedRoute
  ) {
    super(validators, asyncValidators);
  }
  ngOnInit() {
    if (this.lookupName) {
      this.fetchLookUpdata(this.lookupName);
    }
    // this.route.queryParams.subscribe(queryParams => {
    //   const actionType = queryParams['actionType'];
    //   if (actionType === 'RENEWAL') {
    //     this.isRenewal = true;
    //   } else if (actionType === 'ENDORSEMENT') {
    //     this.isEndorsement = true;
    //   }
    // });
    EmitterService.get('SHOW_VALIDATION_MESSAGE').subscribe(data => {
      if (this.value) {
        this.onBlurMethod(true);
      }
    });

    EmitterService.get('HIDE_VALIDATION_MESSAGE').subscribe(data => {
      this.validationMessage = '';
      this.customValidationMessage = '';
    });

  }

  ngOnChanges(changes: any) {
    if (this.lookupName) {
      this.DataArray = [];
      this.validationMessage = '';
      this.fetchLookUpdata(this.lookupName);
    }
  }
  ngAfterViewInit(): void {
     if (this.forSearch) {
       this.value = this.DataArray[0]['Code'];
       this.description = this.DataArray[0]['Description'];
     }
  }

  fetchLookUpdata(lookupName: any) {
    if (lookupName) {
      this.selectService.fetchLookUpData(lookupName, this.params)
        .then(data => {
          this.DataArray = this.convertToDataArray(data, lookupName);
        })
        .catch(err => {
          if (lookupName === 'PlanType' || lookupName === 'SumInsured') {
            const data = [];
            this.DataArray = this.convertToDataArray(data, lookupName);
          }
        });
    }

  }
  public onBlurMethod(showError: any): boolean {
    if (showError) {
      this.validationMessage = '';
    }
    if ((this.required || this.required === 'true') && (this.value === undefined || this.value === null || !this.value)) {
      if (showError) {
        this.validationMessage = (this.label + ' is required');
      }

      // if (this.value === 'Others') {
      //   this.othersFlag = true;
      // }
      return false;
    } else {
    //  this.validationMessage = '';
    //  console.log('one 5' + this.validationMessage);
    }

    return true;
  }
  public onBlurOutSideMethod(showError: any): boolean {
    if (showError) {
      this.validationMessage = '';
    }
    if ((this.required || this.required === 'true') && (this.value === undefined || this.value === null || !this.value)) {
      if (showError) {
        this.validationMessage = (this.label + ' is required');
      }
      // if (this.value === 'Others') {
      //   this.othersFlag = true;
      // }
      return false;
    } else {
      this.validationMessage = '';
    }

    return true;
  }

  public onChangeMethod(data: any) {
    this.changeevent.emit(data);
    this.onBlurMethod(true);
    EmitterService.get('VALIDATE').emit();
  }

  private convertToDataArray(dataArray, metadataname) {

    switch (metadataname) {
      case 'PolicyTerm':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['policyTermId'];
            obj['Description'] = data['policyTerm'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('PolicyTerm', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'PolicyType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {

            const obj: Object = new Object();
            obj['Code'] = data['policyTypeId'];
            obj['Description'] = data['policyTypeName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('PolicyType', JSON.stringify(dataArray));
          return dtArray;

        }
      case 'PlanType':
        {
          //  this.value = undefined;
          this.disablePlan = false;
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['planid'];
            obj['Description'] = data['planTypeName'];
            if (obj['Description'] === '1 Adult') {
              this.disablePlan = true;
              this.value = obj['Code'];
              this.validationMessage = '';
              this.placeholder = undefined;
            } else {
              this.disablePlan = false;
            }
            dtArray.push(obj);
          }
          sessionStorage.setItem('PlanType', JSON.stringify(dataArray));
          this.changeevent.emit(this.value);
          return dtArray;
        }
      case 'MasterPolicy':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['masterPolicyId'];
            obj['Description'] = data['masterPolicyId'];
            dtArray.push(obj);
          }
          if (dtArray.length === 1 && this.greyoutOnSingleOption) {
            this.placeholder = undefined;
            this.value = dtArray[0]['Code'];
          }
          sessionStorage.setItem('MsaterPolicy', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'SumInsured':
        {

          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['sumInsured'];
            obj['Description'] = data['sumInsured'];
            dtArray.push(obj);
          }
          dtArray.sort(function (a: SelectObject, b: SelectObject) {
            if (a.Description > b.Description) { return 1; }
            if (a.Description < b.Description) { return -1; }
            return 0;
          });
          sessionStorage.setItem('SumInsured', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'Title':
        {
          const prodName = sessionStorage.getItem(SHORT_PROD_NAME);
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['titleId'];
            obj['Description'] = data['titleName'];
            dtArray.push(obj);
          }
          if (String(prodName) === 'Health') {
            dtArray.splice(-1, 1);
          }


          sessionStorage.setItem('Title', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'Gender':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['genderId'];
            obj['Description'] = data['genderName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('Gender', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'RelationType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            if (data['relationType'] !== BENIFICIARY_SELF && data['relationType'] !== RELATIONSHIP_DAUGHTER && data['relationType'] !== RELATIONSHIP_SON) {
              obj['Code'] = data['relationId'];
              obj['Description'] = data['relationType'];
              dtArray.push(obj);
            }
          }
          sessionStorage.setItem('RelationType', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'ChildRelationType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            if (data['relationType'] === RELATIONSHIP_DEPENDENT_DAUGHTER || data['relationType'] === RELATIONSHIP_DEPENDENT_SON) {
              obj['Code'] = data['relationId'];
              obj['Description'] = data['relationType'];
              dtArray.push(obj);
            }
          }
          sessionStorage.setItem('ChildRelationType', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'pincode':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['pincode'];
            obj['Description'] = data['pincode_locality'];
            dtArray.push(obj);
          }
          return dtArray;
        }
      case 'CreateUserRole':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['roleId'];
            obj['Description'] = data['roleName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('CreateUserRole', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'Endorsement':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['endorsementTypeID'];
            obj['Description'] = data['endorsementType'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('Endorsement', JSON.stringify(dataArray));
          return dtArray;
        }

      case 'SelectAffinity':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['affinityId'];
            obj['Description'] = data['affinityName'];
            dtArray.push(obj);
          }
          dtArray.sort(function (a, b) {
            return String(a['Description'].toLowerCase()).localeCompare(String(b['Description'].toLowerCase()));
          });
          sessionStorage.setItem('SelectAffinity', JSON.stringify(dataArray));
          return dtArray;
        }

      case 'issuingState':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['stateId'];
            obj['Description'] = data['stateName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('issuingState', JSON.stringify(dataArray));
          return dtArray;
        }

      case 'OfficeLocation':
        {
          const dtArray: Array<Object> = new Array<Object>();
          const placeholder: Object = new Object();
          placeholder['Code'] = '';
          placeholder['Description'] = '';
          dtArray.push(placeholder);
          for (const data of dataArray) {
            if (String(data['stateName']).toLowerCase() !== String('Foreign State').toLowerCase()) {
              const obj: Object = new Object();
              obj['Code'] = data['stateId'];
              obj['Description'] = data['stateName'];
              dtArray.push(obj);
            }
          }
          sessionStorage.setItem('SelectAffinity', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'ProductMasterPolicy':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['masterPolicyID'];
            obj['Description'] = data['masterPolicyID'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('SelectAffinity', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'ProductType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['productTypeId'];
            obj['Description'] = data['productTypeName'];
            dtArray.push(obj);
          }
          return dtArray;
        }
      case 'SubProductType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['subProductTypeId'];
            obj['Description'] = data['subProductTypeName'];
            dtArray.push(obj);
          }
          return dtArray;
        }

        case 'ReportsProductType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['lobId'];
            obj['Description'] = data['lobDescription'];
            dtArray.push(obj);
          }
          dtArray.splice(0, 1);
          sessionStorage.setItem('ReportsProductType', JSON.stringify(dataArray));
          return dtArray;
        }

      case 'MasterPolicyTerm':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['masterPolicyTermId'];
            obj['Description'] = data['masterPolicyTerm'];
            dtArray.push(obj);
          }
          return dtArray;
        }
        case 'sahajDeclaration':
          {
            const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Description'] = data['declaration'];
            dtArray.push(obj);
          }
          return dtArray;
          }
          case 'selectProductType':
          {
            const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['lobId'];
            obj['Description'] = data['lobDescription'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('ReportsProductType', JSON.stringify(dataArray));
          return dtArray;
          }
          case 'districtList':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['districtId'];
            obj['Description'] = data['districtName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('issuingState', JSON.stringify(dataArray));
          return dtArray;
        }
    }
    return [];
  }

  set(value: any, description: any) {
    this.value = value;
    this.description = description;
    this.toggleDisplay();
  }
  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }
}

let identifier = 0;

class SelectObject {
  Description: string;
  Code: number;
}
