export const INVALID_CHILD: String = 'Child age should be less than Adult age';
export const INVAlID_DATE: String = 'Invalid date selection';
export const INVALID_CHILD_AGE: String = 'Child age should not be equal to Adult age';
export const QUESTION_ERROR_MESSAGE: String = 'If any of the Medical and Lifestyle related information is answered as Yes,' +
    ' then Proposal will require Company referral';
export const PINCODE_ERROR: String = 'Enter valid pincode';

/******** Login & Session **********/
export const WRONG_PASSWORD = 400;
export const NO_SUCH_USER = 501;
export const CONNECTION_FAILED = 0;
export const SERVER_ERROR = 500;
export const NOT_FOUND = 404;
export const ALREADY_LOGGEDIN = 503;
/**********************************/