export class UserLoginVO {
    userName: string;
    password: string;
    // Sahaj Parameters
    smId: String;
    smState: String;
    ipAddress: String;
    branchCode: String;
    crmLeadId: String;
    customerId: String;
    token:any;

}

