import { Injectable } from '@angular/core';

import { AppConfig } from 'src/config/app.config';
import { throwError } from 'rxjs';
import { e } from '@angular/core/src/render3';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HEADER, HEADER_PRINT, USER_PROFILE, HEADER_UM, USER_PROFILE_DATA, HEADER_USER, REQUEST_HEADER } from './../common/common.constants';
import { RequestOptions } from '@angular/http';
// import { Base64 } from 'js-base64';


@Injectable()
export class AuthenticationService {
    hostUrl: string = this.config.getConfig('productHost');
    documentDownloadUrl: string = this.config.getConfig('documentHost');
    loginHostUrl: string = this.config.getConfig('loginHost');
    constructor(private httpClient: HttpClient, private config: AppConfig) { }

    public authenticateUser(authData: any) {
        const loginRequestData = new Object();
        loginRequestData['userDetails'] = authData;
        const url = this.loginHostUrl + 'api/public/signin';
        return this.httpClient
            .post(url, JSON.stringify(loginRequestData), { headers: HEADER, observe: 'response' })
            .toPromise()
            .then((response: HttpResponse<any>) => response)
            .catch(this.handleError);
    }

    public authenticateExternalUser(token: any) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.loginHostUrl + 'api/user/transaction/identity';
        return this.httpClient
          .post(url, token, { headers: HEADER })
          .toPromise()
          .then((response: Response) => response)
          .catch(this.handleError);
    }

    public loadTokenDetails(authToken) {
        const requestOptions = new RequestOptions(REQUEST_HEADER);
        const url = this.loginHostUrl + 'api/user/transaction/identity';
        return this.httpClient
          .post(url, authToken, { headers: HEADER })
          .toPromise()
          .then((response: Response) => response)
          .catch(this.handleError);
      }
    public async isLoggedIn() {
        const url = this.hostUrl + 'api/public/token/validate';
        return this.httpClient
            .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    public async validateSession() {
        const url = this.hostUrl + 'api/user/' + JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userId'];
        return this.httpClient
            .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    public resetPassword(password: string, resetToken: Number) {
        const url = this.loginHostUrl + 'api/public/password/reset';
        const resetData = new Object();
        resetData['userDetails'] = new Object();
        resetData['userDetails']['userId'] = resetToken;
        resetData['userDetails']['password'] = password;
        const resetString = JSON.stringify(resetData);
        return this.httpClient
            .post(url, resetString, { headers: HEADER_UM, observe: 'response' })
            .toPromise()
            .then((response: HttpResponse<any>) => response)
            .catch(this.handleError);
    }

    public forgotPassword(emailId: string) {
        const url = this.loginHostUrl + 'api/public/password/forgot/';
        const emailObject = new Object();
        emailObject['userDetails'] = new Object();
        emailObject['userDetails']['emailAddress'] = emailId;
        return this.httpClient
            .post(url, emailObject, { headers: HEADER, observe: 'response' })
            .toPromise()
            .then((response: HttpResponse<any>) => response)
            .catch(this.handleError);
    }

    public forgotUsername(emailId: string) {
        const url = this.loginHostUrl + 'api/public/username/forgot/';
        const emailObject = new Object();
        emailObject['userDetails'] = new Object();
        emailObject['userDetails']['emailAddress'] = emailId;
        return this.httpClient
            .post(url, emailObject, { headers: HEADER, observe: 'response' })
            .toPromise()
            .then((response: HttpResponse<any>) => response)
            .catch(this.handleError);
    }

    public lockAccount(userName: string) {
        const url = this.loginHostUrl + 'api/public/account/lock/' + userName;
        const userLockObj = new Object();
        userLockObj['userName'] = userName;
        return this.httpClient
            .post(url, userLockObj, { headers: HEADER_UM, observe: 'response' })
            .toPromise()
            .then((response: HttpResponse<any>) => response)
            .catch(this.handleError);
    }

    public validatePasswordReset (userId: any) {
        const url = this.loginHostUrl + 'api/public/password/expiry/' + userId;
        return this.httpClient.get(url, {headers: HEADER_UM, observe: 'response'})
        .toPromise()
        .then((response: HttpResponse<any>) => response)
        .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {

        return Promise.reject(error);
    }
}
