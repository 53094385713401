import { Headers } from '@angular/http';
import { HttpHeaders } from '@angular/common/http';

export const MAIN_PAGE_PART_URL = '/welcome/(contentBody:';
export const META_URL = 'uimetadata/fetchmetadata';
export const LOGIN_URL = '/login';
export const LANDING: String = 'landing';
export const NEW_AFFINITY: String = 'newAffinity';
export const BENIFICIARY_SELF: String = 'Self';
export const BENIFICIARY_SPOUSE: String = 'Spouse';
export const GENDER_MALE: String = 'Male';
export const GENDER_FEMALE: String = 'Female';
export const RELATIONSHIP_DEPENDENT_DAUGHTER: String = 'Dependent Daughter';
export const RELATIONSHIP_DEPENDENT_SON: String = 'Dependent Son';
export const RELATIONSHIP_DAUGHTER: String = 'Daughter';
export const RELATIONSHIP_SON: String = 'Son';
export const RELATIONSHIP_OTHERS: String = 'Others';
export const PRODUCT_CODE: any = 'productcode';
export const PLAN_TYPE_DESC: any = 'planTypeName';
export const PLAN_TYPE_ID: any = 'planid';
export const POLICY_TYPE_DESC: any = 'policyTypeName';
export const SUMISURED: any = 'sumInsured';
export const SUMINSURED_ID = 'sumInsuredId';
export const POLICY_TYPE_ID: any = 'policyTypeId';
export const GENDER_ID = 'genderId';
export const GENDER_DESC = 'genderName';
export const RELATION_SHIP_ID: any = 'relationId';
export const RELATION_SHIP_DESC: any = 'relationType';
export const LABEL_GENDER: String = 'Gender';
export const LABEL_RELATIONSHIP: String = 'Relationship';
export const YEAR: String = 'YYYY';
export const DATE: String = 'DD';
export const MONTH: String = 'MM';
export const CODE: any = 'Code';
export const DESCRIPTION: any = 'Description';
export const AFFINITY_SELECTION: String = 'affinity';
export const CERTIFICATE_NO: String = 'certificateNo';
export const START_DATE: String = 'startDate';
export const END_DATE: String = 'endDate';
export const CUSTOMER_NAME: String = 'customerName';
export const QUOTE_NO: String = 'quoteNo';
export const DEFAULT_POLICY_TYPE = 'Individual';
export const BRANCH: String = 'branch';
export const  BRANCH_NAME: String = 'branchName';
export const BRANCH_CODE: String = 'branchCode';
/**user management */
export const USER_NAME: String = 'userName';
export const NAME: String = 'name';
export const EMAIL_ID: String = 'emailID';
export const LAST_ACTIVE_DATE: String = 'lastActiveDate';
export const ACCOUNT: String = 'account';
export const STATUS: String = 'status';
export const ENDORSEMENT_REASON_ID: any = 'endorsementTypeID';
export const ENDORSEMENT_REASON_DESC: any = 'endorsementType';
export const ISSUING_STATE = 'stateId';
export const ISSUING_STATE_DESCRIPTION = 'stateName';
export const USER_PROFILE = 'USER_PROFILE';
export const SHORT_PROD_NAME = 'shortProdName';
export const LOB_NAME:any = "lobName";
export const DROPDOWNS:any = "isDropDown";
export const LOB_HEALTH = 'Corporate Group Health';
export const LOB_HOME = 'Business Package For Home';
export const LOB_SHOP = 'Business Package For Shop Owners';
export const SAHAJ_AND_LIB_OCCUPANCY = 'Shop Of Non Hazardous Goods (Excluding Shop Of Mobile & Jewellery – Precious Items & Godown)';
export const SHOP_OCCUPANCY_TARIFF = 'Shops dealing in goods otherwise not provided for.';
export const SHOP_OCCUPANCY = 'Shop Of Non Hazardous Goods';
export const MEENACHIL_SHOP_OCCUPANCY = 'Shop';
export const HOME_OCCUPANCY_AND_TARIFF = 'Dwelling -Individual Risks';
export const MEENACHIL_HOME_OCCUPANCY = 'Individual Dwellings';
export const INTERACTIVE_TRADEX_SHOP_OCCUPANCY = 'Shop dealing with Non Hazardous Goods Cum Office premises / Meeting Rooms';
export const REQUEST_HEADER = {
    headers: new Headers({
        'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        , 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT'
    })
};
export const REQUEST_HEADER_UM = {
    headers: new Headers({
        'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*'
        , 'Access-Control-Allow-Methods': 'POST, GET, OPTIONS, DELETE, PUT'
        , 'dataType': 'text'
    })
};

/************ Session & Login ***************************************************/
export const HEADER: HttpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT')
    .set('Access-Control-Expose-Headers', 'jsontoken');

export const HEADER_UM: HttpHeaders = new HttpHeaders()
    // .set('Accept', 'application/json')
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT')
    .set('Access-Control-Expose-Headers', 'jsontoken');
    // .set('datatype', 'text');

export const HEADER_PRINT: HttpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT')
    .set('Access-Control-Expose-Headers', 'jsontoken');
    // .set('responseType', 'arraybuffer');

    export const HEADER_URL: HttpHeaders = new HttpHeaders()
    .set('Content-Type', 'text/plain')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT')
    .set('Access-Control-Expose-Headers', 'jsontoken');

    export const HEADER_USER: HttpHeaders = new HttpHeaders()
    .set('Content-Type', 'application/json')
    .set('Access-Control-Allow-Origin', '*')
    .set('Access-Control-Allow-Methods', 'POST, GET, OPTIONS, DELETE, PUT')
  //  .set('Access-Control-Expose-Headers', 'jsontoken');
    .set('Authorization', 'Bearer ' + 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzeXNhZG1pbiIsInJvbGVzIjoiU3lzdGVtIEFkbWluaXN0cmF0b3IiLCJpYXQiOjE1NzA3OTEwNDEsImV4cCI6MTU3MDg3NzQ0MX0.i1IDNuCB7Sh_HjhVDUYHTcT6oPwQI50qEL3Ld8YFad0')
    .set('authorizationToken', 'eyJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJzeXNhZG1pbiIsInJvbGVzIjoiU3lzdGVtIEFkbWluaXN0cmF0b3IiLCJpYXQiOjE1NzA3OTEwNDEsImV4cCI6MTU3MDg3NzQ0MX0.i1IDNuCB7Sh_HjhVDUYHTcT6oPwQI50qEL3Ld8YFad0');


export const USER_PROFILE_DATA = 'USER_PROFILE_DATA';
export const USER_AFFINITY = 'USER_AFFINITY';
export const BRANCH_CODE_INFO = 'BRANCH USER';
export const AFFINITY_NAME = 'name';
export const AFFINITY_ID = 'id';
export const LOGIN_DETAILS = 'LOGIN_DETAILS';
export const LOGIN_USERNAME = 'LOGIN_USERNAME';
export const UNSUCCESSFUL_LOGIN_COUNT = 'UNSUCCESSFUL_LOGIN_COUNT';
export const PREMIUM_ERROR_MSG = 'This impacts premium, Please check the date range';
/****************************************************************************/

export const EMAIL_EXPRESSION = /^(([A-Za-z0-9]+@{1}([A-Za-z0-9]+)(([.-][A-Za-z0-9]+)*)[\\.]{1}[A-Za-z]{2,})|([a-zA-Z0-9]+(([\._-][A-Za-z0-9]+)*)@{1}([A-Za-z0-9]+)(([.-][A-Za-z0-9]+)*)[\\.]{1}[A-Za-z]{2,}))$/;
export const SESSIONTIMEOUT_MINUTES=9;
