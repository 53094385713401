import { Component, OnInit, ViewChildren, ViewChild, ElementRef, Input, HostListener, AfterViewInit, OnChanges, ɵConsole } from '@angular/core';
import { EmitterService } from 'src/services/EmitterService';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DESCRIPTION, CODE } from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { ReportsVO } from './../dto/ReportsVO';
import { DatePipe } from '@angular/common';
import * as _moment from 'moment';
import { ReportsService } from '../services/reports.service';
import { UserManagementServices } from 'src/services/user-management.services';
import { ReportDetailsVO } from '../dto/ReportDetailsVO';
import { map } from 'rxjs/operators';
import { USER_AFFINITY, AFFINITY_ID, USER_PROFILE_DATA, END_DATE } from '../common/common.constants';
import { forEach } from '@angular/router/src/utils/collection';




const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-reportshealth',
  templateUrl: './reportshealth.component.html'
})
export class ReportsHealthComponent implements OnInit, AfterViewInit {
  public hrLeftMargin;
  public hrWidth;
  public isSubmit: Boolean = false;
  public enableSubmit: Boolean = false;
  public totalRows: any;
  public totalNumberOfRecords: Number;
  public count: any;
  public loadingmessage = '';
  public ReportTypeData: any;
  public ReportData = [];
  public ReportType: String;
  public policyNumber: any;
  public branchId: any;
  public branchName: String;
  public regionName: String;
  public branchNameId = [];
  public branchData: any;
  public branchDetails = [];
  public healthDetails = [];
  public productIdList: any;
  public productList: any;
  public roleId: string;
  public roleName: string;
  public userName: String;
  public reportDetails: ReportDetailsVO;
  public policyCount: Array<Object> = Array<Object>();
  public reportArray: Array<Object> = new Array<Object>();
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  public productArray: Array<Object> = new Array<Object>();
  public columnHeadings: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public populatArray: Array<Object> = Array<Object>();
  public lobDetails: Array<Object> = Array<Object>();
  public description: String = '--Select--';
  public productType: String;
  public productDateRangeMessage: any;
  public dateFlag: Boolean = false;
  public lobId: any;
  public max: any;
  public reportId: String;
  public reports: ReportsVO = new ReportsVO();
  public branchDetailsInfo: any;
  public dowanloadFlag: Boolean = false;
  public isSubAdminRole: any = 'N';


  @ViewChildren(TextBoxComponent) private textFields: any;
  @ViewChildren(SelectComponent) private selectFields: any;
  @ViewChildren(DatePickerComponent) private dateFields: any;
  @ViewChild('formWrapper') formWrapperRef: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);

  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  constructor(private reportsService: ReportsService,
    private userManagementServide: UserManagementServices,
    private datePipe: DatePipe) {

  }


  ngOnInit() {
    this.dowanloadFlag = false;
    this.roleId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleId'];
    this.roleName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
    this.userName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userName'];
    if (this.roleName.toLowerCase() === 'sub admin'){
      this.isSubAdminRole = 'Y';
    } 
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
    this.prepareActionColumns();
    this.reports = new ReportsVO();
    this.reports.shortProductName = 'Health';
    this.reportsService.getlobId().then(data => {
      this.lobDetails = data;
      for (const lobdetails of this.lobDetails) {
        if (this.reports.shortProductName === String(lobdetails['lobName'])) {
          this.lobId = lobdetails['lobId'];
          this.reportsService.getReportType(this.roleId, this.lobId).then(reportdata => {
            this.ReportTypeData = [];
            for (const report of reportdata) {
              const reportItem = new Object();
              reportItem['Code'] = report['reportId'];
              reportItem['Description'] = report['reportName'];
              this.ReportTypeData.push(reportItem);
            }
          }).catch(error => {
            this.loadingmessage = '';
          });
        }
      }
    }).catch(error => {
      this.loadingmessage = '';
    });
    const date = new Date;
    this.max = new Date(date.setDate(date.getDate()));
    const endDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
    this.reports.endDate = endDate;
   // this.reports.startDate=endDate;
    this.getPolicyDetails();
  }

  ngAfterViewInit() {
    this.onResize();
  }


  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  reportTypeArray() {
    const code1: Object = new Object();
    code1[CODE] = '1';
    code1[DESCRIPTION] = 'Daily Transaction';
    const code2: Object = new Object();
    code2[CODE] = '2';
    code2[DESCRIPTION] = 'Business Report';
    const code3: Object = new Object();
    code3[CODE] = '3';
    code3[DESCRIPTION] = 'Health Buzz';
    this.reportArray.push(code1);
    this.reportArray.push(code2);
    this.reportArray.push(code3);
  }

  productTypeArray() {
    const code1: Object = new Object();
    code1[CODE] = '1';
    code1[DESCRIPTION] = 'Corporate Group Health';
    const code2: Object = new Object();
    code2[CODE] = '2';
    code2[DESCRIPTION] = 'Business Package Policy for Home';
    const code3: Object = new Object();
    code3[CODE] = '3';
    code3[DESCRIPTION] = 'Business Package Policy for Shop';
    this.productArray.push(code1);
    this.productArray.push(code2);
    this.productArray.push(code3);
  }
  BusinessColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'Branch Name';
    this.columnHeadings.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'No. of Policies';
    this.columnHeadings.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Total Premium';
    this.columnHeadings.push(col3);
  }
  populateValues() {
    const obj: Object = new Object();
    obj['Branch'] = 'punnarpa';
    obj['No. of Policies'] = '14';
    obj['Total Premium'] = '12323.23';
    obj['Branch'] = 'punnarpa1';
    obj['No. of Policies'] = '141';
    obj['Total Premium'] = '12323.231';
    this.populatArray.push(obj);
  }
  DailyTransactionColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'No. of Policies';
    this.columnHeadings.push(col1);
  }

  HealthBuzzColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'No. of Policies';
    this.columnHeadings.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Total Premium';
    this.columnHeadings.push(col2);
  }

  public prepareActionColumns() {
    this.actionColumnsData.push('DOWNLOAD');
  }

  public getBranchId() {
    if ((this.branchName !== '' || this.branchName !== null || this.branchName !== undefined)) {
      this.reportsService.getBranchId(this.branchName).then(branchNameId => {
        this.branchNameId = branchNameId;
      })
        .catch(error => {
          this.loadingmessage = '';
        });
    } else if ((this.branchName === '' || this.branchName === null || this.branchName === undefined) && (this.regionName !== '' || this.regionName !== null || this.regionName !== undefined)) {
      this.reportsService.getRegionBranchId(this.regionName).then(regionNameId => {
        this.branchNameId = regionNameId;
      })
        .catch(error => {
          this.loadingmessage = '';
        });
    }
  }

  public downloadReport(event) {
    this.loadingmessage = 'Preparing Document...';
    if (String(this.reportId) === '2') {
      const isMISReport = false;
      if(this.branchName === '' || this.branchName === undefined){
        this.branchName = null;
       }
       if(this.regionName === '' || this.regionName === undefined){
         this.regionName = null;
       }
      this.reportsService.getDailyTransactionReport(this.reports.startDate, this.reports.endDate, this.reports.shortProductName, this.productIdList,
        isMISReport,this.isSubAdminRole,null,null)
        .then(data => {
          const parsedResponse = data.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'Daily Transaction Health Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'Daily Transaction Health Report.xlsx';
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            this.loadingmessage = '';
          }
        })
        .catch(error => {
          this.loadingmessage = '';
        });

    } else if (String(this.reportId) === '1') {
      const branchDetails = this.branchDetailsInfo;
      const obj = new Object();
      const branchInfo = new Object();
      if (branchDetails !== undefined) {
        branchInfo['branchCode'] = branchDetails['branchCode'];
        branchInfo['branchName'] = branchDetails['branchName'];
        branchInfo['branchRegion'] = branchDetails['branchRegion'];
        branchInfo['branchArea'] = branchDetails['branchArea'];
        obj[branchDetails['branchID']] = branchInfo;
      }
      if(this.branchName === '' || this.branchName === undefined) {
        this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined) {
        this.regionName = null;
      }
      this.reportsService.getBusinessReport(this.reports.startDate, this.reports.endDate, this.reports.shortProductName, obj, this.productIdList, null, null)
        .then(data => {
          const parsedResponse = data.blob();
          this.dowanloadFlag = true;
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'Business ' + this.reports.shortProductName + ' Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'Business ' + this.reports.shortProductName + ' Report.xlsx';
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            this.loadingmessage = '';
          }
        })
        .catch(error => {

        });

    } else if (String(this.reportId) === '3') {
      const branchList = [];
      const obj = new Object();
      branchList.push(obj);
      this.reportsService.getEndorsemenBuzzReport(this.reports.startDate, this.reports.endDate, this.reports.shortProductName, this.productIdList)
        .then(data => {
          const parsedResponse = data.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'Health Buzz Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'Health Buzz Report.xlsx';
            document.body.appendChild(a);
            a.click();

            document.body.removeChild(a);
            this.loadingmessage = '';
          }
        })
        .catch(error => {
        });

    }
  }
  public successmessage() {
    this.enableSubmit = false;
    this.reports.shortProductName = 'Health';
    this.isSubmit = true;
    this.dowanloadFlag = false;
    this.getPolicyDetails();
  }

  public getPolicyDetails() {
   // this.loadingmessage = 'Preparing Data...';
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    this.reportsService.getProductListForAffinity(affinity[AFFINITY_ID])
      .then(list => {
        this.productIdList = list;
      }).catch(error => {
        this.loadingmessage = '';
      });

    if (String(this.reportId) === '2') {
      this.loadingmessage = 'Preparing Data...';
      if(this.branchName === '' || this.branchName === undefined) {
        this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined) {
        this.regionName = null;
      }
      this.policyCount = [];
      const isMISReport = false;
      this.reportsService.getDailyTrasactionGridData(this.reports.startDate, this.reports.endDate, this.reports.shortProductName, this.productIdList, this.branchNameId,isMISReport,this.branchName,this.regionName).then(data => {
        const val: any = data;
        this.totalRows = 1;
        if (data !== 0) {
          this.prepareProjectData(data);
        } else {
          this.enableSubmit = true;
          this.loadingmessage = '';
        }
        this.moveToTop();
      }).catch(error => {
        this.loadingmessage = '';
      });
    } else if (String(this.reportId) === '1') {
      this.loadingmessage = 'Preparing Data...';
      this.policyCount = [];
      const rowDataValues = [];
      this.policyCount = [];
      this.reportsService.getBusinessGridData(this.reports.startDate, this.reports.endDate, this.reports.shortProductName, this.productIdList, this.branchNameId).then(branchDetails => {
        this.branchDetails = branchDetails;
        if (this.branchDetails.length !== 0) {
        for (const branchData of this.branchDetails) {
          const rowData = new Object();
          rowData['Total Premium'] = branchData['totalPremium'];
          rowData['No. of Policies'] = branchData['noOfPolicies'];
          this.branchId = branchData['branchId'];
          if (this.branchId !== 0) {
            this.reportsService.getBranchDetails(this.branchId).then(branch => {
              this.branchData = branch;
              const branchNameDetails = this.branchData;
              this.branchDetailsInfo = branch;
              rowData['Branch Name'] = branch['branchName'];
              rowData['Branch Data'] = branch;
              this.prepareProjectData(rowData);
              rowDataValues.push(rowData);
              this.totalRows = 1;
              this.loadingmessage = '';
              this.moveToTop();
            }).catch(error => {
              this.loadingmessage = '';
            });
          } else {
            this.loadingmessage = '';
            rowData['Branch Data'] = '';
            rowData['Branch Name'] = '';
            this.prepareProjectData(rowData);
            rowDataValues.push(rowData);
          }
        }
      } else {
        this.loadingmessage = '';
        this.enableSubmit = true;
      }
      }).catch(error => {
        this.loadingmessage = '';
      });
    } else if (String(this.reportId) === '3') {
      this.loadingmessage = 'Preparing Data...';
      this.policyCount = [];
      this.reportsService.EndorsementBuzzGridData(this.reports.startDate, this.reports.endDate, this.reports.shortProductName, this.productIdList, this.branchNameId).then(healthDetails => {
        this.healthDetails = healthDetails;
        if (this.healthDetails.length !== 0) {
        for (const healthData of this.healthDetails) {
          const rowData = new Object();
          rowData['Total Premium'] = healthData['totalPremium'];
          rowData['No. of Policies'] = healthData['noOfPolicies'];
          this.branchId = healthData['branchId'];
          this.prepareProjectData(rowData);
          this.totalRows = 1;
          this.loadingmessage = '';
          this.moveToTop();
        }
      } else {
        this.loadingmessage = '';
        this.enableSubmit = true;
      }

      }).catch(error => {
        this.loadingmessage = '';
      });
    }
  }

  public prepareProjectData(dailyTransactionData: any) {
    this.loadingmessage = '';
    this.enableSubmit = true;
    const data: Object = new Object();
    //   this.policyCount = [];
    if (String(this.reportId) === '2') {
      data['No. of Policies'] = dailyTransactionData;
    } else if (String(this.reportId) === '1') {
      data['No. of Policies'] = dailyTransactionData['No. of Policies'];
      data['Total Premium'] = dailyTransactionData['Total Premium'];
      data['Branch Name'] = dailyTransactionData['Branch Name'];
      data['Branch Data'] = dailyTransactionData['Branch Data'];
    } else if (String(this.reportId) === '3') {
      data['Total Premium'] = dailyTransactionData['Total Premium'];
      data['No. of Policies'] = dailyTransactionData['No. of Policies'];
    }
    this.policyCount.push(data);
    this.dowanloadFlag = true;
  }
  public getBranchName(branchName: any) {
    const data: Object = new Object();
    this.policyCount = [];
    if (String(this.reportId) === '1') {
      data['Branch'] = branchName;
    }
    this.policyCount.push(data);
  }


  public gridcolumns() {
    this.initialize();
    if (String(this.reportId) === '2') {
      this.DailyTransactionColDatas();
    } else if (String(this.reportId) === '1') {
      this.BusinessColDatas();
    } else if (String(this.reportId) === '3') {
      this.HealthBuzzColDatas();
    }
  }

  public initialize() {
    this.branchName = '';
    this.regionName = '';
    this.dateFlag = false;
    this.policyCount = [];
    this.reports.startDate = '';
    this.reports.endDate = '';
    this.enableSubmit = false;
    this.isSubmit = false;
  }
  public clearStartDate() {
    if (this.isSubmit === true) {
      this.branchName = '';
      this.regionName = '';
      this.dateFlag = false;
      this.policyCount = [];
      this.reports.endDate = '';
      this.enableSubmit = false;
      this.isSubmit = false;
      this.dowanloadFlag = false;
    }
  }

  public clearEndDate() {
    if (this.isSubmit === true) {
      this.branchName = '';
      this.regionName = '';
      this.dateFlag = false;
      this.policyCount = [];
      this.reports.startDate = '';
      this.enableSubmit = false;
      this.isSubmit = false;
      this.dowanloadFlag = false;
    }
  }

  private outSidevalidate() {
    let textResult: any = false;
    let selectResult: any = false;
    let dateResult: any = false;
    let hasTextField: Boolean = false;
    let hasSelectField: Boolean = false;
    let hasDateField: Boolean = false;
    let textFlag = true;
    let selectFlag = true;
    let dateFlag = true;
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        textFlag = false;
      }
    }
    for (const selectField of this.selectFields['_results']) {
      hasSelectField = true;
      const input: SelectComponent = selectField;
      selectResult = input.onBlurMethod(false);
      if (!selectResult) {
        selectFlag = false;
      }
    }
    for (const dateField of this.dateFields['_results']) {
      hasDateField = true;
      const input: DatePickerComponent = dateField;
      dateResult = input.onBlurMethod(false);
      if (!dateResult) {
        dateFlag = false;
      }
    }
    const result: any =
      (textFlag || !hasTextField) &&
      (selectFlag || !hasSelectField) &&
      (dateFlag || !hasDateField) &&
      this.dateFlag;
    if (result) {
      this.enableSubmit = true;
      return true;
    } else {
      this.enableSubmit = false;
      this.dowanloadFlag = false;
      return false;
    }
  }

  dateRangeValidation(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (startDate > endDate) {
        this.productDateRangeMessage = 'Start Date should be prior to End Date';
        this.dateFlag = false;
      } else {
        this.productDateRangeMessage = '';
        this.dateFlag = true;
      }
    } else {
      this.productDateRangeMessage = '';
    }
  }


  public productStartChange() {
    this.clearStartDate();
    const date = moment(this.reports.startDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.reports.startDate = date;
    } else {
      this.reports.startDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }

  }

  public productEndChange() {
    this.clearEndDate();
    const date = moment(this.reports.endDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.reports.endDate = date;
    } else {
      this.reports.endDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
  }

}

