import { Router, ActivatedRoute } from '@angular/router';
import { ViewChild } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { NavigationComponent } from '../navigation/navigation.component';
import { AppConfig } from '../config/app.config';
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.html',
})
export class WelcomeComponent implements OnInit {
  @ViewChild(NavigationComponent)
  private navigationComponent: NavigationComponent;

  constructor(private router: Router, private config: AppConfig,
    private route: ActivatedRoute) {
  }
  ngOnInit() {
  }

  refresh(value: boolean) {
    if (value) {
      this.navigationComponent.ngOnInit();
    }
  }
}
