import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'noComma'
})
export class NoCommaPipe implements PipeTransform {

    transform(val: any): string {
        if (val === undefined || val === null) {
            return '';
        }
        return val.toString().replace(',', '');
    }
}
