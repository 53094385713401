import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Injectable, OnInit, OnChanges } from '@angular/core';
import { AppConfig } from '../config/app.config';
import {
  REQUEST_HEADER, REQUEST_HEADER_UM, HEADER, USER_AFFINITY, USER_NAME, BRANCH_CODE, BRANCH,
  BRANCH_NAME, NAME, USER_PROFILE, HEADER_UM, USER_PROFILE_DATA

} from '../common/common.constants';
import { HttpParams, HttpClient } from '@angular/common/http';
@Injectable()
export class ProductServices {
  // hostUrl: string = this.config.getConfig('basehost');
  hostUrl: string = this.config.getConfig('productHost');
  constructor(private http: Http,
    private config: AppConfig,
    private httpClient: HttpClient
  ) {

  }



  public getPaymentItems() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = '/paymentItems.json';
    return this.httpClient.get(url, {headers: HEADER})
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public getPlaninfo() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = '/planInfo.json';
    return this.httpClient.get(url, {headers: HEADER})
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public listProducts(offset: any, size: any, idList: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/listproduct?offset=' + offset + '&size=' + size;
    const idListObject = new Object();
    idListObject['idList'] = idList;
    return this.httpClient.post(url, idListObject, { headers: HEADER })
      .toPromise().catch(this.handleError);
  }

  public singleUserlist(offset: any, size: any, idList: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/user/product/user/grid?offset=' + offset + '&size=' + size;
    // url = 'https://j3uli91jt3.execute-api.ap-south-1.amazonaws.com/Dev/api/user/product/user/grid?offset=0&size=10';
    const idListObject = new Object();
    idListObject['idList'] = idList;

    return this.httpClient.post(url, idListObject, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public branchList(offset: any, size: any) {
    const isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;

    url = this.hostUrl + 'api/user/product/branch/grid?offset=' + offset + '&size=' + size;
    // url = 'https://j3uli91jt3.execute-api.ap-south-1.amazonaws.com/Dev/api/user/product/user/grid?offset=0&size=10';
    return this.httpClient.post(url, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  public masterPolicy() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/master/policy';
    return this.httpClient.get(url, {headers: HEADER})
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public masterQuestions() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/master/questions';
    return this.httpClient.get(url, {headers: HEADER})
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public downloadTemplate(lobId: any) {

    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;

    let url: any;
    url = this.hostUrl + 'api/product/download?lobId=' + lobId;
    return this.http.get(url, options)
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public downloadCCWETemplate(productType:any) {

    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;

    let url: any;
    url = this.hostUrl + 'api/product/CCWETemplateDownload?productType=' + productType;
    return this.http.get(url, options)
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public uploadDocument(formData: FormData, effectiveDate: any, productType: any, subProductType: any, productId: any) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    let url: any;
    if (String(productType) === '1') {
      url = this.hostUrl + 'api/product/upload/ratesheet/health?rateSheetDate=' + effectiveDate;
    } else if (String(productType) === '2') {
      url = this.hostUrl + 'api/product/upload/ratesheet/business/' + productId + '?lob=' + String(subProductType).toLowerCase() + '&rateSheetDate=' + effectiveDate;
    }
    return this.http.post(url, formData, options)
      .toPromise().then((response: Response) => response)
      .catch(this.handleError);
  }

  public uploadCCWEDocument(formData: FormData, effectiveDate: any, productType: any, subProductType: any, productId: any) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    let url: any;
    if (String(productType) === '1') {
      url = this.hostUrl + 'api/product/upload/ratesheet/health?rateSheetDate=' + effectiveDate;
    } else if (String(productType) === '2') {
      url = this.hostUrl + 'api/product/upload/ratesheet/business/' + productId + '?lob=' + String(subProductType).toLowerCase() + '&rateSheetDate=' + effectiveDate;
    }
    return this.http.post(url, formData, options)
      .toPromise().catch(this.handleError);
  }

  public editProduct(productId: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/edit/' + productId;
    return this.httpClient.get(url, {headers: HEADER})
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }


  public saveProduct(product: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/save';
    return this.httpClient.post(url, product, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public updateProduct(product: any) {
    product['productDetails']['ccweSheetDocuments']=null;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/update';
    return this.httpClient.post(url, product, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public getProductListForAffinity(affinityId: string) {
    let url: any;
    url = this.hostUrl + 'api/admin/affinity/product/list/' + affinityId;
    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
  }

  public getListForAffinity(affinityId: string) {
    let url: any;
    url = this.hostUrl + 'api/admin/user/affinity/' + affinityId;

    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
  }

  public getProductDetailsForEdit(productId: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/edit/' + productId;
    return this.httpClient.get(url, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public userSearch(searchID, searchInput, count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let URL;
    if (searchID === USER_NAME) {
      URL = this.hostUrl + 'api/user/product/search/user/' + searchID + '~' + searchInput + '?' + '&offset=' + count + '&size=' + totalNumberOfRecords;
    } else if (searchID === NAME) {
      URL = this.hostUrl + 'api/user/product/search/user/' + searchID + '~' + searchInput + '?' + '&offset=' + count + '&size=' + totalNumberOfRecords;
    } else if (searchID === BRANCH_CODE) {
      URL = this.hostUrl + 'api/user/product/search/user/' + searchID + '~' + searchInput + '?' + '&offset=' + count + '&size=' + totalNumberOfRecords;
    }
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    const affinityUsersList = new Object();
    affinityUsersList['idList'] = [];

    return this.getListForAffinity(affinity['id']).then(data => {
      affinityUsersList['idList'] = data;
      return this.httpClient
        .post(
          URL, affinityUsersList, { headers: HEADER }
        )
        .toPromise()
        .then((response: Response) => response)
        .catch(this.handleError);
    })
      .catch(error => { });
  }

  public searchBranch(searchID, searchInput, count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let URL;
    if (searchID === BRANCH_NAME) {
      URL = this.hostUrl + 'api/user/product/search/branch/' + searchID + '~' + searchInput + '?' + '&offset=' + count + '&size=' + totalNumberOfRecords;
    } else if (searchID === BRANCH_CODE) {
      URL = this.hostUrl + 'api/user/product/search/branch/' + searchID + '~' + searchInput + '?' + '&offset=' + count + '&size=' + totalNumberOfRecords;
    }
    // const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    // const affinityUsersList = new Object();
    // affinityUsersList['idList'] = [];

    // return this.getListForAffinity(affinity['id']).then(data => {
    //   affinityUsersList['idList'] = data;
    return this.httpClient
      .post(
        URL, { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getUserProducts() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url: any;
    url = this.hostUrl + 'api/product/list';

    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    const affinityUsersList = new Object();
    affinityUsersList['idList'] = [];

    return this.getProductListForAffinity(affinity['id']).then(data => {
      affinityUsersList['idList'] = data;
      return this.httpClient
        .post(
          url, affinityUsersList, { headers: HEADER }
        )
        .toPromise()
        .then((response: Response) => response)
        .catch(this.handleError);
    })
      .catch(error => { });
  }

  public saveSingleUser(singleuser: object) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/user/product/save';

    const payloadJSON = JSON.stringify(singleuser);
    return this.httpClient
      .post(url, payloadJSON, { headers: HEADER })
      .toPromise()
      .catch(this.handleError);
  }


  public getIdList(branchId: string) {
    let url: any;
    url = this.hostUrl + 'api/user/branch/product' + branchId;

    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
  }

  public saveBranchUser(branchuser: object) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/branch/product/save';

    const payloadJSON = JSON.stringify(branchuser);
    return this.httpClient
      .post(url, payloadJSON, { headers: HEADER })
      .toPromise()
      .catch(this.handleError);
  }

  public getSavedProducts(userID: String, affinityID: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/user/product/' + userID + '?' + 'affinityID=' + affinityID;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  public getSavedBranchProducts(branchID: String, affinityID: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/branch/product/' + branchID + '?' + 'affinityID=' + affinityID;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getUserIdlist(branchID: String) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/user/branch/product/' + branchID;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }



  public saveBranchUserData(branchProducts: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/branch/product/save/user ';

    const payloadJSON = JSON.stringify(branchProducts);
    return this.httpClient
      .post(url, payloadJSON, { headers: HEADER })
      .toPromise()
      .catch(this.handleError);
  }



  public mapProductToAffinity(productId: any, affinityId: any) {
    let url: any;
    const productDetails = new Object();
    productDetails['productId'] = productId;
    productDetails['affinityId'] = affinityId;
    url = this.hostUrl + 'api/admin/affinity/product/save';
    return this.httpClient.post(url, productDetails, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public getProductList() {
    let url: any;

    url = this.hostUrl + 'api/admin/affinity/product/list/' + JSON.parse(sessionStorage.getItem(USER_AFFINITY))['id'];
    return this.httpClient.get(url, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public productExists(idList: any, productName: any) {
    let url: any;
    url = this.hostUrl + 'api/product/affinity/avail/' + productName;
    const idListObject = new Object();
    idListObject['idList'] = idList;
    return this.httpClient.post(url, idListObject, { headers: HEADER_UM })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public getProducts() {
    let url: any;
    const userData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    url = this.hostUrl + 'api/admin/user/product/menu/' + userData['userId'];
    return this.httpClient.get(url, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public getProductNames(idList: any) {
    let url: any;
    const reqObj = new Object();
    reqObj['idList'] = reqObj;
    url = this.hostUrl + 'api/product/retrieve/product/name';
    return this.httpClient.post(url, reqObj, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public async getProductNames2() {

    let url1: any;
    const userData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    // TO-DO
    url1 = this.hostUrl + 'api/admin/user/product/menu/' + userData['userId'];
    // url1 = this.hostUrl + 'api/admin/user/product/menu/' + 554;
    const idList = await this.httpClient.get(url1, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);

      if (idList.length === 0) {
          return ['No Products Mapped'];
      } else if (idList.length === 1 && String(idList[0]) === '0') {
        return ['Products Manually Unmapped'];
      }


    let url2: any;
    const reqObj = new Object();
    reqObj['idList'] = idList;
    url2 = this.hostUrl + 'api/product/retrieve/product/name';
    return this.httpClient.post(url2, reqObj, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public deleteAdditionalDocument(documentId: any) {
    let url: any;
    url = this.hostUrl + 'api/product/delete/document/' + documentId;
    return this.httpClient.post(url, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public getproductDocuments(offset: any, size: any, productId: Number) {
    let url: any;
    url = this.hostUrl + 'api/product/document/list?offset=' + offset + '&size=' + size;
    const requestBody = new Object();
    requestBody['productDetails'] = new Object();
    requestBody['productDetails']['productId'] = productId;
    return this.httpClient.post(url, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public uploadAdditionalDocuments(filesData: FormData) {
    let url: any;
    url = this.hostUrl + 'api/product/documents/save';

    const headers = new Headers({
      'Accept': 'application/json',
    });
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;

    return this.http.post(url, filesData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getUserPlanList(userId: any, productID: any) {
    let url;
    url = this.hostUrl + 'api/admin/user/product/plan';
    const requestObject = new Object();
    requestObject['userProductResource'] = [];
    const resourceObject = new Object();
    resourceObject['userId'] = userId;
    resourceObject['productID'] = productID;
    requestObject['userProductResource'].push(resourceObject);
    return this.httpClient.post(url, requestObject, {headers: HEADER})
    .toPromise().then(response => response).catch(this.handleError);
  }

  public getUserPlans(planNameList: any, policyTypeId: any) {
    let url ;
    url = this.hostUrl + 'api/policy/plans/select/' + policyTypeId;
    const requestObject = new Object();
    requestObject['planNameList'] = planNameList;
    return this.httpClient.post(url, requestObject, {headers: HEADER}).toPromise()
    .then(response => response).catch(this.handleError);
  }

  public uploadProductDocumentsInS3(affinityId, produtId, formData: FormData) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/product/documents/save/' + affinityId + '?' + 'productId=' + produtId;
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public uploadProductRateSheetInS3(affinityId, produtId, formData: FormData) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/product/documents/ratesheet/save/' + affinityId + '?' + 'productId=' + produtId;
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  public uploadCCWESheetInS3(affinityId, produtId, formData: FormData) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/product/uploadCCWESheetDocuments/' + affinityId +"/"+ produtId;
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public saveCCWEFileData(affinityId, shortProductName, formData: FormData,ccweSheetDate) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/document/saveCCWEFileData/' + affinityId +"/"+ shortProductName+'?' + 'ccweSheetDate=' + ccweSheetDate;;
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public saveCCWESheetList(ccweRequestWrapper:any) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/product/saveCCWESheetList';
    return this.http
      .post(url, ccweRequestWrapper, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public validateCCWEFileData(affinityId, shortProductName, formData: FormData,ccweSheetDate) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/document/validateCCWEFileData/' + affinityId +"/"+ shortProductName+'?' + 'ccweSheetDate=' + ccweSheetDate;;
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
