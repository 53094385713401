export class CustomerDetailsVO {
    public title: any;
    public firstName: String;
    public lastName: String;
    public employeeId: String;
    public customerId: String;
    public isActive: Boolean = true;
    public customerDetailId: any;
    public customerName: String = '';
    public employeeName: String;
}
