import { OnChanges, OnInit, EventEmitter, Output, AfterViewChecked, ɵConsole } from '@angular/core';
import { Component, Optional, Inject, Input, ViewChild, ElementRef } from '@angular/core';
import { ElementBase } from '../accessor/element.base';
import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ProposalServices } from './../services/proposal.services';
import { EmitterService } from 'src/services/EmitterService';
import { ActivatedRoute } from '@angular/router';
import { PINCODE_ERROR } from './../common/error.constants';
import { USER_PROFILE_DATA } from 'src/common/common.constants';

@Component({
  selector: 'app-textbox',
  templateUrl: './text-box.html',
  providers: [
    { provide: NG_VALUE_ACCESSOR, useExisting: TextBoxComponent, multi: true }
  ]
})
export class TextBoxComponent extends ElementBase<string>
  implements OnChanges, OnInit {
  @Input()
  public label: any;

  @Input()
  public id: any;

  @Input()
  public isautocomplete: any;
  @Input()
  public isautocomplete2: any;

  @Input()
  public type: String = 'text';

  @Input()
  public readonly: String;

  @Input()
  public columnformat: string;

  @Input()
  public maxLength: number;
  @Input()
  public minLength: number;

  @Input()
  public required: boolean;

  @ViewChild(NgModel)
  model: NgModel;
  @Input()
  public validationMessage: String;

  @Input() public validationMessage2: any;

  @Input()
  public lookupname: String;

  @Input()
  public pattern: string;
  @Input()
  public lastnamedisable: Boolean = false;

  @Input()
  public List: any;

  @Input()
  public keyUp: any;

  @Input() public labelHidden: String;

  @Output() public changeevent = new EventEmitter<Object>();

  @Output() public autoCompleteEvent = new EventEmitter<Object>();

  @Output() public inputevent = new EventEmitter<Object>();

  @Input() public DataArray: Array<Object>;

  @Input()
  public dataList: any;

  @Input() public placeholders: String;

  @Input() public isautosearch: any;

  @Input()
  public defaultValue: any;

  @Input()
  public notDynamic = false;

  @Input()
  public hideValidationMessages = false;


  @Input()
  public disableOnRenewal = false;
  public isRenewal = false;
  public isEndorsement = false;
  @Input()
  public disableOnEndorsement = false;

  @Input() public disable: Boolean = false;
  @Input() public subText: any;

  @Input() public disableonMs: Boolean;

  @Input() public NewvalidationMessage: Boolean;

  @Input() public apiValidationMessage: String;

  @Input() public dropDownDataId: string;

  @Input() public autocomplete: string;
  public std: any;
  public phoneNumber: any;
  public isImuthootAffinity: boolean;
  private activeIndex: number = 0;
  @ViewChild('scrl') myElem: ElementRef;

  constructor(
    @Optional()
    @Inject(NG_VALIDATORS)
    validators: Array<any>,
    @Optional()
    @Inject(NG_ASYNC_VALIDATORS)
    asyncValidators: Array<any>,
    private proposalServices: ProposalServices,
    private route: ActivatedRoute,
    private elementRef: ElementRef
  ) {
    super(validators, asyncValidators);
  }
  ngOnChanges(changes: any) {
    if (this.defaultValue && !this.value) {
      this.value = this.defaultValue;
    }
  }
  ngOnInit() {
    if (this.defaultValue) {
      this.value = this.defaultValue;
    }
    EmitterService.get('SHOW_VALIDATION_MESSAGE').subscribe(data => {
      if (this.value) {
        this.onBlurMethod(true);
      }
    });
    EmitterService.get('HIDE_VALIDATION_MESSAGE').subscribe(data => {
      this.validationMessage = '';
      this.validationMessage2 = '';
      this.apiValidationMessage = '';
    });

  }


  public validationCall() {
    if (!this.notDynamic) {
      this.onBlurMethod(true);
    } else {
      if (this.onBlurMethod(false)) {
        this.onBlurMethod(true);
      }
    }
    EmitterService.get('VALIDATE').emit();
  }


  public onBlurMethod(showError: any): boolean {
    if (showError) {
      this.validationMessage = '';
    }
    if (
      ((this.required && this.required[0] === true) ||
        this.required === true) &&
      (this.value === undefined || this.value === null || (!this.value && String(this.value) !== '0') || this.value === '')
    ) {
      if (this.label === 'Mobile') {
        if (showError) {
          this.validationMessage = (this.label + ' Number is required');
        }
      } else if (this.label === 'State' || this.label === 'District' || this.label === 'City' || this.label === 'Employee Name') {
        this.validationMessage = '';
      } else if (showError) {
        this.validationMessage = (this.label + ' is required');
      }
      return false;
    } else if (this.value && this.columnformat && this.value.match) {
      if (this.value.match(this.columnformat) == null) {
        const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
        if (userdetails['isMuthootCustomer'] === true) {
          this.isImuthootAffinity = true;
        }
        if (this.label === 'Mobile' || this.label === 'Mobile (optional)') {
          if (showError) {
            if (this.label === 'Mobile') {
              this.validationMessage = ('Enter Valid ' + this.label + ' Number');
            } else {
              this.validationMessage = 'Enter Valid Mobile Number (optional)';
            }
          }
        } else if (showError) {
          this.validationMessage = ('Enter Valid ' + this.label);
          // else if condition added for muthoot api load values
        }
        else if (!showError && this.isImuthootAffinity) {
          this.validationMessage = ('Enter Valid ' + this.label);
        }
        return false;
      }

    }

    if (this.value && this.minLength) {
      if (this.value.length < this.minLength) {
        if (this.label === 'Mobile' || this.label === 'Mobile (optional)') {
          if (showError) {
            if (this.label === 'Mobile') {
              this.validationMessage = ('Enter Valid ' + this.label + ' Number');
            } else {
              this.validationMessage = 'Enter Valid Mobile Number (optional)';
            }
          }
        } else if (this.label === 'OTP' && this.value.length === 6) {
          this.validationMessage = '';
        }
        else if (showError) {
          this.validationMessage = ('Enter Valid ' + this.label);
        }
        return false;
      }
    } if (this.value && this.pattern) {
      if (this.value.match(this.pattern)) {
        return true;
      } else {
        if (showError) {
          this.validationMessage = ('Enter Valid ' + this.label);
        }
        return false;
      }
    }
    this.changeevent.emit(this.value);
    return true;
  }

  public onTyping(data: any) {

    if (this.label === 'Mobile' || this.label === 'Mobile (optional)') {
      this.validationMessage = '';
      if (data[0] === '0') {
        if (this.label === 'Mobile') {
          this.validationMessage = ('Enter Valid ' + this.label + ' Number');
        } else {
          this.validationMessage = 'Enter Valid Mobile Number (optional)';
        }
      }
      if (data.length <= 10) {
        const thenum1: String = data.match(/\D/g);
        if (thenum1 !== null && thenum1.length >= 1) {
          if (this.label === 'Mobile') {
            this.validationMessage = ('Enter Valid ' + this.label + ' Number');
          } else {
            this.validationMessage = 'Enter Valid Mobile Number (optional)';
          }
        } else {
          this.validationMessage = '';
        }
      }
    } else if (this.label === 'Phone No.(optional)') {
      this.validationMessage = '';
      this.std = data.split('-')[0];
      this.phoneNumber = data.split('-')[1];
      const phoneNumberOpt: String = data.match(/[^0-9\-]/g);
      if (phoneNumberOpt.length >= 1) {
        this.validationMessage = 'Enter Valid ' + this.label;
      }
      const notStdNumber: any = this.std.match(/\D/g);
      const notPhoneNumber: any = this.phoneNumber.match(/\D/g);
      if (notStdNumber.length >= 1 || notPhoneNumber.length >= 1) {
        this.validationMessage = 'Enter Valid ' + this.label;
      } else {
        this.validationMessage = '';
      }

    } else if (this.label === 'Email ID') {
      this.validationMessage = '';
      const notValidEmail: String = data.match(/[^0-9a-zA-Z\._@\-]/g);
      if (notValidEmail && notValidEmail.length >= 1) {
        this.validationMessage = 'Enter Valid ' + this.label;
      } else {
        this.validationMessage = '';
      }
    } else if (this.label === 'Employee Code' || this.label === 'Employee Code(optional)' ||
      this.label === 'Customer ID' || this.label === 'Employee ID' || this.label === 'GSTIN No.(optional)' || this.label === 'Branch Code') {
      this.validationMessage = '';
      const notEmployeeCode: String = data.match(/[^0-9a-zA-Z]/g);
      if (notEmployeeCode && notEmployeeCode.length >= 1) {
        this.validationMessage = 'Enter Valid ' + this.label;
      } else {
        this.validationMessage = '';
      }
    } else if (this.label === 'Wallet Limit (₹) (optional)' || this.label === 'Minimum Wallet Limit (₹) (optional)') {
      this.validationMessage = '';
      const notwallet: String = data.match(/[^0-9\.]/g);
      if (notwallet !== null && notwallet !== undefined) {
        if (notwallet.length >= 1) {
          this.validationMessage = 'Enter Valid ' + this.label;
        } else {
          this.validationMessage = '';
        }
      }
    }
  }

  public onBlurOutSideMethod(showError: any): boolean {
    if (showError) {
      this.validationMessage = '';
    }
    if (
      ((this.required && this.required[0] === true) ||
        this.required === true) &&
      (this.value === undefined || this.value === null || (!this.value && String(this.value) !== '0') || this.value === '')
    ) {
      if (showError) {
        this.validationMessage = (this.label + ' is required');
        return false;
      }
    } else if (this.value && this.columnformat) {
      if (this.value.match(this.columnformat) == null) {
        if (showError) {
          this.validationMessage = ('Enter Valid ' + this.label);
        }
        return false;
      }
    } if (this.value && this.minLength) {
      if (this.value.length < this.minLength) {
        if (showError) {
          this.validationMessage = ('Enter Valid ' + this.label);
        }
        return false;
      }
    }
    this.changeevent.emit(this.value);
    return true;
  }

  public onInput(data: any) {
    this.dataList = [];
    if (!data) {
      this.autoCompleteEvent.emit([]);
    }
    if (data.length >= 0 && data.length <= 2) {
      const notnumber: String = data.match(/\D/g);
      if (null !== notnumber && notnumber.length > 0) {
        this.validationMessage = PINCODE_ERROR;
      } else {
        this.validationMessage = '';
      }
      this.autoCompleteEvent.emit([]);
    }
    if (data.length > 2 && data.length <= 6) {
      this.validationMessage = '';
      if (this.label === 'Pincode') {
        this.proposalServices.getPincode(this.value).then(pindata => {
          this.List = pindata;
          this.dataList = pindata;
          if (this.dataList.length === 0 && this.value.length <= 6) {
            this.validationMessage = PINCODE_ERROR;
          }
          this.autoCompleteEvent.emit(this.dataList);
        })
          .catch(error => {
            this.validationMessage = PINCODE_ERROR;
            this.autoCompleteEvent.emit([]);
          });
      }
    } else if (data.length > 6) {
      this.validationMessage = '';
      this.validationMessage = '';
      const pin = this.value.split('-');
      if (pin[0].includes('#')) {
        this.validationMessage = PINCODE_ERROR;
      } else {
        this.proposalServices.getPincode(pin[0]).then(pindata => {
          this.List = pindata;
          this.dataList = pindata;
          if (this.dataList.length === 0 || this.value.length <= 6) {
            this.validationMessage = PINCODE_ERROR;
          } else if (this.value === null) {
            this.validationMessage = PINCODE_ERROR;
          }
          this.autoCompleteEvent.emit(this.dataList);
        })
          .catch(error => {
            this.validationMessage = PINCODE_ERROR;
            this.autoCompleteEvent.emit([]);
          });
        const match: any = pin[1].match(/^[a-zA-Z0-9()\.,\- ]*$/);
        if (String(pin[1]) !== String(match)) {
          //this.validationMessage = PINCODE_ERROR;
        }
      }
    }
  }

  public pincodeMessage(data: any) {
    if (!data || data.length === 0) {
      this.validationMessage = 'Pincode is required';
      this.autoCompleteEvent.emit([]);
    }
    if (data.length > 6) {
      this.validationMessage = '';
      this.validationMessage = '';
      const pin = this.value.split('-');
      if (pin[0].includes('#')) {
        this.validationMessage = PINCODE_ERROR;
      } else {
        this.proposalServices.getPincode(pin[0]).then(pindata => {
          this.List = pindata;
          this.dataList = pindata;
          if (this.dataList.length === 0 || this.value.length <= 6) {
            this.validationMessage = PINCODE_ERROR;
          } else if (this.value === null) {
            this.validationMessage = PINCODE_ERROR;
          }
          this.autoCompleteEvent.emit(this.dataList);
        })
          .catch(error => {
            this.validationMessage = PINCODE_ERROR;
            this.autoCompleteEvent.emit([]);
          });
        const match: any = pin[1].match(/^[a-zA-Z0-9()\.,\- ]*$/);
        if (String(pin[1]) !== String(match)) {
          this.validationMessage = PINCODE_ERROR;
        }
      }
    }
  }

  public set(code: any, description: any) {
    this.value = description;
    if (this.isautocomplete2) {
      this.onInput(this.value);
    }
  }

  public clearDataList() {
    this.dataList = [];
  }

  // public populateDataList() {
  //   this.onInput(this.value);
  // }


  // onKeydown(event: any) {
  //   if (event instanceof KeyboardEvent) {
  //     if (this.label.match(/Pincode/i)) {
  //       if ((event.keyCode === 8 || event.keyCode === 46)) {
  //         if (this.value.length > 6) {
  //           if (event.keyCode === 8) {
  //              this.value = this.value.substr(0, 7);
  //           } else if (event.keyCode === 46) {
  //              this.value = this.value.substr(0, 6);
  //              EmitterService.get('GET_PINCODE_DETAILS').emit(this.value);
  //              this.onInput(this.value);
  //            }
  //          }

  //        } else if (this.value.length >= 6)  {
  //          if (!((event.ctrlKey && event.keyCode === 67) ||
  //            (event.ctrlKey && event.keyCode === 86) ||
  //            (event.ctrlKey && event.keyCode === 88) ||
  //            (event.shiftKey && event.keyCode === 37) ||
  //            (event.shiftKey && event.keyCode === 39) ||
  //            (event.shiftKey && event.keyCode === 65) ||
  //            (event.keyCode === 39) ||
  //            (event.keyCode === 37)
  //          )) {
  //            event.preventDefault();
  //          }
  //        }

  //      }
  //    }
  //  }


  // onPaste(event: ClipboardEvent) {
  //   if (this.label.match(/pincode/i)) {
  //     const clipboardData = event.clipboardData;
  //     const pastedText = clipboardData.getData('text');
  //     if (pastedText.match(/[^0-9]/)) {
  //       event.preventDefault();
  //     }
  //   }
  // }

  previousValue() {
    this.activeIndex = this.activeIndex > 0 ? --this.activeIndex : 0;
      // window.scrollBy(0,window.scrollY+40);
      // console.log(window.scrollY, this.activeIndex);
      
    this.value = this.dataList[this.activeIndex - 1].Description;

  }

  nextValue() {
    this.activeIndex = this.activeIndex < this.dataList.length - 1 ? ++this.activeIndex : this.activeIndex;
    // window.scrollBy(0,window.scrollY-40);
    // console.log(window.scrollY, this.activeIndex);
    this.value = this.dataList[this.activeIndex - 1].Description;

  }

  onKeydown(event) {
    if (event.code === "ArrowUp") {
      return this.previousValue();
    }
    if (event.code === "ArrowDown") {
      return this.nextValue();
    }
  }

  scrolling(event){
   let scrl = event.target.scrollingElement.scrollBottom;
   console.log(scrl,'ss');
   console.log(event);
   
   
  }

}

