import { ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { MAIN_PAGE_PART_URL, LANDING, NEW_AFFINITY, AFFINITY_NAME, AFFINITY_ID, USER_AFFINITY, USER_PROFILE, USER_PROFILE_DATA } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { AffinityVO } from 'src/dto/AffinityVO';
import { CODE, DESCRIPTION } from 'src/common/common.constants';
import { ProposalServices } from 'src/services/proposal.services';
import { SelectComponent } from 'src/select/select-component';
import { UserManagementServices } from 'src/services/user-management.services';
import { SelectService } from 'src/services/select.services';

@Component({
  selector: 'app-affinity',
  templateUrl: './affinity.html',

})
export class AffinityComponent implements OnInit {
  @ViewChild('selectAffinity') selectAffinity: SelectComponent;
  @ViewChild('searchInput') searchInput: ElementRef;
  public loading: Boolean = true;
  public selectedAffinity: any;
  public createNewAffinity = false;
  public isupdate = false;
  public affinity: AffinityVO = new AffinityVO();
  public affinityNameArray: Array<Object> = new Array<Object>();
  public selectedAffinityName: any;
  public isDefault = false;
  public currentRole: string;
  public display = 'none';
  public description = 'Select Affinity';
  public affinityArray: any;
  public defaultAffinityId: any;
  public searchKey: any;
  public defaultAffinityID: any;
  constructor(private proposalServices: ProposalServices,
    private router: Router,
    private route: ActivatedRoute,
    private userManagementService: UserManagementServices,
    private selectService: SelectService) {

  }
  ngOnInit() {
    this.getAffinityArray();
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    this.userManagementService.getUserAffinity(profileData['userId']).then(userAffinityData => {
      if (userAffinityData['defaultAffinityID'] !== 0 && userAffinityData['defaultAffinityID'] !== undefined && userAffinityData['defaultAffinityID'] !== null) {
        this.defaultAffinityId = userAffinityData['defaultAffinityID'];
      }
    });
    this.createNewAffinity = false;
    const loggedInselectedAffinity: any = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    if (loggedInselectedAffinity) {
      // this.createNewAffinity = true;
      this.isupdate = true;
    }
    sessionStorage.setItem(USER_AFFINITY, '');
    this.currentRole = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
  }

  public searchFocusFunction() {
    const _this = this;
    setTimeout(function () {
      _this.display = 'flex';
      setTimeout(function () {
        _this.searchInput.nativeElement.focus();
      }.bind(_this));
    }.bind(_this));
  }
  public searchKeyChange() {
    const affinityArrayCopy = JSON.parse(sessionStorage.getItem('SelectAffinity'));
    if (!this.searchKey) {
      this.affinityArray = affinityArrayCopy;
    } else {
      const searchResultArray = new Array<Object>();
      for (const affinity of affinityArrayCopy) {
        if (String(affinity['Description']).toLowerCase().match(String(this.searchKey).toLowerCase())) {
          searchResultArray.push(affinity);
        }
      }
      this.affinityArray = searchResultArray;
    }
  }

  public getAffinityArray() {
    this.selectService.fetchLookUpData('SelectAffinity', null).then(dataArray => {
      this.affinityArray = new Array<Object>();
      for (const data of dataArray) {
        const obj: Object = new Object();
        obj['Code'] = data['affinityId'];
        obj['Description'] = data['affinityName'];
        obj['State'] = data['issueStateDescription'];
        obj['StateId'] = data['issueStateId'];
        obj['agentId'] = data['agentId'];
        obj['affinityLogo'] = data['affinityLogo'];
        obj['agentName'] = data['agentName'];
        obj['agentContactNumber'] = data['agentContactNumber'];
        obj['encodedAffinityId'] = data['encodedAffinityId'];
        this.affinityArray.push(obj);
      }
      this.affinityArray.sort(function (a, b) {
        return String(a['Description'].toLowerCase()).localeCompare(String(b['Description'].toLowerCase()));
      });
      sessionStorage.setItem('SelectAffinity', JSON.stringify(this.affinityArray));
    });
  }

  public setAffinity() {
   
    const affinityArray = JSON.parse(JSON.stringify(this.affinityArray));
    const affinity = new Object();
    for (let i = 0; i < affinityArray.length; i++) {
      if (String(this.selectedAffinity) === String(affinityArray[i][CODE])) {
        affinity['name'] = affinityArray[i][DESCRIPTION];
        affinity['issueState'] = affinityArray[i]['StateId'];
        affinity['issuestateDesc'] = affinityArray[i]['State'];
        affinity['agentId'] = affinityArray[i]['agentId'];
        affinity['affinityLogo'] = affinityArray[i]['affinityLogo'];
        affinity['agentName'] =affinityArray[i]['agentName'];
        affinity['agentContactNumber'] =affinityArray[i]['agentContactNumber'];
        affinity['encodedAffinityId'] =affinityArray[i]['encodedAffinityId'];
        break;
      }
    }
    affinity['id'] = this.selectedAffinity;
    const affinityString = JSON.stringify(affinity);
    sessionStorage.setItem('USER_AFFINITY', affinityString);
    setTimeout(() => EmitterService.get('AFFINITY_SELECT_EVENT').emit(true), 500);

    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    // let userProfile;
    // let affinityID;
    // let userAffinity;
    // new Promise((resolve, reject) => {
    //   this.userManagementService.getUserAffinity(profileData['userId']).then(userAffinityData => {

    //     userProfile = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    //     affinityID = userAffinityData ['affinityID'];
    //     userAffinity = userAffinityData;
    //   });
    // }).then( userAffinityData1 => {
    //     if (this.isDefault) {
    //       this.userManagementService.updateMappedAffinity(userProfile['userId'], affinityID, this.selectedAffinity , userAffinity['userAffinityMappingID']).then(data => {
    //         console.log('User affinity updated successfully!');
    //       }).catch(error => {
    //         console.log('Failed to update affinity!');
    //       });
    //     } else {
    //     //  const userProfile = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    //     //  if (String(userAffinityData['affinityID']) === String(this.selectedAffinity)) {
    //       const defaultAffinityID = 0;
    //         this.userManagementService.updateMappedAffinity(userProfile['userId'], affinityID, defaultAffinityID, userAffinity['userAffinityMappingID']).then(data => {
    //           console.log('User affinity updated successfully!');
    //         }).catch(error => {
    //           console.log('Failed to update affinity!');
    //         });
    //    //   }
    //     }
    // }).then(data2 => {

    // });
     this.userManagementService.getUserAffinity(profileData['userId']).then(userAffinityData => {
       const userProfile = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
       const affinityID = userAffinityData ['affinityID'];
       if (this.isDefault) {
         this.userManagementService.updateMappedAffinity(userProfile['userId'], affinityID, this.selectedAffinity , userAffinityData['userAffinityMappingID']).then(data => {
           console.log('User affinity updated successfully!');
         }).catch(error => {
           console.log('Failed to update affinity!');
         });
       } else {
    //   //  const userProfile = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    //   //  if (String(userAffinityData['affinityID']) === String(this.selectedAffinity)) {
         const defaultAffinityID = 0;
           this.userManagementService.updateMappedAffinity(userProfile['userId'], affinityID, defaultAffinityID, userAffinityData['userAffinityMappingID']).then(data => {
             console.log('User affinity updated successfully!');
           }).catch(error => {
             console.log('Failed to update affinity!');
           });
      //   }
       }
     });
  }

  public onAffinityChange() {
    this.isDefault = false;
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    this.userManagementService.getUserAffinity(profileData['userId']).then(userAffinityData => {
      if (String(this.selectedAffinity) === String(userAffinityData['affinityID'])) {
        this.isDefault = true;
      }
    });
  }

  public createNewAffinityCode() {
    this.createNewAffinity = true;
    const url = MAIN_PAGE_PART_URL + NEW_AFFINITY + ')';
    this.router.navigateByUrl(url);
  }

  public navigateToLanding() {
    if (this.selectedAffinity) {
      const url = MAIN_PAGE_PART_URL + LANDING + ')';
      sessionStorage.setItem('LOGGEDIN_AFFINITY', 'true');
      new Promise((resolve, reject) => {
        this.setAffinity();
      // resolve(10000);
     }).then(data => {
       EmitterService.get('ENABLE_MENU').emit(true);
     });
      // sessionStorage.removeItem('MENU_DATA');
      this.router.navigateByUrl(url);
    }
  }

  public saveAffinity() {
    this.selectedAffinity = this.affinity.name;
    EmitterService.get('ENABLE_MENU').emit('true');
    const url = MAIN_PAGE_PART_URL + LANDING + ')';
    this.router.navigateByUrl(url);
  }

  public cancelAffinity() {
    this.createNewAffinity = false;
  }

  hideDropdown() {
    this.display = 'none';
  }

  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
      this.searchKey = '';
      this.searchKeyChange();
      const _this = this;
      setTimeout(function () {
        _this.searchInput.nativeElement.focus();
      }.bind(_this));
    } else {
      this.display = 'none';
      this.searchKey = '';
      this.searchKeyChange();
    }
  }
  set(value: any, description: any) {
    this.selectedAffinity = value;
    this.description = description;
    this.toggleDisplay();
    this.onAffinityChange();
  }

}
