import { ProductMasterPolicyVO } from './ProductMasterPolicyVO';

export class ProductDetailsVO {
    productName: string;
    lobId: string;
    shortProductName: string;
    startDate: any;
    endDate: any;
    rateSheetEffectiveDate: any;
    ccweSheetEffectiveDate: any;
    riskStartDateRange: any;
    backDatedInception: any;
    backDatedInceptionRange: number;
    policyNumberingFormat: string;
    policyRenewal: boolean;
    policyEndorsement: boolean;
    productQuestion: any;
    productDocuments: any;
    rateSheetDocuments: any;
    ccweSheetDocuments: any;
    productGateway: any;
    productMasterPolicy: any;
    policyType: any;
    policyTerm: any;
    affinityId: any;
    constructor() {
        this.backDatedInception = '';
        this.backDatedInceptionRange = null;
        this.endDate = null;
        this.lobId = '';
        this.policyEndorsement = false;
        this.policyNumberingFormat = '';
        this.policyRenewal = false;
        this.policyType = [];
        this.productDocuments = [];
        this.rateSheetDocuments = [];
        this.ccweSheetDocuments = [];
        this.productGateway = [];
        this.productMasterPolicy = [];
        this.productMasterPolicy.push(new ProductMasterPolicyVO());
        this.productName = '';
        this.productQuestion = [];
        this.rateSheetEffectiveDate = null;
        this.ccweSheetEffectiveDate = null;
        this.riskStartDateRange = '';
        this.shortProductName = '';
        this.startDate = null;
        this.policyTerm = '';
        this.affinityId = '';
    }
}
