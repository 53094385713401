import { ViewChild, ElementRef } from '@angular/core';
import { Component, OnInit, Output } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { MAIN_PAGE_PART_URL, LANDING, NEW_AFFINITY, AFFINITY_NAME, AFFINITY_ID, USER_AFFINITY, USER_PROFILE, USER_PROFILE_DATA } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
import { UserManagementServices } from 'src/services/user-management.services';
import { SelectService } from 'src/services/select.services';
import { ProductServices } from 'src/services/product.service';

@Component({
  selector: 'app-affinity',
  templateUrl: './select-product.html',

})
export class SelectProductComponent implements OnInit {

  
  @ViewChild('searchInput') searchInput: ElementRef;
  public display = 'none';
  public prodDesc = 'Select Product Type';
  public selectedProduct: any;
  public lobTypeArray: Array<Object> = new Array<Object>();
  public productsArray: Array<Object> = new Array<Object>();
  public loadingmessage: any;
  isImuthootAffinity: boolean;
  constructor(private router: Router,
    private route: ActivatedRoute,
    private userManagementService: UserManagementServices,
    private selectService: SelectService,
    private productService: ProductServices) {

  }
  ngOnInit() {
    
    this.loadProductType();
    this.getProductDetails();
    
  }

  public searchFocusFunction() {
    const _this = this;
    setTimeout(function () {
      _this.display = 'flex';
      setTimeout(function () {
        _this.searchInput.nativeElement.focus();
      }.bind(_this));
    }.bind(_this));
  }
  
  public getProductDetails() {
      this.loadingmessage = 'Please wait...';
      const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
      if(userdetails['isMuthootCustomer'] === true){
            this.isImuthootAffinity = true;
          }
        const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
        this.productService.getProductListForAffinity(affinity[AFFINITY_ID])
          .then(list => {
            this.productService.listProducts(0, 10, list).then(data => {
              const dataVal = data['productList'];
              for (const val of dataVal) {
                if (val['shortProductName'] !== 'Health') {
                  const obj: Object = new Object();
                     obj['shortProductName'] = val['shortProductName'];
                     obj['productName'] = val['productName'];
                     obj['productType'] = val ['productType']
                     this.productsArray.push(obj);
                }
              }
              this.loadingmessage = '';
            }).catch(error => { });
          }).catch(error => {
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      
    }
  
    public serviceDownMessage() {
      setTimeout(
        function () {
          EmitterService.get('SERVICE_DOWN').emit(true);
        }
      );
      this.router.navigateByUrl('');
    }

  
   

  
  set(value: any, description: any) {
    this.selectedProduct = value;
    this.prodDesc = description;
    this.toggleDisplay();
  }

  public redirectPage() {
    for(let i=0; this.productsArray.length; i++) {
     const lob = this.productsArray[i]['shortProductName'];
      if (this.selectedProduct === lob){
        const proposalURL = this.productsArray[i]['productName'] +'/' + this.productsArray[i]['shortProductName']
        const url = MAIN_PAGE_PART_URL + 'proposal/'+ proposalURL+')';
        sessionStorage.setItem('LOGGEDIN_AFFINITY', 'true');
        this.router.navigateByUrl(url);
        break;
      }
    }
    
  }

  public saveAffinity() {
    EmitterService.get('ENABLE_MENU').emit('true');
    const url = MAIN_PAGE_PART_URL + LANDING + ')';
    this.router.navigateByUrl(url);
  }

  hideDropdown() {
    this.display = 'none';
  }

  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
      const _this = this;
      setTimeout(function () {
        _this.searchInput.nativeElement.focus();
      }.bind(_this));
    } else {
      this.display = 'none';
    
    }
  }

  public loadProductType() {
    this.lobTypeArray = [];
    this.selectService.fetchLookUpData('selectProductType', null).then(data => {
      for (const val of data) {
        const obj: Object = new Object();
        if (val['lobDescription'] !== 'Corporate Group Health') {
          obj['Code'] = val['lobId'];
          obj['Description'] = val['lobDescription'];
          obj['lobName'] = val['lobName'];
          if (val['lobName'] === 'Shop Owners') {
            obj['lobName'] = 'Shop';
          }
          this.lobTypeArray.push(obj);
        }
      }
    });
  }

}
