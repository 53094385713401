import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest } from '@angular/common/http';
import { AppConfig } from 'src/config/app.config';
import { HEADER, HEADER_PRINT, USER_PROFILE, HEADER_URL,BRANCH_CODE_INFO } from './../common/common.constants';
@Injectable({
  providedIn: 'root'
})
export class InboxpageService {
  hostUrl: string = this.config.getConfig('productHost');
  pageLoadUrl: string = 'api/policy/fetchProposal/';
  searchUrl: string = 'api/policy/search/value/';
  paymentTypeUrl:string ='api/admin/lob/list';
  searchParamUrl:string = 'api/policy/searchProposal/';
  statusUrl:string = 'api/policy/search/dropdown/';
  resendOtpUrl:string = 'api/policy/policyInfo/resendOtp/';
  validateOtpUrl:string= 'api/public/policyInfo/validateOtp';
  constructor(private httpClient: HttpClient,private config: AppConfig) { }

    getStatus(affinityId){
      const statusId = 3;
    return this.httpClient.get(this.hostUrl + this.statusUrl + affinityId + '?searchParamsMappingID='+ statusId ,{ headers: HEADER })
    .toPromise()
    .then((response: Response) => response)
    .catch(error => {
      return error;
    });
  }
public getPageLoadData(count, totalNumberOfRecords,affinityId,productType) {
  let branchCode = sessionStorage.getItem(BRANCH_CODE_INFO);
    if(branchCode === null){
      branchCode = '';
    }
  let URL = this.hostUrl +this.pageLoadUrl+ affinityId + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords +'&productType='+ productType +'&branchCode=' + branchCode;
  return this.httpClient
    .get(URL,{ headers: HEADER })
    .toPromise()
    .then((response: Response) => response)
    .catch(error => {
      const errorResponse = new Object();
      errorResponse['policyDetailsList'] = [];
      errorResponse['totalRecords'] = 0;
      return errorResponse;
    });
}


  getSearchList(affinityId){
  return this.httpClient.get(this.hostUrl + this.searchUrl + affinityId,{ headers: HEADER })
  .toPromise()
  .then((response: Response) => response)
  .catch(error => {
    return error;
  });
}
getPaymentTypeData(affinityId){
  return this.httpClient.get(this.hostUrl + this.paymentTypeUrl,{ headers: HEADER })
  .toPromise()
  .then((response: Response) => response)
  .catch(error => {
    this.handleError(error);
    return error;
  });
}
private handleError(error: any): Promise<any> {
  return Promise.reject(error.message || error);
}



public proposalSearch(searchID, searchInput, searchValue , action,count,totalNumberOfRecords,shortProductName) { 
  const affinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
  const affinityId = affinity['id'];
  const policyStatus = 2;
  let branchCode = sessionStorage.getItem(BRANCH_CODE_INFO);
    if(branchCode === null){
      branchCode = '';
    }
  let URL;
      
  
  if (action === 'ProposalDate' && searchID == 4) { 

      URL = this.hostUrl + this.searchParamUrl + affinityId + '?branchCode='+branchCode + '&offset=0&size=10&searchColumn1=PolicyStartDate&searchValue1=' + searchInput+'&searchColumn2=PolicyStartDate&searchValue2=' + searchValue +'&productType='+shortProductName;
    
  } else if (action === 'OTP Generated' && searchID == 1) {   
      const data = 6; 
      URL = this.hostUrl + this.searchParamUrl + affinityId  + '?branchCode='+branchCode + '&offset=0&size=10&searchColumn1=PolicyStatusID&searchValue1=' + data + '&productType='+shortProductName;
    
  } else if (action === 'OTP Verified' && searchID == 2) {    
    const data = 7;
    URL = this.hostUrl + this.searchParamUrl + affinityId  + '?branchCode='+branchCode + '&offset=0&size=10&searchColumn1=PolicyStatusID&searchValue1=' + data + '&productType='+shortProductName;
  
} else if(searchID == 1 && action === 'Employee ID'){    
      URL = this.hostUrl + this.searchParamUrl + affinityId  + '?branchCode='+branchCode + '&offset=0&size=10&searchColumn1=EmployeeId&searchValue1=' + searchInput +'&productType=' + shortProductName;
     
    
  }else if(searchID == 2 && action === 'ProposalID'){    
    URL = this.hostUrl + this.searchParamUrl + affinityId  + '?branchCode='+branchCode + '&offset=0&size=10&searchColumn1=Covernoteno&searchValue1=' + searchInput + '&productType=' + shortProductName;
   } 
else {      
 URL =this.hostUrl + this.pageLoadUrl + affinityId + '?' +'&offset=' + count + '&size='+totalNumberOfRecords+'&productType='+ shortProductName +'&branchCode=' + branchCode;
  //this.getPageLoadData(0, 10);  
  }
  return this.httpClient
    .get(URL, { headers: HEADER })
    .toPromise()
    .then((response: Response) => response)
    .catch(error => {
      
      const errorResponse = new Object();
      errorResponse['policyDetailsList'] = [];
      errorResponse['totalRecords'] = 0;
      return errorResponse;
    });
}
validateOTP(policyData){
 return this.httpClient
 .post(this.hostUrl + this.validateOtpUrl,policyData,{headers:HEADER,responseType : 'text' as 'json'})
 .toPromise()
  .then((response: Response) => response)
  .catch(error => {
    return error;
  })
}
getOTPRetryStatus(policyId,affinityName){
  return this.httpClient
  .get(this.hostUrl + this.resendOtpUrl + policyId + '?affinityName=' + affinityName, {headers: HEADER_URL,
  responseType : 'text' as 'json'})
  .toPromise()
  .then((response: Response) => response)
  .catch(error => {
    return error;
  });
}


}
