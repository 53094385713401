import { Http, Response, Headers, RequestOptions, ResponseContentType } from '@angular/http';
import { Injectable, OnInit, OnChanges } from '@angular/core';
import { AppConfig } from '../config/app.config';
import {
  REQUEST_HEADER, REQUEST_HEADER_UM, HEADER, USER_AFFINITY, USER_NAME, BRANCH_CODE, BRANCH,
  BRANCH_NAME, NAME, USER_PROFILE, HEADER_UM, USER_PROFILE_DATA, HEADER_PRINT

} from '../common/common.constants';
import { HttpParams, HttpClient } from '@angular/common/http';
@Injectable()
export class ResourceService {
  // hostUrl: string = this.config.getConfig('basehost');
  hostUrl: string = this.config.getConfig('productHost');
  constructor(private http: Http,
    private config: AppConfig,
    private httpClient: HttpClient ) { }

  public getProductsIds(affinityId: string) {
    let url: any;
    url = this.hostUrl + 'api/admin/affinity/product/list/' + affinityId;
    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
  }

  public getProductNames(productIDList) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/product/retrieve/product/id/name';
    const requestBody = new Object();
    requestBody['idList'] = productIDList;
    return this.httpClient.post(url, requestBody, { headers: HEADER })
      .toPromise()
      .then((response: any) => response)
      .catch(this.handleError);
  }

  public getAffinityDocs(affinityId: any) {
    let url: any;
    url = this.hostUrl + 'api/admin/affinity/documents/resources/' +affinityId;
    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
  }

  public downloadAfftinityDocs(affinityId: any, affinityDocumentName: String) {

    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('Accept', 'application/pdf');
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;
    let url: any;
    url = this.hostUrl + 'api/product/resource/affinity/download/' + affinityId + '?affinityDocumentName=' + affinityDocumentName;
    return this.http.get(url, options)
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }


  public downloadAfftinityDocsPDF(affinityId: any, affinityDocumentName: String) {
    const headers = new Headers({
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)),
      'authorizationToken': JSON.parse(sessionStorage.getItem(USER_PROFILE))
    });
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;
    const URl = this.hostUrl + 'api/product/resource/affinity/download/' + affinityId + '?affinityDocumentName=' + affinityDocumentName;
    return this.httpClient.get(URl, { headers: HEADER_PRINT, responseType: 'arraybuffer' })
      .toPromise()
      .then((response: any) => response)
      .catch(this.handleError);
  }

  public getProductDocuments(productId: Number) {
    let url: any;
    url = this.hostUrl + 'api/product/documents/resources/' + productId;
    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();
  }

  public downloadProductDocs(affinityId: Number, productDocumentName: String, productId: Number) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('Accept', 'application/pdf');
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;
    let url: any;
    url = this.hostUrl + 'api/product/resource/product/download/' + affinityId + '?productId=' + productId + '&productDocumentName=' + productDocumentName;
    return this.http.get(url, options)
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public downloadProductDocuments(affinityId: Number, productDocumentName: String, productId: Number) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/product/resource/product/download/' + affinityId;
    const requestObject = new Object();
    requestObject['productDocumentName'] = productDocumentName;
    requestObject['productId '] = productId;
    return this.httpClient.post(url, requestObject, { headers: HEADER })
      .toPromise().then((response: Response) => response)
      .catch(this.handleError);
  }

  // 22/06/2020
  public getProductRateSheetList(productId:Number) {
    let url: any;
    url = this.hostUrl  + 'api/product/document/ratesheet/list/'+ productId;
    return this.httpClient.get(url, { headers: HEADER }).toPromise().catch();

  }

  public downloadProductRateSheetDocs(affinityId: Number, productDocumentName: String, productId: Number) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('Accept', 'application/pdf');
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;
    let url: any;
    url = this.hostUrl + 'api/product/resource/ratesheet/download/' + affinityId + '?productId=' + productId + '&ratesheetDocumentName=' + productDocumentName;
    return this.http.get(url, options)
      .toPromise().then((response: Response) => response).catch(this.handleError); 
  }

  public downloadClaimForm() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('Accept', 'application/pdf');
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;
    let url: any;
    url = this.hostUrl  + 'api/policy/claims/download';
    return this.http.get(url, options)
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
