import { Component, OnInit, ViewChildren, ViewChild, ElementRef, Input, HostListener, AfterViewInit, OnChanges, ɵConsole } from '@angular/core';
import { EmitterService } from 'src/services/EmitterService';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { Router, ActivatedRoute } from '@angular/router';
import { DESCRIPTION, CODE } from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { DatePipe } from '@angular/common';
import { ReportsVO } from './../dto/ReportsVO';
import * as _moment from 'moment';
import { ReportsService } from '../services/reports.service';
import { UserManagementServices } from 'src/services/user-management.services';
import { ReportDetailsVO } from '../dto/ReportDetailsVO';
import { ModalService } from '../services/modal.services';
import { USER_AFFINITY, AFFINITY_ID, SHORT_PROD_NAME, USER_PROFILE_DATA } from '../common/common.constants';




const moment = (_moment as any).default ? (_moment as any).default : _moment;

@Component({
  selector: 'app-reportsbusiness',
  templateUrl: './reportsbusiness.component.html'
})
export class ReportsBusinessComponent implements OnInit, AfterViewInit {


  public hrLeftMargin;
  public hrWidth;
  public isSubmit: Boolean = false;
  public enableSubmit: Boolean = false;
  public totalRows: any;
  public totalNumberOfRecords: Number;
  public count: any;
  public loadingmessage = '';
  public ReportType: String;
  public policyNumber: any;
  public branchId: any;
  public branchName: String = null;
  public regionName: String = null;
  public branchData: any;
  public ReportTypeData: any;
  public branchDetails = [];
  public healthDetails = [];
  public branchNameId: any;
  public productIdList: any;
  public productList: any;
  public reportDetails: ReportDetailsVO;
  public policyCount: Array<Object> = Array<Object>();
  public reportArray: Array<Object> = new Array<Object>();
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  public productArray: Array<Object> = new Array<Object>();
  public columnHeadings: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public populatArray: Array<Object> = Array<Object>();
  public lobDetails: Array<Object> = Array<Object>();
  public description: String = '--Select--';
  public productType: any;
  public productDateRangeMessage: any;
  public dateFlag: Boolean = false;
  public lobId: any;
  public roleId: string;
  public roleName: string;
  public userName: String;
  public reportId: String;
  public max: any;
  public businessType: any;
  public reports: ReportsVO = new ReportsVO();
  public dowanloadFlag: Boolean = false;
  public branchDetailsInfo: any;
  public policyIdWithPlanIds: Array<Object> = Array<Object>();
  public policyPlanCoverageInfo: any;
  public isSubAdminRole: any = 'N';
  public sendFlag: Boolean = false;

  @ViewChildren(TextBoxComponent) private textFields: any;
  @ViewChildren(SelectComponent) private selectFields: any;
  @ViewChildren(DatePickerComponent) private dateFields: any;
  @ViewChild('formWrapper') formWrapperRef: ElementRef;

  @HostListener('window:resize', ['$event'])
  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);

  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }

  constructor(private reportsService: ReportsService,
    private userManagementServide: UserManagementServices,
    private route: ActivatedRoute,
    private modalService: ModalService,
    private datePipe: DatePipe) {

  }


  ngOnInit() {
    this.dowanloadFlag = false;
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    this.roleId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleId'];
    this.roleName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
    this.userName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['userName'];
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
    EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
    if('Branch User' === this.roleName) {
      const branchId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['branchId'];
      this.getBranchNameForBranchUser(branchId);
    }
    if(this.roleName!=='System Administrator')
    {
      this.getAffinityName();
    }
    else
    {
      this.getProductListForAllAffinities();
    }
    this.reports = new ReportsVO();
   // this.productTypeChange();
    const date = new Date;
    this.max = new Date(date.setDate(date.getDate()));
    const endDate: any = this.datePipe.transform(this.max, 'dd/MM/yyyy HH:mm:ss');
    this.reports.startDate = endDate;
    this.reports.endDate = endDate;
    this.getPolicyDetails();
  }

  ngAfterViewInit() {
    this.onResize();
  }
  public getBranchNameForBranchUser(data) {
    this.reportsService.getBranchDetails(data).then(branch => {
      this.branchData = branch;
      this.branchDetailsInfo = branch;
    }).catch(error => {

    });
  }
  public getAffinityName() {
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    this.reportsService.getProductListForAffinity(affinity[AFFINITY_ID])
      .then(list => {
        this.productIdList = list;
      }).catch(error => {
        this.loadingmessage = '';
      });
  }

  public getProductListForAllAffinities() {
    this.reportsService.getProductListForAllAffinities()
      .then(list => {
        this.productIdList = list;
      }).catch(error => {
        this.loadingmessage = '';
      });
  }

  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }
  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  reportTypeArray() {
    const code1: Object = new Object();
    code1[CODE] = '1';
    code1[DESCRIPTION] = 'Daily Transaction Report';
    const code2: Object = new Object();
    code2[CODE] = '2';
    code2[DESCRIPTION] = 'Business Report';
    const code3: Object = new Object();
    code3[CODE] = '3';
    code3[DESCRIPTION] = 'Endorsement Transaction Report';
    this.reportArray.push(code1);
    this.reportArray.push(code2);
    this.reportArray.push(code3);
  }

  productTypeArray() {
    const code1: Object = new Object();
    code1[CODE] = '1';
    code1[DESCRIPTION] = 'Business Package Policy for Home';
    const code2: Object = new Object();
    code2[CODE] = '2';
    code2[DESCRIPTION] = 'Muthoot Shop Owners Policy';
    this.productArray.push(code1);
    this.productArray.push(code2);
  }
  BusinessColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'Branch Name';
    this.columnHeadings.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'No. of Policies';
    this.columnHeadings.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Total Premium';
    this.columnHeadings.push(col3);
  }
 
  DailyTransactionColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'No. of Policies';
    this.columnHeadings.push(col1);
  }

  EndorsementTransactionColDatas() {
    this.columnHeadings = [];
    const col1: Object = new Object();
    col1['colName'] = 'No. of Policies';
    this.columnHeadings.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Total Premium';
    this.columnHeadings.push(col2);
  }

  public prepareActionColumns() {
    this.actionColumnsData.push('DOWNLOAD');
  }

  public productTypeChange() {
    this.clearData();
    if (String(this.productType) === '2') {
      this.reports.shortProductName = 'Home';
    } else if (String(this.productType) === '3') {
      this.reports.shortProductName = 'Shop Owners';
    }
    const selectedAffinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinity = selectedAffinity['name'];
    this.reportsService.getlobId().then(data => {
      this.lobDetails = data;
      for (const lobdetails of this.lobDetails) {
        if (this.reports.shortProductName === String(lobdetails['lobName'])) {
          this.lobId = lobdetails['lobId'];
          if (this.roleName.toLowerCase() === 'sub admin'){
            this.isSubAdminRole = 'Y';
            this.ReportTypeData = [];
            const reportItem1 = new Object();
            reportItem1['Code'] = 1;
            reportItem1['Description'] ='Business Report';
            const reportItem = new Object();
            reportItem['Code'] = 2;
            reportItem['Description'] ='Daily Transaction Report';
            this.ReportTypeData.push(reportItem1);
            this.ReportTypeData.push(reportItem);
          } else {
          this.loadingmessage = 'Preparing Data...';
          this.reportsService.getReportType(this.roleId, this.lobId).then(reportdata => {
            this.ReportTypeData = [];
            this.loadingmessage = '';
            for (const report of reportdata) {
              const reportItem = new Object();
              reportItem['Code'] = report['reportId'];
              reportItem['Description'] = report['reportName'];
              this.ReportTypeData.push(reportItem);
            }
            if (affinity.toLowerCase().startsWith('muthoot')) {
              const reportItem = new Object();
              reportItem['Code'] = 5;
              reportItem['Description'] ='MIS Report';
              this.ReportTypeData.push(reportItem);
            }
          }).catch(error => {
            this.loadingmessage = '';
          });
        }
        }
      }
    }).catch(error => {
      this.loadingmessage = '';
    });
  }

  public getBranchId() {
    this.branchNameId = [];
    if ((this.branchName !== '' && this.branchName !== null && this.branchName !== undefined)) {
      this.reportsService.getBranchId(this.branchName).then(branchNameId => {
        this.branchNameId.push(branchNameId);
      })
        .catch(error => {
          this.loadingmessage = '';
        });
    } else if ((this.branchName === '' || this.branchName === null || this.branchName === undefined) && (this.regionName !== '' && this.regionName !== null && this.regionName !== undefined)) {
      this.reportsService.getRegionBranchId(this.regionName).then(regionNameId => {
        this.branchNameId = regionNameId;
      })
        .catch(error => {
          this.loadingmessage = '';
        });
    }
  }

  public downloadReport(event) {
    this.loadingmessage = 'Preparing Document...';
    if (String(this.productType) === '2') {
      this.businessType = 'Home';
    } else if (String(this.productType) === '3') {
      this.businessType = 'Shop';
    }
      const branchDetails = this.branchDetailsInfo;
      const branchInfo = new Object();
      if (branchDetails !== undefined) {
        this.branchName = branchDetails['branchName'];        
      }
    if (String(this.reportId) === '2') {
     
      if(this.branchName === '' || this.branchName === undefined){
       this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined){
        this.regionName = null;
      }
      const isMISReport = false;
      const coverages = JSON.stringify(this.policyPlanCoverageInfo);
      this.reportsService.getDailyTransactionReportShop(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.policyPlanCoverageInfo,isMISReport, this.isSubAdminRole,
        this.branchName,this.regionName)
        .then(data => {
          const parsedResponse = data.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'Daily Transaction ' + this.businessType + ' Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'Daily Transaction ' + this.businessType + ' Report.xlsx';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);

          }
        })
        .catch(error => {

        });

    } else if (String(this.reportId) === '1') {
      const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
      const userRole = userdetails['roleName'];
      const branchDetailId = userdetails['branchId'];
      const obj = new Object();
      const branchDetails = this.branchDetailsInfo;
      const branchInfo = new Object();
      if (branchDetails !== undefined) {
        branchInfo['branchCode'] = branchDetails['branchCode'];
        this.branchName = branchDetails['branchName'];
        branchInfo['branchRegion '] = branchDetails['branchRegion'];
        branchInfo['branchArea'] = branchDetails['branchArea'];
        obj[branchDetails['branchID']] = branchInfo;
      }
      if(this.branchName === '' || this.branchName === undefined) {
        this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined) {
        this.regionName = null;
      }
      this.reportsService.getBusinessReport(this.reports.startDate, this.reports.endDate, this.businessType, obj, this.productIdList, this.branchName, this.regionName)
        .then(data => {

          const parsedResponse = data.blob();
          this.dowanloadFlag = true;
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'Business ' + this.businessType + ' Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'Business ' + this.businessType + ' Report.xlsx';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {

        });

    } else if (String(this.reportId) === '4') {
      const coverages = JSON.stringify(this.policyPlanCoverageInfo);
      this.reportsService.getEndorsemenBuzzBPReport(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.policyPlanCoverageInfo )
        .then(data => {
          const parsedResponse = data.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'Endorsement Transaction Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'Endorsement Transaction Report.xlsx';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);
          }
        })
        .catch(error => {
        });
    } else if (String(this.reportId) === '5') {
      const isMISReport = true;
      if(this.branchName === '' || this.branchName === undefined){
        this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined){
        this.regionName = null;
      }
      const coverages = JSON.stringify(this.policyPlanCoverageInfo);
      this.reportsService.getDailyTransactionReportShop(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.policyPlanCoverageInfo,isMISReport, this.isSubAdminRole,
        this.branchName,this.regionName)
        .then(data => {
          const parsedResponse = data.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
          if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, ' MIS ' + this.businessType + ' Report.xlsx');
            this.loadingmessage = '';
          } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = ' MIS ' + this.businessType + ' Report.xlsx';
            document.body.appendChild(a);
            a.click();
            this.loadingmessage = '';
            document.body.removeChild(a);

          }
        })
        .catch(error => {

        });

    }
  }

  public successmessage() {
    this.enableSubmit = false;
    this.isSubmit = true;
    this.dowanloadFlag = false;
    this.sendFlag = false;
    this.getPolicyDetails();
  }

  public getPolicyDetails() {
    const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
	  if(userdetails['roleName'].toLowerCase() === 'branch user') {
      this.branchNameId = [];
      this.branchNameId.push(userdetails['branchId']);
    }
    if (String(this.productType) === '2') {
      this.businessType = 'Home';
    } else if (String(this.productType) === '3') {
      this.businessType = 'Shop';
    }
    if (String(this.reportId) === '2') {
      this.policyIdWithPlanIds = [];
      this.loadingmessage = 'Preparing Data...';
      this.policyCount = [];
      const isMISReport = false;
      if(this.branchName === '' || this.branchName === undefined){
        this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined){
        this.regionName = null
      }
      this.reportsService.getDailyTrasactionGridData(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId,isMISReport,
        this.branchName, this.regionName).then(data => {
        const val: any = data;
        this.totalRows = 1;
        if (data !== 0) {
          this.reportsService.getPolicyAndPlansForFromAndToDate(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId,isMISReport).then(data1 => {
            this.policyPlanCoverageInfo = '';
            const policyAndPlanId = data1;
            const planAndPolicyId: object = new Object();
            planAndPolicyId ['policyIdWithPlanIds'] = data1;
         //   planAndPolicyId ['policyIdWithPlanIds'].push(data1);
            const planAndPplicyidList = JSON.stringify(planAndPolicyId);
             this.reportsService.getCoverageAndSectionsReport(planAndPplicyidList).then(data2 => {
              this.policyPlanCoverageInfo = data2;
              this.prepareProjectData(data);
             }).catch(error => {

             });
          }).catch(error => {

          });
        } else {
          this.loadingmessage = '';
          this.enableSubmit = true;
        }
        this.moveToTop();
      }).catch(error => {
        this.loadingmessage = '';
      });
    } else if (String(this.reportId) === '1') {
      this.loadingmessage = 'Preparing Data...';
      const rowDataValues = [];
      this.policyCount = [];
      this.reportsService.getBusinessGridData(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId).then(branchDetails => {
        this.branchDetails = branchDetails;
      }).then(data => {
        if (this.branchDetails.length !== 0) {
        for (const branchData of this.branchDetails) {
          const rowData = new Object();
          rowData['Total Premium'] = branchData['totalPremium'];
          rowData['No. of Policies'] = branchData['noOfPolicies'];
          this.branchId = branchData['branchId'];
          if (this.branchId !== 0) {
            this.reportsService.getBranchDetails(this.branchId).then(branch => {
              this.branchData = branch;
              this.branchDetailsInfo = branch;
              rowData['Branch Data'] = branch;
              rowData['Branch Name'] = branch['branchName'];
              this.prepareProjectData(rowData);
              rowDataValues.push(rowData);
              this.loadingmessage = '';
              this.moveToTop();
            }).then(branch => {
            }).catch(error => {
              this.loadingmessage = '';
            });
          } else {
            this.loadingmessage = '';
            rowData['Branch Data'] = '';
            rowData['Branch Name'] = '';
            this.prepareProjectData(rowData);
            rowDataValues.push(rowData);
          }
        }
      } else {
        this.loadingmessage = '';
        this.enableSubmit = true;
      }
      }).then(branchDetails => {
      }).catch(error => {
        this.loadingmessage = '';
      });

    } else if (String(this.reportId) === '4') {
      const isMISReport: Boolean = false;
      this.policyCount = [];
      this.loadingmessage = 'Preparing Data...';
      this.reportsService.EndorsementBuzzGridData(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId).then(healthDetails => {
        this.healthDetails = healthDetails;
        if (this.healthDetails.length !== 0) {
          this.reportsService.getPolicyAndPlansForFromAndToDate(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId,isMISReport).then(data1 => {
            this.policyPlanCoverageInfo = '';
            const planAndPolicyId: object = new Object();
            planAndPolicyId['policyIdWithPlanIds'] = data1;
            const planAndPplicyidList = JSON.stringify(planAndPolicyId);
            this.reportsService.getCoverageAndSectionsReport(planAndPplicyidList).then(data2 => {
              this.policyPlanCoverageInfo = data2;
              for (const healthData of this.healthDetails) {
                const rowData = new Object();
                rowData['Total Premium'] = healthData['totalPremium'];
                rowData['No. of Policies'] = healthData['noOfPolicies'];
                this.branchId = healthData['branchId'];
                this.prepareProjectData(rowData);
                this.totalRows = 1;
                this.loadingmessage = '';
                this.moveToTop();
              }
            }).catch(error => {
            });
          }).catch(error => {

          });
        } else {
          this.loadingmessage = '';
          this.enableSubmit = true;
        }
      }).catch(error => {
        this.loadingmessage = '';
      });
    } else if (String(this.reportId) === '5') {
      this.policyIdWithPlanIds = [];
      this.loadingmessage = 'Preparing Data...';
      this.policyCount = [];
      const isMISReport = true;
      if(this.branchName === '' || this.branchName === undefined){
        this.branchName = null;
      }
      if(this.regionName === '' || this.regionName === undefined){
        this.regionName = null;
      }
      this.reportsService.getDailyTrasactionGridData(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId,isMISReport,
        this.branchName, this.regionName).then(data => {
        const val: any = data;
        this.totalRows = 1;
        if (data !== 0) {
          this.reportsService.getPolicyAndPlansForFromAndToDate(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.branchNameId,isMISReport).then(data1 => {
            this.policyPlanCoverageInfo = '';
            const policyAndPlanId = data1;
            const planAndPolicyId: object = new Object();
            planAndPolicyId ['policyIdWithPlanIds'] = data1;
         //   planAndPolicyId ['policyIdWithPlanIds'].push(data1);
            const planAndPplicyidList = JSON.stringify(planAndPolicyId);
             this.reportsService.getCoverageAndSectionsReport(planAndPplicyidList).then(data2 => {
              this.policyPlanCoverageInfo = data2;
              this.prepareProjectData(data);
             }).catch(error => {

             });
          }).catch(error => {

          });
        } else {
          this.loadingmessage = '';
          this.enableSubmit = true;
        }
        this.moveToTop();
      }).catch(error => {
        this.loadingmessage = '';
      });
    }
  }

  public generateBusinessGridData(data: any) {
    const rowDataValues = [];
    return new Promise((resolve, reject) => {
    }).then(data2 => {
    }).catch(error => {
    });
  }
  public prepareProjectData(dailyTransactionData: any) {
    this.loadingmessage = '';
    const data: Object = new Object();
    this.enableSubmit = true;
    // this.policyCount = [];
    if (String(this.reportId) === '2') {
      data['No. of Policies'] = dailyTransactionData;
    } else if (String(this.reportId) === '1') {
      data['No. of Policies'] = dailyTransactionData['No. of Policies'];
      data['Total Premium'] = dailyTransactionData['Total Premium'];
      data['Branch Name'] = dailyTransactionData['Branch Name'];
      data['Branch Data'] = dailyTransactionData['Branch Data'];
    } else if (String(this.reportId) === '4') {
      data['Total Premium'] = dailyTransactionData['Total Premium'];
      data['No. of Policies'] = dailyTransactionData['No. of Policies'];
    } else if (String(this.reportId) === '5') {
      data['No. of Policies'] = dailyTransactionData;
    }
    this.policyCount.push(data);
    this.dowanloadFlag = true;
  }
  public getBranchName(branchName: any) {
    const data: Object = new Object();
    this.policyCount = [];
    if (String(this.reportId) === '1') {
      data['Branch'] = branchName;
    }
    this.policyCount.push(data);
  }


  public gridcolumns() {
    this.initialize();
    if (String(this.reportId) === '2') {
      this.DailyTransactionColDatas();
    } else if (String(this.reportId) === '1') {
      this.BusinessColDatas();
    } else if (String(this.reportId) === '4') {
      this.EndorsementTransactionColDatas();
    } else if (String(this.reportId) === '5') {
      this.DailyTransactionColDatas();
    }
  }

  public sendReport() {
    const emailaddress = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['emailAddress'];
    this.loadingmessage = 'Preparing Document...';
    if (String(this.productType) === '2') {
      this.businessType = 'Home';
    } else if (String(this.productType) === '3') {
      this.businessType = 'Shop';
    }
    if (String(this.reportId) === '2') {
      const isMISReport = false;
      const coverages = JSON.stringify(this.policyPlanCoverageInfo);
      this.reportsService.sendDailyTransactionReportShop(this.reports.startDate, this.reports.endDate, this.businessType, this.productIdList, this.policyPlanCoverageInfo,isMISReport, this.isSubAdminRole,emailaddress)
        .then(data => {
          const parsedResponse = data.blob();
          const blob = new Blob([parsedResponse], {
            type: 'application/xlsx'

          });
        })
        .catch(error => {

        });
        this.showModal();
        this.sendFlag = true;
        this.loadingmessage = '';
      }

  }
    
  

  public initialize() {
    this.description = '--Select--';
    this.branchName = null;
    this.regionName = null;
    this.dateFlag = false;
    this.policyCount = [];
    this.reports.startDate = '';
    this.reports.endDate = '';
    this.enableSubmit = false;
    this.isSubmit = false;
  }


  public clearData() {
    this.branchName = null;
    this.regionName = null;
    this.dateFlag = false;
    this.policyCount = [];
    // this.reports.startDate = '';
    // this.reports.endDate = '';
    this.enableSubmit = false;
    this.isSubmit = false;
    this.dowanloadFlag = false;
    this.ReportTypeData=[];
    this.reportId="";

  }

  public clearStartDate() {
    if (this.isSubmit === true) {
      this.branchName = null;
      this.regionName = null;
      this.dateFlag = false;
      this.policyCount = [];
      this.reports.endDate = '';
      this.enableSubmit = false;
      this.isSubmit = false;
    }
  }

  public clearEndDate() {
    if (this.isSubmit === true) {
      this.branchName = null;
      this.regionName = null;
      this.dateFlag = false;
      this.policyCount = [];
      this.reports.startDate = '';
      this.enableSubmit = false;
      this.isSubmit = false;
    }
  }

  private outSidevalidate() {
    let textResult: any = false;
    let selectResult: any = false;
    let dateResult: any = false;
    let hasTextField: Boolean = false;
    let hasSelectField: Boolean = false;
    let hasDateField: Boolean = false;
    let textFlag = true;
    let selectFlag = true;
    let dateFlag = true;
    for (const textField of this.textFields['_results']) {
      hasTextField = true;
      const input: TextBoxComponent = textField;
      textResult = input.onBlurMethod(false);
      if (!textResult) {
        textFlag = false;
      }
    }
    for (const selectField of this.selectFields['_results']) {
      hasSelectField = true;
      const input: SelectComponent = selectField;
      selectResult = input.onBlurMethod(false);
      if (!selectResult) {
        selectFlag = false;
      }
    }
    for (const dateField of this.dateFields['_results']) {
      hasDateField = true;
      const input: DatePickerComponent = dateField;
      dateResult = input.onBlurMethod(false);
      if (!dateResult) {
        dateFlag = false;
      }
    }
    const result: any =
      (textFlag || !hasTextField) &&
      (selectFlag || !hasSelectField) &&
      (dateFlag || !hasDateField) &&
      this.dateFlag;
    if (result) {
      this.enableSubmit = true;
      return true;
    } else {
      this.enableSubmit = false;
      this.dowanloadFlag = false;
      return false;
    }
  }

  dateRangeValidation(startDate: Date, endDate: Date) {
    if (startDate && endDate) {
      if (startDate > endDate) {
        this.productDateRangeMessage = 'Start Date should be prior to End Date';
        this.dateFlag = false;
      } else {
        this.productDateRangeMessage = '';
        this.dateFlag = true;
      }
    } else {
      this.productDateRangeMessage = '';
    }
  }


  public productStartChange() {
    this.clearStartDate();
    const date = moment(this.reports.startDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.reports.startDate = date;
    } else {
      this.reports.startDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }

  }

  public productEndChange() {
    this.clearEndDate();
    const date = moment(this.reports.endDate, 'DD/MM/YYYY').startOf(
      'day'
    );
    const now = moment(new Date(), 'DD/MM/YYYY').startOf('day');
    if ((now > date || now < date)) {
      this.reports.endDate = date;
    } else {
      this.reports.endDate = moment(
        new Date(),
        'DD/MM/YYYY HH:MM:ss'
      );
    }
  }
  public showModal() {
    this.openModal('confirmationModal');
  }
  public closeRetryModal() {
    this.closeModal('confirmationModal');
  }
  openModal(id: string) {
    this.modalService.open(id);
  }

  closeModal(id: string) {
    this.modalService.close(id);
  }


}
