import { Injectable } from '@angular/core';
import { Http, Response, RequestOptions, ResponseContentType } from '@angular/http';
import { AppConfig } from 'src/config/app.config';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HEADER, HEADER_PRINT } from './../common/common.constants';



@Injectable()
export class DeclarationService {
    constructor(private httpClient: HttpClient, private http: Http, private config: AppConfig) {
    }
    hostUrl: string = this.config.getConfig('loginHost');
    public getDeclarationDetails(application: string) {
        const url = this.hostUrl + 'api/public/declaration?application=' + application;
        return this.httpClient
        .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    public redirectToPaymentGateWay(application: any, gatwayId: any, walletId: any, policyInfo: any) {

        const url  = this.hostUrl + 'api/public/getPayment?application=' + application + '&gateWayId=' + gatwayId + '&walletId=' + walletId + '&policy=' + policyInfo;
        return this.httpClient
        .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    public getPolicyIdInfo(policyId: any) {
       
       const url  = this.hostUrl + 'api/public/policyInfo/' +policyId ;
        return this.httpClient
        .get(url, { headers: HEADER })
            .toPromise()
            .then((response: Response) => response)
            .catch(this.handleError);
    }

    private handleError(error: any): Promise<any> {
        return Promise.reject(error.message || error);
    }
}
