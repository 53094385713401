import { Component, OnInit } from '@angular/core';
import { ProposalServices } from 'src/services/proposal.services';
import { ProposalVO } from 'src/dto/ProposalVO';
import { CommunicationVO } from '../dto/CommunicationVO';
import { BeneficiaryVO } from '../dto/BeneficiaryVO';
import { ActivatedRoute } from '@angular/router';
import { PremiumDetailsVO } from 'src/dto/PremiumDetailsVO';
import { ModalService } from '../services/modal.services';
import * as _moment from 'moment';
const moment = (_moment as any).default ? (_moment as any).default : _moment;

import {
  BENIFICIARY_SELF,
  BENIFICIARY_SPOUSE,
  GENDER_MALE,
  GENDER_FEMALE,
  RELATIONSHIP_DEPENDENT_SON,
  RELATIONSHIP_DEPENDENT_DAUGHTER,
  PLAN_TYPE_DESC,
  POLICY_TYPE_DESC,
  DEFAULT_POLICY_TYPE,
  GENDER_ID,
  GENDER_DESC,
  RELATION_SHIP_DESC,
  RELATION_SHIP_ID,
  LABEL_GENDER,
  LABEL_RELATIONSHIP,
  SUMISURED,
  SUMINSURED_ID,
  DESCRIPTION,
  CODE
} from 'src/common/common.constants';
import { SelectService } from 'src/services/select.services';
import { CustomerDetailsVO } from 'src/dto/CustomerDetailsVO';
import { RiskLocationVO } from 'src/dto/RiskLocationVO';

@Component({
  selector: 'app-proposal-summary',
  templateUrl: './proposal-summary.component.html',
  styleUrls: ['./proposal-summary.component.css']
})
export class ProposalSummaryComponent implements OnInit {
  public proposerVo: ProposalVO = new ProposalVO();
  public communicationDetail: CommunicationVO = new CommunicationVO();
  public beneficiaryVO: BeneficiaryVO = new BeneficiaryVO();
  public premiumDetail: PremiumDetailsVO = new PremiumDetailsVO();
  public customerDetails: CustomerDetailsVO = new CustomerDetailsVO();
  public riskLocationDetails: RiskLocationVO = new RiskLocationVO();
  public adultList: any;
  public childList: any;
  public beneficiaryList: any;
  public questionList: any;
  public collabsible = new Object();
  public loadingmessage = 'Preparing Data...';
  public isMappingDone: Boolean = false;
  public otpFlag: Boolean = false;
  public dtArray: Array<Object> = new Array<Object>();
  public checkedArray: Array<Object> = new Array<Object>();
  public isHealth: Boolean = true;
  public bpTitleDesc: String;
  public otpVerified: Boolean = false;
  public otpValidateStatus: String;

  constructor(
    private proposalServices: ProposalServices,
    private modalService: ModalService,
    private route: ActivatedRoute,
    private selectService: SelectService,
  ) {}

  ngOnInit() {
    let quoteNumber;
    this.LoadDeclration();
   // this.loadingmessage = 'Preparing Data...';
    this.route.params.subscribe(params => (quoteNumber = params.quoteNumber));
    this.proposerVo.communicationDetails = this.communicationDetail;
    this.proposerVo.customerDetails = this.customerDetails;
    this.proposerVo.riskLocationDetails = this.riskLocationDetails;

    this.proposalServices
      .getProposalSummary(quoteNumber)
      .then(data => {
        if (data !== undefined) {
          this.summaryData(data);
        }
      })
      .catch(error => {});
  }

  public summaryData(data) {
    const proposerDetails = data;
    this.proposerVo.policyId=  proposerDetails.policyId;
    this.proposerVo.policyIdEncrypt=  proposerDetails.policyIdEncrypt;
    this.proposerVo.policyStartDate = proposerDetails.policyStartDate;
    this.proposerVo.policyTerm = proposerDetails.policyTermDescription;
    const date = proposerDetails.policyEndDate.split(' ');
    const endDateTemp = date[0] + ' 23:59:59';
    this.proposerVo.policyEndDate = endDateTemp;
    this.proposerVo.masterPolicyNo = proposerDetails.masterPolicyNo;
    this.proposerVo.policyTypeName = proposerDetails.policyTypeDescription;
    this.proposerVo.planTypeName = proposerDetails.planDescription;
    this.proposerVo.sumInsured = proposerDetails.sumInsured;
    this.proposerVo.customerId = proposerDetails.customerId;
    this.proposerVo.employeeId = proposerDetails.employeeId;
    this.proposerVo.employeeName = proposerDetails.employeeName;
    if (proposerDetails.policyStatusId === 7) {
      this.otpVerified = true;
      this.proposerVo.otp = proposerDetails.paymentDetails.otp;
      for (let i = 0; i < this.dtArray.length; i++) {
        this.dtArray[i]['Checked'] = true;
      }
    }
    if (proposerDetails.productAnswers !== undefined) {
      this.questionList = proposerDetails.productAnswers;
    }
    if (proposerDetails.communicationDetails !== undefined) {
      const proposerCommDtls = proposerDetails.communicationDetails;
      this.proposerVo.communicationDetails.address1 = proposerCommDtls.address1;
      this.proposerVo.communicationDetails.address2 = proposerCommDtls.address2;
      this.proposerVo.communicationDetails.address3 = proposerCommDtls.address3;
      this.proposerVo.communicationDetails.pincode = proposerCommDtls.pincodeLocality;
      this.proposerVo.communicationDetails.stateName = proposerCommDtls.stateName;
      this.proposerVo.communicationDetails.districtName =
        proposerCommDtls.districtName;
      this.proposerVo.communicationDetails.cityName = proposerCommDtls.cityName;
      this.proposerVo.communicationDetails.emailId = proposerCommDtls.emailId;
      this.proposerVo.communicationDetails.mobile = proposerCommDtls.mobile;
      this.proposerVo.communicationDetails.telephone =
        proposerCommDtls.telephone;
      this.proposerVo.communicationDetails.gstinNo = proposerCommDtls.gstinNo;
    }
    this.proposerVo.riskLocationDetails = proposerDetails.riskLocationDetails;
    if (proposerDetails.riskLocationDetails !== undefined) {
      const proposerRisDtls = proposerDetails.riskLocationDetails;
      this.proposerVo.riskLocationDetails.address1 = proposerRisDtls.address1;
      this.proposerVo.riskLocationDetails.address2 = proposerRisDtls.address2;
      this.proposerVo.riskLocationDetails.address3 = proposerRisDtls.address3;
      this.proposerVo.riskLocationDetails.pincode = proposerRisDtls.pincodeLocality;
      this.proposerVo.riskLocationDetails.stateName = proposerRisDtls.stateName;
      this.proposerVo.riskLocationDetails.districtName =
      proposerRisDtls.districtName;
      this.proposerVo.riskLocationDetails.cityName = proposerRisDtls.cityName;
      this.proposerVo.riskLocationDetails.emailId = proposerRisDtls.emailId;
      this.proposerVo.riskLocationDetails.mobile = proposerRisDtls.mobile;
      this.proposerVo.riskLocationDetails.telephone =
      proposerRisDtls.telephone;
      this.proposerVo.riskLocationDetails.gstinNo = proposerRisDtls.gstinNo;
    }
    if (proposerDetails.customerDetails !== undefined) {
      const customerDetails = data.customerDetails;
      this.proposerVo.customerId = customerDetails.customerId;
      this.proposerVo.employeeId = customerDetails.employeeId;
      this.proposerVo.customerDetails.firstName= data.customerDetails.firstName;
      this.proposerVo.customerDetails.lastName= data.customerDetails.lastName;
      this.bpTitleDesc = data.customerDetails.titleDescription;
      this.proposerVo.customerDetails.customerName = data.customerDetails.customerName;
    }
    if (proposerDetails.premiumDetails !== undefined) {
      const premiumDetails = proposerDetails.premiumDetails;
      this.premiumDetail.cgst = premiumDetails.totalCgst;
      this.premiumDetail.sgst = premiumDetails.totalSgst;
      this.premiumDetail.cessTax = premiumDetails.cessTax;
      this.premiumDetail.igst = premiumDetails.totalIgst;
      this.premiumDetail.netPremium = premiumDetails.totalNetPremium;
      this.premiumDetail.grossPremium = premiumDetails.totalGrossPremium;
    }
    this.adultList = [];
    this.childList = [];
    // adult and child list seperation.
    if (proposerDetails.beneficiaryList.length !== 0) {
      this.beneficiaryList = proposerDetails.beneficiaryList;
      for (let i = 0; i < this.beneficiaryList.length; i++) {
        if (this.beneficiaryList[i].nomineeType === 2) {
          this.childList.push(this.beneficiaryList[i]);
        }
        if (this.beneficiaryList[i].nomineeType === 1) {
          this.adultList.push(this.beneficiaryList[i]);
        }
      }
    } else {
      this.isHealth = false;
    }
    this.loadingmessage = '';
    this.isMappingDone = true;
  }
  openModal(id: string) {
    this.modalService.open(id);
  }


  closeModal(id: string) {
    this.modalService.close(id);
  }

  veriftyOTP() {
    this.otpValidateStatus = '';
    const allChecked = this.dtArray.every(data => data['Checked'] === true)
    if (allChecked && !this.otpVerified) {
      const pattern = '^[0-9]*$';
      if (this.proposerVo.otp !== undefined && this.proposerVo.otp !== '' && this.proposerVo.otp.length === 6 && this.proposerVo.otp.match(pattern)) {
        let otpReqDetails: Object = new Object();
        otpReqDetails['policyId'] = this.proposerVo.policyIdEncrypt;
        otpReqDetails['otpEnteredbyUser'] = this.proposerVo.otp;
        this.proposalServices
          .verifyPaymentOTP(JSON.stringify(otpReqDetails))
          .then(data => {
            if (data !== undefined) {
              if('OTP_NOT_VALIDATED' === data) {
                this.otpValidateStatus = 'OTP validation failed. Please try again.';
              } else if ('OTP_VALIDATED' === data) {
                this.otpFlag = true;
                this.otpVerified = true;
                this.otpValidateStatus = 'OTP validated successfully!.';
              }
            }
          })
          .catch(error => { });
      }
    }
  }

  LoadDeclration(){
    this.dtArray = [];
    this.selectService.fetchLookUpData('sahajDeclaration', null).then(data => {
          for (const val of data) {
            const obj: Object = new Object();
            obj['Description'] = val['declaration'];
            obj['id'] = val['id'];
            obj['checked'] = false;
            this.dtArray.push(obj);
          }
    });    
  }
}
