import { Component, OnInit, ViewChildren, ViewChild, ElementRef, HostListener, AfterViewInit, OnChanges } from '@angular/core';
import { EmitterService } from 'src/services/EmitterService';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { Router, ActivatedRoute } from '@angular/router';
import { UserDetailsVO } from 'src/dto/UserDetailsVO';
import { UserBranch } from 'src/dto/UserBranchVO';
import { UserRole } from 'src/dto/UserRoleVO';
import { UserDataVO } from 'src/dto/UserDataVO';
import { UserPortalVO } from 'src/dto/UserPortalVO';

import { UserManagementServices } from 'src/services/user-management.services';
import { EMAIL_EXPRESSION, USER_AFFINITY, USER_PROFILE_DATA, CODE, DESCRIPTION } from 'src/common/common.constants';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';


@Component({
    selector: 'app-create-user',
    templateUrl: './create-user.component.html'
})
export class CreateUserComponent implements OnInit, AfterViewInit, OnChanges {

    public userDetails: UserDetailsVO;
    public userData: UserDataVO = new UserDataVO();
    public userOption: String = 'single';
    public role = 'System Admin';
    public selectedRoleId;
    public enableSubmit: boolean;
    public cancelFlag = true;
    public hrLeftMargin;
    public hrWidth;
    public singleUserFlag: boolean;
    public multipleUsersFlag: boolean;
    public accoumt: string;
    public isEdit: boolean;
    public userId: string;
    public userIdEncrypt: string;
    public branchCode: string;
    public roleId: string;
    public updateUserFlag: boolean;
    public errorFlag: boolean;
    public disableSubmit = false;
    public branchName: string;
    public branchArea: string;
    public branchRegion: string;
    public branchStateId: string;
    public branchState: string;
    public branchDistrictId: string;
    public branchDistrict: string;
    public branchList: any[];
    public emailFormat = EMAIL_EXPRESSION;
    public branchGreyout = false;
    public emailResult = false;
    public userNameResult = false;
    public emailValidationMessage: any;
    public usernameValidationMessage: any;
    public emailAddress: any;
    public branchCodeValidationMessage: any;

    /*File Upload*/
    public fileupload: File;
    public flag: Boolean = false;
    public uploadedfilename: String;
    public response: any;
    public recordcount: String;
    public successrecords: String;
    public totalrecords: String;
    public extension: String;
    public isFile: Boolean = false;
    public isvalue1: Boolean = false;
    public greyRenewal: Boolean = false;
    public isdeletefile: Boolean = false;
    public customValidationMessage: any;
    public fileError: String;
    public formData: FormData = new FormData();
    private importFile: File;
    public listoffiles: FileList;
    public value: any;
    public objectFile: Object;
    public currentFileUpload: File;
    public errormessage: Boolean = false;
    public totalfiles: Array<any> = [];
    public filesToUpload: Array<File>;
    public isresponse: Boolean = false;
    public userNameTemporal: any;
    public emailTemporal: any;
    public bulkusersList: any;
    public affinityID: any;
    public affinityId: any;
    public roleName: any;
    public loadingmessage: any;
    public actionFailedFlag = false;
    public actionFailedMessage = '';
    public isValue: Boolean = false;
    // Variable to track submit action
    public submitInProgress = false;
    /********** */
    public accountLockedStatusArray: any;
    @ViewChildren(TextBoxComponent) private textFields: any;
    @ViewChildren(SelectComponent) private selectFields: any;
    @ViewChildren(DatePickerComponent) private dateFields: any;
    @ViewChild('formWrapper') formWrapperRef: ElementRef;
    @ViewChild('alignSectionCenter') alignSectionCenterRef: ElementRef;
    @HostListener('window:resize', ['$event'])
    onResize() {
        this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
        this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);
    }
    @HostListener('window:scroll', [])
    onWindowScroll() {
        this.scrollFunction();
    }
    constructor(private router: Router, private route: ActivatedRoute, private userManagementService: UserManagementServices) {
        this.initialize();
    }
    ngOnInit() {
        this.roleId = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleId'];
        this.roleName = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA))['roleName'];
        const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
        EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
        EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
        EmitterService.get('VALIDATE').subscribe(data => this.outSidevalidate());
        this.router.routeReuseStrategy.shouldReuseRoute = function () {
            return false;
        };
        this.prepareAccountLockedStatusArray();
    }
    ngAfterViewInit() {
        this.onResize();
        this.route.queryParams.subscribe(params => {

            this.isEdit = params['edit'];
            this.userId = params['userID'];
            this.userIdEncrypt = params['userIdEncrypt'];
            
            if (this.isEdit) {
                this.initialize();

                this.userManagementService.getUserDetails(this.userIdEncrypt).then(response => {
                    if (response.status === 200) {
                        const data = response.body;
                        this.userData.roleId = data['roleId'] ? data['roleId'] : null;
                        this.selectedRoleId = data['roleId'] ? data['roleId'] : null;
                        if (String(this.roleName) === 'Underwriter') {
                            if (String(data['Role ID']) === 'System Administrator' || String(data['Role ID']) === 'Affinity Administrator') {
                                this.router.navigateByUrl('welcome/(contentBody:viewUsers)');
                            }
                        } else if (String(this.roleId) === 'Affinity Administrator') {
                            if (String(data['roleName']) === 'System Administrator' || String(data['Role ID']) === 'Underwriter') {
                                this.router.navigateByUrl('welcome/(contentBody:viewUsers)');
                            }
                        }
                        if (this.selectedRoleId === 4) {
                            this.userData.branchId = data['branchId'] ? data['branchId'] : null;
                            this.userManagementService.getBranchDetails(this.userData.branchId).then(branchData => {
                                this.branchCode = branchData['branchCode'];
                            });
                            this.populateBranchDetails(this.userData.branchId);
                        }
                        if (this.selectedRoleId === 2) {
                            this.userData.officeLocation = data['officeLocation'] ? Number(data['officeLocation']) : null;
                        }
                        this.userData.emailAddress = data['emailAddress'] ? data['emailAddress'] : null;
                        this.emailAddress = this.userData.emailAddress;
                        this.userData.employeeCode = data['employeeCode'] ? data['employeeCode'] : null;
                        this.userData.isActive = data['isActive'];
                        this.userData.isLocked = data['isLocked'];
                        this.userData.name = data['name'] ? data['name'] : null;
                        this.userData.phoneNumber = data['phoneNumber'] ? data['phoneNumber'] : null;
                        this.userData.userName = data['userName'] ? data['userName'] : null;
                        this.userData.userId = data['userId'] ? data['userId'] : null;
                        this.userData.creationTimestamp = data['creationTimestamp'] ? data['creationTimestamp'] : null;
                        this.userData.expiryTimeStamp = data['expiryTimeStamp'] ? data['expiryTimeStamp'] : null;
                        this.userData.lastActivityDate = data['lastActivityDate'] ? data['lastActivityDate'] : null;
                        this.userData.lastUpdatedTimestamp = data['lastUpdatedTimestamp'] ? data['lastUpdatedTimestamp'] : null;
                        this.userData.userPortal = data['userPortal'] ? data['userPortal'] : null;
                        this.userData.password = data['password'] ? data['password'] : null;
                        this.enableSubmit = true;
                        this.validateUserFeasibility();
                        this.validateEmailFeasibility();
                    } else if (response.status > 299) {
                        this.actionFailedFlag = true;
                        this.actionFailedMessage = 'Could not retrieve user data';
                    }
                }
                ).catch(error => {
                });
            }
        });
    }
    ngOnChanges() {
        this.outSidevalidate();
    }


    public onRoleChange() {
        this.initialize();
        setTimeout(function () { this.outSidevalidate(); }.bind(this), 200);
    }

    public initialize() {
        this.userData = new UserDataVO();
        this.branchArea = '';
        this.branchCode = '';
        this.branchDistrict = '';
        this.branchDistrictId = '';
        this.branchList = [];
        this.branchName = '';
        this.branchRegion = '';
        this.branchState = '';
        this.branchStateId = '';
        this.enableSubmit = false;
        this.branchGreyout = false;
        this.emailResult = false;
        this.userNameResult = false;
        this.emailValidationMessage = '';
        this.usernameValidationMessage = '';
    }


    public createUser() {
        this.submitInProgress = true;
        if (this.enableSubmit && !this.disableSubmit) {
            this.userData.roleId = this.selectedRoleId;

            if (this.selectedRoleId === 3) {
                // Administrator
                delete this.userData.branchId;
                delete this.userData.officeLocation;
                this.userData.roleName = 'Affinity Administrator';
                this.userData.affinityname = JSON.parse(sessionStorage.getItem('USER_AFFINITY'))['name'];

            } else if (this.selectedRoleId === 4) {
                // Branch User
                delete this.userData.officeLocation;
                delete this.userData.phoneNumber;
                this.userData.roleName = 'Branch User';
                this.userData.affinityname = JSON.parse(sessionStorage.getItem('USER_AFFINITY'))['name'];

            } else if (this.selectedRoleId === 5) {
                // Call Center User
                delete this.userData.officeLocation;
                delete this.userData.branchId;
                delete this.userData.phoneNumber;
                this.userData.roleName = 'Call Center User';
                this.userData.affinityname = JSON.parse(sessionStorage.getItem('USER_AFFINITY'))['name'];
            } else if (this.selectedRoleId === 6) {
                // Sub Admin
                delete this.userData.branchId;
                delete this.userData.officeLocation;
                this.userData.roleName = 'Sub Admin';
                this.userData.affinityname = JSON.parse(sessionStorage.getItem('USER_AFFINITY'))['name'];

            } else if (this.selectedRoleId === 1) {
                // System Administrator
                delete this.userData.officeLocation;
                delete this.userData.branchId;
                delete this.userData.phoneNumber;
                delete this.userData.affinityname;
                delete this.userData.employeeCode;
                this.userData.roleName = 'System Administrator';

            } else if (this.selectedRoleId === 2) {
                // Underwriter
                delete this.userData.branchId;
                delete this.userData.phoneNumber;
                delete this.userData.affinityname;
                this.userData.roleName = 'Underwriter';
            }

            if (!this.isEdit) {
                const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
                delete this.userData.userId;
                delete this.userData.creationTimestamp;
                delete this.userData.expiryTimeStamp;
                delete this.userData.lastActivityDate;
                delete this.userData.lastUpdatedTimestamp;
                this.userData.affinityId = affinity.id;
                this.userData.affinityname = affinity.name;
                const userPortal = new UserPortalVO();
                userPortal.portalId = '1';
                userPortal.landingUrl = '/hello';
                this.userData.userPortal.push(userPortal);
                this.userManagementService.createUser(this.userData).then(data => {
                    
                   if(data.userId===undefined)
                    {
                        console.log("error while saving the User Data");
                        this.openActionFailDialog("User Cannot be created");
                    }
					else{
                    this.submitInProgress = false;

                    // User-Affinity Mapping
                    const idList = [data.userId];
                    this.userManagementService.mapUserToAffinity(idList, this.userData.affinityId).then(affinityData => {
                        console.log('User affinity has been mapped!');
                    })
                        .catch(error => {
                            this.actionFailedFlag = false;
                            this.actionFailedMessage = 'Error in mapping affinity to user. Please Try again';
                        });

                    this.updateUserFlag = false;
                    this.singleUserFlag = true;
                    this.moveToTop();
                    this.enableSubmit = false;
                    this.disableSubmit = true;
                    this.cancelFlag = false;
					}
                })
                    .catch(error => {
                        this.actionFailedFlag = false;
                        this.actionFailedMessage = 'User cannot be created. Please try again';
                    });
            } else {
                if (this.isEdit) {
                    if (this.isEdit) {
                        this.userManagementService.updateUser(this.userData).then(data => {
                            this.submitInProgress = false;
                            this.updateUserFlag = true;
                            this.singleUserFlag = false;
                            this.moveToTop();
                            this.enableSubmit = false;
                            this.disableSubmit = true;
                            this.cancelFlag = false;
                        }).catch(error => {
                            this.actionFailedFlag = false;
                            this.actionFailedMessage = 'User details could not be updated. Please try again';
                        });
                    }
                }
            }
        }
    }

    public cancel() {
        if (!(this.singleUserFlag || this.updateUserFlag)) {
            this.router.navigateByUrl(this.router.url);
            this.moveToTop();
        }
    }


    moveToTop() {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
    }
    scrollFunction() {
        if (
            document.body.scrollTop > 20 ||
            document.documentElement.scrollTop > 20
        ) {
            document.getElementById('scrollbtn').style.display = 'block';
        } else {
            document.getElementById('scrollbtn').style.display = 'none';
        }
    }
    public navigate(path: string) {
        this.router.navigateByUrl(path);
    }

    validateUserFeasibility() {
        const _this = this;
        this.usernameValidationMessage = '';
        if (this.userData.userName && !this.isEdit) {
            if (this.userNameTemporal) {
                clearTimeout(this.userNameTemporal);
            }
            this.userNameTemporal = setTimeout(function () {
                _this.userManagementService.checkUserName(_this.userData.userName).then(data => {
                    _this.userNameResult = !Boolean(data);
                    _this.outSidevalidate();
                    if (!_this.userNameResult) {
                        this.usernameValidationMessage = 'User Name already exists';
                    } else {
                        this.usernameValidationMessage = '';
                    }
                }).catch(error => {
                    this.userNameResult = false;
                    this.usernameValidationMessage = 'Error occured in fetching Username status';
                    _this.outSidevalidate();
                });
            }.bind(_this), 500);
        }
    }

    validateEmailFeasibility() {
        const _this = this;
        this.emailValidationMessage = '';
        if (this.userData.emailAddress && !this.isEdit) {
            if (this.emailTemporal) {
                clearTimeout(this.emailTemporal);
            }
            this.emailTemporal = setTimeout(function () {
                _this.userManagementService.checkEmail(_this.userData.emailAddress).then(data => {
                    _this.emailResult = !Boolean(data);
                    if (!_this.emailResult) {
                        this.emailValidationMessage = 'Email ID already exists';
                    } else {
                        this.emailValidationMessage = '';
                    }
                    _this.outSidevalidate();
                }).catch(error => {
                    _this.emailResult = false;
                    this.emailValidationMessage = 'Error occured in fetching Email status';
                });
            }.bind(_this), 500);
        }
        if (this.userData.emailAddress && this.isEdit) {
            if (this.emailTemporal) {
                clearTimeout(this.emailTemporal);
            }
            if (String(this.userData.emailAddress) === String(this.emailAddress)) {
                this.emailValidationMessage = '';
                this.emailResult = true;
                return;
            }
            this.emailTemporal = setTimeout(function () {
                _this.userManagementService.checkEmail(_this.userData.emailAddress).then(data => {
                    _this.emailResult = !Boolean(data);
                    _this.outSidevalidate();
                    if (!_this.emailResult) {
                        this.emailValidationMessage = 'Email ID already exists';
                    } else {
                        this.emailValidationMessage = '';
                    }
                }).catch(error => {
                    _this.emailResult = false;
                    this.emailValidationMessage = 'Error occured in fetching Email status';
                });
            }.bind(_this), 500);
        }
    }


    private outSidevalidate() {
        let textResult: any = false;
        let selectResult: any = false;
        let dateResult: any = false;
        let hasTextField: Boolean = false;
        let hasSelectField: Boolean = false;
        let hasDateField: Boolean = false;
        let selectFlag = true;
        let dateFlag = true;
        for (const textField of this.textFields['_results']) {
            hasTextField = true;
            const input: TextBoxComponent = textField;
            textResult = input.onBlurMethod(false);
            if (!textResult) {
                break;
            }
        }
        for (const selectField of this.selectFields['_results']) {
            hasSelectField = true;
            const input: SelectComponent = selectField;
            selectResult = input.onBlurMethod(false);
            if (!selectResult) {
                selectFlag = false;
            }
        }
        for (const dateField of this.dateFields['_results']) {
            hasDateField = true;
            const input: DatePickerComponent = dateField;
            dateResult = input.onBlurMethod(false);
            if (!dateResult) {
                dateFlag = false;
            }
        }
        const result: any =
            (textResult || !hasTextField) &&
            (selectFlag || !hasSelectField) &&
            (dateFlag || !hasDateField) &&
            this.emailResult && (this.userNameResult || this.isEdit);
        if (result) {
            this.enableSubmit = true;
            return true;
        } else {
            this.enableSubmit = false;
            return false;
        }
    }

    public getBranchList() {
        this.branchCodeValidationMessage = '';
        if (this.branchCode.length < 3) {
            this.branchList = [];
        }
        if (this.branchCode.length > 2) {
            this.userManagementService.getBranches(this.branchCode).then(data => {
                this.branchList = [];
                if (data.length === 0) {
                    this.branchArea = '';
                    this.branchName = '';
                    this.branchRegion = '';
                    this.userData.branchId = '';
                    this.branchDistrictId = '';
                    this.branchStateId = '';
                    this.branchState = '';
                    this.branchDistrict = '';
                    this.branchGreyout = false;
                    this.branchCodeValidationMessage = 'Branch Code does not exist';
                }
                for (let i = 0; i < data.length; i++) {
                    const branch = new Object();
                    branch['Code'] = data[i]['branchID'];
                    branch['Description'] = data[i]['branchCode'];
                    if (this.branchCode === branch['Description']) {
                        this.populateBranchDetails(branch['Code']);
                        this.branchList = [];
                        break;
                    } else {
                        this.branchArea = '';
                        this.branchName = '';
                        this.branchRegion = '';
                        this.userData.branchId = '';
                        this.branchDistrictId = '';
                        this.branchStateId = '';
                        this.branchState = '';
                        this.branchDistrict = '';
                        this.branchGreyout = false;
                    }
                    this.branchList.push(branch);
                }
            });
        }
        if (this.branchCode.length > 3) {
            const id = this.userManagementService.getBranchIdFromCode(this.branchCode);
            if (id) {
                this.populateBranchDetails(id);
            }
        } else {
            const _this = this;
            setTimeout(function () {
                _this.outSidevalidate();
            }, 500);
        }
    }

    public populateBranchDetails(branchId) {
        this.userManagementService.getBranchDetails(branchId).then(data => {
            if (data) {
                this.branchArea = data['branchArea'];
                this.branchName = data['branchName'];
                this.branchRegion = data['branchRegion'];
                this.userData.branchId = data['branchID'];
                this.branchDistrictId = data['branchDistrictId'];
                this.branchStateId = data['branchStateId'];
                this.userManagementService.getStateDistrictData(this.branchStateId, this.branchDistrictId).then(branchData => {
                    this.branchState = branchData['stateName'];
                    this.branchDistrict = branchData['districts'][0]['districtName'];
                    this.branchGreyout = true;
                    const _this = this;
                    setTimeout(function () {
                        _this.outSidevalidate();
                    }, 500);
                });
            } else {
                this.branchArea = '';
                this.branchName = '';
                this.branchRegion = '';
                this.userData.branchId = '';
                this.branchDistrictId = '';
                this.branchStateId = '';
                this.branchState = '';
                this.branchDistrict = '';
                this.branchGreyout = false;
            }
        });
    }

    /*FILE UPLOAD*/
    public fileSelectionEvent(fileInput: any) {
        this.isFile = true;
        this.isValue = true;
        this.isdeletefile = true;
        const fileObj = new Object();
        this.formData = new FormData();
        fileObj['fileValues'] = fileInput.target.files;
        const fileList: FileList = fileInput.target.files;
        this.listoffiles = fileList;
        this.objectFile = fileObj;
        const filename: String = fileInput.target.files[0].name;
        this.uploadedfilename = fileInput.target.files[0].name;
        const fileNameSplit = filename.split('.');
        fileObj['fileName'] = filename.split('.')[0];
        fileObj['fileExtension'] = filename.split('.')[fileNameSplit.length - 1];
        this.extension = fileObj['fileExtension'];
        this.customValidationMessage = '';
    }
    public deleteFile() {
        this.isValue = false;
        this.isdeletefile = false;
        this.isFile = false;
        this.uploadedfilename = null;
        this.customValidationMessage = '';
        this.totalfiles = [];
        this.selectedRoleId = undefined;
    }

    public submitUser() {
        if (this.extension === 'xlsx' || this.extension === 'xls') {
            if (this.listoffiles.length > 0) {
                this.importFile = this.listoffiles[0];
            }
            if (this.importFile) {
                this.formData.append('file', this.importFile);
            }
            this.loadingmessage = 'Please wait...';
            this.greyRenewal = true;
            this.isFile = true;
            this.userManagementService.saveData(this.formData)
                .then(data => {
                    this.loadingmessage = '';
                    const recordCountResponse = data.headers.get('RecordCount');
                    if (recordCountResponse === 'INVALID-FILE') {
                        this.customValidationMessage = 'Uploaded User Template is not in the right format. Please check';
                        this.isresponse = false;
                        this.greyRenewal = false;
                    } else if (recordCountResponse === 'INVALID-DATA') {
                        this.customValidationMessage = 'Uploaded User Data is not in the right format. Please check';
                        this.isresponse = false;
                        this.greyRenewal = false;
                    }  else {
                    const userId: String = data.headers.get('idList');
                    this.bulkusersList = userId;
                    this.response = data;
                    this.recordcount = recordCountResponse;
                    this.successrecords = this.recordcount.split('~')[0];
                    this.totalrecords = this.recordcount.split('~')[1];
                    this.bulkusersList = JSON.parse(this.recordcount.split('~')[2]);
                    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
                    this.affinityID = affinity.id;
                    this.userManagementService.mapBulkUsersToAffinity(this.bulkusersList, this.affinityID).then(userData => {
                    }).catch(error => {
                    });
                    if (Number(this.successrecords) === 0) {
                        this.errormessage = true;
                    } else {
                        this.errormessage = false;
                    }
                    this.isFile = true;
                    this.isresponse = true;
                    this.customValidationMessage = '';
                    this.totalfiles.push(this.objectFile);
                    this.greyRenewal = true;
                }
                })
                .catch(error => {
                    this.loadingmessage = '';
                    const data = error;
                    if (data['statusText'] === 'Not Acceptable') {
                        this.customValidationMessage = 'Uploaded user data is not in the right format. Please check';
                        this.isresponse = false;
                        this.greyRenewal = false;
                    }
                   // this.openActionFailDialog('Template could not be downloaded');
                //    this.loadingmessage = 'Please wait...';
                //    setTimeout(() => {
                //      this.serviceDownMessage();
                //    }, 10000);
                });

        } else {
            this.customValidationMessage = 'Please provide file in .xls, .xlsx format';
            this.isresponse = false;
            this.greyRenewal = false;
        }



        this.moveToTop();


    }

    public downloadTemplate() {

        this.loadingmessage = 'Preparing Document...';
        const parsedResponse = this.response.blob();
        const blob = new Blob([parsedResponse], {
            type: 'application/octet-stream'

        });
        if (this.extension === 'xlsx') {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'UserCreationTemplate.xlsx');
            this.customValidationMessage = '';
            this.loadingmessage = '';
        } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'UserCreationTemplate.xlsx';
            document.body.appendChild(a);
            a.click();
            this.customValidationMessage = '';
            this.loadingmessage = '';
            document.body.removeChild(a);
        }
    } else  if (this.extension === 'xls') {
        if (window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveBlob(blob, 'UserCreationTemplate.xls');
            this.customValidationMessage = '';
            this.loadingmessage = '';
        } else {
            const a = window.document.createElement('a');
            a.href = window.URL.createObjectURL(blob);
            a.download = 'UserCreationTemplate.xls';
            document.body.appendChild(a);
            a.click();
            this.customValidationMessage = '';
            this.loadingmessage = '';
            document.body.removeChild(a);
        }
    }



    }

    templateDownload() {
        this.loadingmessage = 'Preparing Document...';
        // this.type = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        this.userManagementService.tempDownload().then(data => {
            // this.downLoadFile(data, this.type);
            const parsedResponse = data.blob();
            const blob = new Blob([parsedResponse], {
                type: 'application/xlsx'

            });
            const blob1 = new Blob([parsedResponse], {
                type: 'application/xls'

            });
            const downloadExtension: String = blob['type'].split('/')[1];
            const downloadExtension1: String = blob1['type'].split('/')[1];
            if (downloadExtension === 'xlsx') {
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob, 'BulkUserCreationTemplate.xlsx');
                this.customValidationMessage = '';
                this.loadingmessage = '';
            } else {
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(blob);
                a.download = 'BulkUserCreationTemplate.xlsx';
                document.body.appendChild(a);
                a.click();
                this.customValidationMessage = '';
                this.loadingmessage = '';
                document.body.removeChild(a);
            }
        } else   if (downloadExtension1 === 'xls') {
            if (window.navigator.msSaveOrOpenBlob) {
                window.navigator.msSaveBlob(blob1, 'BulkUserCreationTemplate.xls');
                this.customValidationMessage = '';
                this.loadingmessage = '';
            } else {
                const a = window.document.createElement('a');
                a.href = window.URL.createObjectURL(blob1);
                a.download = 'BulkUserCreationTemplate.xls';
                document.body.appendChild(a);
                a.click();
                this.customValidationMessage = '';
                this.loadingmessage = '';
                document.body.removeChild(a);
            }
        }
        })
            .catch(error => {
              //  this.openActionFailDialog('Template could not be downloaded');
              this.loadingmessage = 'Please wait...';
              setTimeout(() => {
                this.serviceDownMessage();
              }, 10000);
            });
    }


      public openActionFailDialog(message: any) {
        this.moveToTop();
        this.actionFailedFlag = true;
        this.actionFailedMessage = message;
      }

    public userOptionChange() {
        this.initialize();
        this.selectedRoleId = undefined;
        this.deleteFile();
    }
    public clearBranchList() {
        this.branchList = [];
    }
    public closeActionFailDialog() {
        this.actionFailedFlag = false;
        this.actionFailedMessage = '';
        this.deleteFile();
    }

    public prepareAccountLockedStatusArray() {
        this.accountLockedStatusArray = [];
        const code1: Object = new Object();
        code1[CODE] = true;
        code1[DESCRIPTION] = 'Locked';
        const code2: Object = new Object();
        code2[CODE] = false;
        code2[DESCRIPTION] = 'Unlocked';
        this.accountLockedStatusArray.push(code1);
        this.accountLockedStatusArray.push(code2);
      }

      public serviceDownMessage() {
        setTimeout(
          function () {
            EmitterService.get('SERVICE_DOWN').emit(true);
          }
        );
        this.router.navigateByUrl('');
      }
}
