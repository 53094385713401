import { AppConfig } from '../config/app.config';
import { Http, Response, RequestOptions } from '@angular/http';
import 'rxjs/add/operator/toPromise';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';
import { Injectable } from '@angular/core';
import { REQUEST_HEADER, META_URL } from 'src/common/common.constants';
import { HEADER, HEADER_PRINT } from './../common/common.constants';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';


@Injectable()
export class PostalService {
  hostUrl: string = this.config.getConfig('productHost');
  constructor(private httpClient: HttpClient, private http: Http,
    private config: AppConfig) {

  }

  fetchPostalDetailsByZip(zipcode: String) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const request: Object = new Object();
    const url = this.hostUrl + 'api/reference/regions/' + zipcode;
    return this.httpClient.get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);

  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
