import { Component, OnInit, EventEmitter, Output, ViewChild, ElementRef, HostListener, AfterViewInit, OnChanges, ɵConsole } from '@angular/core';
import { EmitterService } from 'src/services/EmitterService';
import { GridComponent } from 'src/grid/grid.component';
import {
  DESCRIPTION,
  CODE,
  USER_NAME,
  NAME,
  BRANCH,
  BRANCH_NAME,
  BRANCH_CODE,
  USER_AFFINITY,
  AFFINITY_ID,
  PRODUCT_CODE
} from 'src/common/common.constants';
import { ProposalSearchVO } from 'src/dto/ProposalSearchVO';
import { TextBoxComponent } from 'src/textbox/text-box.component';
import { SelectComponent } from 'src/select/select-component';
import { DatePickerComponent } from 'src/datepicker/datepicker.component';
import { Router, ActivatedRoute } from '@angular/router';
import { ProductServices } from 'src/services/product.service';



@Component({
  selector: 'app-plan-assignment',
  templateUrl: './planassignment.html',
  providers: [ProductServices]
})
export class PlanAssignmentComponent implements OnInit, AfterViewInit {


  public searchArray: Array<Object> = Array<Object>();
  public userData: Array<Object> = Array<Object>();
  public searchBranchArray: Array<Object> = Array<Object>();
  public columnsData: Array<Object> = Array<Object>();
  public columnsbranchData: Array<Object> = Array<Object>();
  public actionColumnsData: Array<Object> = Array<Object>();
  public actionColumnsBranchData: Array<Object> = Array<Object>();
  public projectData: Array<Object> = Array<Object>();
  public display = 'none';
  public planOption: String = 'single';
  public description: String = '--Select--';
  public productPlan: Boolean = false;
  public singleuserproduct: Boolean = false;
  public branchuserproduct: Boolean = false;
  public showPlan: Boolean = false;
  public greyRenewal: Boolean = false;
  public totalRows: any;
  public totalNumberOfRecords: Number;
  public count: any;
  public issave: Boolean = false;
  public issaveProducts: Boolean = false;
  public enableHealthSave: Boolean = false;
  public enableHomeSave: Boolean = false;
  public enableShopSave: Boolean = false;
  public enableBranchSave: Boolean = false;
  public healthChecked: Boolean = false;
  public planChecked: Boolean = false;
  public planProductData: Array<Object> = Array<Object>();
  public planProductBranchData: Array<Object> = Array<Object>();
  public selectAllHealth = [];
  public selectAllHome = [];
  public selectAllShop = [];
  public selectAllBranchHealth = [];
  public selectAllBranchHome = [];
  public selectAllBranchShop = [];
  public selectCount = 0;
  public totalCount = 0;
  public plans: any;
  public affinityid: any;



  public userID: String;
  public savedProducts: Object;
  public userName: String;
  public name: String;
  public branch: String;
  public branchName: String;
  public branchCode: String;
  public loadingmessage = '';
  public proposerSearchVo: ProposalSearchVO = new ProposalSearchVO();
  public userDetails: Array<Object> = Array<Object>();
  public branchDetails: Array<Object> = Array<Object>();
  public productName1: String;
  public productName2: String;
  public productbusiness1: String;
  public branchId: any;
  public userBranchId: any;
  public userIdList = [];
  public healthDetails = [];
  public hrLeftMargin;
  public hrWidth;


  public planData: any;
  public planDataBranch: any;
  public actionFailedFlag = false;
  public actionFailedMessage = '';
  @Output() public changeevent = new EventEmitter<Object>();




  // @ViewChild('contentBody') contentBodyRef: ElementRef;
  /*Search Pagination*/
  @ViewChild('grid') gridComponent: GridComponent;
  // @ViewChild('formWrapper') formWrapperRef: ElementRef;
  //   @ViewChild('alignSectionCenter') alignSectionCenterRef: ElementRef;

  @ViewChild('formWrapper') formWrapperRef: ElementRef;

  @HostListener('window:resize', ['$event'])
  @ViewChild('contentBody') contentBodyView: ElementRef;
  @HostListener('window:resize', ['$event'])
  // onResize() {
  //   this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
  //   this.hrLeftMargin = -((this.hrWidth - this.contentBodyView.nativeElement.clientWidth) / 2);

  // }

  onResize() {
    this.hrWidth = window.document.getElementsByTagName('body').item(0).offsetWidth;
    this.hrLeftMargin = -((this.hrWidth - this.formWrapperRef.nativeElement.clientWidth) / 2);
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.scrollFunction();
  }


  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private productService: ProductServices
  ) { }



  scrollFunction() {
    if (
      document.body.scrollTop > 20 ||
      document.documentElement.scrollTop > 20
    ) {
      document.getElementById('scrollbtn').style.display = 'block';
    } else {
      document.getElementById('scrollbtn').style.display = 'none';
    }
  }
  moveToTop() {
    document.body.scrollTop = document.documentElement.scrollTop = 0;
  }

  ngAfterViewInit() {
    this.onResize();
  }

  ngOnInit() {
    // this.loadingmessage = 'Please wait...';
    this.prepareColumnsData();
    this.branchColumnsData();
    this.prepareActionColumns();
    this.branchActionColumns();
    this.userSearchArray();
    this.branchSearchArray();
    // this.singleUserData();
    // this.getPlanDetails(0, 10);
    // this.getBranchDetails(0, 10);
    const selectedAffinity = sessionStorage.getItem('LOGGEDIN_AFFINITY');
    EmitterService.get('ENABLE_MENU').emit(selectedAffinity);
    EmitterService.get('DISABLE_FOOTER').emit(selectedAffinity);
   this.affinityid = JSON.parse(sessionStorage.getItem(USER_AFFINITY))['id'];
    this.planOptionChange();
  }


  public planOptionChange() {
    if (this.planOption === 'single') {
      // this.proposerSearchVo.searchId = this.searchArray[0]['Code'];
      this.description = '--Select--';
      this.proposerSearchVo.searchInput = '';
      this.getPlanDetails(this.count, this.totalNumberOfRecords);
    } else {
      // this.proposerSearchVo.searchBranchId = this.searchBranchArray[0]['Code'];
      this.description = '--Select--';
      this.proposerSearchVo.searchBranchInput = '';
      this.getBranchDetails(this.count, this.totalNumberOfRecords);
    }
  }

  prepareColumnsData() {
    const col1: Object = new Object();
    col1['colName'] = 'Username';
    this.columnsData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Name';
    this.columnsData.push(col2);
    const col3: Object = new Object();
    col3['colName'] = 'Branch';
    this.columnsData.push(col3);
  }


  branchColumnsData() {
    const col1: Object = new Object();
    col1['colName'] = 'Branch';
    this.columnsbranchData.push(col1);
    const col2: Object = new Object();
    col2['colName'] = 'Branch Code';
    this.columnsbranchData.push(col2);
  }

  public prepareActionColumns() {
    this.actionColumnsData.push('Assign Products and Plans');
  }

  public branchActionColumns() {
    this.actionColumnsBranchData.push('Assign Products and Plans');
  }

  public userSearchArray() {
    const code0: Object = new Object();
    code0[CODE] = USER_NAME;
    code0[DESCRIPTION] = 'Username';
    const code2: Object = new Object();
    code2[CODE] = NAME;
    code2[DESCRIPTION] = 'Name';
    const code3: Object = new Object();
    code3[CODE] = BRANCH_CODE;
    code3[DESCRIPTION] = 'Branch';
    this.searchArray.push(code0);
    this.searchArray.push(code2);
    this.searchArray.push(code3);
  }

  public branchSearchArray() {
    const code0: Object = new Object();
    code0[CODE] = BRANCH_NAME;
    code0[DESCRIPTION] = 'Branch';
    const code1: Object = new Object();
    code1[CODE] = BRANCH_CODE;
    code1[DESCRIPTION] = 'Branch Code';
    this.searchBranchArray.push(code0);
    this.searchBranchArray.push(code1);
  }




  public productPlanBranchData(data) {
    this.planDataBranch = [];
    this.planProductBranchData = [];
    this.branchuserproduct = true;
    // const url = 'welcome/(contentBody:planAssignment)=' + data['BranchName'];
    this.branchName = data['Branch'];
    this.branchCode = data['Branch Code'];
    this.branchId = data['branchID'];
    // this.router.navigateByUrl(url);
    this.moveToTop();
    this.productPlan = true;
    // tslint:disable-next-line:no-shadowed-variable
    this.productService.getUserProducts().then(data => {


      this.planDataBranch = data;

       const affinityid = JSON.parse(sessionStorage.getItem(USER_AFFINITY))['id'];


      this.productService.getSavedBranchProducts(this.branchId,  affinityid).then(prodBranchData => {


        this.planProductBranchData = prodBranchData;

        // this.enableBranchSave = true;

        if (this.planProductBranchData.length === 0) {
          const healthBranchProducts = this.planDataBranch['healthProductList'];
          for (const healthBranchProduct of healthBranchProducts) {
            this.selectAllBranchHealth.push(true);

            healthBranchProduct['Checked'] = true;
            for (const policyTypeBranch of healthBranchProduct['policyType']) {
              for (const planTypeBranch of policyTypeBranch['planType']) {
                planTypeBranch['Checked'] = true;
              }
            }
          }

          const homeBranchProducts = this.planDataBranch['businessProductHomeList'];
          for (const homeBranchProduct of homeBranchProducts) {
            this.selectAllBranchHome.push(true);
            homeBranchProduct['Checked'] = true;
            for (const policyTypeBranch of homeBranchProduct['policyType']) {
              for (const planTypeBranch of policyTypeBranch['planType']) {
                planTypeBranch['Checked'] = true;
              }
            }
          }

          const shopBranchProducts = this.planDataBranch['businessProductShopList'];
          for (const shopBranchProduct of shopBranchProducts) {
            this.selectAllBranchShop.push(true);
            shopBranchProduct['Checked'] = true;
            for (const policyTypeBranch of shopBranchProduct['policyType']) {
              for (const planTypeBranch of policyTypeBranch['planType']) {
                planTypeBranch['Checked'] = true;
              }
            }
          }

        } else {
          for (const userPlanBranch of this.planProductBranchData) {
            for (const planBranch of userPlanBranch['branchPlanResourceList']) {
              for (const healthBranchProduct of this.planDataBranch['healthProductList']) {
                for (const policyTypeBranch of healthBranchProduct['policyType']) {
                  for (const planTypeBranch of policyTypeBranch['planType']) {
                    if (String(planTypeBranch['planTypeName']) === String(planBranch['planName'])) {
                      if (String(healthBranchProduct['productId']) === String(planBranch['productID'])) {
                        healthBranchProduct['Checked'] = true;
                        planTypeBranch['Checked'] = true;
                        // this.enableBranchSave = true;
                        this.enableHealthSave = true;
                      }
                    }
                  }
                }
              }

              for (const homeBranchProduct of this.planDataBranch['businessProductHomeList']) {
                for (const policyTypeBranch of homeBranchProduct['policyType']) {
                  for (const planTypeBranch of policyTypeBranch['planType']) {
                    if (String(planTypeBranch['planTypeName']) === String(planBranch['planName'])) {
                      if (String(homeBranchProduct['productId']) === String(planBranch['productID'])) {
                        homeBranchProduct['Checked'] = true;
                        planTypeBranch['Checked'] = true;
                        this.enableHomeSave = true;
                      }
                    }
                  }
                }
              }

              for (const shopBranchProduct of this.planDataBranch['businessProductShopList']) {
                for (const policyTypeBranch of shopBranchProduct['policyType']) {
                  for (const planTypeBranch of policyTypeBranch['planType']) {
                    if (String(planTypeBranch['planTypeName']) === String(planBranch['planName'])) {
                      if (String(shopBranchProduct['productId']) === String(planBranch['productID'])) {
                        shopBranchProduct['Checked'] = true;
                        planTypeBranch['Checked'] = true;
                        this.enableShopSave = true;
                        // this.enableBranchSave = true;
                      }
                    }
                  }
                }
              }

            }
          }

          this.selectAllBranchHealth = [];
          this.selectAllBranchHome = [];
          this.selectAllBranchShop = [];
          for (const product of this.planDataBranch['healthProductList']) {
            let selectedHealthBranchCount = 0;
            let totalHealthBranchCount = 0;
            for (const policy of product['policyType'][0]['planType']) {
              totalHealthBranchCount++;
              if (policy['Checked']) {
                selectedHealthBranchCount++;
              }
            }
            if (selectedHealthBranchCount === totalHealthBranchCount) {
              this.selectAllBranchHealth.push(true);
            } else {
              this.selectAllBranchHealth.push(false);
            }
          }
          for (const product of this.planDataBranch['businessProductHomeList']) {
            let selectedHomeBranchCount = 0;
            let totalHomeBranchCount = 0;
            for (const policy of product['policyType'][0]['planType']) {
              totalHomeBranchCount++;
              if (policy['Checked']) {
                selectedHomeBranchCount++;
              }
            }
            if (selectedHomeBranchCount === totalHomeBranchCount) {
              this.selectAllBranchHome.push(true);
            } else {
              this.selectAllBranchHome.push(false);
            }
          }
          for (const product of this.planDataBranch['businessProductShopList']) {
            let selectedShopBranchCount = 0;
            let totalShopBranchCount = 0;
            for (const policy of product['policyType'][0]['planType']) {
              totalShopBranchCount++;
              if (policy['Checked']) {
                selectedShopBranchCount++;
              }
            }
            if (selectedShopBranchCount === totalShopBranchCount) {
              this.selectAllBranchShop.push(true);
            } else {
              this.selectAllBranchShop.push(false);
            }
          }

        }
      }).catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);    
    });

  }



  public productPlanData(data) {
    this.planData = [];
    this.planProductData = [];
    this.singleuserproduct = true;
    this.userName = data['Username'];
    this.name = data['Name'];
    this.branch = data['Branch'];
    this.userBranchId = data['BranchId'];
    this.userID = data['User ID'];
    // this.router.navigateByUrl(url);
    this.moveToTop();
    this.productPlan = true;

    this.productService.getUserIdlist(this.userBranchId).then(userData => {

      // this.userIdList.push(userData) ;
      this.userIdList = userData;
    })
      .catch(error => {

        //  this.openActionFailDialog('User list could not be fetched');
      });
    this.productService.getUserProducts().then(valdata => {

      this.planData = valdata;
      const affinityid = JSON.parse(sessionStorage.getItem(USER_AFFINITY))['id'];
      this.productService.getSavedProducts(this.userID, affinityid).then(prodData => {

        this.planProductData = prodData;

        if (this.planProductData.length === 0) {
          const healthProducts = this.planData['healthProductList'];
          for (const healthProduct of healthProducts) {
            this.selectAllHealth.push(true);

            healthProduct['Checked'] = true;
            for (const policyType of healthProduct['policyType']) {
              for (const planType of policyType['planType']) {
                planType['Checked'] = true;
              }
            }
          }

          const homeProducts = this.planData['businessProductHomeList'];
          for (const homeProduct of homeProducts) {
            this.selectAllHome.push(true);
            homeProduct['Checked'] = true;
            for (const policyType of homeProduct['policyType']) {
              for (const planType of policyType['planType']) {
                planType['Checked'] = true;
              }
            }
          }

          const shopProducts = this.planData['businessProductShopList'];
          for (const shopProduct of shopProducts) {
            this.selectAllShop.push(true);
            shopProduct['Checked'] = true;
            for (const policyType of shopProduct['policyType']) {
              for (const planType of policyType['planType']) {
                planType['Checked'] = true;
              }
            }
          }

        } else {
          // this.selectAllHealth = [];
          // this.selectAllHome = [];
          // this.selectAllShop = [];
          for (let i = 0; i <= this.userIdList.length; i++) {
            if (String(this.userIdList[i]) === String(this.userID)) {
              if (this.planProductData.length === 0) {
                // this.planProductData = [];
                for (const userPlanBranch of this.planProductBranchData) {
                  for (const planBranch of userPlanBranch['branchPlanResourceList']) {
                    for (const healthProduct of this.planData['healthProductList']) {
                      for (const policyType of healthProduct['policyType']) {

                        for (const planType of policyType['planType']) {

                          if (String(planType['planTypeName']) === String(planBranch['planName'])) {
                            if (String(healthProduct['productId']) === String(planBranch['productID'])) {
                              healthProduct['Checked'] = true;
                              planType['Checked'] = true;

                            }
                          }
                        }


                      }

                    }
                    for (const homeProduct of this.planData['businessProductHomeList']) {
                      for (const policyType of homeProduct['policyType']) {

                        for (const planType of policyType['planType']) {

                          if (String(planType['planTypeName']) === String(planBranch['planName'])) {
                            if (String(homeProduct['productId']) === String(planBranch['productID'])) {
                              homeProduct['Checked'] = true;
                              planType['Checked'] = true;

                            }
                          }
                        }

                      }

                    }
                    for (const shopProduct of this.planData['businessProductShopList']) {
                      for (const policyType of shopProduct['policyType']) {

                        for (const planType of policyType['planType']) {

                          if (String(planType['planTypeName']) === String(planBranch['planName'])) {
                            if (String(shopProduct['productId']) === String(planBranch['productID'])) {
                              shopProduct['Checked'] = true;
                              planType['Checked'] = true;

                            }
                          }
                        }

                      }

                    }
                  }
                }
              } else {
                for (const userPlan of this.planProductData) {
                  for (const plan of userPlan['userPlanResourceList']) {
                    for (const healthProduct of this.planData['healthProductList']) {
                      for (const policyType of healthProduct['policyType']) {

                        for (const planType of policyType['planType']) {
                          // totalHealthCount1++;
                          if (String(planType['planTypeName']) === String(plan['planName'])) {
                            if (String(healthProduct['productId']) === String(plan['productID'])) {
                              healthProduct['Checked'] = true;
                              planType['Checked'] = true;
                              this.selectCount++;
                              // selectedtHealthCount1++;
                              // this.enableSave = true;
                              // this.enableHealthSave = true;
                            }
                          }
                        }

                      }
                      // if (selectedtHealthCount1 === totalHealthCount1) {
                      //   this.selectAllHealth.push(true);
                      // } else {
                      //   this.selectAllHealth.push(false);
                      // }
                    }

                    for (const homeProduct of this.planData['businessProductHomeList']) {
                      for (const policyType of homeProduct['policyType']) {

                        for (const planType of policyType['planType']) {
                          // totalHomeCount1++;
                          if (String(planType['planTypeName']) === String(plan['planName'])) {
                            if (String(homeProduct['productId']) === String(plan['productID'])) {
                              homeProduct['Checked'] = true;
                              planType['Checked'] = true;
                              this.selectCount++;
                              // selectedtHomeCount1++;
                              // this.enableSave = true;
                              // this.enableHomeSave = true;
                            }
                          }
                        }

                      }
                      // if (selectedtHomeCount1 === totalHomeCount1) {
                      //   this.selectAllHome.push(true);
                      // } else {
                      //   this.selectAllHome.push(false);
                      // }
                    }
                    for (const shopProduct of this.planData['businessProductShopList']) {
                      for (const policyType of shopProduct['policyType']) {

                        for (const planType of policyType['planType']) {
                          // totalShopCount1++;
                          if (String(planType['planTypeName']) === String(plan['planName'])) {
                            if (String(shopProduct['productId']) === String(plan['productID'])) {
                              shopProduct['Checked'] = true;
                              planType['Checked'] = true;
                              this.selectCount++;
                              // selectedtShopCount1++;
                              // this.enableSave = true;
                              // this.enableShopSave = true;
                            }
                          }
                        }

                      }
                      // if (selectedtShopCount1 === totalShopCount1) {
                      //   this.selectAllShop.push(true);
                      // } else {
                      //   this.selectAllShop.push(false);
                      // }
                    }
                  }
                }
              }
            }
          }

          for (let i = 0; i <= this.userIdList.length; i++) {
            if (String(this.userIdList[i]) !== String(this.userID)) {
              for (const userPlan of this.planProductData) {
                for (const plan of userPlan['userPlanResourceList']) {
                  for (const healthProduct of this.planData['healthProductList']) {
                    for (const policyType of healthProduct['policyType']) {
                      for (const planType of policyType['planType']) {
                        if (String(planType['planTypeName']) === String(plan['planName'])) {
                          if (String(healthProduct['productId']) === String(plan['productID'])) {
                            healthProduct['Checked'] = true;
                            planType['Checked'] = true;
                          }
                        }
                      }


                    }

                  }

                  for (const homeProduct of this.planData['businessProductHomeList']) {
                    for (const policyType of homeProduct['policyType']) {
                      let totalHomeCount2 = 0;
                      for (const planType of policyType['planType']) {
                        totalHomeCount2++;
                        if (String(planType['planTypeName']) === String(plan['planName'])) {
                          if (String(homeProduct['productId']) === String(plan['productID'])) {
                            homeProduct['Checked'] = true;
                            planType['Checked'] = true;
                            // this.selectCount++;
                            // selectedtHomeCount2++;
                          }
                        }
                      }
                      // if (selectedtHomeCount2 === totalHomeCount2) {
                      //   this.selectAllHome.push(true);
                      // } else {
                      //   this.selectAllHome.push(false);
                      // }
                    }
                  }
                  for (const shopProduct of this.planData['businessProductShopList']) {
                    for (const policyType of shopProduct['policyType']) {
                      let totalShopCount2 = 0;
                      for (const planType of policyType['planType']) {
                        totalShopCount2++;
                        if (String(planType['planTypeName']) === String(plan['planName'])) {
                          if (String(shopProduct['productId']) === String(plan['productID'])) {
                            shopProduct['Checked'] = true;
                            planType['Checked'] = true;
                            // this.selectCount++;
                            // selectedtShopCount2++;
                          }
                        }
                      }
                      // if (selectedtShopCount2 === totalShopCount2) {
                      //   this.selectAllShop.push(true);
                      // } else {
                      //   this.selectAllShop.push(false);
                      // }
                    }
                  }
                }
              }
              // this.enableSave = true;
            }

          }

          this.selectAllHealth = [];
          this.selectAllHome = [];
          this.selectAllShop = [];
          for (const product of this.planData['healthProductList']) {
            let selectedHealthCount = 0;
            let totalHealthCount = 0;
            for (const policy of product['policyType'][0]['planType']) {
              totalHealthCount++;
              if (policy['Checked']) {
                selectedHealthCount++;
              }
            }
            if (selectedHealthCount === totalHealthCount) {
              this.selectAllHealth.push(true);
            } else {
              this.selectAllHealth.push(false);
            }
          }
          for (const product of this.planData['businessProductHomeList']) {
            let selectedHomeCount = 0;
            let totalHomeCount = 0;
            for (const policy of product['policyType'][0]['planType']) {
              totalHomeCount++;
              if (policy['Checked']) {
                selectedHomeCount++;
              }
            }
            if (selectedHomeCount === totalHomeCount) {
              this.selectAllHome.push(true);
            } else {
              this.selectAllHome.push(false);
            }
          }
          for (const product of this.planData['businessProductShopList']) {
            let selectedShopCount = 0;
            let totalShopCount = 0;
            for (const policy of product['policyType'][0]['planType']) {
              totalShopCount++;
              if (policy['Checked']) {
                selectedShopCount++;
              }
            }
            if (selectedShopCount === totalShopCount) {
              this.selectAllShop.push(true);
            } else {
              this.selectAllShop.push(false);
            }
          }
        }
      }).catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });

    }).catch(error => {
      this.loadingmessage = 'Please wait...';
      setTimeout(() => {
        this.serviceDownMessage();
      }, 10000);
    });
  }

  public updatePageData(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;
    this.planOptionChange();
    // this.getUsersData();
  }

  updatePageDataWithSearch(data) {
    if (data.PageNumber === 0) {
      this.count = data.PageNumber;
    } else {
      this.count = data.PageNumber * data.NumberOfRecords;
    }
    this.totalNumberOfRecords = data.NumberOfRecords;

    // this.searchUser();
    // this.searchBranchUser();
  }


  set(value: any, description: any) {
    this.proposerSearchVo.searchId = value;
    this.description = description;
    this.toggleDisplay();
  }

  setBranch(value: any, description: any) {
    this.proposerSearchVo.searchBranchId = value;
    this.description = description;
    this.toggleDisplay();
  }

  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }


  public prepareProjectData(userData: any) {
    const userDetailsList = userData['userDetailsList'];
    if (userData !== undefined) {
      this.userDetails = [];
      for (let i = 0; i < userDetailsList.length; i++) {
        const data: Object = new Object();
        data['Username'] = userDetailsList[i]['userName'];
        data['Name'] = userDetailsList[i]['name'];
        data['Branch'] = userDetailsList[i]['branchCode'] ? userDetailsList[i]['branchCode'] : ' ';
        data['BranchId'] = userDetailsList[i]['branchId'];
        data['User ID'] = userDetailsList[i]['userId'];
        this.userDetails.push(data);

      }
    }
  }

  public prepareBranchData(branchData: any) {
    const branchDetailsList = branchData['branchList'];

    if (branchData !== undefined) {
      this.branchDetails = [];
      for (let i = 0; i < branchDetailsList.length; i++) {
        const data: Object = new Object();
        data['Branch'] = branchDetailsList[i]['branchName'];
        data['Branch Code'] = branchDetailsList[i]['branchCode'];
        data['branchID'] = branchDetailsList[i]['branchID'];
        this.branchDetails.push(data);
      }
    }
  }


  public getPlanDetails(offset: any, size: any) {
    // this.loadingmessage = 'Please wait...';
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    this.productService.getListForAffinity(affinity[AFFINITY_ID])
      .then(list => {

        const lists = list;
        this.productService.singleUserlist(offset, size, lists).then(data => {
          const val: any = data;

          this.totalRows = data['totalRecords'];

          this.prepareProjectData(data);
          this.loadingmessage = '';
          this.moveToTop();
        }).catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });
      })
      .catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
  }


  public getBranchDetails(offset: any, size: any) {
    // this.loadingmessage = 'Please wait...';
    this.productService.branchList(offset, size).then(data => {
      const val: any = data;

      this.totalRows = data['totalRecords'];

      this.prepareBranchData(data);
      this.loadingmessage = '';
      this.moveToTop();
    }).catch(error => {
      this.loadingmessage = '';

    });
  }

  public singleUserSearch() {
    // this.loadingmessage = 'Please wait...';

    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {
      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        this.getPlanDetails(this.count, this.totalNumberOfRecords);
      }
    } else if (this.proposerSearchVo.searchId === USER_NAME || this.proposerSearchVo.searchId === NAME || this.proposerSearchVo.searchId === BRANCH_CODE) {
        const notspecialchar: any = (/[^a-zA-Z0-9 ]/g);
        if (this.proposerSearchVo.searchInput.match(notspecialchar)) {
          this.loadingmessage = '';
          const obj = new Object();
          obj['userDetailsList'] = [];
         obj['totalRecords'] = 0;
          this.prepareProjectData(obj);

        } else {

        this.productService.userSearch(this.proposerSearchVo.searchId, this.proposerSearchVo.searchInput, this.count, this.totalNumberOfRecords).then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareProjectData(data);
          this.gridComponent.inSearch = true;
          this.gridComponent.setcurrentpage(0);
          this.gridComponent.showData();
        })
          .catch(error => {
            this.loadingmessage = '';
            this.prepareProjectData(null);
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      }
    }

  }

  public branchUserSearch() {
    // this.loadingmessage = 'Please wait...';
    if (!this.proposerSearchVo.searchBranchInput || !this.proposerSearchVo.searchBranchInput || this.proposerSearchVo.searchBranchInput === '') {
      if (this.count !== undefined && this.totalNumberOfRecords !== undefined) {
        this.getBranchDetails(this.count, this.totalNumberOfRecords);
      }
    } else if (this.proposerSearchVo.searchBranchId === BRANCH_NAME || this.proposerSearchVo.searchBranchId === BRANCH_CODE) {
      const notspecialchar: any = (/[^a-zA-Z0-9 ]/g);
       if (this.proposerSearchVo.searchBranchInput.match(notspecialchar)) {

         this.loadingmessage = '';
         const obj = new Object();
         obj['branchList'] = [];
         obj['totalRecords'] = 0;
         this.prepareBranchData(obj);

       } else {

        this.productService.searchBranch(this.proposerSearchVo.searchBranchId, this.proposerSearchVo.searchBranchInput, this.count, this.totalNumberOfRecords).then(data => {
          this.totalRows = data['totalRecords'];
          this.prepareBranchData(data);
          this.gridComponent.inSearch = true;
          this.gridComponent.setcurrentpage(0);
          this.gridComponent.showData();

        })
          .catch(error => {
            this.loadingmessage = '';
            this.prepareBranchData(null);
            this.loadingmessage = 'Please wait...';
            setTimeout(() => {
              this.serviceDownMessage();
            }, 10000);
          });
      }
    }
  }


  public searchUser() {
    if (!this.proposerSearchVo.searchInput || !this.proposerSearchVo.searchInput || this.proposerSearchVo.searchInput === '') {

      this.singleUserSearch();
      this.loadingmessage = 'Please wait...';


    }
  }

  public searchBranchUser() {
    if (!this.proposerSearchVo.searchBranchInput || !this.proposerSearchVo.searchBranchInput || this.proposerSearchVo.searchBranchInput === '') {
      this.branchUserSearch();
      this.loadingmessage = 'Please wait...';
    }
  }

  public saveBranchUser() {
    // const branchobject: Object = new Object();
    // branchobject['branchProductResource'] = Array<any>();
    // const sample = new Object();
    // sample['branchID'] = 408;
    // sample['productID'] = 27;
    // sample['branchPlanResourceList'] = Array<any>();
    // const sample2 = new Object();
    // sample2['userId'] = 408;
    // sample2['planID'] = 495;
    // sample['branchPlanResourceList'].push(sample2);
    // branchobject['branchProductResource'].push(sample);


    const branchhealthProducts = this.planDataBranch['healthProductList'];
    const branchProductResource = [];
    const usersProductResource = [];
    for (const branchHealthProduct of branchhealthProducts) {
      const branchPlanResourceList = [];
      const userPlanResourceList = [];
      if (branchHealthProduct['Checked']) {
        for (const policyType of branchHealthProduct['policyType']) {
          for (const planType of policyType['planType']) {
            if (planType['Checked']) {
              const branchPlanResource = new Object();
              const userPlanResource = new Object();
              branchPlanResource['branchID'] = this.branchId;
              branchPlanResource['planName'] = planType['planTypeName'];
              branchPlanResource['productID'] = branchHealthProduct['productId'];
              branchPlanResource['affinityID'] =  this.affinityid;
              userPlanResource['planName'] = planType['planTypeName'];
              userPlanResource['productID'] = branchHealthProduct['productId'];
              userPlanResource['affinityID'] =  this.affinityid;
              branchPlanResourceList.push(branchPlanResource);
              userPlanResourceList.push(userPlanResource);

            }
          }
        }
        if (branchPlanResourceList.length > 0) {
          if (userPlanResourceList.length > 0) {
            const branchProduct = new Object();
            const userProduct = new Object();
            branchProduct['branchID'] = this.branchId;
            branchProduct['productID'] = branchHealthProduct['productId'];
            branchProduct['branchPlanResourceList'] = branchPlanResourceList;
            branchProduct['isProductMapped'] = true;
            branchProduct['affinityID'] =  this.affinityid;
            userProduct['productID'] = branchHealthProduct['productId'];
            userProduct['userPlanResourceList'] = userPlanResourceList;
            userProduct['isProductMapped'] = true;
            userProduct['affinityID'] =  this.affinityid;
            branchProductResource.push(branchProduct);
            usersProductResource.push(userProduct);
          }
        }
      }
    }

    const branchHomeProducts = this.planDataBranch['businessProductHomeList'];
    for (const branchHomeProduct of branchHomeProducts) {
      const branchPlanResourceList = [];
      const userPlanResourceList = [];
      if (branchHomeProduct['Checked']) {
        for (const policyType of branchHomeProduct['policyType']) {
          for (const planType of policyType['planType']) {
            if (planType['Checked']) {
              const branchPlanResource = new Object();
              const userPlanResource = new Object();
              branchPlanResource['branchID'] = this.branchId;
              branchPlanResource['planName'] = planType['planTypeName'];
              branchPlanResource['productID'] = branchHomeProduct['productId'];
              branchPlanResource['affinityID'] =  this.affinityid;
              userPlanResource['planName'] = planType['planTypeName'];
              userPlanResource['productID'] = branchHomeProduct['productId'];
              userPlanResource['affinityID'] =  this.affinityid;
              branchPlanResourceList.push(branchPlanResource);
              userPlanResourceList.push(userPlanResource);
            }
          }
        }
        if (branchPlanResourceList.length > 0) {
          if (userPlanResourceList.length > 0) {
            const branchProduct = new Object();
            const userProduct = new Object();
            branchProduct['branchID'] = this.branchId;
            branchProduct['productID'] = branchHomeProduct['productId'];
            branchProduct['branchPlanResourceList'] = branchPlanResourceList;
            branchProduct['isProductMapped'] = true;
            branchProduct['affinityID'] =  this.affinityid;
            userProduct['productID'] = branchHomeProduct['productId'];
            userProduct['userPlanResourceList'] = userPlanResourceList;
            userProduct['isProductMapped'] = true;
            userProduct['affinityID'] =  this.affinityid;
            branchProductResource.push(branchProduct);
            usersProductResource.push(userProduct);
          }
        }
      }
    }

    const branchShopProducts = this.planDataBranch['businessProductShopList'];
    for (const branchShopProduct of branchShopProducts) {
      const branchPlanResourceList = [];
      const userPlanResourceList = [];
      if (branchShopProduct['Checked']) {
        for (const policyType of branchShopProduct['policyType']) {
          for (const planType of policyType['planType']) {
            if (planType['Checked']) {
              const branchPlanResource = new Object();
              const userPlanResource = new Object();
              branchPlanResource['branchID'] = this.branchId;
              branchPlanResource['planName'] = planType['planTypeName'];
              branchPlanResource['productID'] = branchShopProduct['productId'];
              branchPlanResource['affinityID'] =  this.affinityid;
              userPlanResource['planName'] = planType['planTypeName'];
              userPlanResource['productID'] = branchShopProduct['productId'];
              userPlanResource['affinityID'] =  this.affinityid;
              branchPlanResourceList.push(branchPlanResource);
              userPlanResourceList.push(userPlanResource);
            }
          }
        }
        if (branchPlanResourceList.length > 0) {
          if (userPlanResourceList.length > 0) {
            const branchProduct = new Object();
            const userProduct = new Object();
            branchProduct['branchID'] = this.branchId;
            branchProduct['productID'] = branchShopProduct['productId'];
            branchProduct['branchPlanResourceList'] = branchPlanResourceList;
            branchProduct['isProductMapped'] = true;
            branchProduct['affinityID'] =  this.affinityid;
            userProduct['productID'] = branchShopProduct['productId'];
            userProduct['userPlanResourceList'] = userPlanResourceList;
            userProduct['isProductMapped'] = true;
            userProduct['affinityID'] = this.affinityid;
            branchProductResource.push(branchProduct);
            usersProductResource.push(userProduct);
          }
        }
      }
    }


    const branchUserProducts = new Object();
    branchUserProducts['branchProductResource'] = branchProductResource;
    this.savedProducts = branchUserProducts;

    if (branchUserProducts['branchProductResource'].length === 0) {
      const branchProduct = new Object();
      branchProduct['branchID'] = this.branchId;
      branchProduct['productID'] = 0;
      branchProduct['isProductMapped'] = false;
      branchProduct['affinityID'] =  this.affinityid;
      branchUserProducts['branchProductResource'].push(branchProduct);
    }

    this.productService.getUserIdlist(this.branchId).then(userData => {
      // this.userIdList.push(userData) ;
      this.userIdList = userData;
    })
      .catch(error => {
        // this.openActionFailDialog('User list could not be fetched');
      });

     const affinityid = JSON.parse(sessionStorage.getItem(USER_AFFINITY))['id'];
    this.productService.saveBranchUser(branchUserProducts).then(
      data => {


        const branchProductPlan = new Object();
        branchProductPlan['userProductResource'] = usersProductResource;
        branchProductPlan['userIdList'] = this.userIdList;

        if ( branchProductPlan['userProductResource'].length === 0) {
          const userProduct = new Object();
      userProduct['userId'] = this.userID;
      userProduct['productID'] = 0;
      userProduct['isProductMapped'] = false;
      userProduct['userPlanResourceList'] = [];
      userProduct['affinityID'] =  this.affinityid;
      branchProductPlan['userProductResource'].push(userProduct);
    }


        this.productService.saveBranchUserData(branchProductPlan).then(branchData => {

          this.moveToTop();
          this.issave = true;
          this.greyRenewal = true;
        }).catch(error => {
          this.loadingmessage = 'Please wait...';
          setTimeout(() => {
            this.serviceDownMessage();
          }, 10000);
        });

      }).catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });

  }

  public saveSingleUser() {
    // this.enableSave = true;

    // const branchobject: Object = new Object();
    // branchobject['userProductResource'] = Array<any>();
    // const sample = new Object();
    // sample['userId'] = 408;
    // sample['productID'] = 27;
    // sample['userPlanResourceList'] = Array<any>();
    // const sample2 = new Object();
    // sample2['userId'] = 408;
    // sample2['planID'] = 495;
    // sample['userPlanResourceList'].push(sample2);
    // branchobject['userProductResource'].push(sample);

    const healthProducts = this.planData['healthProductList'];
    const userProductResource = [];
    for (const healthProduct of healthProducts) {
      const userPlanResourceList = [];
      if (healthProduct['Checked']) {
        // this.healthChecked = true;
        for (const policyType of healthProduct['policyType']) {
          for (const planType of policyType['planType']) {

            if (planType['Checked']) {
              // this.planChecked = true;
              const userPlanResource = new Object();
              userPlanResource['userId'] = this.userID;
              userPlanResource['productID'] = healthProduct['productId'];
              userPlanResource['planName'] = planType['planTypeName'];
              userPlanResource['affinityID'] =  this.affinityid;
              userPlanResourceList.push(userPlanResource);
              this.healthDetails.push(userPlanResource);

            }
          }
        }
        if (userPlanResourceList.length > 0) {
          const userProduct = new Object();
          userProduct['userId'] = this.userID;
          userProduct['productID'] = healthProduct['productId'];
          userProduct['isProductMapped'] = true;
          userProduct['affinityID'] =  this.affinityid;
          userProduct['userPlanResourceList'] = userPlanResourceList;
          userProductResource.push(userProduct);
        }
      }
    }

    const homeProducts = this.planData['businessProductHomeList'];
    for (const homeProduct of homeProducts) {
      const userPlanResourceList = [];
      if (homeProduct['Checked']) {
        for (const policyType of homeProduct['policyType']) {
          for (const planType of policyType['planType']) {
            if (planType['Checked']) {
              const userPlanResource = new Object();
              userPlanResource['userId'] = this.userID;
              userPlanResource['productID'] = homeProduct['productId'];
              userPlanResource['planName'] = planType['planTypeName'];
              userPlanResource['affinityID'] =  this.affinityid;
              userPlanResourceList.push(userPlanResource);
            }
          }
        }
        if (userPlanResourceList.length > 0) {
          const userProduct = new Object();
          userProduct['userId'] = this.userID;
          userProduct['productID'] = homeProduct['productId'];
          userProduct['isProductMapped'] = true;
          userProduct['affinityID'] =  this.affinityid;
          userProduct['userPlanResourceList'] = userPlanResourceList;
          userProductResource.push(userProduct);
        }
      }
    }

    const shopProducts = this.planData['businessProductShopList'];
    for (const shopProduct of shopProducts) {
      const userPlanResourceList = [];
      if (shopProduct['Checked']) {
        for (const policyType of shopProduct['policyType']) {
          for (const planType of policyType['planType']) {
            if (planType['Checked']) {
              const userPlanResource = new Object();
              userPlanResource['userId'] = this.userID;
              userPlanResource['productID'] = shopProduct['productId'];
              userPlanResource['planName'] = planType['planTypeName'];
              userPlanResource['affinityID'] =  this.affinityid;
              userPlanResourceList.push(userPlanResource);
            }
          }
        }
        if (userPlanResourceList.length > 0) {
          const userProduct = new Object();
          userProduct['userId'] = this.userID;
          userProduct['productID'] = shopProduct['productId'];
          userProduct['isProductMapped'] = true;
          userProduct['affinityID'] =  this.affinityid;
          userProduct['userPlanResourceList'] = userPlanResourceList;
          userProductResource.push(userProduct);
        }
      }
    }


    const userProducts = new Object();
    userProducts['userProductResource'] = userProductResource;
    this.savedProducts = userProducts;
    if (userProducts['userProductResource'].length === 0) {
      const userProduct = new Object();
      userProduct['userId'] = this.userID;
      userProduct['productID'] = 0;
      userProduct['isProductMapped'] = false;
      userProduct['affinityID'] =  this.affinityid;
      userProduct['userPlanResourceList'] = [];
      userProducts['userProductResource'].push(userProduct);
    }
    this.productService.saveSingleUser(userProducts).then(
      data => {


        this.moveToTop();
        this.issave = true;

        this.greyRenewal = true;
      })
      .catch(error => {
        this.loadingmessage = 'Please wait...';
        setTimeout(() => {
          this.serviceDownMessage();
        }, 10000);
      });
  }

  public viewUsers() {
    this.moveToTop();
    this.productPlan = false;
    this.issave = false;
    this.greyRenewal = false;
    this.issaveProducts = true;
    this.singleuserproduct = false;
    this.branchuserproduct = false;
    this.planOption = 'single';
  }

  public cancel() {
    this.productPlan = false;
    // this.greyRenewal = true;
    this.singleuserproduct = false;
    this.branchuserproduct = false;
    this.moveToTop();
    // this.issave = false;
  }


  public selectAllHealthFunction(data: any, index) {

    if (this.selectAllHealth[index]) {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = true;
          // this.enableHealthSave = true;
          // this.enableHomeSave = true;
          // this.enableShopSave = true;
        }
      }
    } else {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = false;
          // this.enableHealthSave = false;
          // this.enableHomeSave = false;
          // this.enableShopSave = false;
        }
      }
    }

  }

  public selectAllHomeFunction(data: any, index) {

    if (this.selectAllHome[index]) {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = true;
          // this.enableHealthSave = true;
          // this.enableHomeSave = true;
          // this.enableShopSave = true;
        }
      }
    } else {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = false;
          // this.enableHealthSave = false;
          // this.enableHomeSave = false;
          // this.enableShopSave = false;
        }
      }
    }
  }

  public selectAllShopFunction(data: any, index) {

    if (this.selectAllShop[index]) {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = true;
          // this.enableHealthSave = true;
          // this.enableHomeSave = true;
          // this.enableShopSave = true;
        }
      }
    } else {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = false;
          // this.enableHealthSave = false;
          // this.enableHomeSave = false;
          // this.enableShopSave = false;
        }
      }
    }

  }

  public selectAllBranchHealthFunction(data: any, index) {

    if (this.selectAllBranchHealth[index]) {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = true;
          // this.enableHealthSave = true;
          // this.enableHomeSave = true;
          // this.enableShopSave = true;
        }
      }
    } else {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = false;
          // this.enableHealthSave = false;
          // this.enableHomeSave = false;
          // this.enableShopSave = false;
        }
      }
    }
  }

  public selectAllBranchHomeFunction(data: any, index) {

    if (this.selectAllBranchHome[index]) {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = true;
          // this.enableHealthSave = true;
          // this.enableHomeSave = true;
          // this.enableShopSave = true;
        }
      }
    } else {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = false;
          // this.enableHealthSave = false;
          // this.enableHomeSave = false;
          // this.enableShopSave = false;
        }
      }
    }
  }

  public selectAllBranchShopFunction(data: any, index) {

    if (this.selectAllBranchShop[index]) {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = true;
          // this.enableHealthSave = true;
          // this.enableHomeSave = true;
          // this.enableShopSave = true;
        }
      }
    } else {
      for (const policyType of data['policyType']) {
        for (const planType of policyType['planType']) {
          planType['Checked'] = false;
          // this.enableHealthSave = false;
          // this.enableHomeSave = false;
          // this.enableShopSave = false;
        }
      }
    }
  }


  public checkActionHealth(index: any, planValue) {
    // if (planValue['Checked'] === false) {
    //   this.selectAllHealth[index] = false;
    // }

    let selectedHealthCount = 0;
    let totalHealthCount = 0;
    for (const policy of planValue) {
      totalHealthCount++;
      if (policy['Checked']) {
        selectedHealthCount++;
      }
    }
    if (selectedHealthCount === totalHealthCount) {
      this.selectAllHealth[index] = true;
    } else {
      this.selectAllHealth[index] = false;
    }
  }

  public checkActionHome(index: any, planValue) {
    let selectedHomeCount = 0;
    let totalHomeCount = 0;
    for (const policy of planValue) {
      totalHomeCount++;
      if (policy['Checked']) {
        selectedHomeCount++;
      }
    }
    if (selectedHomeCount === totalHomeCount) {
      this.selectAllHome[index] = true;
    } else {
      this.selectAllHome[index] = false;
    }
  }

  public checkActionShop(index: any, planValue) {
    let selectedShopCount = 0;
    let totalShopCount = 0;
    for (const policy of planValue) {
      totalShopCount++;
      if (policy['Checked']) {
        selectedShopCount++;
      }
    }
    if (selectedShopCount === totalShopCount) {
      this.selectAllShop[index] = true;
    } else {
      this.selectAllShop[index] = false;
    }
  }

  public checkBranchActionHealth(index: any, planBranchvalue) {

    let selectedHealthBranchCount = 0;
    let totalHealthBranchCount = 0;
    for (const policy of planBranchvalue) {
      totalHealthBranchCount++;
      if (policy['Checked']) {
        selectedHealthBranchCount++;
      }
    }
    if (selectedHealthBranchCount === totalHealthBranchCount) {
      this.selectAllBranchHealth[index] = true;
    } else {
      this.selectAllBranchHealth[index] = false;
    }
  }

  public checkBranchActionHome(index: any, planBranchvalue) {
    let selectedHomeBranchCount = 0;
    let totalHomeBranchCount = 0;
    for (const policy of planBranchvalue) {
      totalHomeBranchCount++;
      if (policy['Checked']) {
        selectedHomeBranchCount++;
      }
    }
    if (selectedHomeBranchCount === totalHomeBranchCount) {
      this.selectAllBranchHome[index] = true;
    } else {
      this.selectAllBranchHome[index] = false;
    }
  }
  public checkBranchActionShop(index: any, planBranchvalue) {
    let selectedShopBranchCount = 0;
    let totalShopBranchCount = 0;
    for (const policy of planBranchvalue) {
      totalShopBranchCount++;
      if (policy['Checked']) {
        selectedShopBranchCount++;
      }
    }
    if (selectedShopBranchCount === totalShopBranchCount) {
      this.selectAllBranchShop[index] = true;
    } else {
      this.selectAllBranchShop[index] = false;
    }
  }




  // if (this.selectAll) {
  //   const healthProducts = this.planData['healthProductList'];
  //   for (const healthProduct of healthProducts) {
  //     if (healthProduct['Checked']) {
  //     for (const policyType of healthProduct['policyType']) {
  //       for (const planType of policyType['planType']) {
  //         planType['Checked'] = true;
  //       }
  //     }
  //   }
  //   }
  // } else {
  //   const healthProducts = this.planData['healthProductList'];
  //   for (const healthProduct of healthProducts) {
  //     if (healthProduct['Checked']) {
  //     for (const policyType of healthProduct['policyType']) {
  //       for (const planType of policyType['planType']) {
  //         planType['Checked'] = false;
  //       }
  //     }
  //   }
  //   }
  // }


  // checkAction(index: any, value) {
  //     this.selectAll[index] = value;
  // }

  // public healthSave(healthplan: any) {
  //   if (healthplan['Checked'] === true) {
  //     this.enableHealthSave = true;
  //   } else {
  //     this.enableHealthSave = false;
  //   }
  // }

  // public homeSave(homeplan: any) {
  //   if (homeplan['Checked'] === true) {
  //     this.enableHomeSave = true;
  //   } else {
  //     this.enableHomeSave = false;
  //   }
  // }
  // public shopSave(shopplan: any) {
  //   if (shopplan['Checked'] === true) {
  //     this.enableShopSave = true;
  //   } else {
  //     this.enableShopSave = false;
  //   }
  // }

  // public branchHealthSave(healthplan: any) {
  //   if (healthplan['Checked'] === true) {
  //     this.enableHealthSave = true;
  //   } else {
  //     this.enableHealthSave = false;
  //   }
  // }

  // public branchHomeSave(homeplan: any) {
  //   if (homeplan['Checked'] === true) {
  //     this.enableHomeSave = true;
  //   } else {
  //     this.enableHomeSave = false;
  //   }
  // }
  // public branchShopSave(shopplan: any) {
  //   if (shopplan['Checked'] === true) {
  //     this.enableShopSave = true;
  //   } else {
  //     this.enableShopSave = false;
  //   }
  // }


  public closeActionFailDialog() {
    this.actionFailedFlag = false;
    this.actionFailedMessage = '';
  }

  public openActionFailDialog(message: any) {
    this.moveToTop();
    this.actionFailedFlag = true;
    this.actionFailedMessage = message;
  }

  public serviceDownMessage() {
    setTimeout(
      function () {
        EmitterService.get('SERVICE_DOWN').emit(true);
      });
    this.router.navigateByUrl('');
  }
}
