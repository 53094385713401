import { Injectable, ɵConsole } from '@angular/core';
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  ResponseContentType,
} from '@angular/http';
import {
  DESCRIPTION,
  CODE,
  USER_NAME,
  NAME,
  EMAIL_ID,
  LAST_ACTIVE_DATE,
  ACCOUNT,
  STATUS,
  MAIN_PAGE_PART_URL,
  END_DATE,
  USER_AFFINITY,
  USER_PROFILE
} from 'src/common/common.constants';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { AppConfig } from 'src/config/app.config';
import { REQUEST_HEADER, REQUEST_HEADER_UM } from '../common/common.constants';
import { UserDetailsVO } from 'src/dto/UserDetailsVO';
import { EmitterService } from 'src/services/EmitterService';
import { HEADER, HEADER_UM } from './../common/common.constants';
import { UserDataVO } from 'src/dto/UserDataVO';

@Injectable()
export class UserManagementServices {
  hostUrl: string = this.config.getConfig('productHost');
  constructor(private httpClient: HttpClient, private http: Http, private config: AppConfig) { }

  public createUser(userDetails: UserDataVO) {
    const requestOptions = new RequestOptions(REQUEST_HEADER_UM);
    const url = this.hostUrl + 'api/user/save';
    const payload = new Object();
    userDetails['userIdEncrypt']=JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'))['userIdEncrypt'];
    payload['userDetails'] = userDetails;
    const payloadJSON = JSON.stringify(payload);
    return this.httpClient
      .post(url, payloadJSON, { headers: HEADER_UM })
      .toPromise()
      .catch(error => error);
  }

  public mapBulkUsersToAffinity(idList: any, affinityId: any) {
    const userData = new Object();
    userData['idList'] = idList;
    const url = this.hostUrl + 'api/admin/affinity/user/save/' + affinityId;
    return this.httpClient
      .post(url, userData, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public tempDownload() {
    const URl = this.hostUrl + 'api/product/download/template/user';
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Blob;
    return this.http.get(URl, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public updateUser(userDetails: UserDataVO) {
    const requestOptions = new RequestOptions(REQUEST_HEADER_UM);
    const url = this.hostUrl + 'api/user/update';
    const payload = new Object();
    userDetails['userIdEncrypt']=JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'))['userIdEncrypt'];
    payload['userDetails'] = userDetails;
    const payloadJSON = JSON.stringify(payload);
    return this.httpClient
      .post(url, payloadJSON, { headers: HEADER_UM })
      .toPromise()
      .catch(this.handleError);
  }

  public getUserDetails(userID: string) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/user/' + userID;
    return this.httpClient
      .get(url, { headers: HEADER, observe: 'response' })
      .toPromise()
      .then((response: HttpResponse<any>) => response)
      .catch(error => error);
  }


  public getBranchDetails(branchId: string) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/user/branchId/' + branchId;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getBranches(branchCode: string) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/user/branchCode/' + branchCode;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getValidBranchCode(branchCode: string) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/user/valid/branchCode/' + branchCode; 
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public saveBranch(branchDetails: any) {
    const url = this.hostUrl + 'api/user/branch/save';
    return this.httpClient
      .post(url, branchDetails, { headers: HEADER_UM })
      .toPromise()
      .catch(this.handleError);
  }

  public getBranchIdFromCode(branchCode: string) {
    this.getBranches(branchCode).then(data => {
      for (let i = 0; i < data.length; i++) {
        if (branchCode === data[i]['branchCode']) {
          return data[i];
        }
      }
    });
    return null;
  }

  public getUsers(count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    const affinityUsersList = new Object();
    affinityUsersList['idList'] = [];

    return this.getUsersForAffinity(affinity['id']).then(data => {
      affinityUsersList['idList'] = data;
      return this.httpClient
        .post(this.hostUrl + 'api/user/listUser?offset=' + count + '&size=' + totalNumberOfRecords, affinityUsersList, { headers: HEADER })
        .toPromise()
        .then((response: Response) => response)
        .catch(this.handleError);
    })
      .catch(error => { });
  }

  public getUsersForAffinity(affinityId: string) {
    return this.httpClient
      .get(this.hostUrl + 'api/admin/user/affinity/' + affinityId, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  public userSearch(searchID, searchInput, count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let URL;
    if (searchID === USER_NAME) {
      URL = this.hostUrl + 'api/user/search' + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords;
    } else if (searchID === NAME) {
      URL = this.hostUrl + 'api/user/search' + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords;
    } else if (searchID === EMAIL_ID) {
      URL = this.hostUrl + 'api/user/search' + '?' + 'offset=0' + count + '&size=' + totalNumberOfRecords;
    } else if (searchID === 'branchCode') {
      URL = this.hostUrl + 'api/user/search' + '?' + 'offset=0' + count + '&size=' + totalNumberOfRecords;
    }
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    const affinityUsersList = new Object();
    affinityUsersList['idList'] = [];
    affinityUsersList['searchColumn'] = searchID === EMAIL_ID ? 'emailAddress' : searchID;
    affinityUsersList['searchValue'] = searchInput;

    return this.getUsersForAffinity(affinity['id']).then(data => {
      affinityUsersList['idList'] = data;
      return this.httpClient
        .post(
          URL, affinityUsersList, { headers: HEADER }
        )
        .toPromise()
        .then((response: Response) => response)
        .catch(this.handleError);
    })
      .catch(error => { });
  }

  public saveData(formData: FormData) {
    const url = this.hostUrl + 'api/user/upload';

    if (!formData) {
      formData = new FormData();
    }

    const headers = new Headers({
      'Accept': 'application/octet-stream',
    });
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;

    return this.http.post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getStateDistrictData(stateId: any, districtId: any) {
    const url = this.hostUrl + 'api/reference/state?districtId=' + districtId + '&stateId=' + stateId;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public mapUserToAffinity(idList: any, affinityId: any) {
    const userData = new Object();
    userData['idList'] = idList;
    // userData['affinityID'] = affinityId;
    // userData['isDefault'] = false;
    const url = this.hostUrl + 'api/admin/affinity/user/save/' + affinityId;
    return this.httpClient
      .post(url, userData, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  public updateMappedAffinity(userId: any, affinityId: any, setDefaultAffinityId: any, affinityMappingId: any) {
    const userData = new Object();
    userData['userId'] = userId;
    userData['affinityID'] = affinityId;
    userData['defaultAffinityID'] = setDefaultAffinityId;
    userData['userAffinityMappingID'] = affinityMappingId;
    const url = this.hostUrl + 'api/admin/affinity/user/update';
    return this.httpClient
      .post(url, userData, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getUserAffinity(userId: any) {
    const url = this.hostUrl + 'api/admin/affinity/user/' + userId;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public checkEmail(email: string) {
    // const requestOptions = new RequestOptions(REQUEST_HEADER_UM);
    // const url = this.hostUrl + 'api/user/exists/email/' + email;
    // return this.httpClient
    //   .get(url, {headers: HEADER_UM })
    //   .toPromise()
    //   .then((response: Response) => response)
    //   .catch(this.handleError);

    const url = this.hostUrl + 'api/user/exists/email';
    const userDetail = new Object();
    userDetail['userDetails'] = new Object();
    userDetail['userDetails']['emailAddress'] = email;
    return this.httpClient
      .post(url, userDetail, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public checkEmailForEdit(email: string, userId: string) {
    const requestOptions = new RequestOptions(REQUEST_HEADER_UM);
    const userData = new Object();
    userData['userDetails'] = new Object();
    userData['userDetails']['userId'] = userId;
    userData['userDetails']['emailAddress'] = email;
    const url = this.hostUrl + 'api/user/exists/id/email/';
    return this.httpClient
      .post(url, userData, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public checkUserName(userName: string) {
    const requestOptions = new RequestOptions(REQUEST_HEADER_UM);
    const url = this.hostUrl + 'api/user/exists/username/' + userName;
    return this.httpClient
      .get(url, { headers: HEADER_UM })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }
}
