import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from 'src/services/authentication.service';
import { USER_PROFILE } from 'src/common/common.constants';
// import { Base64 } from 'js-base64';
import { EmitterService } from 'src/services/EmitterService';

@Injectable()
export class AuthGuard implements CanActivate {
  private userProfile: Object;
  constructor(private router: Router, private authenticationService: AuthenticationService) { }
  // async  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const loggedIn = true;
    if (loggedIn) {
      if (route.data.affinityDataRequired) {
        if (sessionStorage.getItem('USER_AFFINITY')) {
          const affinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
          if (!affinity['name'] || !affinity['id']) {
            this.router.navigateByUrl('welcome/(contentBody:affinity)');
            return false;
          }
        } else {
          this.router.navigateByUrl('welcome/(contentBody:affinity)');
          return false;
        }
      }
      return true;
    }
    sessionStorage.setItem(USER_PROFILE, '');
    this.router.navigate(['/']);
    return false;
  }
}
