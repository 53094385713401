import { OnChanges, ChangeDetectorRef, HostListener, OnDestroy, AfterViewInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { NavigationServices } from '../services/navigation.services';
import { EmitterService } from 'src/services/EmitterService';
import { USER_PROFILE, USER_AFFINITY, SESSIONTIMEOUT_MINUTES } from 'src/common/common.constants';
import { USER_PROFILE_DATA } from '../common/common.constants';
import { ProductServices } from 'src/services/product.service';
import * as _moment from 'moment';
import { THIS_EXPR } from '@angular/compiler/src/output/output_ast';

const moment = (_moment as any).default ? (_moment as any).default : _moment;

declare global {
  interface JQuery {
    (selector: string): JQuery;
    imgViewer2(): JQuery;
  }
}
@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.html',

})
export class NavigationComponent implements OnInit, OnChanges, OnDestroy {
  public isOpenSideMenu: Boolean = false;
  public rootMenus: Array<Object> = new Array<Object>();
  public primaryMenus: Array<Object> = new Array<Object>();
  public secondaryMenus: Array<Object> = new Array<Object>();
  private menuData: Array<Object> = new Array<Object>();
  public user: Object = new Object;
  public showmenu: any = false;
  public menuType: any = 'main';
  public activeSubmenu: any;
  public showfooter: any = true;
  /**** Session / Login *****/
  public role = '';
  public name = '';
  public showLibertyIcon: Boolean = false;
  public isMuthootCustomer: Boolean = false;
  public display = 'none';
  public affinityName: any;
  public fullAffinityName: any;
  /***********************/
  public userActions: any;
  public showUserActions = false;
  public disableAffinityChange = true;
  public showUserDetails = true;
  public timeoutRef: any;
  public parentMenuId: any;
  public menuUpdate: any;
  public showSpinner = false;
  constructor(private navigationServices: NavigationServices,
    private router: Router,
    private route: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private productService: ProductServices) {
  }

  @HostListener('document:keyup', ['$event'])
  @HostListener('document:click', ['$event'])
  @HostListener('document:wheel', ['$event'])
  resetTimer() {
    sessionStorage.setItem('TEMPORAL_INDEX', String(new Date()));
  }

  checkSessionTimeout() {
    let temporalIndex;
    if (sessionStorage.getItem('TEMPORAL_INDEX') === null) {
      temporalIndex = moment(new Date());
      sessionStorage.setItem('TEMPORAL_INDEX', String(new Date()));
    } else {
      temporalIndex = moment(new Date(sessionStorage.getItem('TEMPORAL_INDEX')));
    }
    const currentTime = moment(new Date());
  //  const temporalOverhead = moment.utc(moment(currentTime, 'HH:mm:ss').diff(moment(temporalIndex, 'HH:mm:ss'))).format('minutes');
    const temporalOverhead = currentTime.diff(temporalIndex, 'minutes');
    if (temporalOverhead > SESSIONTIMEOUT_MINUTES) {
      setTimeout(
        function () {
          EmitterService.get('SESSION_EXPIRED').emit(true);
        },
        500
      );
      this.logout();
    }
  }

  beforeClosing(event: any) {
    // Do something when the browser or tab is about to close
    console.log('Browser or tab is about to close!');
    this.logout();
  }

  ngOnChanges(changes: any): void {

  }
  ngOnInit() {
    window.addEventListener('beforeunload', this.beforeClosing.bind(this));
    // this.menuData = [];
    if (sessionStorage.getItem('MENU_DATA') && sessionStorage.getItem('MENU_DATA') !== 'undefined') {
      this.menuData = JSON.parse(sessionStorage.getItem('MENU_DATA'));
      this.rootMenus = this.FilteredArrayBySearchCriteria(this.menuData, 'MenuLevel', 0);
    }
    EmitterService.get('LOGIN_EVENT').subscribe(data => {
      if (data) {
        // if (!sessionStorage.getItem('MENU_REQUEST')) {
        if (!this.menuData || this.menuData.length === 0) {
          this.prepareMenu();
        }
        this.setName();
      }
    });
    EmitterService.get('AFFINITY_SELECT_EVENT').subscribe(data => {
      if (data) {
        this.setAffinityDetails();
        // if (!sessionStorage.getItem('MENU_REQUEST')) {
        if (!this.menuData || this.menuData.length === 0) {
          this.prepareMenu();
        }
      }
    });
    EmitterService.get('MENU_UPDATE_EVENT').subscribe(data => {
      if ('true' === sessionStorage.getItem('MENU_UPDATE_EVENT')) {
        sessionStorage.setItem('MENU_UPDATE_EVENT', 'false');
        if (data) {
          this.menuUpdate = true;
          this.setAffinityDetails();
          this.prepareMenu();
        }
      }
    });
    EmitterService.get('SHOW_SPINNER').subscribe(data => {
      if (data) {
        this.showSpinner = true;
      } else {
        this.showSpinner = false;
      }
    });
    if (this.router.url.match(/^\/paymentGateway.*$/) &&
      this.router.url.match(/^\/paymentGateway.*$/).length &&
      this.router.url.match(/^\/paymentGateway.*$/).length > 0) {
      this.showUserDetails = false;
    }
    /**** Session / Login *****/
    const _this = this;
    this.timeoutRef = setInterval(function () { _this.checkSessionTimeout(); }.bind(_this), (30 * 1000));
    this.setDisplayDetails();


    this.highlightRootMenu();
    if (sessionStorage.getItem(USER_PROFILE_DATA)) {
      this.setName();
    }

    EmitterService.get('ENABLE_MENU').subscribe((data: any) => {
      this.showmenu = true;
      this.setName();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    EmitterService.get('HIDE_USER_DETAIL').subscribe((data: any) => {
      this.showUserDetails = false;
    });


    EmitterService.get('DISABLE_FOOTER').subscribe((data: any) => {
      this.showfooter = false;
      this.setName();
      if (!this.cdr['destroyed']) {
        this.cdr.detectChanges();
      }
    });
    this.router.events.subscribe((e) => {
      if (this.router.url.match('contentBody:affinity')) {
        this.showfooter = true;
        this.showmenu = false;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }
      if (this.router.url.match('contentBody:proposal')) {
        this.showfooter = false;
        this.showmenu = true;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }
      if (this.router.url.match('contentBody:landing')) {
        this.showfooter = true;
        this.showmenu = true;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }
      if (this.router.url.match('contentBody:issuedProposal')) {
        this.showfooter = false;
        this.showmenu = true;
        if (!this.cdr['destroyed']) {
          this.cdr.detectChanges();
        }
      }
      // if (this.router.url.match('contentBody:resources/renewalDownload')) {
      //   this.showfooter = false;
      //   this.showmenu = true;
      //   if (!this.cdr['destroyed']) {
      //     this.cdr.detectChanges();
      //   }
      // }
    });
    const user = JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'));
    if (user['roleName'] === 'System Administrator' || user['roleName'] === 'Underwriter') {
      this.disableAffinityChange = false;
    }
    this.highlightRootMenu();
    this.prepareUserActions();
  }

  ngOnDestroy() {
    sessionStorage.removeItem('MENU_REQUEST');
    clearInterval(this.timeoutRef);
  }
  FilteredArrayBySearchCriteria(datas: Array<Object>, searchby: string, value: any): Array<Object> {
    const filteredArray: Array<Object> = new Array<Object>();
    for (const data of datas) {
      if (data[searchby] !== undefined && data[searchby] === value) {
        filteredArray.push(data);
      }
    }
    return filteredArray;
  }
  getSubMenu(data: Object) {
    const filteredArray: Array<Object> = this.FilteredArrayBySearchCriteria(this.menuData, 'ParentMenuId', data['MenuId']);
    if (String(data['MenuLevel']) === '1')  {
      filteredArray.sort(function (a, b) {
        return String(a['MenuLabel'].toLowerCase()).localeCompare(String(b['MenuLabel'].toLowerCase()));
    });
    }
    return filteredArray;
  }

  getActionLink(url: string) {
    this.isOpenSideMenu = false;
    // if (url.includes('proposal') && !url.includes('actionType')) {
    //   EmitterService.get('ACTION_TYPE').emit('CREATE_PROPOSAL');
    // }
    this.router.navigateByUrl(url);
  }


  changeClicked() {
    // this.menuData = [];
    sessionStorage.removeItem('MENU_DATA');
    sessionStorage.removeItem(USER_AFFINITY);
    sessionStorage.removeItem('MENU');
    this.router.navigateByUrl('/welcome/(contentBody:affinity)');
  }
  /**** Session / Login *****/
  public setDisplayDetails() {
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    this.name = profileData['name'];
    this.role = profileData['roleName'];
    if(profileData['isMuthootCustomer'] !== undefined){
      this.isMuthootCustomer = profileData['isMuthootCustomer'];
    } else {
      this.showLibertyIcon = true;
    }
    if (this.role !== 'System Administrator' && this.role !== 'Underwriter') {
      const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
      this.affinityName = String(affinity['name']).toUpperCase();
      this.fullAffinityName = this.affinityName;
      if (this.affinityName.length > 14) {
        this.affinityName = this.affinityName.substr(0, 15) + '...';
      }
    }
  }
  public setAffinityDetails() {
    const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
    this.affinityName = String(affinity['name']).toUpperCase();
    this.fullAffinityName = this.affinityName;
    if (this.affinityName.length > 14) {
      this.affinityName = this.affinityName.substr(0, 15) + '...';
    }
  }
  public setName() {
    if (sessionStorage.getItem(USER_AFFINITY)) {
      const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
      this.affinityName = String(affinity['name']).toUpperCase();
      this.fullAffinityName = this.affinityName;
      if (this.affinityName.length > 14) {
        this.affinityName = this.affinityName.substr(0, 15) + '...';
      }
    }
  }
  /*********************/


  openSideMenu() {
    this.isOpenSideMenu = true;
  }

  closeSideMenu() {
    this.isOpenSideMenu = false;
  }

  public logoff() {
    sessionStorage.clear();
    this.router.navigateByUrl('/login');
  }


  public setActiveMaimenu() {
    this.menuType = 'main';
  }
  public setActiveSubmenu(submenu: any) {
    this.activeSubmenu = submenu;
    this.menuType = 'submenu';
  }
  public hideSubMenus() {
    const submenus: HTMLCollection = document.getElementsByClassName('sub-menu');
    for (let i = 0; i < submenus.length; i++) {
      const submenu: Element = submenus.item(i);
      submenu.setAttribute('style', 'display:none');
      setTimeout(function () {
        submenu.setAttribute('style', 'display:');
      }, 500);
    }
  }

  toggleDisplay() {
    if (this.display === 'none') {
      this.display = 'flex';
    } else {
      this.display = 'none';
    }
  }
  hideDropdown() {
    this.display = 'none';
  }
  logout() {
    const profileData = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
    this.navigationServices.signout(profileData);
    sessionStorage.setItem(USER_PROFILE, '');
    sessionStorage.setItem(USER_AFFINITY, '');
    this.router.navigateByUrl('/');
  }

    /**** Session / Login *****/
    public prepareMenu() {
      // if (!sessionStorage.getItem('MENU_REQUEST')) {
      sessionStorage.setItem('MENU_REQUEST', 'true');
      const searchRequest: Object = new Object();
      const userdetails = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
      if (userdetails['muthootBranchUser']) {
        const dataVal: Array<any> = new Array<any>();
        dataVal.push('No Products Mapped');
        this.navigationServices.getMenuItems(dataVal).then(data => {
          const menuArray = [];
          for (let i = 0; i < data.length; i++) {
            const menuItem = new Object();
            menuItem['MenuId'] = data[i]['menuId'];
            menuItem['MenuLabel'] = data[i]['menuName'];
            menuItem['MenuLevel'] = data[i]['menuLevel'];
            menuItem['MenuAction'] = data[i]['menuUrl'];
            menuItem['MenuPriviledge'] = data[i]['roleId'];
            menuItem['ParentMenuId'] = data[i]['parentMenu'] ? data[i]['parentMenu'] : null;
            menuItem['MenuSeq'] = null;
            menuArray.push(menuItem);
          }
          this.menuData = JSON.parse(JSON.stringify(menuArray));
          this.rootMenus = this.FilteredArrayBySearchCriteria(this.menuData, 'MenuLevel', 0);
          sessionStorage.setItem('MENU_DATA', JSON.stringify(this.menuData));
          sessionStorage.removeItem('MENU_REQUEST');
          this.highlightRootMenu();
          this.menuUpdate = false;
          this.showSpinner = false;
        }).catch(error => {
          sessionStorage.removeItem('MENU_REQUEST');
        });
      } else {
      if (sessionStorage.getItem(USER_AFFINITY) || sessionStorage.getItem(USER_AFFINITY) !== 'undefined') {
        this.productService.getProductNames2().then(names => {
          this.navigationServices.getMenuItems(names).then(data => {
            const menuArray = [];
            for (let i = 0; i < data.length; i++) {
              const menuItem = new Object();
              menuItem['MenuId'] = data[i]['menuId'];
              menuItem['MenuLabel'] = data[i]['menuName'];
              menuItem['MenuLevel'] = data[i]['menuLevel'];
              menuItem['MenuAction'] = data[i]['menuUrl'];
              menuItem['MenuPriviledge'] = data[i]['roleId'];
              menuItem['ParentMenuId'] = data[i]['parentMenu'] ? data[i]['parentMenu'] : null;
              menuItem['MenuSeq'] = null;
              menuArray.push(menuItem);
            }
            this.menuData = JSON.parse(JSON.stringify(menuArray));
            this.rootMenus = this.FilteredArrayBySearchCriteria(this.menuData, 'MenuLevel', 0);
            sessionStorage.setItem('MENU_DATA', JSON.stringify(this.menuData));
            sessionStorage.removeItem('MENU_REQUEST');
            this.highlightRootMenu();
            this.menuUpdate = false;
            this.showSpinner = false;
          }).catch(error => {
            this.logout();
            sessionStorage.removeItem('MENU_REQUEST');
          });
        }).catch(error => {
          sessionStorage.removeItem('MENU_REQUEST');
        });
        this.setDisplayDetails();
        // }
      }
    }
    }
    /***********************/
  public prepareUserActions() {
    this.userActions = [];
    const col1 = new Object();
    col1['label'] = 'Logout';
    col1['action'] = 'logout';
    this.userActions.push(col1);
  }
  public performAction(action: string) {
    if (action === 'logout') {
      this.logout();
    }
  }
  public toggleUserAction() {
    this.showUserActions = !this.showUserActions;
  }

  public async getRootMenuId(currentMenu): Promise<Number> {
    let menuRef = JSON.parse(JSON.stringify(currentMenu));
    if (!menuRef['ParentMenuId']) {
      return menuRef['MenuId'];
    }
    for (let i = 0; i < 4; i++) {
      for (let j = 0; j < this.menuData.length; j++) {
        if (String(menuRef['ParentMenuId']) === String(this.menuData[j]['MenuId'])) {
          if (!this.menuData[j]['ParentMenuId']) {
            return this.menuData[j]['MenuId'];
          } else {
            menuRef = JSON.parse(JSON.stringify(this.menuData[j]));
          }
        }
      }
    }
  }

  public getMatchedMenu(url: any): any {
    for (const menu of this.menuData) {
      const matchArray = String(menu['MenuAction']).match(/\(contentBody:.+\)/);
      if (matchArray && (decodeURI(matchArray[0]) === decodeURI(url))) {
        return menu;
      }
    }
  }

  public highlightRootMenu() {
    if (this.menuData && this.menuData.length > 0) {
      const matchArray = this.router.url.match(/\(contentBody:.+\)/);
      let partialUrl;
      if (matchArray && matchArray[0]) {
        partialUrl = matchArray[0];
      }
      if (partialUrl) {
        const currentMenu = this.getMatchedMenu(partialUrl);
        if (currentMenu) {
          this.getRootMenuId(currentMenu).then(parentMenu => this.parentMenuId = parentMenu);
        }
      }
    }
  }
}
