export class BeneficiaryVO {
  public policyId: String;
  public title: any;
  public firstName: String;
  public lastName: String;
  public dob: Date;
  public age: String;
  public gender: String;
  public proposerRelation: String;
  public nomineeName: String;
  public nomineeRelation: Number;
  public benificiaryType: String;
  public nomineeType: Number;
  public proposerOthers: String = '';
  public nomineeOthers: String = '';
  public beneficiaryInfoId: Number;
  public isActive: Number;
  public genderDescription: String = '';
  public proposerRelationDescription: String = '';
  public nomineeRelationDescription: String = '';
}
