import { DocumentVO } from './DocumentVO';

export class ProductVO {

    public documentVO: DocumentVO;
    public productName: String;
    public ProductStartDate: any = '';
    public ProductEndDate: Date;
    public ProductType: String;
    public MasterPolicy: String;

    public RiskStartDate: Date;
    public BackdatedInception: any;
    public BackdateInceptionRange: any;
    public PolicyTerm: any;
    public PolicyNumberingFormat: any;

    public EffectiveDate: Date;

    public Basic: String;
    public Elite: String;
    public Premium: String;
    public Supreme: String;
    public lobid: String;
    // public Renewal:String;
    // public Endorsement:String;
    public productMasterPolicy: any;
}
