import { Component, OnInit, Inject, OnChanges } from '@angular/core';
import { DOCUMENT } from '@angular/platform-browser';
import { Router, ActivatedRoute, NavigationCancel } from '@angular/router';
import { MAIN_PAGE_PART_URL, LANDING } from 'src/common/common.constants';
@Component({
  selector: 'app-error',
  templateUrl: './Error.html',

})
export class ErrorComponent implements OnInit, OnChanges {
  public user: any = new Object();
  public errorList: Array<Object> = new Array<Object>();
  constructor(private router: Router, private route: ActivatedRoute, @Inject(DOCUMENT) private document: any) {

  }
  ngOnInit() {
  }

  ngOnChanges(changes: any) {
  }

  authenticateUsers() {
      const url = MAIN_PAGE_PART_URL + LANDING + ')';
      this.user['userRoles'] = ['admin'];
      this.router.navigateByUrl(url);
    }
  }

