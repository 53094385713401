import { Injectable } from '@angular/core';
import {
  Http,
  Response,
  Headers,
  RequestOptions,
  ResponseContentType
} from '@angular/http';
import { AppConfig } from 'src/config/app.config';
import { REQUEST_HEADER, HEADER_URL } from '../common/common.constants';
import { PRODUCT_CODE, CERTIFICATE_NO, CUSTOMER_NAME, START_DATE, QUOTE_NO, END_DATE, BRANCH_CODE_INFO } from './../common/common.constants';
import { throwError } from 'rxjs';
import { e } from '@angular/core/src/render3';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { HEADER, HEADER_PRINT, USER_PROFILE } from './../common/common.constants';

@Injectable()
export class ProposalServices {
  hostUrl: string = this.config.getConfig('productHost');
  loginHostUrl: string = this.config.getConfig('loginHost');
  documentDownloadUrl: string = this.config.getConfig('documentHost');
  tempDownloadProp: string = this.config.getConfig('tempDocumentProposal');
  tempDocumentCertificate: string = this.config.getConfig('tempDocumentCert');
  constructor(private httpClient: HttpClient, private http: Http, private config: AppConfig) { }

  public getQuestion() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/product/' + productid + '/questions')
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAvailableProducts(affinityId) {
    return this.httpClient
      .get(this.hostUrl + 'api/product/menu/lob/' + affinityId)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAffinityDetailForEdit(affinityId: any) {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/public/affinity/' + affinityId)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAffinityDocDetailForEdit(affinityId: any, offset: any, size: any) {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/admin/affinity/documents/' + affinityId + '?offset=' + offset + '&size=' + size)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getEmployeeNameByCode(employeeId: any) {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/admin/employee/' + employeeId)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public saveAffinityData(affinityDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/saveaffinity';
    return this.httpClient
      .post(url, affinityDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public saveDocuments(docDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/save/affinity/documents';
    return this.httpClient
      .post(url, docDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public saveAffinityPayment(affinityDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/save/affinity/paymentOptions';
    return this.httpClient
      .post(url, affinityDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getQuestions() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/product/1/questions', { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getMasterPolicy() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/masterPolicy/' + productid, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getSumInsured(planid: any) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(this.hostUrl + 'api/rating/suminsured/' + planid, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public getProductDetails() {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/product/' + productid, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPolicyType(policystartDate) {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    const policyDetails: Object = new Object();
    policyDetails['policyDetails']  = new Object();
    policyDetails['policyDetails']['policyStartDate'] = policystartDate;
    return this.httpClient
      .post(
        this.hostUrl +
        'api/policy/product/' +
        productid +
        '/policytypes',policyDetails, { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPlanType(policyType) {
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/plans/' + policyType, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public getIssuedStateDetails(affinityId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/affinity/' + affinityId;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPremiumDetails(lobId, rourceDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    let url;
    if (lobId === 1) {
      url = this.hostUrl + 'api/rating/premium';
    } else if (lobId === 2) {
      url = this.hostUrl + 'api/rating/BPpremium';
    }
    return this.httpClient
      .post(url, rourceDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public savePropsal(policyDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/quote';
    return this.httpClient
      .post(url, policyDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPincode(pinCode) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(this.hostUrl + 'api/reference/pincodes/' + pinCode, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }

  public getPinCodeDetails(pinCode, pincodeLocality) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(this.hostUrl + 'api/reference/regions/' + pinCode +'/'+pincodeLocality, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getRiskPinCodeDetails(pinCode) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(this.hostUrl + 'api/reference/regions/' + pinCode, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getProposalSummary(summaryID) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const headers = new Headers();
    return this.httpClient
      .get(
        this.loginHostUrl + 'api/public/proposalInfo/' +summaryID ,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getProposalSummaryByCertificate(certificateId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityName= affinty['name'];
    return this.httpClient
      .get(
      this.loginHostUrl + 'api/policy/certificateNo/' + certificateId +'?affinityName=' +affinityName,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getProposalSummaryByCertificatePayment(certificateId,affinityName) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.loginHostUrl + 'api/public/certificateNo/' + certificateId +'?affinityName=' +affinityName,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getRenewalData(summaryID) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.hostUrl + 'api/public/proposalInfo/' +summaryID,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPrintProposal(requestJson) {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;
    const URl = this.hostUrl + 'api/document/generateProposal';
    return this.httpClient.post(URl, requestJson, { headers: HEADER_PRINT, responseType: 'arraybuffer' })
      .toPromise()
      .then((response: any) => response)
      .catch(this.handleError);
  }
  public getPrintProposalpayment(requestJson) {
    const headers = new Headers({
      'Content-Type': 'application/json',
    });
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;
    const URl = this.hostUrl + 'api/public/generateProposal';
    return this.httpClient.post(URl, requestJson, { headers: HEADER, responseType: 'arraybuffer' })
      .toPromise()
      .then((response: any) => response)
      .catch(this.handleError);
  }

  public getPrintCertificate(requestJson) {
    const headers = new Headers({
      Accept: 'application/pdf'
    });
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;
    const URl = this.hostUrl + 'api/document/generateCertificate';
    return this.httpClient
      .post(URl, requestJson, { headers: HEADER_PRINT, responseType: 'arraybuffer' })
      .toPromise()
      .then((response: any) => response)
      .catch(this.handleError);
  }

  public getPrintCertificatePayment(requestJson) {
    const headers = new Headers({
      Accept: 'application/pdf'
    });
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.ArrayBuffer;
    const URl = this.hostUrl + 'api/public/generateCertificate';
    return this.httpClient
      .post(URl, requestJson, { headers: HEADER, responseType: 'arraybuffer' })
      .toPromise()
      .then((response: any) => response)
      .catch(this.handleError);
  }

  public getPaymentTransDetails(transID) {
    return this.httpClient
      .get(
        this.loginHostUrl + 'api/public/certificate/' + transID,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);

  }

  public saveCoverageIds(searchCriteria){

    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/public/certificate/coverageIds';
    return this.httpClient
      .post(url, searchCriteria, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getIssuedProposal(count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    let branchCode = sessionStorage.getItem(BRANCH_CODE_INFO);
    if(branchCode === null){
      branchCode = '';
    }
    return this.httpClient
      .get(this.hostUrl + 'api/policy/issued/' + productid + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords +'&branchCode=' + branchCode, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }

  public getOldRenewalProposal(affinityId,count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .post(this.hostUrl + 'api/policy/list/renewal/data/' + affinityId + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }

  public getEndorsmentProposal(count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/endorsment/' + productid + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }

  public getRenewalProposal(count, totalNumberOfRecords,affinityName) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    return this.httpClient
      .get(
        this.hostUrl + 'api/policy/renewal/' + productid + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords +'&affinityName=' +affinityName, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }


  public getRetrieveProposal(count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    let branchCode = sessionStorage.getItem(BRANCH_CODE_INFO);
    if(branchCode === null){
      branchCode = '';
    }
    return this.httpClient
      .get(
        this.hostUrl + 'api/policy/retriveProposal/' + productid + '?' + 'offset=' + count + '&size=' + totalNumberOfRecords +'&branchCode=' + branchCode, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }

  public oldRenewalProposalSearch(affinityId, searchID, searchInput, count, totalNumberOfRecords) {
     let URL;
    if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/search/renewal/data/' + affinityId + '?' + CERTIFICATE_NO + '=' + searchInput + '&offset='+count + '&size='+totalNumberOfRecords;
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/search/renewal/data/' + affinityId + '?' + CUSTOMER_NAME + '=' + searchInput + '&offset=' +count + '&size='+totalNumberOfRecords;
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/search/renewal/data/' + affinityId + '?' + START_DATE + '=' + searchInput +'&offset=' +count + '&size='+totalNumberOfRecords;
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/search/renewal/data/' + affinityId + '?' + END_DATE + '=' + searchInput + + '&offset=' +count + '&size='+totalNumberOfRecords;
      } else {
        this.getOldRenewalProposal(affinityId,0, 10);
      }
      return this.httpClient
      .get(
        URL, { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }

  public proposalSearch(searchID, searchInput, policyStatus, action) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityName= affinty['name'];
    let branchCode = sessionStorage.getItem(BRANCH_CODE_INFO);
    if(branchCode === null){
      branchCode = '';
    }
    let URL;
    if (action === 'RENEWAL') {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + CERTIFICATE_NO + '=' + searchInput + '&offset=0' + '&size=10'+ '&affinityName=' +affinityName;
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + CUSTOMER_NAME + '=' + searchInput + '&offset=0' + '&size=10' + '&affinityName=' +affinityName;
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + START_DATE + '=' + searchInput + '&offset=0' + '&size=10' + '&affinityName=' +affinityName;
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + END_DATE + '=' + searchInput + '&offset=0' + '&size=10' + '&affinityName=' +affinityName;
      } else {
        this.getRenewalProposal(0, 10,affinityName);
      }
    } else if (action === 'ISSUED') {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CERTIFICATE_NO + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CUSTOMER_NAME + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + START_DATE + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + END_DATE + '=' + searchInput + '&offset=0' + '&size=10';
      } else {
        this.getIssuedProposal(0, 10);
      }
    } else if (action === 'ENDORSEMENT') {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CERTIFICATE_NO + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CUSTOMER_NAME + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + START_DATE + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + END_DATE + '=' + searchInput + '&offset=0' + '&size=10';
      } else {
        this.getEndorsmentProposal(0, 10);
      }
    } else {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CERTIFICATE_NO + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CUSTOMER_NAME + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + START_DATE + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + END_DATE + '=' + searchInput + '&offset=0' + '&size=10';
      } else if (searchID === QUOTE_NO) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + QUOTE_NO + '=' + searchInput + '&offset=0' + '&size=10';
      } else {
        if (action === 'RETRIEVE') {
          this.getRetrieveProposal(0, 10);
        }
      }
    }
    return this.httpClient
      .get(
        URL, { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }

  public proposalSearchPagination(searchID, searchInput, policyStatus, action, count, totalNumberOfRecords) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const productid = sessionStorage.getItem(PRODUCT_CODE);
    const affinty = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
    const affinityName= affinty['name'];
    let branchCode = sessionStorage.getItem(BRANCH_CODE_INFO);
    if(branchCode === null){
      branchCode = '';
    }
    let URL;
    if (action === 'RENEWAL') {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + CERTIFICATE_NO + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords +'&affinityName=' +affinityName;
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + CUSTOMER_NAME + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords +'&affinityName=' +affinityName;
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + START_DATE + '=' + searchInput + '&offset=0' + count + '&size=' + totalNumberOfRecords +'&affinityName=' +affinityName;
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/renewalSearch/' + productid + '?policyStatus=' + policyStatus + '&' + END_DATE + '=' + searchInput + '&offset=0' + count + '&size=' + totalNumberOfRecords +'&affinityName=' +affinityName;
      } else {
        this.getRenewalProposal(0, 10,affinityName);
      }
    } else if (action === 'ISSUED') {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CERTIFICATE_NO + '=' + searchInput + '&offset=' + count + '&size=' +totalNumberOfRecords;
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CUSTOMER_NAME + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + START_DATE + '=' + searchInput + '&offset='+ count + '&size='+totalNumberOfRecords;
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/issuedSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + END_DATE + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else {
        this.getIssuedProposal(0, 10);
      }
    } else if (action === 'ENDORSEMENT') {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CERTIFICATE_NO + '=' + searchInput + '&offset=' + count + '&size=' +totalNumberOfRecords;
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CUSTOMER_NAME + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + START_DATE + '=' + searchInput + '&offset='+ count + '&size='+totalNumberOfRecords;
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/endorsementSearch/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + END_DATE + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else {
        this.getEndorsmentProposal(0, 10);
      }
    } else {
      if (searchID === CERTIFICATE_NO) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus+ '&branchCode='+ branchCode +'&' + CERTIFICATE_NO + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else if (searchID === CUSTOMER_NAME) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + CUSTOMER_NAME + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else if (searchID === START_DATE) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + START_DATE + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else if (searchID === END_DATE) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + END_DATE + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else if (searchID === QUOTE_NO) {
        URL = this.hostUrl + 'api/policy/search/' + productid + '?policyStatus=' + policyStatus + '&branchCode='+ branchCode +'&' + QUOTE_NO + '=' + searchInput + '&offset=' + count + '&size=' + totalNumberOfRecords;
      } else {
        if (action === 'RETRIEVE') {
          this.getRetrieveProposal(0, 10);
        } else {
          this.getEndorsmentProposal(0, 10);
        }
      }
    }
    return this.httpClient
      .get(
        URL, { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        const errorResponse = new Object();
        errorResponse['policyDetailsList'] = [];
        errorResponse['totalRecords'] = 0;
        return errorResponse;
      });
  }


  public getCertificateSearch(summaryID) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.hostUrl + 'api/policy/certificateNo/' + summaryID,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  // business pacge appliance details get call.
  public getApplianceDetails(planId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.hostUrl + 'api/policy/applianceInfo/' + planId,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }


  public getApplianceDetailsPayment(planId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.loginHostUrl + 'api/public/applianceInfo/' + planId,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPaymentOptionList(applciationId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.hostUrl + 'api/payment/' + applciationId,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public saveEndorsementData(policyDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/endorsmentsavequote';
    return this.httpClient
      .post(url, policyDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  public getSumInsuredDetails(planId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.hostUrl + 'api/rating/getBPSuminsured?planID=' + planId,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getSumInsuredDetailsPayment(planId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.loginHostUrl + 'api/public/getBPSuminsured?planID=' + planId,
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getCoverageAndSections(coverageList) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/getcoverages';
    return this.httpClient
      .post(url, coverageList, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public getCoverageAndSectionsPayment(coverageList) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.loginHostUrl + 'api/public/getcoverages';
    return this.httpClient
      .post(url, coverageList, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public employeeCodeExists(employeeCode: string) {
    const url = this.hostUrl + 'api/admin/employee/' + employeeCode;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAffinityPaymentDetails() {
    const affinity = JSON.parse(sessionStorage.getItem('USER_AFFINITY'));
 //   const url = this.hostUrl + 'api/product/gateway/' + productid;
      const url = this.hostUrl + 'api/admin/affinity/paymentMapping/fetch/' + affinity['id'];
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getDetailsForPayment(paymentKey) {
    const url = this.hostUrl + 'api/reference/payment/url/' + paymentKey;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAvailablePaymentOptions() {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(
        this.hostUrl + 'api/payment/list/gateway',
        { headers: HEADER }
      )
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);

  }

  public paymentKeyDisable(paymentKey) {
    const url = this.hostUrl + 'api/reference/payment/disable/' + paymentKey;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public uploadDocumentsInS3(affinityId, formData: FormData) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/save/affinity/multipart/documents/' + affinityId;
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public validateDocuments(formData: FormData) {
    const headers = new Headers();
    headers.set('Authorization', 'Bearer ' + JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    headers.set('authorizationToken', JSON.parse(sessionStorage.getItem(USER_PROFILE)));
    const options = new RequestOptions({ headers: headers });
    options.responseType = ResponseContentType.Json;
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/validateDocuments';
    return this.http
      .post(url, formData, options)
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        return error;
      })
    }
  public verifyPaymentOTP(requestList) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.loginHostUrl + 'api/public/policyInfo/validateOtp';
    return this.httpClient
      .post(url, requestList, {headers:HEADER,responseType : 'text' as 'json'})
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public loadSahajDetails(authToken) {

    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.loginHostUrl + 'api/user/transaction/identity';
    return this.httpClient
      .post(url, authToken, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPaymentReqData(policyId, smState, branchCode, userName,token) {
    const url = this.hostUrl + 'api/policy/getPaymentReqData/' + policyId + '?userName=' + userName +
      '&branchCode=' + branchCode + '&smState=' + smState +'&authToken=' + token;
    return this.httpClient
      .get(url, { headers: HEADER, responseType: 'text' as 'json' })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPlanDetails(productId) {
    const url = this.hostUrl + 'api/policy/getPlan/'+ productId;
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);

  }

  public getBpSumIsnuredList(planList) {

    const url = this.hostUrl + 'api/rating/getListBPSuminsured';
    return this.httpClient
      .post(url, planList, { headers: HEADER})
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
    
  }

  public getCovergeDetlForPremium(planDetails) {
    const url = this.hostUrl + 'api/policy/getPlanDetails';
    return this.httpClient
      .post(url, planDetails, { headers: HEADER})
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getLob() {
    const url = this.hostUrl + 'api/admin/lob/list';
    return this.httpClient
      .get(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public updateAffinityProducts(affinityDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/admin/update/affinity/lob';
    return this.httpClient
      .post(url, affinityDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public muthoodCheckTokenStatus(muthootCusDtls){
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/public/policyInfo/imuthoot/checkTokenStatus';
    return this.httpClient
      .post(url, muthootCusDtls, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public muthootusDts(muthootCusDtls){
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/imuthoot/fetchCustomerDetails';
    return this.httpClient
      .post(url, muthootCusDtls, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public muthootStatusUpdate(policyDtls){
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/public/policyInfo/imuthoot/updatePolicyStatusResponse';
    return this.httpClient
      .post(url, policyDtls, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public muthoodBranchTokenStatus(tokenDetails){
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/public/policyInfo/muthoot/branch/checkTokenStatus';
    
    return this.httpClient
      .post(url, tokenDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public cashVoucherAPI(voucherDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/muthoot/branch/cashServiceVouchers';
    return this.httpClient
      .post(url, voucherDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public updateTransactionDetails(transactionDetails) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/muthoot/branch/transactionStatus';
    return this.httpClient
      .post(url, transactionDetails, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public updateMutBranchPolicyDetails(policyId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/policy/muthoot/branch/updatePolicyStatus/'+policyId;
    return this.httpClient
      .post(url, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getPlanwithPremium(planDts) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    const url = this.hostUrl + 'api/rating/getPlanWithPremium';
    return this.httpClient
      .post(url, planDts, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public oldRenewalDownload(addinityName,certificateNo) {
      const headers = new Headers({
        Accept: 'application/pdf'
      });
      const options = new RequestOptions({ headers: headers });
      options.responseType = ResponseContentType.ArrayBuffer;
      const URl = this.hostUrl + 'api/policy/download/renewal/'+addinityName +'?certificateNo='+certificateNo;
      return this.httpClient
        .get(URl, { headers: HEADER_PRINT, responseType: 'arraybuffer' })
        .toPromise()
        .then((response: any) => response)
        .catch(this.handleError);  
  }

  public validateDeclinedPincode(pinCode) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient
      .get(this.hostUrl + 'api/policy/declinedPincode/validation/' + pinCode, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(error => {
        return Promise.reject(error);
      });
  }


  public MobileValidation(affinityId,mobileNo, productId) {
    const requestOptions = new RequestOptions(REQUEST_HEADER);
    return this.httpClient.post(this.hostUrl + 'api/policy/mobileNoValidation/' + affinityId + '?' + 'mobileNo=' + mobileNo + '&productId=' + productId, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

  public getAaryaToken(details)
  {
      const url = this.hostUrl + 'api/policy/arya-generate-token';
      return this.httpClient
      .post(url, details, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }
  public checkAMLStatus(details)
  {
      const url = this.hostUrl + 'api/policy/checkAMLStatus';
      return this.httpClient
      .post(url, details, { headers: HEADER })
      .toPromise()
      .then((response: Response) => response)
      .catch(this.handleError);
  }

}