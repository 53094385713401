import { ClickOutsideDirective } from 'src/clickOutside.directive';
import { OnInit, EventEmitter, Output, OnChanges, AfterViewInit, ɵConsole } from '@angular/core';
import {
  Component,
  Optional,
  Inject,
  Input,
  ViewChild,
} from '@angular/core';


import {
  NgModel,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NG_ASYNC_VALIDATORS,
} from '@angular/forms';
import { ElementBase } from 'src/accessor/element.base';
import { SelectService } from '../services/select.services';
import { PRODUCT_CODE } from '../common/common.constants';
import { BENIFICIARY_SELF, RELATIONSHIP_DEPENDENT_DAUGHTER, RELATIONSHIP_DEPENDENT_SON, RELATIONSHIP_DAUGHTER, RELATIONSHIP_SON } from 'src/common/common.constants';
import { EmitterService } from 'src/services/EmitterService';
@Component({
  selector: 'app-multiselect',
  templateUrl: './multiselect.component.html',
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    useExisting: MultiselectComponent,
    multi: true,
  }],
})
export class MultiselectComponent extends ElementBase<String> implements OnInit, OnChanges {
  @Input() public label: String;
  @Input() public params: any;
  @Input() public placeholder: String;
  @Input() public lookupName: String;
  @Input() public labelhidden: String;
  @Input() public required: String;
  @Input() public DataArray: Array<Object>;
  @Input() public readonly: any = false;
  @Input() public properties: any;
  @Output() public changeevent = new EventEmitter<Object>();
  @Input() public propertyName;
  public isShow: Boolean = false;
  @Input()
  public validationMessage: String;
  public disablePlan: Boolean = false;
  public results: any;
  @Input() parseData: any;
  @Input() defaultSelect: Number;
  public selectAll = false;
  @ViewChild(NgModel) model: NgModel;

  @Input() public id: String;
  @Input() public enableParse = false;
  @Input() public enableParse2 = false;
  @Input() public edit = false;

  public identifier = `app-multiselect${identifier++}`;
  public isVisible: Boolean = true;

  public selectCount = 0;
  public totalCount = 0;
  public singleSelectedDescription = '';

  constructor(
    @Optional() @Inject(NG_VALIDATORS) validators: Array<any>,
    @Optional() @Inject(NG_ASYNC_VALIDATORS) asyncValidators: Array<any>,
    private selectService: SelectService
  ) {
    super(validators, asyncValidators);
  }
  ngOnInit() {
    if (this.parseData) {
      this.fetchLookUpdata(this.lookupName);
    } else if (this.lookupName === 'PolicyTerm') {
      this.fetchLookUpdata(this.lookupName);
    }
    EmitterService.get('MULTISELECT_PARSE').subscribe(data => {
      if (data && this.enableParse) {
        this.parse(data);
      }
    });
    EmitterService.get('MULTISELECT_PARSE_2').subscribe(data => {
      if (this.id && this.enableParse2) {
        this.parse2(data);
      }
    });
    EmitterService.get('MULTISELECT_INITIALIZE').subscribe(data => {
      this.fetchLookUpdata(this.lookupName);
    });
  }

  ngOnChanges(changes: any) {
     if (this.lookupName) {
       // this.fetchLookUpdata(this.lookupName);
     }
  }

  fetchLookUpdata(lookupName: any) {
    if (lookupName) {
      this.selectService.fetchLookUpData(lookupName, this.params)
        .then(data => {
          this.DataArray = this.convertToDataArray(data, lookupName);
          this.totalCount = this.DataArray.length;
          // for (const listData of this.DataArray) {
          //   listData['Checked'] = true;
          // }
          for (let i = 0; i < this.DataArray.length; i++) {
            if (this.defaultSelect && !this.value) {
              if (i < this.defaultSelect) {
                this.DataArray[i]['Checked'] = true;
                this.singleSelectedDescription = this.DataArray[i]['Description'];
              }
            } else {
              this.DataArray[i]['Checked'] = true;
              this.singleSelectedDescription = this.DataArray[i]['Description'];
            }
          }
         this.onChangeMethod();
        })
        .catch(err => {
        });
    }

  }

  public selectAllFunction() {
    for (const data of this.DataArray) {
      data['Checked'] = this.selectAll;
      this.singleSelectedDescription = data['Description'];
    }
    this.onChangeMethod();
  }

  public allChecked(): boolean {
    for (const data of this.DataArray) {
      if (!data['Checked']) {
        return false;
      }
    }
    return true;
  }

  public close() {


    this.isShow = false;
  }

  public show() {
    this.isShow = !this.isShow;
  }

  public onBlurMethod(showError: any): boolean {
    this.validationMessage = '';
    if (((this.required || this.required === 'true') && this.selectCount === 0)) {
      if (showError) {
        this.validationMessage = (this.label + ' is required');
      }
      return false;
    }
    return true;
  }

  public validationCall() {

  }

  public onChangeMethod() {
    const resultArray = [];
    const valueObject = [];
    this.selectCount = 0;
    for (const dtArray of this.DataArray) {
      if (dtArray['Checked']) {
        resultArray.push(dtArray['Code']);
        const valueItem = new Object();
        valueItem[this.propertyName] = dtArray['Code'];
        valueObject.push(valueItem);
        this.selectCount++;
        this.singleSelectedDescription = dtArray['Description'];
      }
    }
    if (this.totalCount === this.selectCount) {
      this.selectAll = true;
    } else {
      this.selectAll = false;
    }
    this.onBlurMethod(true);
    this.value = JSON.stringify(valueObject);
    EmitterService.get('VALIDATE').emit();
    this.changeevent.emit(this.value);
  }

  public parse(parseData: any) {
    if (this.lookupName) {
      this.selectService.fetchLookUpData(this.lookupName, this.params)
        .then(data => {
          this.DataArray = this.convertToDataArray(data, this.lookupName);
          this.totalCount = this.DataArray.length;
          // const inputData = JSON.parse(String(dataList));
          const inputData = JSON.parse(String(parseData));
          this.selectCount = 0;
          for (const checkedData of this.DataArray) {
            checkedData['Checked'] = false;
            for (const input of inputData) {
              if (String(input[this.propertyName]) === String(checkedData['Code'])) {
                checkedData['Checked'] = true;
                this.singleSelectedDescription = checkedData['Description'];
                this.selectCount++;
              }
            }
          }
          EmitterService.get('VALIDATE').emit(true);
        })
        .catch(err => {
        });
    }
  }

  public parse2(parseParam: any) {
    const id: any = this.id;
    const parseData = parseParam[id];
    if (this.lookupName) {
       this.selectService.fetchLookUpData(this.lookupName, this.params)
         .then(applianceData => {
          this.DataArray = this.convertToDataArray(applianceData, this.lookupName);
          this.totalCount = this.DataArray.length;
          this.selectCount = 0;
            const appliances = String(parseData).split(',');
            for (const data of this.DataArray) {
              data['Checked'] = false;
            }
            for (const appliance of appliances) {
              for (const data of this.DataArray) {
                if (String(data['Description']) === String(appliance)) {
                  data['Checked'] = true;
                  this.singleSelectedDescription = data['Description'];
                  this.selectCount++;
                }
              }
            }
            if (this.selectCount === this.totalCount) {
              this.selectAll = true;
            } else {
              this.selectAll = false;
            }
            EmitterService.get('VALIDATE').emit(true);
         })
         .catch(err => {
         });
    }
  }
  public parse3(parseParam: any, applianceData: any) {
    let data1 = parseParam;
    const id: any = this.id;
    const parseData = parseParam[id];
    this.lookupName = 'applianceList';
    if (this.lookupName) {
      // this.selectService.fetchLookUpData(this.lookupName, this.params)
      //   .then(applianceData => {
          this.DataArray = this.convertToDataArray(applianceData, this.lookupName);
          this.totalCount = this.DataArray.length;
          this.selectCount = 0;
         //   const appliances = String(parseData).split(',');
            const appliances = parseParam;
            for (const data of this.DataArray) {
              data['Checked'] = false;
            }
            for (const appliance of appliances) {
              for (const data of this.DataArray) {
                if (data['Description'] === appliance['applianceName']) {
                  data['Checked'] = true;
                  this.singleSelectedDescription = data['Description'];
                  this.selectCount++;
                }
              }
            }
            if (this.selectCount === this.totalCount) {
              this.selectAll = true;
            } else {
              this.selectAll = false;
            }
           this.onChangeMethod();
         //   EmitterService.get('VALIDATE').emit(true);
           // this.changeevent.emit(this.value);
        // })
        // .catch(err => {
        // });
    }
  }

  private convertToDataArray(dataArray, metadataname) {

    switch (metadataname) {
      case 'PolicyTerm':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {

            const obj: Object = new Object();
            obj['Code'] = data['policyTermId'];
            obj['Description'] = data['policyTerm'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('PolicyTerm', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'PolicyType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {

            const obj: Object = new Object();
            obj['Code'] = data['policytypeId'];
            obj['Description'] = data['policyTypeName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('PolicyType', JSON.stringify(dataArray));
          return dtArray;

        }
      case 'PlanType':
        {
          //  this.value = undefined;
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['planid'];
            obj['Description'] = data['planTypeName'];
            if (obj['Description'] === '1 Adult') {
              this.disablePlan = true;
              this.value = obj['Code'];
              this.validationMessage = '';
            } else {
              this.disablePlan = false;
            }
            dtArray.push(obj);
          }
          sessionStorage.setItem('PlanType', JSON.stringify(dataArray));
          // this.changeevent.emit(this.value);
          return dtArray;
        }
      case 'MasterPolicy':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['masterPolicyId'];
            obj['Description'] = data['masterPolicyId'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('MsaterPolicy', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'SumInsured':
        {

          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['sumInsured'];
            obj['Description'] = data['sumInsured'];
            dtArray.push(obj);
          }
          dtArray.sort(function (a: SelectObject, b: SelectObject) {
            if (a.Description > b.Description) { return 1; }
            if (a.Description < b.Description) { return -1; }
            return 0;
          });
          sessionStorage.setItem('SumInsured', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'Title':
        {
          const prodId = sessionStorage.getItem(PRODUCT_CODE);
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['titleId'];
            obj['Description'] = data['titleName'];
            dtArray.push(obj);
          }
          if (Number(prodId) === 1) {
            dtArray.splice(-1, 1);
          }

          sessionStorage.setItem('Title', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'Gender':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['genderId'];
            obj['Description'] = data['genderName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('Gender', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'RelationType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            if (data['relationType'] !== BENIFICIARY_SELF && data['relationType'] !== RELATIONSHIP_DAUGHTER && data['relationType'] !== RELATIONSHIP_SON) {
              obj['Code'] = data['relationId'];
              obj['Description'] = data['relationType'];
              dtArray.push(obj);
            }
          }
          sessionStorage.setItem('RelationType', JSON.stringify(dataArray));
          sessionStorage.setItem('ChildRelationType', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'ChildRelationType':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            if (data['relationType'] === RELATIONSHIP_DEPENDENT_DAUGHTER || data['relationType'] === RELATIONSHIP_DEPENDENT_SON) {
              obj['Code'] = data['relationId'];
              obj['Description'] = data['relationType'];
              dtArray.push(obj);
            }
          }
          sessionStorage.setItem('ChildRelationType', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'pincode':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['pincode'];
            obj['Description'] = data['pincode_locality'];
            dtArray.push(obj);
          }
          return dtArray;
        }
      case 'CreateUserRole':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['roleId'];
            obj['Description'] = data['roleName'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('CreateUserRole', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'Endorsement':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['endorsementTypeID'];
            obj['Description'] = data['endorsementType'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('Endorsement', JSON.stringify(dataArray));
          return dtArray;
        }

      case 'planInfo':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['planId'];
            obj['Description'] = data['planInfo'];
            dtArray.push(obj);
          }
          sessionStorage.setItem('PolicyTerm', JSON.stringify(dataArray));
          return dtArray;
        }
      case 'applianceList':
        {
          const dtArray: Array<Object> = new Array<Object>();
          for (const data of dataArray) {
            const obj: Object = new Object();
            obj['Code'] = data['applianceID'];
            obj['Description'] = data['applianceName'];
            dtArray.push(obj);
          }
          return dtArray;
        }
    }
    return [];
  }
}
let identifier = 0;

class SelectObject {
  Description: string;
  Code: number;
}
