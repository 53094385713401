
export class PaymentDetailsVO {
public paymentType: String;
public amount: Number;
public paymentID: Number;
public otpCreatedDate: any;
public otpVerifiedDate: any;
public status: any;
public otp: any;
public otpEncrypt: any;
// public dob: any = [];
}