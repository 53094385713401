import { Http, Response, Headers, RequestOptions } from '@angular/http';
import { Injectable, OnInit, OnChanges } from '@angular/core';
import { AppConfig } from '../config/app.config';
import { REQUEST_HEADER, USER_PROFILE_DATA, USER_AFFINITY, AFFINITY_ID } from '../common/common.constants';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { HEADER, USER_PROFILE } from './../common/common.constants';
// import { Base64 } from 'js-base64';


@Injectable()
export class NavigationServices {
  hostUrl: string = this.config.getConfig('productHost');
  constructor(private httpClient: HttpClient, private http: Http,
    private config: AppConfig) {

  }

  // public getMenuItems(searchCriteria: Object) {
  //   const requestOptions = new RequestOptions(REQUEST_HEADER);
  //   let url: any;
  //   // 2019-03-29
  //   const user = JSON.parse(sessionStorage.getItem(USER_PROFILE_DATA));
  //   const affinity = JSON.parse(sessionStorage.getItem(USER_AFFINITY));
  //   url = this.hostUrl + 'api/user/getMenu/' + user['roleId'] + '?affinityId=' + affinity[AFFINITY_ID];

  //   const requestBody = new Object();
  //   requestBody['menuProductList'] = [];

  //   return this.httpClient.post(url, requestBody, { headers: HEADER })
  //     .toPromise().then((response: Response) => response).catch(this.handleError);
  // }

  public getMenuItems(userDetails: any) {
    let url: any;
    userDetails=new Object();
    userDetails['roleId']=JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'))['roleId'];
    userDetails['roleName']=JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'))['roleName'];
    userDetails['userId']=JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'))['userId'];
    userDetails['userIdEncrypt']=JSON.parse(sessionStorage.getItem('USER_PROFILE_DATA'))['userIdEncrypt'];
    userDetails['affinityId']=JSON.parse(sessionStorage.getItem('USER_AFFINITY'))['id'];
    
    url = this.hostUrl + 'api/user/getMenuByUserId';
    return this.httpClient.post(url, userDetails , { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }


  public validateUserDetails(userDetails: any) {
    let url: any;
    url = this.hostUrl + 'api/user/validateUserDetails';
    
    return this.httpClient.post(url, userDetails , { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }

  public mapMenuToProduct(productId: any, productName: any, productType: any, affinityId: any, endorsement: any, renewal: any, startDate: any, endDate: any) {
    let url: any;
    const body = new Object();
    body['productId'] = productId;
    body['productName'] = productName;
    body['productType'] = productType;
    body['affinityId'] = affinityId;
    body['endorsement'] = endorsement;
    body['renewal'] = renewal;
    body['startDate'] = startDate;
    body['endDate'] = endDate;
    // url = this.hostUrl +
    //   'api/user/product/menu?productName=' + productName +
    //   '&productType=' + productType + '&affinityId=' + affinityId + '&endorsement=' + endorsement +
    //   '&renewal=' + renewal + '&startDate=' + startDate + '&endDate=' + endDate;
    url = this.hostUrl + 'api/user/product/menu';
    return this.httpClient.post(url, body, { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }


  private handleError(error: any): Promise<any> {
    return Promise.reject(error.message || error);
  }

  public signout(userDetails: any) {
    let url: any;
    url = this.hostUrl + 'api/user/signout';
    const userDetailsObj = new Object();
    userDetailsObj['userDetails'] = new Object();
    userDetailsObj['userDetails']['userIdEncrypt'] = userDetails['userIdEncrypt'];;
    const userDetailsObjDetails = JSON.stringify(userDetailsObj);

    return this.httpClient.post(url, userDetailsObjDetails , { headers: HEADER })
      .toPromise().then((response: Response) => response).catch(this.handleError);
  }
}
